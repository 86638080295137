import {
  Button,
  Form,
  Input,
  Spin,
  Tooltip,
  Typography,
  message as messages
} from "antd";
import { AppLogoIcon } from "components/svg-icons";
import { ErrorMessages } from "constants/index";
import FormItem from "antd/es/form/FormItem";
import { PasswordRequest } from "models/user";
import { useEffect, useState } from "react";
import { upadatepassword, validateUser } from "services/auth";
import { useHistory } from "react-router";
import jwtDecode from "jwt-decode";
import { InfoCircleOutlined } from "@ant-design/icons";

function SetPassword() {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const accessToken = params.get("token") || "";
  const isForgetPasswordLink = params.get("forgot-password") || false;
  const [isSessionExpired, setSessionExpired] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (accessToken) {
      const jwt: any = jwtDecode(accessToken);
      if (!jwt) return;

      const expirationTime = jwt.exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        setSessionExpired(true);
        setLoading(false);
        messages.error("Link has expired.");
        history.push("/");
        return;
      }

      if (!isForgetPasswordLink) {
        const validateUserToken = async () => {
          if (!jwt) return;
          const subscriptionId =
            jwt["https://hasura.io/jwt/claims"]["x-hasura-subscription-id"];
          const projectId =
            jwt["https://hasura.io/jwt/claims"]["x-hasura-project-id"];
          const userId =
            jwt["https://hasura.io/jwt/claims"]["x-hasura-user-id"];

          const result: any = await validateUser(
            userId,
            accessToken,
            subscriptionId,
            projectId
          );

          setLoading(false);

          if (result?.data?.success) {
            if (result?.data?.success?.status === "USER_PASSWORD_NOT_SET") {
              setSessionExpired(false);
              return;
            }
            if (
              result?.data?.success?.status === "INVITATION_ALREADY_ACCEPTED" ||
              result?.data?.success?.status ===
                "USER_NOT_ACCEPTED_SUBSCRIPTION_INVITE" ||
              result?.data?.success?.status ===
                "USER_NOT_ACCEPTED_PROJECT_INVITE"
            ) {
              setSessionExpired(true);
              messages.success({
                content: (
                  <div>
                    Password has already been set.{" "}
                    <span>
                      <a href="/" className="underline">
                        Click here
                      </a>
                    </span>{" "}
                    to login.
                  </div>
                ),
                duration: 60
              });
            } else {
              setSessionExpired(true);
              messages.success(result?.data?.success?.message);
              history.push("/");
            }
          }

          if (result?.response?.data?.error) {
            setSessionExpired(true);
            if (result?.response?.data?.error === "Link has expired.") {
              messages.error(ErrorMessages.sessionExpiredMsg);
            } else {
              messages.error(result?.response?.data?.error || "");
            }
          }
        };
        validateUserToken();
      } else {
        setLoading(false);
      }
    }
  }, [accessToken, form, history, isForgetPasswordLink]);

  const onFinish = async (event: PasswordRequest) => {
    setLoader(true);
    const result: any = await upadatepassword({
      password: event.password,
      token: accessToken
    });

    setLoader(false);
    if (result.status >= 200 && result.status <= 204) {
      messages.success(ErrorMessages.PasswordSetMsg);
      history.push("/");
    } else if (result.response?.data?.error) {
      messages.error(result.response?.data?.error);
      if (result.response?.data?.error === "Link has expired.") {
        history.push("/");
      }
    } else {
      messages.error(result.message);
    }

    form.resetFields();
  };

  if (isLoading) {
    return (
      <div className="flex min-w-full min-h-screen bg-[#f7f6f4] items-center justify-center p-2">
        <Spin />
      </div>
    );
  }

  return (
    <div className="flex min-w-full min-h-screen bg-[#f7f6f4] items-center justify-center p-2">
      <div className="flex flex-col p-8 shadow-[0px_5px_25px_rgba(0,0,0,0.1)] w-[400px] rounded-md">
        <div className="m-auto block">
          <AppLogoIcon />
        </div>
        {!isSessionExpired && (
          <div>
            <div className="flex justify-center">
              <Title level={3}>Set Password</Title>
            </div>
            <Form
              className="space-y-3"
              onFinish={onFinish}
              form={form}
              scrollToFirstError
              layout="vertical"
            >
              <FormItem
                name="password"
                label={
                  <div>
                    New Password{" "}
                    <Tooltip title={ErrorMessages.PasswordRequired}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                className="password"
                validateStatus=""
                rules={[
                  {
                    validateTrigger: "onSubmit",
                    required: true,
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$£@_%+=.,^&(){}[\]|;:'"”<>?\\]).{12,}$/,
                    message: ErrorMessages.PasswordRequired
                  }
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Enter New Password" />
              </FormItem>

              <FormItem
                name="confirmpassword"
                label="Confirm Password"
                className="password"
                dependencies={["password"]}
                hasFeedback
                validateStatus=""
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.ConfirmPasswordRequired
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(ErrorMessages.ConfirmPasswordRequired)
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Enter Confirm Password" />
              </FormItem>
              <div style={{ paddingTop: "1em" }}>
                <Button
                  htmlType="submit"
                  size="middle"
                  style={{ margin: "auto", display: "block" }}
                  loading={loader}
                  disabled={loader}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}
export default SetPassword;
