import { Button, Form } from "antd";

function ReadOnlyGeneralInfo(props: any) {
  const {
    submittalData,
    BICUnregisteredUserDetails,
    getUserCompany,
    submittalTypesOptions,
    setShowSpecSectionFileModal,
    submittalHeaderMap
  } = props;

  const getSpecString = () => {
    if (submittalData?.spec_no && submittalData?.spec_name)
      return `${submittalData?.spec_no} - ${submittalData?.spec_name}`;
    if (submittalData?.spec_no) return submittalData?.spec_no;
    return undefined;
  };

  const getSubmittalType = () => {
    const foundType = submittalTypesOptions?.find((type: any) => {
      return type.id === submittalData?.type;
    });
    if (!foundType) return "";
    return foundType.value;
  };

  const specSectionLabel = () => {
    return (
      <div className="w-full flex items-center justify-between">
        <div className="uppercase">Spec Section</div>
        <div>
          {submittalData?.spec_section?.file_key ? (
            <Button
              type="link"
              size="small"
              className="text-xs uppercase workflow-document-upload h-[17px] mb-1 mr-1"
              onClick={() => {
                setShowSpecSectionFileModal(true);
              }}
            >
              view spec section file
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className="grow min-h-0 h-[320px] overflow-auto">
      <div className="w-full flex">
        <Form.Item label="State" className="w-1/3">
          <span className="font-bold">{submittalData?.raw_status || ""}</span>
        </Form.Item>
        <Form.Item label="Ball In Court" className="w-2/3">
          {submittalData?.assignee_unregistered && (
            <div>
              <span className="font-bold">
                {BICUnregisteredUserDetails?.fullName}
              </span>
              <span className="ml-1 text-xs">
                ({BICUnregisteredUserDetails?.company.name})
              </span>
            </div>
          )}
          {submittalData?.submittal_assignee && (
            <div>
              <span className="font-bold">
                {submittalData?.submittal_assignee?.first_name}{" "}
                {submittalData?.submittal_assignee?.last_name}
              </span>
              <span className="ml-1 text-xs">
                (
                {getUserCompany(
                  submittalData?.submittal_assignee?.project_users[0]
                )}
                )
              </span>
            </div>
          )}
        </Form.Item>
      </div>
      <Form.Item
        label={
          submittalHeaderMap?.submittal_number?.toUpperCase() ||
          "SUBMITTAL NUMBER"
        }
      >
        <span>{submittalData?.submittal_number}</span>
      </Form.Item>
      <Form.Item label={specSectionLabel()}>
        <span>{getSpecString()}</span>
      </Form.Item>
      <Form.Item label="TYPE">
        <span>{getSubmittalType()}</span>
      </Form.Item>
      <Form.Item
        label={submittalHeaderMap?.priority?.toUpperCase() || "PRIORITY"}
      >
        <span>{submittalData.priority}</span>
      </Form.Item>
      <Form.Item label="Description">
        <span>{submittalData?.description}</span>
      </Form.Item>
    </div>
  );
}

export default ReadOnlyGeneralInfo;
