import { Button } from "antd";
import "./summary-table.scss";
import { RiskStates } from "../../constants";

function RiskSummary(props: any) {
  const { riskSummaryData, onCountClick, detailsClickEnabled } = props;

  return (
    <div>
      <div>
        <table className="summary-table font-semibold text-left table-auto w-full ">
          <thead>
            <tr>
              <th>RISK</th>
              <th>SUBMITTALS</th>
              <th>MATERIALS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cell-with-padding">HIGH</td>
              <td>
                {detailsClickEnabled ? (
                  <Button
                    className="button-with-padding w-full text-left h-auto font-semibold outline-0 border-0"
                    type="text"
                    title="Click to view submittals at high risk"
                    onClick={() => {
                      onCountClick({
                        type: "submittal",
                        risk: RiskStates.HIGH
                      });
                    }}
                  >
                    <span className="text-danger underlined-text">
                      {riskSummaryData.submittal.high}
                    </span>
                  </Button>
                ) : (
                  <span className="text-danger cell-with-padding">
                    {riskSummaryData.submittal.high}
                  </span>
                )}
              </td>
              <td>
                {detailsClickEnabled ? (
                  <Button
                    className="button-with-padding w-full text-left h-auto font-semibold outline-0 border-0"
                    type="text"
                    title="Click to view materials at high risk"
                    onClick={() => {
                      onCountClick({
                        type: "material",
                        risk: RiskStates.HIGH
                      });
                    }}
                  >
                    <span className="text-danger underlined-text">
                      {riskSummaryData.material.high}
                    </span>
                  </Button>
                ) : (
                  <span className="text-danger cell-with-padding">
                    {riskSummaryData.material.high}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="cell-with-padding">MEDIUM</td>
              <td>
                {detailsClickEnabled ? (
                  <Button
                    className="button-with-padding w-full text-left h-auto font-semibold outline-0 border-0"
                    type="text"
                    title="Click to view submittals at medium risk"
                    onClick={() => {
                      onCountClick({
                        type: "submittal",
                        risk: RiskStates.MEDIUM
                      });
                    }}
                  >
                    <span className="underlined-text">
                      {riskSummaryData.submittal.medium}
                    </span>
                  </Button>
                ) : (
                  <span className="cell-with-padding">
                    {riskSummaryData.submittal.medium}
                  </span>
                )}
              </td>
              <td>
                {detailsClickEnabled ? (
                  <Button
                    className="button-with-padding w-full text-left h-auto font-semibold outline-0 border-0"
                    type="text"
                    title="Click to view materials at medium risk"
                    onClick={() => {
                      onCountClick({
                        type: "material",
                        risk: RiskStates.MEDIUM
                      });
                    }}
                  >
                    <span className="underlined-text">
                      {riskSummaryData.material.medium}
                    </span>
                  </Button>
                ) : (
                  <span className="cell-with-padding">
                    {riskSummaryData.material.medium}
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="cell-with-padding">LOW</td>
              <td>
                <span className="cell-with-padding">
                  {riskSummaryData.submittal.low}
                </span>
              </td>
              <td>
                <span className="cell-with-padding">
                  {riskSummaryData.material.low}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RiskSummary;
