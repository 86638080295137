import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tabs, Button, Spin, message, Select } from "antd";
import SubmittalDetails from "components/submittal-details/submittal-details";

import { AttachmentDoc, SubmittalLog } from "models/submittal-log";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import "./submittal-details.css";

import { useMutation, useQuery } from "@apollo/client";
import {
  MUTATION_UPDATE_ATTACHMENT_BY_PK,
  MUTATION_UPDATE_SUBMITTAL_BY_ID
} from "services/graphQL/mutations";
import { generateS3URL } from "services/file-mgmt";
import { noAuthClient } from "services/axios";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  getEditableFields,
  updateSubmittalOne
} from "components/submittal-details/helpers";
import {
  getSubmittalStatus,
  isProjectInIntegrationMode,
  matchUserProjectRole,
  updateSubmittalParticipantsForINTG
} from "utils/utils";
import SubmittalTitlePanel from "components/submittal-title-panel";
import {
  QUERY_SUBMITTAL_TYPES,
  QUERY_SUBMITTAL_WORKFLOW_RESPONSES
} from "services/graphQL/queries";
import ErrorBoundary from "components/error-boundary";
import {
  useIntegrationProjectParticipants,
  useProjectParticipants
} from "hooks/project-participants";

import {
  SUBMITTAL_ATTACHMENTS,
  SUBMITTAL_BY_PK
} from "services/graphQL/ciq-queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { IntegrationType } from "pages/subscription-settings/integrations-tab-new/utils";
import SubmittalAttachments from "./submittal-attachment";
import { ESubmittalStatus, EUserTypes, ErrorMessages } from "../../constants";

function SubmittalDetailspage() {
  const history = useHistory();
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get("tab");
  const paramAttachmentId = new URLSearchParams(location.search).get("aId");
  const [tabIndex, setTabIndex] = useState<string>(tab || "1");

  const { projectId, submittalId } = useParams() as any;

  const [updatedData, setUpdatedData] = useState<SubmittalLog | null>(null);
  const [submittalRevisions, setSubmittalRevision] = useState<
    Array<SubmittalLog>
  >([]);
  const [isRevisionMode, setRevisionMode] = useState(false);
  const {
    gqlClientForProject,
    tokenRetrievalState,
    tokenContents,
    columnHeaders: { submittalHeaderMap },
    projectDetails,
    projectIntegrationConfig,
    eventLogs
  }: TProjectContext = useContext(ProjectContext);

  const [submittalViewState, setSubmittalViewState] = useState<{
    status: number;
    userType: string;
  }>({
    status: 4,
    userType: tokenContents?.userType as string
  });

  const isLoggedInUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const { data: dataSubmittalTypes } = useQuery(QUERY_SUBMITTAL_TYPES);

  const { projectParticipants: WFProjectParticipants } = useProjectParticipants(
    isIntegrationMode || !isLoggedInUserGC
  );
  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode || !isLoggedInUserGC
  );

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  const { data: subSubmittalByPkData, refetch: refetchSubmittalPK } =
    useCIQQuery(SUBMITTAL_BY_PK, {
      variables: { id: submittalId },
      client: gqlClientForProject,
      skip: !gqlClientForProject
    });

  const dataSubmittalByPk = useMemo(() => {
    if (!subSubmittalByPkData) return null;

    const clonedSubmittalData = JSON.parse(
      JSON.stringify(subSubmittalByPkData)
    );
    let submittalDetails = clonedSubmittalData?.submittals_by_pk;
    try {
      if (isIntegrationMode) {
        submittalDetails = updateSubmittalParticipantsForINTG(
          subSubmittalByPkData?.submittals_by_pk
        );
      }
    } catch (ex: any) {
      console.log("failed to update submittal users: ", ex);
    }
    return submittalDetails;
  }, [isIntegrationMode, subSubmittalByPkData]);

  const { data: dataAllAttachments, refetch: refetchAttachments } = useCIQQuery(
    SUBMITTAL_ATTACHMENTS,
    {
      variables: {
        where: {
          submittal_id: { _eq: submittalId },
          version_of: { _is_null: true },
          submittal_revision: {
            _eq: dataSubmittalByPk?.revision
          }
        }
      },
      client: gqlClientForProject,
      skip: !gqlClientForProject || !dataSubmittalByPk
    }
  );

  const previousEventLogs = useRef(eventLogs);

  useEffect(() => {
    if (eventLogs.length && previousEventLogs.current !== eventLogs) {
      if (
        eventLogs.some(
          (e) =>
            e.data_source === "attachments" &&
            e.info.submittal_ids?.includes(submittalId)
        )
      ) {
        refetchAttachments();
      }

      const dependentIds = [submittalId];
      const submittalDbId = dataSubmittalByPk?.date_block_submittals[0]?.id;
      const linkedMaterialIds =
        dataSubmittalByPk?.submittal_material_links?.map(
          (m: any) => m.material_id
        );
      const linkedMaterialDbIds =
        dataSubmittalByPk?.submittal_material_links?.map(
          (m: any) => m.material?.date_block_materials[0].id
        );
      if (submittalDbId) dependentIds.push(submittalDbId);
      if (linkedMaterialDbIds) dependentIds.push(...linkedMaterialDbIds);
      if (linkedMaterialIds) dependentIds.push(...linkedMaterialIds);

      if (
        eventLogs.some(
          (e) =>
            e.data_source === "project_feature_configurations" ||
            e.info.submittal_ids?.includes(submittalId) ||
            dependentIds.includes(e.object_id)
        )
      ) {
        refetchSubmittalPK();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    dataSubmittalByPk?.date_block_submittals,
    dataSubmittalByPk?.submittal_material_links,
    eventLogs,
    refetchAttachments,
    refetchSubmittalPK,
    submittalId
  ]);

  const dataSubmittalRevisions = subSubmittalByPkData;

  const [updateSubmittalById] = useMutation<any>(
    MUTATION_UPDATE_SUBMITTAL_BY_ID,
    {
      client: gqlClientForProject
    }
  );

  const [allAttachments, setAllAttachements] = useState([]);
  const [attachmentDocuments, setAttachmentDocuments] = useState<{
    documents: Array<AttachmentDoc>;
    referenceDocs: Array<AttachmentDoc>;
  }>({ documents: [], referenceDocs: [] });

  const [hasCurrentAccess] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState<
    AttachmentDoc | undefined
  >();

  const [isWorkflowInOverrideMode, setIsWorkflowInOverrideMode] =
    useState(false);

  useEffect(() => {
    if (!tab) {
      history.replace(`/project/${projectId}/submittals/${submittalId}?tab=1`);
    } else {
      setTabIndex(tab);
    }
  }, [history, projectId, submittalId, tab]);

  useEffect(() => {
    if (dataAllAttachments) {
      const { attachments } = dataAllAttachments;
      setAllAttachements(attachments);
      setAttachmentDocuments({
        documents: attachments.filter((x: AttachmentDoc) => !x.reference_id),
        referenceDocs: attachments.filter((y: AttachmentDoc) => y.reference_id)
      });
    }
  }, [dataAllAttachments]);

  const { data: submittalWorkflowResponses } = useQuery(
    QUERY_SUBMITTAL_WORKFLOW_RESPONSES,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const [deleteAttachmentMutationPK] = useMutation<any>(
    MUTATION_UPDATE_ATTACHMENT_BY_PK,
    { client: gqlClientForProject }
  );

  useEffect(() => {
    if (dataSubmittalRevisions) {
      if (dataSubmittalRevisions.submittals_by_pk?.submittal_revisions) {
        const clonedSubmittalRevisions = [
          ...dataSubmittalRevisions.submittals_by_pk.submittal_revisions
        ];
        clonedSubmittalRevisions.sort(
          (a: any, b: any) => b.revision - a.revision
        );
        setSubmittalRevision(clonedSubmittalRevisions);
      } else {
        setSubmittalRevision([]);
      }
    }
  }, [dataSubmittalRevisions]);

  useEffect(() => {
    if (
      gqlClientForProject &&
      tokenRetrievalState?.success &&
      dataSubmittalByPk &&
      tokenContents
    ) {
      if (!isRevisionMode && tokenContents) {
        setUpdatedData(dataSubmittalByPk);

        const submittalStatus = getSubmittalStatus(
          dataSubmittalByPk?.submittal_status_history_details
        );
        setSubmittalViewState({
          userType: tokenContents.userType,
          status: submittalStatus || ESubmittalStatus.CREATE
        });
      }
    }
  }, [
    gqlClientForProject,
    tokenRetrievalState,
    dataSubmittalByPk,
    tokenContents,
    isRevisionMode
  ]);

  const editableFields: string[] = useMemo(() => {
    const tmpEditableFields = getEditableFields(
      submittalViewState,
      updatedData,
      tokenContents
    );
    if (isWorkflowInOverrideMode) {
      tmpEditableFields.push("attachment");
    }
    return tmpEditableFields;
  }, [
    submittalViewState,
    updatedData,
    tokenContents,
    isWorkflowInOverrideMode
  ]);

  const updateSubmittalTitle = async (title: string) => {
    if (title.trim() === "") return;
    try {
      const variables: any = {
        id: submittalId,
        submittalValues: { title }
      };
      const updateResponse: any = await updateSubmittalById({
        variables
      });

      if (updateResponse.errors) {
        message.error({ content: updateResponse.errors[0].message });
      } else {
        message.success("Updated successfully");
      }
    } catch (ex: any) {
      message.error(ex);
    }
  };

  /// /// ******************************** //////////

  const goToSubmittalPage = () => {
    history.push(`/project/${projectId}/submittals`);
  };

  const getOnBehalfOfInfoObj = () => {
    let onBehalfOfInfoObj: any = null;
    if (isWorkflowInOverrideMode) {
      onBehalfOfInfoObj = {};
      if (updatedData?.assignee) {
        onBehalfOfInfoObj.on_behalf_of_user_id = updatedData.assignee;
      }
      if (updatedData?.assignee_unregistered) {
        onBehalfOfInfoObj.on_behalf_of = updatedData.assignee_unregistered;
      }
    }
    return onBehalfOfInfoObj;
  };

  const getLatestWFHistoryItem = (historyItems: any) => {
    if (!historyItems) return null;
    const historyArr = historyItems.filter(
      (historyItem: any) =>
        !(
          historyItem.action &&
          historyItem.action.toLowerCase().includes("deleted")
        )
    );
    if (historyArr && historyArr?.length > 0) {
      return historyArr[0];
    }
    return null;
  };

  const UploadAttachment = async ({ file, onSuccess }: any) => {
    let requestPayload = {
      feature: "SUBMITTALS",
      feature_id: submittalId,
      file_name: file.name
    };

    const onBehalfOfInfo = getOnBehalfOfInfoObj();
    if (onBehalfOfInfo) {
      requestPayload = { ...requestPayload, ...onBehalfOfInfo };
    }

    const historyItem = getLatestWFHistoryItem(
      dataSubmittalByPk?.submittal_status_history_details || []
    );

    const subHistoryObj: any = {};
    if (historyItem) {
      subHistoryObj.submittal_status_history_id = `${historyItem.id}`;
      requestPayload = {
        ...requestPayload,
        ...subHistoryObj
      };
    }
    const signedUrlRespose: any = await generateS3URL(
      requestPayload,
      tokenRetrievalState.token
    );

    if (signedUrlRespose.error) {
      message.error(signedUrlRespose.error.msg);
      return null;
    }

    const fileUploadResponse: any = await noAuthClient.put(
      `${signedUrlRespose.data.success.url}`,
      file,
      {
        onUploadProgress: (progressEvent) => {
          const progressCount = Math.ceil(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(progressCount);
          // if (setProgress) setProgress(progressCount);
        }
      }
    );
    if (fileUploadResponse.error) {
      message.error(signedUrlRespose.error.msg);
      return null;
      // reject(new Error(fileUploadResponse.error));
    }
    onSuccess("OK");
    return fileUploadResponse;
  };

  const RemoveAttachment = async (attachmentId: string) => {
    try {
      const updateResponse = await deleteAttachmentMutationPK({
        variables: {
          id: attachmentId,
          set: { deleted: true }
        }
      });

      if (updateResponse && updateResponse.data) {
        message.success("Removed successfully");
        if (paramAttachmentId) {
          history.replace(
            `/project/${projectId}/submittals/${submittalId}?tab=2`
          );
        }
        if (selectedDocument && selectedDocument?.id === attachmentId) {
          setSelectedDocument(undefined);
        }
      }
      if (updateResponse && updateResponse.errors) {
        message.error(updateResponse.errors[0].message);
      }
    } catch (ex: any) {
      message.error(ex.message);
    }
  };

  const findLatestAttachmentVersion = useCallback(
    (doc: AttachmentDoc) => {
      const latestVersion =
        doc.versions.length === 0 ? doc : doc.versions.slice(-1)[0];
      const lastRef = attachmentDocuments.referenceDocs.find(
        (d) => d.reference_id === latestVersion.id
      );
      return lastRef || latestVersion;
    },
    [attachmentDocuments.referenceDocs]
  );

  const revisionSelectHidden = useMemo(() => {
    return (
      isIntegrationMode &&
      projectIntegrationConfig &&
      projectIntegrationConfig[0].integration.system ===
        IntegrationType[IntegrationType.AUTODESK]
    );
  }, [isIntegrationMode, projectIntegrationConfig]);

  const revisionDropDown =
    !revisionSelectHidden && submittalRevisions.length ? (
      <Select
        style={{ width: 150 }}
        value={updatedData?.revision}
        onChange={(val: number) => {
          if (val === dataSubmittalByPk?.revision) {
            setRevisionMode(false);
            setUpdatedData(dataSubmittalByPk);
            const { attachments } = dataAllAttachments;
            setAllAttachements(attachments);
            setAttachmentDocuments({
              documents: attachments.filter(
                (x: AttachmentDoc) => !x.reference_id
              ),
              referenceDocs: attachments.filter(
                (y: AttachmentDoc) => y.reference_id
              )
            });

            const submittalStatus = getSubmittalStatus(
              dataSubmittalByPk?.submittal_status_history_details
            );
            setSubmittalViewState({
              userType: tokenContents!.userType,
              status: submittalStatus || ESubmittalStatus.CREATE
            });
          } else {
            const revision: any = submittalRevisions.find(
              (x) => x.revision === val
            );
            if (revision) {
              setRevisionMode(true);

              let revisionData = { ...revision };
              if (isIntegrationMode) {
                revisionData = updateSubmittalParticipantsForINTG(revisionData);
              }

              setUpdatedData(revisionData);
              // revision.submittal_attachments
              const attachments = revision.submittal_attachments?.filter(
                (x: any) => x.submittal_revision === val
              );
              setAllAttachements(attachments);
              setAttachmentDocuments({
                documents: attachments?.filter(
                  (x: AttachmentDoc) => !x.reference_id
                ),
                referenceDocs: attachments?.filter(
                  (y: AttachmentDoc) => y.reference_id
                )
              });

              const submittalStatus = getSubmittalStatus(
                revision?.submittal_status_history_details
              );
              setSubmittalViewState({
                userType: tokenContents!.userType,
                status: submittalStatus || ESubmittalStatus.CREATE
              });
              message.warn(ErrorMessages.RevisionMode);
            }
          }
        }}
        options={[
          {
            value: dataSubmittalByPk?.revision,
            label: `${submittalHeaderMap?.revision}: ${dataSubmittalByPk?.revision}`
          },
          ...submittalRevisions.map((x: { revision: number }) => {
            return {
              value: x.revision,
              label: `${submittalHeaderMap?.revision}: ${x.revision}`
            };
          })
        ]}
      />
    ) : null;

  useEffect(() => {
    if (
      dataAllAttachments &&
      attachmentDocuments.documents &&
      attachmentDocuments.documents.length &&
      paramAttachmentId
    ) {
      const foundDoc: any = attachmentDocuments.documents.find(
        (doc: any) => doc.id === paramAttachmentId
      );
      if (!foundDoc) return;
      const latest = findLatestAttachmentVersion(foundDoc);
      setSelectedDocument(latest);
    }
  }, [
    paramAttachmentId,
    dataAllAttachments,
    findLatestAttachmentVersion,
    attachmentDocuments.documents
  ]);

  const submittalTitlePanel = (
    <SubmittalTitlePanel
      projectId={projectId}
      revisionDropDown={revisionDropDown}
      submittalData={updatedData}
      projectDetails={projectDetails}
      editableFields={isRevisionMode ? [] : editableFields}
      updateSubmittalTitle={updateSubmittalTitle}
      tabIndex={tabIndex}
      isIntegrationMode={isIntegrationMode}
      projectParticipants={projectParticipants}
      dataSubmittalRevisions={dataSubmittalRevisions}
      dataSubmittalByPk={dataSubmittalByPk}
    />
  );

  const getTabItems = () => {
    const tabItems: any = [
      {
        label: "Submittal Details",
        key: "1",
        style: {
          opacity: hasCurrentAccess ? "1.0" : "0.5"
        },
        children: (
          <div className="submittal-details">
            {(isLoggedInUserGC && projectParticipants && updatedData) ||
            updatedData ? (
              <ErrorBoundary>
                <SubmittalDetails
                  gqlClientForProject={gqlClientForProject}
                  submittalHeaderMap={submittalHeaderMap}
                  submittalData={updatedData}
                  subscriptionSubmittalData={dataSubmittalByPk}
                  documents={attachmentDocuments.documents}
                  allAttachments={allAttachments}
                  submittalTypesOptions={
                    dataSubmittalTypes
                      ? dataSubmittalTypes.configurations_value
                      : []
                  }
                  updateSubmittalOne={(data: any) => {
                    return updateSubmittalOne(data, gqlClientForProject);
                  }}
                  updateSubmittalById={updateSubmittalById}
                  UploadAttachment={UploadAttachment}
                  selectAttachmentItem={setSelectedDocument}
                  setTabIndex={setTabIndex}
                  submittalViewState={submittalViewState}
                  submittalHistory={dataSubmittalByPk?.submittal_status_history_details.filter(
                    (x: any) => x.submittal_revision === updatedData.revision
                  )}
                  submittalWorkflowResponses={
                    submittalWorkflowResponses?.submittal_response || []
                  }
                  findLatestAttachmentVersion={findLatestAttachmentVersion}
                  projectDetails={projectDetails}
                  isRevisionMode={isRevisionMode}
                  submittalTitlePanel={submittalTitlePanel}
                  workflowOverride={{
                    isWorkflowInOverrideMode,
                    setIsWorkflowInOverrideMode
                  }}
                  projectParticipants={projectParticipants}
                  isIntegrationMode={isIntegrationMode}
                  refetchSubmittalPK={refetchSubmittalPK}
                />
              </ErrorBoundary>
            ) : (
              <div className="min-h-[80vh] flex items-center justify-center">
                <Spin size="large" />
              </div>
            )}
          </div>
        )
      }
    ];

    if (!isIntegrationMode) {
      tabItems.push({
        label: "Attachments",
        key: "2",
        className: "attachments",
        style: { opacity: hasCurrentAccess ? "1.0" : "0.5" },
        children: (
          <SubmittalAttachments
            editableFields={isRevisionMode ? [] : editableFields}
            documents={attachmentDocuments.documents}
            referenceDocuments={attachmentDocuments.referenceDocs}
            UploadAttachment={UploadAttachment}
            RemoveAttachment={RemoveAttachment}
            disabled={false}
            selectedDocument={selectedDocument}
            setSelectedDocument={(attachment: any) => {
              setSelectedDocument(attachment);
              if (paramAttachmentId) {
                history.replace(
                  `/project/${projectId}/submittals/${submittalId}?tab=2`
                );
              }
            }}
            findLatestAttachmentVersion={findLatestAttachmentVersion}
            submittalTitlePanel={submittalTitlePanel}
            isIntegrationMode={isIntegrationMode}
          />
        )
      });
    }
    return tabItems;
  };

  // useEffect(() => {
  //   // Logs for QA
  //   console.log({
  //     projectParticipants,
  //     submittalByPk: subSubmittalByPk,
  //     submittalViewState,
  //     submittalRevisions: dataSubmittalRevisions
  //   });
  // }, [
  //   projectParticipants,
  //   updatedData,
  //   submittalViewState,
  //   submittalRevisions,
  //   dataSubmittalRevisions,
  //   subSubmittalByPk
  // ]);

  return (
    <div className="w-full px-5 pb-6">
      <div className="w-full bg-transparent border-solid border-0 border-b-2 border-[#00000005] grid grid-cols-12 items-center h-[40px]">
        <div className="col-span-2 flex items-center justify-start">
          <Button
            icon={<ArrowLeftOutlined />}
            className="text-one text-black !bg-transparent !border-0 px-0 !shadow-none"
            onClick={goToSubmittalPage}
          >
            All Submittals
          </Button>
        </div>
      </div>
      <div className="relative">
        <Tabs
          onChange={(activeKey: any) => {
            setTabIndex(activeKey.toString());
            history.push(
              `/project/${projectId}/submittals/${submittalId}?tab=${activeKey}`
            );
          }}
          activeKey={tabIndex}
          items={getTabItems()}
          className="submittal-tabs"
        />
      </div>
    </div>
  );
}

export default SubmittalDetailspage;
