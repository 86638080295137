import { useEffect, useState } from "react";
import { Button } from "antd";
import { FeatureTypes } from "entity-app/constants";
import {
  TMilestonesTemplate,
  TSaveWorkflowModelInput,
  TWorkflowTemplate
} from "./model";
import "./index.scss";
import EditableInput from "./components/editable-input";
import MilestoneEditor from "./milestone-editor";
import { VRules } from "./validation-rules";
import {
  transformEditTemplateToSaveModel,
  transformMilestoneToEditMilestone
} from "./data-transformations";
import { ErrorMessages } from "../../constants";

function WorkflowTemplateEditor({
  workflowTemplate,
  milestones,
  disable = false,
  isTemplateUsed = false,
  openInEditMode = false,
  otherTemplateNames,
  onSave,
  featureId
}: {
  workflowTemplate: TWorkflowTemplate;
  milestones: Array<TMilestonesTemplate>;
  disable?: boolean;
  isTemplateUsed?: boolean;
  openInEditMode?: boolean;
  otherTemplateNames: Array<string>;
  onSave: (data: TSaveWorkflowModelInput) => Promise<void>;
  featureId?: FeatureTypes;
}) {
  const [editMode, setEditMode] = useState(openInEditMode);
  const editable = !editMode || disable || isTemplateUsed;
  const [workflowTemplateState, setWorkflowTemplate] =
    useState<TWorkflowTemplate>({
      name: workflowTemplate.name,
      id: workflowTemplate.id,
      feature_id: workflowTemplate.feature_id,
      float: 0
    });
  const [milestonesState, setMilestones] = useState(
    transformMilestoneToEditMilestone(milestones)
  );

  const [float, setFloat] = useState<number>(workflowTemplate.float);

  const onRenameTemplateName = (name: string) => {
    setWorkflowTemplate((pre) => ({ ...pre, name }));
  };

  useEffect(() => {
    if (milestones && !editMode)
      setMilestones(transformMilestoneToEditMilestone(milestones));
  }, [milestones, editMode]);

  useEffect(() => {
    if (workflowTemplate && !editMode)
      setWorkflowTemplate({
        name: workflowTemplate.name,
        id: workflowTemplate.id,
        feature_id: workflowTemplate.feature_id,
        float: 0
      });
    setFloat(workflowTemplate.float);
  }, [workflowTemplate, editMode]);

  const [saveLoading, setSaveLoading] = useState(false);
  const onSaveButtonClick = async () => {
    try {
      setSaveLoading(() => true);
      const saveData = transformEditTemplateToSaveModel(
        workflowTemplateState.id,
        workflowTemplateState.name,
        workflowTemplateState.feature_id,
        milestonesState,
        float
      );

      await onSave(saveData);
    } finally {
      setSaveLoading(false);
      setEditMode(false);
    }
  };

  const onCancelButtonClick = () => {
    setMilestones(transformMilestoneToEditMilestone(milestones));
    setWorkflowTemplate({ ...workflowTemplate });
    setEditMode(false);
  };

  const onEditButtonClick = () => setEditMode(true);

  return (
    <div className="w-full bg-white border border-solid  workflow-template-editor">
      <div className="flex items-center w-full p-2 justify-between border-0 border-b border-solid border-inherit">
        <EditableInput
          className="font-medium text-base"
          classNameEdit="font-medium text-base"
          input={workflowTemplateState.name}
          onSubmit={onRenameTemplateName}
          rules={VRules.TemplateTitle(otherTemplateNames)}
          disable={editable}
        />
        {editMode ? (
          <div className="flex items-center space-x-2">
            <Button
              type="primary"
              className="w-24"
              onClick={onSaveButtonClick}
              loading={saveLoading}
            >
              Save
            </Button>
            <Button
              className="w-24"
              onClick={onCancelButtonClick}
              disabled={saveLoading}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Button
              type="primary"
              className="w-24"
              disabled={disable || isTemplateUsed}
              title={
                // eslint-disable-next-line no-nested-ternary
                disable
                  ? ErrorMessages.PermissionNotGranted
                  : isTemplateUsed
                  ? ErrorMessages.templateAlreadyUsed
                  : ""
              }
              onClick={onEditButtonClick}
            >
              Edit
            </Button>
          </div>
        )}
      </div>
      <div className="w-full p-1 overflow-x-auto">
        <div className="flex w-full mr-60">
          {milestonesState.map((t, index) => (
            <MilestoneEditor
              allMilestones={milestonesState}
              setMilestones={setMilestones}
              key={t.id}
              index={index}
              milestone={t}
              disable={editable}
              floatState={{ float, setFloat }}
              featureId={featureId}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default WorkflowTemplateEditor;
