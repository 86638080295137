import { AgGridReact } from "ag-grid-react";
import { Button, notification } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import CiqGridViewWrapper from "components/ciq-grid-view-wrapper";
import { useCallback, useContext, useRef, useState } from "react";
import { calculateLogPageStats } from "components/ciq-log-page-header";
import { newEntityNotificationMessage } from "entity-app/shared-components/new-entity-notification-message";
import { useParams } from "react-router";
import {
  FilterChipComponent,
  FilterChipComponentRef
} from "entity-app/shared-components/log-render-components/filter-chip-component";
import { AppContext } from "context/AppProvider";
import { applyBidPackagesLogFilters } from "utils/ag-grid-programmatic-filtering";
import { resetGridFilters } from "entity-app/utils/utils";
import { FeatureTypes } from "entity-app/constants";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import { exportEntityLogAsCSV } from "entity-app/utils/export-entity-log-as-csv";

import {
  EFeaturePageId,
  ErrorMessages,
  ProjectPermissionEnum
} from "constants/index";
import { UtilColumnConfig } from "components/column-config-views/column-config-util";
import ColumnConfigViews from "components/column-config-views";
import { usePreconstrutionData } from "./hooks/preconstruction-data";
import { usePreconstrutionAgGrid } from "./hooks/preconstruction-ag-grid-data";
import CreateBidPackageComponent from "./components/create-bid-package";

const featureKey = "bid-packages"; // URL

function PreconstructionLogPage() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const { projectDetails, tokenContents } = useContext(ProjectContext);

  const pageData = usePreconstrutionData({ gridRef });
  const agGridData = usePreconstrutionAgGrid({
    featureKey,
    milestonesColumns: pageData.milestonesColumns
  });
  const filterChipRef = useRef<FilterChipComponentRef>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { projectId } = useParams() as any;
  const { bidPackageListFilter, setBidPackageListFilter } =
    useContext(AppContext);

  const [isFirstDataRendered, setIsFirstDataRendered] = useState(false);
  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();

  const openNotification = (id: string, title: string) => {
    api.open(
      newEntityNotificationMessage({
        projectId,
        id,
        title,
        featureKey
      })
    );
  };

  const onFirstDataRendered = useCallback(() => {
    if (bidPackageListFilter) {
      applyBidPackagesLogFilters({ gridRef, bidPackageListFilter });
    }
    if (
      pageData.savedColumns?.bid_package_column_config &&
      bidPackageListFilter === null &&
      gridRef.current
    ) {
      UtilColumnConfig.setFilterStateFromData({
        columnStates: pageData.savedColumns?.bid_package_column_config,
        gridRef: gridRef.current
      });
    }
    pageData.calculateMaxNumberOfMilestone();

    setTimeout(() => {
      // NOTE: To load initial data once in onFilterApplied method
      setIsFirstDataRendered(true);
    }, 2000);

    gridRef.current?.api.onFilterChanged();
  }, [bidPackageListFilter, pageData]);

  const cannotWriteAccessOfBidPackage = isPermissionNotGrantted(
    ProjectPermissionEnum.BidPackageWrite,
    tokenContents?.role!
  );

  const headerItems = () => {
    const onAllBidPackagesClick = () => {
      resetGridFilters({
        setListFilter: setBidPackageListFilter,
        api: gridRef.current?.api
      });
    };

    const menus = [
      <CiqAgSearch key="PreconstructionLogPage_search" gridRef={gridRef} />,

      <ColumnConfigViews
        key="PreconstructionLogPage_ColumnConfigViews"
        featureId={EFeaturePageId.BID_PACKAGE}
        setFilterStateFromData={(columnStates) => {
          if (gridRef.current)
            UtilColumnConfig.setFilterStateFromData({
              columnStates,
              gridRef: gridRef.current
            });
        }}
        getColumnStateFromGrid={() => {
          if (gridRef.current) {
            return UtilColumnConfig.getColumnStateFromGrid({
              gridRef: gridRef.current
            });
          }
          return null;
        }}
        afterApplyFilterCallBack={pageData.calculateMaxNumberOfMilestone}
      />,
      <Button
        key="PreconstructionLogPage_new"
        disabled={cannotWriteAccessOfBidPackage}
        title={
          cannotWriteAccessOfBidPackage
            ? ErrorMessages.PermissionNotGranted
            : "Create New Bid Package"
        }
        onClick={() => {
          setShowDrawer(!showDrawer);
        }}
      >
        New Bid Package
      </Button>,
      <Button key="PreconstructionLogPage_importLog" disabled>
        Import Log
      </Button>,
      <Button
        key="PreconstructionLogPage_export"
        placeholder="Export Bid Package Log"
        onClick={() => {
          if (projectDetails && gridRef.current)
            exportEntityLogAsCSV({
              entityName: "Bid Package Log",
              projectDetails,
              gridRef: gridRef.current,
              includedColumns: ["description"],
              excludedColumns: ["_milestones", "_durations"]
            });
        }}
        disabled={!pageData.bidPackageList?.bid_package_list_view}
      >
        Export
      </Button>
    ];

    if (bidPackageListFilter) {
      menus.splice(
        1,
        0,
        <Button
          key="PreconstructionLogPage_AllBidPackages"
          onClick={() => onAllBidPackagesClick()}
        >
          All Bid Packages
        </Button>
      );
    } else {
      menus.splice(
        1,
        0,
        <FilterChipComponent
          key="PreconstructionLogPage_FilterChip"
          columnDefs={agGridData.columnDefs}
          gridRef={gridRef}
          ref={filterChipRef}
        />
      );
    }

    return menus;
  };

  return (
    <>
      {contextHolder}
      <CiqGridViewWrapper
        headerInfo={{
          entityName: "PreconstructionLogPage",
          titleParam: {
            title: "Bid Packages",
            totalCount: pageData.bidPackageList?.bid_package_list_view?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems()
        }}
        gridInfo={{
          gridRef,
          columnDefs: agGridData.columnDefs,
          rowData: pageData.bidPackageList?.bid_package_list_view,
          defaultColDef: agGridData.defaultColDef,
          rowHeight: 62,
          onFirstDataRendered,
          onSortChanged: () => {
            if (isFirstDataRendered && !bidPackageListFilter)
              pageData.onSaveColumnState();
          },
          onFilterChanged: () => {
            filterChipRef.current?.onFilterApplied();
            setStatsData(
              calculateLogPageStats({
                gridRef,
                ListFilter: bidPackageListFilter,
                featureTypeId: FeatureTypes.BID_PACKAGE
              })
            );
            if (isFirstDataRendered && !bidPackageListFilter)
              pageData.onSaveColumnState();
          },
          onColumnVisible: (e) => {
            if (e.columnApi && e.source === "toolPanelUi") {
              if (e.column) {
                agGridData.milestonesColumns.onColumnVisible(
                  e,
                  pageData.maxNumberOfMilestone
                );
              } else {
                agGridData.milestonesColumns.updateAllMileStoneInitialVisibility(
                  e.columnApi,
                  pageData.maxNumberOfMilestone
                );
              }
              pageData.onSaveColumnState();
            }
          },
          onDragStopped: (e) => {
            const classNames = [
              "ag-header-cell-resize",
              "ag-tool-panel-horizontal-resize"
            ];
            if (e.columnApi && !classNames.includes(e.target.className)) {
              pageData.onSaveColumnState();
            }
          }
        }}
      />
      {showDrawer && (
        <CreateBidPackageComponent
          setDrawerOpen={setShowDrawer}
          showDrawerOpen={showDrawer}
          modelTitle="Create Bid Package"
          onBidPackageCreated={(title: string, id: string) => {
            setShowDrawer(false);
            openNotification(id, title);
            pageData.refetchBidPackageList();
          }}
        />
      )}
    </>
  );
}

export default PreconstructionLogPage;
