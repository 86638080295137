import { Button, Form, Input, Typography, message as messages } from "antd";
import { AppLogoIcon } from "components/svg-icons";
import { ErrorMessages } from "constants/index";
import FormItem from "antd/es/form/FormItem";
import { ForgetPasswordRequest } from "models/user";
import { useState } from "react";
import { forgetpassword } from "services/auth";
import { useHistory } from "react-router";

function ForgetPassword() {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const onFinish = async (event: ForgetPasswordRequest) => {
    setLoader(true);
    const result: any = await forgetpassword({
      email: event.email
    });

    setLoader(false);
    if (result.status >= 200 && result.status <= 204) {
      setLoader(false);
      messages.success(ErrorMessages.EmailSentMsg);
      history.push("/");
    } else {
      setLoader(false);
      if (result.response?.data.error === undefined) {
        messages.error("Error occured..");
      } else {
        messages.error(result.response?.data.error);
      }
    }

    form.resetFields();
  };

  return (
    <div className="flex min-w-full min-h-screen bg-[#f7f6f4] items-center justify-center p-2">
      <div className="flex flex-col p-8 shadow-[0px_5px_25px_rgba(0,0,0,0.1)] max-w-[400px] rounded-md">
        <div className="m-auto block">
          <AppLogoIcon />
        </div>
        <div className="flex justify-center">
          <Title level={3}>Forgot Password</Title>
        </div>
        <Form
          className="space-y-3"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <FormItem
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
                message: ErrorMessages.EmailRequired
              },
              {
                type: "email",
                validateTrigger: "onSubmit",
                message: ErrorMessages.EmailRequired
              }
            ]}
          >
            <Input placeholder="Enter Email" />
          </FormItem>
          <div style={{ paddingTop: "1em" }}>
            <Button
              htmlType="submit"
              size="middle"
              style={{ margin: "auto", display: "block" }}
              loading={loader}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
export default ForgetPassword;
