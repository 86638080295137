import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription
} from "@apollo/client";
import { Button, Form, Input, Select, message, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import SelectField from "components/submittal-details/select-field";
import { ErrorMessages, Regex, UserProjectRoles } from "constants/index";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { useUserProfileInfo } from "hooks/users";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser } from "services/auth";
import {
  MUTATION_INSERT_COMPANY_TO_PROJECT,
  MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION,
  MUTATION_INSERT_USERS_TO_USER_SUBSCRIPTION
} from "services/graphQL/mutations";
import {
  QUERY_GET_USER_TYPE_BY_KEY,
  QUERY_TRADE_USER_TYPE
} from "services/graphQL/queries";
import {
  SUBSCRIPTION_COMPANY_LIST,
  SUBSCRIPTION_PROJECT_ROLES,
  SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
  SUBSCRIPTION_USERS_ACTIVE
} from "services/graphQL/subscriptions";

const { Option } = Select;

export type InviteUserAction = {
  isFromProjectUser: boolean;
  projectID?: string;
  existingUsers: Array<any>;
  setDrawerOpen: Function;
  vendorData?: any;
  showDrawerOpen: boolean;
  modelTitle: string;
};

export type Role = {
  id: number;
  name: string;
};

function InviteSubscriptionUserComponent(props: InviteUserAction) {
  const {
    isFromProjectUser,
    projectID,
    existingUsers,
    setDrawerOpen,
    vendorData,
    showDrawerOpen,
    modelTitle
  } = props;

  const { tokenContents, gqlClientForProject }: TProjectContext =
    useContext(ProjectContext);

  const [form] = Form.useForm();

  const companyFieldValue = Form.useWatch("company", form);
  const companyTypeFieldValue = Form.useWatch("company_type", form);

  const [projectRoles, setprojectRoles] = useState<Role[]>([]);
  const [allUsers, setfetchedUsers] = useState<any>([]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    company: "0"
  });

  const [companies, setCompanies] = useState<any>([]);

  const [pocUsers, setPOCUsers] = useState<any>([]);

  const [userTypeId, setUserTypeId] = useState<Number>();

  const [currentUser]: [any, any] = useState(getUser());

  const [isReadOnly, setIsReadOnly] = useState(false);

  const { data: loggedInUserData } = useUserProfileInfo(currentUser.email);

  const { data: usersData, loading } = useSubscription(
    SUBSCRIPTION_USERS_ACTIVE,
    {
      shouldResubscribe: true
    }
  );

  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const { subscriptionRole } = useContext(SubscriptionContext);

  const { data: projectSubscriptionRoles, loading: projectRolesloading } =
    useSubscription(SUBSCRIPTION_PROJECT_ROLES, {
      shouldResubscribe: true,
      variables: {
        where: { type_id: { _eq: userTypeId } }
      },
      skip: !userTypeId
    });

  const { data: companyData, loading: companyDataLoading } = useSubscription(
    SUBSCRIPTION_COMPANY_LIST,
    isFromProjectUser
      ? {
          shouldResubscribe: true,
          skip: !gqlClientForProject,
          client: gqlClientForProject
        }
      : { shouldResubscribe: true }
  );

  const [addCompanyToProject] = useMutation<any>(
    MUTATION_INSERT_COMPANY_TO_PROJECT,
    {
      client: gqlClientForProject
    }
  );

  const [projectVendors, setProjectVendors] = useState<Array<any>>([]);

  useEffect(() => {
    if (companyData && companyData?.subscription_vendors) {
      const subscriptionVendors = companyData?.subscription_vendors || [];

      const loggedInUserSubsription =
        loggedInUserData?.subscription_users?.length > 0
          ? loggedInUserData?.subscription_users[0]?.subscription
          : null;

      const orgName =
        loggedInUserSubsription &&
        loggedInUserSubsription?.organization_subscriptions?.length > 0
          ? loggedInUserSubsription?.organization_subscriptions[0]?.organization
              ?.name
          : "";

      const loggedInUserCompany = {
        id: "0",
        name: orgName
      };

      if (isFromProjectUser) {
        const vendors =
          subscriptionVendors.filter(
            (x: any) => x.project_vendors.length > 0
          ) || [];
        setProjectVendors([loggedInUserCompany, ...vendors]);
      }

      let allCompanies = [loggedInUserCompany, ...subscriptionVendors];

      try {
        allCompanies = [loggedInUserCompany, ...subscriptionVendors].sort(
          (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        if (!isFromProjectUser) setProjectVendors(allCompanies);
      } catch (ex) {
        //
      }

      setCompanies(allCompanies);
    }
  }, [companyData, isFromProjectUser, loggedInUserData?.subscription_users]);

  const [queryUserType] = useLazyQuery(QUERY_GET_USER_TYPE_BY_KEY);
  const { data: userTypes } = useQuery(QUERY_TRADE_USER_TYPE);

  useEffect(() => {
    if (tokenContents) {
      queryUserType({
        variables: { key: tokenContents.userType }
      }).then((res) => {
        if (res.data) {
          setUserTypeId(res.data.user_types[0].id);
        }
      });
    }
  }, [queryUserType, tokenContents]);

  useEffect(() => {
    if (projectSubscriptionRoles) {
      setprojectRoles(projectSubscriptionRoles.project_role);
    }
  }, [projectSubscriptionRoles]);

  useEffect(() => {
    if (usersData && existingUsers && isFromProjectUser && companyFieldValue) {
      const remainingSubscriptionUsers: Array<any> =
        usersData?.subscription_users
          ?.filter(
            (u: any) =>
              !existingUsers.some((e) => e.user.email === u.user.email)
          )
          .filter((usr: any) => {
            if (companyFieldValue === "0") {
              return !usr.vendor_id;
            }
            return usr.vendor_id === companyFieldValue;
          });

      const selectedCompany = companyData?.subscription_vendors?.find(
        (cmpny: any) => cmpny.id === companyFieldValue
      );

      const remainingPocUsers: Array<any> = selectedCompany?.trade_partner_pocs
        ?.filter((u: any) => !existingUsers.some((e) => e.user.id === u.id))
        .map((usr: any) => {
          return {
            user: {
              id: usr.id,
              email: usr.email,
              first_name: usr.first_name,
              last_name: usr.last_name
            },
            subscription_vendor: { name: selectedCompany?.name }
          };
        });

      setfetchedUsers([
        ...remainingSubscriptionUsers,
        ...(remainingPocUsers || [])
      ]);
    }
  }, [
    existingUsers,
    isFromProjectUser,
    usersData,
    companyFieldValue,
    companyData
  ]);

  const [addUserToSubscription] = useMutation<any>(
    MUTATION_INSERT_USERS_TO_USER_SUBSCRIPTION
  );

  const [addUserProjectSubscription] = useMutation<any>(
    MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION,
    {
      client: gqlClientForProject
    }
  );

  const { data: userSubscriptionRoles } = useSubscription(
    SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
    {
      shouldResubscribe: true
    }
  );

  useEffect(() => {
    if (userSubscriptionRoles) {
      const subscriptionPermission =
        userSubscriptionRoles?.subscription_permission?.filter(
          (role: any) => role.key !== "subscription_none"
        ); // Remove None permission for editor

      if (subscriptionRole <= RoleSubscriptionEnum.subscription_editor) {
        const roles = subscriptionPermission?.filter(
          (role: any) => role.name !== "Admin"
        );
        setUserRoles(roles);
      } else {
        setUserRoles(subscriptionPermission);
      }
    }
  }, [subscriptionRole, userSubscriptionRoles]);

  useEffect(() => {
    if (!isFromProjectUser) {
      const userRole = userRoles?.find(
        (role: any) => role?.key === "subscription_viewer"
      );
      form.setFieldValue("subscription_permission_id", userRole?.id || "");
    }
  }, [form, isFromProjectUser, userRoles]);

  useEffect(() => {
    if (companies?.length > 0) {
      form.setFieldValue("company", "0");
    }
  }, [companies, form]);

  // eslint-disable-next-line consistent-return
  const onSendInvite = async (values: any) => {
    const userMap: any = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name
    };
    if (values.phone) {
      userMap.phone = values.phone;
    }
    if (isFromProjectUser) {
      userMap.project_role_id = values.project_role_id;
      userMap.user_type_id = userTypeId;
      if (userMap.user_type_id !== 1) {
        userMap.external = true;
        if (vendorData)
          userMap.company_id =
            vendorData.project_users[0].subscription_vendor.id;
      } else {
        userMap.subscription_role_id = 3; // subscription_viewer
      }
    } else {
      userMap.subscription_permission = values.subscription_permission_id; // subscription_viewer
    }
    if (values.company !== "0") {
      userMap.company_id = values.company;
      if (isFromProjectUser) {
        const compData: any = {
          name: companies.find((c: any) => c.id === values.company).name,
          company_type: values.company_type
        };
        setResponseLoading(true);
        try {
          const addCompanyResponse: any = await addCompanyToProject({
            variables: { companyData: [compData], projectId: projectID }
          });
          if (addCompanyResponse.errors) {
            message.error(addCompanyResponse.errors[0].message);
            setResponseLoading(false);
            return;
          }
        } catch (ex) {
          message.error(ex);
          return;
        }
      }
    }
    const requestedUsers = [userMap];
    setResponseLoading(true);
    if (isFromProjectUser) {
      try {
        const addUserProjectSubscriptionResponse =
          await addUserProjectSubscription({
            variables: { users: requestedUsers, projectId: projectID }
          });
        setResponseLoading(false);
        if (
          addUserProjectSubscriptionResponse?.errors &&
          addUserProjectSubscriptionResponse?.errors.length > 0
        ) {
          message.error(addUserProjectSubscriptionResponse.errors[0]?.message);
          return;
        }
        setDrawerOpen(false);

        if (
          addUserProjectSubscriptionResponse?.data?.insert_project_users_multi
            ?.message
        ) {
          message.success(
            addUserProjectSubscriptionResponse?.data?.insert_project_users_multi
              ?.message
          );
        } else {
          message.error("Unable to invite user to project.");
        }
      } catch (ex) {
        console.log(ex);
        setResponseLoading(false);
        message.error(ex);
      }
    } else {
      try {
        const addUserToSubscriptionResponse = await addUserToSubscription({
          variables: { users: requestedUsers }
        });
        setResponseLoading(false);
        if (
          addUserToSubscriptionResponse?.errors &&
          addUserToSubscriptionResponse?.errors.length > 0
        ) {
          message.error(addUserToSubscriptionResponse.errors[0]?.message);
          return;
        }

        setDrawerOpen(false);
        if (
          addUserToSubscriptionResponse?.data?.insert_subscription_users_multi
            ?.message
        ) {
          message.success(
            addUserToSubscriptionResponse?.data?.insert_subscription_users_multi
              ?.message
          );
        } else {
          message.error("Unable to invite user.");
        }
      } catch (ex) {
        console.log(ex);
        setResponseLoading(false);
        message.error(ex);
      }
    }
  };

  function resetForm() {
    form.resetFields();
  }

  function resetUsersInfoFields() {
    form.resetFields([
      "select_user",
      "first_name",
      "last_name",
      "email",
      "phone",
      "project_role_id"
    ]);
    setIsReadOnly(false);
  }

  const GCSCProjectRoleKeys = (projectRoles || [])
    .filter((role: any) => {
      return role.key.includes("gc_sc");
    })
    .map((role: any) => {
      return role.key;
    });

  function setSelectedPoc(userdata: any) {
    const fields: any = [
      {
        name: "first_name",
        value: userdata?.first_name || ""
      },
      {
        name: "last_name",
        value: userdata?.last_name || ""
      },
      {
        name: "email",
        value: userdata?.email || ""
      },
      {
        name: "phone",
        value: userdata?.phone || ""
      }
    ];

    form.setFields(fields);
  }

  function setPocUsersOfSelectedCompany(company_id: string) {
    const selectedCompany = companyData.subscription_vendors.find(
      (cmpny: any) => cmpny.id === company_id
    );
    if (selectedCompany?.trade_partner_pocs?.length > 0) {
      const remainingUsers: Array<any> =
        selectedCompany.trade_partner_pocs.filter(
          (u: any) => !existingUsers.some((e) => e.user.email === u.email)
        );

      if (remainingUsers.length > 0) {
        setPOCUsers(remainingUsers);
        const userData = remainingUsers[0];
        setSelectedPoc(userData);
        form.setFieldValue(
          "select_poc_user",
          `${userData.first_name} ${userData.last_name}`
        );
      } else {
        setSelectedPoc({});
        setPOCUsers([]);
      }
    } else {
      setSelectedPoc({});
      setPOCUsers([]);
    }
  }

  return (
    <Modal
      className="custom-drawer"
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div className="flex-col h-full overflow-y overflow-x-hidden px-3">
        <Form
          preserve={false}
          onFinish={onSendInvite}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <FormItem name="company" label="Company">
            <Select
              loading={companyDataLoading}
              disabled={companyDataLoading}
              onChange={(value: string) => {
                if (!isFromProjectUser) {
                  setPocUsersOfSelectedCompany(value);
                } else {
                  resetUsersInfoFields();
                }
                const companyDetails = projectVendors?.find(
                  (x: any) => x.id === value
                );
                if (companyDetails) {
                  form.setFieldsValue({
                    company_type: companyDetails?.project_vendors?.[0]?.type_id,
                    project_role_id: null
                  });
                } else {
                  form.setFieldsValue({
                    company_type: null,
                    project_role_id: null
                  });
                }
              }}
              showSearch
              filterOption={(input, option: any) => {
                const cmpny: any = companies.find(
                  (c: any) => c.id === option.key
                );

                if (!cmpny) return false;
                const inputMes = input.toString().toLowerCase();
                const companyDt = [cmpny?.name?.toLowerCase()];
                return companyDt?.some((text) => text.includes(inputMes));
              }}
            >
              {projectVendors.map((company: any) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          {isFromProjectUser &&
          companyFieldValue &&
          companyFieldValue !== "0" ? (
            <FormItem
              name="company_type"
              label="Role of Company"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.CompanyProjectRole,
                  validator(_, value: string) {
                    if (!value) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Select
                loading={projectRolesloading}
                disabled
                onChange={() => {
                  form.setFieldsValue({
                    project_role_id: null
                  });
                }}
              >
                {userTypes &&
                  userTypes.user_types.map((type: Role) => (
                    <Option key={type.id} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
              </Select>
            </FormItem>
          ) : null}
          {!isFromProjectUser && pocUsers?.length > 1 && (
            <FormItem label="Select user" name="select_poc_user">
              <SelectField
                placeholder="Select User"
                className="flex w-full font-normal text-slate-700"
                onChange={(index: any) => {
                  const userdata = pocUsers[index];
                  setSelectedPoc(userdata);
                }}
                // ref={selectRef}
                showSearch
                allowClear
                onClear={() => resetForm()}
                filterOption={(input, option: any) => {
                  const user = pocUsers.find((u: any) => u.id === option.key);
                  const inputMes = input.toString().toLowerCase();
                  const userData = [
                    user.email.toLowerCase(),
                    `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`
                  ];
                  return userData.some((text) => text.includes(inputMes));
                }}
                title=""
                loading={loading}
                value={
                  selectedUser
                    ? `${selectedUser.first_name} ${selectedUser.last_name}`
                    : ""
                }
              >
                {pocUsers.map((item: any, index: number) => {
                  return (
                    <Option key={item.id} value={index}>
                      <div>
                        {item.first_name} {item.last_name}
                      </div>
                      <div className="text-xs text-slate-500">{item.email}</div>
                    </Option>
                  );
                })}
              </SelectField>
            </FormItem>
          )}
          {isFromProjectUser && allUsers?.length > 0 && (
            <FormItem label="Select user" name="select_user">
              <SelectField
                placeholder="Select user"
                className="flex w-full font-normal text-slate-700"
                onChange={(index: any) => {
                  if (index === undefined) {
                    setSelectedUser({
                      email: "",
                      phone: "",
                      first_name: "",
                      last_name: "",
                      company: "0"
                    });
                    return;
                  }
                  const userdata = allUsers[index];
                  const vendorId = userdata.vendor_id || "0";
                  setSelectedUser({
                    email: userdata.user.email,
                    phone: userdata.user.phone,
                    first_name: userdata.user.first_name,
                    last_name: userdata.user.last_name,
                    company: vendorId
                  });

                  const fields: any = [
                    {
                      name: "first_name",
                      value: userdata.user.first_name
                    },
                    {
                      name: "last_name",
                      value: userdata.user.last_name
                    },
                    {
                      name: "email",
                      value: userdata.user.email
                    },
                    {
                      name: "phone",
                      value: userdata.user.phone
                    }
                  ];

                  form.setFields(fields);

                  if (Object.keys(userdata).length > 0) {
                    setIsReadOnly(true);
                  } else {
                    setIsReadOnly(false);
                  }
                }}
                // ref={selectRef}
                showSearch
                allowClear
                onClear={() => resetUsersInfoFields()}
                filterOption={(input, option: any) => {
                  const { user } = allUsers.find(
                    (u: any) => u.user_id === option.key
                  );
                  const inputMes = input.toString().toLowerCase();
                  const userData = [
                    user.email.toLowerCase(),
                    `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`
                  ];
                  return userData.some((text) => text.includes(inputMes));
                }}
                notFoundContent={
                  vendorData ? (
                    <span>
                      No user found. Click <Link to="/users">here</Link> to add
                      users.
                    </span>
                  ) : (
                    <span>No user found, fill the below fields.</span>
                  )
                }
                title=""
                loading={loading}
                value={
                  selectedUser
                    ? `${selectedUser.first_name} ${selectedUser.last_name}`
                    : ""
                }
              >
                {allUsers.map((item: any, index: number) => {
                  return (
                    <Option key={item.user_id} value={index}>
                      <div>
                        {item.user.first_name} {item.user.last_name}
                      </div>
                      <div className="text-xs text-slate-500">
                        {item.user.email}
                      </div>
                      <div className="text-xs text-slate-500">
                        {item?.subscription?.organization_subscriptions
                          ?.length > 0 && !item?.subscription_vendor
                          ? item?.subscription?.organization_subscriptions[0]
                              ?.organization?.name
                          : ""}
                        {item?.subscription_vendor?.name && (
                          <span> {item?.subscription_vendor?.name}</span>
                        )}
                      </div>
                    </Option>
                  );
                })}
              </SelectField>
            </FormItem>
          )}
          {isFromProjectUser && !vendorData && (
            <div className="flex text-sm text-[#3b3b3b] bg-[#F5F5F5] h-8 items-center -mx-3 pl-3 my-2">
              OR ADD USER DETAILS
            </div>
          )}
          <div>
            <FormItem
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.FirstName
                }
              ]}
            >
              <Input disabled={isReadOnly || vendorData} />
            </FormItem>
            <FormItem
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.LastName
                }
              ]}
            >
              <Input disabled={isReadOnly || vendorData} />
            </FormItem>
            <FormItem
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailRequired
                },
                {
                  type: "email",
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailInvalid
                },
                {
                  validateTrigger: "onSubmit",
                  validator(_, value: string) {
                    if (value === undefined || !value) return Promise.resolve();
                    const email = value.toLowerCase();
                    if (existingUsers.some((e) => e.user.email === email))
                      return Promise.reject();
                    return Promise.resolve();
                  },
                  message: isFromProjectUser
                    ? ErrorMessages.EmailProjectUserExist
                    : ErrorMessages.EmailAlreadyExist
                }
              ]}
            >
              <Input
                disabled={
                  form.getFieldValue("email") &&
                  form.getFieldValue("email") !== ""
                }
              />
            </FormItem>
            <FormItem
              name="phone"
              label="Phone Number"
              rules={[
                {
                  message: ErrorMessages.PhoneNumber,
                  validateTrigger: "onSubmit",
                  pattern: Regex.phoneNumber
                }
              ]}
            >
              <Input disabled={isReadOnly || vendorData} />
            </FormItem>
            {isFromProjectUser && (
              <FormItem
                name="project_role_id"
                label="Project Role"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.ProjectRole,
                    validator(_, value: string) {
                      if (!value) {
                        return Promise.reject();
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Select
                  loading={projectRolesloading}
                  disabled={
                    companyFieldValue &&
                    companyFieldValue !== "0" &&
                    !companyTypeFieldValue
                  }
                >
                  {projectRoles.length > 0 &&
                    projectRoles
                      .filter((role: any) => {
                        if (
                          companyFieldValue &&
                          companyFieldValue !== "0" &&
                          companyTypeFieldValue
                        ) {
                          try {
                            return UserProjectRoles[
                              companyTypeFieldValue
                            ]?.includes(role.key);
                          } catch (ex) {
                            return false;
                          }
                        }
                        return true;
                      })
                      .filter((role: any) => {
                        if (companyFieldValue && companyFieldValue !== "0") {
                          return GCSCProjectRoleKeys.includes(role.key);
                        }
                        return !GCSCProjectRoleKeys.includes(role.key);
                      })
                      .map((role: Role) => (
                        <Option key={role.id} value={role.id}>
                          {role.name}
                        </Option>
                      ))}
                </Select>
              </FormItem>
            )}
            {!isFromProjectUser && (
              <FormItem
                name="subscription_permission_id"
                label="Permission"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.SubscriptionRole,
                    validator(_, value: string) {
                      if (!value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Select style={{ width: "100%" }}>
                  {userRoles?.length > 0 &&
                    userRoles?.map((role: Role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              htmlType="submit"
              disabled={responseLoading}
              loading={responseLoading}
              type="primary"
            >
              Send Invite
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default InviteSubscriptionUserComponent;
