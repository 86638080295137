import { Form, Input, Button, Typography, Carousel, Select, Modal } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { useCallback, useRef, useState } from "react";
import "./login.css";
import { useHistory, useLocation } from "react-router";
import { login, setAccessToken, setCurrentSubscriptionId } from "services/auth";
import { ErrorMessages } from "constants/index";
import { AppLogoIcon } from "components/svg-icons";
import { LoginRequest } from "models/user";

const { Title } = Typography;

function Login() {
  const history = useHistory();
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get("redirectUrl");

  const [loader, setLoader] = useState(false);
  const [loginForm] = Form.useForm();
  const [subscriptionForm] = Form.useForm();

  const [loginInfo, setloginInfo] = useState<any>(null);
  const [userSubscriptions, setUserSubscriptions] = useState<any[]>([]);

  const slider: any = useRef();

  const onLoginFinish = useCallback(
    async (event: LoginRequest) => {
      setLoader(true);
      const res: any = await login({
        email: event.email,
        password: event.password
      });

      if (res.status === 200) {
        setAccessToken(res.data.success.token);
        setloginInfo(res.data.success);
        if (res.data.success.associated_subscriptions.length === 1) {
          // single subscriptions
          setCurrentSubscriptionId(
            res.data.success.associated_subscriptions[0].id
          );
          if (redirectUrl) history.push(redirectUrl);
          else history.push("/projects");
          return;
        }
        loginForm.resetFields();
        setUserSubscriptions(res.data.success.associated_subscriptions);
        subscriptionForm.setFieldsValue({
          subscriptionId: res.data.success.associated_subscriptions[0].id
        });
        setLoader(false);
        slider.current.next();
      } else {
        setLoader(false);
        console.log(res);
        Modal.error({ content: res.response?.data?.error || res.message });
      }
    },
    [history, loginForm, redirectUrl, subscriptionForm]
  );

  return (
    <div className="login">
      <div className="login-form">
        <div style={{ margin: "auto", display: "block" }}>
          <AppLogoIcon />
        </div>
        <div className="login-logo">
          <Title level={3} className="mt-3">
            Login
          </Title>
        </div>
        <Carousel
          dots={false}
          afterChange={() => {}}
          ref={(ref) => {
            slider.current = ref;
          }}
          speed={250}
          infinite={false}
          swipe={false}
          swipeToSlide={false}
          draggable={false}
        >
          <div className="px-1">
            <Form
              onFinish={onLoginFinish}
              form={loginForm}
              labelCol={{ span: 50 }}
              wrapperCol={{ span: 30 }}
              scrollToFirstError
              layout="vertical"
            >
              <FormItem
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.EmailRequired
                  },
                  {
                    type: "email",
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.EmailRequired
                  }
                ]}
              >
                <Input
                  placeholder="Enter Email"
                  tabIndex={loginInfo ? -1 : 0}
                />
              </FormItem>
              <FormItem
                name="password"
                label="Password"
                className="password"
                validateStatus=""
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.PasswordEmptyCheck
                  }
                ]}
              >
                <Input.Password
                  placeholder="Enter Password"
                  tabIndex={loginInfo ? -1 : 0}
                />
              </FormItem>
              <div className="flex justify-end items-center w-full mt-2">
                <a
                  href="/forgot_password"
                  className="text-gray-800"
                  tabIndex={loginInfo ? -1 : 0}
                >
                  Forgot password?
                </a>
              </div>
              <div style={{ paddingTop: "1em" }}>
                <Button
                  htmlType="submit"
                  size="middle"
                  style={{ margin: "auto", display: "block" }}
                  loading={loader}
                  disabled={loader}
                  tabIndex={loginInfo ? -1 : 0}
                >
                  Login
                </Button>
              </div>
            </Form>
          </div>
          <div className="px-1">
            <Form
              onFinish={(subscriptionData: any) => {
                setCurrentSubscriptionId(subscriptionData.subscriptionId);
                if (redirectUrl) history.push(redirectUrl);
                else history.push("/projects");
              }}
              form={subscriptionForm}
              labelCol={{ span: 50 }}
              wrapperCol={{ span: 30 }}
              scrollToFirstError
              layout="vertical"
              className="-mt-10"
            >
              <FormItem name="subscriptionId" label="Choose a subscription">
                <Select
                  className="w-full"
                  onChange={() => {}}
                  options={userSubscriptions.map((sub: any) => {
                    return {
                      value: sub.id,
                      label: sub.name
                    };
                  })}
                  tabIndex={!loginInfo ? -1 : 0}
                />
              </FormItem>

              <div
                style={{ paddingTop: "1em" }}
                className="w-full grid grid-cols-3 content-center"
              >
                <div className="flex items-center">
                  {/* <Button
                      type="link"
                      size="small"
                      className="m-0 p-0"
                      onClick={() => {
                        slider.current.prev();
                      }}
                    >
                      <LeftOutlined />
                      <span className="!ml-0">Back</span>
                    </Button> */}
                </div>
                <div className="flex justify-center">
                  <Button
                    htmlType="submit"
                    size="middle"
                    loading={loader}
                    disabled={loader}
                    tabIndex={!loginInfo ? -1 : 0}
                  >
                    Continue
                  </Button>
                </div>
                <div />
              </div>
            </Form>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Login;
