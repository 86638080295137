import { Button, Form, Input, Modal } from "antd";
import { TEditMilestone } from "components/workflow-template-editor/model";
import { VRules } from "components/workflow-template-editor/validation-rules";

function AddOffsetPanel({
  isOpen,
  setOpen,
  onAdd,
  milestones
}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAdd: (offsetData: { duration: number; offsetName: string }) => void;
  milestones: Array<TEditMilestone>;
}) {
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="ADD DURATION"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Add"
      footer={null}
      width={500}
      className="workflow-template-editor"
    >
      <Form
        layout="vertical"
        className="space-y-2"
        onFinish={(values) => {
          console.log(values);
          setOpen(false);
          onAdd({
            duration: Number(values.duration),
            offsetName: values.OffsetName
          });
        }}
      >
        <Form.Item
          label="ASSOCIATED DURATION"
          name="OffsetName"
          rules={VRules.OffsetName(milestones)}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="DEFAULT DURATION IN DAYS"
          name="duration"
          rules={VRules.Duration}
          required
        >
          <Input />
        </Form.Item>
        <div className="w-full p-2 space-x-2 flex justify-end">
          <Button className="w-24" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="w-24" type="primary" htmlType="submit">
            Add
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddOffsetPanel;
