import { ColGroupDef } from "ag-grid-community";
import {
  materialDueDateHeaderComponent,
  riskCellRenderer
} from "components/cell-renders";
import { InnerIdLinkMaterialRenderer } from "pages/reports-menu/components/cellRenderer";
import { TLinkedMaterial } from "../models";
import {
  getActivityValueGetterData,
  getLinkedSubmittalGetterData,
  valueGetterMaterialDueDate,
  valueGetterMaterialGcRepresentative,
  valueGetterMaterialId,
  valueGetterMaterialName,
  valueGetterMaterialResponsibleContractor,
  valueGetterMaterialRisk,
  valueGetterMaterialSpecSection
} from "../utils/value-getters";

export const materialColDef = [
  {
    headerName: "Linked Materials",
    children: [
      {
        colId: "material_id",
        field: "material_id",
        tooltipField: "material_id",
        headerName: "MATERIAL ID",
        headerTooltip: "MATERIAL ID",
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: {
          innerRenderer: InnerIdLinkMaterialRenderer
        },
        valueGetter: valueGetterMaterialId,
        getQuickFilterText: (params) => {
          const { value, data, context } = params;
          let allValues = [value];
          data.linked_submittals.forEach((s) => {
            allValues = allValues.concat(
              getLinkedSubmittalGetterData({ data: s.submittal, context })
            );
          });
          if (data.parentActivity) {
            allValues = allValues.concat(
              getActivityValueGetterData(data.parentActivity)
            );
          }
          return allValues.join(" ");
        }
      },
      {
        colId: "specsection",
        headerName: "Spec Section",
        headerTooltip: "Spec Section",
        valueGetter: valueGetterMaterialSpecSection,
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "name",
        field: "name",
        headerName: "MATERIAL NAME",
        headerTooltip: "MATERIAL NAME",
        tooltipField: "name",
        valueGetter: valueGetterMaterialName
      },
      {
        colId: "status",
        field: "status",
        headerName: "MATERIAL STATUS",
        headerTooltip: "MATERIAL STATUS",
        tooltipField: "status"
      },
      {
        colId: "gc_representative",
        headerName: "GC REPRESENTATIVE",
        headerTooltip: "GC REPRESENTATIVE",
        valueGetter: valueGetterMaterialGcRepresentative,
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "responsible_contractor_name",
        headerName: "RESPONSIBLE CONTRACTOR",
        headerTooltip: "RESPONSIBLE CONTRACTOR",
        valueGetter: valueGetterMaterialResponsibleContractor,
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "due_date",
        headerName: "DUE DATE",
        headerTooltip: "DUE DATE",
        headerComponentParams: materialDueDateHeaderComponent,
        valueGetter: valueGetterMaterialDueDate,
        sort: "asc",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "risk",
        headerName: "RISK",
        headerTooltip: "RISK",
        valueGetter: valueGetterMaterialRisk,
        cellRenderer: riskCellRenderer,
        tooltipValueGetter: ({ value }) => value
      }
    ]
  }
] as ColGroupDef<TLinkedMaterial>[];
