import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Typography } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import useEscapeKey from "components/workflow-template-editor/hooks/use-escape-key";
import { useEffect, useState } from "react";

import "./index.scss";

const { Text } = Typography;

function EditableInput({
  fieldLabel,
  fieldValue,
  onSubmit,
  disable = false,
  rules = []
}: {
  fieldLabel: any;
  fieldValue?: any;
  onSubmit?: (result: any) => void;
  disable?: boolean;
  rules?: Rule[];
}) {
  const [form] = useForm();
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(fieldValue);
  useEscapeKey(() => {
    setEditMode(false);
  });

  const hide = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (disable) hide();
  }, [disable]);

  useEffect(() => {
    setValue(fieldValue);
    if (editMode) {
      form.resetFields();
    }
  }, [editMode, form, fieldValue]);

  return (
    <div className="editable-input-container z-10 relative w-[120px]">
      <div
        className={`w-full transition-all absolute duration-200 ${
          editMode ? "-translate-y-5 delay-0" : "delay-[50ms]"
        }`}
      >
        <div className="flex items-center space-x-2 ">
          <Text className="grow" ellipsis={{ tooltip: fieldLabel, suffix: "" }}>
            {fieldLabel}
          </Text>
          <div className="w-6 z-10">
            {!disable && !editMode && (
              <EditOutlined
                className="cursor-pointer"
                onClick={() => {
                  setEditMode(true);
                }}
              />
            )}
          </div>
        </div>
        {fieldValue && (
          <div className={`m-0 -mt-0.5 ml-0.5 ${editMode ? "hidden" : ""}`}>
            ({fieldValue} Days)
          </div>
        )}
      </div>

      <Form
        className={`w-[180px] -mt-1 flex transition-all space-x-1 z-10 items-center editable-input-form duration-200 ${
          editMode
            ? "opacity-100 delay-100"
            : "w-0 h-0 opacity-0 delay-0 overflow-hidden"
        }`}
        validateTrigger="onChange"
        form={form}
        autoComplete="off"
      >
        <Form.Item name="name" rules={rules} required initialValue={value}>
          <input
            className="w-full"
            value={value}
            onChange={(event) => {
              const val = parseInt(event.target.value, 10);
              setValue(val);
            }}
            disabled={!editMode}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </Form.Item>
        <Button
          htmlType="submit"
          className="bg-gray-300 hover:bg-gray-300 m-0 p-0  h-fit w-fit"
          icon={<CheckOutlined />}
          onClick={(event) => {
            event.preventDefault();
            const errors = form.getFieldError("name");
            console.log("errors ", errors);
            if (errors.length === 0) {
              if (onSubmit) onSubmit(value);
              hide();
            }
          }}
        />
        <Button
          className="bg-gray-300 hover:bg-gray-300 m-0 p-0 h-fit w-fit"
          onClick={hide}
          icon={<CloseOutlined />}
        />
      </Form>
    </div>
  );
}

export default EditableInput;
