import { EditOutlined, LinkOutlined } from "@ant-design/icons";
import { Popover, Tag } from "antd";
import CustomTooltip from "components/custom-tooltip/custom-tooltip";
import UserTag from "components/user-tag/user-tag";
import { AcceptanceStatus } from "constants/index";
import { Link } from "react-router-dom";

export const linkCellRenderer = ({
  value,
  data,
  projectId,
  featureKey
}: any) => {
  return (
    <Link
      className="gridIdLink"
      to={{
        pathname: `/project/${projectId}/${featureKey}/${data.id}`
      }}
    >
      {value}
    </Link>
  );
};

export const userEntityCellRenderer = ({ value, statusId, data }: any) => {
  const statusIdValue = data[statusId];
  return (
    <div>
      {statusIdValue === AcceptanceStatus.DEACTIVATED && (
        <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
          <UserTag label="Inactive" />
        </div>
      )}
      <div>{value}</div>
    </div>
  );
};

export const ArrayStringGetQuickFilterText = ({ value }: any) => {
  if (value && Array.isArray(value)) {
    return value.map((v) => v.name).join(" ");
  }
  return "";
};

export function ArrayStringCellRenderer(params: any) {
  const { value, featureKey } = params;

  const arr = (value || []) as Array<{ id: string; name: string }>;

  const toShow = arr.slice(0, 2);
  const remaining = arr.slice(2, arr.length);

  const popupContent = (
    <div className="max-w-[260px] m-0 text-xs flex flex-wrap gap-y-1 gap-x-0">
      {remaining.map((item: any) => {
        return (
          <Link
            key={`${item.id}${item.name}`}
            target="_blank"
            to={`${featureKey}/${item.id}`}
            onClick={(evt) => (featureKey ? null : evt.preventDefault())}
          >
            <Tag
              color="default"
              icon={<LinkOutlined />}
              className="bg-gray-100 w-[120px] truncate"
            >
              <CustomTooltip value={item.name} />
            </Tag>
          </Link>
        );
      })}
    </div>
  );

  return (
    <div className="py-1 flex flex-wrap items-center gap-y-1 gap-x-0">
      {toShow.map((item, index: number) => {
        return (
          <div className="flex items-center" key={`${item.id}${item.name}`}>
            <Link
              target="_blank"
              to={`${featureKey}/${item.id}`}
              onClick={(evt) => (featureKey ? null : evt.preventDefault())}
              className="leading-normal"
            >
              <Tag
                color="default"
                icon={<LinkOutlined />}
                className="bg-gray-100 w-[120px] truncate"
              >
                <CustomTooltip value={item.name} />
              </Tag>
            </Link>
            {index === 1 && arr.length > 2 ? (
              <div className="flex-none flex-grow-0 leading-none">
                <Popover content={popupContent}>
                  <div>+{remaining.length}</div>
                </Popover>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

export const twoRowGetQuickFilterText = ({ data }: any) => {
  try {
    return `${data.title}\n${data.description}`;
  } catch (error) {
    return "";
  }
};

export const twoRowCellRenderer = (props: any) => {
  const { data, context, featureKey, projectId } = props;

  return (
    <div className="w-full">
      <div className="pl-3 !flex !items-center space-x-1">
        <Link
          className="gridIdLink grow truncate text-left"
          to={{
            pathname: `/project/${projectId}/${featureKey}/${data.id}`
          }}
        >
          {data.title || <>&nbsp;</>}{" "}
        </Link>
        <EditOutlined
          className="cell-edit-icon px-2"
          onClick={() => {
            context.onEditCell({
              rowIndex: props.node.rowIndex!,
              colKey: props.column!.getId(),
              data
            });
          }}
        />
      </div>
      <p className="colSecondValue pl-3">{data.description || <>&nbsp;</>}</p>
    </div>
  );
};
