export default function CopyIcon() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66653 3.00016V1.00016C2.66653 0.631976 2.96501 0.333496 3.3332 0.333496H11.3332C11.7014 0.333496 11.9999 0.631976 11.9999 1.00016V10.3335C11.9999 10.7017 11.7014 11.0002 11.3332 11.0002H9.3332V12.9996C9.3332 13.3681 9.03327 13.6668 8.662 13.6668H0.671107C0.300393 13.6668 0 13.3704 0 12.9996L0.0017333 3.66741C0.00179997 3.2989 0.30176 3.00016 0.672947 3.00016H2.66653ZM1.33495 4.3335L1.33346 12.3335H7.99987V4.3335H1.33495ZM3.99987 3.00016H9.3332V9.66683H10.6665V1.66683H3.99987V3.00016Z"
        fill="currentColor"
        fillOpacity="0.8"
      />
    </svg>
  );
}
