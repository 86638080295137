import { ITooltipParams } from "ag-grid-community";
import "./submittal-tooltip.css";

function SubmittalTooltip(props: ITooltipParams) {
  const { rowIndex, api, colDef } = props;
  const data = api.getDisplayedRowAtIndex(rowIndex!)?.data || {};

  // eslint-disable-next-line react/destructuring-assignment
  return props.location === "header" ? (
    <div className="customheadertooltip">
      <div>{colDef?.headerName}</div>
    </div>
  ) : (
    <>
      <span />
      {Object.keys(data).length > 0 && (
        <div className="customtooltip">
          <div>{data.title}</div>
          <div>{data.description}</div>
        </div>
      )}
    </>
  );
}

export default SubmittalTooltip;
