import { useState } from "react";
import { Form, Input, Button, Typography, Modal } from "antd";
import FormItem from "antd/lib/form/FormItem";
import "pages/login/login.css";
import { ErrorMessages } from "constants/index";
import { AppLogoIcon } from "components/svg-icons";
import { useHistory } from "react-router";
import { login, setAccessToken } from "services/auth";

const { Title } = Typography;

function AdminLogin() {
  const history = useHistory();
  const [loginForm] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const onLoginFinish = async (event: any) => {
    setLoader(true);
    const res: any = await login({
      email: event.email,
      password: event.password,
      env_admin: true
    });
    if (res.status === 200) {
      setAccessToken(res.data.success.token);
      history.push("/organizations");
    } else {
      Modal.error({ content: res.response?.data?.error || res.message });
      setLoader(false);
    }
  };

  return (
    <div className="login">
      <div className="login-form">
        <div style={{ margin: "auto", display: "block" }}>
          <AppLogoIcon />
        </div>
        <div className="login-logo">
          <Title level={3} className="mt-3">
            Login
          </Title>
        </div>
        <div className="px-1">
          <Form
            onFinish={onLoginFinish}
            form={loginForm}
            labelCol={{ span: 50 }}
            wrapperCol={{ span: 30 }}
            scrollToFirstError
            layout="vertical"
          >
            <FormItem
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailRequired
                },
                {
                  type: "email",
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.EmailRequired
                }
              ]}
            >
              <Input placeholder="Enter Email" />
            </FormItem>
            <FormItem
              name="password"
              label="Password"
              className="password"
              validateStatus=""
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.PasswordEmptyCheck
                }
              ]}
            >
              <Input.Password placeholder="Enter Password" />
            </FormItem>
            <div style={{ paddingTop: "1em" }}>
              <Button
                htmlType="submit"
                size="middle"
                style={{ margin: "auto", display: "block" }}
                loading={loader}
                disabled={loader}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default AdminLogin;
