/* eslint-disable default-case */
import {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { Button, message } from "antd";
import { useParams } from "react-router";
import { ColDef } from "ag-grid-community";
import InviteSubscriptionUserComponent from "pages/invite-user/invite-subscription-user";
import {
  InviteStatusCellRenderer,
  projectUserRoleRenderer
} from "components/cell-renders";
import { GridLoadingIndicator } from "components/widgets";
import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import ProjectUserRoleSelectCellEditor from "components/cell-editor/project-user-role-cell-editor";
import { useMutation, useSubscription } from "@apollo/client";
import {
  MUTATION_UPDATE_PROJECT_USER_PERMISSION,
  MUTATION_UPDATE_PROJECT_USER_ACTION
} from "services/graphQL/mutations";
import { SUBSCRIPTION_PROJECT_ROLES } from "services/graphQL/subscriptions";
import { getSubscriptionId, getUser } from "services/auth";
import {
  getSource,
  isProjectInIntegrationMode,
  matchCustomUserProjectRole
} from "utils/utils";
import { FilterItem } from "models/types";
import { FilterChips } from "components";
import ErrorBoundary from "components/error-boundary";
import InviteAccUserComponent from "pages/invite-user/invite-subscription-user/invite-acc-user";
import {
  AcceptanceStatus,
  DialogTitles,
  ECustomUserTypes,
  EUserRoleName,
  ErrorMessages,
  ProjectPermissionEnum
} from "../../constants";

function ProjectUsersList({
  internalUsers,
  vendorData
}: {
  internalUsers: { project_users: Array<any> };
  vendorData?: any;
}) {
  const { projectId } = useParams() as any;
  const gridRef = useRef<AgGridReact>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [drawer, setDrawerVisibility] = useState<boolean>(false);
  const { tokenContents, gqlClientForProject, projectDetails } =
    useContext(ProjectContext);
  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const { data: allProjectRoles } = useSubscription(SUBSCRIPTION_PROJECT_ROLES);
  const subscriptionId = getSubscriptionId();
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [showClearBtn, setShowClearBtn] = useState(false);
  const isGCADmin =
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin];
  const [currentUser]: [any, any] = useState(getUser());
  const [inviteAccUser, setInviteAccUser] = useState<{
    showInviteModel: boolean;
    inviteUserData?: any;
  }>({
    showInviteModel: false,
    inviteUserData: null
  });

  const [updateUserPermission] = useMutation<any>(
    MUTATION_UPDATE_PROJECT_USER_PERMISSION,
    {
      client: gqlClientForProject
    }
  );

  const [updateProjectUserAction] = useMutation<any>(
    MUTATION_UPDATE_PROJECT_USER_ACTION,
    {
      client: gqlClientForProject
    }
  );

  const userAction = async (userId: string, isChecked: boolean) => {
    if (userId) {
      const variables = {
        updates: {
          _set: {
            status_id: isChecked
              ? AcceptanceStatus.ACTIVE
              : AcceptanceStatus.DEACTIVATED
          },
          where: {
            user_id: { _eq: userId }
          }
        }
      };

      const updateResponse = await updateProjectUserAction({ variables });

      try {
        if (
          updateResponse.data?.update_project_users_many &&
          updateResponse.data?.update_project_users_many?.length > 0 &&
          updateResponse.data?.update_project_users_many[0]?.affected_rows === 1
        ) {
          message.success(
            isChecked
              ? "Project user has been activated."
              : "Project user has been deactivated."
          );
        } else {
          message.error("Failed to update.");
        }
      } catch (ex) {
        message.error("An error occured");
      }
    }
  };

  const gridContext = {
    allProjectRoles: allProjectRoles?.project_role,
    tokenContents,
    isGCADmin,
    userAction,
    currentUser,
    setInviteAccUser
  };

  const getProjectUserSources = (params: any) => {
    const projectIntegrationUsers = params?.project_integration_users;
    const allSources: string[] = [];
    if (projectIntegrationUsers?.length) {
      projectIntegrationUsers
        .map((piu: any) => piu.full_source_name)
        ?.reduce((acc: string[], item: string) => {
          if (!acc.includes(item)) {
            acc.push(item);
          }
          return acc;
        }, allSources);
    }
    const originalSource = getSource(params?.external_source_id);
    if (!allSources.includes(originalSource)) {
      allSources.push(originalSource);
    }
    return allSources;
  };

  const isPermissionEditable = (params: any) => {
    const { data, context } = params;

    const editEnabledBasedOnInviteStatus =
      params?.data?.status_id === AcceptanceStatus.INVITED ||
      params?.data?.status_id === AcceptanceStatus.ACTIVE;

    if (!editEnabledBasedOnInviteStatus) return false;

    const isGcADmin =
      context?.tokenContents?.role ===
      EUserRoleName[EUserRoleName.gc_project_admin];
    if (isGcADmin) return !data.external; // GC admin can edit only GC users for now, if return true then he can able to edit all users
    const rolePermission = isPermissionNotGrantted(
      ProjectPermissionEnum.AddProjectUser,
      context?.tokenContents?.role!
    );

    if (rolePermission) return false;

    return data.external;
  };

  const cellStyleForDeactivatedUsers = (params: any) => {
    if (params.data.status_id === AcceptanceStatus.DEACTIVATED) {
      return { opacity: 0.5 };
    }
    return { opacity: 1.0 };
  };

  // Each Column Definition results in one Column.
  const columnDefs = useMemo<ColDef[]>(() => {
    const colArr: ColDef[] = [
      {
        valueGetter: (params: any) => {
          return `${params.data.user.first_name} ${params.data.user.last_name}`;
        },
        field: "name",
        colId: "name",
        headerName: "Name",
        headerTooltip: "NAME",
        menuTabs: [],
        sort: "asc",
        tooltipValueGetter: (params: any) => {
          return `${params.data.user.first_name} ${params.data.user.last_name}`;
        },
        minWidth: 120,
        cellStyle: cellStyleForDeactivatedUsers
      },
      {
        field: "subscription_vendor.name",
        colId: "subscription_vendor.name",
        valueGetter: (params: any) => {
          const sources = getProjectUserSources(params.data);
          if (sources.includes("ConstructivIQ")) {
            if (params.data.subscription_vendor?.name) {
              return params.data.subscription_vendor?.name;
            }
            const arr: any[] =
              params.data.subscription?.organization_subscriptions;
            return !arr || arr.length === 0 ? "" : arr[0].organization?.name;
          }
          return params.data.subscription_vendor?.name || "";
        },
        headerName: "Company Name",
        headerTooltip: "COMPANY NAME",
        menuTabs: ["filterMenuTab"],
        filter: true,
        tooltipValueGetter: ({ value }: any) => value,
        cellStyle: cellStyleForDeactivatedUsers
      },

      {
        headerName: "Email",
        headerTooltip: "EMAIL",
        menuTabs: [],
        field: "user.email",
        colId: "user.email",
        tooltipField: "user.email",
        cellStyle: cellStyleForDeactivatedUsers
      },
      {
        field: "user.phone",
        colId: "user.phone",
        headerName: "Phone Number",
        headerTooltip: "PHONE NUMBER",
        menuTabs: [],
        tooltipField: "user.phone",
        cellStyle: cellStyleForDeactivatedUsers
      },
      {
        colId: "project_role.name",
        headerName: "Project Role",
        headerTooltip: "PROJECT ROLE",
        filter: true,
        menuTabs: ["filterMenuTab"],
        editable: (params) => {
          return isPermissionEditable(params);
        },
        cellRenderer: (params: any) => {
          return projectUserRoleRenderer(params);
        },
        cellEditor: ProjectUserRoleSelectCellEditor,
        cellClass: (params: any) => {
          return isPermissionEditable(params) ? "cell-editable" : "";
        },
        minWidth: 240,
        tooltipField: "project_role.name",
        cellStyle: cellStyleForDeactivatedUsers
      },
      {
        colId: "source",
        headerName: "Source",
        menuTabs: ["filterMenuTab"],
        hide: !isIntegrationMode,
        valueGetter: ({ data }) => {
          const sources = getProjectUserSources(data);
          return sources.join(",");
        }
      },
      {
        field: "status_id",
        colId: "status_id",
        headerName: "User Status",
        headerTooltip: "USER STATUS",
        minWidth: 300,
        menuTabs: [],
        cellRenderer: InviteStatusCellRenderer,
        cellRendererParams: { inviteType: "ProjectUser" },
        comparator: (valueA: any, valueB: any) => {
          return AcceptanceStatus[valueA]
            .toLowerCase()
            .localeCompare(AcceptanceStatus[valueB].toLowerCase());
        },
        tooltipValueGetter: (params) => {
          let status = "";
          switch (params.data.status_id) {
            case 1:
              status = "Pending";
              break;
            case 2:
              status = "Active";
              break;
            case 3:
              status = "Inactive";
              break;

            default:
              break;
          }
          return status;
        },
        cellStyle: cellStyleForDeactivatedUsers
      }
    ];

    return colArr;
  }, [isIntegrationMode]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true
    };
  }, []);

  const onFilterTextBoxChanged = useCallback(
    (value: string) => {
      gridRef.current!.api.setQuickFilter(value);
    },
    [gridRef]
  );

  const setDrawerAction = (drawerAction: boolean) => {
    setDrawerVisibility(drawerAction);
  };

  const projectUsersData = useMemo(() => {
    if (!internalUsers) return [];

    const subscriptionUsers: any[] = internalUsers?.project_users?.filter(
      (user: any) => {
        return subscriptionId === user?.subscription_id;
      }
    );

    const isSCUser = matchCustomUserProjectRole(
      ECustomUserTypes.AllSCRoles,
      tokenContents?.role
    );

    if (isSCUser) {
      const filteredScUsers: any[] = subscriptionUsers?.filter((user: any) => {
        return (
          !user?.subscription_vendor ||
          tokenContents?.claims["x-hasura-vendor-id"] ===
            user?.subscription_vendor?.id
        );
      });
      return filteredScUsers || [];
    }
    return subscriptionUsers || [];
  }, [internalUsers, subscriptionId, tokenContents]);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const onUserPermissionCellEdit = async (event: any) => {
    if (event.newValue === event.oldValue) return;
    const userRole = allProjectRoles?.project_role?.find(
      (role: any) => role?.id === event.newValue
    );
    if (userRole?.id) {
      const variables = {
        role_id: userRole?.id,
        user_id: event.data?.user_id
      };
      // eslint-disable-next-line no-await-in-loop
      const updateResponse = await updateUserPermission({
        variables
      });
      try {
        if (updateResponse.data?.update_users_project_role?.message) {
          message.success(
            updateResponse.data?.update_users_project_role?.message
          );
        } else {
          message.error("Failed to update.");
        }
      } catch (ex) {
        message.error("An error occured");
      }
    }
  };

  const cellEditRequest = async (event: any) => {
    console.log("Event Called:", event);
    if (!event.newValue) return;
    if (event.column.colId === "project_role.name") {
      onUserPermissionCellEdit(event);
    }
  };

  const onFilterChipDelete = (item: FilterItem) => {
    if (gridRef.current) {
      const filterInstance = gridRef.current.api.getFilterInstance(item.field);
      if (filterInstance) {
        const model = filterInstance.getModel();
        const values = model.values.filter((val: any) => val !== item.value);
        if (values.length === 0) {
          gridRef?.current?.api.destroyFilter(item.field);
          return;
        }
        filterInstance.setModel({ values });
        gridRef.current.api.onFilterChanged();
      }
    }
  };

  const resetFilters = () => {
    setShowClearBtn(false);
    // setGridSearchtext("");
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "name" || "user.email" || "user.phone" || "status_id":
          columnDefArr[index].sortable = true;
          break;
        default:
          columnDefArr[index].sortable = true;
          columnDefArr[index].suppressMenu = false;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
    gridRef.current!.api.setFilterModel(null);
    gridRef.current!.api.setQuickFilter("");
  };

  function setSortAndFilterVisibility(
    isEnabled: boolean,
    isRowDataUpdated: boolean
  ) {
    setShowClearBtn(isRowDataUpdated ? false : !isEnabled);
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "name" || "user.email" || "user.phone" || "status_id":
          columnDefArr[index].sortable = isEnabled;
          break;
        default:
          columnDefArr[index].sortable = isEnabled;
          columnDefArr[index].suppressMenu = !isEnabled;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
  }

  const onFiltersApplied = (event: any) => {
    const filtersApplied = event.api.getFilterModel();
    if (filtersApplied) {
      const items: FilterItem[] = new Array<FilterItem>();
      Object.keys(filtersApplied).forEach((key: any) => {
        if (
          filtersApplied[key]?.values &&
          filtersApplied[key].values.length > 0
        ) {
          const field = columnDefs.filter((x) => x.field === key)[0];
          const { values } = filtersApplied[key];

          items.push({
            field: key,
            header: field ? field.headerName : key,
            value: values.filter(Boolean)
          });
        }
      });
      setFilters(items.filter(Boolean));
    }

    if (
      gridRef?.current &&
      gridRef.current.api?.getDisplayedRowCount() < 2 &&
      gridRef.current.api?.isAnyFilterPresent()
    ) {
      setSortAndFilterVisibility(false, false);
    } else {
      setSortAndFilterVisibility(true, false);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-160px)]">
      <ActionBar noXPadding noYPadding>
        <div className="flex w-full justify-end space-x-2 items-center">
          <SearchInput
            placeholder="Search Users"
            onChange={onFilterTextBoxChanged}
          />
          <FilterChips
            items={filters}
            onChipDelete={onFilterChipDelete}
            onCategoryDelete={(item: FilterItem) => {
              gridRef?.current?.api?.destroyFilter(item.field);
            }}
            resetAll={resetFilters}
          />
          {showClearBtn && !filters?.length && (
            <Button
              onClick={() => {
                filters.forEach((item: any) => {
                  gridRef?.current?.api?.destroyFilter(item.field);
                });

                resetFilters();
              }}
            >
              Clear All
            </Button>
          )}
          <Button
            onClick={() => {
              setDrawerVisibility(true);
            }}
            title={
              isPermissionNotGrantted(
                ProjectPermissionEnum.AddProjectUser,
                tokenContents?.role!
              )
                ? ErrorMessages.PermissionNotGranted
                : "Invite User"
            }
            disabled={isPermissionNotGrantted(
              ProjectPermissionEnum.AddProjectUser,
              tokenContents?.role!
            )}
          >
            Invite User
          </Button>
        </div>
      </ActionBar>
      <div className="grow ag-theme-alpine">
        <AgGridReact<any>
          ref={gridRef}
          onGridReady={() => {
            setGridReady(true);
            gridRef.current!.api.sizeColumnsToFit();
          }}
          rowData={projectUsersData}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows // Optional - set to 'true' to have rows animate when sorted
          loadingOverlayComponent={GridLoadingIndicator}
          suppressDragLeaveHidesColumns
          readOnlyEdit
          singleClickEdit
          onCellEditRequest={cellEditRequest}
          context={gridContext}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          stopEditingWhenCellsLoseFocus
          onFilterChanged={onFiltersApplied}
          onRowDataUpdated={() => {
            if (
              gridRef.current &&
              gridRef.current.api.getDisplayedRowCount() < 2
            ) {
              setSortAndFilterVisibility(false, true);
            } else {
              setSortAndFilterVisibility(true, true);
            }
          }}
        />
      </div>
      {drawer && (
        <ErrorBoundary
          onError={() => {
            setDrawerVisibility(false);
          }}
        >
          <InviteSubscriptionUserComponent
            projectID={projectId}
            isFromProjectUser
            existingUsers={projectUsersData}
            setDrawerOpen={setDrawerAction}
            vendorData={vendorData}
            showDrawerOpen={drawer}
            modelTitle="Invite User"
          />
        </ErrorBoundary>
      )}

      {inviteAccUser.showInviteModel && (
        <ErrorBoundary
          onError={() => {
            setInviteAccUser({
              showInviteModel: false,
              inviteUserData: null
            });
          }}
        >
          <InviteAccUserComponent
            setDrawerOpen={setInviteAccUser}
            showDrawerOpen={inviteAccUser.showInviteModel}
            modelTitle={DialogTitles.inviteUser}
            userData={inviteAccUser.inviteUserData}
            isFromProjectUser
          />
        </ErrorBoundary>
      )}
    </div>
  );
}

export default ProjectUsersList;
