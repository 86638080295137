import { useHistory, useParams } from "react-router";
import { Tabs } from "antd";
import { useState } from "react";

import "../../../project-setting-menu/module-settings/module-settings.scss";
import FeatureTemplateSubscriptionSettings from "components/manage-feature-wf-templates/feature-template-subscription-settings";
import { FeatureTypes } from "entity-app/constants";

const URLFeatureTypeParamToIdMap: Record<string, FeatureTypes> = {
  bidpackages: FeatureTypes.BID_PACKAGE,
  materials: FeatureTypes.MATERIALS
};
const featureTabItemLabels: Partial<Record<FeatureTypes, string>> = {
  [FeatureTypes.BID_PACKAGE]: "Bid Package Templates",
  [FeatureTypes.MATERIALS]: "Material Templates"
};

function FeatureSubscriptionSettings() {
  const history = useHistory();
  const { tab2Id, tab3Id } = useParams() as any;
  const [tabIndex, setTabIndex] = useState<string>(tab3Id || "dateblock");
  const featureId = URLFeatureTypeParamToIdMap[tab2Id || "materials"];
  const tabItems = [
    {
      label: featureTabItemLabels[featureId],
      key: "dateblock",
      children: <FeatureTemplateSubscriptionSettings featureId={featureId} />
    }
  ];
  return (
    <div className="w-full bg-white h-full module-settings--tabs">
      <div className="flex flex-col">
        <Tabs
          tabPosition="left"
          tabBarGutter={2}
          tabBarStyle={{ width: "180px" }}
          activeKey={tabIndex}
          items={tabItems}
          onChange={(activeKey) => {
            history.push(`/settings/modules/${tab2Id}/${activeKey.toString()}`);
            setTabIndex(activeKey);
          }}
        />
      </div>
    </div>
  );
}
export default FeatureSubscriptionSettings;
