import { gql } from "@apollo/client";

export const QUERY_AGG_SUBMITTAL_STATUS_LINKED_BY_MATERIAL_ID = gql`
  query SubmittalLinkedWithMaterialQuery($materialId: uuid!) {
    submittals_aggregate(
      where: { submittal_material_links: { material_id: { _eq: $materialId } } }
    ) {
      nodes {
        status
      }
    }
  }
`;

export const SUBSCRIPTION_AGG_SUBMITTAL_STATUS_LINKED_BY_MATERIAL_ID = gql`
  subscription SubmittalLinkedWithMaterialSubscription($materialId: uuid!) {
    submittals_aggregate(
      where: { submittal_material_links: { material_id: { _eq: $materialId } } }
    ) {
      nodes {
        status
      }
    }
  }
`;

export const MUTATION_UPDATE_DATEBLOCK_PK = gql`
  mutation MyMutation($set: date_block_set_input!, $id: uuid = "") {
    update_date_block_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

export const MUTATION_UPDATE_DATEBLOCK_BY_MATERIALID = gql`
  mutation MyMutation(
    $where: date_block_bool_exp!
    $set: date_block_set_input!
  ) {
    update_date_block(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const MUTATION_UPDATE_NEW_DATEBLOCK_FOR_SUBMITTAL = gql`
  mutation MutationUpdateDateblockForSubmittal(
    $submittal_id: uuid!
    $set: date_block_set_input!
  ) {
    update_date_block(
      where: { submittal_id: { _eq: $submittal_id } }
      _set: $set
    ) {
      affected_rows
    }
  }
`;
