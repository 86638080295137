function FileIcon() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 1.1686V1.16675H11.6654C11.9883 1.16675 12.25 1.43235 12.25 1.7453V12.2549C12.25 12.5744 11.9905 12.8334 11.6705 12.8334H2.32948C2.00944 12.8334 1.75 12.571 1.75 12.2461V4.66675L5.25 1.1686ZM3.40035 4.66675H5.25V2.81808L3.40035 4.66675ZM6.41667 2.33341V5.25008C6.41667 5.57225 6.15551 5.83342 5.83333 5.83342H2.91667V11.6667H11.0833V2.33341H6.41667Z"
        fill="#3B3B3B"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default FileIcon;
