import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Typography } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import useEscapeKey from "components/workflow-template-editor/hooks/use-escape-key";
import { useEffect, useState } from "react";

const { Text } = Typography;

function EditableInput({
  input,
  inputText,
  classNameEdit = "",
  className = "",
  onSubmit,
  disable = false,
  rules = []
}: {
  input: any;
  inputText?: string;
  className?: string;
  classNameEdit?: string;
  onSubmit?: (result: any) => void;
  disable?: boolean;
  rules?: Rule[];
}) {
  const [form] = useForm();
  const [editMode, setEditMode] = useState(false);
  const [inputData, setInputData] = useState(input);
  useEscapeKey(() => {
    setEditMode(false);
  });

  const hide = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (disable) hide();
  }, [disable]);

  useEffect(() => {
    setInputData(input);
    if (editMode) {
      form.resetFields();
    }
  }, [editMode, input, form]);

  return editMode ? (
    <Form
      className="flex space-x-1 z-10 pr-1 items-center editable-input-form"
      validateTrigger="onChange"
      form={form}
    >
      <Form.Item name="name" rules={rules} initialValue={inputData} required>
        <input
          className={classNameEdit}
          value={inputData}
          onChange={(event) => {
            setInputData(event.target.value);
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </Form.Item>
      <Button
        htmlType="submit"
        className="bg-gray-300 hover:bg-gray-300 m-0 p-0  h-fit w-fit"
        icon={<CheckOutlined />}
        onClick={(event) => {
          event.preventDefault();
          const errors = form.getFieldError("name");
          if (errors.length === 0) {
            if (onSubmit) onSubmit(inputData);
            hide();
          }
        }}
      />
      <Button
        className="bg-gray-300 hover:bg-gray-300 m-0 p-0 h-fit w-fit"
        onClick={hide}
        icon={<CloseOutlined />}
      />
    </Form>
  ) : (
    <div className="flex space-x-2 items-center">
      <Text
        className={className}
        ellipsis={{ tooltip: inputText || input, suffix: "" }}
      >
        {inputText || input}
      </Text>
      <div className="w-6 z-10">
        {!disable && (
          <EditOutlined
            className="cursor-pointer pl-2"
            onClick={() => {
              setEditMode(true);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default EditableInput;
