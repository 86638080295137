import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Popover, Select, message } from "antd";
import moment from "moment";
import {
  createSubscriptionIntegration,
  getCompanies
} from "services/integration-service";

import WarningIcon from "components/svg-icons/warning-icon";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  ErrorMessages,
  IntegrationSystemNameMap,
  SuccessMessages
} from "../../../../constants";
import { IntegrationsTabSharedContext } from "../integrations-tab-context";
import { IntegrationType } from "../utils";

const { Option } = Select;

export default function ProcoreInitialIntegration() {
  const { sharedState, dispatch } = useContext(IntegrationsTabSharedContext);
  const [form] = Form.useForm();

  const [companies, setCompanies] = useState<any>(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [submittingForm, setSubmittingForm] = useState(false);

  const onFinish = (values: any) => {
    setSubmittingForm(true);
    const comp = companies.find(
      (company: any) => company.id === values.account_id
    );

    const payload: any = [
      {
        properties: {
          integration_name: sharedState.additionalData.newIntegrationName,
          procore_company_id: values.account_id.toString(),
          procore_company_name: comp.name,
          subscription_alert_email: values.alert_email.join(",")
        },
        oauth_operation_id: sharedState.authData.id
      }
    ];

    const saveData = async () => {
      const createResponse = await createSubscriptionIntegration(
        sharedState.system,
        payload
      );
      setSubmittingForm(false);

      if (createResponse.success) {
        message.success(SuccessMessages.integration.integrationAdded);

        dispatch({
          type: "SET_ACTIVE_LIST_VIEW"
        });
      } else {
        message.error(ErrorMessages.integration.integrationFailed);
      }
    };
    saveData();
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      const companiesResponse = await getCompanies(
        sharedState.system,
        sharedState.authData.id
      );
      // console.log("companiesResponse ", companiesResponse);
      if (companiesResponse.success) {
        setCompanies(companiesResponse.data);
      }
    };
    fetchCompanyData();
  }, [sharedState.authData.id, sharedState.system]);

  const companyListOptions = useMemo(() => {
    const integratedAcountIDs = sharedState.integrationConfigs
      .filter(
        (config: any) =>
          config.integration.system === IntegrationType[IntegrationType.PROCORE]
      )
      .map((config: any) => {
        return parseInt(config.properties.PROCORE_COMPANY_ID, 10);
      });

    return companies?.map((company: any) => {
      return {
        label: company.name,
        value: company.id,
        alreadyIntegrated: integratedAcountIDs.includes(company.id)
      };
    });
  }, [companies, sharedState.integrationConfigs]);

  const confirmPopoverContent = (
    <div>
      <div className="flex justify-center">
        <WarningIcon />
      </div>
      <div className="flex justify-center text-lg font-medium">
        Please confirm your action
      </div>
      <div className="mt-2 mb-3.5">
        Please review and verify the entered details before you save.
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Button
          onClick={() => {
            setShowConfirmDialog(false);
          }}
          disabled={submittingForm}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
          loading={submittingForm}
        >
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="h-full flex flex-col"
      disabled={submittingForm}
    >
      <div className="h-full relative">
        <div className="grow w-full md:max-w-screen-sm mx-auto py-4">
          <div className="mt-5 ">
            <div className="text-sm uppercase font-semibold text-neutral-700">
              You are authorized with{" "}
              {IntegrationSystemNameMap[sharedState.system]}
            </div>
            <div className="text-xs mt-1">
              <span className="text-neutral-600">Last authorized on: </span>
              <span>
                {sharedState.authData.updated_at
                  ? moment(sharedState.authData.updated_at)
                      .local()
                      .format("hh:mmA MM/DD/YYYY")
                  : null}
              </span>
            </div>
          </div>
          <div className="mt-7 w-[500px]">
            <Form.Item
              name="account_id"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>ACCOUNT NAME</span>
                </div>
              }
              rules={[{ required: true, message: "Please select an Account" }]}
              requiredMark={false}
            >
              <Select
                showSearch={false}
                placeholder="Select an Account"
                className="normal-case"
                loading={!companyListOptions}
                allowClear={false}
                showArrow
              >
                {companyListOptions?.map((option: any) => {
                  return (
                    <Option
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      disabled={option.alreadyIntegrated}
                    >
                      <div>
                        <div
                          className={`${
                            option.alreadyIntegrated ? "text-gray-400" : ""
                          }`}
                        >
                          {option.label}
                        </div>
                        {option.alreadyIntegrated && (
                          <div className="text-gray-500">
                            This account is already integrated
                          </div>
                        )}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="alert_email"
              className="hide-required-mark"
              label={
                <div className="flex items-center">
                  <span>Notification Recipients</span>
                  <Popover
                    content={
                      <div className="text-sm font-medium">
                        Enter the email address of users who need to be notified
                        in case of failure in authorization.
                      </div>
                    }
                  >
                    <InfoCircleOutlined className="pl-1 -mt-0.5" />
                  </Popover>
                </div>
              }
              rules={[
                { required: true, message: "Please enter at least one email" }
              ]}
            >
              <Select
                className="normal-case"
                mode="tags"
                placeholder="Email IDs"
                notFoundContent={null}
                dropdownStyle={{ display: "none" }}
              />
            </Form.Item>
          </div>
          <div className="mt-9 grid grid-cols-2 gap-x-4">
            <div className="space-y-1">
              <div className="info-label">AUTHORIZATION GRANTED BY:</div>
              <div className="info-value">
                {sharedState.authData?.output?.user_info?.login}
              </div>
            </div>
            <div className="space-y-1">
              <div className="info-label">ADDED BY:</div>
              <div className="info-value">
                {sharedState.authData?.created_by_user?.first_name}{" "}
                {sharedState.authData?.created_by_user?.last_name} (
                {sharedState.authData?.created_by_user?.email})
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className="mx-0 pb-0 mb-0" />
      <footer className="p-6 flex justify-end gap-x-4">
        <Button
          size="middle"
          onClick={() => {
            dispatch({
              type: "SET_ACTIVE_LIST_VIEW"
            });
          }}
          disabled={submittingForm}
        >
          Back
        </Button>
        <Popover
          placement="topRight"
          content={confirmPopoverContent}
          destroyTooltipOnHide
          open={showConfirmDialog}
        >
          <Button
            size="middle"
            type="primary"
            onClick={async () => {
              try {
                await form.validateFields();
                // console.log("validationResult ", validationResult);
                setShowConfirmDialog(true);
              } catch (ex) {
                console.log(ex);
              }
            }}
            disabled={submittingForm}
          >
            Save
          </Button>
        </Popover>
      </footer>
    </Form>
  );
}
