import { Button, Popover, Tooltip } from "antd";
import InlineEditTitle from "components/inline-edit-title";
import { CopyIcon } from "components/svg-icons";
import { useEffect, useState } from "react";
import "./bid-package-header.scss";
import ErrorBoundary from "components/error-boundary";

function FeatureHeader(props: {
  actions?: any;
  headerTitle?: string;
  number?: string;
  status?: any;
  disabled?: boolean;
  feature: string;
}) {
  const { actions, headerTitle, status, number, disabled, feature } = props;
  const [visible, setVisible] = useState(false);
  const [headerInfo, setHeaderInfo] = useState<{
    featureHeaderNumber?: string;
    featureHeaderTitle?: string;
  }>();

  const updateHeaderInfo = async (headersInfo: any) => {
    setHeaderInfo({
      featureHeaderNumber: headersInfo?.number,
      featureHeaderTitle: headersInfo?.title
    });
    await actions.updateFeatureInstanceFields({
      title: headersInfo?.title,
      number: headersInfo?.number
    });
  };

  useEffect(() => {
    setHeaderInfo({
      featureHeaderNumber: number,
      featureHeaderTitle: headerTitle
    });
  }, [headerTitle, number]);

  return (
    <div className="flex justify-between items-center w-full p-2 bid-package-header">
      <ErrorBoundary>
        <InlineEditTitle
          isPermissionToEdit={!disabled}
          headerInfo={headerInfo}
          updateHeaderInfo={updateHeaderInfo}
          feature={feature}
        />
      </ErrorBoundary>
      <div className="flex space-x-2 items-center">
        <div className="label uppercase">{status}</div>
        <Popover
          open={visible}
          placement="bottomRight"
          // content={<div>Test</div>}
          trigger="click"
          onOpenChange={() => {
            setVisible(!visible);
          }}
          destroyTooltipOnHide
        >
          <Tooltip>
            <Button onClick={() => setVisible(true)} disabled>
              <div className="flex items-center space-x-2">
                <div className=" text-[12px]">Duplicate</div>
                <CopyIcon />
              </div>
            </Button>
          </Tooltip>
        </Popover>
      </div>
    </div>
  );
}

export default FeatureHeader;
