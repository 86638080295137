import { Link } from "react-router-dom";

type TSelectSearchNotFoundContent = {
  notFoundMsg: string;
  linkTitle: string;
  linkPath: string;
};

export default function SelectSearchNotFoundContent(
  props: TSelectSearchNotFoundContent
) {
  const { notFoundMsg, linkTitle, linkPath } = props;
  return (
    <div className="w-full space-y-1">
      {notFoundMsg} <Link to={linkPath}>{linkTitle}</Link>
    </div>
  );
}
