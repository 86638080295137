import { Link, useParams } from "react-router-dom";

export function InnerIdLinkSubmittalRenderer(param: any) {
  const { projectId } = useParams() as any;
  const { data, value } = param;
  return (
    <Link
      className="gridIdLink"
      target="_blank"
      to={{
        pathname: `/project/${projectId}/submittals/${data.id}`
      }}
    >
      {value}
    </Link>
  );
}

export function InnerIdLinkMaterialRenderer(param: any) {
  const { projectId } = useParams() as any;
  const { data, value } = param;
  return (
    <Link
      className="gridIdLink"
      target="_blank"
      to={{
        pathname: `/project/${projectId}/materials/${data.id}`
      }}
    >
      {value}
    </Link>
  );
}
