export const updateSubmittalList = (
  submittalList: any,
  projectParticipants: any
) => {
  const updatedSubmittalList = submittalList.map((submittal: any) => {
    const missingData: any = {};
    if (submittal.assignee_user_id) {
      const assigneUser =
        projectParticipants.allProjectUsersMap[submittal.assignee_user_id];

      missingData.assignee_first_name = assigneUser?.first_name || "";
      missingData.assignee_last_name = assigneUser?.last_name || "";
      missingData.assignee_org = assigneUser?.company?.name || "";
    }
    if (submittal.assignee_unregistered) {
      const assigneUnregisteredUser =
        projectParticipants.allProjectUsersMap[submittal.assignee_unregistered];
      missingData.assignee_unregistered_org =
        assigneUnregisteredUser?.company?.name || "";
    }
    if (submittal.design_reviewer_user_id) {
      const designReviewerUser =
        projectParticipants.allProjectUsersMap[
          submittal.design_reviewer_user_id
        ];
      missingData.design_reviewer_first_name =
        designReviewerUser?.first_name || "";
      missingData.design_reviewer_last_name =
        designReviewerUser?.last_name || "";
      missingData.design_reviewer_org = designReviewerUser?.company?.name || "";
    }
    if (submittal.design_reviewer_unregistered) {
      const designReviewerUnregisteredUser =
        projectParticipants.allProjectUsersMap[
          submittal.design_reviewer_unregistered
        ];
      missingData.design_reviewer_unregistered_org =
        designReviewerUnregisteredUser?.company?.name || "";
    }
    if (submittal.gc_reviewer_user_id) {
      const gcUser =
        projectParticipants.allProjectUsersMap[submittal.gc_reviewer_user_id];
      missingData.gc_reviewer_first_name = gcUser?.first_name || "";
      missingData.gc_reviewer_last_name = gcUser?.last_name || "";
      missingData.gc_reviewer_org = gcUser?.company?.name || "";
    }
    if (submittal.submitter_unregistered) {
      const submitterUnregisteredUser =
        projectParticipants.allProjectUsersMap[
          submittal.submitter_unregistered
        ];
      missingData.submitter_org =
        submitterUnregisteredUser?.company?.name || "";
    }
    if (submittal.submitter_user_id) {
      const submitterUser =
        projectParticipants.allProjectUsersMap[submittal.submitter_user_id];
      missingData.submitter_first_name = submitterUser?.first_name || "";
      missingData.submitter_last_name = submitterUser?.last_name || "";
      missingData.submitter_org = submitterUser?.company?.name || "";
    }
    if (submittal.created_by) {
      const createdByUser =
        projectParticipants.allProjectUsersMap[submittal.created_by];
      missingData.created_by_first_name = createdByUser?.first_name || "";
      missingData.created_by_last_name = createdByUser?.last_name || "";
    }

    if (submittal.history) {
      const updatedHistory = submittal.history.map((wfHistory: any) => {
        const missingHistoryData: any = {};
        if (wfHistory.assignee) {
          const assigneUser =
            projectParticipants.allProjectUsersMap[wfHistory.assignee];
          missingHistoryData.assignee_user = {
            id: wfHistory.assignee,
            email: assigneUser?.email || "",
            first_name: assigneUser?.first_name || "",
            last_name: assigneUser?.last_name || "",
            project_users: [
              {
                subscription_vendor: {
                  name: assigneUser?.company?.name || ""
                }
              }
            ]
          };
        }
        if (wfHistory.on_behalf_of) {
          const onBehalfUser =
            projectParticipants.allProjectUsersMap[wfHistory.on_behalf_of];
          missingHistoryData.on_behalf_of_user = {
            id: wfHistory.on_behalf_of,
            email: onBehalfUser?.email || "",
            first_name: onBehalfUser?.first_name || "",
            last_name: onBehalfUser?.last_name || "",
            project_users: {
              subscription_vendor: {
                name: onBehalfUser?.company?.name || ""
              }
            }
          };
        }
        return {
          ...wfHistory,
          ...missingHistoryData
        };
      });
      missingData.history = updatedHistory;
    }
    const mergedSubmittal = {
      ...submittal,
      ...missingData
    };

    return mergedSubmittal;
  });

  return updatedSubmittalList;
};
