import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Spin,
  UploadFile,
  message
} from "antd";
import FormItem from "antd/es/form/FormItem";
import Dragger from "antd/lib/upload/Dragger";
import CIQDatePicker from "components/custom-date-picker";
import { useContext, useEffect, useState } from "react";
import { DATE_FORMAT_MMDDYYYY } from "utils/dateutils";
import { UploadProps } from "antd/lib/upload";
import classNames from "classnames";
import { useMutation } from "@apollo/client";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { MUTATION_INSERT_SPECIFICATION } from "services/graphQL/mutations";
import { v4 as uuidV4 } from "uuid";
import { generateS3URL } from "services/file-mgmt";
import { noAuthClient } from "services/axios";
import moment from "moment";
import { ErrorMessages } from "../../../constants";

function UploadSpecPanel(props: any) {
  const { showPanel, hidePanel, mode, parentData } = props;

  const { gqlClientForProject, tokenRetrievalState }: TProjectContext =
    useContext(ProjectContext);

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [unsupportedFileSelected, setUnsupportedFileSelected] = useState(false);

  const [insertSpecification] = useMutation(MUTATION_INSERT_SPECIFICATION, {
    client: gqlClientForProject
  });

  const fileUploadDraggerProps: UploadProps = {
    style: { borderColor: unsupportedFileSelected === true ? "red" : "" },
    accept: "application/pdf, .pdf",
    fileList,
    onRemove: () => {
      setFileList([]);
    },
    onDrop: (e) => {
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        const fileName = file.name.toLowerCase();
        const extension =
          fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
          "";
        if (!["pdf"].some((x) => x === extension)) {
          setUnsupportedFileSelected(true);
          setFileList([]);
          message.error(ErrorMessages.specUploads.pdfSelectionOnly);
          return false;
        }
        setUnsupportedFileSelected(false);
        return false;
      }
      return false;
    },
    beforeUpload: (file) => {
      const fileName = file.name.toLowerCase();
      const extension =
        fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
        "";
      if (!["pdf"].some((x) => x === extension)) {
        setUnsupportedFileSelected(true);
        setFileList([]);
        message.error(ErrorMessages.specUploads.pdfSelectionOnly);
        return false;
      }
      setUnsupportedFileSelected(false);
      setFileList([file]);
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false
    }
  };

  const handleUpload = async (values: any) => {
    setUploading(true);

    const selectedFile: any = fileList[0];

    const specPayload: any = {
      specification: {
        title: values.title,
        description: "",
        id: uuidV4(),
        version_name: values.version_name,
        version_date: values.version_date.format(DATE_FORMAT_MMDDYYYY)
      }
    };

    if (mode === "revision") {
      specPayload.specification.version_of = parentData.id;
    }

    let insertSpecResponse = null;
    try {
      insertSpecResponse = await insertSpecification({
        variables: { ...specPayload }
      });

      if (insertSpecResponse.errors) {
        message.error(
          insertSpecResponse.errors[0]?.message ||
            ErrorMessages.specUploads.specSectionCreationFailed
        );
        setUploading(false);
        return null;
      }
    } catch (ex: any) {
      console.log("ex ", ex);
      const errorMsg = ErrorMessages.specUploads.specSectionCreationFailed;
      message.error(errorMsg);
      setUploading(false);
      return null;
    }

    if (
      insertSpecResponse?.data &&
      insertSpecResponse?.data?.insert_specifications_one?.id
    ) {
      try {
        const signedUrlRespose: any = await generateS3URL(
          {
            feature: "SPECIFICATION",
            feature_id: insertSpecResponse.data?.insert_specifications_one?.id,
            file_name: selectedFile.name
          },
          tokenRetrievalState.token
        );

        await noAuthClient.put(
          `${signedUrlRespose.data.success.url}`,
          selectedFile
        );

        message.loading(ErrorMessages.specUploads.specFileUnderProcess, 7);
        setUploading(false);
        hidePanel();
        return null;
      } catch (ex: any) {
        const error: string =
          ex.response?.data?.error ||
          ex.message ||
          ErrorMessages.specUploads.specSectionFileUploadFailed;
        message.error(error);
        setUploading(false);
        return null;
      }
    }

    return null;
  };

  useEffect(() => {
    if (!fileList.length) {
      form.setFieldsValue({
        title: "",
        version_name: "",
        version_date: null
      });
    }
  }, [fileList, form, mode]);

  useEffect(() => {
    if (mode !== "new") return;
    if (!fileList.length) return;
    const file = fileList[0];
    form.setFieldsValue({
      title: file.name,
      version_name: "V0",
      version_date: moment()
    });
  }, [fileList, form, mode]);

  useEffect(() => {
    if (mode !== "revision") return;
    if (!fileList.length) return;
    console.log("parentData ", parentData);
    const childSpecLength = parentData.child_specifications.length + 1;

    form.setFieldsValue({
      title: parentData.title,
      version_name: `V${childSpecLength}`,
      version_date: moment()
    });
  }, [fileList, form, mode, parentData]);

  const panelTitle =
    mode === "new" ? "Upload Spec Document" : "Upload New Version";

  return (
    <div>
      <Modal
        className="custom-drawer"
        title={panelTitle}
        width={420}
        style={{
          right: 0,
          bottom: 0,
          top: 40,
          padding: 0,
          position: "absolute"
        }}
        bodyStyle={{ height: "calc(100vh - 92px)" }}
        footer={null}
        open={showPanel}
        onCancel={() => {
          hidePanel();
        }}
        destroyOnClose
      >
        <div className="space-y-4 w-full px-3">
          <div className="w-full">
            <Dragger {...fileUploadDraggerProps} disabled={uploading}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              {fileList.length > 0 ? (
                <p className="ant-upload-text">{fileList[0].name}</p>
              ) : (
                <div>
                  <p className="ant-upload-text">
                    Click or drag a file to upload
                  </p>
                  <p className="ant-upload-hint">
                    Only .pdf file is supported.
                  </p>
                </div>
              )}
            </Dragger>
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleUpload}
            className={classNames({
              hidden: fileList.length === 0
            })}
            disabled={uploading}
          >
            <FormItem
              name="title"
              label="FILE NAME"
              rules={[
                {
                  required: true,
                  message: "Enter file name"
                }
              ]}
            >
              <Input placeholder="Enter file name" disabled={uploading} />
            </FormItem>
            <FormItem
              name="version_name"
              label="REVISION NUMBER"
              rules={[
                {
                  required: true,
                  message: "Enter revision number"
                }
              ]}
            >
              <Input placeholder="Enter revision number" disabled={uploading} />
            </FormItem>
            <FormItem
              name="version_date"
              label="REVISION DATE"
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Select a date"
                }
              ]}
            >
              <CIQDatePicker
                className="w-full date-picker-with-icon"
                placeholder="Select revision date"
                format={DATE_FORMAT_MMDDYYYY}
                disabled={uploading}
              />
            </FormItem>
            <Divider className="my-2" />
            {uploading ? (
              <Spin
                className="mt-4 text-[#3b3b3b] w-full"
                tip="Upload is in progress, this may take some time."
                size="default"
              />
            ) : (
              <div className="flex justify-end space-x-2 -mt-3">
                {/* <Button
                  onClick={() => {
                    form.resetFields();
                    setFileList([]);
                  }}
                  style={{ marginTop: 16 }}
                  disabled={uploading}
                >
                  Clear
                </Button> */}
                <Button
                  loading={uploading}
                  htmlType="submit"
                  style={{ marginTop: 16 }}
                  type="primary"
                >
                  {uploading ? "Uploading" : "Upload"}
                </Button>
              </div>
            )}
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default UploadSpecPanel;
