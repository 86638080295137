import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { SubmittalLog } from "models/submittal-log";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getLoggedinUserId } from "services/auth";
import { getUserCompany, matchUserProjectRole } from "utils/utils";
import { QUERY_PROJECT_USERS } from "services/graphQL/queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { AcceptanceStatus, EUserTypes } from "../../constants";

type TbtnAlternateAssign = {
  projectId: string;
  submittalData: SubmittalLog;
  onAssigneeSelect: Function;
  tooltip: string;
  disabled: boolean;
  loading: boolean;
};

export default function BtnAlternateAssign(props: TbtnAlternateAssign) {
  const {
    projectId,
    submittalData,
    onAssigneeSelect,
    disabled,
    tooltip,
    loading
  } = props;

  const { gqlClientForProject, tokenContents }: TProjectContext =
    useContext(ProjectContext);

  const [alternateAssignees, setAlternateAssignees] = useState<any>([]);

  const loggedInUserId = getLoggedinUserId();

  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserSC = matchUserProjectRole(
    EUserTypes.SUBCONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserArchitect = matchUserProjectRole(
    EUserTypes.ARCHITECT,
    tokenContents?.role
  );

  const isCurrentUserEngineer = matchUserProjectRole(
    EUserTypes.ENGINEER,
    tokenContents?.role
  );

  const where: any = {
    project_id: { _eq: projectId }
  };

  const { data: projectUsers } = useCIQQuery<{ project_users: Array<any> }>(
    QUERY_PROJECT_USERS,
    {
      client: gqlClientForProject,
      variables: {
        where
      }
    }
  );

  useEffect(() => {
    let tmpAlternateAssignees = [];
    if (isCurrentUserGC) {
      tmpAlternateAssignees =
        projectUsers?.project_users?.filter((user: any) => {
          return !user.external && !user.subscription_vendor;
        }) || [];
    }

    if (isCurrentUserSC || isCurrentUserArchitect || isCurrentUserEngineer) {
      tmpAlternateAssignees =
        projectUsers?.project_users?.filter((user: any) => {
          return (
            user.subscription_vendor &&
            user.subscription_vendor.id ===
              tokenContents?.claims["x-hasura-vendor-id"]
          );
        }) || [];
    }

    setAlternateAssignees(tmpAlternateAssignees);
  }, [
    isCurrentUserArchitect,
    isCurrentUserEngineer,
    isCurrentUserGC,
    isCurrentUserSC,
    projectUsers,
    tokenContents?.claims
  ]);

  const getAsigneeList = useCallback(() => {
    if (!alternateAssignees?.length) return [];

    if (isCurrentUserGC) {
      if (loggedInUserId === submittalData.gc_reviewer) {
        return alternateAssignees.filter(
          (item: any) => item.user_id !== loggedInUserId
        );
      }
      return alternateAssignees.filter(
        (item: any) => item.user_id === submittalData.gc_reviewer
      );
    }

    if (isCurrentUserSC) {
      if (loggedInUserId === submittalData.submitter) {
        return alternateAssignees.filter(
          (item: any) => item.user_id !== loggedInUserId
        );
      }
      return alternateAssignees.filter(
        (item: any) => item.user_id === submittalData.submitter
      );
    }

    if (isCurrentUserArchitect || isCurrentUserEngineer) {
      if (loggedInUserId === submittalData.design_reviewer) {
        return alternateAssignees.filter(
          (item: any) => item.user_id !== loggedInUserId
        );
      }
      return alternateAssignees.filter(
        (item: any) => item.user_id === submittalData.design_reviewer
      );
    }

    return [];
  }, [
    alternateAssignees,
    isCurrentUserArchitect,
    isCurrentUserEngineer,
    isCurrentUserGC,
    isCurrentUserSC,
    loggedInUserId,
    submittalData.design_reviewer,
    submittalData.gc_reviewer,
    submittalData.submitter
  ]);

  const menu = useMemo(() => {
    const assigneeList = getAsigneeList();
    if (!assigneeList || assigneeList.length === 0) {
      return (
        <Menu>
          <Menu.Item key={0}>No users found</Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu
        style={{
          maxHeight: "300px",
          overflow: "auto"
        }}
      >
        {assigneeList
          .filter((usr: any) => usr.status_id !== AcceptanceStatus.DEACTIVATED)
          .map((item: any) => {
            return (
              <Menu.Item
                key={item.user_id}
                onClick={() => {
                  onAssigneeSelect(
                    item.user.id,
                    `${item.user.first_name} ${item.user.last_name}`
                  );
                }}
              >
                <div>
                  {item.user.first_name} {item.user.last_name}
                </div>
                <div className="text-sm text-gray-500">
                  {getUserCompany(item)}
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    );
  }, [getAsigneeList, onAssigneeSelect]);

  return (
    <div>
      <Dropdown overlay={menu} disabled={disabled}>
        <Button disabled={disabled} title={tooltip} loading={loading}>
          <Space>
            Assign To
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
}
