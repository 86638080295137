import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useState } from "react";
import CiqAgGrid from "components/ciq-ag-grid";
import { GridOptions } from "ag-grid-community";
import { ProjectContext } from "context/ProjectProvider";
import { TLinkedMaterial } from "../models";
import { materialColDef } from "../col-defs/material-col-def";
import { submittalColDefs } from "../col-defs/submittal-col-def";

const MinHeight = 120;

function MaterialGrid({
  materials,
  gridRef
}: {
  materials: Array<TLinkedMaterial>;
  gridRef: React.RefObject<AgGridReact<TLinkedMaterial>>;
}) {
  const { projectDetails } = useContext(ProjectContext);
  const [height, setHeight] = useState(MinHeight);

  useEffect(() => {
    const colHeight = 42;
    let heightAll = 80;
    materials.forEach((m) => {
      heightAll = colHeight + heightAll;

      if (m.linked_submittals.length) {
        const headerHeight = 100;
        const offset = 54;
        const total =
          headerHeight + offset + m.linked_submittals.length * colHeight;
        heightAll = total + heightAll;
      }
    });
    setHeight(heightAll);
  }, [materials]);

  const calculatorGridHeight = () => {
    let heightAll = 80;
    gridRef.current?.api.forEachNode((node) => {
      if (node.rowHeight) heightAll = node.rowHeight + heightAll;
      if (node.expanded) {
        if (node.detailNode && node.detailNode.rowHeight)
          heightAll = node.detailNode.rowHeight + heightAll;
      }
    });
    setHeight(MinHeight > heightAll ? MinHeight : heightAll);
  };

  const detailCellRendererParams = useMemo<{
    detailGridOptions: GridOptions;
  }>(() => {
    return {
      // provide the Grid Options to use on the Detail Grid
      detailGridOptions: {
        defaultColDef: {
          sortable: true,
          filter: "agSetColumnFilter",
          menuTabs: ["filterMenuTab"],
          resizable: true,
          flex: 1
        },
        onGridReady(gridApi) {
          gridApi.api?.sizeColumnsToFit();
        },
        detailRowAutoHeight: true,
        columnDefs: submittalColDefs,
        context: { projectDetails },
        suppressMovableColumns: true
      },

      // get the rows for each Detail Grid
      getDetailRowData: (params: any) => {
        params.successCallback(
          params.data.linked_submittals.map((s: any) => s.submittal)
        );
      }
    };
  }, [projectDetails]);

  const isRowMaster = (data: TLinkedMaterial) =>
    data.linked_submittals?.length > 0;

  return (
    <div className="flex min-h-[120px]" style={{ height }}>
      <CiqAgGrid
        {...{
          gridRef,
          columnDefs: materialColDef,
          rowData: materials,
          sideBar: null,
          masterDetail: true,
          isRowMaster,
          detailCellRendererParams,
          detailRowAutoHeight: true,
          groupDefaultExpanded: 1,
          suppressMovableColumns: true,
          context: {
            projectDetails
          },
          onRowGroupOpened: () => {
            calculatorGridHeight();
          }
        }}
      />
    </div>
  );
}
export default MaterialGrid;
