import { getInitials } from "utils/utils";

function UserAvatar(props: { fullName: string; classList?: string }) {
  const { fullName, classList } = props;
  const initials = getInitials(fullName);

  const elementClassNames = `w-full h-full flex items-center justify-center uppercase cursor-default ${classList}`;

  return (
    <div
      style={{ backgroundColor: "#D9D9D999", color: "#3B3B3B" }}
      className={elementClassNames}
      title={fullName}
    >
      {initials}
    </div>
  );
}

export default UserAvatar;
