import { useMemo, useState } from "react";
import { DateUtils } from "utils/dateutils";
import "./RenderVisual.scss";

function formatDate(dt: any) {
  return dt ? DateUtils.format(dt, "MM / DD / YYYY") : "";
}

function shortFormatDate(dt: any) {
  return dt ? DateUtils.format(dt, "MM / DD / YY") : "";
}

function formatTheVisualData(data: any) {
  const result = {
    designPackageName: "",
    milestones: [
      { name: "SD", date: "" },
      { name: "25% DD", date: "" },
      { name: "50% DD", date: "" },
      { name: "100% DD", date: "" },
      { name: "CD", date: "" }
    ],
    bidPackageOne: {
      name: "",
      firstMilestone: { name: "", date: "", shortDate: "" },
      lastMilestone: { name: "", date: "", shortDate: "" },
      durations: { total: "", submittal: "", material: "" },
      activity: { name: "", date: "" }
    },
    bidPackageTwo: {
      name: "",
      firstMilestone: { name: "", date: "", shortDate: "" },
      lastMilestone: { name: "", date: "", shortDate: "" },
      durations: { total: "", submittal: "", material: "" },
      activity: { name: "", date: "" }
    }
  };

  result.designPackageName = data?.name;
  const milestones =
    data?.milestones?.map((m: any) => {
      return { /* name: m.name, */ date: formatDate(m.planned_date) };
    }) || [];

  for (let i = 0; i < milestones.length; i += 1) {
    result.milestones[i] = { ...result.milestones[i], ...milestones[i] };
  }

  if (data?.bidPackages?.length > 0) {
    const bid = data?.bidPackages[0];
    result.bidPackageOne.name = `${bid?.number} ${bid?.name}`;
    result.bidPackageOne.firstMilestone = {
      name: bid?.firstMilestone?.name,
      date: formatDate(bid?.firstMilestone?.planned_date),
      shortDate: shortFormatDate(bid?.firstMilestone?.planned_date)
    };
    result.bidPackageOne.lastMilestone = {
      name: bid?.lastMilestone?.name,
      date: formatDate(bid?.lastMilestone?.planned_date),
      shortDate: shortFormatDate(bid?.lastMilestone?.planned_date)
    };
    result.bidPackageOne.durations = {
      total:
        bid.offsetDuration.materialOffsetDuration +
        bid.offsetDuration.submittalOffsetDuration,
      submittal: bid?.offsetDuration?.submittalOffsetDuration,
      material: bid?.offsetDuration?.materialOffsetDuration
    };
    result.bidPackageOne.activity = {
      name: bid?.activity?.name,
      date: formatDate(bid?.activity?.startDate)
    };
  }

  if (data?.bidPackages?.length > 1) {
    const bid = data?.bidPackages[1];
    result.bidPackageTwo.name = `${bid?.number} ${bid?.name}`;
    result.bidPackageTwo.firstMilestone = {
      name: bid?.firstMilestone?.name,
      date: formatDate(bid?.firstMilestone?.planned_date),
      shortDate: shortFormatDate(bid?.firstMilestone?.planned_date)
    };
    result.bidPackageTwo.lastMilestone = {
      name: bid?.lastMilestone?.name,
      date: formatDate(bid?.lastMilestone?.planned_date),
      shortDate: shortFormatDate(bid?.lastMilestone?.planned_date)
    };
    result.bidPackageTwo.durations = {
      total:
        bid.offsetDuration.materialOffsetDuration +
        bid.offsetDuration.submittalOffsetDuration,
      submittal: bid?.offsetDuration?.submittalOffsetDuration,
      material: bid?.offsetDuration?.materialOffsetDuration
    };
    result.bidPackageTwo.activity = {
      name: bid?.activity?.name,
      date: formatDate(bid?.activity?.startDate)
    };
  }

  return result;
}

function RenderVisual(props: { data: any }) {
  const { data } = props;

  const [inHover, setHoverOne] = useState(false);
  const [inHover2, setHoverTwo] = useState(false);
  const [visualData, setVisualData] = useState<any>();

  useMemo(() => {
    const formattedData = formatTheVisualData(data);
    setVisualData(formattedData);
  }, [data]);

  return (
    <div className="flex">
      {visualData && (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1081 270"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVG Export - Revision 3">
            <rect
              id="Background"
              width="225"
              height="270"
              rx="2"
              fill="#F7F6F4"
            />
            <g id="Timeline Bounding Box">
              <mask id="path-2-inside-1_2013_52582" fill="white">
                <path d="M225 0H1081V90H225V0Z" />
              </mask>
              <path
                d="M1081 89H225V91H1081V89Z"
                fill="#D9D9D9"
                mask="url(#path-2-inside-1_2013_52582)"
              />
            </g>
            <g id="Timeline Bounding Box_2">
              <mask id="path-4-inside-2_2013_52582" fill="white">
                <path d="M225 90H1081V180H225V90Z" />
              </mask>
              <path
                d="M1081 179H225V181H1081V179Z"
                fill="#D9D9D9"
                mask="url(#path-4-inside-2_2013_52582)"
              />
            </g>
            <g id="Timeline Bounding Box_3">
              <mask id="path-6-inside-3_2013_52582" fill="white">
                <path d="M225 180H1081V270H225V180Z" />
              </mask>
              <path
                d="M1081 269H225V271H1081V269Z"
                fill="#D9D9D9"
                mask="url(#path-6-inside-3_2013_52582)"
              />
            </g>
            <g id="Design Package Name">
              <g id="Design Package Bounding Box">
                <mask id="path-8-inside-4_2013_52582" fill="white">
                  <path d="M0 0H225V90H0V0Z" />
                </mask>
                <path
                  d="M225 89H0V91H225V89Z"
                  fill="#D9D9D9"
                  mask="url(#path-8-inside-4_2013_52582)"
                />
              </g>
              <text
                id="design package 1"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="12"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="28.92" y="39.8636">
                  {visualData.designPackageName}
                </tspan>
              </text>
              <text
                id="design package"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="28.92" y="53.6364">
                  Design Package
                </tspan>
              </text>
            </g>
            <g id="Bid Package Name">
              <g id="Bid Package Bounding Box">
                <mask id="path-12-inside-5_2013_52582" fill="white">
                  <path d="M0 90H225V180H0V90Z" />
                </mask>
                <path
                  d="M225 179H0V181H225V179Z"
                  fill="#D9D9D9"
                  mask="url(#path-12-inside-5_2013_52582)"
                />
              </g>
              <text
                id="05A Structural Steel"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="12"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="58.9199" y="129.864">
                  {/* 05A Structural Steel */}
                  {visualData.bidPackageOne.name}
                </tspan>
              </text>
              <text
                id="bid package"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="58.9199" y="143.636">
                  Bid Package
                </tspan>
              </text>
            </g>
            <g id="Bid Package Name_2">
              <g id="Bid Package Bounding Box_2">
                <mask id="path-16-inside-6_2013_52582" fill="white">
                  <path d="M0 180H225V270H0V180Z" />
                </mask>
                <path
                  d="M225 269H0V271H225V269Z"
                  fill="#D9D9D9"
                  mask="url(#path-16-inside-6_2013_52582)"
                />
              </g>
              <text
                id="07A Roofing"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="12"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="58.9199" y="219.864">
                  {/* 07A Roofing */}
                  {visualData.bidPackageTwo.name}
                </tspan>
              </text>
              <text
                id="bid package_2"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="58.9199" y="233.636">
                  Bid Package
                </tspan>
              </text>
            </g>
            <path
              id="Dependency Marker Line "
              d="M680.5 205V65"
              stroke="#EF774B"
              strokeWidth="3"
              strokeDasharray="8 4"
            />
            <rect
              id="Timeline"
              x="280"
              y="51"
              width="621"
              height="16"
              fill="#D5D5D5"
            />
            <rect
              id="Small Timeline"
              x="437"
              y="115"
              width="288"
              height="8"
              fill="#EBEBEB"
            />
            <rect
              id="Small Timeline_2"
              x="679"
              y="205"
              width="132"
              height="8"
              fill="#EBEBEB"
            />
            <g id="Milestone Marker_Design Package">
              <text
                id="10 / 12 / 24"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="254.942" y="38.6364">
                  {/* 10 / 12 / 24 */}
                  {visualData.milestones[0].date}
                </tspan>
              </text>
              <text
                id="SD"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="273.918" y="24.6364">
                  {/* SD */}
                  {visualData.milestones[0].name}
                </tspan>
              </text>
              <rect
                id="Rectangle 3352"
                x="279.582"
                y="45"
                width="3.21067"
                height="22"
                fill="#838383"
              />
            </g>
            <g id="Milestone Marker_Design Package_2">
              <text
                id="11 / 10 / 24"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="357.851" y="38.6364">
                  {/* 11 / 10 / 24 */}
                  {visualData.milestones[1].date}
                </tspan>
              </text>
              <text
                id="25% DD"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="364.78" y="24.6364">
                  {/* 25% DD */}
                  {visualData.milestones[1].name}
                </tspan>
              </text>
              <rect
                id="Rectangle 3352_2"
                x="382.323"
                y="45"
                width="3.21067"
                height="22"
                fill="#838383"
              />
            </g>
            <text
              id="11 / 20 / 24"
              fill="#EF774B"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize="8"
              fontWeight="500"
              letterSpacing="0em"
            >
              <tspan x="384.008" y="76.9091">
                {/* 11 / 20 / 24 */}
                {visualData.bidPackageOne.firstMilestone.shortDate}
              </tspan>
            </text>
            <text
              id="1 / 20 / 25"
              fill="#EF774B"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize="8"
              fontWeight="500"
              letterSpacing="0em"
            >
              <tspan x="625.938" y="76.9091">
                {/* 1 / 20 / 25 */}
                {visualData.bidPackageTwo.firstMilestone.shortDate}
              </tspan>
            </text>
            <g id="Milestone Marker_Bid Package">
              <text
                id="11 / 20 / 24_2"
                fill="#151515"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="bold"
                letterSpacing="0em"
              >
                <tspan x="412.356" y="141.636">
                  {/* 11 / 20 / 24 */}
                  {visualData.bidPackageOne.firstMilestone.date}
                </tspan>
              </text>
              <text
                id="Bid Package Prep"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="397.989" y="156.636">
                  {/* Bid Package Prep */}
                  {visualData.bidPackageOne.firstMilestone.name}
                </tspan>
              </text>
              <rect
                id="Rectangle 3352_3"
                x="437"
                y="115"
                width="3"
                height="14"
                fill="#838383"
              />
            </g>
            <g id="Milestone Marker_Bid Package_2">
              <text
                id="1 / 20 / 25_2"
                fill="#151515"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="bold"
                letterSpacing="0em"
              >
                <tspan x="656.89" y="231.636">
                  {/* 1 / 20 / 25 */}
                  {visualData.bidPackageTwo.firstMilestone.date}
                </tspan>
              </text>
              <text
                id="Bid Package Prep_2"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="639.989" y="246.636">
                  {/* Bid Package Prep */}
                  {visualData.bidPackageTwo.firstMilestone.name}
                </tspan>
              </text>
              <rect
                id="Rectangle 3352_4"
                x="679"
                y="205"
                width="3"
                height="14"
                fill="#838383"
              />
            </g>
            <g id="Milestone Marker_Bid Package_3">
              <text
                id="2 / 1 / 25"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="706.264" y="141.636">
                  {/* 2 / 1 / 25 */}
                  {visualData.bidPackageOne.lastMilestone.date}
                </tspan>
              </text>
              <text
                id="Subcontract Executed"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="698.338" y="156.636">
                  {/* Subcontract{" "} */}
                  {visualData.bidPackageOne.lastMilestone.name}
                </tspan>
                <tspan x="705.203" y="168.636">
                  {/* Executed */}
                </tspan>
              </text>
              <rect
                id="Rectangle 3352_5"
                x="725"
                y="115"
                width="3"
                height="14"
                fill="#838383"
              />
            </g>
            <g id="Milestone Marker_Bid Package_4">
              <text
                id="2 / 25 / 25"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="786.461" y="231.636">
                  {/* 2 / 25 / 25 */}
                  {visualData.bidPackageTwo.lastMilestone.date}
                </tspan>
              </text>
              <text
                id="Subcontract Executed_2"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="782.338" y="246.636">
                  {/* Subcontract{" "} */}
                  {visualData.bidPackageTwo.lastMilestone.name}
                </tspan>
                <tspan x="789.203" y="258.636">
                  {/* Executed */}
                </tspan>
              </text>
              <rect
                id="Rectangle 3352_6"
                x="809"
                y="205"
                width="3"
                height="14"
                fill="#838383"
              />
            </g>
            <g id="Milestone Marker_Design Package_3">
              <text
                id="1 / 10 / 25"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="599.503" y="38.6364">
                  {/* 1 / 10 / 25 */}
                  {visualData.milestones[2].date}
                </tspan>
              </text>
              <text
                id="50% DD"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="603.426" y="24.6364">
                  {/* 50% DD */}
                  {visualData.milestones[2].name}
                </tspan>
              </text>
              <rect
                id="Rectangle 3353"
                x="620.983"
                y="45"
                width="3.21067"
                height="22"
                fill="#838383"
              />
            </g>
            <g id="Milestone Marker_Design Package_4">
              <text
                id="2 / 10 / 25"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="727.762" y="38.6364">
                  {/* 2 / 10 / 25 */}
                  {visualData.milestones[3].date}
                </tspan>
              </text>
              <text
                id="100% DD"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="730.608" y="24.6364">
                  {/* 100% DD */}
                  {visualData.milestones[3].name}
                </tspan>
              </text>
              <rect
                id="Rectangle 3354"
                x="750.48"
                y="45"
                width="3.21067"
                height="22"
                fill="#838383"
              />
            </g>
            <g id="Milestone Marker_Design Package_5">
              <text
                id="3 / 10 / 25"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="500"
                letterSpacing="0em"
              >
                <tspan x="875.296" y="38.6364">
                  {/* 3 / 10 / 25 */}
                  {visualData.milestones[4].date}
                </tspan>
              </text>
              <text
                id="CD"
                fill="#3B3B3B"
                fillOpacity="0.8"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Inter"
                fontSize="10"
                fontWeight="300"
                letterSpacing="0em"
              >
                <tspan x="892.56" y="24.6364">
                  {/* CD */}
                  {visualData.milestones[4].name}
                </tspan>
              </text>
              <rect
                id="Rectangle 3354_2"
                x="898.171"
                y="45"
                width="3.21067"
                height="22"
                fill="#838383"
              />
            </g>
            <path
              id="Vector"
              d="M45.499 127.367C45.274 127.601 44.8997 127.601 44.6747 127.367V127.367C44.4617 127.146 44.4617 126.796 44.6747 126.574L45.0869 126.146C46.1826 125.007 46.1826 123.16 45.0869 122.021C43.9911 120.882 42.2145 120.882 41.1187 122.021L40.7373 122.417C40.5122 122.651 40.1379 122.651 39.9129 122.417V122.417C39.6999 122.196 39.6999 121.846 39.9129 121.624L40.3251 121.196C41.8592 119.601 44.3464 119.601 45.8805 121.196C47.4146 122.791 47.4146 125.376 45.8805 126.971L45.499 127.367ZM43.1181 129.049C43.3311 129.27 43.3311 129.621 43.1181 129.842L42.706 130.271C41.1719 131.865 38.6846 131.865 37.1506 130.271C35.6165 128.676 35.6165 126.09 37.1506 124.496L37.532 124.099C37.757 123.865 38.1314 123.865 38.3564 124.099V124.099C38.5694 124.321 38.5694 124.671 38.3564 124.892L37.9442 125.321C36.8484 126.46 36.8484 128.307 37.9442 129.446C39.04 130.585 40.8166 130.585 41.9123 129.446L42.2938 129.049C42.5188 128.815 42.8931 128.815 43.1181 129.049V129.049ZM42.6906 123.687C42.9156 123.453 43.2899 123.453 43.5149 123.687V123.687C43.728 123.908 43.728 124.258 43.5149 124.48L40.3405 127.78C40.1154 128.014 39.7411 128.014 39.5161 127.78V127.78C39.3031 127.558 39.3031 127.208 39.5161 126.987L42.6906 123.687Z"
              fill="#3B3B3B"
              fillOpacity="0.8"
            />
            <path
              id="Vector_2"
              d="M45.499 217.367C45.274 217.601 44.8997 217.601 44.6747 217.367V217.367C44.4617 217.146 44.4617 216.796 44.6747 216.574L45.0869 216.146C46.1826 215.007 46.1826 213.16 45.0869 212.021C43.9911 210.882 42.2145 210.882 41.1187 212.021L40.7373 212.417C40.5122 212.651 40.1379 212.651 39.9129 212.417V212.417C39.6999 212.196 39.6999 211.846 39.9129 211.624L40.3251 211.196C41.8592 209.601 44.3464 209.601 45.8805 211.196C47.4146 212.791 47.4146 215.376 45.8805 216.971L45.499 217.367ZM43.1181 219.049C43.3311 219.27 43.3311 219.621 43.1181 219.842L42.706 220.271C41.1719 221.865 38.6846 221.865 37.1506 220.271C35.6165 218.676 35.6165 216.09 37.1506 214.496L37.532 214.099C37.757 213.865 38.1314 213.865 38.3564 214.099V214.099C38.5694 214.321 38.5694 214.671 38.3564 214.892L37.9442 215.321C36.8484 216.46 36.8484 218.307 37.9442 219.446C39.04 220.585 40.8166 220.585 41.9123 219.446L42.2938 219.049C42.5188 218.815 42.8931 218.815 43.1181 219.049V219.049ZM42.6906 213.687C42.9156 213.453 43.2899 213.453 43.5149 213.687V213.687C43.728 213.908 43.728 214.258 43.5149 214.48L40.3405 217.78C40.1154 218.014 39.7411 218.014 39.5161 217.78V217.78C39.3031 217.558 39.3031 217.208 39.5161 216.987L42.6906 213.687Z"
              fill="#3B3B3B"
              fillOpacity="0.8"
            />
            <path
              id="Dependency Marker Line _2"
              d="M438.5 115V65"
              stroke="#EF774B"
              strokeWidth="3"
              strokeDasharray="8 4"
            />
            <path
              id="Vector 44"
              d="M892.354 119.354C892.549 119.158 892.549 118.842 892.354 118.646L889.172 115.464C888.976 115.269 888.66 115.269 888.464 115.464C888.269 115.66 888.269 115.976 888.464 116.172L891.293 119L888.464 121.828C888.269 122.024 888.269 122.34 888.464 122.536C888.66 122.731 888.976 122.731 889.172 122.536L892.354 119.354ZM728 119.5H892V118.5H728V119.5Z"
              fill="#BEBEBE"
            />
            <path
              id="Arrow"
              d="M976.354 209.354C976.549 209.158 976.549 208.842 976.354 208.646L973.172 205.464C972.976 205.269 972.66 205.269 972.464 205.464C972.269 205.66 972.269 205.976 972.464 206.172L975.293 209L972.464 211.828C972.269 212.024 972.269 212.34 972.464 212.536C972.66 212.731 972.976 212.731 973.172 212.536L976.354 209.354ZM812 209.5H976V208.5H812V209.5Z"
              fill="#BEBEBE"
            />
            <g id="Activity Box">
              <rect
                x="892"
                y="108"
                width="91"
                height="37"
                rx="2"
                fill="white"
              />
              <rect
                x="892.25"
                y="108.25"
                width="90.5"
                height="36.5"
                rx="1.75"
                stroke="#3B3B3B"
                strokeOpacity="0.3"
                strokeWidth="0.5"
              />
              <g id="Group 1062">
                <text
                  id="BASE0024"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  letterSpacing="0em"
                >
                  <tspan x="912" y="121.636">
                    {/* BASE0024 */}
                    {visualData.bidPackageOne.activity.name}
                  </tspan>
                </text>
                <path
                  id="Vector_3"
                  d="M905.499 120.039C905.274 120.272 904.9 120.272 904.675 120.039V120.039C904.462 119.817 904.462 119.467 904.675 119.246L905.087 118.817C906.183 117.678 906.183 115.831 905.087 114.692C903.991 113.553 902.214 113.553 901.119 114.692L900.737 115.089C900.512 115.323 900.138 115.323 899.913 115.089V115.089C899.7 114.867 899.7 114.517 899.913 114.296L900.325 113.867C901.859 112.273 904.346 112.273 905.881 113.867C907.415 115.462 907.415 118.047 905.881 119.642L905.499 120.039ZM903.118 121.72C903.331 121.942 903.331 122.292 903.118 122.513L902.706 122.942C901.172 124.537 898.685 124.537 897.151 122.942C895.616 121.347 895.616 118.762 897.151 117.167L897.532 116.771C897.757 116.537 898.131 116.537 898.356 116.771V116.771C898.569 116.992 898.569 117.342 898.356 117.564L897.944 117.992C896.848 119.131 896.848 120.978 897.944 122.117C899.04 123.256 900.817 123.256 901.912 122.117L902.294 121.72C902.519 121.487 902.893 121.487 903.118 121.72V121.72ZM902.691 116.358C902.916 116.124 903.29 116.124 903.515 116.358V116.358C903.728 116.58 903.728 116.93 903.515 117.151L900.34 120.451C900.115 120.685 899.741 120.685 899.516 120.451V120.451C899.303 120.23 899.303 119.879 899.516 119.658L902.691 116.358Z"
                  fill="#3B3B3B"
                  fillOpacity="0.8"
                />
              </g>
              <g id="Group 1085">
                <text
                  id="06-01-2025"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  fontStyle="italic"
                  letterSpacing="0em"
                >
                  <tspan x="896" y="138.636">
                    {/* 06-01-2025 */}
                    {visualData.bidPackageOne.activity.date}
                  </tspan>
                </text>
              </g>
            </g>
            <g id="Activity Box_2">
              <rect
                x="976"
                y="198"
                width="81"
                height="37"
                rx="2"
                fill="white"
              />
              <rect
                x="976.25"
                y="198.25"
                width="80.5"
                height="36.5"
                rx="1.75"
                stroke="#3B3B3B"
                strokeOpacity="0.3"
                strokeWidth="0.5"
              />
              <g id="Group 1062_2">
                <text
                  id="PROC7015"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  letterSpacing="0em"
                >
                  <tspan x="994.072" y="211.636">
                    {/* PROC7015 */}
                    {visualData.bidPackageTwo.activity.name}
                  </tspan>
                </text>
                <path
                  id="Vector_4"
                  d="M988.399 209.986C988.186 210.238 987.798 210.238 987.585 209.986V209.986C987.417 209.788 987.417 209.497 987.585 209.298L987.992 208.817C988.956 207.678 988.956 205.831 987.992 204.692C987.028 203.553 985.466 203.553 984.502 204.692L984.211 205.037C983.998 205.288 983.61 205.288 983.397 205.037V205.037C983.229 204.838 983.229 204.547 983.397 204.348L983.804 203.867C985.153 202.273 987.341 202.273 988.69 203.867C990.039 205.462 990.039 208.047 988.69 209.642L988.399 209.986ZM986.305 211.773C986.473 211.971 986.473 212.262 986.305 212.461L985.898 212.942C984.549 214.537 982.361 214.537 981.012 212.942C979.663 211.347 979.663 208.762 981.012 207.167L981.303 206.823C981.516 206.571 981.904 206.571 982.117 206.823V206.823C982.285 207.022 982.285 207.313 982.117 207.511L981.71 207.992C980.746 209.131 980.746 210.978 981.71 212.117C982.674 213.256 984.236 213.256 985.2 212.117L985.491 211.773C985.704 211.521 986.092 211.521 986.305 211.773V211.773ZM985.84 206.411C986.053 206.159 986.441 206.159 986.654 206.411V206.411C986.822 206.609 986.822 206.9 986.654 207.099L983.862 210.399C983.649 210.65 983.261 210.65 983.048 210.399V210.399C982.88 210.2 982.88 209.909 983.048 209.71L985.84 206.411Z"
                  fill="#3B3B3B"
                  fillOpacity="0.8"
                />
              </g>
              <g id="Group 1085_2">
                <text
                  id="07-01-2025"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  fontStyle="italic"
                  letterSpacing="0em"
                >
                  <tspan x="980" y="228.636">
                    {/* 07-01-2025 */}
                    {visualData.bidPackageTwo.activity.date}
                  </tspan>
                </text>
              </g>
            </g>
            <g id="Component 1">
              <g
                id="Days"
                className="cursor-default"
                onMouseOver={() => {
                  console.log(12);
                  setHoverOne(true);
                }}
                onMouseOut={() => {
                  console.log(22);
                  setHoverOne(false);
                }}
              >
                <rect
                  x="783"
                  y="108"
                  width="51"
                  height="20"
                  rx="2"
                  fill="white"
                />
                <rect
                  x="783.25"
                  y="108.25"
                  width="50.5"
                  height="19.5"
                  rx="1.75"
                  stroke="#3B3B3B"
                  strokeOpacity="0.3"
                  strokeWidth="0.5"
                />
                <g id="Group 1085_3">
                  <text
                    id="120 days"
                    fill="#3B3B3B"
                    fillOpacity="0.6"
                    xmlSpace="preserve"
                    style={{ whiteSpace: "pre" }}
                    fontFamily="Inter"
                    fontSize="10"
                    letterSpacing="0em"
                  >
                    <tspan x="787" y="121.636">
                      {/* 120 days */}
                      {visualData.bidPackageOne.durations.total} days
                    </tspan>
                  </text>
                </g>
              </g>
              <g
                id="Dialog Box"
                style={{ visibility: inHover ? "visible" : "hidden" }}
              >
                <g id="Dialog_BP1" filter="url(#filter0_d_2013_52582)">
                  <mask id="path-71-inside-7_2013_52582" fill="white">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M901 35H718V96H802.868L808.663 102L814.459 96H901V35Z"
                    />
                  </mask>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M901 35H718V96H802.868L808.663 102L814.459 96H901V35Z"
                    fill="white"
                  />
                  <path
                    d="M718 35V34H717V35H718ZM901 35H902V34H901V35ZM718 96H717V97H718V96ZM802.868 96L803.587 95.3052L803.292 95H802.868V96ZM808.663 102L807.944 102.695L808.663 103.439L809.383 102.695L808.663 102ZM814.459 96V95H814.035L813.74 95.3052L814.459 96ZM901 96V97H902V96H901ZM718 36H901V34H718V36ZM719 96V35H717V96H719ZM802.868 95H718V97H802.868V95ZM809.383 101.305L803.587 95.3052L802.149 96.6948L807.944 102.695L809.383 101.305ZM813.74 95.3052L807.944 101.305L809.383 102.695L815.178 96.6948L813.74 95.3052ZM901 95H814.459V97H901V95ZM900 35V96H902V35H900Z"
                    fill="#EDEDED"
                    mask="url(#path-71-inside-7_2013_52582)"
                  />
                </g>
                <text
                  id="Submittal Plan: 40 days"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  letterSpacing="0em"
                >
                  <tspan x="731" y="58.6364">
                    Submittal Plan:{" "}
                    {visualData.bidPackageOne.durations.submittal} days
                  </tspan>
                </text>
                <text
                  id="Material Plan: 80 days"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  letterSpacing="0em"
                >
                  <tspan x="731" y="78.6364">
                    Material Plan: {visualData.bidPackageOne.durations.material}{" "}
                    days
                  </tspan>
                </text>
              </g>
            </g>
            <g id="Component 2">
              <g
                id="Days_2"
                className="cursor-default"
                onMouseOver={() => {
                  setHoverTwo(true);
                }}
                onMouseOut={() => {
                  setHoverTwo(false);
                }}
              >
                <rect
                  x="868"
                  y="198"
                  width="51"
                  height="20"
                  rx="2"
                  fill="white"
                />
                <rect
                  x="868.25"
                  y="198.25"
                  width="50.5"
                  height="19.5"
                  rx="1.75"
                  stroke="#3B3B3B"
                  strokeOpacity="0.3"
                  strokeWidth="0.5"
                />
                <g id="Group 1085_4">
                  <text
                    id="120 days_2"
                    fill="#3B3B3B"
                    fillOpacity="0.6"
                    xmlSpace="preserve"
                    style={{ whiteSpace: "pre" }}
                    fontFamily="Inter"
                    fontSize="10"
                    letterSpacing="0em"
                  >
                    <tspan x="872" y="211.636">
                      {/* 126 days */}
                      {visualData.bidPackageTwo.durations.total} days
                    </tspan>
                  </text>
                </g>
              </g>
              <g
                id="Dialog Box_2"
                style={{ visibility: inHover2 ? "visible" : "hidden" }}
              >
                <g id="Dialog" filter="url(#filter1_d_2013_52582)">
                  <mask id="path-78-inside-8_2013_52582" fill="white">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M986 125H803V186H887.868L893.663 192L899.459 186H986V125Z"
                    />
                  </mask>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M986 125H803V186H887.868L893.663 192L899.459 186H986V125Z"
                    fill="white"
                  />
                  <path
                    d="M803 125V124H802V125H803ZM986 125H987V124H986V125ZM803 186H802V187H803V186ZM887.868 186L888.587 185.305L888.292 185H887.868V186ZM893.663 192L892.944 192.695L893.663 193.439L894.383 192.695L893.663 192ZM899.459 186V185H899.035L898.74 185.305L899.459 186ZM986 186V187H987V186H986ZM803 126H986V124H803V126ZM804 186V125H802V186H804ZM887.868 185H803V187H887.868V185ZM894.383 191.305L888.587 185.305L887.149 186.695L892.944 192.695L894.383 191.305ZM898.74 185.305L892.944 191.305L894.383 192.695L900.178 186.695L898.74 185.305ZM986 185H899.459V187H986V185ZM985 125V186H987V125H985Z"
                    fill="#EDEDED"
                    mask="url(#path-78-inside-8_2013_52582)"
                  />
                </g>
                <text
                  id="Submittal Plan: 40 days_2"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  letterSpacing="0em"
                >
                  <tspan x="816" y="148.636">
                    Submittal Plan:{" "}
                    {visualData.bidPackageTwo.durations.submittal} days
                  </tspan>
                </text>
                <text
                  id="Material Plan: 80 days_2"
                  fill="#3B3B3B"
                  fillOpacity="0.6"
                  xmlSpace="preserve"
                  style={{ whiteSpace: "pre" }}
                  fontFamily="Inter"
                  fontSize="10"
                  letterSpacing="0em"
                >
                  <tspan x="816" y="168.636">
                    Material Plan: {visualData.bidPackageTwo.durations.material}{" "}
                    days
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_2013_52582"
              x="714"
              y="32"
              width="191"
              height="75"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2013_52582"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2013_52582"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_2013_52582"
              x="799"
              y="122"
              width="191"
              height="75"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2013_52582"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2013_52582"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
    </div>
  );
}

export default RenderVisual;
