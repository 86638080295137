import { Button, Dropdown, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { FilterItem } from "models/types";
import "./filter-chip.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";

type FilterChipProps = {
  items: FilterItem[];
  onChipDelete: any;
  onCategoryDelete: any;
  resetAll: Function;
};

function FilterChips(props: FilterChipProps) {
  const { items, onChipDelete, onCategoryDelete, resetAll } = props;
  const [filterItem, setFilterItem] = useState([] as Array<any>);
  const [openDropdown, setOpenDropdown] = useState(false);
  useEffect(() => {
    if (!items?.length) {
      setOpenDropdown(false);
      setFilterItem([] as Array<any>);
      return;
    }
    const filters = [] as Array<any>;
    (items || []).forEach((item: any) => {
      if (!item?.value) return;
      const colObj = {
        field: item?.field,
        header: item?.header,
        values: item?.value || [],
        range: item?.range
      };
      filters.push(colObj);
    });
    setFilterItem(filters);
  }, [items]);
  const handleDelete = (item: any) => {
    onChipDelete(item);
  };

  const totalFilterCount = () =>
    filterItem.reduce((acc: any, curr: any) => {
      return acc + curr.values.length;
    }, 0);

  const renderChip = (item: any, key: any) => {
    if (item.range) {
      return `${key}: From: ${DateUtils.dateTimeObj(item?.range.from).format(
        DATE_FORMAT_MMDDYYYY
      )} To: ${DateUtils.dateTimeObj(item?.range.to).format(
        DATE_FORMAT_MMDDYYYY
      )}`;
    }
    if (key === "") {
      return '""';
    }
    return key === null ? "(Blanks)" : key;
  };

  if (!filterItem.length) return null;

  return (
    <Dropdown
      placement="bottom"
      overlay={
        <div className="custom-filter-options">
          {filterItem.map((item: any) => {
            return (
              <div key={item.header}>
                <div className="custom-filter-header font-semibold mt-2 flex justify-between items-center">
                  {item.header}
                  <CloseCircleFilled onClick={() => onCategoryDelete(item)} />
                </div>
                <div className="custom-filter-options__container">
                  {item.values.map((key: any) => {
                    return (
                      <div
                        key={`k-${key}`}
                        className="flex flex-wrap gap-y-1 items-center custom-filter-values"
                      >
                        <Tooltip
                          title={
                            item.range
                              ? `From: ${DateUtils.dateTimeObj(
                                  item?.range.from
                                ).format(
                                  DATE_FORMAT_MMDDYYYY
                                )} To: ${DateUtils.dateTimeObj(
                                  item?.range.to
                                ).format(DATE_FORMAT_MMDDYYYY)}`
                              : ""
                          }
                        >
                          <div className="flex-1 truncate">
                            {renderChip(item, key)}
                          </div>
                        </Tooltip>

                        <CloseCircleFilled
                          className="ml-2"
                          onClick={() =>
                            handleDelete({
                              field: item.field,
                              value: key
                            })
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="flex w-full justify-end custom-filter-divider pt-2">
            <Button
              onClick={() => {
                items.forEach((item) => {
                  onCategoryDelete(item);
                });
                resetAll();
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
      }
      className="linking-container--nav__select"
      trigger={["click"]}
      open={openDropdown}
      onOpenChange={(open) => {
        setOpenDropdown(open);
      }}
    >
      <div className="flex flex-wrap gap-y-1 items-center custom-filter-bar">
        Filter <div className="custom-filter-count">{totalFilterCount()}</div>
      </div>
    </Dropdown>
  );
}

export default FilterChips;
