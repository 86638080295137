function LogoutIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.17651 2.538L4.94119 3.6304C3.56575 4.59499 2.66659 6.1925 2.66659 8.00001C2.66659 10.9455 5.0544 13.3333 7.99992 13.3333C10.9455 13.3333 13.3333 10.9455 13.3333 8.00001C13.3333 6.1925 12.4341 4.59499 11.0587 3.6304L11.8233 2.538C13.5427 3.74374 14.6666 5.74062 14.6666 8.00001C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00001C1.33325 5.74062 2.45721 3.74374 4.17651 2.538ZM7.33325 8.00001V1.33334H8.66658V8.00001H7.33325Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}

export default LogoutIcon;
