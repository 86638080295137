import { Button, Modal, message } from "antd";
import WorkflowTemplateBulkEditor from "components/workflow-template-bulk-editor";
import { milestonesForSubmittal } from "components/workflow-template-bulk-editor/data-transformations";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useContext, useMemo, useState } from "react";
import "./bulk-edit-submittal-durations.scss";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { UPDATE_PROJECT_FEATURE_DURATIONS } from "services/graphQL/mutations";
import DisplayNameListPopover from "components/bulk-edit-material/display-name-list";
import { ESubmittalStatus } from "../../constants";

function BulkEditSubmittalDurations(props: any) {
  const { showEdit, setShowEdit, selectedRows, submittals } = props;

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const [mutationBulkUpdateSubmittals] = useCIQMutation(
    UPDATE_PROJECT_FEATURE_DURATIONS,
    {
      client: gqlClientForProject
    }
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const selectedSubmittals: any = useMemo(() => {
    return selectedRows
      .filter((row: any) => {
        return row.workflow_status !== ESubmittalStatus.DONE;
      })
      .map((row: any) => {
        return submittals.find((submittal: any) => {
          return row.id === submittal.id;
        });
      });
  }, [selectedRows, submittals]);

  const transformedMilestones = useMemo(() => {
    if (!selectedSubmittals.length) return [];
    const milestones = milestonesForSubmittal(
      selectedSubmittals[0]?.date_block_data
    );
    return milestones;
  }, [selectedSubmittals]);

  const incomingMilestones = useMemo(() => {
    if (!transformedMilestones.length) return [];
    return transformedMilestones.map((milestone: any, index: number) => {
      let hasSameValues = true;
      const firstOffsetValue =
        selectedSubmittals[0].date_block_data[`offset_${index + 1}`];
      selectedSubmittals.forEach((material: any) => {
        const offsetValue = material.date_block_data[`offset_${index + 1}`];
        if (offsetValue !== firstOffsetValue) {
          hasSameValues = false;
        }
      });
      const milestoneToReturn = { ...milestone };

      if (hasSameValues) {
        milestoneToReturn.value = firstOffsetValue;
      }

      return milestoneToReturn;
    });
  }, [selectedSubmittals, transformedMilestones]);

  const [milestones, setMilestones] = useState(incomingMilestones);

  const incomingSameFloatValueAcrossSubmittals = useMemo(() => {
    if (!selectedSubmittals.length) return 0;
    let hasSameValues = true;
    const firstFloatValue = selectedSubmittals[0].float;
    selectedSubmittals.forEach((submittal: any) => {
      const floatValue = submittal.float;
      if (floatValue !== firstFloatValue) {
        hasSameValues = false;
      }
    });
    if (hasSameValues) {
      return firstFloatValue;
    }

    return null;
  }, [selectedSubmittals]);

  const [float, setFloat] = useState(
    incomingSameFloatValueAcrossSubmittals || ""
  );

  const onCancelEdit = () => {
    setMilestones(transformedMilestones);
    setFloat(incomingSameFloatValueAcrossSubmittals || "");
    setIsEditing(false);
  };

  const onSave = async (event: any) => {
    setIsSaving(true);
    event.stopPropagation();
    const submittalIds = selectedSubmittals.map(
      (submittal: any) => submittal.id
    );
    const offsets: any = {};
    milestones.forEach((milestone: any) => {
      if (milestone.offsetName && milestone.value)
        offsets[`offset_${milestone.sequence_no}`] = milestone.value;
    });

    const payload: any = {
      featureData: {
        submittals: submittalIds,
        offsets
      }
    };

    if (float) {
      payload.featureData.float = float;
    }

    const bulkEditResponse = await mutationBulkUpdateSubmittals({
      variables: payload
    });

    if (bulkEditResponse.success) {
      setIsEditing(false);
      message.success("Updated successfully.");
    }

    setIsSaving(false);
  };

  return (
    <Modal
      title="BULK EDIT DURATIONS"
      className="custom-drawer with-padding-fix"
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 148px)" }}
      width="calc(100vw - 200px)"
      footer={[
        <div className="flex w-full space-x-3 justify-end" key={100}>
          <Button
            onClick={() => {
              setShowEdit(false);
            }}
            key={101}
          >
            Close
          </Button>
        </div>
      ]}
      open={showEdit}
      onCancel={() => {
        setShowEdit(false);
      }}
      destroyOnClose
      keyboard={false}
    >
      <div className="bulk-edit-submittals px-2 -mt-2">
        <div className="font-semibold">
          <span className="tracking-wide">Selected Submittals:</span>{" "}
          {selectedRows.length}
        </div>

        {milestones.length > 0 ? (
          <div className="mt-2">
            Durations for completed milestones will not be updated.
          </div>
        ) : (
          <div className="mt-2 font-semibold">
            Durations cannot be edited as all the selected submittals are
            closed.
          </div>
        )}

        {milestones.length > 0 && (
          <div className="mt-2 panel">
            <div className="panel-header">
              <div className="w-full flex items-center justify-between">
                <div className="uppercase !text-gray-600">
                  <span className="-ml-1">
                    <DisplayNameListPopover
                      list={selectedSubmittals.map((submittal: any) => {
                        return { name: submittal.title };
                      })}
                    >
                      {selectedSubmittals.length}
                    </DisplayNameListPopover>
                  </span>{" "}
                  <span className="-ml-1">Submittals</span>
                </div>
                <div>
                  {!isEditing && (
                    <Button
                      type="primary"
                      size="small"
                      className="px-4"
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {(isEditing || isSaving) && (
                    <div className="space-x-3">
                      <Button
                        type="primary"
                        size="small"
                        className="px-4"
                        onClick={onSave}
                        disabled={isSaving}
                        loading={isSaving}
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        className="px-4"
                        onClick={() => {
                          onCancelEdit();
                        }}
                        disabled={isSaving}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-4">
              <WorkflowTemplateBulkEditor
                disabled={!isEditing}
                milestonesState={[milestones, setMilestones]}
                floatState={[float, setFloat]}
                entity="submittal"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default BulkEditSubmittalDurations;
