import { useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { SUBSCRIPTION_CALENDAR_LIST } from "services/graphQL/subscriptions";
import { QUERY_CALENDAR_LIST } from "services/graphQL/queries";
import { useCIQQuery } from "./ciq-gql-hooks";

export const useGetProjectCalendarHolidaysAndWeekends = (
  projectId: string,
  gqlClientForProject: any
) => {
  const [calendarOffs, setCalendarOffs] = useState({
    holidays: [],
    weekends: []
  });

  const { data: calHolidayData, refetch } = useCIQQuery(QUERY_CALENDAR_LIST, {
    client: gqlClientForProject,
    variables: { projectId },
    skip: !gqlClientForProject
  });

  useEffect(() => {
    if (
      calHolidayData?.gantt_calendar &&
      calHolidayData?.gantt_calendar?.length > 0
    ) {
      const calendar = calHolidayData.gantt_calendar[0];
      const holidays = calendar?.calendar_holiday_lists || [];
      const weekends: any = [];
      calendar?.calendar_shift_mapping?.forEach((weekday: any) => {
        if (weekday?.deleted) {
          weekends.push(weekday?.work_day);
        }
      });

      setCalendarOffs({ holidays, weekends });
    }
  }, [calHolidayData]);

  return { calendarOffs, refetch };
};

export const useOldGetProjectCalendarHolidaysAndWeekends = (
  projectId: string,
  gqlClientForProject: any
) => {
  const [calHolidayWeekend, setHolidayWeekend] = useState({
    holidays: [],
    weekends: []
  });
  const { data: calHolidayData } = useSubscription(SUBSCRIPTION_CALENDAR_LIST, {
    client: gqlClientForProject,
    fetchPolicy: "no-cache",
    variables: { projectId },
    skip: !gqlClientForProject
  });

  useEffect(() => {
    if (
      calHolidayData?.gantt_calendar &&
      calHolidayData?.gantt_calendar?.length > 0
    ) {
      const calendar = calHolidayData.gantt_calendar[0];
      const holidays = calendar?.calendar_holiday_lists || [];
      const weekends: any = [];
      calendar?.calendar_shift_mapping?.forEach((weekday: any) => {
        if (weekday?.deleted) {
          weekends.push(weekday?.work_day);
        }
      });

      setHolidayWeekend({ holidays, weekends });
    }
  }, [calHolidayData]);

  return calHolidayWeekend;
};
