import { Modal } from "antd";

type CreateNewVersionOfPdfProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  handleOk: Function;
  fileName: string;
};

function CreateNewVersionOfPdfModel({
  fileName,
  isModalOpen,
  setIsModalOpen,
  handleOk
}: CreateNewVersionOfPdfProps) {
  const onOkClick = () => {
    handleOk();
  };
  return (
    <Modal
      className=""
      title="Create markup file of PDF"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okText="Create"
      onOk={onOkClick}
    >
      <div className="flex">
        Do you want to Save as new markup file of {fileName}
      </div>
    </Modal>
  );
}
export default CreateNewVersionOfPdfModel;
