import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useContext, useEffect } from "react";
import { EUserTypes } from "constants/index";
import { Spin } from "antd";
import { matchUserProjectRole } from "utils/utils";
import { useHistory, useParams } from "react-router";
import GCProjectDashboard2 from "./gc-project-dashboard2";
import SCProjectDashboard from "./sc-project-dashboard";

import ArchProjectDashboard from "./arch-project-dashboard";

function ProjectDashboard() {
  const { projectId } = useParams() as any;
  const history = useHistory();

  const { tokenContents }: TProjectContext = useContext(ProjectContext);

  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserSC = matchUserProjectRole(
    EUserTypes.SUBCONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserArchitect = matchUserProjectRole(
    EUserTypes.ARCHITECT,
    tokenContents?.role
  );

  useEffect(() => {
    if (
      tokenContents?.role &&
      !isCurrentUserGC &&
      !isCurrentUserSC &&
      !isCurrentUserArchitect
    ) {
      history.replace(`/project/${projectId}/submittals`);
    }
  }, [
    history,
    isCurrentUserArchitect,
    isCurrentUserGC,
    isCurrentUserSC,
    projectId,
    tokenContents?.role
  ]);

  const dashboardRenderer = () => {
    if (isCurrentUserGC) {
      return <GCProjectDashboard2 />;
    }

    if (isCurrentUserSC) {
      return <SCProjectDashboard />;
    }

    if (isCurrentUserArchitect) {
      return <ArchProjectDashboard />;
    }

    return (
      <div className="h-screen flex items-center justify-center">
        <Spin size="large" />
      </div>
    );
  };
  return <div className="h-screen w-full ">{dashboardRenderer()}</div>;
}

export default ProjectDashboard;
