/* eslint-disable react/no-unstable-nested-components */
import { Button, Image, message, Spin } from "antd";
import MarkupLayerDbPdf from "libs/pdfLib/components/markupsDbLayer";
import PdfLoader from "libs/pdfLib/components/PdfLoader";
import { AttachmentDoc } from "models/submittal-log";
import { useEffect, useState } from "react";
import { getFileDownloadURL } from "services/file-mgmt";
import { downloadFileInBrowser } from "utils/utils";

// const imagesFileTypes = ["jpg", "jpeg", "png", "jfif"];
// const pdfFileTypes = ["pdf"];
// const imageReg = new RegExp(`.(${imagesFileTypes.join("|")})$`, "i");
// const pdfReg = new RegExp(`.(${pdfFileTypes.join("|")})$`, "i");

function DocumentView({ file }: { file: AttachmentDoc }) {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);

  async function init(f: AttachmentDoc) {
    const fileDownloadURLResp: any = await getFileDownloadURL([f.blob_key]);
    if (fileDownloadURLResp.data) {
      const link = fileDownloadURLResp.data.success.urls[f.blob_key];
      setUrl(link);
    }
    if (fileDownloadURLResp.error) {
      setError(true);
      message.error(fileDownloadURLResp.error.msg);
    }
  }

  useEffect(() => {
    setUrl(undefined);
    setError(false);
    init(file);
  }, [file]);

  const onExpiredUrl = () => {
    setUrl(undefined);
    setError(false);
    init(file);
  };

  function DocumentViewer() {
    if (error) return <div>Something wrong</div>;
    if (!url) return <Spin size="large" />;
    if (file.file_type.toLowerCase().indexOf("image") >= 0) {
      return <Image className="h-full w-full" src={url} />;
    }
    if (file.file_type.toLowerCase().indexOf("pdf") >= 0) {
      console.log(file.id);
      return (
        <PdfLoader url={url} onExpiredUrl={onExpiredUrl}>
          {(pdfDocument) => (
            <MarkupLayerDbPdf file={file} pdfDocument={pdfDocument} />
          )}
        </PdfLoader>
      );
    }
    return (
      <div>
        This file can&lsquo;t be viewed, click
        <Button
          type="link"
          className="italic hover:border-none"
          onClick={() => {
            downloadFileInBrowser(url, file.file_name);
          }}
        >
          here
        </Button>
        to download the file.
      </div>
    );
  }

  return <DocumentViewer />;
}

export default DocumentView;
