import React, { useContext } from "react";
import { Button, Result } from "antd";
import { getUser } from "services/auth";
import { useHistory } from "react-router";
import { AppContext } from "context/AppProvider";
import { ResultStatusType } from "antd/lib/result";

function NotFoundPage(props: {
  status?: ResultStatusType;
  title?: string;
  subTitle?: string;
}) {
  const {
    status = "404",
    title = "404",
    subTitle = "Sorry, the page you visited does not exist."
  } = props;
  const history = useHistory();
  const userInfo = getUser();
  const { inIframe }: any = useContext(AppContext);
  const subTitleDiv = inIframe ? (
    <div className="flex flex-col">
      <div>{subTitle}</div>
      <div className="flex flex-col pt-4">
        <div>Login to ConstructivIQ.</div>
        <div>
          Go to the Project you want to configure this partner card with.
        </div>
        <div>
          Go to Settings -&gt; Details and copy the URL available under
          Submittal Integration.
        </div>
      </div>
    </div>
  ) : (
    <div>{subTitle}</div>
  );
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitleDiv}
      extra={
        inIframe ? undefined : (
          <Button
            type="primary"
            onClick={() => {
              if (userInfo) {
                history.push("/projects");
              } else {
                history.push("/");
              }
            }}
          >
            Back Home
          </Button>
        )
      }
    />
  );
}
export default NotFoundPage;
