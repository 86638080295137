import SpecNumberNameDropDown from "components/spec-number-name";
import { forwardRef, useState, useImperativeHandle } from "react";

const SpecSectionCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing, data, specNoField, specNameField } = props;

  const [specIdValue, setSpecIdValue] = useState(data.spec_section_id || null);
  const [specNoValue, setSpecNoValue] = useState(data[specNoField] || null);
  const [specNameValue, setSpecNameValue] = useState(
    data[specNameField] || null
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return { specIdValue, specNoValue, specNameValue };
      }
    };
  });

  return (
    <SpecNumberNameDropDown
      defaultSpecName={specNameValue}
      defaultSpecNumber={specNoValue}
      onChange={(
        id: string | null,
        number: string | null,
        name: string | null
      ) => {
        setSpecIdValue(id);
        setSpecNoValue(number);
        setSpecNameValue(name);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    />
  );
});

export default SpecSectionCellEditor;
