import { useContext, useState } from "react";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { isProjectInIntegrationMode } from "utils/utils";
import { useProjectReportSummary } from "hooks/project";
import { Spin } from "antd";
import ErrorBoundary from "components/error-boundary";
import SubmittalRiskDetail from "./submittal-risk-detail";
import RiskSummary from "./risk-summary";
import MaterialRiskDetail from "./material-risk-detail";
import { RiskStates } from "../../constants";

function RiskReportExportPDF() {
  const [selectedDetail, setSelectedDetail] = useState<null | {
    type: "submittal" | "material";
    risk: RiskStates.HIGH | RiskStates.MEDIUM | RiskStates.LOW;
  }>({ type: "submittal", risk: RiskStates.HIGH });

  const { gqlClientForProject, projectDetails, eventLogs }: TProjectContext =
    useContext(ProjectContext);

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const riskSummaryData: any = useProjectReportSummary({
    gqlClientForProject,
    eventLogs
  });

  return (
    <div className="risk-report-page bg-white m-1.5 p-2 pb-0">
      {riskSummaryData ? (
        <div>
          <h1 className="text-[16px] font-bold mb-1.5">SUMMARY</h1>
          <div>
            <ErrorBoundary>
              <RiskSummary
                onCountClick={setSelectedDetail}
                riskSummaryData={riskSummaryData}
                detailsClickEnabled
              />
            </ErrorBoundary>
            {selectedDetail ? (
              <div>
                {selectedDetail.type === "submittal" && (
                  <ErrorBoundary>
                    <SubmittalRiskDetail
                      risk={selectedDetail.risk}
                      {...{
                        isIntegrationMode
                      }}
                      key={selectedDetail.risk}
                      printMode
                    />
                  </ErrorBoundary>
                )}
                {selectedDetail.type === "material" && (
                  <ErrorBoundary>
                    <MaterialRiskDetail
                      risk={selectedDetail.risk}
                      {...{
                        isIntegrationMode
                      }}
                      key={selectedDetail.risk}
                    />
                  </ErrorBoundary>
                )}
              </div>
            ) : (
              <div className="flex justify-center">
                <div className="mt-40">
                  Click within the table to get more details.
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="h-full flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}

export default RiskReportExportPDF;
