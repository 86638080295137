import { useMutation } from "@apollo/client";
import {
  MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK,
  MUTATION_UPDATE_SUBMITTAL_BY_ID
} from "services/graphQL/mutations";

export const useSubmittalLogMutations = ({
  gqlClientForProject
}: {
  gqlClientForProject: any;
}) => {
  const [updateSubmittalById] = useMutation<any>(
    MUTATION_UPDATE_SUBMITTAL_BY_ID,
    {
      client: gqlClientForProject
    }
  );

  const [updateSDB] = useMutation(MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK, {
    client: gqlClientForProject
  });
  return { updateSubmittalById, updateSDB };
};
