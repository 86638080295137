import { Button, Form, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { VRules } from "components/workflow-template-editor/validation-rules";

function EditablePopover({
  message,
  placeholder,
  disable = false,
  onSubmit
}: {
  message: string;
  placeholder?: string;
  disable?: boolean;
  onSubmit: (result: any) => void;
}) {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const [inputData, setInputData] = useState(message);

  useEffect(() => {
    setInputData(message);
  }, [message, open]);

  const contentForm = useMemo(
    () =>
      open && (
        <Form
          layout="vertical"
          className="w-80 m-0 p-0"
          onFinish={(value) => {
            console.log(value);

            if (onSubmit) onSubmit(inputData || "");
            hide();
          }}
          validateTrigger="onChange"
        >
          <Form.Item
            name="name"
            rules={VRules.ActionInfo}
            initialValue={inputData}
          >
            <textarea
              rows={5}
              value={inputData}
              placeholder={placeholder}
              onChange={(event) => {
                setInputData(event.target.value || "");
              }}
              className="grow w-full"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </Form.Item>

          <div className="w-full my-1 px-1 space-x-2 flex justify-end">
            <Button className="w-24" onClick={hide}>
              Cancel
            </Button>
            <Button className="w-24" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      ),
    [inputData, onSubmit, open, placeholder]
  );

  return (
    <Popover
      content={
        disable ? <div className="w-80 m-0 p-0">{message}</div> : contentForm
      }
      title={null}
      className="p-0 m-0"
      trigger={disable ? "hover" : "click"}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <InfoCircleOutlined />
    </Popover>
  );
}

export default EditablePopover;
