export default function DatePickerIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 456">
        <rect id="Rectangle 1010" width="16" height="16" fill="#F5F5F5" />
        <g id="Group 455">
          <path
            id="Union"
            d="M6.2 2V3.2H9.8V2H11V3.2H13.4C13.7314 3.2 14 3.46863 14 3.8V13.4C14 13.7314 13.7314 14 13.4 14H2.6C2.26863 14 2 13.7314 2 13.4V3.8C2 3.46863 2.26863 3.2 2.6 3.2H5V2H6.2ZM12.8 8H3.2V12.8H12.8V8ZM5 4.4H3.2V6.8H12.8V4.4H11V5.6H9.8V4.4H6.2V5.6H5V4.4Z"
            fill="#3B3B3B"
            fillOpacity="0.8"
          />
          <g id="Group 454">
            <path
              id="Line 21"
              d="M5 10.4H11"
              stroke="#3B3B3B"
              strokeOpacity="0.8"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              id="Line 22"
              d="M5 9.39999C4.44772 9.39999 4 9.84771 4 10.4C4 10.9523 4.44772 11.4 5 11.4V9.39999ZM5 11.4H9.2V9.39999H5V11.4Z"
              fill="#DCDCDC"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
