import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  useCallback
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { Button, message } from "antd";

import { ColDef } from "ag-grid-community";
import { FilterItem } from "models/types";
import FilterChips from "components/filter-chips";
import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";
import {
  projectDateCellRenderer,
  IdLinkProjectSumbittalListComponent
} from "components/cell-renders";
import dateCellEditor from "components/cell-editor/date-cell-editor";
import { useAcceptProjectInvite } from "hooks/project";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { useHistory } from "react-router";
import { GridLoadingIndicator } from "components/widgets";
import { useQuery } from "@apollo/client";
import {
  QUERY_GET_PROJECT_LIST,
  QUERY_GET_SUBSCRIPTION_DETAILS
} from "services/graphQL/queries";
import { DateUtils } from "utils/dateutils";
import { AcceptanceStatus, ErrorMessages } from "../../constants";
import { TProject } from "./models";
import CustomDateFilters from "../../pages/submittals-list/custom-date-filter";

function ProjectLinkWithInviteRenderer(props: any) {
  const { data, onInviteAccepted } = props;
  const { acceptProjectInvite, acceptanceState } = useAcceptProjectInvite();

  const onAccept = () => {
    acceptProjectInvite(data.id);
  };

  useEffect(() => {
    if (acceptanceState.isCompleted) {
      onInviteAccepted();
    }
  }, [acceptanceState, onInviteAccepted]);

  return (
    <div className="w-full flex items-center justify-between">
      <div className="text-ellipsis overflow-hidden">
        <IdLinkProjectSumbittalListComponent {...props} />
      </div>
      {data.status_id === AcceptanceStatus.INVITED && (
        <div>
          <Button
            onClick={() => {
              onAccept();
            }}
            loading={acceptanceState.isLoading}
            disabled={acceptanceState.isLoading}
          >
            Accept Project Invite
          </Button>
        </div>
      )}
    </div>
  );
}

function ProjectAgGridListComponent() {
  const history = useHistory();
  const { subscriptionRole, subscriptionId } = useContext(SubscriptionContext);

  const gridRef = useRef<AgGridReact>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [filters, setFilters] = useState<FilterItem[]>([]);

  const [projectListData, setProjectListData] = useState<any>(null);
  const [showClearBtn, setShowClearBtn] = useState(false);

  const { data: subscriptionDetail } = useQuery(
    QUERY_GET_SUBSCRIPTION_DETAILS,
    {
      variables: {
        id: subscriptionId
      }
    }
  );

  const {
    refetch: getProjectList,
    data: projectListQueryData,
    error: projectListQueryError
  } = useQuery(QUERY_GET_PROJECT_LIST, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        status_id: { _neq: AcceptanceStatus.DEACTIVATED }
      }
    }
  });

  useEffect(() => {
    if (projectListQueryError) {
      message.error("An error occured");
      console.log(projectListQueryError);
    }
  }, [projectListQueryError]);

  const fetchProjectList = useCallback(async () => {
    try {
      const projectListResponse = await getProjectList();
      if (projectListResponse.data) {
        setProjectListData(projectListResponse.data);
      } else {
        message.error("An error occured");
        console.log(projectListResponse);
      }
    } catch (ex) {
      message.error("An error occured");
      console.log(ex);
    }
  }, [getProjectList]);

  useEffect(() => {
    setProjectListData(projectListQueryData);
  }, [projectListQueryData]);

  useEffect(() => {
    if (!projectListData) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [projectListData]);

  const onFilterTextBoxChanged = (value: string) => {
    if (gridRef.current) gridRef.current.api.setQuickFilter(value);
  };

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isGridReady]);

  const [customDateFilter, setCustomDateFilter] = useState<any>({});
  const onInviteAccepted = () => {
    fetchProjectList();
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      colId: "name",
      field: "name",
      headerName: "Project Name",
      headerTooltip: "PROJECT NAME",
      tooltipField: "name",
      menuTabs: [],
      width: 350,
      minWidth: 120,
      maxWidth: 500,
      cellRenderer: ProjectLinkWithInviteRenderer,
      cellRendererParams: {
        onInviteAccepted
      },
      comparator: (valueA: any, valueB: any) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      },
      sort: "asc"
    },
    {
      colId: "number",
      field: "number",
      headerName: "Project Number",
      headerTooltip: "PROJECT NUMBER",
      menuTabs: [],
      width: 160,
      minWidth: 70,
      maxWidth: 200
    },
    {
      colId: "details",
      field: "details",
      headerName: "Detail",
      headerTooltip: "PROJECT DETAILS",
      menuTabs: [],
      width: 250,
      minWidth: 120,
      maxWidth: 500,
      comparator: (valueA: any, valueB: any) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
    },
    {
      colId: "address",
      field: "address",
      headerName: "Address",
      headerTooltip: "ADDRESS",
      width: 500,
      autoHeight: true,
      wrapText: true,
      menuTabs: [],
      comparator: (valueA: any, valueB: any) => {
        return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
      }
    },
    {
      colId: "start_date",
      field: "start_date",
      headerName: "Start Date",
      headerTooltip: "START DATE",
      width: 200,
      minWidth: 70,
      maxWidth: 350,
      autoHeight: true,
      cellEditor: dateCellEditor,
      menuTabs: ["filterMenuTab"],
      cellEditorParams: {
        type: "date-field"
      },
      cellEditorPopup: false,
      cellRenderer: projectDateCellRenderer,
      cellRendererParams: { field: "start_date" },
      filter: CustomDateFilters,
      filterParams: {
        title: "Start Date",
        columnData: {
          field: "start_date",
          header: "Start Date"
        },
        setCustomDateFilter
      },
      getQuickFilterText(params: { data: TProject }) {
        return params.data.start_date
          ? DateUtils.format(params.data.start_date)
          : "";
      },
      comparator: (value1: string, value2: string): number => {
        const date1 = new Date(value1);
        const date2 = new Date(value2);
        if (date1 === date2) {
          return 0;
        }
        return date1 > date2 ? 1 : -1;
      },
      tooltipValueGetter: (params) => {
        return params.data.start_date
          ? DateUtils.format(params.data.start_date)
          : "";
      }
    },
    {
      colId: "end_date",
      field: "end_date",
      headerName: "End Date",
      headerTooltip: "END DATE",
      width: 200,
      minWidth: 70,
      maxWidth: 350,
      autoHeight: true,
      menuTabs: ["filterMenuTab"],
      cellEditor: dateCellEditor,
      cellEditorParams: {
        type: "date-field"
      },
      cellEditorPopup: false,
      cellRenderer: projectDateCellRenderer,
      cellRendererParams: { field: "end_date" },
      filter: CustomDateFilters,
      filterParams: {
        title: "End Date",
        columnData: {
          field: "end_date",
          header: "End Date"
        },
        setCustomDateFilter
      },
      getQuickFilterText(params: { data: TProject }) {
        return params.data.end_date
          ? DateUtils.format(params.data.end_date)
          : "";
      },
      comparator: (value1: string, value2: string): number => {
        const date1 = new Date(value1);
        const date2 = new Date(value2);
        if (date1 === date2) {
          return 0;
        }
        return date1 > date2 ? 1 : -1;
      },
      tooltipValueGetter: (params) => {
        return params.data.end_date
          ? DateUtils.format(params.data.end_date)
          : "";
      }
    }
  ]);

  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      filter: "agDateColumnFilter",
      autoSizeAllColumns: true,
      resizable: true,
      editable: false
    };
  }, []);

  const resetFilters = () => {
    setShowClearBtn(false);
    // setGridSearchtext("");
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "name" || "number" || "details" || "address":
          columnDefArr[index].sortable = true;
          break;
        default:
          columnDefArr[index].sortable = true;
          columnDefArr[index].suppressMenu = false;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
    gridRef.current!.api.setFilterModel(null);
    gridRef.current!.api.setQuickFilter("");
  };

  function setSortAndFilterVisibility(
    isEnabled: boolean,
    isRowDataUpdated: boolean
  ) {
    setShowClearBtn(isRowDataUpdated ? false : !isEnabled);
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "name" || "number" || "details" || "address":
          columnDefArr[index].sortable = isEnabled;
          break;
        default:
          columnDefArr[index].sortable = isEnabled;
          columnDefArr[index].suppressMenu = !isEnabled;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
  }

  const onFiltersApplied = (event: any) => {
    const filtersApplied = event.api.getFilterModel();
    if (filtersApplied) {
      const items: FilterItem[] = new Array<FilterItem>();
      Object.keys(filtersApplied).forEach((key: any) => {
        if (
          filtersApplied[key]?.values &&
          filtersApplied[key].values.length > 0
        ) {
          const field = columnDefs.filter((x) => x.field === key)[0];
          const { values } = filtersApplied[key];

          items.push({
            field: key,
            header: field ? field.headerName : key,
            value: values.filter(Boolean),
            range: filtersApplied[key].range ? filtersApplied[key].range : null
          });
        }
      });
      setFilters(items.filter(Boolean));
    }

    if (
      gridRef?.current &&
      gridRef.current.api?.getDisplayedRowCount() < 2 &&
      gridRef.current.api?.isAnyFilterPresent()
    ) {
      setSortAndFilterVisibility(false, false);
    } else {
      setSortAndFilterVisibility(true, false);
    }
  };

  const onFilterChipDelete = (item: FilterItem) => {
    if (item.field === customDateFilter.field) {
      const index = filters.findIndex(
        (val) => val.field === customDateFilter.field
      );
      if (index > -1) {
        delete filters[index];
      }
      setFilters([...filters].filter(Boolean));
      setCustomDateFilter({});
      gridRef.current!.api.destroyFilter(customDateFilter.field);
    } else {
      gridRef.current!.api.destroyFilter(item.field);
    }
  };

  const renderProjects = () => {
    if (projectListData && projectListData?.project_list_func.length === 0) {
      return (
        <div className="h-[300px] flex items-center justify-center">
          <span>
            Start by creating projects or contact your Admin to invite you to a
            project
          </span>
        </div>
      );
    }

    return (
      <AgGridReact<any>
        ref={gridRef}
        onGridReady={() => {
          setGridReady(true);
          gridRef.current!.api.sizeColumnsToFit();
        }}
        rowData={projectListData?.project_list_func}
        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        suppressContextMenu
        onFilterChanged={onFiltersApplied}
        loadingOverlayComponent={GridLoadingIndicator}
        className="ag-theme-alpine"
        suppressScrollOnNewData
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
        onRowDataUpdated={() => {
          if (
            gridRef.current &&
            gridRef.current.api.getDisplayedRowCount() < 2
          ) {
            setSortAndFilterVisibility(false, true);
          } else {
            setSortAndFilterVisibility(true, true);
          }
        }}
      />
    );
  };

  return (
    <>
      <ActionBar>
        <div className="flex space-x-2 justify-end w-full items-center">
          <SearchInput
            placeholder="Search Projects"
            onChange={onFilterTextBoxChanged}
          />
          <FilterChips
            items={filters}
            onChipDelete={onFilterChipDelete}
            onCategoryDelete={(item: FilterItem) => {
              gridRef?.current?.api?.destroyFilter(item.field);
            }}
            resetAll={resetFilters}
          />
          {showClearBtn && !filters?.length && (
            <Button
              onClick={() => {
                filters.forEach((item: any) => {
                  gridRef?.current?.api?.destroyFilter(item.field);
                });

                resetFilters();
              }}
            >
              Clear All
            </Button>
          )}
          <Button
            title={
              !subscriptionDetail?.subscription_by_pk?.paid ||
              subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
                ? ErrorMessages.PermissionNotGranted
                : "Add Project"
            }
            onClick={() => history.push("/new-project")}
            disabled={
              !subscriptionDetail?.subscription_by_pk?.paid ||
              subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
            }
          >
            Add Project
          </Button>
        </div>
      </ActionBar>
      <div className="grow px-2 pb-2">{renderProjects()}</div>
    </>
  );
}

export default ProjectAgGridListComponent;
