function SpecSectionIcon() {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 27 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1_5" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.6154 1C26.6154 0.447715 26.1677 0 25.6154 0H4.61539C4.0631 0 3.61539 0.447716 3.61539 1V6.79657C3.61539 7.15384 3.42479 7.48397 3.11539 7.6626L0.5 9.17259C-0.166667 9.55749 -0.166667 10.5197 0.5 10.9046L3.11539 12.4146C3.42479 12.5933 3.61539 12.9234 3.61539 13.2807V17C3.61539 17.5523 4.0631 18 4.61539 18H25.6154C26.1677 18 26.6154 17.5523 26.6154 17V1Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6154 1C26.6154 0.447715 26.1677 0 25.6154 0H4.61539C4.0631 0 3.61539 0.447716 3.61539 1V6.79657C3.61539 7.15384 3.42479 7.48397 3.11539 7.6626L0.5 9.17259C-0.166667 9.55749 -0.166667 10.5197 0.5 10.9046L3.11539 12.4146C3.42479 12.5933 3.61539 12.9234 3.61539 13.2807V17C3.61539 17.5523 4.0631 18 4.61539 18H25.6154C26.1677 18 26.6154 17.5523 26.6154 17V1Z"
        fill="white"
      />
      <path
        d="M3.11539 12.4146L3.61539 11.5486L3.11539 12.4146ZM0.5 9.17259L1 10.0386L0.5 9.17259ZM4.61539 1H25.6154V-1H4.61539V1ZM4.61539 6.79657V1H2.61539V6.79657H4.61539ZM1 10.0386L3.61539 8.52862L2.61539 6.79657L-2.08239e-07 8.30657L1 10.0386ZM3.61539 11.5486L1 10.0386L-6.92492e-09 11.7707L2.61539 13.2807L3.61539 11.5486ZM4.61539 17V13.2807H2.61539V17H4.61539ZM25.6154 17H4.61539V19H25.6154V17ZM25.6154 1V17H27.6154V1H25.6154ZM25.6154 19C26.72 19 27.6154 18.1046 27.6154 17H25.6154L25.6154 17V19ZM2.61539 17C2.61539 18.1046 3.51082 19 4.61539 19V17L4.61539 17H2.61539ZM2.61539 13.2807L2.61539 13.2807H4.61539C4.61539 12.5661 4.23419 11.9059 3.61539 11.5486L2.61539 13.2807ZM-2.08239e-07 8.30657C-1.33333 9.07637 -1.33333 11.0009 -6.92492e-09 11.7707L1 10.0386L1 10.0386L-2.08239e-07 8.30657ZM2.61539 6.79657L2.61539 6.79657L3.61539 8.52862C4.23419 8.17136 4.61539 7.5111 4.61539 6.79657H2.61539ZM25.6154 1L25.6154 1H27.6154C27.6154 -0.104569 26.72 -1 25.6154 -1V1ZM4.61539 -1C3.51082 -1 2.61539 -0.104568 2.61539 1H4.61539L4.61539 1V-1Z"
        fill="rgb(24, 29, 31)"
        fillOpacity="0.8"
        mask="url(#path-1-inside-1_1_5)"
      />
      <path
        d="M9.66974 7H23.6697"
        stroke="rgb(24, 29, 31)"
        strokeOpacity="0.8"
        strokeLinecap="round"
      />
      <path
        d="M9.66974 9H23.6697"
        stroke="rgb(24, 29, 31)"
        strokeOpacity="0.8"
        strokeLinecap="round"
      />
      <path
        d="M16.6697 11L23.6697 11"
        stroke="rgb(24, 29, 31)"
        strokeOpacity="0.8"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SpecSectionIcon;
