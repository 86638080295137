/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { fabric } from "fabric";
import { PdfConfig } from "../config";

export class FabricHelper {
  public static FabricCalcArrowAngle(
    x1: any,
    y1: any,
    x2: any,
    y2: any
  ): number {
    let angle = 0;
    const x = x2 - x1;
    const y = y2 - y1;
    if (x === 0) {
      angle = y === 0 ? 0 : y > 0 ? Math.PI / 2 : (Math.PI * 3) / 2;
    } else if (y === 0) {
      angle = x > 0 ? 0 : Math.PI;
    } else {
      angle =
        x < 0
          ? Math.atan(y / x) + Math.PI
          : y < 0
          ? Math.atan(y / x) + 2 * Math.PI
          : Math.atan(y / x);
    }
    return (angle * 180) / Math.PI + 90;
  }

  // Double-click event handler
  public static fabricDblClick(obj: any, handler: any) {
    return function () {
      if (obj.clicked) handler(obj);
      else {
        obj.clicked = true;
        setTimeout(function () {
          obj.clicked = false;
        }, 500);
      }
    };
  }

  public static ungroup(group: any, canvas: fabric.Canvas) {
    console.log("ungroup");
    const items = group._objects;
    group._restoreObjectsState();
    canvas.remove(group);
    canvas.renderAll();
    for (let i = 0; i < items.length; i++) {
      canvas.add(items[i]);
    }
    // if you have disabled render on addition
    canvas.renderAll();
    return items;
  }

  public static rotate(
    centerX: number,
    centerY: number,
    x: number,
    y: number,
    angle: number
  ): { x: number; y: number } {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * (x - centerX) + sin * (y - centerY) + centerX;
    const ny = cos * (y - centerY) - sin * (x - centerX) + centerY;
    return { x: nx, y: ny };
  }

  public static newITextFromText(pointer: {
    x: number;
    y: number;
  }): fabric.IText {
    return new fabric.IText("", {
      top: pointer.y,
      left: pointer.x,
      fontSize: 25,
      fontWeight: "normal",
      selectable: false,
      backgroundColor: "#FFFDFC",
      fill: PdfConfig.color.text,
      padding: 4,
      originX: "center",
      originY: "center"
    });
  }

  public static allowTextEditingInGroup(
    group: fabric.Group,
    canvas: fabric.Canvas,
    callbackTextEditing?: Function
  ) {
    if (group._objects) {
      const dimensionText = (group._objects as any[]).find(
        (o) => o.type === "i-text"
      );
      let items = group._objects as Array<fabric.Object>;
      if (dimensionText) {
        dimensionText.on("editing:exited", () => {
          for (let i = 0; i < items.length; i++) {
            canvas.remove(items[i]);
          }
          const grp = new fabric.Group(items, {
            lockScalingFlip: true,
            name: group.name,
            type: "group",
            selectable: true,
            perPixelTargetFind: true,
            data: group.data
          });
          canvas.add(grp);
          if (callbackTextEditing) {
            callbackTextEditing(grp);
          }
          grp.on(
            "mousedown",
            FabricHelper.fabricDblClick(grp, () => {
              items = FabricHelper.ungroup(grp, canvas);
              canvas.setActiveObject(dimensionText);
              dimensionText.enterEditing();
              dimensionText.selectAll();
            })
          );
        });
      }

      group.on(
        "mousedown",
        FabricHelper.fabricDblClick(group, () => {
          items = FabricHelper.ungroup(group, canvas);
          canvas?.setActiveObject(dimensionText);
          dimensionText.enterEditing();
          dimensionText.selectAll();
          canvas?.renderAll();
        })
      );
    }
  }

  public static afterLoadFromJSON(
    canvas: fabric.Canvas,
    callbackTextEditing?: Function
  ) {
    canvas.getObjects().forEach((ele) => {
      if (ele.type === "group") {
        const group = ele as fabric.Group;
        FabricHelper.allowTextEditingInGroup(
          group,
          canvas,
          callbackTextEditing
        );
      }
    });
    canvas?.renderAll();
  }

  public static distanceBetweenTwoPoint(
    first: { x: number; y: number },
    second: { x: number; y: number }
  ): number {
    return Math.sqrt(
      (second.x - first.x) * (second.x - first.x) +
        (second.y - first.y) * (second.y - first.y)
    );
  }

  public static createCloudRectangle(
    width: number,
    height: number,
    left: number,
    top: number
  ): Array<fabric.Object> {
    if (width < 20 || height < 20) return [];
    const tempObject = new Array<fabric.Object>();
    const iRadius = 10;
    const iWidthi = width - (width % (2 * iRadius));
    const iHeight = height - (height % (2 * iRadius));
    const numberOfArcX = Math.floor(iWidthi / iRadius / 2);
    const numberOfArcY = Math.floor(iHeight / iRadius / 2);

    for (let i = 0; i < numberOfArcX; i++) {
      let angle1 = 180;
      let angle2 = 0;
      let cAngle = 180;
      if (i === 0) {
        angle1 = 90;
        cAngle = 270;
      }
      if (i === numberOfArcX - 1) {
        angle1 = 180;
        angle2 = 90;
        cAngle = 90;
      }

      const circle1 = new fabric.Circle({
        radius: iRadius,
        left: left + iRadius * 2 * i,
        top,
        stroke: "#F00",
        strokeWidth: 1,
        fill: "",
        startAngle: angle1,
        endAngle: angle2
      });
      tempObject.push(circle1);

      const circle2 = new fabric.Circle({
        radius: iRadius,
        left: left + iRadius * 2 * i + iRadius,
        top: top + iHeight - iRadius,
        stroke: "#F00",
        strokeWidth: 1,
        fill: "",
        startAngle: angle1,
        endAngle: angle2,
        angle: cAngle,
        originX: "center",
        originY: "center"
      });
      tempObject.push(circle2);
    }
    for (let j = 1; j < numberOfArcY; j++) {
      const angle1 = 90;
      const angle2 = 270;
      const circle1 = new fabric.Circle({
        radius: iRadius,
        left,
        top: top + iRadius * 2 * j,
        stroke: "#F00",
        strokeWidth: 1,
        fill: "",
        startAngle: angle1,
        endAngle: angle2
      });
      tempObject.push(circle1);

      const circle2 = new fabric.Circle({
        radius: iRadius,
        left: left + iWidthi - iRadius * 2,
        top: top + iRadius * 2 * j,
        stroke: "#F00",
        strokeWidth: 1,
        fill: "",
        startAngle: angle2,
        endAngle: angle1
      });
      tempObject.push(circle2);
    }
    return tempObject;
  }
}
