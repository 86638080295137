import { AppLogoIcon } from "components/svg-icons";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { ProjectContext } from "context/ProjectProvider";
import { DATE_FORMAT_MMDDYYYY_HHMM_A_Z } from "utils/dateutils";
import { Tooltip } from "antd";
import AAccountMenu from "../a-account-menu";

function DateTimePanel() {
  const [time, setTime] = useState(moment());
  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setTime(moment());
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);
  return (
    <Tooltip
      className="italic pl-1"
      title={time.format(DATE_FORMAT_MMDDYYYY_HHMM_A_Z)}
    >
      {time.format("hh:mm A (z)")}
    </Tooltip>
  );
}

const borderRightClassname =
  "border-solid border-y-0 border-l-0 border-r-1 border-slate-100";
const verticalCenterClassname = "flex items-center";

function ANavbar() {
  const { projectDetails } = useContext(ProjectContext);

  return (
    <nav className="bg-white leading-none">
      <div className="h-[45px] flex items-stretch">
        <div
          className={`px-4 ${borderRightClassname} ${verticalCenterClassname}`}
        >
          <div className="w-[120px]">
            <AppLogoIcon />
          </div>
        </div>

        <div className={`px-4 ${verticalCenterClassname}`}>
          {projectDetails?.name}
          {projectDetails && <DateTimePanel />}
        </div>
        <div className="px-4 flex grow justify-end cursor-pointer">
          <AAccountMenu />
        </div>
      </div>
    </nav>
  );
}

export default ANavbar;
