import { gql } from "@apollo/client";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { FeatureTypes } from "entity-app/constants";
import {
  UPDATE_FEATURE_WORKFLOW_INSTANCE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET
} from "entity-app/graphQL/ciq-feature-mutations";
import { GET_FEATURE_INSTANCE_PLANNING_DATA } from "entity-app/graphQL/ciq-feature-queries";
import { getGQLQueryData } from "entity-app/services";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useCallback, useContext, useEffect, useState } from "react";

type UUID = string;
type ISO8601Date = string;

interface FeatureInstance {
  id: UUID;
  title: string;
  feature_type_id: number;
}

export interface WorkflowInstanceOffset {
  id: UUID;
  name: string;
  duration: number;
}

export interface WorkflowInstanceMilestone {
  id: UUID;
  name: string;
  planned_date: ISO8601Date;
  sequence_no: number;
  hidden: boolean;
}

interface FeatureWorkflowInstance {
  id: UUID;
  manual_entry_date: ISO8601Date;
}

interface TargetFeatureInstance extends FeatureInstance {
  project_id: UUID;
  feature_workflow_instance: FeatureWorkflowInstance;
  workflow_instance_milestones: WorkflowInstanceMilestone[];
  workflow_instance_offsets: WorkflowInstanceOffset[];
}

interface Link {
  id: UUID;
  target_gantt_task: null;
  target_feature_instance: TargetFeatureInstance;
}

interface NestedFeatureInstance extends FeatureInstance {
  workflow_instance_offsets: WorkflowInstanceOffset[];
  workflow_instance_milestones: WorkflowInstanceMilestone[];
  links_as_source: Link[];
}

export interface RootFeatureInstance extends FeatureInstance {
  links_as_source: {
    target_feature_instance: NestedFeatureInstance;
  }[];
}

interface PlanningLink extends Link {
  workflow_instance_milestones: WorkflowInstanceMilestone[];
  workflow_instance_offsets: WorkflowInstanceOffset[];
  links_as_source: {
    target_feature_instance?: NestedFeatureInstance;
    target_gantt_task?: any;
    link_type: number;
  }[];
  feature_workflow_instance: FeatureWorkflowInstance;
  governing_link: boolean;
}

export type LinksForFeature = {
  [K in keyof typeof FeatureTypes]?: PlanningLink;
};

function extractTargetFeatureInstances(data: any): any[] {
  const instances: any[] = [];

  function traverse(obj: any) {
    if (typeof obj !== "object" || obj === null) return;

    if (obj.target_feature_instance) {
      const reStructuredObj = { ...obj, ...obj.target_feature_instance };
      delete reStructuredObj.target_feature_instance;
      instances.push(reStructuredObj);
    }

    if (Array.isArray(obj)) {
      obj.forEach(traverse);
    } else {
      Object.values(obj).forEach(traverse);
    }
  }

  traverse(data);
  return instances;
}

function formatWFInstanceMilestones(
  workflowInstanceMilestones: WorkflowInstanceMilestone[]
) {
  // remove hidden milestones and sort by sequence_no
  const visibleMilestones = workflowInstanceMilestones.filter(
    (milestone) => !milestone.hidden
  );
  visibleMilestones.sort((a, b) => a.sequence_no - b.sequence_no);
  return visibleMilestones;
}

export function useBidPackagePlanning(params: {
  featureId: string;
  token: string;
  onRefetchCallback: Function;
}) {
  const { featureId, token, onRefetchCallback } = params;

  const [featureLinks, setFeatureLinks] = useState<LinksForFeature | null>(
    null
  );
  const [loadingLinks, setLoadingLinks] = useState(false);

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const [mutationUpdateFeatureWorkflowInstanceOffset] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureWorkflowInstance] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE),
    {
      client: gqlClientForProject
    }
  );

  const getPlanningData = useCallback(async () => {
    if (!token || !featureId) {
      return null;
    }
    setLoadingLinks(true);
    let linksForAFeature: any = null;
    const PlanningDataResponse = await getGQLQueryData(
      token,
      GET_FEATURE_INSTANCE_PLANNING_DATA,
      {
        featureId
      }
    );

    if (PlanningDataResponse.success) {
      // console.log(PlanningDataResponse.data.data.feature_instance_by_pk);
      const allLinks = extractTargetFeatureInstances(
        structuredClone(PlanningDataResponse.data.data.feature_instance_by_pk)
      );

      allLinks.forEach((link: any) => {
        linksForAFeature = linksForAFeature || {};
        let formattedMilestones: WorkflowInstanceMilestone[] = [];
        if (link.workflow_instance_milestones) {
          formattedMilestones = formatWFInstanceMilestones(
            link.workflow_instance_milestones
          );
          linksForAFeature[FeatureTypes[link.feature_type_id]] = {
            ...link,
            workflow_instance_milestones: formattedMilestones
          };
        } else {
          linksForAFeature[FeatureTypes[link.feature_type_id]] = link;
        }
      });
    }
    setFeatureLinks(linksForAFeature);
    setLoadingLinks(false);
    return PlanningDataResponse;
  }, [featureId, token]);

  const multiFetchPlanningData = useCallback(async () => {
    getPlanningData();
    setTimeout(() => {
      getPlanningData();
    }, 3000);
    setTimeout(() => {
      getPlanningData();
    }, 6000);
  }, [getPlanningData]);

  const refetchAllData = useCallback(() => {
    onRefetchCallback();
    multiFetchPlanningData();
  }, [multiFetchPlanningData, onRefetchCallback]);

  const updateWorkflowInstanceOffset = useCallback(
    async (updateParams: {
      offsetId: string;
      value: number | null;
      workflowInstanceId: string;
    }) => {
      const { offsetId, value, workflowInstanceId } = updateParams;
      const updateResponse = await mutationUpdateFeatureWorkflowInstanceOffset({
        variables: {
          id: offsetId,
          set: {
            duration: value
          },
          workflowInstanceId
        }
      });
      refetchAllData();
      return updateResponse;
    },
    [mutationUpdateFeatureWorkflowInstanceOffset, refetchAllData]
  );

  const updateWorkflowInstance = useCallback(
    async (updateParams: { id: string; set: any }) => {
      const updateResponse = await mutationUpdateFeatureWorkflowInstance({
        variables: updateParams
      });

      refetchAllData();
      return updateResponse;
    },
    [mutationUpdateFeatureWorkflowInstance, refetchAllData]
  );

  useEffect(() => {
    getPlanningData();
  }, [getPlanningData]);

  return {
    featureLinks,
    loadingLinks,
    updateWorkflowInstanceOffset,
    updateWorkflowInstance,
    refetch: refetchAllData
  };
}
