import { Typography } from "antd";
import ErrorBoundary from "components/error-boundary";
import SubmittalMicroVisualiser from "components/submittal-details/submittal-micro-viz";
import SubmittalMicroVizDateBlock from "components/submittal-details/submittal-microviz-dateblock";
import { SubmittalLog } from "models/submittal-log";

const { Paragraph } = Typography;

type Props = {
  submittalData: SubmittalLog;
  isIntegrationMode: boolean;
  projectParticipants: any;
  dataSubmittalRevisions: any;
  submittalHeaderMap: any;
};

function ASubmittalTitleHeader(props: Props) {
  const {
    submittalData,
    isIntegrationMode,
    projectParticipants,
    dataSubmittalRevisions,
    submittalHeaderMap
  } = props;

  return (
    <div className="w-full md:flex bg-white px-5 items-center">
      <div className="grow flex items-center space-x-10  py-3">
        <a
          href={`${window.location.origin}${window.location.hash?.substr(1)}`}
          target="_blank"
          className="flex items-center space-x-10"
          rel="noreferrer"
        >
          <Paragraph className="!m-0 text-two !font-normal ">
            {`${submittalData?.spec_no} - ${submittalData?.submittal_id || ""}`}
          </Paragraph>
          <Paragraph className="!my-0 !font-normal text-two !text-black">
            {submittalData?.title}
          </Paragraph>
        </a>
        <div className="w-28">
          {submittalHeaderMap?.revision || ""}: {submittalData?.revision}
        </div>
      </div>
      <div className="flex justify-end py-3 h-full">
        {isIntegrationMode && (
          <div className="w-[260px]">
            {dataSubmittalRevisions && (
              <ErrorBoundary>
                <SubmittalMicroVizDateBlock
                  submittalData={submittalData}
                  projectParticipants={projectParticipants}
                  submittalRevisions={
                    dataSubmittalRevisions?.submittals_by_pk
                      ?.submittal_revisions
                  }
                />
              </ErrorBoundary>
            )}
          </div>
        )}
        {!isIntegrationMode && (
          <div className="w-[260px]">
            <ErrorBoundary>
              <SubmittalMicroVisualiser
                design_reviewer_id={
                  submittalData?.design_reviewer ||
                  submittalData?.design_reviewer_unregistered
                }
                gc_reviewer_id={submittalData?.gc_reviewer}
                submittal_history={
                  submittalData?.submittal_status_history_details
                }
                vendorInfo={{
                  designReviewerCompanyName:
                    submittalData?.design_reviewer_vendor_unregistered,
                  responsibleContractorCompanyName:
                    submittalData?.responsible_contractor_vendor?.name
                }}
                disabledTooltip
              />
            </ErrorBoundary>
          </div>
        )}
      </div>
    </div>
  );
}

export default ASubmittalTitleHeader;
