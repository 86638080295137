export default function FileValidatorErrorRenderer(props: {
  errors: string[];
}) {
  const { errors } = props;
  return (
    <div>
      <ul className="inline-flex flex-col m-0 p-0 pl-3 text-left">
        {errors.map((msg: string) => {
          return <li>{msg}</li>;
        })}
      </ul>
    </div>
  );
}
