import { Tabs } from "antd";
import { useContext, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { isProjectInIntegrationMode } from "utils/utils";
import { useHistory, useParams } from "react-router";
import ErrorBoundary from "components/error-boundary";
import { EUserRoleName } from "../../constants";
import ModuleSettings from "./module-settings";
import GeneralSettings from "./general-settings";
import ProjectIntegrationsTab from "./project-integrations/project-integrations-tab";

function ProjectSettingManu() {
  const history = useHistory();
  const { projectId, tab1Id } = useParams() as any;
  const [tabIndex, setTabIndex] = useState<string>(tab1Id || "general");
  const { tokenContents, projectDetails } = useContext(ProjectContext);

  const isInternalGCser =
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin] ||
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_foreman];

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const tabItems = [
    {
      label: "General Settings",
      key: "general",
      children: <GeneralSettings isInternalGCser={isInternalGCser} />
    },
    {
      label: "Module Settings",
      key: "modules",
      children: <ModuleSettings />
    }
  ];

  if (isIntegrationMode) {
    tabItems.push({
      label: "Integrations",
      key: "integrations",
      children: (
        <ErrorBoundary>
          <ProjectIntegrationsTab />
        </ErrorBoundary>
      )
    });
  }

  const secondTabItems = [
    {
      label: "General Settings",
      key: "general",
      children: <GeneralSettings isInternalGCser={isInternalGCser} />
    }
  ];

  return (
    <div className="w-full h-full px-4">
      <Tabs
        onChange={(activeKey) => {
          history.push(
            `/project/${projectId}/settings/${activeKey.toString()}`
          );
          setTabIndex(activeKey);
        }}
        activeKey={tabIndex}
        items={isInternalGCser ? tabItems : secondTabItems}
        destroyInactiveTabPane
      />
    </div>
  );
}
export default ProjectSettingManu;
