import LinkIcon from "components/svg-icons/link-icon";
import { DateUtils } from "utils/dateutils";
import { LinkTypes } from "entity-app/constants";

function ActivityItem({
  activity,
  linkType
}: {
  activity: any;
  linkType: number;
}) {
  return (
    <div className="w-full border border-solid border-[#3B3B3B4D] text-xs p-2 bg-transparent m-0 text-left ">
      <div className="w-full flex items-center gap-x-1">
        <div className="shrink-0 flex items-center justify-center">
          <LinkIcon />
        </div>
        <div
          className="grow text-gray-500 truncate"
          title={activity.source_task_id}
        >
          {activity.source_task_id}
        </div>
        {linkType === LinkTypes.finish_to_start && (
          <div className="text-gray-500 grow text-right">
            Start Date:{" "}
            {activity.start_date
              ? DateUtils.format(activity.start_date, "MM-DD-YYYY")
              : ""}
          </div>
        )}
        {linkType === LinkTypes.finish_to_finish && (
          <div className="text-gray-500 grow text-right">
            End Date:{" "}
            {activity.end_date
              ? DateUtils.format(activity.end_date, "MM-DD-YYYY")
              : ""}
          </div>
        )}
      </div>
      <div className="mt-1 pl-0.5 truncate" title={activity.text}>
        {activity.text}
      </div>
    </div>
  );
}

export default ActivityItem;
