import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useState } from "react";
import { QUERY_GET_PROJECT_SPECS } from "services/graphQL/queries";
import { Select } from "antd";

function FeatureSpecSectionDropdown(props: {
  onDelete: (params: { specSectionIds: string[] }) => void;
  onInsert: (params: { specSectionId: string }) => void;
  disabled?: boolean;
  selectedIds?: any;
}) {
  const { onDelete, onInsert, disabled, selectedIds } = props;

  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);

  const [localSelectedIds, setLocalSelectedIds] = useState<string[]>([]);

  const { data: specSections, refetch: refetchSpecs } = useCIQQuery<{
    spec_sections: Array<{
      title: string;
      id: string;
      section_number: string;
    }>;
  }>(QUERY_GET_PROJECT_SPECS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  useEffect(() => {
    if (!selectedIds || !specSections) return;
    setLocalSelectedIds(structuredClone(selectedIds));
  }, [selectedIds, specSections]);

  useEffect(() => {
    const hasInsertEvent = eventLogs.some(
      (log) =>
        log.data_source === "spec_sections" && log.change_type === "insert"
    );
    if (hasInsertEvent) {
      refetchSpecs();
    }
  }, [eventLogs, refetchSpecs]);

  const onSelect = async (value: any) => {
    const response: any = await onInsert({ specSectionId: value });
    if (response.success) {
      setLocalSelectedIds((prev) => [...prev, value]);
    }
  };

  const onDeselect = async (value: any) => {
    setLocalSelectedIds((prev) => prev.filter((id) => id !== value));
    const response: any = await onDelete({ specSectionIds: [value] });
    if (response.success) {
      setLocalSelectedIds((prev) => prev.filter((id) => id !== value));
    }
  };

  return (
    <Select
      showSearch
      mode="multiple"
      disabled={disabled}
      value={localSelectedIds}
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
      options={specSections?.spec_sections.map((spec) => ({
        value: spec.id,
        label: spec.title
          ? `${spec.section_number} - ${spec.title}`
          : spec.section_number
      }))}
      onSelect={onSelect}
      onDeselect={onDeselect}
      showArrow
    />
  );
}

export default FeatureSpecSectionDropdown;
