import { AppLogoIcon } from "components/svg-icons";
import classnames from "classnames";
import { Link, matchPath, useLocation } from "react-router-dom";
import AdminAccountMenu from "../admin-account-menu";

type MenuLink = {
  label: string;
  key: string;
};

const borderRightClassname =
  "border-solid border-y-0 border-l-0 border-r-1 border-slate-100";
const verticalCenterClassname = "flex items-center";
const linkClassname =
  "text-[black] hover:text-[black] hover:drop-shadow-[0_0_0.3px_rgba(0,0,0,1)]";
const activeLinkClassname =
  "border-solid border-[black] border-x-0 border-t-0 border-b-2 font-semibold";

function AdminNavbar(props: { menuLinks?: MenuLink[] }) {
  const { menuLinks = [] } = props;
  const { pathname } = useLocation();

  return (
    <nav className="bg-white leading-none">
      <div className="h-[45px] flex items-stretch">
        <div
          className={`px-4 ${borderRightClassname} ${verticalCenterClassname}`}
        >
          <Link to="/organizations">
            <div className="w-[120px]">
              <AppLogoIcon />
            </div>
          </Link>
        </div>
        <div className={`px-4 ${borderRightClassname} flex grow justify-end`}>
          {menuLinks.map((link: any) => {
            const className = classnames(verticalCenterClassname, "ml-4", {
              [activeLinkClassname]: matchPath(pathname, { path: link.key })
            });
            return (
              <Link
                key={link.key}
                to={link.key}
                className={`${verticalCenterClassname} ml-4 items-stretch ${linkClassname}`}
              >
                <div className={className}>{link.label}</div>
              </Link>
            );
          })}
        </div>
        <div className={`px-4 ${verticalCenterClassname} cursor-pointer`}>
          <AdminAccountMenu />
        </div>
      </div>
    </nav>
  );
}
export default AdminNavbar;
