import { Button } from "antd";

function FeatureLinksDialogHeader(props: {
  onCancel: () => void;
  onSave: () => void;
  disableSaveButton: boolean;
  disableCancelButton?: boolean;
  saveButtonLoading: boolean;
}) {
  const {
    onCancel,
    onSave,
    disableSaveButton,
    disableCancelButton,
    saveButtonLoading
  } = props;
  return (
    <div className="flex items-center text-xs">
      <div className="font-semibold uppercase pr-4 grow">
        Modify Bid Package Links
      </div>
      <div className="flex gap-x-3">
        <Button
          className="px-6"
          onClick={onCancel}
          disabled={disableCancelButton || saveButtonLoading}
        >
          Cancel
        </Button>
        <Button
          className="px-6"
          type="primary"
          onClick={onSave}
          disabled={disableSaveButton}
          loading={saveButtonLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default FeatureLinksDialogHeader;
