import { Button, Dropdown, Menu, MenuProps } from "antd";
import { UserAvatar, UserAvatarWithDetails } from "components/user-avatar";
import { getUser } from "services/auth";
import { useEffect, useState } from "react";
import LogoutIcon from "components/svg-icons/logout-icon";
import { useQuery } from "@apollo/client";
import { QUERY_USER_PROFILE_INFO } from "services/graphQL/queries";

const ACCESS_TOKEN_KEY = "accToken";
const SUB_ID = "subId";

function AAccountMenu() {
  const [currentUser]: [any, any] = useState(getUser());
  const [userInfo, setUserInfo] = useState<any>();
  const { data: userData } = useQuery(QUERY_USER_PROFILE_INFO, {
    variables: { where: { user: { email: { _eq: currentUser.email } } } }
  });

  useEffect(() => {
    if (!userData) return;
    if (userData.subscription_users.length > 0) {
      setUserInfo(userData.subscription_users[0]);
    }
  }, [userData]);

  const logout = () => {
    try {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(SUB_ID);
    } catch (ex) {
      console.error(ex);
    }
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const menuItems: MenuProps["items"] = [
    {
      label: (
        <UserAvatarWithDetails
          showAvatar={false}
          fullName={
            userInfo
              ? `${userInfo?.user?.first_name} ${userInfo?.user?.last_name}`
              : undefined
          }
          email={currentUser.email}
          subscription={userInfo?.subscription?.name || ""}
        />
      ),
      key: "profile"
    },
    {
      label: (
        <Button
          onClick={() => logout()}
          className="flex w-full h-8 items-center justify-between  bg-[#E8E8E8] px-4 "
        >
          <div className="flex h-full items-center font-semibold text-xs text-[#3B3B3B]">
            Logout
          </div>
          <LogoutIcon />
        </Button>
      ),
      key: "sign-out",
      className: "p-0"
    }
  ];
  const menu = <Menu className="p-0 space-y-1" items={menuItems} />;

  return (
    <Dropdown arrow placement="bottomRight" overlay={menu} trigger={["click"]}>
      <div data-cy="account" className="accMenuContainer">
        <UserAvatar
          url=""
          isRect={false}
          fullName={
            userInfo
              ? `${userInfo?.user?.first_name} ${userInfo?.user?.last_name}`
              : ""
          }
        />
      </div>
    </Dropdown>
  );
}

export default AAccountMenu;
