import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { MATERIAL_LIST_PERF } from "../queries/material-queries";

export const useCiqMaterialList = <TData = any>({
  gqlClientForProject
}: {
  gqlClientForProject: any;
}): {
  data: TData;
  refetch: Function;
} => {
  const { data, refetch: refetchMaterialLog } = useCIQQuery<any>(
    MATERIAL_LIST_PERF,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  return { data, refetch: refetchMaterialLog };
};
