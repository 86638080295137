import { ReactElement } from "react";
import { Moment } from "moment";
import { DateUtils } from "utils/dateutils";
import { SubmittalDiffV6 } from "../compare-schedule-2/SubmittalDiffV6";

import {
  computeDecorators,
  computeXPositionsForDatesv6
} from "../compare-schedule-2/TemporalLayout";
import { computeTimelineDataForSubmittal } from "./utils";
import { TActivitiesV3, TLinkedSubmittal } from "./models";

function SubmittalGroupDiffV6(props: {
  submittal: TLinkedSubmittal;
  activityComparison: TActivitiesV3;
  projectId: string;
}): ReactElement {
  const { submittal, activityComparison, projectId } = props;

  const dataForBefore = computeTimelineDataForSubmittal(
    "before",
    submittal,
    activityComparison,
    projectId
  );
  const dataForAfter = computeTimelineDataForSubmittal(
    "after",
    submittal,
    activityComparison,
    projectId
  );

  const beforeFinalDeadlineMilestone =
    dataForBefore.timelineData.primaryElement.milestones.final_deadline;

  const afterFinalDeadlineMilestone =
    dataForAfter.timelineData.primaryElement.milestones.final_deadline;

  const oldMaterialMilestoneDates: Moment[] = [];
  submittal.oldGoverningMaterials.forEach((governingMaterial) => {
    oldMaterialMilestoneDates.push(
      DateUtils.dateTimeObj(governingMaterial.start_date)
    );
    oldMaterialMilestoneDates.push(
      DateUtils.dateTimeObj(governingMaterial.end_date)
    );
  });

  const newMaterialMilestoneDates: Moment[] = [];
  submittal.newGoverningMaterials.forEach((governingMaterial) => {
    newMaterialMilestoneDates.push(
      DateUtils.dateTimeObj(governingMaterial.start_date)
    );
    newMaterialMilestoneDates.push(
      DateUtils.dateTimeObj(governingMaterial.end_date)
    );
  });

  // todo: consider only the visible milestones
  const xLocationsByDate = computeXPositionsForDatesv6(
    [
      ...dataForBefore.allDates,
      ...dataForAfter.allDates,
      ...oldMaterialMilestoneDates,
      ...newMaterialMilestoneDates
    ],
    dataForAfter.timelineData.today.date
  );

  const width = 0;

  const beforeLastMaterialROJ =
    submittal.oldGoverningMaterials &&
    submittal.oldGoverningMaterials.length > 0
      ? submittal.oldGoverningMaterials[
          submittal.oldGoverningMaterials.length - 1
        ].end_date
      : null;
  const afterLastMaterialROJ =
    submittal.newGoverningMaterials &&
    submittal.newGoverningMaterials.length > 0
      ? submittal.newGoverningMaterials[
          submittal.newGoverningMaterials.length - 1
        ].end_date
      : null;

  const { arrow } = computeDecorators(
    xLocationsByDate,
    beforeLastMaterialROJ,
    afterLastMaterialROJ,
    beforeFinalDeadlineMilestone
      ? beforeFinalDeadlineMilestone.normalizedDate
      : null,
    afterFinalDeadlineMilestone
      ? afterFinalDeadlineMilestone.normalizedDate
      : null
  );

  return (
    <div className="overflow-x-auto">
      <span className="uppercase text-xs tracking-wider sticky left-0">
        Before
      </span>
      <div>
        <span className="bg-gray-50 px-4 pt-4 inline-block">
          <SubmittalDiffV6
            xLocationsByDate={xLocationsByDate}
            primaryElementName={dataForBefore.timelineData.primaryElement.label}
            linkedMaterials={submittal.oldGoverningMaterials}
            milestones={Object.values(
              dataForBefore.timelineData.primaryElement.milestones
            )}
            todayDate={dataForBefore.timelineData.today.date}
            siCalculations={dataForBefore.timelineData.siCalculations}
            width={width}
            projectId={projectId}
            isCompletedWorkflow={submittal.workflow_status === "COMPLETED"}
          />
        </span>
      </div>
      <span className="uppercase text-xs tracking-wider sticky left-0">
        After
      </span>
      <div>
        <span className="bg-blue-50 px-4 pt-4 inline-block">
          <SubmittalDiffV6
            xLocationsByDate={xLocationsByDate}
            primaryElementName={dataForBefore.timelineData.primaryElement.label}
            linkedMaterials={submittal.newGoverningMaterials}
            milestones={Object.values(
              dataForAfter.timelineData.primaryElement.milestones
            )}
            todayDate={dataForAfter.timelineData.today.date}
            siCalculations={dataForAfter.timelineData.siCalculations}
            width={width}
            arrow={arrow}
            projectId={projectId}
            isCompletedWorkflow={submittal.workflow_status === "COMPLETED"}
          />
        </span>
      </div>
    </div>
  );
}

export default SubmittalGroupDiffV6;
