import { useMutation } from "@apollo/client";
import { CREATE_SUBSCRIPTION_MUTATION } from "admin-app/services/mutations";
import { Button, Divider, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { sendEmailToInvitedUserInSubscription } from "services/auth";
import { ErrorMessages } from "../../../constants";

function CreateSubscriptionForm({
  organization_id,
  onClose
}: {
  organization_id: string;
  onClose?: () => void;
}) {
  const [form] = useForm();
  const [loading, setLoader] = useState(false);
  const [createSubscripionMutation] = useMutation(CREATE_SUBSCRIPTION_MUTATION);

  const onFinish = async (value: { email: string; name: string }) => {
    console.log(value);
    const userData = {
      email: value.email.toLowerCase(),
      name: value.name
    };
    setLoader(true);
    const res = await createSubscripionMutation({
      variables: { organization_id, ...userData }
    });
    if (res.data) {
      const inputData = {
        env_admin: true,
        input: {
          email: userData.email,
          subscription_id: res.data?.insert_subscription_one?.id
        }
      };
      const apiRes = await sendEmailToInvitedUserInSubscription(inputData);
      message.success("Created successfully.");
      if (!apiRes.data)
        message.warn(`Failed to send invitation email to ${userData.email}`);

      if (onClose) onClose();
    }
    if (res.errors) {
      message.error(res.errors[0].message);
    }
    setLoader(false);
  };
  return (
    <Form
      onFinish={onFinish}
      form={form}
      labelCol={{ span: 50 }}
      wrapperCol={{ span: 30 }}
      scrollToFirstError
      layout="vertical"
      className="p-2"
      disabled={loading}
    >
      <Form.Item
        name="name"
        label="SUBSCRIPTION NAME"
        rules={[{ required: true, message: "Please enter subscription name." }]}
        required
      >
        <Input placeholder="Enter Subscription Name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Subscription Admin TO BE INVITED"
        rules={[
          {
            required: true,
            message: "Please enter email Address."
          },
          { type: "email", message: ErrorMessages.EmailInvalid }
        ]}
        required
      >
        <Input placeholder="Please enter email Address" />
      </Form.Item>
      <Divider />
      <div className="w-full flex justify-end">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          className="px-5"
        >
          Create
        </Button>
      </div>
    </Form>
  );
}
export default CreateSubscriptionForm;
