import { InboxOutlined } from "@ant-design/icons";
import { useSubscription } from "@apollo/client";
import {
  Button,
  message,
  Modal,
  Select,
  Spin,
  Table,
  UploadFile,
  UploadProps
} from "antd";
import { RcFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { useEffect, useMemo, useRef, useState } from "react";
import { BASE_URL } from "services/endpoints";
import { SUBSCRIPTION_IMPORT_FILE_PROCESSING } from "services/graphQL/subscriptions";
import { downloadStringAsCSVFile, downloadFileInBrowser } from "utils/utils";

type SuccessResponse = {
  Total: number;
  Imported: number;
  Failed: number;
  Updated: number;
  DuplicatesCreated: number;
  File: any;
};

export default function ImportSubmittalLogs({
  tokenRetrievalState,
  gqlClientForProject,
  existingSubmittalsCount,
  showImportLogDrawer,
  setDrawerOpen,
  submittalHeaderMap,
  onCancel
}: {
  tokenRetrievalState: { token: string };
  gqlClientForProject: any;
  existingSubmittalsCount: number;
  showImportLogDrawer: boolean;
  setDrawerOpen: Function;
  submittalHeaderMap: any;
  onCancel: () => void;
}) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [isOpenModel, setOpenModel] = useState(false);
  const [fileProcessing, setfileProcessing] = useState(false);
  const [unsupportedFileSelected, setUnsupportedFileSelected] = useState(false);
  const [successResponse, setSuccessResponse] = useState<SuccessResponse>();
  const validationData = useRef<{
    misMatchHeaders: Array<string>;
    file: RcFile | undefined;
    config: any;
    allInputCSVheaders: Array<{ label: string; value: any }>;
  }>({
    misMatchHeaders: [],
    file: undefined,
    config: {},
    allInputCSVheaders: []
  });

  const [importlogId, setImportLogId] = useState(null);
  const [resultFileUrl, setResultFileUrl] = useState(null);

  const { data: fileProcessingData } = useSubscription(
    SUBSCRIPTION_IMPORT_FILE_PROCESSING,
    {
      shouldResubscribe: true,
      client: gqlClientForProject,
      skip: !importlogId,
      variables: {
        where: { id: { _eq: importlogId } }
      }
    }
  );

  // console.log("existingSubmittalsCount ", existingSubmittalsCount);

  useEffect(() => {
    console.log("fileProcessingData ", fileProcessingData);
    try {
      if (fileProcessingData?.import_log?.length > 0) {
        const res = fileProcessingData?.import_log[0];
        if (res.status === "PROCESSED") {
          setfileProcessing(false);
          setImportLogId(null);
          if (res?.status_comment) {
            const parsedStatus = JSON.parse(res.status_comment);
            setSuccessResponse(parsedStatus);
            if (parsedStatus.File) {
              setResultFileUrl(parsedStatus.File);
            }
          }
        } else if (res.status === "FAILED") {
          setfileProcessing(false);
          setImportLogId(null);
        }
      }
    } catch (ex) {
      message.error("An error occured while importing the log");
    }
  }, [fileProcessingData, importlogId]);

  useEffect(() => {
    if (!resultFileUrl) return;
    downloadFileInBrowser(resultFileUrl, "Failed Submittals Log.csv");
  }, [resultFileUrl]);

  const sampleCSVHeaders = useMemo(() => {
    const headers = [
      "Submittal #",
      "Spec Section #",
      "Spec Section Name",
      submittalHeaderMap?.title || "",
      submittalHeaderMap?.description || "",
      submittalHeaderMap?.type || "",
      "Para"
    ];

    return headers;
  }, [submittalHeaderMap]);

  const getkey = (title: string) => {
    switch (title) {
      case "Submittal #":
        return "submittal_id";
      case "Spec Section #":
        return "spec_no";
      case "Spec Section Name":
        return "spec_name";
      case "Para":
        return "para";
      default:
        return "";
    }
  };

  const handleUpload = () => {
    setResultFileUrl(null);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });

    const config: any = {};

    Object.keys(validationData.current.config).forEach((key) => {
      const colTitle = validationData.current.config[key];
      const fieldName = Object.keys(submittalHeaderMap).find((fieldKey) => {
        return submittalHeaderMap[fieldKey] === key;
      });
      if (fieldName) {
        config[fieldName] = colTitle;
      } else {
        config[getkey(key)] = colTitle;
      }
    });

    if (!config) return;

    formData.append("config", JSON.stringify(config));
    formData.append("async", "true");
    setUploading(true);

    fetch(`${BASE_URL}/submittal/import`, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${tokenRetrievalState.token}`
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setfileProcessing(true);
          setImportLogId(result.success);
        } else {
          message.error("import failed.");
          setDrawerOpen(false);
        }
      })
      .catch(() => {
        message.error("import failed.");
        setDrawerOpen(false);
      })
      .finally(() => {
        setFileList([]);
        setUploading(false);
      });
  };

  const validateUploadCsvHeader = async (file: RcFile) => {
    try {
      validationData.current = {
        misMatchHeaders: [],
        file,
        config: {},
        allInputCSVheaders: []
      };
      const allText = await file.text();
      const firstLineRegEx = /^(.*)$/m;
      const csvHeader = firstLineRegEx.exec(allText);
      const headerStrings = csvHeader![0].split(",");
      const headers = headerStrings!.filter((h) => h).map((h) => h.trim());

      headers.forEach((h) =>
        validationData.current.allInputCSVheaders.push({ label: h, value: h })
      );

      sampleCSVHeaders.forEach((head) => {
        if (headers.includes(head)) {
          validationData.current.config[head] = head;
          const index = headers.indexOf(head);
          if (index > -1) {
            headers.splice(index, 1);
          }
        } else {
          validationData.current.misMatchHeaders.push(head);
          validationData.current.config[head] = null;
        }
      });
      if (validationData.current.misMatchHeaders.length > 0) {
        setOpenModel(true);
      } else {
        setFileList([file]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const props: UploadProps = {
    accept: "text/csv",
    style: { borderColor: unsupportedFileSelected === true ? "red" : "" },
    fileList,
    onRemove: () => {
      setFileList([]);
    },
    onDrop: (e) => {
      setResultFileUrl(null);
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file.type !== "text/csv") {
          setUnsupportedFileSelected(true);
          setFileList([]);
          message.error("Please select csv file only");
          return false;
        }
        setUnsupportedFileSelected(false);
        return false;
      }
      return false;
    },
    beforeUpload: (file: RcFile) => {
      if (file.type !== "text/csv") {
        setUnsupportedFileSelected(true);
        setFileList([]);
        message.error("Please select csv file only");
        return false;
      }
      setSuccessResponse(undefined);
      validateUploadCsvHeader(file);
      setUnsupportedFileSelected(false);
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false
    }
  };

  const renderUploadTable = (response: SuccessResponse | undefined) => {
    if (!response) return null;

    const data = [];
    data.push({ label: "Total", count: response.Total });
    if (existingSubmittalsCount > 0) {
      data.push({ label: "Updated", count: response.Updated });
      data.push({
        label: "Duplicates created",
        count: response.DuplicatesCreated
      });
    }
    data.push({ label: "Imported", count: response.Imported });
    const failedClassName = response.Failed > 0 ? "text-red-600" : "";
    data.push({
      label: <span className={failedClassName}>Failed</span>,
      count: <span className={failedClassName}>{response.Failed}</span>
    });

    const columns = [
      { title: "Import status", dataIndex: "label", key: "label" },
      { title: "Count", dataIndex: "count", key: "count" }
    ];

    return (
      <Table
        className="mt-4"
        dataSource={data}
        columns={columns}
        size="small"
        bordered
        pagination={false}
      />
    );
  };

  const requiredFields = ["Submittal #", submittalHeaderMap?.title || ""];

  return (
    <Modal
      className="custom-drawer"
      title="Import Submittal Log"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showImportLogDrawer}
      onCancel={onCancel}
      destroyOnClose
    >
      <div className="px-3">
        <p>
          Import an existing submittal log (CSV){" "}
          <span className=" font-semibold">OR</span> use our{" "}
          <button
            type="button"
            className="underline bg-transparent text-[#1890ff] border-none p-0 hover:no-underline cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              downloadStringAsCSVFile(
                [...sampleCSVHeaders].join(","),
                "Sample_Submittal_Log.csv"
              );
            }}
          >
            sample template
          </button>{" "}
          to import.
        </p>
        <div>
          <Dragger {...props} disabled={fileProcessing}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            {fileList.length > 0 ? (
              <p className="ant-upload-text">{fileList[0].name}</p>
            ) : (
              <div>
                <p className="ant-upload-text">Click or drag file to upload.</p>
                <p className="ant-upload-hint">
                  Only .csv files are supported.
                </p>
              </div>
            )}
          </Dragger>
        </div>
        <div className="flex flex-col justify-center items-center">
          {fileProcessing ? (
            <Spin
              className="mt-4 text-red-500"
              tip="File is being processed.Please do not close page while processing"
              size="default"
            />
          ) : (
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              {uploading ? "Importing" : "Import CSV"}
            </Button>
          )}
        </div>
        {renderUploadTable(successResponse)}
        <Modal
          title="Please select approprite headers for below titles"
          okText="Select"
          open={isOpenModel}
          onCancel={() => setOpenModel(false)}
          onOk={() => {
            if (validationData.current.file)
              setFileList([validationData.current.file]);
            setOpenModel(false);
          }}
          width={600}
          destroyOnClose
        >
          <div className="py-3 space-y-2">
            {validationData.current.misMatchHeaders.map((header) => {
              return (
                <div className="flex py-1 w-full">
                  <div className="w-3/12 pt-1">
                    <span
                      className={
                        requiredFields.includes(header)
                          ? "form-item-required"
                          : ""
                      }
                    >
                      {header}
                    </span>
                  </div>
                  <div className="w-9/12">
                    <Select
                      className="w-full"
                      defaultValue={null}
                      placeholder={`Please select header for ${header}`}
                      onChange={(value) => {
                        validationData.current.config[header] = value;
                      }}
                      options={[
                        { label: "None", value: null },
                        ...validationData.current.allInputCSVheaders
                      ]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4 text-xs leading-snug text-gray-600">
            Note: This Submittal # will be used to identify updates made to the
            submittal in consecutive imports.
          </div>
        </Modal>
      </div>
    </Modal>
  );
}
