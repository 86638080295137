import { EditOutlined } from "@ant-design/icons";
import "./inline-edit-title.scss";
import { Button, Form, Input } from "antd";
import { validatorForFieldValue } from "utils/utils";
import { ErrorMessages } from "constants/index";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";

function InlineEditTitle(props: {
  isPermissionToEdit: boolean;
  headerInfo: any;
  updateHeaderInfo: any;
  feature: string;
}) {
  const { isPermissionToEdit, headerInfo, updateHeaderInfo, feature } = props;
  const { tokenRetrievalState } = useContext(ProjectContext);
  const [numberEditing, setNumberEditing] = useState(false);
  const [titleEditing, setTitleEditing] = useState(false);
  const featureNumberInputRef: any = useRef();
  const featureTitleInputRef: any = useRef();

  const [form] = Form.useForm();

  const featureNumberEmptyMsg = useMemo(() => {
    switch (feature) {
      case "BID_PACKAGE":
        return ErrorMessages.bidPackgeNumber;
      case "DESIGN_PACKAGE":
        return ErrorMessages.designPackgeNumber;
      default:
        return "";
    }
  }, [feature]);

  const featureTitleEmptyMsg = useMemo(() => {
    switch (feature) {
      case "BID_PACKAGE":
        return ErrorMessages.bidPackgeTitle;
      case "DESIGN_PACKAGE":
        return ErrorMessages.designPackgeTitle;
      default:
        return "";
    }
  }, [feature]);

  const featureNumberErrorMsg = useMemo(() => {
    switch (feature) {
      case "BID_PACKAGE":
        return ErrorMessages.BidPackageNumberMsg;
      case "DESIGN_PACKAGE":
        return ErrorMessages.DesignPackageNumberMsg;
      default:
        return "";
    }
  }, [feature]);

  const featureTitleErrorMsg = useMemo(() => {
    switch (feature) {
      case "BID_PACKAGE":
        return ErrorMessages.BidPackageTitleMsg;
      case "DESIGN_PACKAGE":
        return ErrorMessages.DesignPackageTitleMsg;
      default:
        return "";
    }
  }, [feature]);

  useEffect(() => {
    form.setFieldsValue({
      number: headerInfo?.featureHeaderNumber ?? "",
      title: headerInfo?.featureHeaderTitle ?? ""
    });
  }, [headerInfo, form]);

  const onFinish = async (field: string) => {
    try {
      const result = await form.validateFields([field]);
      if (result) {
        updateHeaderInfo({
          title: form.getFieldValue("title")?.trim(),
          number: form.getFieldValue("number")?.trim()
        });
      }
    } catch (error: any) {
      error?.errorFields.forEach((item: any) => {
        if (item.name.includes("title")) {
          setTitleEditing(true);
        }
        if (item.name.includes("number")) {
          setNumberEditing(true);
        }
      });
    }
  };

  if (!headerInfo?.featureHeaderNumber) return <div />;

  return (
    <div className="flex mb-7">
      <Form
        className="absolute flex gap-x-2"
        form={form}
        scrollToFirstError
        layout="vertical"
      >
        <div className="flex">
          <p
            className={`mt-2 !font-normal text-two !text-black max-w-xs ${
              numberEditing ? "hidden" : ""
            }`}
          >
            {headerInfo?.featureHeaderNumber}
          </p>
          <Form.Item
            name="number"
            className={`${numberEditing ? "" : "hidden"}`}
            rules={[
              {
                validator: (_, value) => {
                  if (value === "") {
                    return Promise.reject(featureNumberEmptyMsg);
                  }
                  if (value === headerInfo?.featureHeaderNumber) {
                    return Promise.resolve();
                  }
                  return validatorForFieldValue(
                    "number",
                    value?.trim(),
                    feature,
                    tokenRetrievalState?.token,
                    featureNumberErrorMsg
                  );
                }
              }
            ]}
          >
            <Input
              ref={featureNumberInputRef}
              onBlur={async () => {
                setNumberEditing(false);
                onFinish("number");
              }}
            />
          </Form.Item>
          {isPermissionToEdit && (
            <Button
              className="border-none"
              icon={<EditOutlined className="ml-1 text-[#0000007F]" />}
              onClick={() => {
                form.setFieldValue("number", headerInfo?.featureHeaderNumber);
                setNumberEditing(true);
                setTimeout(() => {
                  featureNumberInputRef?.current?.focus();
                }, 500);
              }}
            />
          )}
        </div>
        <div className="flex">
          <p
            className={`mt-2 !font-normal text-two !text-black max-w-xs ${
              titleEditing ? "hidden" : ""
            }`}
          >
            {headerInfo?.featureHeaderTitle}
          </p>
          <Form.Item
            name="title"
            className={`${titleEditing ? "" : "hidden"}`}
            rules={[
              {
                validator: (_, value) => {
                  if (value === "") {
                    return Promise.reject(featureTitleEmptyMsg);
                  }
                  if (value === headerInfo?.featureHeaderTitle) {
                    return Promise.resolve();
                  }
                  return validatorForFieldValue(
                    "title",
                    value?.trim(),
                    feature,
                    tokenRetrievalState?.token,
                    featureTitleErrorMsg
                  );
                }
              }
            ]}
          >
            <Input
              ref={featureTitleInputRef}
              onBlur={() => {
                setTitleEditing(false);
                onFinish("title");
              }}
            />
          </Form.Item>
          {isPermissionToEdit && (
            <Button
              className="border-none"
              icon={<EditOutlined className="ml-1 text-[#0000007F]" />}
              onClick={() => {
                setTitleEditing(true);
                setTimeout(() => {
                  featureTitleInputRef?.current?.focus();
                }, 500);
              }}
            />
          )}
        </div>
      </Form>
    </div>
  );
}

export default InlineEditTitle;
