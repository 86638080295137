import { useMutation } from "@apollo/client";
import { ADD_ORGANIZATION_MUTATION } from "admin-app/services/mutations";
import { Button, Divider, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";

function AddOrganizationForm({ onClose }: { onClose?: () => void }) {
  const [form] = useForm();

  const [loading, setLoader] = useState(false);
  const [addOrganizationMutation] = useMutation(ADD_ORGANIZATION_MUTATION);

  const onFinish = async (value: any) => {
    console.log(value);
    setLoader(true);
    const res = await addOrganizationMutation({ variables: { object: value } });
    if (res.data) {
      message.success("Added successfully.");
      if (onClose) onClose();
    }
    if (res.errors) {
      message.error(res.errors[0].message);
    }
    setLoader(false);
  };
  return (
    <Form
      onFinish={onFinish}
      form={form}
      labelCol={{ span: 50 }}
      wrapperCol={{ span: 30 }}
      scrollToFirstError
      layout="vertical"
      className="p-2"
      disabled={loading}
    >
      <Form.Item
        name="name"
        label="ORGANIZATION NAME"
        rules={[{ required: true, message: "Please enter organization name." }]}
        required
      >
        <Input placeholder="Enter Organization Name" />
      </Form.Item>
      <Divider />
      <div className="w-full flex justify-end">
        <Button
          type="primary"
          loading={loading}
          htmlType="submit"
          className="px-5"
        >
          Create
        </Button>
      </div>
    </Form>
  );
}
export default AddOrganizationForm;
