import { useMutation } from "@apollo/client";
import { Checkbox, Modal, message } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useState } from "react";
import {
  MUTATION_UPDATE_SUBMITTAL_BY_ID,
  MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL
} from "services/graphQL/mutations";
import { SubmittalType } from "./models";

function TrackMaterial({
  selectedSubmittal,
  canEdit
}: {
  selectedSubmittal: SubmittalType | undefined;
  canEdit: boolean;
}) {
  const [isTrackMaterialDate, setTrackMaterialDate] = useState(true);
  const { gqlClientForProject } = useContext(ProjectContext);
  useEffect(() => {
    setTrackMaterialDate(!!selectedSubmittal?.material_tracking);
  }, [selectedSubmittal?.material_tracking]);

  const [updateSubmittalMutation] = useMutation(
    MUTATION_UPDATE_SUBMITTAL_BY_ID,
    {
      client: gqlClientForProject
    }
  );
  const [deleteLinkingSubmittalMaterialMutaion] = useMutation(
    MUTATION_DELETE_LINKING_SUBMITTAL_MATERIAL,
    {
      client: gqlClientForProject
    }
  );
  const changeMaterialTrack = (e: any) => {
    const value = e.target.checked;
    if (value) {
      setTrackMaterialDate(value);
      updateSubmittalMutation({
        variables: {
          id: selectedSubmittal?.id,
          submittalValues: {
            material_tracking: e.target.checked
          }
        }
      }).then((res) => {
        if (res.data) {
          console.info(res.data);
        }
        if (res.errors) {
          message.error(res.errors[0].message);
        }
      });
    } else {
      Modal.confirm({
        title: "Do you want to disable material tracking? ",
        content:
          " It will remove all linked materials from your selected submittal.",
        className: "skip-listing-window",
        onOk() {
          setTrackMaterialDate(!isTrackMaterialDate);
          updateSubmittalMutation({
            variables: {
              id: selectedSubmittal?.id,
              submittalValues: {
                material_tracking: e.target.checked
              }
            }
          }).then((res) => {
            if (res.data) {
              console.info(res.data);
              if (!e.target.checked) {
                deleteLinkingSubmittalMaterialMutaion({
                  variables: {
                    where: {
                      submittal_id: {
                        _eq: selectedSubmittal!.id
                      },
                      material: { implicit: { _neq: true } }
                    }
                  }
                }).then((res1) => {
                  if (res1.data)
                    console.info(
                      "Removed all Materials linked to selected submittal."
                    );
                  if (res1.errors) console.error(res1.errors[0].message);
                });
              }
            }
            if (res.errors) {
              message.error(res.errors[0].message);
            }
          });
        },
        okText: "Yes, disable"
      });
    }
  };
  return (
    <Checkbox
      checked={isTrackMaterialDate}
      onChange={changeMaterialTrack}
      className="mx-4"
      disabled={!canEdit}
    >
      Track Material
    </Checkbox>
  );
}

export default TrackMaterial;
