import { useCallback, useMemo } from "react";
import { Input } from "antd";
import debounce from "lodash.debounce";
import { IconSearch } from "@tabler/icons-react";

type SearchInputProps = {
  placeholder: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

function SearchInput(props: SearchInputProps) {
  const { onChange, placeholder, disabled = false } = props;

  const debouncedSearch = useMemo(
    () =>
      debounce((val) => {
        onChange(val);
      }, 500),
    [onChange]
  );

  const handleChange = useCallback(
    (e: React.FormEvent) => {
      debouncedSearch((e.target as HTMLInputElement).value);
    },
    [debouncedSearch]
  );

  return (
    <div className="w-[225px] py-1">
      <Input
        type="text"
        placeholder={placeholder || "Search"}
        prefix={<IconSearch size={14} />}
        onInput={handleChange}
        disabled={disabled}
      />
    </div>
  );
}

export default SearchInput;
