import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";

function SearchInputField(props: {
  gridRef: any;
  searchText?: string | undefined;
  getSearchText: (searchText: string) => void;
}) {
  const { gridRef, searchText, getSearchText } = props;
  const searchInputRef: any = useRef();
  const [inputText, setInputText] = useState<string>();

  const onFilterTextBoxChanged = useRef(
    debounce(() => {
      gridRef.current?.api?.setQuickFilter(searchInputRef.current.input.value);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      onFilterTextBoxChanged.cancel();
    };
  }, [onFilterTextBoxChanged]);

  useEffect(() => {
    setInputText(searchText);
    onFilterTextBoxChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <div className="w-[225px] py-1">
      <Input
        type="text"
        placeholder="Search"
        prefix={<SearchOutlined />}
        onChange={(e) => {
          setInputText(e.target.value);
          if (e.target?.value) {
            getSearchText(e?.target?.value);
          }

          onFilterTextBoxChanged();
        }}
        allowClear
        ref={searchInputRef}
        value={inputText}
      />
    </div>
  );
}
export default SearchInputField;
