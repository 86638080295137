import { Route, Switch, HashRouter } from "react-router-dom";
import { ReactNode, useMemo } from "react";
import PrivateRoute from "ProtectedRoute";
import { ApolloProvider } from "@apollo/client";
import AdminLogin from "./pages/admin-login";
import AdminNavbar from "./components/admin-navbar/admin-navbar";
import OrganizationDashboard from "./pages/organizations-list";
import { useGetGQLClientForAdmin } from "./hooks/init-gql-client";
import OrganizationDetail from "./pages/organization-details/organization-details";
import SubscriptionDetail from "./pages/subscription-details";
import AuditProjectFeatureEvents from "./pages/subscription-details/components/audit-project-feature-events";
import AuditUserEvents from "./pages/subscription-details/components/audit-user-events";
import UserActivityDashboard from "./pages/user-activity";

function WithNavBar(props: { children: ReactNode }) {
  const { children } = props;
  const menuLinks = useMemo(
    () => [
      {
        label: "Activity",
        key: "/user_activity"
      },
      {
        label: "Organization",
        key: "/organizations"
      }
    ],
    []
  );
  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar menuLinks={menuLinks} />
      {children}
    </div>
  );
}

function PrivateRoutes() {
  const { gqlClient } = useGetGQLClientForAdmin();
  if (!gqlClient) return <PrivateRoute />;
  return (
    <ApolloProvider client={gqlClient}>
      <WithNavBar>
        <Route exact path="/user_activity" component={UserActivityDashboard} />
        <Route exact path="/organizations" component={OrganizationDashboard} />
        <Route
          exact
          path="/organizations/:orgId"
          component={OrganizationDetail}
        />
        <Route
          exact
          path="/organizations/:orgId/subscriptions/:subId/project/:projectId?"
          component={SubscriptionDetail}
        />
        <Route
          exact
          path="/organizations/:orgId/subscriptions/:subId/project/:projectId/events/login"
          component={AuditUserEvents}
        />
        <Route
          exact
          path="/organizations/:orgId/subscriptions/:subId/project/:projectId/events/project"
          component={AuditProjectFeatureEvents}
        />
      </WithNavBar>
    </ApolloProvider>
  );
}

function AdminApp() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={AdminLogin} />
        <PrivateRoutes />
      </Switch>
    </HashRouter>
  );
}
export default AdminApp;
