import { TOrganizationLog } from "admin-app/models";
import { USER_ACTIVITIES_EVENTS } from "admin-app/services/ciq-queries";
import { ColDef, CsvExportParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, DatePicker } from "antd";
import { userNameCellRenderer } from "components/cell-renders";
import SearchInput from "components/search-input";
import { GridLoadingIndicator } from "components/widgets";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import moment from "moment";
import { useMemo, useRef, useState, useEffect } from "react";
import {
  DATE_FORMAT_MMDDYYYY_HHMM_A,
  DATE_FORMAT_YYYYMMDD,
  DateFilter,
  DateUtils
} from "utils/dateutils";
import "./user-activity.scss";
import Separator from "../../../components/date-range-picker/separator";

function UserActivityDashboard() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [isGridReady, setGridReady] = useState(false);
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState<{
    fromDate: string;
    toDate: string;
  }>({
    fromDate: moment()
      .subtract(1, "months")
      .format(`${DATE_FORMAT_YYYYMMDD} 00:00:00`),
    toDate: moment(Date.now()).format(`${DATE_FORMAT_YYYYMMDD} 23:59:59`)
  });

  const { data: userActivitiesEventData, loading } = useCIQQuery(
    USER_ACTIVITIES_EVENTS,
    {
      variables: {
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate
      }
    }
  );

  const allEvents: any = useMemo(() => {
    if (!userActivitiesEventData) return null;
    const subLevelEvents =
      userActivitiesEventData?.audit_subscription_events || [];
    const projLevelEvents = userActivitiesEventData?.audit_project_events || [];
    const projFeatureEvents =
      userActivitiesEventData?.audit_project_feature_events || [];

    const combinedEvents = [
      ...subLevelEvents,
      ...projLevelEvents,
      ...projFeatureEvents
    ].sort((e1, e2) => (e1 > e2 ? -1 : 1));

    return combinedEvents;
  }, [userActivitiesEventData]);

  const onFirstDataRendered = () => {
    if (allEvents) {
      gridRef?.current?.api?.getFilterInstance(
        "event",
        (eventinstance: any) => {
          eventinstance?.setModel({
            values: ["PROJECT_USER_LOGIN", "SUBSCRIPTION_USER_LOGIN"]
          });
          gridRef.current!.api.onFilterChanged();
        }
      );
    }
  };

  const getFormattedValues = (params: any) => {
    const { value } = params;

    // if (column.colId === "user") {
    //   try {
    //     return `${value}\n${node?.data?.created_by_user?.email}`;
    //   } catch (ex) {
    //     return "";
    //   }
    // }

    return value ?? "";
  };

  const getParams: () => CsvExportParams = () => ({
    columnKeys: gridRef.current?.columnApi
      ?.getAllGridColumns()
      ?.filter((col) => {
        return col.isVisible();
      })
      .map((col) => col.getColId()),
    processCellCallback(params: any): string {
      return getFormattedValues(params);
    },
    fileName: "User Activity log"
  });

  const exportLogAsCSV = () => {
    gridRef.current?.api?.exportDataAsCsv(getParams());
  };

  const columnDefs: ColDef[] = [
    {
      colId: "user",
      headerName: "USER DETAILS",
      filter: true,
      menuTabs: ["filterMenuTab"],
      cellRenderer: userNameCellRenderer,
      valueGetter: ({ data }) => {
        const userInfo = data?.created_by_user ?? data?.user_created;
        const fullName = `${userInfo?.first_name ?? ""} ${
          userInfo?.last_name ?? ""
        }`;
        return fullName;
      }
    },
    {
      colId: "project",
      field: "project.name",
      headerName: "PROJECT NAME",
      valueGetter: ({ data }) => {
        if (data?.project) {
          return data?.project?.name;
        }
        return "";
      },
      filter: true,
      menuTabs: ["filterMenuTab"]
    },
    {
      colId: "subscription",
      field: "subscription",
      headerName: "SUBSCRIPTION NAME",
      valueGetter: ({ data }) => {
        if (data?.project) {
          return data?.project?.owner_subscription?.name;
        }
        return data?.subscription?.name;
      },
      filter: true,
      menuTabs: ["filterMenuTab"]
    },
    {
      colId: "event_date",
      field: "event_date",
      headerName: "EVENT DATE",
      valueGetter: ({ data }) =>
        DateUtils.format(data.created_at, DATE_FORMAT_MMDDYYYY_HHMM_A),
      comparator: DateFilter.comparator,
      tooltipValueGetter: ({ value }) => value
    },
    {
      colId: "event",
      field: "event",
      headerName: "EVENT",
      sortable: true,
      filter: true,
      menuTabs: ["filterMenuTab"],
      valueGetter: ({ data }) => {
        return data?.event_log?.event?.name;
      }
    },
    {
      colId: "organization",
      headerName: "ORGANIZATION NAME",
      filter: true,
      menuTabs: ["filterMenuTab"],
      sortingOrder: ["asc", "desc"],
      valueGetter: ({ data }) => {
        if (data?.project) {
          const organizations =
            data?.project?.owner_subscription.organization_subscriptions || [];
          if (!organizations?.length) return "";
          return organizations[0].organization?.name;
        }
        const organizations =
          data?.subscription?.organization_subscriptions || [];
        if (!organizations?.length) return "";
        return organizations[0].organization?.name;
      }
    }
  ];

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      filter: false,
      resizable: true,
      menuTabs: [],
      sortingOrder: ["asc", "desc"],
      cellClass: "cellClass"
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  return (
    <div className="flex flex-col h-full p-2">
      <div className="flex w-full justify-between items-end p-2">
        <div className="text-[#3B3B3B] text-sm font-semibold">
          User Activity
        </div>
        <div className="flex space-x-2 items-center">
          <RangePicker
            separator={Separator()}
            defaultValue={[
              moment(dateRange.fromDate),
              moment(dateRange.toDate)
            ]}
            onChange={(range: any) => {
              if (range && range?.length === 2) {
                gridRef.current?.api?.showLoadingOverlay();
                setDateRange({
                  fromDate: range[0].format(`${DATE_FORMAT_YYYYMMDD} 00:00:00`),
                  toDate: range[1].format(`${DATE_FORMAT_YYYYMMDD} 23:59:59`)
                });
              }
            }}
          />
          <SearchInput
            placeholder="Search Organizations"
            onChange={(text) => {
              gridRef.current?.api?.setQuickFilter(text);
            }}
          />
          <Button
            disabled={(allEvents && allEvents?.length === 0) || loading}
            onClick={exportLogAsCSV}
          >
            Export
          </Button>
        </div>
      </div>

      <div className="grow flex-col ag-theme-alpine p-2">
        <AgGridReact<TOrganizationLog>
          ref={gridRef}
          rowData={allEvents}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={36}
          loadingOverlayComponent={GridLoadingIndicator}
          onGridReady={() => {
            setGridReady(true);
            gridRef.current?.api.sizeColumnsToFit();
          }}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </div>
  );
}
export default UserActivityDashboard;
