import { Select } from "antd";
import { forwardRef, useState, useImperativeHandle, useMemo } from "react";

const WarrantyCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing } = props;
  const [value, setValue] = useState(props.value);

  const warrantyList = useMemo(
    () =>
      [...Array(21).keys()].map((v: number) => {
        if (v === 20) return { label: "Lifetime warranty", value: 100 };
        return {
          label: `${v + 1} ${v === 0 ? "year" : "years"}`,
          value: v + 1
        };
      }),
    []
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!value) return null;
        return value;
      }
    };
  });

  return (
    <Select
      placeholder="Select warranty"
      options={warrantyList}
      virtual={false}
      style={{ width: "100%" }}
      onChange={(event: any) => {
        setValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    />
  );
});

export default WarrantyCellEditor;
