import { CalendarFilled } from "@ant-design/icons";
import { Button } from "antd";
import CIQDatePicker from "components/custom-date-picker";
import { Moment } from "moment";
import { useState, useImperativeHandle, forwardRef } from "react";

type Props = {
  value?: Moment | null;
  disabled?: boolean;
  onChange: (value: Moment | null, dateString: string) => void;
  disabledDate?: (current: Moment) => boolean;
  renderExtraFooter?: (mode: any) => React.ReactNode;
  title?: string;
};

const DatePickerWithIcon = forwardRef((props: Props, ref: any) => {
  const {
    value,
    disabled = false,
    onChange,
    disabledDate,
    renderExtraFooter,
    title
  } = props;

  const [openDatePicker, setOpenDatePicker] = useState(false);
  useImperativeHandle(ref, () => ({
    close() {
      setOpenDatePicker(false);
    }
  }));

  return (
    <div>
      <Button
        title={title}
        disabled={disabled}
        className="border-0 w-[20px] h-[20px] text-[#3B3B3BCC]"
        icon={<CalendarFilled />}
        onClick={() => {
          setOpenDatePicker(true);
        }}
      />
      <CIQDatePicker
        className="h-0 w-0 p-0 m-0 border-0 border-none bg-white ciq-date-picker"
        allowClear={false}
        open={openDatePicker}
        onOpenChange={() => setOpenDatePicker(false)}
        value={value}
        onChange={onChange}
        disabledDate={disabledDate}
        renderExtraFooter={renderExtraFooter}
      />
    </div>
  );
});
export default DatePickerWithIcon;
