import { Button, Col, Row, Spin, Upload } from "antd";
import { DocumentSection, DocumentView } from "components";
import {
  ErrorMessages,
  noDocumentMessageUpload,
  noDocumentSelectMessage,
  ProjectPermissionEnum
} from "constants/index";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import { AttachmentDoc } from "models/submittal-log";
import { useContext, useState } from "react";
import "./submittal-attachment.css";
import {
  IconLayoutSidebarRightCollapse,
  IconLayoutSidebarRightExpand
} from "@tabler/icons-react";

export type SubmittalAttachmentsProps = {
  documents: Array<AttachmentDoc>;
  referenceDocuments: Array<AttachmentDoc>;
  selectedDocument: AttachmentDoc | undefined;
  UploadAttachment: Function;
  RemoveAttachment: Function;
  setSelectedDocument: any;
  disabled: boolean;
  findLatestAttachmentVersion: (doc: AttachmentDoc) => AttachmentDoc;
  editableFields: any[];
  submittalTitlePanel: JSX.Element;
  isIntegrationMode: boolean;
};

function SubmittalAttachments({
  documents,
  referenceDocuments,
  disabled,
  selectedDocument,
  setSelectedDocument,
  UploadAttachment,
  RemoveAttachment,
  findLatestAttachmentVersion,
  editableFields,
  submittalTitlePanel,
  isIntegrationMode
}: SubmittalAttachmentsProps) {
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const [fullScreen, setFullScreen] = useState(false);
  const { tokenContents } = useContext(ProjectContext);

  const toggleFullScreen = () => {
    setFullScreen((prev) => !prev);
  };
  const fileUploadRequest = async ({ file, onSuccess }: any) => {
    try {
      setFileLoading(true);
      await UploadAttachment({ file, onSuccess });
    } finally {
      setFileLoading(false);
    }
  };

  let documentView = (
    <div className="document-not-selected">{noDocumentMessageUpload}</div>
  );
  let expandToggleButton = null;

  if (documents.length > 0) {
    if (selectedDocument) {
      documentView = <DocumentView file={selectedDocument} />;
      expandToggleButton = (
        <Button
          size="small"
          className="p-0"
          style={{ position: "absolute", zIndex: 100, left: -3, top: "50%" }}
          onClick={toggleFullScreen}
        >
          {fullScreen ? (
            <IconLayoutSidebarRightCollapse size={22} stroke={1.5} />
          ) : (
            <IconLayoutSidebarRightExpand size={22} stroke={1.5} />
          )}
        </Button>
      );
    } else {
      documentView = (
        <div className="document-not-selected">{noDocumentSelectMessage}</div>
      );
    }
  }

  const sidebarColSpan = fullScreen ? 0 : 6;
  const documentColSpan = 24 - sidebarColSpan;

  return (
    <>
      <Row>{submittalTitlePanel}</Row>

      <Row gutter={15} style={{ height: "80vh" }}>
        <Col span={sidebarColSpan}>
          <DocumentSection
            documents={documents}
            referenceDocuments={referenceDocuments}
            RemoveAttachment={RemoveAttachment}
            selectedDocument={selectedDocument!}
            setSelectedDocument={setSelectedDocument}
            findLatestAttachmentVersion={findLatestAttachmentVersion}
            disabled={
              !editableFields.includes("attachment") ||
              isPermissionNotGrantted(
                ProjectPermissionEnum.AddAttachment,
                tokenContents?.role!
              ) ||
              disabled
            }
          />
          <Upload
            showUploadList={false}
            customRequest={fileUploadRequest}
            className="upload-wrapper"
          >
            <Button
              block
              title={(() => {
                if (!editableFields.includes("attachment"))
                  return "Only the Ball In court user can currently add attachments";

                if (
                  isPermissionNotGrantted(
                    ProjectPermissionEnum.AddAttachment,
                    tokenContents?.role!
                  )
                )
                  return ErrorMessages.PermissionNotGranted;

                return "";
              })()}
              disabled={
                isIntegrationMode ||
                !editableFields.includes("attachment") ||
                isPermissionNotGrantted(
                  ProjectPermissionEnum.AddAttachment,
                  tokenContents?.role!
                ) ||
                disabled
              }
            >
              {fileLoading ? <Spin size="small" /> : "Add"}
            </Button>
          </Upload>
        </Col>
        <Col span={documentColSpan}>
          {expandToggleButton}
          {documentView}
        </Col>
      </Row>
    </>
  );
}

export default SubmittalAttachments;
