export default function AppLogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 300.351 51"
      xmlSpace="preserve"
    >
      <path
        fill="#203A72"
        d="M12.418,25.523c-0.092-7.105,5.682-12.925,12.761-13.084V1.001C11.799,1.189,0.931,11.898,1.032,25.523 c0.057,7.708,3.593,14.512,9.162,19.003l7.062-8.772C14.273,33.361,12.472,29.644,12.418,25.523z"
      />
      <path
        fill="#8080AC"
        d="M13.554,30.906c-0.677-1.681-1.129-3.391-1.132-5.466c-0.009-6.913,5.961-12.859,12.757-12.92V8.158 c-9.158,0.049-16.911,7.85-16.911,17.365c0,5.387,2.497,10.209,6.38,13.386l2.578-3.175C17.25,35.755,14.673,33.687,13.554,30.906z"
      />
      <path
        fill="#E2381F"
        d="M38.172,37.303c0,0,1.115-1.413,2.367-3.275c1.025-1.528,1.83-3.275,1.83-3.275l11.033,4.891 c0,0-0.924,2.208-2.636,4.891c-1.831,2.872-3.395,4.757-3.395,4.757L38.172,37.303z"
      />
      <g>
        {" "}
        <path
          fill="#1C93A2"
          d="M43.247,41.718l-8.113-7.175c-2.312,2.591-5.595,4.05-9.446,4.185c-3.118,0.109-6.251-1.142-8.462-2.994 l-7.104,8.748c1.586,1.284,4.126,2.942,6.936,3.933c2.512,0.886,5.219,1.368,7.894,1.384 C31.108,49.839,37.888,47.576,43.247,41.718z"
        />
        <path
          fill="#6AB3BE"
          d="M35.116,34.516c-2.656,2.906-6.25,4.203-9.625,4.188c-3.141-0.015-6.156-1.172-8.252-2.976 c-0.007-0.006-2.605,3.168-2.586,3.184c3.031,2.388,6.778,3.882,10.915,3.882c4.986,0,9.466-2.117,12.604-5.542L35.116,34.516z"
        />
      </g>{" "}
      <g>
        {" "}
        <path
          fill="#EF774B"
          d="M25.173,1.008v11.449c5.662-0.21,11.139,3.582,12.826,9.02l9.573-5.975 C43.852,6.947,35.256,0.959,25.173,1.008z"
        />{" "}
        <path
          fill="#F5A37F"
          d="M25.538,8.141c-0.086,0-0.406,0-0.406,0v4.389c0.014-0.026,0.286-0.014,0.406-0.014 c5.781,0,10.754,3.718,12.461,8.992l3.543-2.205C39.031,12.906,32.798,8.141,25.538,8.141z"
        />{" "}
      </g>{" "}
      <g>
        {" "}
        <path
          fill="#203A72"
          d="M66.458,46.088c-2.115,0-3.963-0.485-5.538-1.455c-1.577-0.969-2.801-2.325-3.673-4.068 c-0.871-1.743-1.307-3.78-1.307-6.11c0-2.34,0.436-4.387,1.307-6.141c0.872-1.752,2.096-3.113,3.673-4.083 c1.575-0.969,3.423-1.454,5.538-1.454c2.114,0,3.961,0.485,5.537,1.454c1.576,0.97,2.801,2.331,3.673,4.083 c0.872,1.753,1.307,3.8,1.307,6.141c0,2.331-0.435,4.368-1.307,6.11c-0.872,1.743-2.097,3.099-3.673,4.068 C70.419,45.603,68.572,46.088,66.458,46.088z M66.473,42.401c1.37,0,2.507-0.362,3.407-1.087c0.901-0.724,1.569-1.689,2.005-2.893 c0.437-1.205,0.654-2.532,0.654-3.981c0-1.44-0.218-2.764-0.654-3.974c-0.436-1.209-1.104-2.181-2.005-2.916 c-0.9-0.734-2.037-1.101-3.407-1.101c-1.382,0-2.525,0.367-3.431,1.101c-0.906,0.735-1.576,1.707-2.012,2.916 c-0.436,1.21-0.654,2.534-0.654,3.974c0,1.449,0.219,2.776,0.654,3.981c0.436,1.204,1.105,2.169,2.012,2.893 C63.947,42.039,65.091,42.401,66.473,42.401z"
        />{" "}
        <path
          fill="#203A72"
          d="M86.258,32.236v13.397h-4.392V23.071h4.215v3.672h0.279c0.52-1.195,1.334-2.154,2.445-2.879 c1.112-0.725,2.515-1.087,4.209-1.087c1.538,0,2.884,0.321,4.039,0.962c1.156,0.642,2.052,1.594,2.689,2.857 c0.636,1.263,0.953,2.825,0.953,4.686v14.351h-4.392V31.811c0-1.635-0.426-2.916-1.277-3.841c-0.853-0.926-2.022-1.389-3.51-1.389 c-1.02,0-1.923,0.221-2.712,0.662c-0.787,0.44-1.409,1.084-1.865,1.931C86.485,30.021,86.258,31.042,86.258,32.236z"
        />{" "}
        <path
          fill="#203A72"
          d="M123.48,28.579l-3.982,0.705c-0.167-0.509-0.428-0.994-0.784-1.455c-0.359-0.459-0.841-0.836-1.447-1.13 c-0.608-0.293-1.366-0.441-2.277-0.441c-1.244,0-2.282,0.277-3.115,0.83c-0.831,0.553-1.248,1.266-1.248,2.138 c0,0.753,0.279,1.36,0.838,1.821c0.558,0.46,1.459,0.837,2.703,1.131L117.751,33c2.075,0.48,3.623,1.219,4.642,2.218 c1.018,0.999,1.527,2.297,1.527,3.893c0,1.352-0.39,2.554-1.167,3.606c-0.78,1.053-1.862,1.878-3.248,2.475 c-1.385,0.598-2.988,0.896-4.811,0.896c-2.525,0-4.587-0.54-6.184-1.623c-1.596-1.082-2.575-2.622-2.938-4.62l4.245-0.646 c0.265,1.106,0.808,1.941,1.632,2.504c0.821,0.563,1.895,0.845,3.216,0.845c1.439,0,2.59-0.302,3.452-0.904 c0.861-0.603,1.292-1.339,1.292-2.21c0-0.706-0.262-1.298-0.786-1.778c-0.523-0.479-1.324-0.841-2.401-1.086l-3.819-0.837 c-2.105-0.48-3.66-1.244-4.662-2.292c-1.004-1.048-1.506-2.375-1.506-3.98c0-1.332,0.371-2.497,1.115-3.496 s1.772-1.779,3.086-2.343c1.311-0.563,2.814-0.845,4.508-0.845c2.439,0,4.359,0.527,5.759,1.579 C122.104,25.409,123.028,26.816,123.48,28.579z"
        />{" "}
        <path
          fill="#203A72"
          d="M139.27,23.071v3.525h-12.324v-3.525H139.27z M130.25,17.665h4.394v21.343c0,0.852,0.125,1.491,0.381,1.917 c0.254,0.426,0.586,0.715,0.991,0.867c0.407,0.151,0.85,0.227,1.329,0.227c0.354,0,0.661-0.024,0.926-0.073 c0.265-0.049,0.471-0.088,0.617-0.118l0.793,3.628c-0.255,0.098-0.617,0.198-1.087,0.301s-1.058,0.159-1.763,0.168 c-1.156,0.02-2.232-0.186-3.231-0.617c-0.999-0.431-1.807-1.097-2.424-1.998c-0.616-0.901-0.926-2.032-0.926-3.393V17.665z"
        />{" "}
        <path
          fill="#203A72"
          d="M144.116,45.633V23.071h4.245v3.584h0.235c0.411-1.214,1.138-2.171,2.182-2.872 c1.043-0.7,2.226-1.05,3.547-1.05c0.274,0,0.6,0.01,0.977,0.029c0.377,0.02,0.674,0.044,0.889,0.074v4.201 c-0.176-0.05-0.489-0.105-0.939-0.169c-0.451-0.063-0.901-0.096-1.352-0.096c-1.037,0-1.961,0.218-2.77,0.653 c-0.808,0.437-1.446,1.039-1.916,1.807c-0.471,0.769-0.705,1.643-0.705,2.622v13.778H144.116z"
        />{" "}
        <path
          fill="#203A72"
          d="M174.302,36.275V23.071h4.406v22.562h-4.318v-3.908h-0.234c-0.52,1.205-1.35,2.207-2.488,3.005 c-1.143,0.798-2.56,1.196-4.254,1.196c-1.449,0-2.73-0.321-3.842-0.962s-1.982-1.594-2.613-2.856 c-0.633-1.264-0.948-2.825-0.948-4.686V23.071h4.393v13.822c0,1.537,0.425,2.761,1.278,3.672c0.852,0.911,1.957,1.366,3.318,1.366 c0.823,0,1.643-0.206,2.461-0.617c0.817-0.411,1.498-1.033,2.042-1.865C174.045,38.617,174.312,37.559,174.302,36.275z"
        />{" "}
        <path
          fill="#203A72"
          d="M194.103,46.088c-2.184,0-4.062-0.496-5.633-1.491c-1.572-0.994-2.779-2.368-3.621-4.121 c-0.842-1.752-1.264-3.76-1.264-6.022c0-2.292,0.431-4.316,1.293-6.074c0.861-1.758,2.076-3.131,3.644-4.121 c1.565-0.989,3.412-1.483,5.538-1.483c1.713,0,3.239,0.316,4.581,0.948c1.343,0.631,2.427,1.516,3.255,2.65 c0.827,1.137,1.32,2.463,1.476,3.981h-4.274c-0.234-1.058-0.771-1.969-1.607-2.732c-0.838-0.764-1.958-1.146-3.357-1.146 c-1.224,0-2.294,0.321-3.209,0.962c-0.916,0.641-1.63,1.552-2.138,2.732c-0.51,1.18-0.764,2.573-0.764,4.179 c0,1.645,0.25,3.064,0.749,4.259c0.499,1.194,1.207,2.12,2.122,2.776c0.916,0.656,1.995,0.984,3.239,0.984 c0.833,0,1.589-0.154,2.27-0.462s1.254-0.751,1.719-1.33c0.465-0.578,0.791-1.272,0.977-2.086h4.274 c-0.155,1.459-0.632,2.762-1.425,3.907c-0.792,1.146-1.854,2.047-3.18,2.703C197.438,45.76,195.885,46.088,194.103,46.088z"
        />{" "}
        <path
          fill="#203A72"
          d="M218.618,23.071v3.525h-12.324v-3.525H218.618z M209.6,17.665h4.392v21.343 c0,0.852,0.127,1.491,0.382,1.917c0.255,0.426,0.585,0.715,0.991,0.867c0.406,0.151,0.85,0.227,1.329,0.227 c0.354,0,0.662-0.024,0.926-0.073c0.264-0.049,0.47-0.088,0.617-0.118l0.794,3.628c-0.256,0.098-0.617,0.198-1.087,0.301 c-0.472,0.103-1.058,0.159-1.764,0.168c-1.155,0.02-2.232-0.186-3.231-0.617c-1-0.431-1.807-1.097-2.424-1.998 c-0.617-0.901-0.925-2.032-0.925-3.393V17.665z"
        />{" "}
        <path
          fill="#203A72"
          d="M225.683,19.589c-0.764,0-1.417-0.257-1.96-0.771c-0.544-0.514-0.815-1.128-0.815-1.843 c0-0.725,0.271-1.344,0.815-1.858c0.543-0.514,1.196-0.771,1.96-0.771s1.418,0.257,1.962,0.771 c0.544,0.514,0.815,1.133,0.815,1.858c0,0.715-0.271,1.33-0.815,1.843C227.101,19.332,226.446,19.589,225.683,19.589z M223.466,45.633V23.071h4.392v22.562H223.466z"
        />{" "}
        <path
          fill="#203A72"
          d="M253.063,23.071l-8.182,22.562h-4.701l-8.195-22.562h4.715l5.714,17.362h0.235l5.699-17.362H253.063z"
        />{" "}
        <path fill="#E2381F" d="M263.213,15.55v30.083h-6.358V15.55H263.213z" />{" "}
        <path
          fill="#E2381F"
          d="M296.102,30.591c0,3.28-0.62,6.071-1.858,8.373c-1.238,2.302-2.924,4.057-5.053,5.266 c-2.131,1.21-4.522,1.814-7.176,1.814c-2.673,0-5.072-0.607-7.197-1.821c-2.126-1.214-3.805-2.972-5.039-5.273 c-1.233-2.301-1.85-5.088-1.85-8.358c0-3.28,0.616-6.072,1.85-8.373c1.234-2.301,2.913-4.056,5.039-5.266 c2.125-1.209,4.524-1.814,7.197-1.814c2.653,0,5.045,0.604,7.176,1.814c2.129,1.209,3.814,2.965,5.053,5.266 C295.481,24.52,296.102,27.311,296.102,30.591z M289.652,30.591c0-2.125-0.315-3.917-0.946-5.376 c-0.632-1.459-1.521-2.565-2.666-3.32c-1.146-0.753-2.488-1.131-4.025-1.131s-2.879,0.377-4.024,1.131 c-1.146,0.755-2.034,1.861-2.667,3.32c-0.631,1.459-0.946,3.252-0.946,5.376c0,2.125,0.315,3.917,0.946,5.376 c0.633,1.459,1.521,2.566,2.667,3.32c1.146,0.754,2.487,1.131,4.024,1.131s2.879-0.376,4.025-1.131 c1.145-0.754,2.034-1.86,2.666-3.32C289.337,34.508,289.652,32.716,289.652,30.591z M279.812,35.174h5.405l2.718,3.497l2.674,3.113 l5.037,6.316h-5.934l-3.467-4.259l-1.777-2.527L279.812,35.174z"
        />{" "}
      </g>{" "}
      <circle
        display="none"
        fill="none"
        stroke="#BE1622"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        cx="25.537"
        cy="25.604"
        r="13.146"
      />{" "}
      <path
        fill="#4F5194"
        d="M25.163,8.161C16.061,8.167,8.167,16.129,8.252,25.523c0.01,1.139,0.038,0.892,0.038,0.471 C8.276,18.309,15.585,8.941,25.163,8.924V8.161z"
      />{" "}
    </svg>
  );
}
