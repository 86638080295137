import { IconProps } from "./submittal-icon-2";

function MaterialIcon({
  className = "",
  onClick,
  style,
  fill = "#3B3B3B",
  size = 24,
  title = "Material"
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      className={className}
      onClick={onClick}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_62_174)">
        <path d="M20.0833 14.0338L21.2854 14.7551C21.5221 14.8972 21.5989 15.2043 21.4569 15.4411C21.4146 15.5114 21.3557 15.5703 21.2854 15.6126L12.5144 20.8751C12.1977 21.0651 11.8021 21.0651 11.4854 20.8751L2.71451 15.6126C2.47772 15.4705 2.40093 15.1634 2.54301 14.9266C2.58523 14.8562 2.64413 14.7973 2.71451 14.7551L3.9166 14.0338L11.9999 18.8838L20.0833 14.0338ZM20.0833 9.33379L21.2854 10.0551C21.5221 10.1972 21.5989 10.5043 21.4569 10.7411C21.4146 10.8114 21.3557 10.8703 21.2854 10.9126L11.9999 16.4838L2.71451 10.9126C2.47772 10.7705 2.40093 10.4634 2.54301 10.2266C2.58523 10.1562 2.64413 10.0973 2.71451 10.0551L3.9166 9.33379L11.9999 14.1838L20.0833 9.33379ZM12.5144 0.142507L21.2854 5.40507C21.5221 5.54714 21.5989 5.85427 21.4569 6.09106C21.4146 6.16144 21.3557 6.22034 21.2854 6.26256L11.9999 11.8338L2.71451 6.26256C2.47772 6.12049 2.40093 5.81336 2.54301 5.57657C2.58523 5.50619 2.64413 5.44729 2.71451 5.40507L11.4854 0.142507C11.8021 -0.0475025 12.1977 -0.0475025 12.5144 0.142507ZM11.9999 2.1662L5.88723 5.83382L11.9999 9.50139L18.1126 5.83382L11.9999 2.1662Z" />
      </g>
    </svg>
  );
}

export default MaterialIcon;
