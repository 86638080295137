/* eslint-disable no-nested-ternary */
import classnames from "classnames";
import { Link, matchPath, useLocation } from "react-router-dom";

import { AppLogoIcon } from "components/svg-icons";
import AccountMenu from "components/account-menu";
import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { matchUserProjectRole } from "utils/utils";
import { Tooltip } from "antd";
import { DATE_FORMAT_MMDDYYYY_HHMM_A_Z, DateUtils } from "utils/dateutils";
// import { useShowDesignAndSpec } from "hooks/common";
import HomeIcon from "components/svg-icons/home-icon";
import DemoTextIcon from "components/svg-icons/demo-icon";
import { EUserTypes, ProjectPermissionEnum } from "../../constants";

type MenuLink = {
  label: string;
  key: string;
};

function DateTimePanel() {
  const [time, setTime] = useState(DateUtils.dateTimeObj());
  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setTime(DateUtils.dateTimeObj());
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);
  return (
    <Tooltip
      className="italic pl-1"
      title={time.format(DATE_FORMAT_MMDDYYYY_HHMM_A_Z)}
    >
      {time.format("hh:mm A (z)")}
    </Tooltip>
  );
}

const borderRightClassname =
  "border-solid border-y-0 border-l-0 border-r-1 border-slate-100";
const verticalCenterClassname = "flex items-center";
const linkClassname =
  "text-[black] hover:text-[black] hover:drop-shadow-[0_0_0.3px_rgba(0,0,0,1)]";
const activeLinkClassname =
  "border-solid border-[black] border-x-0 border-t-0 border-b-2 font-semibold";

type NavbarProps = {
  menuLinks?: MenuLink[];
};

function Navbar(props: NavbarProps) {
  const { menuLinks = [] } = props;
  const { pathname } = useLocation();

  const { subscriptionRole, isFeatureFlagEnabled } =
    useContext(SubscriptionContext);
  const { tokenContents, projectDetails: projectSetting }: TProjectContext =
    useContext(ProjectContext);
  const isBuyoutFeatureEnabled = isFeatureFlagEnabled("BUYOUT");

  const filteredMenuLinks: any = useMemo(() => {
    const cannotViewProjectReports = isPermissionNotGrantted(
      ProjectPermissionEnum.ViewProjectReports,
      tokenContents?.role!
    );
    const cannotViewDesignPage = isPermissionNotGrantted(
      ProjectPermissionEnum.ViewDesignPage,
      tokenContents?.role!
    );
    let fMenuLinks: any = menuLinks;
    if (cannotViewProjectReports) {
      fMenuLinks = fMenuLinks.filter((item: any) => {
        return item.label !== "Reports";
      });
    }

    if (!isBuyoutFeatureEnabled || cannotViewDesignPage) {
      fMenuLinks = fMenuLinks.filter((item: any) => {
        return !["Design", "Preconstruction"].includes(item.label);
      });
    }

    if (subscriptionRole < RoleSubscriptionEnum.subscription_admin) {
      fMenuLinks = fMenuLinks.filter((item: any) => {
        return item.key !== "/settings";
      });
    }
    return fMenuLinks;
  }, [
    isBuyoutFeatureEnabled,
    menuLinks,
    subscriptionRole,
    tokenContents?.role
  ]);

  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserSC = matchUserProjectRole(
    EUserTypes.SUBCONTRACTOR,
    tokenContents?.role
  );

  const isCurrentUserArchitect = matchUserProjectRole(
    EUserTypes.ARCHITECT,
    tokenContents?.role
  );

  return (
    <nav className="bg-white leading-none hide-on-print">
      <div className="h-[45px] flex items-stretch">
        <div
          className={`px-4 ${borderRightClassname} ${verticalCenterClassname}`}
        >
          <Link to="/projects">
            <div className="w-[120px]">
              <AppLogoIcon />
            </div>
          </Link>
        </div>
        {/* {(projectId || pathname === "/new-project") && (
          <div
            className={`px-4 ${borderRightClassname} ${verticalCenterClassname}`}
          >
            <Link
              to="/projects"
              className={`${verticalCenterClassname} ${linkClassname}`}
            >
              <IconHome size={18} />
            </Link>
          </div>
        )} */}
        <div className={`px-2 ${verticalCenterClassname}`}>
          {projectSetting &&
          (isCurrentUserGC || isCurrentUserSC || isCurrentUserArchitect) ? (
            <Link
              className={`${linkClassname} flex items-center space-x-1`}
              to={`/project/${projectSetting?.id}/dashboard`}
            >
              <span>
                <HomeIcon />
              </span>
              <span>{projectSetting?.name}</span>
            </Link>
          ) : (
            projectSetting?.name
          )}
          {projectSetting && <DateTimePanel />}
        </div>
        <div className={`px-4 ${borderRightClassname} flex grow justify-end`}>
          {filteredMenuLinks.map((link: any) => {
            const className = classnames(verticalCenterClassname, "ml-4", {
              [activeLinkClassname]: matchPath(pathname, { path: link.key })
            });
            return (
              <Link
                key={link.key}
                to={link.key}
                className={`${verticalCenterClassname} ml-4 items-stretch ${linkClassname}`}
              >
                {link.label === "Design" || link.label === "Preconstruction" ? (
                  <div className={className}>
                    <span className="-mt-[2px] mr-[2px]">
                      <DemoTextIcon />
                    </span>
                    {link.label}
                  </div>
                ) : (
                  <div className={className}>{link.label}</div>
                )}
              </Link>
            );
          })}
        </div>
        <div className={`px-4 ${verticalCenterClassname} cursor-pointer`}>
          <AccountMenu />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
