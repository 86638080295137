import { Button, Spin } from "antd";
import "./specification-documents-tab.scss";
import { useContext, useMemo, useState } from "react";
import { GET_SPECIFICATIONS } from "services/graphQL/ciq-queries";
import { getGQL } from "utils/utils";
import { useQuerySubscription } from "hooks/common";
import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import classNames from "classnames";
import { DateUtils } from "utils/dateutils";
import UploadSpecPanel from "./upload-spec-panel";
import SpecFileViewer from "./spec-file-viewer";
import {
  ProjectPermissionEnum,
  noDocumentSelectMessage
} from "../../../constants";

function FileItemCard(props: any) {
  const {
    data,
    onUploadVersionClick,
    onFileSelect,
    selectedFile,
    uploadDisabled
  } = props;

  const versionListItems = useMemo(() => {
    const versions: any = [data, ...data.child_specifications];

    versions.sort((a: any, b: any) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);
      return dateB - dateA;
    });

    return versions;
  }, [data]);

  const latestProcessedFile = useMemo(() => {
    let foundFile = null;
    for (let v = 0; v < versionListItems.length; v += 1) {
      if (versionListItems[v].status === "PROCESSED") {
        foundFile = versionListItems[v];
        break;
      }
    }
    return foundFile;
  }, [versionListItems]);

  return (
    <div className="ciq-card">
      <div className="ciq-card-header flex justify-between">
        <div className="truncate">
          <button
            type="button"
            className="font-semibold text-sm truncate border-0 max-w-full bg-white cursor-pointer"
            onClick={() => {
              if (latestProcessedFile) onFileSelect(latestProcessedFile);
            }}
            title={data.title}
            disabled={!latestProcessedFile}
          >
            {data.title}
          </button>
        </div>
        <Button
          type="link"
          size="small"
          className="text-xs"
          onClick={() => {
            onUploadVersionClick(data);
          }}
          disabled={uploadDisabled}
        >
          Upload New Version
        </Button>
      </div>
      <div className="ciq-card-content !p-0 space-y-1">
        {versionListItems.map((version: any) => {
          const isProcessing =
            !version?.status || version?.status === "PROCESSING";
          const isProcessed = version?.status === "PROCESSED";
          return (
            <button
              type="button"
              className={classNames("file-version-item", {
                selected: selectedFile?.id === version?.id,
                "opacity-60": isProcessing,
                "highlight-on-hover": isProcessed
              })}
              key={version.id}
              onClick={() => {
                if (isProcessing) return;
                onFileSelect(version);
              }}
              title={isProcessing ? "Processing" : ""}
            >
              {version?.version_name && (
                <div className="flex">
                  <div className="max-w-[40px] text-left truncate">
                    {version?.version_name}
                  </div>
                  ,
                </div>
              )}
              {version?.created_by_user && (
                <div className="flex">
                  <div className="max-w-[160px] text-left truncate">
                    {version?.created_by_user?.first_name}{" "}
                    {version?.created_by_user?.last_name}
                  </div>
                  ,
                </div>
              )}
              {version?.version_date && (
                <div className="max-w-[100px]">
                  {DateUtils.format(version?.version_date)}
                </div>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
}

function SpecificationDocumentsTab() {
  const { gqlClientForProject, tokenContents }: TProjectContext =
    useContext(ProjectContext);

  const [specDocUploadPanelState, setSpecDocUploadPanelState] = useState<{
    isOpen: boolean;
    mode: null | "new" | "revision";
    parentData?: any;
  }>({
    isOpen: false,
    mode: null
  });

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const { query: qGetSpecs, sub: sGetSpecs } = useMemo(() => {
    return getGQL(GET_SPECIFICATIONS);
  }, []);

  const { data: availableSpecs } = useQuerySubscription(qGetSpecs, sGetSpecs, {
    client: gqlClientForProject
  });

  const sortedSpecs = useMemo(() => {
    if (!availableSpecs?.specifications) return [];
    const specs = [...availableSpecs.specifications];

    specs.sort((a: any, b: any) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);
      return dateB - dateA;
    });
    return specs;
  }, [availableSpecs?.specifications]);

  const onUploadVersionClick = (parentData: any) => {
    setSpecDocUploadPanelState({
      isOpen: true,
      mode: "revision",
      parentData
    });
  };
  const onUploadNewClick = () => {
    setSpecDocUploadPanelState({
      isOpen: true,
      mode: "new"
    });
  };

  const onFileSelectForView = (data: any) => {
    setSelectedFile(data);
  };

  const noSelectionMsgPlaceholder = () => {
    if (!sortedSpecs || !sortedSpecs.length) return null;
    return (
      <div className="h-full flex items-center justify-center text-gray-400 bg-gray-100 m-2">
        <span>{noDocumentSelectMessage}</span>
      </div>
    );
  };

  const uploadDisabled = isPermissionNotGrantted(
    ProjectPermissionEnum.UploadSpecSectionFile,
    tokenContents?.role!
  );

  const noDocsView = useMemo(() => {
    if (!availableSpecs?.specifications.length) {
      if (uploadDisabled) {
        return (
          <div className="h-full flex items-center justify-center ">
            <div className="text-[#00000080] font-normal text-lg">
              No spec documents uploaded
            </div>
          </div>
        );
      }

      return (
        <div className="h-full flex items-center justify-center ">
          <div>
            <button
              type="button"
              className="border-0 bg-transparent text-[#4F91F5E5] font-normal text-lg w-full underline cursor-pointer"
              onClick={onUploadNewClick}
              disabled={uploadDisabled}
            >
              Click here
            </button>
            <div className="text-[#00000080] font-normal text-lg">
              To upload Spec Documents
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, [availableSpecs?.specifications.length, uploadDisabled]);

  return (
    <div className="spec-docs-tab bg-white h-[87vh] p-2 pt-0">
      <div className="flex pt-2 p-1 font-medium italic text-sm text-[#3B3B3B] justify-center">
        This feature is under development and available only for demonstration
        purposes.
      </div>
      <div className="h-[calc(87vh-30px)] grid grid-cols-12 gap-1 pt-1">
        <div className="h-full col-span-3 overflow-y-auto">
          {availableSpecs ? (
            <div className="h-full">
              {noDocsView}
              {availableSpecs?.specifications.length ? (
                <div className="pr-2 space-y-2">
                  <div className="space-y-1.5">
                    {sortedSpecs.map((spec: any) => {
                      return (
                        <FileItemCard
                          key={spec.id}
                          data={spec}
                          onUploadVersionClick={onUploadVersionClick}
                          onFileSelect={onFileSelectForView}
                          selectedFile={selectedFile}
                          uploadDisabled={uploadDisabled}
                        />
                      );
                    })}
                  </div>
                  <div className="py-2 bg-white sticky bottom-0">
                    <Button
                      block
                      onClick={onUploadNewClick}
                      disabled={uploadDisabled}
                    >
                      Add Spec Document
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <Spin size="large" />
            </div>
          )}
        </div>
        <div className="col-span-9 h-full overflow-hidden border-solid border-2 border-gray-100 pb-2">
          {selectedFile ? (
            <div className="h-full overflow-hidden">
              <div className="p-2.5 border-solid border-0 border-b-2 border-b-gray-100 grid grid-cols-2 gap-x-4">
                <div
                  className="font-semibold truncate"
                  title={selectedFile.title}
                >
                  {selectedFile.title}
                </div>
                <div className="font-semibold truncate">
                  <span className="mr-2">Revision:</span>
                  {selectedFile?.version_name && (
                    <span>{selectedFile?.version_name}</span>
                  )}
                </div>
              </div>
              <div className="h-full overflow-hidden pb-12 mt-1">
                <SpecFileViewer
                  fileKey={selectedFile?.spec_text_analysis_stat?.spec_file_key}
                  pageNumber={1}
                />
              </div>
            </div>
          ) : (
            noSelectionMsgPlaceholder()
          )}
        </div>
      </div>

      {specDocUploadPanelState.isOpen ? (
        <UploadSpecPanel
          showPanel={specDocUploadPanelState.isOpen}
          mode={specDocUploadPanelState.mode}
          parentData={specDocUploadPanelState.parentData}
          hidePanel={() => {
            setSpecDocUploadPanelState({
              isOpen: false,
              mode: null
            });
          }}
        />
      ) : null}
    </div>
  );
}

export default SpecificationDocumentsTab;
