import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FeatureSpecSectionDropdown from "components/feature-spec-section-dropdown";
import { useEffect, useState } from "react";
import { updatePropertyAtPath } from "utils/utils";

export default function BidPackageEdtableGeneralInfo(props: {
  featureInstanceData: any;
  actions: any;
  disabled: boolean;
}) {
  const { featureInstanceData, actions, disabled } = props;

  const [localFeatureInstanceData, setLocalFeatureInstanceData] =
    useState<any>();

  useEffect(() => {
    if (!featureInstanceData) return;
    setLocalFeatureInstanceData(structuredClone(featureInstanceData));
  }, [featureInstanceData]);

  return (
    <Form layout="vertical">
      <Form.Item label="Bid Scope">
        <FeatureSpecSectionDropdown
          disabled={disabled}
          selectedIds={localFeatureInstanceData?.feature_instance_spec_sections.map(
            (specSection: any) => {
              return specSection.spec_section.id;
            }
          )}
          onDelete={actions.deleteFeatureInstanceSpecSectionByIds}
          onInsert={actions.insertFeatureInstanceSpecSection}
        />
      </Form.Item>
      <Form.Item label="Bid Package Description">
        <TextArea
          disabled={disabled}
          value={localFeatureInstanceData?.description}
          rows={5}
          placeholder="Enter Description"
          onChange={(event: any) => {
            setLocalFeatureInstanceData(
              updatePropertyAtPath(
                localFeatureInstanceData,
                "description",
                event.target.value
              )
            );
          }}
          onBlur={async (event: any) => {
            const response = await actions.updateFeatureInstanceFields({
              description: event.target.value
            });
            if (!response.success) {
              setLocalFeatureInstanceData(
                updatePropertyAtPath(
                  localFeatureInstanceData,
                  "description",
                  featureInstanceData.description
                )
              );
            }
          }}
        />
      </Form.Item>
      <Form.Item label="Wave">
        <Input
          disabled={disabled}
          value={localFeatureInstanceData?.feature_bid_package?.wave}
          onChange={(event: any) => {
            setLocalFeatureInstanceData(
              updatePropertyAtPath(
                localFeatureInstanceData,
                "feature_bid_package.wave",
                event.target.value
              )
            );
          }}
          onBlur={async (event: any) => {
            const response = await actions.updateBidPackageFields({
              wave: event.target.value
            });

            if (!response.success) {
              setLocalFeatureInstanceData(
                updatePropertyAtPath(
                  localFeatureInstanceData,
                  "feature_bid_package.wave",
                  featureInstanceData?.feature_bid_package.wave
                )
              );
            }
          }}
        />
      </Form.Item>
    </Form>
  );
}
