import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import { useContext, useEffect, useRef, useState } from "react";
import { DATE_FORMAT_YYYYMMDD, DateUtils } from "utils/dateutils";
import { ProjectContext } from "context/ProjectProvider";
import { getScheduleLookupReport } from "services/auth";
import CiqGridViewWrapper from "components/ciq-grid-view-wrapper";
import { TActivityTask } from "./models";
import { TDateRangeActivity } from "../components/activities-filter-form";
import { DetailCellRendererActivity } from "./detail-cell-renderer";
import { activityColDefs } from "./col-defs/activity-col-def";
import { exportActivityReportAsCSV } from "./utils/export-activity-report-csv";
import { customSearchForActivityReport } from "./utils/custom-search";
import { translateWithParentInfo } from "./utils/translate-with-parent-info";

function ScheduleActivitiesReportComponent({
  openFilter,
  reportFilter
}: {
  openFilter: () => void;
  reportFilter: TDateRangeActivity | undefined;
}) {
  const gridRef = useRef<AgGridReact<TActivityTask>>(null);
  const { tokenRetrievalState, projectDetails } = useContext(ProjectContext);
  const [activities, setActivities] = useState<Array<TActivityTask>>();
  const [searchText, setSearchText] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    const updateReportData = async () => {
      const response = await getScheduleLookupReport(
        tokenRetrievalState.token,
        {
          start_date: reportFilter?.startDate
            ? DateUtils.format(reportFilter?.startDate, DATE_FORMAT_YYYYMMDD)
            : null,
          end_date: reportFilter?.endDate
            ? DateUtils.format(reportFilter?.endDate, DATE_FORMAT_YYYYMMDD)
            : null
        }
      );
      if (response.data && response.data.success) {
        const data = response.data.success as Array<TActivityTask>;
        setActivities(translateWithParentInfo(data));
      } else {
        console.log(response);
        setActivities([]);
      }
    };
    updateReportData();
  }, [
    reportFilter?.endDate,
    reportFilter?.startDate,
    tokenRetrievalState.token
  ]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api)
      customSearchForActivityReport(searchText, gridRef.current.api);
  }, [searchText]);

  const headerItems = [
    <CiqAgSearch
      key="ScheduleActivitiesReportComponent_search"
      gridRef={gridRef}
      placeholder="Search Activities"
      onSearch={(text) => {
        setSearchText(text);
      }}
    />,
    <Button key="ScheduleActivitiesReportComponentt_new" onClick={openFilter}>
      Run New Report
    </Button>,
    <Button
      key="ScheduleActivitiesReportComponent_exportCSV"
      disabled={loadingExport || !gridRef.current || !projectDetails}
      loading={loadingExport}
      onClick={() => {
        if (gridRef.current) {
          setLoadingExport(true);
          gridRef.current!.api.forEachNode((node) => {
            node.setExpanded(true);
          });

          setTimeout(() => {
            try {
              if (gridRef.current && projectDetails) {
                exportActivityReportAsCSV(gridRef.current, projectDetails);
              }
            } finally {
              setLoadingExport(false);
            }
          }, 3000);
        }
      }}
    >
      Export CSV
    </Button>
  ];
  return (
    <CiqGridViewWrapper
      headerInfo={{
        entityName: "SCHEDULE LOOKAHEAD REPORT",
        gridRef,
        items: headerItems
      }}
      gridInfo={{
        gridRef,
        columnDefs: activityColDefs,
        rowData: activities,
        masterDetail: true,
        detailCellRenderer: DetailCellRendererActivity,
        detailRowAutoHeight: true,
        sideBar: null,
        suppressMovableColumns: true,
        context: { projectDetails },
        onRowGroupOpened: (event) => {
          if (event.expanded && gridRef.current && gridRef.current.api)
            customSearchForActivityReport(searchText, gridRef.current.api);
        }
      }}
    />
  );
}
export default ScheduleActivitiesReportComponent;
