import { useContext, useState } from "react";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Popover, Space, message } from "antd";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  MUTATION_DELETE_VIEW_CONFIG,
  MUTATION_SAVE_VIEW_CONFIG
} from "services/graphQL/mutations";
import { ProjectContext } from "context/ProjectProvider";
import { useMutation } from "@apollo/client";
import { QUERY_USER_CONFIG_VIEWS } from "services/graphQL/queries";
import styles from "./ColumnsViews.module.scss";
import { EFeaturePageId } from "../../constants";

export type TColumnType = {
  order: number;
  field: {
    name: string;
    filter: any;
    sortOrder: "asc" | "desc" | null;
    range: any;
  };
  visibility: boolean;
};

export type TSaveColumnState = {
  columnList: Array<TColumnType>;
  quickSearch: string;
};

type Props = {
  setFilterStateFromData: (columnState: TSaveColumnState) => void;
  getColumnStateFromGrid: () => {
    columnList: any;
    quickSearch: string;
  } | null;
  afterApplyFilterCallBack?: Function;
  featureId: EFeaturePageId;
};

function ColumnConfigViews(props: Props) {
  const {
    setFilterStateFromData,
    getColumnStateFromGrid,
    afterApplyFilterCallBack,
    featureId
  } = props;
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isCreatingView, setCreateViewUI] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const hide = () => {
    setOpen(false);
  };

  const { gqlClientForProject } = useContext(ProjectContext);

  const { data: viewsData, refetch: refetchViews } = useCIQQuery<{
    project_feature_user_view: Array<{
      id: string;
      name: string;
      column_config: TSaveColumnState;
    }>;
  }>(QUERY_USER_CONFIG_VIEWS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: { feature_id: featureId }
  });

  const [saveViewColumnsConfig] = useMutation(MUTATION_SAVE_VIEW_CONFIG, {
    client: gqlClientForProject
  });

  const [deleteViewColumnsConfig] = useMutation(MUTATION_DELETE_VIEW_CONFIG, {
    client: gqlClientForProject
  });

  const onViewApply = async (view: TSaveColumnState) => {
    console.log(view);
    setFilterStateFromData(view);
    hide();
    setTimeout(() => {
      if (afterApplyFilterCallBack) afterApplyFilterCallBack();
    }, 500);
  };

  const onCreateNewView = async (name: string) => {
    const currentColumnState = getColumnStateFromGrid() as any;
    console.log(currentColumnState);
    if (currentColumnState) {
      const res = await saveViewColumnsConfig({
        variables: {
          name,
          feature_id: featureId,
          column_config: currentColumnState
        }
      });
      if (res.data) {
        message.success("Successfully added a view");
        refetchViews();
      } else if (res.errors) {
        message.error(res.errors[0].message);
      }
    }
  };

  const onDeleteView = (view: { id: string; name: string }) => {
    hide();
    Modal.confirm({
      content: `Do you want to remove ${view.name} View ?`,
      onOk: async () => {
        const res = await deleteViewColumnsConfig({
          variables: { id: view.id }
        });
        if (res.data) {
          message.success(`Successfully removed ${view.name}.`);
          refetchViews();
        } else if (res.errors) {
          message.error(res.errors[0].message);
        }
      }
    });
  };

  const listViewContent = (
    <div className={styles.boxStyle}>
      <Button
        className="w-full bg-[#F5F5F5] bg-transparent flex items-center justify-center p-2 cursor-pointer border-none"
        onClick={() => {
          setCreateViewUI(true);
        }}
        disabled={viewsData && viewsData.project_feature_user_view.length >= 10}
        title={
          viewsData && viewsData.project_feature_user_view.length >= 10
            ? "Upto 10 views can save."
            : ""
        }
      >
        Save Current View
      </Button>
      {viewsData &&
        viewsData.project_feature_user_view.map((v) => (
          <Space
            key={v.id}
            className="w-full space-x-1 flex items-center p-2 justify-between bg-white hover:bg-opacity-90"
          >
            <Space
              className="grow truncate cursor-pointer"
              onClick={() => {
                onViewApply(v.column_config);
              }}
            >
              {v.name}
            </Space>
            <DeleteOutlined
              className="cursor-pointer"
              onClick={() => {
                onDeleteView(v);
              }}
            />
          </Space>
        ))}
    </div>
  );

  const createNewView = (
    <div className={styles.boxStyle}>
      <Form
        form={form}
        title="VIEW NAME"
        layout="vertical"
        className="bg-white p-3"
        onFinish={(values) => {
          const name = (values.name || "").trim();
          onCreateNewView(name);
          setCreateViewUI(false);
          form.resetFields();
        }}
      >
        <Form.Item
          label="VIEW NAME"
          name="name"
          required
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please enter view name."
            },
            {
              validator(rule, value) {
                if (value) {
                  const name = value.trim().toLowerCase();
                  if (
                    viewsData &&
                    viewsData.project_feature_user_view.some(
                      (v) => v.name.toLowerCase() === name
                    )
                  )
                    return Promise.reject(
                      new Error("View Name already exist.")
                    );
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder="Enter View Name here" />
        </Form.Item>
        <div className="flex justify-end space-x-4">
          <Button htmlType="submit" type="primary">
            SAVE
          </Button>
          <Button
            onClick={() => {
              setCreateViewUI(false);
            }}
          >
            CANCEL
          </Button>
        </div>
      </Form>
    </div>
  );

  return (
    <Popover
      content={isCreatingView ? createNewView : listViewContent}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName={styles.popoverNoPadding}
      placement="bottomLeft"
      showArrow={false}
    >
      <Button>
        Views{" "}
        {open ? (
          <UpOutlined className="text-xs" />
        ) : (
          <DownOutlined className="text-xs" />
        )}
      </Button>
    </Popover>
  );
}
export default ColumnConfigViews;
