/* eslint-disable no-plusplus */
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useSubscription } from "@apollo/client";
import { Button, Checkbox, Form, Input, message, Select, Spin } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import {
  ErrorMessages,
  ProjectPermissionEnum,
  WeekdaysToNumberMap
} from "constants/index";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import { useProjectPK } from "hooks/project";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  MUTATION_UPDATE_PROJECT_CALENDAR,
  MUTATION_UPDATE_PROJECT_HOLIDAY_CALENDAR,
  MUTATION_INSERT_PROJECT_HOLIDAY_TO_EXISTING_CALENDAR,
  MUTATION_UPDATE_PROJECT_CALENDAR_WEEKDAYS
} from "services/graphQL/mutations";
import { SUBSCRIPTION_CALENDAR_LIST } from "services/graphQL/subscriptions";
import "./project-calendar.scss";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";
import CIQDatePicker from "components/custom-date-picker";

function ProjectCalendar() {
  const { gqlClientForProject, tokenContents } = useContext(ProjectContext);
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingCalendars, setLoadingCalendars] = useState(true);
  const [isDisable, setFieldsDisabled] = useState(true);
  const { projectId } = useParams() as any;
  const { Option } = Select;
  const [selectedYear, setSelectedYear] = useState({
    year: "",
    holidays: [{}]
  });
  const [groupedYearHolidays, setGroupedYearHolidays] = useState<any>();

  const { data: projectPkData } = useProjectPK(projectId, gqlClientForProject);
  const shiftweekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  const [editProjectCalendar] = useMutation<any>(
    MUTATION_UPDATE_PROJECT_CALENDAR,
    {
      client: gqlClientForProject
    }
  );

  const [editProjectCalendarWeekdays] = useMutation<any>(
    MUTATION_UPDATE_PROJECT_CALENDAR_WEEKDAYS,
    {
      client: gqlClientForProject
    }
  );

  const [editProjectHolidayCalendar] = useMutation<any>(
    MUTATION_UPDATE_PROJECT_HOLIDAY_CALENDAR,
    {
      client: gqlClientForProject
    }
  );

  const [insertProjectHolidayToExistingCalendar] = useMutation<any>(
    MUTATION_INSERT_PROJECT_HOLIDAY_TO_EXISTING_CALENDAR,
    {
      client: gqlClientForProject
    }
  );

  const { data: calendarData } = useSubscription(SUBSCRIPTION_CALENDAR_LIST, {
    shouldResubscribe: true,
    client: gqlClientForProject,
    variables: { projectId }
  });

  const [selectedCalendar, setSelectedCalendar] = useState<any>();

  async function editProjHolidayCalendar(holidayArr: any) {
    const holidaysArr = selectedCalendar?.calendar_holiday_lists;
    const editedHolidayArr = holidayArr;

    const deletedArr = holidaysArr?.filter((oldholiday: any) => {
      return !editedHolidayArr.find((newholiday: any) => {
        return newholiday.id === oldholiday.id;
      });
    });

    for (let i = 0; i < deletedArr.length; i++) {
      const oldHoliday = deletedArr[i];

      const editProjectHolidayCalendarResponse = editProjectHolidayCalendar({
        variables: {
          where: {
            id: { _eq: oldHoliday?.id },
            gantt_calendar_id: { _eq: selectedCalendar?.id }
          },
          _set: {
            deleted: true
          }
        }
      });

      console.log("Deleted holiday: ", editProjectHolidayCalendarResponse);
    }

    if (holidayArr.length === 0) return;

    const editArr = editedHolidayArr?.filter((oldholiday: any) => {
      return !deletedArr.find((deletedholiday: any) => {
        return deletedholiday.id === oldholiday.id;
      });
    });

    // Updating Holiday List
    for (let i = 0; i < editArr.length; i++) {
      const newHoliday = editArr[i];
      if (newHoliday?.id) {
        // Updating Existing holiday from Calendar
        for (let j = 0; j < holidaysArr.length; j++) {
          const oldHoliday = holidaysArr[j];
          if (newHoliday.id === oldHoliday.id) {
            if (
              newHoliday.holiday_name !== oldHoliday.holiday_name ||
              newHoliday.date.format(DATE_FORMAT_MMDDYYYY) !==
                oldHoliday.date.format(DATE_FORMAT_MMDDYYYY)
            ) {
              const editProjectHolidayCalendarResponse =
                editProjectHolidayCalendar({
                  variables: {
                    where: {
                      id: { _eq: newHoliday?.id },
                      gantt_calendar_id: { _eq: selectedCalendar?.id }
                    },
                    _set: {
                      holiday_name: newHoliday.holiday_name,
                      date: newHoliday.date.format(DATE_FORMAT_MMDDYYYY),
                      country_code: ""
                    }
                  }
                });

              console.log(editProjectHolidayCalendarResponse);
            }
          }
        }
      } else if (newHoliday.date || newHoliday.name) {
        // Inserting new holiday from to existing calendar
        const object: any = {
          holiday_name: newHoliday.holiday_name,
          date: newHoliday.date.format(DATE_FORMAT_MMDDYYYY),
          country_code: "",
          gantt_calendar_id: selectedCalendar?.id
        };
        const insertProjectHolidayToExistingCalendarResponse =
          insertProjectHolidayToExistingCalendar({
            variables: { object: [object] }
          });
        console.log(insertProjectHolidayToExistingCalendarResponse);
      }
    }
  }

  async function updateProjectCalendarWeekdays(weekdays: any) {
    const deletedWeekdays = selectedCalendar?.calendar_shift_mapping?.filter(
      (shift: any) => {
        return !weekdays.find((weekday: string) => {
          return weekday === shift?.work_day_mapping?.name;
        });
      }
    );

    const addedWeekdays = selectedCalendar?.calendar_shift_mapping?.filter(
      (shift: any) => {
        return !deletedWeekdays.find((deletedWeekDay: any) => {
          return (
            deletedWeekDay?.work_day_mapping?.name ===
            shift?.work_day_mapping?.name
          );
        });
      }
    );

    const shifts = selectedCalendar?.work_shifts;

    if (shifts?.length > 0) {
      const objects: any[] = [];

      // eslint-disable-next-line array-callback-return
      addedWeekdays.map((workday: any) => {
        objects.push({
          calendar_id: selectedCalendar?.id,
          shift_id: shifts[0].id,
          work_day: WeekdaysToNumberMap[workday?.work_day_mapping?.name],
          deleted: false
        });
      });

      // eslint-disable-next-line array-callback-return
      deletedWeekdays.map((workday: any) => {
        objects.push({
          calendar_id: selectedCalendar?.id,
          shift_id: shifts[0].id,
          work_day: WeekdaysToNumberMap[workday?.work_day_mapping?.name],
          deleted: true
        });
      });

      await editProjectCalendarWeekdays({
        variables: {
          objects
        }
      });
    }
  }
  async function editCalendar(values: any) {
    const object: any = {};
    Object.keys(values).forEach(async (key: string) => {
      if (values[key] && key !== "name") {
        if (key === "calendar_holiday_lists") {
          await editProjHolidayCalendar(values[key]);
        } else if (key === "work_time_days") {
          await updateProjectCalendarWeekdays(values[key]);
        } else {
          object[key] = values[key];
        }
      }
    });

    if (Object.keys(object)) {
      setLoading(true);
      const editProjectCalendarResponse = await editProjectCalendar({
        variables: {
          where: { id: { _eq: selectedCalendar?.id } },
          _set: object
        }
      });
      setLoading(false);

      if (editProjectCalendarResponse.errors) {
        message.error(editProjectCalendarResponse.errors[0].message);
        return;
      }
      if (
        editProjectCalendarResponse?.data?.update_gantt_calendar
          ?.affected_rows === 1
      ) {
        setFieldsDisabled(true);
        message.success("Calendar updated successfully.");
      } else {
        message.error("Failed to update calendar.");
      }
    }
  }

  useEffect(() => {
    if (calendarData) {
      const projectCalendars = calendarData?.gantt_calendar || [];
      if (projectCalendars.length > 0) {
        const calendar = { ...projectCalendars[0] };
        const holidays = [];
        const workdays = [];
        for (let j = 0; j < calendar.calendar_holiday_lists.length; j++) {
          const holiday = calendar.calendar_holiday_lists[j];
          holiday.date = DateUtils.dateTimeObj(holiday.date);
          holiday.yearNumber = holiday.date.year();
          holidays.push(holiday);
        }
        const groupedHolidays: any = {};
        const filterKeys: any[] = ["yearNumber"];

        holidays.forEach((hld: any) => {
          filterKeys
            .reduce((prev: any, curr: any, i) => {
              // eslint-disable-next-line no-param-reassign
              prev[hld[curr]] =
                prev[hld[curr]] || (i + 1 === filterKeys.length ? [] : {});
              return prev[hld[curr]];
            }, groupedHolidays)
            .push(hld);
        });

        setGroupedYearHolidays(groupedHolidays);
        for (let j = 0; j < calendar.calendar_shift_mapping.length; j++) {
          const shift = calendar.calendar_shift_mapping[j];
          if (Object.keys(shift).length > 0) {
            if (!shift?.deleted) {
              workdays.push(shift.work_day_mapping.name);
            }
          }
        }

        if (selectedYear.year !== "") {
          const hldList = groupedHolidays[selectedYear.year];
          calendar.calendar_holiday_lists = hldList;
          setSelectedYear({
            year: selectedYear.year,
            holidays: hldList
          });
        } else {
          const currentYearKey = DateUtils.dateTimeObj().format("YYYY");
          const currentYearValue = groupedHolidays[
            currentYearKey
          ] as Array<any>;
          if (currentYearValue) {
            calendar.calendar_holiday_lists = currentYearValue;
            setSelectedYear({
              year: currentYearKey,
              holidays: currentYearValue
            });
          } else {
            calendar.calendar_holiday_lists =
              Object.keys(groupedHolidays).length > 0
                ? Object.values(groupedHolidays)[0]
                : [];
            if (Object.keys(groupedHolidays).length > 0) {
              setSelectedYear({
                year: Object.keys(groupedHolidays)[0],
                holidays: Object.values(groupedHolidays)[0] as []
              });
            }
          }
        }

        calendar.work_time_days = workdays;

        setSelectedCalendar(calendar);
        form.setFieldsValue(calendar);
      }
      setLoadingCalendars(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarData, projectPkData]);

  const onSubmit = async (values: any) => {
    editCalendar(values);
  };

  const onEditPencilClick = () => {
    setFieldsDisabled(false);
  };

  const onCancelClick = () => {
    setFieldsDisabled(true);
    form.setFieldsValue(selectedCalendar);
  };

  if (isLoadingCalendars) {
    return (
      <div className="flex flex-col h-screen overflow-hidden justify-center items-center">
        <Spin />
      </div>
    );
  }

  const holidayListRules = {
    date: (key: number) => [
      {
        validateTrigger: "onSubmit",
        validator: (_: any, value: string) => {
          if (!value) {
            if (form.getFieldValue("calendar_holiday_lists")[key]) {
              const holiday: any = form.getFieldValue("calendar_holiday_lists")[
                key
              ];
              if (holiday.holiday_name) {
                return Promise.reject(new Error(ErrorMessages.HolidayDateMsg));
              }
            }
          }
          return Promise.resolve();
        }
      }
    ],
    holiday_name: (key: number) => [
      {
        validateTrigger: "onSubmit",
        message: ErrorMessages.HolidayNameMsg,
        validator(_: any, value: string) {
          if (!value) {
            if (form.getFieldValue("calendar_holiday_lists")[key]) {
              const holiday: any = form.getFieldValue("calendar_holiday_lists")[
                key
              ];
              if (holiday.date) {
                return Promise.reject(new Error(ErrorMessages.HolidayNameMsg));
              }
            }
          }
          return Promise.resolve();
        }
      }
    ]
  };

  return (
    <Form
      className="calendar-page flex flex-col p-1 overflow-y-auto bg-white h-[calc(100vh-150px)]"
      preserve={false}
      form={form}
      scrollToFirstError
      layout="vertical"
      initialValues={{
        name: selectedCalendar?.name,
        description: selectedCalendar?.description,
        work_time_days: selectedCalendar?.work_time_days,
        calendar_holiday_lists: selectedCalendar?.calendar_holiday_lists || [{}]
      }}
      onFinish={(values: any) => {
        onSubmit(values);
      }}
    >
      <div className="w-full flex space-x-10 px-1">
        <div className="flex-col w-96 py-1">
          <div className="heading-title">Description</div>
          <FormItem name="description">
            <TextArea
              rows={4}
              placeholder="Enter the description"
              maxLength={150}
              disabled={isDisable}
            />
          </FormItem>
          <div className="heading-title">Work days</div>
          <FormItem name="work_time_days">
            <Checkbox.Group disabled={isDisable} className="block">
              {shiftweekdays.map((day) => (
                <div key={day}>
                  <Checkbox value={day}>{day}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </FormItem>
        </div>

        <div className="w-full">
          <div className="flex w-full justify-between items-center">
            <div className="heading-title w-full">Holiday List</div>
            <div className="flex w-full justify-end space-x-2">
              <Select
                value={selectedYear.year}
                loading={isLoadingCalendars}
                disabled={!isDisable}
                showSearch
                className="w-24"
                onChange={(year) => {
                  setSelectedYear({
                    year,
                    holidays: groupedYearHolidays[year] || []
                  });
                  form.setFieldsValue({
                    calendar_holiday_lists: groupedYearHolidays[year] || []
                  });
                  const cal = { ...selectedCalendar };
                  cal.calendar_holiday_lists = groupedYearHolidays[year] || [];
                  setSelectedCalendar(cal);
                }}
              >
                {Object.keys(groupedYearHolidays).length > 0 &&
                  Object.keys(groupedYearHolidays).map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
              </Select>
              {isDisable &&
                !isPermissionNotGrantted(
                  ProjectPermissionEnum.AddEditCalendar,
                  tokenContents?.role!
                ) && (
                  <Button
                    className="w-24"
                    type="primary"
                    onClick={onEditPencilClick}
                    title={
                      !selectedCalendar?.editable
                        ? "The calendar can no longer be edited as Submittal and Material Schedules have been computed."
                        : ""
                    }
                  >
                    Edit
                  </Button>
                )}
              {!isDisable && (
                <div className="flex justify-end space-x-2">
                  <Button
                    disabled={isLoading}
                    onClick={onCancelClick}
                    className="w-24"
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    Update calendar
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full lg:w-3/5 ">
            <Form.List name="calendar_holiday_lists">
              {(fields, { add, remove }) => {
                return (
                  <div className="flex-col">
                    <div className="flex uppercase space-x-5">
                      <div className="w-48 text-left">Date</div>
                      <div className="w-full text-left">Holiday Name</div>
                    </div>
                    <div className="flex-col">
                      {fields.map((field) => {
                        return (
                          <div className="flex space-x-5" key={field.key}>
                            <FormItem
                              {...field}
                              className="w-48"
                              name={[field.name, "date"]}
                              rules={holidayListRules.date(field.key)}
                            >
                              <CIQDatePicker
                                format={DATE_FORMAT_MMDDYYYY}
                                disabled={isDisable}
                                className="w-full text-left"
                              />
                            </FormItem>
                            <FormItem
                              {...field}
                              className="w-full"
                              name={[field.name, "holiday_name"]}
                              rules={holidayListRules.holiday_name(field.key)}
                            >
                              <Input
                                placeholder="Holiday Name"
                                disabled={isDisable}
                              />
                            </FormItem>
                            {!isDisable && (
                              <div className="">
                                <Button
                                  block
                                  onClick={() => remove(field.name)}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {!isDisable && (
                      <Button className="px-6" onClick={add}>
                        Add holiday
                      </Button>
                    )}
                  </div>
                );
              }}
            </Form.List>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default ProjectCalendar;
