import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { getFileDownloadURL } from "services/file-mgmt";
import ErrorBoundary from "components/error-boundary";
import CiqPdfViewer from "./ciq-pdf-viewer";
import PdfLoaderDiv from "./pdf-loader";
import { BoundingBoxPositionType } from "./models";

function CiqDocumentView({
  blobKey,
  boundingBoxPossition
}: {
  blobKey: string;
  boundingBoxPossition?: BoundingBoxPositionType;
}) {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);

  const init = useCallback(async () => {
    const fileDownloadURLResp: any = await getFileDownloadURL([blobKey]);
    if (fileDownloadURLResp.data) {
      const link = fileDownloadURLResp.data.success.urls[blobKey];
      setUrl(link);
    }
    if (fileDownloadURLResp.error) {
      setError(true);
      message.error(fileDownloadURLResp.error.msg);
    }
  }, [blobKey]);

  useEffect(() => {
    setUrl(undefined);
    setError(false);
    if (blobKey) init();
  }, [blobKey, init]);

  const onExpiredUrl = useCallback(() => {
    setUrl(undefined);
    setError(false);
    init();
  }, [init]);

  if (error) return <div>Something wrong</div>;
  if (!url) return <PdfLoaderDiv />;

  return (
    <ErrorBoundary>
      <CiqPdfViewer
        fileUrl={url}
        onExpiredUrl={onExpiredUrl}
        boundingBoxPossition={boundingBoxPossition}
      />
    </ErrorBoundary>
  );
}

export default CiqDocumentView;
