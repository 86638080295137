import { Select } from "antd";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import { forwardRef, useState, useImperativeHandle, useMemo } from "react";

const { Option } = Select;

const ResponsibleContractorCellEditor = forwardRef((props: any, ref) => {
  const { context, showSearch, stopEditing, source, projectId } = props;
  const [value, setValue] = useState(props.value);

  let scCompanies = context.projectParticipants.responsibleContractors;
  if (source && source === "material") {
    scCompanies = context.projectParticipants.materialTradePartners;
  }

  const dropDownOptions = useMemo(() => {
    return scCompanies.map((company: any) => {
      return (
        <Option
          key={company.vendor_id}
          value={company.vendor_id}
          label={company.subscription_vendor.name}
        >
          {company.subscription_vendor.name}
        </Option>
      );
    });
  }, [scCompanies]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!value) return null;
        return value;
      }
    };
  });

  return (
    <Select
      autoFocus
      defaultOpen
      placeholder={showSearch ? "Select / Search" : "Select"}
      allowClear
      showSearch={showSearch}
      virtual={false}
      notFoundContent={
        <SelectSearchNotFoundContent
          notFoundMsg={
            scCompanies?.length > 0
              ? "Company not found. To add, go to "
              : "No companies added to this project. To add, go to "
          }
          linkTitle="Project Companies"
          linkPath={`/project/${projectId}/settings/general/project-companies`}
        />
      }
      filterOption
      optionFilterProp="label"
      style={{ width: "100%" }}
      onChange={(event: any) => {
        setValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    >
      {dropDownOptions}
    </Select>
  );
});

export default ResponsibleContractorCellEditor;
