import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { Button, Form, Input, Select, message } from "antd";
import { QUERY_GET_VENDOR_BY_PK } from "services/graphQL/queries";
import { SUBSCRIPTION_PROJECT_ROLES } from "services/graphQL/subscriptions";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import {
  MUTATION_INSERT_POC_TO_COMPANY,
  MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION,
  MUTATION_UPDATE_COMPANY_POC
} from "services/graphQL/mutations";
import { ProjectContext } from "context/ProjectProvider";
import { ErrorMessages, Regex } from "../../../constants";

const { Option } = Select;

type Props = {
  setDrawerVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  companyInput: {
    venderId: string;
    typeId: number;
  };
};
function InviteCompanyProjectUser(props: Props) {
  const { setDrawerVisibility, companyInput } = props;

  const { projectId } = useParams() as any;
  const [form] = Form.useForm();

  const { gqlClientForProject } = useContext(ProjectContext);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isEmailReadOnly, setEmailReadOnly] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const { data: companyData } = useQuery(QUERY_GET_VENDOR_BY_PK, {
    variables: {
      id: companyInput.venderId
    },
    client: gqlClientForProject
  });

  const { data: projectSubscriptionRoles, loading: projectRolesloading } =
    useSubscription(SUBSCRIPTION_PROJECT_ROLES, {
      client: gqlClientForProject,
      shouldResubscribe: true,
      variables: {
        where: {
          type_id: { _eq: companyInput.typeId },
          key: { _nin: ["gc_foreman", "sc_foreman", "gc_sc_foreman"] }
        }
      }
    });

  const [insertPoc] = useMutation<any>(MUTATION_INSERT_POC_TO_COMPANY, {
    client: gqlClientForProject
  });

  const [updatePoc] = useMutation<any>(MUTATION_UPDATE_COMPANY_POC, {
    client: gqlClientForProject
  });

  const [addUserToProjectSubscription] = useMutation<any>(
    MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION,
    {
      client: gqlClientForProject
    }
  );

  const onSendInvite = async (values: any) => {
    setResponseLoading(true);
    const userMap: any = {
      external: true,
      company_id: companyInput.venderId,
      user_type_id: companyInput.typeId,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      project_role_id: values.project_role_id
    };
    if (values.phone) {
      userMap.phone = values.phone;
    }
    const isPocUser =
      companyData?.subscription_vendors_by_pk.trade_partner_pocs.some(
        (poc: any) => poc.email === userMap.email
      );

    if (!isPocUser && values.id) {
      const variables = {
        pk_columns: { id: values.id },
        _set: { email: userMap.email }
      };
      const updatePOCResponse = await updatePoc({
        variables
      });

      if (!updatePOCResponse.data) {
        message.error(ErrorMessages.CompanyUpdateFailedMsg);
        setResponseLoading(false);
        return;
      }
    } else if (!values.id) {
      const addPOCResponse = await insertPoc({
        variables: {
          objects: {
            email: userMap.email,
            first_name: userMap.first_name,
            last_name: userMap.last_name,
            phone: userMap.phone,
            vendor_id: userMap.company_id
          }
        }
      });
      if (!addPOCResponse.data) {
        message.error(ErrorMessages.CompanyUpdateFailedMsg);
        setResponseLoading(false);
        return;
      }
    }

    const addUserToProjectSubscriptionResponse =
      await addUserToProjectSubscription({
        variables: {
          users: [userMap],
          projectId
        }
      });
    setResponseLoading(false);
    if (addUserToProjectSubscriptionResponse.data) {
      message.success(ErrorMessages.CompanyInvitedSuccessMessage);
      form.resetFields();
      setDrawerVisibility(false); // close drawer
    }

    if (addUserToProjectSubscriptionResponse.errors) {
      message.error(ErrorMessages.CompanyInvitedFailureMessage);
      console.log(addUserToProjectSubscriptionResponse.errors[0].message);
    }
  };
  return (
    <Form
      preserve={false}
      form={form}
      scrollToFirstError
      layout="vertical"
      onFinish={onSendInvite}
    >
      <Form.Item label="Points of Contact">
        <Select
          placeholder="Select points of contact"
          allowClear
          optionLabelProp="label"
          onChange={(poc_id: any) => {
            const user =
              companyData?.subscription_vendors_by_pk?.trade_partner_pocs?.find(
                (p: any) => p.id === poc_id
              );
            if (poc_id === undefined) {
              setIsReadOnly(false);
              form.setFieldsValue({
                email: undefined,
                phone: undefined,
                first_name: undefined,
                last_name: undefined,
                id: undefined
              });
            } else {
              setIsReadOnly(true);
              if (user.email) {
                setEmailReadOnly(true);
              } else {
                setEmailReadOnly(false);
              }
              form.setFieldsValue({
                email: user.email || "",
                phone: user.phone || "",
                first_name: user.first_name || "",
                last_name: user.last_name || "",
                id: user.id
              });
            }
          }}
        >
          {companyData?.subscription_vendors_by_pk?.trade_partner_pocs?.map(
            (user: any) => (
              <Option key={user.id} value={user.id} label={user.email || ""}>
                <div>
                  {user.first_name} {user.last_name}
                </div>
                <div className="text-sm text-gray-500">{user.email}</div>
              </Option>
            )
          )}
        </Select>
      </Form.Item>
      <Form.Item
        name="first_name"
        label="First Name"
        rules={[
          {
            required: true,
            validateTrigger: "onSubmit",
            message: ErrorMessages.FirstName
          }
        ]}
      >
        <Input disabled={isReadOnly} />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[
          {
            required: true,
            validateTrigger: "onSubmit",
            message: ErrorMessages.LastName
          }
        ]}
      >
        <Input disabled={isReadOnly} />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            validateTrigger: "onSubmit",
            message: ErrorMessages.EmailRequired
          },
          {
            type: "email",
            validateTrigger: "onSubmit",
            message: ErrorMessages.EmailInvalid
          }
        ]}
      >
        <Input disabled={isEmailReadOnly} />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          {
            message: ErrorMessages.PhoneNumber,
            validateTrigger: "onSubmit",
            pattern: Regex.phoneNumber
          }
        ]}
      >
        <Input disabled={isReadOnly} />
      </Form.Item>
      <Form.Item
        name="project_role_id"
        label="Project Role"
        rules={[
          {
            required: true,
            validateTrigger: "onSubmit",
            message: ErrorMessages.ProjectRole,
            validator(_, value: string) {
              if (!value) {
                return Promise.reject();
              }

              return Promise.resolve();
            }
          }
        ]}
      >
        <Select
          loading={projectRolesloading}
          options={
            projectSubscriptionRoles
              ? projectSubscriptionRoles.project_role.map((role: any) => {
                  return {
                    label: role.name,
                    value: role.id
                  };
                })
              : []
          }
        />
      </Form.Item>
      <Form.Item name="id" hidden />
      <div className="flex justify-center space-x-2 pt-4">
        <Button loading={responseLoading} htmlType="submit">
          Invite to Collaborate
        </Button>
      </div>
    </Form>
  );
}
export default InviteCompanyProjectUser;
