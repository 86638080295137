export default function ClearIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.3333C5.39764 18.3333 1.66669 14.6023 1.66669 9.99999C1.66669 5.39761 5.39764 1.66666 10 1.66666C14.6024 1.66666 18.3334 5.39761 18.3334 9.99999C18.3334 14.6023 14.6024 18.3333 10 18.3333ZM10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 9.99999C16.6667 6.31809 13.6819 3.33332 10 3.33332C6.31812 3.33332 3.33335 6.31809 3.33335 9.99999C3.33335 13.6819 6.31812 16.6667 10 16.6667ZM10 8.82149L12.357 6.46446L13.5355 7.64296L11.1785 9.99999L13.5355 12.357L12.357 13.5355L10 11.1785L7.64299 13.5355L6.46449 12.357L8.82152 9.99999L6.46449 7.64296L7.64299 6.46446L10 8.82149Z"
        fill="#3B3B3B"
        fillOpacity="0.7"
      />
    </svg>
  );
}
