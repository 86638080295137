import { Tabs } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import "./module-settings.scss";
import { useMutation, useSubscription } from "@apollo/client";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useHistory, useParams } from "react-router";
import {
  SUBSCRIPTION_NEW_PROJECT_OFFSET_DEFAULT,
  SUBSCRIPTION_PROJECT_FEATURE_CONFIGURATION
} from "services/graphQL/subscriptions";
import {
  MUTATION_PROJECT_FEATURE_CONFIG,
  MUTATION_UPDATE_NEW_PROJECT_DEFAULT_OFFSET_PK
} from "services/graphQL/mutations";
import { getGQL } from "utils/utils";
import { DATEBLOCK_ACTIONS } from "services/graphQL/ciq-queries";
import { useQuerySubscription } from "hooks/common";
import {
  DateBlockInfoAction,
  DateBlockInfoType
} from "components/date-block/models";
import { SubscriptionContext } from "context/SubscriptionProvider";
import DemoTextIcon from "components/svg-icons/demo-icon";
import { ProjectPermissionEnum } from "../../../constants";
import { ThresholdType } from "./model";

import MaterialSettings from "./material-settings/material-settings";
import SubmittalSettings from "./submittal-settings/submittal-settings";
import ProjectBidPackageSettings from "./bid-package-settings/project-bid-package-settings";

function ModuleSettings() {
  const { gqlClientForProject, tokenContents } = useContext(ProjectContext);
  const history = useHistory();
  const { projectId, tab2Id } = useParams() as any;
  const [tabIndex, setTabIndex] = useState<string>(tab2Id || "submittals");

  const [dbInfoMessages, setDbInfoMessages] = useState({
    submittal: new Array<DateBlockInfoAction>(),
    material: new Array<DateBlockInfoAction>()
  });

  const { isFeatureFlagEnabled } = useContext(SubscriptionContext);

  const {
    columnHeaders: { submittalHeaders, materialHeaders }
  } = useContext(ProjectContext);

  const { data: newProjectOffsetData } = useSubscription(
    SUBSCRIPTION_NEW_PROJECT_OFFSET_DEFAULT,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const { data: featureConfigurationsData } = useSubscription<{
    project_feature_configurations: Array<ThresholdType>;
  }>(SUBSCRIPTION_PROJECT_FEATURE_CONFIGURATION, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const { query: qDateBlockActions, sub: sDateBlockActions } = useMemo(() => {
    return getGQL(DATEBLOCK_ACTIONS);
  }, []);
  const { data: dateBlockInfoFeatureData } = useQuerySubscription<{
    project_feature_configurations: Array<DateBlockInfoType>;
  }>(qDateBlockActions, sDateBlockActions, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  useEffect(() => {
    if (dateBlockInfoFeatureData) {
      const submittal =
        dateBlockInfoFeatureData.project_feature_configurations.find(
          (x) => x.feature_id === 1
        )?.date_block_action || [];
      const material =
        dateBlockInfoFeatureData.project_feature_configurations.find(
          (x) => x.feature_id === 2
        )?.date_block_action || [];
      setDbInfoMessages({
        submittal,
        material
      });
    }
  }, [dateBlockInfoFeatureData]);

  const [updateDefaultNewProjectOffsetMutation] = useMutation(
    MUTATION_UPDATE_NEW_PROJECT_DEFAULT_OFFSET_PK,
    {
      client: gqlClientForProject
    }
  );
  const [updateProjectFeatureConfigMutation] = useMutation(
    MUTATION_PROJECT_FEATURE_CONFIG,
    {
      client: gqlClientForProject
    }
  );

  const updateSettingsData = async (data: any, tabTitle: string) => {
    let response: any;
    if (tabTitle === "dateBlockDuration") {
      response = await updateDefaultNewProjectOffsetMutation({
        variables: data
      });
    }

    if (tabTitle === "riskThreshold") {
      response = await updateProjectFeatureConfigMutation({
        variables: data
      });
    }

    return response;
  };

  const tabItems = [
    {
      label: "Submittals",
      key: "submittals",
      children: (
        <SubmittalSettings
          projectId={projectId}
          submittalheader={submittalHeaders}
          submittalDateBlockActions={dbInfoMessages.submittal}
          newProjectOffsetData={newProjectOffsetData}
          featureConfigurationsData={featureConfigurationsData}
          updateSettingsData={updateSettingsData}
          disabled={isPermissionNotGrantted(
            ProjectPermissionEnum.EditProjectDetail,
            tokenContents?.role!
          )}
        />
      )
    },
    {
      label: "Materials",
      key: "materials",
      children: (
        <MaterialSettings
          materialheader={materialHeaders}
          materialDateBlockActions={dbInfoMessages.material}
          newProjectOffsetData={newProjectOffsetData}
          featureConfigurationsData={featureConfigurationsData}
          updateSettingsData={updateSettingsData}
          disabled={isPermissionNotGrantted(
            ProjectPermissionEnum.EditProjectDetail,
            tokenContents?.role!
          )}
        />
      )
    },
    ...(isFeatureFlagEnabled("BUYOUT")
      ? [
          {
            label: (
              <span>
                <DemoTextIcon /> Bid Packages
              </span>
            ),
            key: "bid-packages",
            children: <ProjectBidPackageSettings />
          }
        ]
      : [])
  ];

  return (
    <div className="w-full py-1 h-full">
      <Tabs
        onChange={(activeKey) => {
          history.push(
            `/project/${projectId}/settings/modules/${activeKey.toString()}`
          );
          setTabIndex(activeKey);
        }}
        activeKey={tabIndex}
        items={tabItems}
        destroyInactiveTabPane
      />
    </div>
  );
}
export default ModuleSettings;
