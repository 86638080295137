import { Select } from "antd";
import SelectNotFoundContent from "components/widgets/select-notfound-content";
import { AcceptanceStatus } from "constants/index";
import { forwardRef, useState, useImperativeHandle } from "react";

const { Option } = Select;

export const MaterialGCRepresentativeSelectCellEditor = forwardRef(
  (props: any, ref) => {
    const { stopEditing, value, context, data } = props;
    const [selectValue, setSelectValue] = useState(value);

    const users = context.projectParticipants.gcReviewers.filter(
      (user: any) => {
        if (data.gc_representative === user.id) {
          return true;
        }
        return user.status_id !== AcceptanceStatus.DEACTIVATED;
      }
    );

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (!selectValue) return null;
          return selectValue;
        }
      };
    });

    return (
      <Select
        open
        allowClear
        optionFilterProp="label"
        virtual={false}
        style={{ width: "100%" }}
        showSearch
        filterOption={(input, option: any) => {
          const user: any = users.find((u: any) => u.id === option.key);
          const inputMes = input.toString().toLowerCase();
          const userData = [
            user.first_name.toLowerCase(),
            user.last_name.toLowerCase()
          ];
          return userData.some((text) => text.includes(inputMes));
        }}
        onChange={(event: any) => {
          setSelectValue(event);
          setTimeout(() => {
            stopEditing();
          }, 200);
        }}
        notFoundContent={
          <SelectNotFoundContent
            notFoundMsg="User not found. Go to Project Settings to add users"
            onAddClick={() => {}}
            showAddBtn={false}
          />
        }
      >
        {users.map((user: any) => {
          if (user.status_id === AcceptanceStatus.DEACTIVATED) {
            return "";
          }

          return (
            <Option key={user.id} value={user.id}>
              <div>
                {user.first_name} {user.last_name}
              </div>
            </Option>
          );
        })}
      </Select>
    );
  }
);
