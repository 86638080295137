import { ReactElement } from "react";
import { Moment } from "moment";
import { SubmittalDiffV6 } from "../compare-schedule-2/SubmittalDiffV6";

import {
  computeDecorators,
  computeXPositionsForDatesv6
} from "../compare-schedule-2/TemporalLayout";
import { computeTimelineDataMaterial } from "./utils";
import { TActivitiesV3, TLinkedMaterial } from "./models";

function MaterialsGroupDiffV6(props: {
  material: TLinkedMaterial;
  activityComparison: TActivitiesV3;
  projectId: string;
}): ReactElement {
  const { material, activityComparison, projectId } = props;

  const dataForBefore = computeTimelineDataMaterial(
    "before",
    material,
    activityComparison,
    projectId
  );
  const dataForAfter = computeTimelineDataMaterial(
    "after",
    material,
    activityComparison,
    projectId
  );

  const beforeFinalDeadlineMilestone =
    dataForBefore.timelineData.primaryElement.milestones.final_deadline;

  const afterFinalDeadlineMilestone =
    dataForAfter.timelineData.primaryElement.milestones.final_deadline;

  const oldMaterialMilestoneDates: Moment[] = [];

  const newMaterialMilestoneDates: Moment[] = [];

  // todo: consider only the visible milestones
  const xLocationsByDate = computeXPositionsForDatesv6(
    [
      ...dataForBefore.allDates,
      ...dataForAfter.allDates,
      ...oldMaterialMilestoneDates,
      ...newMaterialMilestoneDates
    ],
    dataForAfter.timelineData.today.date
  );

  const width = 0;

  const beforeLastMaterialROJ = null;
  const afterLastMaterialROJ = null;

  const { arrow } = computeDecorators(
    xLocationsByDate,
    beforeLastMaterialROJ,
    afterLastMaterialROJ,
    beforeFinalDeadlineMilestone
      ? beforeFinalDeadlineMilestone.normalizedDate
      : null,
    afterFinalDeadlineMilestone
      ? afterFinalDeadlineMilestone.normalizedDate
      : null
  );

  return (
    <div className="overflow-x-auto">
      <span className="uppercase text-xs tracking-wider sticky left-0">
        Before
      </span>
      <div>
        <span className="bg-gray-50 px-4 pt-4 inline-block">
          <SubmittalDiffV6
            xLocationsByDate={xLocationsByDate}
            primaryElementName={dataForBefore.timelineData.primaryElement.label}
            linkedMaterials={[]}
            milestones={Object.values(
              dataForBefore.timelineData.primaryElement.milestones
            )}
            todayDate={dataForBefore.timelineData.today.date}
            siCalculations={dataForBefore.timelineData.siCalculations}
            width={width}
            projectId={projectId}
            isCompletedWorkflow={material.workflow_status === "COMPLETED"}
          />
        </span>
      </div>
      <span className="uppercase text-xs tracking-wider sticky left-0">
        After
      </span>
      <div>
        <span className="bg-blue-50 px-4 pt-4 inline-block">
          <SubmittalDiffV6
            xLocationsByDate={xLocationsByDate}
            primaryElementName={dataForBefore.timelineData.primaryElement.label}
            linkedMaterials={[]}
            milestones={Object.values(
              dataForAfter.timelineData.primaryElement.milestones
            )}
            todayDate={dataForAfter.timelineData.today.date}
            siCalculations={dataForAfter.timelineData.siCalculations}
            width={width}
            arrow={arrow}
            projectId={projectId}
            isCompletedWorkflow={material.workflow_status === "COMPLETED"}
          />
        </span>
      </div>
    </div>
  );
}

export default MaterialsGroupDiffV6;
