import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormRule,
  Input,
  InputNumber,
  message,
  Popover,
  Radio,
  Row,
  Select
} from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import {
  AcceptanceStatus,
  ESubmittalMgmtModes,
  ErrorMessages
} from "constants/index";
import { SubscriptionContext } from "context/SubscriptionProvider";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { getUser } from "services/auth";
import {
  MUTATION_INSERT_ONE_PROJECT,
  MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION,
  MUTATION_UPDATE_PROJECT_BY_ID
} from "services/graphQL/mutations";
import {
  QUERY_GET_TEMPLATE_PROJECTS,
  QUERY_GET_TIMEZONES,
  QUERY_PROJECT_AGG
} from "services/graphQL/queries";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";
import moment from "moment-timezone";

import "./create-project.scss";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const todayDate = DateUtils.dateTimeObj();

function CreateProjectPage() {
  const [form] = Form.useForm();
  const { subscriptionId } = useContext(SubscriptionContext);
  const [loader, setLoader] = useState(false);
  const [showProjectSelector, setShowProjectSelector] = useState(false);
  const [projectTemplatesData, setProjectTemplatesData] = useState<any>(null);

  const [timezoneData, setTimeZoneData] =
    useState<
      Array<{ id: string; timezone_id: string; name: string; gmt: string }>
    >();
  const history = useHistory();

  const { data: timezones } = useQuery(QUERY_GET_TIMEZONES);

  const [insertProject] = useMutation<any>(MUTATION_INSERT_ONE_PROJECT);

  const [updateProjectById] = useMutation<any>(MUTATION_UPDATE_PROJECT_BY_ID);

  const [addUserToProject] = useMutation<any>(
    MUTATION_INSERT_USERS_TO_PROJECT_SUBSCRIPTION
  );

  const [checkForExistingProject] = useLazyQuery(QUERY_PROJECT_AGG, {
    fetchPolicy: "no-cache"
  });

  const newlyCreatedProjectId = useRef();

  const { data: projectListQueryData, error: projectListQueryError } = useQuery(
    QUERY_GET_TEMPLATE_PROJECTS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          status_id: { _neq: AcceptanceStatus.DEACTIVATED }
        }
      }
    }
  );

  useEffect(() => {
    if (projectListQueryError) {
      message.error("An error occured");
      console.log(projectListQueryError);
    }
  }, [projectListQueryError]);

  useEffect(() => {
    setProjectTemplatesData(projectListQueryData?.project_list_func);
  }, [projectListQueryData]);

  useEffect(() => {
    if (timezones?.timezones) {
      const zones = (timezones?.timezones as Array<any>).map((zone) => ({
        id: zone.id,
        timezone_id: zone.timezone_id,
        name: zone.name,
        gmt: moment.tz(todayDate, zone.timezone_id).format("Z"),
        offset: Number(
          moment.tz(todayDate, zone.timezone_id).format("Z")?.replace(":", ".")
        )
      }));

      setTimeZoneData(zones.sort((a, b) => (a.offset > b.offset ? 1 : -1)));
    }
  }, [timezones]);

  const ProjectTemplates: any = useMemo(() => {
    if (projectTemplatesData && projectTemplatesData?.length > 0) {
      const projects = projectTemplatesData
        .map((project: any) => {
          return {
            label: project.name,
            value: project.id
          };
        })
        .sort((a: any, b: any) => {
          if (a.label < b.label) {
            return -1;
          }
          return a.label > b.label ? 1 : 0;
        });

      return projects;
    }
    return [];
  }, [projectTemplatesData]);

  const updateProjectDetails = async (projectid: any, projectValues: any) => {
    let updateResponse = null;
    try {
      updateResponse = await updateProjectById({
        variables: {
          projectId: projectid,
          projectValues: {
            name: projectValues.name,
            details: projectValues.details,
            status_id: AcceptanceStatus.ACTIVE
          }
        }
      });
      if (updateResponse && updateResponse.data) {
        return updateResponse;
      }
      return null;
    } catch (ex: any) {
      message.error(ex);
      return null;
    }
  };

  const onFinish = async (projectValues: any) => {
    const payload = {
      ...projectValues
    };

    if (projectValues.start_date) {
      payload.start_date = DateUtils.format(projectValues.start_date);
    }

    if (projectValues.end_date) {
      payload.end_date = DateUtils.format(projectValues.end_date);
    }

    setLoader(true);
    const createProjectResponse = await insertProject({
      variables: {
        newProject: payload
      }
    });

    if (createProjectResponse && createProjectResponse.errors) {
      const errorMsg = createProjectResponse.errors[0]?.message || "";
      if (
        errorMsg.includes("Uniqueness violation") &&
        errorMsg.includes("idx_project_number")
      ) {
        message.error(ErrorMessages.ProjectNumberMsg);
      } else if (
        errorMsg.includes("Uniqueness violation") &&
        errorMsg.includes("idx_project_name")
      ) {
        message.error(ErrorMessages.ProjectNameExistsMsg);
      } else {
        message.error(createProjectResponse.errors[0].message);
      }
      setLoader(false);
      return null;
    }

    newlyCreatedProjectId.current =
      createProjectResponse.data.insert_project_one.id;

    const loggedInUser = getUser();

    const addUserToProjectResponse = await addUserToProject({
      variables: {
        projectId: createProjectResponse.data.insert_project_one.id,
        triggerMail: false,
        users: [
          {
            email: loggedInUser.email,
            first_name: loggedInUser["first-name"],
            last_name: loggedInUser["last-name"],
            project_role_id: 1,
            user_type_id: 1
          }
        ]
      }
    });

    if (addUserToProjectResponse && addUserToProjectResponse.errors) {
      message.error(addUserToProjectResponse.errors[0].message);
      setLoader(false);
      return null;
    }

    const updateResponse: any = await updateProjectDetails(
      createProjectResponse.data.insert_project_one.id,
      payload
    );

    if (updateResponse.data) {
      setTimeout(() => {
        history.push(
          `/project/${newlyCreatedProjectId.current}/settings/general/project-users`
        );
      }, 0);
    } else {
      message.error(updateResponse.errors[0].message);
    }
    setLoader(false);

    return null;
  };

  const projectFormRules: { [key: string]: FormRule[] } = useMemo(() => {
    return {
      name: [
        {
          required: true,
          message: ErrorMessages.ProjectNameMsg
        },
        () => ({
          async validator(_, value) {
            if (value && value?.length > 0) {
              const response: any = await checkForExistingProject({
                variables: {
                  where: {
                    name: { _eq: value || "" },
                    owner_subscription_id: {
                      _eq: subscriptionId
                    }
                  }
                }
              });

              const projectCount =
                response?.data?.project_aggregate?.aggregate?.count ?? 0;
              if (projectCount > 0) {
                return Promise.reject(
                  new Error(ErrorMessages.ProjectNameExistsMsg)
                );
              }
            }

            return Promise.resolve();
          }
        })
      ],
      details: [
        {
          required: true,
          message: ErrorMessages.ProjectDetailMsg
        }
      ],
      number: [
        {
          required: true,
          message: ErrorMessages.ProjectNumberErrMsg
        },
        () => ({
          async validator(_, value) {
            if (value && value?.length > 0) {
              const response: any = await checkForExistingProject({
                variables: {
                  where: {
                    number: { _eq: value || "" },
                    owner_subscription_id: {
                      _eq: subscriptionId
                    }
                  }
                }
              });
              const projectCount =
                response?.data?.project_aggregate?.aggregate?.count ?? 0;
              if (projectCount) {
                return Promise.reject(
                  new Error(ErrorMessages.ProjectNumberMsg)
                );
              }
            }
            return Promise.resolve();
          }
        })
      ],
      mode: [
        {
          required: true,
          message: ErrorMessages.SelectIntegrationType
        }
      ]
    };
  }, [checkForExistingProject, subscriptionId]);

  const managementModePopover = (
    <div className="text-xs">
      <div className="flex">
        Select <div className="px-1 font-semibold">workflow mode</div> if
        submittal approval workflow will take place in ConstructivIQ.
      </div>
      <div className="flex">
        Select <div className="px-1 font-semibold">integration mode</div> if
        submittal approval workflow will take place outside ConstructivIQ.
      </div>
    </div>
  );

  const showProjectTemplateInfo = (
    <span className="text-xs">
      Select the option below to copy the Material Log from an existing project.
    </span>
  );

  return (
    <div className="create-project-wrapper">
      <div>
        <h2 className="text-center uppercase text-lg font-semibold py-3 m-0 p-0">
          Create New Project
        </h2>
      </div>
      <div className="w-full bg-white py-4">
        <div className="max-w-md mx-auto">
          <Form
            onFinish={onFinish}
            form={form}
            scrollToFirstError
            layout="vertical"
            size="large"
            initialValues={{
              mode: ESubmittalMgmtModes.INTEGRATION_MODE
            }}
            validateTrigger={["onSubmit", "onBlur"]}
          >
            <FormItem
              name="name"
              className="w-full"
              label="Project name"
              rules={projectFormRules.name}
            >
              <Input type="text" />
            </FormItem>
            <FormItem
              name="details"
              label="Project details"
              rules={projectFormRules.details}
            >
              <TextArea
                autoSize={{ maxRows: 3, minRows: 3 }}
                className="text-base"
              />
            </FormItem>
            <FormItem
              name="number"
              label="Project number"
              rules={projectFormRules.number}
            >
              <Input type="text" />
            </FormItem>
            <FormItem name="client" label="Client">
              <Input type="text" />
            </FormItem>
            <FormItem
              name="timezone_id"
              label="Select Time Zone"
              initialValue={3}
              required
            >
              <Select
                placeholder="Select Time Zone"
                loading={!timezoneData}
                disabled={!timezoneData}
                className=" text-sm"
              >
                {timezoneData?.map((zone) => (
                  <Option key={zone.timezone_id} value={zone.id}>
                    <span className="font-semibold text-[#3b3b3b]">{`(GMT${zone.gmt})`}</span>
                    <span className="px-1">-</span>
                    <span>{zone.name}</span>
                  </Option>
                ))}
              </Select>
            </FormItem>
            <Row gutter={12}>
              <Col span={12}>
                <FormItem name="start_date" label="Start date">
                  <DatePicker
                    className="w-full date-picker-with-icon"
                    format={DATE_FORMAT_MMDDYYYY}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem name="end_date" label="End date">
                  <DatePicker
                    className="w-full date-picker-with-icon"
                    format={DATE_FORMAT_MMDDYYYY}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem name="value" label="Project value">
              <InputNumber
                className="w-full"
                addonBefore="$"
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </FormItem>
            <FormItem name="address" label="Address">
              <TextArea
                autoSize={{ maxRows: 3, minRows: 3 }}
                className="text-base"
              />
            </FormItem>
            <hr className="section-separator absolute left-0 right-0" />
            <FormItem
              name="mode"
              label={
                <span className="font-semibold mt-4 mb-1">
                  SUBMITTAL MANAGEMENT MODE
                  <Popover content={managementModePopover}>
                    <InfoCircleOutlined className="px-1" />
                  </Popover>
                </span>
              }
              className="mt-5"
            >
              <Radio.Group className="grid grid-cols-2 p-2.5">
                <Radio value={ESubmittalMgmtModes.WORKFLOW_MODE}>
                  <span className="form-field-heading-2">WORKFLOW MODE</span>
                </Radio>
                <Radio value={ESubmittalMgmtModes.INTEGRATION_MODE}>
                  <span className="form-field-heading-2">INTEGRATION MODE</span>
                </Radio>
              </Radio.Group>
            </FormItem>

            <FormItem
              name="use_project_template"
              valuePropName="checked"
              label={
                <span className="font-semibold mb-1">
                  Material log source
                  <Popover content={showProjectTemplateInfo}>
                    <InfoCircleOutlined className="px-1" />
                  </Popover>
                </span>
              }
            >
              <Checkbox
                className="mt-2 mb-5"
                onChange={(e: any) => {
                  setShowProjectSelector(!!e.target.checked);
                  return e.target.checked;
                }}
              >
                <span className="font-normal mt-4 mb-4 project-template-checkbox-label">
                  Bootstrap Material Log From Existing Project.
                </span>
              </Checkbox>
            </FormItem>
            {showProjectSelector && (
              <FormItem
                name="template_project_id"
                label="Source Project"
                className="source-project-label mt-[-20px]"
                rules={[
                  {
                    required: showProjectSelector,
                    message: ErrorMessages.SelectProjectForCopyMsg
                  }
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select an existing project"
                  optionFilterProp="label"
                  options={ProjectTemplates}
                />
              </FormItem>
            )}

            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loader}
              disabled={loader}
              className="mt-3"
            >
              Create Project
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default CreateProjectPage;
