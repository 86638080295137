import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Spin, Tabs } from "antd";
import ErrorBoundary from "components/error-boundary";
import { ProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { SubmittalLog } from "models/submittal-log";
import { useQuerySubscription } from "hooks/common";
import {
  getGQL,
  isProjectInIntegrationMode,
  updateSubmittalParticipantsForINTG
} from "utils/utils";
import { SUBMITTAL_BY_PK } from "services/graphQL/ciq-queries";
import ASubmittalDetailPage from "./a-submittal-detail/a-submittal-detail";

function ASubmittalPage() {
  const history = useHistory();
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get("tab");
  const [tabIndex, setTabIndex] = useState<string>(tab || "1");

  const { gqlClientForProject, projectDetails } = useContext(ProjectContext);

  const { projectId, submittalId } = useParams() as any;
  const [updatedData, setUpdatedData] = useState<SubmittalLog | null>(null);

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const { query: qSubmittalByPk, sub: sSubmittalByPk } = useMemo(() => {
    return getGQL(SUBMITTAL_BY_PK);
  }, []);
  const { data: dataSubmittalByPk } = useQuerySubscription(
    qSubmittalByPk,
    sSubmittalByPk,
    {
      variables: { id: submittalId },
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  useEffect(() => {
    if (dataSubmittalByPk && dataSubmittalByPk.submittals_by_pk) {
      let submittalDetails = dataSubmittalByPk?.submittals_by_pk;
      try {
        if (isIntegrationMode) {
          submittalDetails =
            updateSubmittalParticipantsForINTG(submittalDetails);
        }
      } catch (ex: any) {
        console.log("failed to update submittal users: ", ex);
      }

      setUpdatedData(submittalDetails);
    }
  }, [dataSubmittalByPk, isIntegrationMode]);

  const tabItems = [
    {
      label: "Submittal Details",
      key: "1",
      children: (
        <div className="submittal-details">
          {updatedData ? (
            <ErrorBoundary>
              <ASubmittalDetailPage submittalData={updatedData} />
            </ErrorBoundary>
          ) : (
            <div className="min-h-[80vh] flex items-center justify-center">
              <Spin size="large" />
            </div>
          )}
        </div>
      )
    }
  ];

  return (
    <div className="w-full px-5 pb-6">
      <div className="w-full bg-transparent border-solid border-0 border-b-2 border-[#00000005] grid grid-cols-12 items-center h-[40px]">
        <div className="col-span-2 flex items-center justify-start">
          <Button
            icon={<ArrowLeftOutlined />}
            className="text-one text-black !bg-transparent !border-0 px-0 !shadow-none"
            onClick={() => {
              history.push(`/project/${projectId}/submittals`);
            }}
          >
            All Submittals
          </Button>
        </div>
      </div>
      <div className="relative">
        <Tabs
          onChange={(activeKey: any) => {
            setTabIndex(activeKey.toString());
            history.replace(`/project/${projectId}/submittals/${submittalId}`);
          }}
          activeKey={tabIndex}
          items={tabItems}
          className="submittal-tabs"
        />
      </div>
    </div>
  );
}
export default ASubmittalPage;
