import {
  GET_COMMENTS,
  MATERIAL_LIST,
  SUBMITTAL_ATTACHMENTS
} from "services/graphQL/ciq-queries";
import { getGQL } from "utils/utils";
import { useMemo } from "react";
import { useQuerySubscription } from "./common";
import { useCIQQuery } from "./ciq-gql-hooks";

export const useMaterialList = (params: {
  gqlClientForProject: any;
  skipSubscribe?: any;
}): { data: any; error: any; loading: boolean } => {
  const { gqlClientForProject, skipSubscribe = false } = params;

  const { query: qMaterialList, sub: sMaterialList } = getGQL(MATERIAL_LIST);
  const {
    data: materialListData,
    error,
    loading
  } = useQuerySubscription(qMaterialList, sMaterialList, {
    client: gqlClientForProject,
    skip: skipSubscribe
  });

  return {
    data: materialListData?.material_list_func,
    error,
    loading
  };
};

export const useMaterialAttachments = (params: {
  materialId: string;
  gqlClientForProject: any;
}): {
  data:
    | {
        attachments: any[];
        commentsData: {
          comment: Array<any>;
        };
      }
    | undefined;
  error: any;
  refetchCommentData: Function;
} => {
  const { materialId, gqlClientForProject } = params;

  const { data: commentsData, refetch: refetchCommentData } = useCIQQuery<{
    comment: Array<any>;
  }>(GET_COMMENTS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: {
      where: { material_id: { _eq: materialId } }
    }
  });

  // SUBMITTAL_ATTACHMENTS ignore the name, query is same for submittal and material attachments
  const { data, error } = useCIQQuery(SUBMITTAL_ATTACHMENTS, {
    variables: {
      where: {
        material_id: { _eq: materialId }
      }
    },
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const mergedAttachments = useMemo(() => {
    if (data && commentsData) {
      // console.log({ data, commentsData });
      const allCommentAttachments: any = [];
      commentsData.comment.forEach((comment: any) => {
        const attachmentInfo = comment.attachments?.map((attach: any) => {
          return {
            id: attach.id,
            file_name: attach.file_name,
            created_at: comment.created_at,
            created_by_user: comment.created_by_user,
            blob_key: attach.blob_key,
            file_type: attach.file_type
          };
        });
        allCommentAttachments.push(...attachmentInfo);
      });
      return {
        attachments: [...allCommentAttachments, ...data.attachments],
        commentsData
      };
    }
    return undefined;
  }, [commentsData, data]);

  return { data: mergedAttachments, error, refetchCommentData };
};
