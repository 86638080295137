import { Redirect, Route, RouteProps } from "react-router-dom";
import { getAccessToken } from "services/auth";

interface PrivateRouteProps extends RouteProps {}

function PrivateRoute({ ...rest }: PrivateRouteProps) {
  const auth = getAccessToken();
  if (auth == null) {
    let redirectUrl = window.location.pathname;
    if (window.location.hash) {
      redirectUrl = window.location.hash?.substr(1);
    }
    return <Redirect to={`/?redirectUrl=${redirectUrl}`} />;
  }
  return <Route {...rest} />;
}

export default PrivateRoute;
