/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Avatar } from "antd";
import "./user-avatar.css";
// const SIZES = { small: "22", default: "34" };

type UserAvatarProps = {
  url: string;
  // email: string;
  fullName: string;
  isRect: boolean;
  // size: string;
};
function UserAvatar({ url, fullName, isRect }: UserAvatarProps) {
  const shape = isRect ? "square" : "circle";
  const avatarWidth = isRect ? "100px" : "30px";
  const avatarHeight = isRect ? "100px" : "30px";
  // const _size = size || "default";
  const letterSplit = (fullName && fullName.split(" ")) || ["", ""];
  let letters = letterSplit.map((word: string) => word[0]).join("");
  if (letters.length > 3) {
    letters = letters.slice(0, 3);
  }
  // if (!url && email) {
  //   url = `https://www.gravatar.com/avatar/${email}?d=robohash`;
  // }

  // const heightWidth = SIZES[_size as keyof typeof SIZES] || "34";

  return (
    <div className="avator">
      {!url && (
        <Avatar
          className="flex items-center justify-center"
          shape={shape}
          style={{
            width: avatarWidth,
            height: avatarHeight,
            textTransform: "uppercase"
          }}
        >
          {letters}
        </Avatar>
      )}
      {url && <img src={url} alt="Profile" height={30} width={30} />}
    </div>
  );
}

export default UserAvatar;
