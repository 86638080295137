import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";

export type FilterProps = {
  onSearch: any;
  stats: string;
};

function ASubmittalListFilterComponent(props: FilterProps) {
  const { onSearch, stats } = props;

  return (
    <ActionBar>
      <div className="flex space-x-10 uppercase">
        <span
          style={{
            fontWeight: 600
          }}
        >
          {stats}&nbsp;&nbsp;
        </span>
      </div>
      <div className="flex space-x-2">
        <div className="flex h-fit">
          <SearchInput placeholder="Search Submittals" onChange={onSearch} />
        </div>
      </div>
    </ActionBar>
  );
}

export default ASubmittalListFilterComponent;
