import { Modal } from "antd";

function SideModelPanel({
  title,
  open,
  onCancel,
  children
}: {
  title: string;
  open: boolean;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  children: any;
}) {
  return (
    <Modal
      className="custom-drawer"
      title={title}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={open}
      onCancel={onCancel}
      destroyOnClose
    >
      {children}
    </Modal>
  );
}

export default SideModelPanel;
