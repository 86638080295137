import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import CiqAgGridUtil from "admin-app/components/ag-grid-util";
import { AUDIT_PROJECT_FEATURE_EVENTS } from "admin-app/services/queries";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, message } from "antd";
import SearchInput from "components/search-input";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { DATE_FORMAT_MMDDYYYY_HHMM_Z, DateUtils } from "utils/dateutils";

type TAuditType = {
  audit_project_feature_events: Array<any>;
  audit_project_feature_events_aggregate: { aggregate: { count: number } };
};

function AuditProjectFeatureEvents() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [auditData, setAuditData] = useState<TAuditType>();
  const history = useHistory();
  const location = useLocation();
  const {
    userId,
    projectId,
    featureId,
    name,
    organization,
    subscription,
    featureName
  }: any = location.state;

  const [getAuditData] = useLazyQuery<TAuditType>(
    AUDIT_PROJECT_FEATURE_EVENTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        project_id: projectId,
        created_by: userId,
        feature_id: featureId
      }
    }
  );
  useEffect(() => {
    if (projectId && userId) {
      getAuditData().then((res) => {
        if (res.data) {
          setAuditData(res.data);
        }
        if (res.error) {
          message.error(res.error.message);
        }
      });
    }
  }, [getAuditData, projectId, userId]);

  const columnDefsUsers: ColDef[] = useMemo<ColDef[]>(
    () => [
      {
        colId: "event_description",
        field: "event_log.event.description",
        headerName: "Event"
      },
      { colId: "field", field: "field" },
      {
        colId: "created_at",
        field: "created_at",
        headerName: "CREATED ON",
        valueGetter: ({ data }) => {
          return DateUtils.format(
            data?.created_at,
            DATE_FORMAT_MMDDYYYY_HHMM_Z
          );
        }
      }
    ],
    []
  );

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: []
    };
  }, []);

  return (
    <div className="h-[calc(100vh-130px)] px-3 p-1">
      <div className="flex w-full justify-between">
        <Button
          icon={<ArrowLeftOutlined />}
          className="!bg-transparent !border-0 !shadow-none uppercase font-medium items-center p-0"
          onClick={() => {
            history.goBack();
          }}
        >
          {name}
        </Button>
        <div className="p-1 flex uppercase items-center space-x-1">
          <div>organization :</div>
          <div className="font-medium ">{organization}</div>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="py-1 flex uppercase items-center space-x-1">
          <div>Subscripotion :</div>
          <div className="font-medium ">{subscription}</div>
        </div>
        <div className="uppercase ">{featureName} Events</div>
        <SearchInput
          placeholder="Search"
          onChange={(text) => {
            gridRef.current?.api?.setQuickFilter(text);
          }}
        />
      </div>
      <CiqAgGridUtil
        gridRef={gridRef}
        rowData={auditData?.audit_project_feature_events}
        columnDefs={columnDefsUsers}
        defaultColDef={defaultColDef}
      />
    </div>
  );
}
export default AuditProjectFeatureEvents;
