import classnames from "classnames";

type ActionBarProps = {
  children: React.ReactNode[] | React.ReactNode;
  noXPadding?: boolean;
  noYPadding?: boolean;
};

function ActionBar(props: ActionBarProps) {
  const { children, noXPadding = false, noYPadding = false } = props;

  const className = classnames("flex", "justify-between", "items-center", {
    "px-2": !noXPadding,
    "py-2": !noYPadding
  });

  return <div className={className}>{children}</div>;
}

export default ActionBar;
