import Milestone from "./components/milestone";
import "./index.scss";

function WorkflowTemplateBulkEditor(props: {
  disabled: boolean;
  milestonesState: any;
  floatState: any;
  entity: "submittal" | "material";
}) {
  const { disabled, milestonesState, floatState, entity } = props;

  const [float, setFloat] = floatState;
  const [milestones, setMilestones] = milestonesState;

  const onMilestoneValueChange = (data: any) => {
    const clonedMilestoneState: any = [...milestones];
    clonedMilestoneState[data.milestone_index] = {
      ...milestones[data.milestone_index],
      value: data.value
    };
    setMilestones(clonedMilestoneState);
  };

  return (
    <div className="w-full workflow-template-bulk-editor overflow-x-auto">
      <div className="flex w-full">
        {milestones.map((t: any, index: number) => (
          <Milestone
            allMilestones={milestones}
            onMilestoneValueChange={onMilestoneValueChange}
            key={t.id}
            index={index}
            milestone={t}
            disable={disabled}
            floatState={{ float, setFloat }}
            entity={entity}
          />
        ))}
      </div>
    </div>
  );
}

export default WorkflowTemplateBulkEditor;
