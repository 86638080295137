import { useMutation, useSubscription } from "@apollo/client";
import { Button, Form, Input, message, Modal, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { AcceptanceStatus, ErrorMessages } from "constants/index";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getSubscriptionId } from "services/auth";
import {
  MUTATION_CREATE_DISTRIBUTION_GROUP,
  MUTATION_INSERT_NEW_USER_TO_DISTRIBUTION_GROUP,
  MUTATION_REMOVE_USER_FROM_DISTRIBUTION_GROUP,
  MUTATION_UPDATE_DISTRIBUTION_GROUP
} from "services/graphQL/mutations";
import {
  SUBSCRIPTION_ACTIVE_PROJECT_USERS,
  SUBSCRIPTION_DISTRIBUTION_GROUP_LIST
} from "services/graphQL/subscriptions";
import dlTagRenderer from "components/dl-tag-renderer/dl-tag-renderer";

function CreateDLGroup(props: {
  selectedGroupId: any;
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  modelTitle: string;
}) {
  const [form] = Form.useForm();
  const subscriptionId = getSubscriptionId();
  const { selectedGroupId, setDrawerOpen, showDrawerOpen, modelTitle } = props;
  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);
  const { projectId } = useParams() as any;
  const { Option } = Select;
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const [selectedGrouName, setSelectedGroupName] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  const { data: distributionGroupData } = useSubscription(
    SUBSCRIPTION_DISTRIBUTION_GROUP_LIST,
    {
      shouldResubscribe: true,
      client: gqlClientForProject,
      variables: {
        where: {
          subscription: { project_users: { project_id: { _eq: projectId } } }
        }
      }
    }
  );

  const { data: usersData, loading } = useSubscription(
    SUBSCRIPTION_ACTIVE_PROJECT_USERS,
    {
      shouldResubscribe: true,
      client: gqlClientForProject,
      variables: {
        where: {
          project_id: { _eq: projectId },
          status_id: { _neq: 1 },
          subscription_id: { _eq: subscriptionId }
        }
      }
    }
  );

  const [createDistibutionGroup] = useMutation<any>(
    MUTATION_CREATE_DISTRIBUTION_GROUP,
    {
      client: gqlClientForProject
    }
  );

  const [insertUserToDistibutionGroup] = useMutation<any>(
    MUTATION_INSERT_NEW_USER_TO_DISTRIBUTION_GROUP,
    {
      client: gqlClientForProject
    }
  );

  const [removeUserFromDistibutionGroup] = useMutation<any>(
    MUTATION_REMOVE_USER_FROM_DISTRIBUTION_GROUP,
    {
      client: gqlClientForProject
    }
  );

  const [updateDistibutionGroup] = useMutation<any>(
    MUTATION_UPDATE_DISTRIBUTION_GROUP,
    {
      client: gqlClientForProject
    }
  );

  useEffect(() => {
    if (selectedGroupId && distributionGroupData) {
      const groups: any = distributionGroupData?.distribution_group?.filter(
        (grp: any) => grp.id === selectedGroupId
      );
      if (groups.length > 0) {
        const userArr: any = [];
        groups[0].distribution_group_user?.forEach((item: any) => {
          userArr.push(item?.user?.id || "");
        });

        const filteredArr = userArr.filter((selectedUsrId: any) => {
          return usersData?.project_users.find((user: any) => {
            return user?.user?.id === selectedUsrId;
          });
        });

        setSelectedUsers(filteredArr);
        setSelectedGroupName(groups[0]?.name);
        form.setFieldsValue({
          name: groups[0]?.name,
          users: filteredArr
        });
      }
    } else {
      form.setFieldsValue({
        users: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionGroupData, selectedGroupId, usersData]);

  const onCreate = async () => {
    setLoading(true);
    if (selectedGroupId) {
      const usersArr = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedUsers.length; index++) {
        usersArr.push({ user_id: selectedUsers[index] });
      }

      const groups = distributionGroupData?.distribution_group.filter(
        (grp: any) => grp.id === selectedGroupId
      );

      if (groups.length > 0) {
        const getUpdateMutationsPromise = async () => {
          const promises: any = [];
          const users: any = groups[0].distribution_group_user;
          // Removing existing user from DL
          const deletedArr = users.filter((olduser: any) => {
            return !selectedUsers.find((userid: string) => {
              return olduser?.user?.id === userid;
            });
          });

          if (deletedArr.length > 0) {
            const removeUsers: any = [];
            deletedArr.map((user: any) => removeUsers.push(user?.user?.id));
            const removeUserFromDistibutionGroupResponse =
              await removeUserFromDistibutionGroup({
                variables: {
                  where: {
                    dl_id: { _eq: selectedGroupId },
                    user_id: { _in: removeUsers }
                  }
                }
              });
            if (removeUserFromDistibutionGroupResponse.data) {
              promises.push("Distribution group updated successfully.");
            }
            if (removeUserFromDistibutionGroupResponse.errors) {
              promises.push("Failed to update distribution group.");
            }
          }

          // Adding new user to DL
          const newUsers: any = selectedUsers.filter((userId: string) => {
            return !users.find((user: any) => {
              return user?.user?.id === userId;
            });
          });

          if (newUsers.length > 0) {
            const newusers: any = [];
            newUsers.map((userid: string) =>
              newusers.push({
                dl_id: selectedGroupId,
                user_id: userid
              })
            );
            const insertUserToDistibutionGroupResponse =
              await insertUserToDistibutionGroup({
                variables: {
                  users: newusers
                }
              });

            if (insertUserToDistibutionGroupResponse.data) {
              promises.push("Distribution group updated successfully.");
            }
            if (insertUserToDistibutionGroupResponse.errors) {
              promises.push("Failed to update distribution group.");
            }
          }

          const updateDistibutionGroupResponse = await updateDistibutionGroup({
            variables: {
              _set: { name: form.getFieldValue("name") },
              where: { id: { _eq: selectedGroupId } }
            }
          });

          if (updateDistibutionGroupResponse.data) {
            promises.push("Distribution group updated successfully.");
          }

          if (updateDistibutionGroupResponse.errors) {
            promises.push("Failed to update distribution group.");
          }

          return Promise.all(promises);
        };
        getUpdateMutationsPromise().then((res: any) => {
          setLoading(false);
          if (res.length > 0) {
            if (res[0] === "Distribution group updated successfully.") {
              setDrawerOpen(false);
              form.resetFields();
              message.success(res[0]);
            } else {
              message.error(res[0]);
            }
          }
        });
      }
    } else {
      const usersArr = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedUsers.length; index++) {
        usersArr.push({ user_id: selectedUsers[index] });
      }

      const createDistibutionGroupResponse = await createDistibutionGroup({
        variables: {
          object: {
            name: form.getFieldValue("name"),
            distribution_group_user: {
              data: usersArr
            }
          }
        }
      });
      setLoading(false);

      if (createDistibutionGroupResponse.data) {
        setDrawerOpen(false);
        message.success("Distribution group created.");
      }

      if (createDistibutionGroupResponse.errors) {
        message.error("Failed to create distribution group.");
      }
    }
  };

  const onDelete = async () => {
    if (selectedGroupId) {
      setRemoving(true);
      const updateDistibutionGroupResponse = await updateDistibutionGroup({
        variables: {
          _set: { deleted: true, name: form.getFieldValue("name") },
          where: { id: { _eq: selectedGroupId } }
        }
      });
      setRemoving(false);
      if (updateDistibutionGroupResponse.data) {
        setDrawerOpen(false);
        message.success("Distribution group deleted successfully.");
      }

      if (updateDistibutionGroupResponse.errors) {
        message.error("Failed to delete distribution group.");
      }
    }
  };

  useEffect(() => {
    if (usersData?.project_users) {
      const filteredOptions = usersData?.project_users.filter((user: any) => {
        return !selectedUsers.find((selectedUsrId: any) => {
          return user?.user?.id === selectedUsrId;
        });
      });
      setFilteredUsers(filteredOptions);
    }
  }, [selectedUsers, usersData]);

  return (
    <Modal
      className="custom-drawer"
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div className="px-3">
        <Form
          preserve={false}
          form={form}
          scrollToFirstError
          layout="vertical"
          onFinish={onCreate}
        >
          <FormItem
            name="name"
            label="Distribution Group Name"
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
                message: ErrorMessages.GroupNameMsg
              },
              () => ({
                validator(_, value) {
                  if (value && selectedGrouName !== value) {
                    const groups: any =
                      distributionGroupData?.distribution_group?.filter(
                        (grp: any) => grp.name === value
                      );

                    if (groups?.length > 0) {
                      return Promise.reject(
                        new Error(
                          "Distribution group with this name exists. Please provide a different name"
                        )
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            name="users"
            label="Users"
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
                validator() {
                  if (form.getFieldValue("users")) {
                    const users: any = form.getFieldValue("users");
                    if (users?.length === 0) {
                      return Promise.reject(
                        new Error(ErrorMessages.GroupUsersMsg)
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Select
              loading={loading}
              className="min-w-[80px]"
              placeholder="Select users"
              mode="multiple"
              showSearch
              virtual={false}
              filterOption={(input, option: any) => {
                const { user } = filteredUsers.find(
                  (u: any) => u?.user?.email === option.key
                );
                const inputMes = input.toString().toLowerCase();
                const userData = [
                  user.email?.toLowerCase(),
                  `${user?.first_name?.toLowerCase()} ${user?.last_name?.toLowerCase()}`
                ];

                return userData.some((text: any) => text?.includes(inputMes));
              }}
              onChange={(event) => {
                setSelectedUsers(event);
              }}
              tagRender={(prop) =>
                dlTagRenderer(prop, usersData?.project_users ?? [])
              }
            >
              {filteredUsers &&
                filteredUsers?.map((user: any) => {
                  if (
                    user.status_id === AcceptanceStatus.DEACTIVATED &&
                    !selectedUsers?.includes(user.id)
                  )
                    return "";

                  const selectedAndInactive =
                    user.status_id === AcceptanceStatus.DEACTIVATED &&
                    selectedUsers?.includes(user.id);

                  return (
                    <Option
                      value={user?.user?.id}
                      key={user?.user?.email}
                      className={selectedAndInactive ? "hidden" : ""}
                    >
                      <div className="flex-col">
                        <div>{`${user?.user?.first_name} ${user?.user?.last_name}`}</div>
                        <div>{user?.user?.email}</div>
                      </div>
                    </Option>
                  );
                })}
            </Select>
          </FormItem>
          <div className="flex justify-end space-x-2 pt-4">
            {selectedGroupId && (
              <Button
                loading={isRemoving}
                disabled={isLoading || isRemoving}
                onClick={onDelete}
              >
                Delete group
              </Button>
            )}
            <Button
              loading={isLoading}
              disabled={isLoading || isRemoving || loading}
              htmlType="submit"
              type="primary"
            >
              {selectedGroupId ? "Update Group" : "Create Group"}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default CreateDLGroup;
