import { gql } from "@apollo/client";
import { message } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import {
  DELETE_FEATURE_COMPANY_PARTICIPANT,
  DELETE_FEATURE_INSTANCE_SPEC_SECTION,
  DELETE_FEATURE_INSTANCE_SPEC_SECTION_BY_IDS,
  DELETE_FEATURE_USER_PARTICIPANT,
  INSERT_FEATURE_COMPANY_PARTICIPANT,
  INSERT_FEATURE_INSTANCE_SPEC_SECTION,
  INSERT_FEATURE_USER_PARTICIPANT,
  MUTATION_UPDATE_RESPONSIBLE_DESIGN_FIRM,
  MUTATION_UPDATE_FEATURE_USER_PARTICIPANT,
  UPDATE_FEATURE_BID_PACKAGE_BY_PK,
  UPDATE_FEATURE_INSTANCE_BY_PK,
  UPDATE_FEATURE_WORKFLOW_INSTANCE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET,
  UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE
} from "entity-app/graphQL/ciq-feature-mutations";
import { FEATURE_INSTANCE_DETAILS_BY_PK } from "entity-app/graphQL/ciq-feature-queries";
import { getGQLQueryData } from "entity-app/services";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { useCallback, useContext, useEffect, useState } from "react";

export const useManageFeatureInstanceDetails = (featureId: string) => {
  const { gqlClientForProject, tokenRetrievalState }: TProjectContext =
    useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [mutationUpdateFeatureInstanceFields] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_BY_PK),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureInstanceWorkflowTemplate] = useCIQMutation(
    gql(UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureBidPackageFields] = useCIQMutation(
    gql(UPDATE_FEATURE_BID_PACKAGE_BY_PK),
    {
      client: gqlClientForProject
    }
  );

  const [mutationInsertFeatureInstanceSpecSection] = useCIQMutation(
    gql(INSERT_FEATURE_INSTANCE_SPEC_SECTION),
    {
      client: gqlClientForProject
    }
  );

  const [mutationDeleteFeatureInstanceSpecSection] = useCIQMutation(
    gql(DELETE_FEATURE_INSTANCE_SPEC_SECTION),
    {
      client: gqlClientForProject
    }
  );

  const [mutationDeleteFeatureInstanceSpecSectionByIds] = useCIQMutation(
    gql(DELETE_FEATURE_INSTANCE_SPEC_SECTION_BY_IDS),
    {
      client: gqlClientForProject
    }
  );

  const [mutationDeleteFeatureUserParticipant] = useCIQMutation(
    gql(DELETE_FEATURE_USER_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [mutationInsertFeatureUserParticipant] = useCIQMutation(
    gql(INSERT_FEATURE_USER_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [mutationInsertFeatureCompanyParticipant] = useCIQMutation(
    gql(INSERT_FEATURE_COMPANY_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [mutationDeleteFeatureCompanyParticipant] = useCIQMutation(
    gql(DELETE_FEATURE_COMPANY_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureWorkflowInstanceOffset] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET),
    {
      client: gqlClientForProject
    }
  );
  const [mutationUpdateFeatureWorkflowInstanceMilestone] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureWorkflowInstance] = useCIQMutation(
    gql(UPDATE_FEATURE_WORKFLOW_INSTANCE),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateDesignFirm] = useCIQMutation(
    gql(MUTATION_UPDATE_RESPONSIBLE_DESIGN_FIRM),
    {
      client: gqlClientForProject
    }
  );

  const [mutationUpdateFeatureUserParticipant] = useCIQMutation(
    gql(MUTATION_UPDATE_FEATURE_USER_PARTICIPANT),
    {
      client: gqlClientForProject
    }
  );

  const [featureInstanceData, setFeatureInstanceData] = useState();

  const getFeatureInstanceData = useCallback(async () => {
    if (!token) {
      return;
    }

    const fetchInstanceData = async () => {
      const featureInstanceDataResp = await getGQLQueryData(
        token,
        FEATURE_INSTANCE_DETAILS_BY_PK,
        {
          featureId
        }
      );
      setFeatureInstanceData(
        featureInstanceDataResp.data.data.feature_instance_by_pk
      );
    };

    await fetchInstanceData();

    setTimeout(fetchInstanceData, 3000); // Calling after 3 second
  }, [featureId, token]);

  const updateFeatureInstanceWorkflowTemplate = useCallback(
    async (workflowTemplateId: string) => {
      const payload: any = {
        variables: {
          featureInstanceId: featureId,
          workflowTemplateId
        }
      };

      const updateResponse =
        await mutationUpdateFeatureInstanceWorkflowTemplate(payload);

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [
      featureId,
      getFeatureInstanceData,
      mutationUpdateFeatureInstanceWorkflowTemplate
    ]
  );

  const updateFeatureInstanceFields = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureId,
          set: params
        }
      };

      const updateResponse = await mutationUpdateFeatureInstanceFields(payload);

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, getFeatureInstanceData, mutationUpdateFeatureInstanceFields]
  );

  const updateBidPackageFields = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureId,
          set: params
        }
      };
      const updateResponse = await mutationUpdateFeatureBidPackageFields(
        payload
      );

      if (updateResponse.success) {
        message.success("Updated successfully.");
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, mutationUpdateFeatureBidPackageFields]
  );

  const insertFeatureInstanceSpecSection = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureId,
          specSectionId: params.specSectionId,
          bidScope: true
        }
      };
      const insertResponse = await mutationInsertFeatureInstanceSpecSection(
        payload
      );
      if (insertResponse.success) {
        message.success("Added successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to add.");
      }
      return insertResponse;
    },
    [
      featureId,
      getFeatureInstanceData,
      mutationInsertFeatureInstanceSpecSection
    ]
  );

  const deleteFeatureInstanceSpecSection = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureId,
          specSectionId: params.specSectionId
        }
      };
      const deleteResponse = await mutationDeleteFeatureInstanceSpecSection(
        payload
      );
      if (deleteResponse.success) {
        message.success("Deleted successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to remove.");
      }
      return deleteResponse;
    },
    [
      featureId,
      getFeatureInstanceData,
      mutationDeleteFeatureInstanceSpecSection
    ]
  );

  const deleteFeatureInstanceSpecSectionByIds = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureId,
          specSectionIds: params.specSectionIds
        }
      };
      const deleteResponse =
        await mutationDeleteFeatureInstanceSpecSectionByIds(payload);
      if (deleteResponse.success) {
        message.success("Removed successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to remove.");
      }
      return deleteResponse;
    },
    [
      featureId,
      getFeatureInstanceData,
      mutationDeleteFeatureInstanceSpecSectionByIds
    ]
  );

  const deleteFeatureUserParticipant = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          instance_id: featureId,
          userId: params?.userId,
          userParticipantTypeId: params?.userParticipantTypeId
        }
      };

      const deleteResponse = await mutationDeleteFeatureUserParticipant(
        payload
      );
      return deleteResponse;
    },
    [featureId, mutationDeleteFeatureUserParticipant]
  );

  const insertFeatureUserParticipant = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          instance_id: featureId,
          userId: params?.userId,
          userParticipantTypeId: params?.userParticipantTypeId
        }
      };
      const insertResponse = await mutationInsertFeatureUserParticipant(
        payload
      );
      if (insertResponse?.data?.insert_feature_user_participant_one?.user_id) {
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return insertResponse;
    },
    [featureId, getFeatureInstanceData, mutationInsertFeatureUserParticipant]
  );

  const insertFeatureCompanyParticipant = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          instanceId: featureId,
          vendorId: params?.vendorId,
          companyParticipantTypeId: params?.companyParticipantTypeId
        }
      };

      const insertResponse = await mutationInsertFeatureCompanyParticipant(
        payload
      );
      if (insertResponse?.success) {
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return insertResponse;
    },
    [featureId, getFeatureInstanceData, mutationInsertFeatureCompanyParticipant]
  );

  const deleteFeatureCompanyParticipant = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          instanceId: featureId,
          vendorId: params?.vendorId,
          companyParticipantTypeId: params?.companyParticipantTypeId
        }
      };
      const deleteResponse = await mutationDeleteFeatureCompanyParticipant(
        payload
      );
      if (deleteResponse?.success) {
        getFeatureInstanceData();
      } else {
        message.error("Failed to delete.");
      }
      return deleteResponse;
    },
    [featureId, getFeatureInstanceData, mutationDeleteFeatureCompanyParticipant]
  );

  const updateFeatureWorkflowInstanceOffset = useCallback(
    async (params: {
      id: string;
      set: {
        duration: number;
      };
      workflowInstanceId: string;
    }) => {
      const payload = {
        variables: params
      };
      const updateResponse = await mutationUpdateFeatureWorkflowInstanceOffset(
        payload
      );

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [getFeatureInstanceData, mutationUpdateFeatureWorkflowInstanceOffset]
  );

  const updateFeatureWorkflowInstanceMilestone = useCallback(
    async (params: {
      id: string;
      set: {
        actual_date?: string | null;
        milestone_action?: string | null;
        name?: string | null;
      };
    }) => {
      const payload = {
        variables: params
      };
      const updateResponse =
        await mutationUpdateFeatureWorkflowInstanceMilestone(payload);

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [getFeatureInstanceData, mutationUpdateFeatureWorkflowInstanceMilestone]
  );

  //

  const updateFeatureWorkflowInstance = useCallback(
    async (params: {
      id: string;
      set: {
        high_risk_threshold?: number;
        low_risk_threshold?: number;
        manual_entry_date?: string | null;
        float?: number;
        override_project_threshold?: boolean;
        workflow_template_id?: string;
      };
    }) => {
      const payload = {
        variables: params
      };
      const updateResponse = await mutationUpdateFeatureWorkflowInstance(
        payload
      );

      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [getFeatureInstanceData, mutationUpdateFeatureWorkflowInstance]
  );

  const updateDesignFirm = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureInstanceId: featureId,
          designFirm: {
            instance_id: featureId,
            company_participation_type_id: 3,
            vendor_id: params?.design_firm
          }
        }
      };

      const updateResponse = await mutationUpdateDesignFirm(payload);
      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, getFeatureInstanceData, mutationUpdateDesignFirm]
  );

  const updateFeatureUserParticipant = useCallback(
    async (params: any) => {
      const payload: any = {
        variables: {
          featureInstanceId: featureId,
          user_participation_type_id: params?.user_participation_type_id,
          object: {
            instance_id: featureId,
            user_id: params?.user_id,
            user_participation_type_id: params?.user_participation_type_id
          }
        }
      };
      const updateResponse = await mutationUpdateFeatureUserParticipant(
        payload
      );
      if (updateResponse.success) {
        message.success("Updated successfully.");
        getFeatureInstanceData();
      } else {
        message.error("Failed to update.");
      }
      return updateResponse;
    },
    [featureId, getFeatureInstanceData, mutationUpdateFeatureUserParticipant]
  );

  useEffect(() => {
    if (!token) return;
    getFeatureInstanceData();
  }, [getFeatureInstanceData, token]);

  return {
    featureInstanceData,
    actions: {
      refetchFeatureInstanceData: getFeatureInstanceData,
      updateFeatureInstanceFields,
      updateFeatureInstanceWorkflowTemplate,
      updateBidPackageFields,
      insertFeatureInstanceSpecSection,
      deleteFeatureInstanceSpecSection,
      deleteFeatureInstanceSpecSectionByIds,
      deleteFeatureUserParticipant,
      insertFeatureUserParticipant,
      insertFeatureCompanyParticipant,
      deleteFeatureCompanyParticipant,
      updateFeatureWorkflowInstanceOffset,
      updateFeatureWorkflowInstanceMilestone,
      updateFeatureWorkflowInstance,
      updateDesignFirm,
      updateFeatureUserParticipant
    }
  };
};
