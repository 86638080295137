import { useMutation, useQuery } from "@apollo/client";
import { Spin, Tabs, message } from "antd";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useSubscriptionStream } from "hooks/common";
import { useProjectPK } from "hooks/project";
import ProjectUsersList from "pages/project-users";
import { useContext, useState } from "react";
import { getLoggedinUserId } from "services/auth";
import {
  QUERY_GET_VENDOR_ID,
  QUERY_PROJECT_USERS
} from "services/graphQL/queries";
import { SUBSCRIPTION_PROJECT_USERS_STREAM } from "services/graphQL/subscriptions";
import { useHistory, useParams } from "react-router";
import ProjectCompanies from "pages/project-companies";
import { MUTATION_UPDATE_PROJECT_BY_ID } from "services/graphQL/mutations";
import ProjectCalendar from "pages/project-calendar";
import { EUserTypes, ProjectPermissionEnum } from "../../../constants";
import ProjectDetailEdit from "./edit-project-detail";

function GeneralSettings({ isInternalGCser }: { isInternalGCser: boolean }) {
  const history = useHistory();
  const { projectId, tab2Id } = useParams() as any;
  const [tabIndex, setTabIndex] = useState<string>(tab2Id || "project-users");

  const { gqlClientForProject, tokenContents } = useContext(ProjectContext);

  const { data: projectPkData } = useProjectPK(projectId, gqlClientForProject);
  const { data: vendorData } = useQuery(QUERY_GET_VENDOR_ID, {
    variables: {
      userId: getLoggedinUserId()
    },
    client: gqlClientForProject,
    skip:
      !gqlClientForProject ||
      tokenContents?.userType === EUserTypes[EUserTypes.GENERAL_CONTRACTOR]
  });
  const projectUserVariables: any = {
    where: { project_id: { _eq: projectId } }
  };
  const { data: usersData } = useSubscriptionStream(
    QUERY_PROJECT_USERS,
    SUBSCRIPTION_PROJECT_USERS_STREAM,
    "user_id",
    {
      client: gqlClientForProject,
      variables: projectUserVariables,
      skip:
        !tokenContents ||
        !gqlClientForProject ||
        (tokenContents?.userType !==
          EUserTypes[EUserTypes.GENERAL_CONTRACTOR] &&
          !vendorData)
    }
  );

  const [updateProjectByIdMutation] = useMutation(
    MUTATION_UPDATE_PROJECT_BY_ID,
    {
      client: gqlClientForProject
    }
  );

  const updateProjectData = async (projectValues: any) => {
    const variables = {
      projectId,
      projectValues
    };

    const updateResponse = await updateProjectByIdMutation({
      variables
    });

    if (updateResponse && updateResponse.errors) {
      message.error(updateResponse.errors[0].message);
      return {
        success: false
      };
    }

    message.success("Updated successfully");
    return {
      success: true
    };
  };

  const tabItems = [
    {
      label: "Project Users",
      key: "project-users",
      children: (
        <ProjectUsersList internalUsers={{ project_users: usersData }} />
      )
    },
    {
      label: "Project Companies",
      key: "project-companies",
      children: <ProjectCompanies />
    },
    {
      label: "Details",
      key: "details",
      children:
        projectPkData && projectPkData.project_by_pk ? (
          <ProjectDetailEdit
            projectInfo={projectPkData.project_by_pk}
            updateProjectData={updateProjectData}
            hasEditPermission={
              !isPermissionNotGrantted(
                ProjectPermissionEnum.EditProjectDetail,
                tokenContents?.role!
              )
            }
          />
        ) : (
          <Spin size="large" />
        )
    },
    {
      label: "Calendar",
      key: "calendar",
      children: <ProjectCalendar />
    }
  ];

  const secondTabItems = [
    {
      label: "Project Users",
      key: "project-users",
      children: (
        <ProjectUsersList internalUsers={{ project_users: usersData }} />
      )
    }
  ];

  return (
    <div className="w-full py-1">
      <Tabs
        onChange={(activeKey) => {
          history.push(
            `/project/${projectId}/settings/general/${activeKey.toString()}`
          );
          setTabIndex(activeKey);
        }}
        activeKey={tabIndex}
        items={isInternalGCser ? tabItems : secondTabItems}
      />
    </div>
  );
}
export default GeneralSettings;
