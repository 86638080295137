import { Button, Tooltip, message } from "antd";
import "./activityLinking.scss";
import { useContext, useRef, useState, useEffect, useMemo } from "react";
import { ExclamationCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import modal from "antd/lib/modal";
import { ProjectContext } from "context/ProjectProvider";
import {
  MUTATION_INSERT_LINKING_SUBMITTAL_SCHEDULE,
  MUTATION_DELETE_LINKING_SUBMITTAL_SCHEDULE
} from "services/graphQL/mutations";
import ErrorBoundary from "components/error-boundary";
import ActivityIcon from "components/svg-icons/activity-icon";
import { DateUtils } from "utils/dateutils";
import { ErrorMessages } from "constants/index";
import { ScheduleLinkingType, ScheduleTaskType, SubmittalType } from "./models";
import ActivityGrid from "./activityGrid";
import ListingWindow from "./listingWindow";

interface IActivityListProps {
  activityList: Array<ScheduleTaskType>;
  selectedSubmittal: SubmittalType | undefined;
  reFetchActivity?: () => void;
  canEdit: boolean;
}

function ActivityLinking({
  activityList,
  selectedSubmittal,
  reFetchActivity,
  canEdit
}: IActivityListProps) {
  const activityLinking = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLElement>(null);
  const [openActivityList, setOpenActivityList] = useState(false);
  const { gqlClientForProject } = useContext(ProjectContext);
  const [apiCallBack, setApiCallBack] = useState<any>();

  useEffect(() => {
    const element = activityLinking.current;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    element && element?.scrollTo(0, element.scrollHeight);
  }, [selectedSubmittal?.submittal_schedule_links]);
  const [linkingSubmittalScheduleMutation] = useMutation(
    MUTATION_INSERT_LINKING_SUBMITTAL_SCHEDULE,
    {
      client: gqlClientForProject
    }
  );
  const [deleteLinkingSubmittalScheduleMutation] = useMutation(
    MUTATION_DELETE_LINKING_SUBMITTAL_SCHEDULE,
    {
      client: gqlClientForProject
    }
  );
  const [associatedActivitiesCount, setAssociatedActivitiesCount] =
    useState("");

  const onLinkingSubmittalScheduleMutation = (
    selectedScheduleId: string,
    linked_to_end_date: boolean
  ) => {
    linkingSubmittalScheduleMutation({
      variables: {
        object: {
          submittal_id: selectedSubmittal?.id,
          task_id: selectedScheduleId,
          linked_to_end_date
        }
      }
    }).then((res) => {
      if (res.data) {
        console.log(res.data);
      }
      if (res.errors) {
        if (res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)) {
          message.error(ErrorMessages.linkingErrorMsg);
          return;
        }
        message.error(res.errors[0].message);
      }
    });
  };

  const onRemoveLinkingSubmittalSchedule = (selectedScheduleId: string) => {
    modal.confirm({
      title: "Do you want to unlink?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      className: "skip-listing-window",
      onOk() {
        deleteLinkingSubmittalScheduleMutation({
          variables: {
            where: {
              task_id: { _eq: selectedScheduleId },
              submittal_id: { _eq: selectedSubmittal?.id }
            }
          }
        }).then((res) => {
          const activity = {
            id: selectedScheduleId,
            action: "deleted"
          };
          setApiCallBack(activity);
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
        });
      },
      onCancel() {
        const activity = {
          id: selectedScheduleId,
          action: "cancelled"
        };
        setApiCallBack(activity);
      },
      okText: "Yes",
      cancelText: "No"
    });
  };

  const onChange = (selectedScheduleId: string, params?: any) => {
    const selectedActivity = Object.keys(params?.selectedList).find(
      (taskId: string) => {
        return taskId === selectedScheduleId;
      }
    );
    if (selectedActivity) {
      if (!params || (params && params.linked_to_end_date === null)) {
        onRemoveLinkingSubmittalSchedule(selectedScheduleId);
      } else {
        deleteLinkingSubmittalScheduleMutation({
          variables: {
            where: {
              task_id: { _eq: selectedScheduleId },
              submittal_id: { _eq: selectedSubmittal?.id }
            }
          }
        }).then((res) => {
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
          if (!res.errors) {
            onLinkingSubmittalScheduleMutation(
              selectedScheduleId,
              params.linked_to_end_date
            );
          }
        });
      }
    } else {
      onLinkingSubmittalScheduleMutation(
        selectedScheduleId,
        params.linked_to_end_date
      );
    }
  };

  useEffect(() => {
    if (selectedSubmittal?.submittal_schedule_links) {
      const associatedActivitiesArr =
        selectedSubmittal?.submittal_schedule_links.map(
          (item: ScheduleLinkingType) => {
            const taskData = activityList.find(
              (task: ScheduleTaskType) => task.id === item.gantt_task.id
            );
            if (taskData) {
              return taskData;
            }
            return "";
          }
        );
      if (associatedActivitiesArr?.length > 0) {
        setAssociatedActivitiesCount(`${associatedActivitiesArr.length}`);
      }
    }
  }, [activityList, selectedSubmittal?.submittal_schedule_links]);

  const selectedList = useMemo(() => {
    const tmpSelectedListObj: any = {};
    selectedSubmittal?.submittal_schedule_links.forEach(
      (item: ScheduleLinkingType) => {
        tmpSelectedListObj[item.gantt_task.id] = {
          linked_to_end_date: item.linked_to_end_date
        };
      }
    );
    return tmpSelectedListObj || {};
  }, [selectedSubmittal?.submittal_schedule_links]);

  return (
    <ErrorBoundary>
      <div className="activity-linking" ref={activityLinking}>
        <div className="activity-linking--nav">
          <h4>
            Associated Activities{" "}
            <span className="">
              {associatedActivitiesCount !== ""
                ? ` (${associatedActivitiesCount})`
                : ""}
            </span>
          </h4>
        </div>
        <div className="h-full pb-10">
          <div className="overflow-y max-h-full overflow-x-hidden">
            {selectedSubmittal?.submittal_schedule_links.map(
              (item: ScheduleLinkingType) => {
                const taskData = activityList.find(
                  (task: ScheduleTaskType) => task.id === item.gantt_task.id
                );
                if (taskData) {
                  return (
                    <div
                      className="activity-linking--item"
                      key={item.gantt_task.id}
                    >
                      <ActivityIcon
                        size={20}
                        fill="#a8a8a8"
                        className="grow-none"
                      />
                      <i className="grow-none mr-2">
                        {taskData.source_task_id}
                      </i>
                      <Tooltip
                        className="flex grow"
                        title={taskData.text}
                        placement="topLeft"
                      >
                        {item.driving_task ? (
                          <span className="text-base mr-1 pt-[10px] leading-[0]">
                            *
                          </span>
                        ) : (
                          ""
                        )}
                        {taskData.text}
                        {taskData?.start_date &&
                        item.linked_to_end_date === false ? (
                          <i>
                            Start Date :{" "}
                            {taskData?.start_date
                              ? DateUtils.format(taskData?.start_date)
                              : ""}
                          </i>
                        ) : null}
                        {taskData?.end_date &&
                        item.linked_to_end_date === true ? (
                          <i>
                            End Date :{" "}
                            {taskData?.end_date
                              ? DateUtils.format(taskData?.end_date)
                              : ""}
                          </i>
                        ) : null}
                      </Tooltip>

                      <Button
                        size="small"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          onChange(item.gantt_task.id, {
                            linked_to_end_date: null,
                            selectedList
                          });
                        }}
                        type="link"
                        className="linking--unlink-button grow-none skip-listing-window"
                        disabled={!canEdit}
                      >
                        Unlink <LinkOutlined />
                      </Button>
                    </div>
                  );
                }
                return "";
              }
            )}
            <div
              className="flex justify-end mt-2"
              style={{ marginRight: "6px" }}
            >
              <Button
                className="mb-2"
                ref={buttonRef}
                onClick={() => setOpenActivityList(true)}
                disabled={!canEdit}
              >
                + Associate Activity
              </Button>
              {openActivityList ? (
                <ListingWindow
                  onClose={() => setOpenActivityList(false)}
                  title="List of Activities"
                  isFromSubmittals={undefined}
                >
                  <div className="activity-linking--options">
                    <ActivityGrid
                      activityList={activityList}
                      selectedList={selectedList}
                      onToggleSelect={onChange}
                      reFetchActivity={reFetchActivity}
                      apiCallBack={apiCallBack}
                    />
                  </div>
                </ListingWindow>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default ActivityLinking;
