import {
  IconAlertOctagonFilled,
  IconAlertTriangleFilled,
  IconInfoSquareRoundedFilled
} from "@tabler/icons-react";

import { Collapse } from "antd";
import DeletedIcon from "components/svg-icons/deleted-icon";
import ScheduleImpactV3 from "./schedule-impact-v3";
import { EntityType } from "../../../../constants/index";

function RenderImpactCategory(props: {
  categoryType: any;
  count: any;
  showCategory?: boolean;
  data: any;
  entityType: EntityType;
}) {
  const { categoryType, count, showCategory = true, data, entityType } = props;

  const getHeader = (catType: any) => {
    const itemCount = count || 0;
    return (
      <div>
        {(() => {
          switch (catType) {
            case "deleted":
              return (
                <div>
                  <DeletedIcon className="mb-[-4px] mr-2" />
                  Activities Deleted ({itemCount})
                </div>
              );
            case "high":
              return (
                <div>
                  <IconAlertOctagonFilled
                    size={18}
                    className="mb-[-4px] mr-2 text-red-500"
                  />
                  Attention required ({itemCount})
                </div>
              );
            case "medium":
              return (
                <div>
                  <IconAlertTriangleFilled
                    size={18}
                    className="mb-[-4px] mr-2 text-yellow-500"
                  />
                  Review recommended ({itemCount})
                </div>
              );
            case "low":
              return (
                <div>
                  <IconInfoSquareRoundedFilled
                    size={18}
                    className="mb-[-4px] mr-2 text-slate-500"
                  />
                  Information only ({itemCount})
                </div>
              );
            default:
              return <div>default</div>;
          }
        })()}
      </div>
    );
  };

  const renderImpacts = (activity: any, entityTyp: EntityType) => {
    // console.log("activity =>", activity);
    return (
      <ScheduleImpactV3
        key={activity.id}
        activity={activity}
        entityType={entityTyp}
      />
    );
  };

  if (!categoryType) {
    return null;
  }

  if (entityType === EntityType.None) {
    console.error("Entity type is not define =>", entityType);
    return null;
  }

  return (
    <div>
      {count > 0 && showCategory && (
        <Collapse accordion key="attentionRequired-a">
          <Collapse.Panel
            key="attentionRequired-material"
            header={getHeader(categoryType)}
            className="impactBoxContainerTitle"
          >
            {count
              ? data.map((activity: any) => renderImpacts(activity, entityType))
              : null}
          </Collapse.Panel>
        </Collapse>
      )}{" "}
    </div>
  );
}

export default RenderImpactCategory;
