import { Select } from "antd";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";

export const MaterialTemplateSelectCellEditor = forwardRef(
  (props: any, ref) => {
    const {
      data,
      context,
      MDBTemplatesContextKey,
      showSearch,
      stopEditing,
      notFoundContent
    } = props;

    const [value, setValue] = useState(props.value);

    const dropDownOptions = useMemo(() => {
      return context[MDBTemplatesContextKey].filter((template: any) => {
        return (
          !template.disabled || template.id === data.date_block_template_id
        );
      }).map((template: any) => {
        return {
          label: template.name,
          value: template.id
        };
      });
    }, [context, MDBTemplatesContextKey, data.date_block_template_id]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (!value) return null;
          return value;
        }
      };
    });

    return (
      <Select
        autoFocus
        defaultOpen
        placeholder={showSearch ? "Select / Search" : "Select"}
        allowClear
        showSearch={showSearch}
        optionFilterProp="label"
        options={dropDownOptions}
        virtual={false}
        notFoundContent={notFoundContent}
        style={{ width: "100%" }}
        filterOption={(input, option: any) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(event: any) => {
          setValue(event);
          setTimeout(() => {
            stopEditing();
          }, 200);
        }}
      />
    );
  }
);
