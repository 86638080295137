import { ScheduleImpactStates } from "constants/index";
import { ImpactSummaryDataType } from "./model";
import "./sch-impact-summary-table.scss";

function ScheduleImpactSummary(props: {
  scheduleImpactSummary: ImpactSummaryDataType;
}) {
  const { scheduleImpactSummary } = props;
  return (
    <div className=" bg-white p-2 mb-2">
      <div className="font-medium text-[14px] text-[#3B3B3B] mb-0.5 pl-0.5">
        SUMMARY
      </div>
      <table className="sch-summary-table text-left w-full ">
        <thead>
          <tr>
            <th>Type of Change</th>
            <th>Total No of Activities</th>
            <th>Activities tied to Procurement</th>
            <th>Impacted Submittals</th>
            <th>Impacted Materials</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="cell-with-padding">
              {ScheduleImpactStates.ACTIVITIES_UPDATED}
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.totalNoOfActivities?.updated}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.activitiesTiedToProcurement?.updated}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.impactedSubmittals?.updated}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.impactedMaterials?.updated}
              </span>
            </td>
          </tr>
          <tr>
            <td className="cell-with-padding">
              {ScheduleImpactStates.ACTIVITIES_DELETED}
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.totalNoOfActivities?.deleted}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.activitiesTiedToProcurement?.deleted}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.impactedSubmittals?.deleted}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.impactedMaterials?.deleted}
              </span>
            </td>
          </tr>
          <tr>
            <td className="cell-with-padding">
              {ScheduleImpactStates.ACTIVITIES_ADDED}
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.totalNoOfActivities?.added}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.activitiesTiedToProcurement?.added}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.impactedSubmittals?.added}
              </span>
            </td>
            <td>
              <span className="cell-with-padding">
                {scheduleImpactSummary?.impactedMaterials?.added}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ScheduleImpactSummary;
