import { LinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import LinkIcon from "components/svg-icons/link-icon";
import { LinkTypes } from "entity-app/constants";
import { DateUtils } from "utils/dateutils";

function ActivityItem({
  activity,
  onUnlink
}: {
  activity: any;
  onUnlink: (activity: any) => void;
}) {
  return (
    <div className="w-full bg-white border border-solid border-[#3B3B3B4D] text-xs p-2 bg-transparent m-0 text-left space-y-1">
      <div className="w-full flex items-center gap-x-1">
        <div className="shrink-0 flex items-center justify-center">
          <LinkIcon />
        </div>
        <div
          className="grow text-gray-500 truncate"
          title={activity.source_task_id}
        >
          {activity.source_task_id}
        </div>
        <div>
          <Button
            type="link"
            size="small"
            className="text-xs ciq-button-2 h-[20px]"
            onClick={() => onUnlink(activity)}
          >
            Unlink <LinkOutlined className="!ml-1" />
          </Button>
        </div>
      </div>
      <div className="truncate" title={activity.text}>
        {activity.text}
      </div>
      {activity.start_date &&
        activity.link_type === LinkTypes.finish_to_start && (
          <div className="text-gray-500">
            Start Date:{" "}
            {activity.start_date
              ? DateUtils.format(activity.start_date, "MM-DD-YYYY")
              : ""}
          </div>
        )}
      {activity.end_date &&
        activity.link_type === LinkTypes.finish_to_finish && (
          <div className="text-gray-500">
            End Date:{" "}
            {activity.end_date
              ? DateUtils.format(activity.end_date, "MM-DD-YYYY")
              : ""}
          </div>
        )}
    </div>
  );
}

export default ActivityItem;
