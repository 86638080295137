import { useSubscription } from "@apollo/client";
import { Input, Select, TreeSelect } from "antd";
import {
  UnitType,
  getTreeFromMeasuremntList
} from "pages/material-details/material-details-tab/units-tree";
import { forwardRef, useState, useImperativeHandle, useMemo } from "react";
import { SUBSCRIPTION_COMPANY_LIST } from "services/graphQL/subscriptions";

const { Option } = Select;

export const TradePartnerCompanySelect = forwardRef((props: any, ref) => {
  const { stopEditing } = props;
  const [value, setValue] = useState(props.value);

  const { data: tradePartnerCompanies, loading: tradePartnerCompaniesLoading } =
    useSubscription(SUBSCRIPTION_COMPANY_LIST);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!value) return null;
        return value;
      }
    };
  });

  return (
    <Select
      defaultOpen
      placeholder="Select / Search"
      showSearch
      virtual={false}
      style={{ width: "100%" }}
      onChange={(event: any) => {
        setValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
      loading={tradePartnerCompaniesLoading}
    >
      {tradePartnerCompanies?.subscription_vendors.map((company: any) => {
        return (
          <Option key={company.id} value={company.id}>
            {company.name}
          </Option>
        );
      })}
    </Select>
  );
});

export const measurementSelectEditor = forwardRef((props: any, ref) => {
  const {
    context,
    data,
    dataMapVar,
    notFoundContent,
    setKeyId,
    unitValueProp,
    unit
  } = props;
  const [unitId, setUnitId] = useState<string>();
  const [unitValue, setUnitValue] = useState<number>(data[unitValueProp] || 0);
  const itemsMap = context[dataMapVar] as Array<UnitType>;

  const treeData = useMemo(() => {
    return getTreeFromMeasuremntList(itemsMap);
  }, [itemsMap]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (unitId === undefined) {
          if (unitValue === data[unitValueProp]) return null;
          return {
            unitValue: {
              prop: unitValueProp,
              value: unitValue
            }
          };
        }
        const label = itemsMap.find((x) => x.id === unitId);
        return {
          unitValue: {
            prop: unitValueProp,
            value: unitValue
          },
          unitType: {
            prop: setKeyId,
            value: unitId
          },
          label: `${label?.unit}`
        };
      }
    };
  });

  return (
    <div className="flex">
      <Input
        type="number"
        className="w-1/3 h-8"
        value={unitValue}
        onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
        onChange={(event: any) => {
          setUnitValue(event.target.value.replace(/\D/g, ""));
        }}
        min="0"
      />
      <TreeSelect
        placeholder="Select"
        virtual={false}
        notFoundContent={notFoundContent}
        className="w-2/3 h-8"
        defaultValue={data[unit] || ""}
        value={unitId || undefined}
        onChange={(event: any) => setUnitId(event)}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        treeDefaultExpandAll
        treeData={treeData}
      />
    </div>
  );
});
