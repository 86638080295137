import { ReactElement } from "react";

function GoverningLegend(): ReactElement {
  return (
    <div className=" px-2 flex items-center text-sm">
      <span className="flex items-center text-xl mx-1 pt-2 leading-none">
        *
      </span>
      Governing Date
    </div>
  );
}

export default GoverningLegend;
