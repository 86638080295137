import { IconProps } from "./submittal-icon-2";

function ActivityIcon({
  className = "",
  onClick,
  style,
  fill = "#3B3B3B",
  size = 24,
  title = "Activity"
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      className={className}
      onClick={onClick}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      xlinkTitle="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_62_177)">
        <path d="M17 2V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7V2H17ZM7 6H5V20H19V6H17V8H7V6ZM9 16V18H7V16H9ZM9 13V15H7V13H9ZM9 10V12H7V10H9ZM15 4H9V6H15V4Z" />
      </g>
    </svg>
  );
}

export default ActivityIcon;
