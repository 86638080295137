import { SettingOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormRule,
  Input,
  Popover,
  Spin,
  message
} from "antd";
import { useState, useContext, useEffect } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { QUERY_PROJECT_FEATURE_CONFIGURATION } from "services/graphQL/queries";
import { MUTATION_UPDATE_DATEBLOCK_PK } from "./gql-graphgl";

type ThresholdType = {
  id: string;
  high_risk_threshold: number;
  low_risk_threshold: number;
  override_project_threshold: boolean;
  feature_id?: number;
  submittal_id?: string;
};

function EditThresholdPanel(props: { dateblock: ThresholdType }) {
  const { dateblock } = props;
  const featureId = dateblock.submittal_id ? 1 : 2;
  const { gqlClientForProject } = useContext(ProjectContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isUseProjectSetting, setUseProjectSetting] = useState(
    !dateblock.override_project_threshold
  );

  const { data: featureConfigurationsData, refetch: refetchThresholdData } =
    useCIQQuery<{
      project_feature_configurations: Array<ThresholdType>;
    }>(QUERY_PROJECT_FEATURE_CONFIGURATION, {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    });

  useEffect(() => {
    if (form && dateblock) {
      form.setFieldsValue(dateblock);
      setUseProjectSetting(!dateblock.override_project_threshold);
    }
  }, [dateblock, form]);

  const [updateDateBlockMutationPK] = useMutation(
    MUTATION_UPDATE_DATEBLOCK_PK,
    {
      client: gqlClientForProject
    }
  );

  const updateThreshold = async (values: any) => {
    const variables: any = { id: dateblock.id, set: values };

    try {
      const res = await updateDateBlockMutationPK({
        variables
      });

      if (res.data) {
        message.success("Updated successfully.");
      }
      if (res.errors) {
        message.error(res.errors[0].message);
      }
    } catch (ex: any) {
      message.error(ex.message);
    }
  };

  const thresholdRules: FormRule[] = [
    {
      required: true,
      message: <div className="text-xs">Please enter threshold </div>
    },
    {
      message: (
        <div className="text-xs">
          Please enter threshold in numbers ( + or - ) only.
        </div>
      ),
      validator: (_, value) => {
        const number = Number(value);
        if (!Number.isInteger(number) || Number.isNaN(number)) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    },
    {
      message: (
        <div className="text-[#3B3B3BCC] text-xs">
          Please note that negative value of float indicates the workflow is
          already delayed.
        </div>
      ),
      warningOnly: true,
      validator: async (_, value) => {
        const number = Number(value);
        if (number < 0) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    }
  ];

  const editThresholdDiv = (
    <div className="w-96">
      <div className="flex  text-xs text-justify">
        Indicate the value of float at which you would consider the workflow to
        be at High, Medium and Low risk.
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          setLoading(true);

          let setData: any = {};
          if (isUseProjectSetting)
            setData = { override_project_threshold: !isUseProjectSetting };
          else
            setData = {
              override_project_threshold: !isUseProjectSetting,
              high_risk_threshold: values.high_risk_threshold,
              low_risk_threshold: values.low_risk_threshold
            };

          await updateThreshold(setData);
          setLoading(false);
          setVisible(!visible);
        }}
        onReset={() => {
          setVisible(!visible);
        }}
        initialValues={dateblock}
        validateTrigger={["onSubmit", "onChange"]}
        disabled={loading}
      >
        <Checkbox
          className="py-1"
          checked={isUseProjectSetting}
          onChange={async (event) => {
            setUseProjectSetting(event.target.checked);
            if (event.target.checked) {
              const data =
                featureConfigurationsData?.project_feature_configurations?.find(
                  (s) => s.feature_id === featureId
                );
              form.setFields([
                {
                  name: "high_risk_threshold",
                  value: data?.high_risk_threshold,
                  errors: []
                },
                {
                  name: "low_risk_threshold",
                  value: data?.low_risk_threshold,
                  errors: []
                }
              ]);
            }
          }}
          disabled={loading}
        >
          Use Project Settings
        </Checkbox>
        <Form.Item
          label="HIGH RISK THRESHOLD"
          name="high_risk_threshold"
          rules={thresholdRules}
        >
          <Input
            type="text"
            autoComplete="off"
            disabled={isUseProjectSetting}
          />
        </Form.Item>
        <Form.Item
          label="LOW RISK THRESHOLD"
          name="low_risk_threshold"
          rules={[
            ...thresholdRules,
            {
              validateTrigger: "onSubmit",
              message: (
                <div className="text-xs">
                  High risk threshold has to be lower than the low risk
                  threshold.
                </div>
              ),
              validator: () => {
                const highRisk = Number(
                  form.getFieldValue("high_risk_threshold")
                );
                const lowRisk = Number(
                  form.getFieldValue("low_risk_threshold")
                );
                if (lowRisk <= highRisk) {
                  return Promise.reject();
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            type="text"
            autoComplete="off"
            disabled={isUseProjectSetting}
          />
        </Form.Item>
        <Divider className="my-2" />
        <div className="flex w-full justify-end space-x-2">
          <Button htmlType="reset" disabled={loading}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
  return (
    <Popover
      content={featureConfigurationsData ? editThresholdDiv : <Spin />}
      trigger="click"
      title="RISK THRESHOLD"
      placement="bottomLeft"
      align={{ offset: [-20] }}
      className="p-0"
      open={visible}
      onOpenChange={() => {
        if (refetchThresholdData) refetchThresholdData();
        form.setFieldsValue(dateblock);
        setUseProjectSetting(!dateblock.override_project_threshold);

        setVisible(!visible);
      }}
      destroyTooltipOnHide
    >
      <SettingOutlined />
    </Popover>
  );
}
export default EditThresholdPanel;
