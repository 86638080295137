import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from "axios";
import { subscriptionTokenExpiryInterceptor } from "utils/utils";
import ErrorManager from "utils/cumulative-error-message/error-manager";
import { BASE_URL } from "./endpoints";

export type HTTPClientResponse = {
  success: boolean;
  data?: any;
  error?: AxiosError;
  errorMsg?: string;
  response: any;
};

type HTTPClientParams = {
  url: string;
  config?: AxiosRequestConfig;
  data?: any;
  showError?: boolean;
};

export class CIQHTTPProvider {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        "Content-Type": "application/json"
      }
    });

    this.axiosInstance.interceptors.request.use(
      subscriptionTokenExpiryInterceptor
    );
  }

  public async get<T = any>(
    params: HTTPClientParams
  ): Promise<HTTPClientResponse> {
    const { url, config, showError = true } = params;
    let response: AxiosResponse<T> = null!;
    try {
      response = await this.axiosInstance.get(url, config);
      return {
        success: true,
        data: response.data,
        response
      };
    } catch (error: any) {
      const errorMsg = ErrorManager().handleError(error, showError);

      return {
        success: false,
        error,
        errorMsg,
        response
      };
    }
  }

  public async post<T = any>(
    params: HTTPClientParams
  ): Promise<HTTPClientResponse> {
    const { url, config, data, showError = true } = params;
    let response: AxiosResponse<T> = null!;
    try {
      response = await this.axiosInstance.post(url, data, config);

      return {
        success: true,
        data: response.data,
        response
      };
    } catch (error: any) {
      console.log("in exception");

      const errorMsg = ErrorManager().handleError(error, showError);

      return {
        success: false,
        error,
        errorMsg,
        response
      };
    }
  }

  public async put<T = any>(
    params: HTTPClientParams
  ): Promise<HTTPClientResponse> {
    const { url, config, data, showError = true } = params;
    let response: AxiosResponse<T> = null!;
    try {
      response = await this.axiosInstance.put(url, data, config);
      return {
        success: true,
        data: response.data,
        response
      };
    } catch (error: any) {
      const errorMsg = ErrorManager().handleError(error, showError);
      return {
        success: false,
        error,
        errorMsg,
        response
      };
    }
  }
}

const CIQClient = new CIQHTTPProvider();

export default CIQClient;
