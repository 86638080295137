import { Button } from "antd";

export default function SubscriptionIntegrationListMessage(params: {
  onConfigureClick: () => void;
}) {
  const { onConfigureClick } = params;
  return (
    <div className="absolute top-[45%] z-50 left-0 right-0 flex items-center justify-center text-sm space-x-1">
      <span>There no integrations added.</span>
      <Button
        size="small"
        type="text"
        className="font-medium workflow-document-upload"
        onClick={onConfigureClick}
      >
        Click here
      </Button>
      <span>to Add.</span>
    </div>
  );
}
