import { Button, Select } from "antd";
import { Schedule } from "entity-app/hooks/feature-activity-linking";
import { Link, useParams } from "react-router-dom";
import { DateUtils } from "utils/dateutils";

function ActivityLinksDialogHeader(props: {
  selectedSchedule: Schedule | null | undefined;
  scheduleList: Schedule[];
  onCancel: () => void;
  onSave: () => void;
  onScheduleChange: (value: {
    id: string;
    schedule_date: string;
    schedule_name: string;
  }) => void;
  isLoadingScheduleList: boolean;
  isSaving: boolean;
  saveButtonDisabled: boolean;
  scheduleSelectionDisabled: boolean;
}) {
  const {
    selectedSchedule,
    onCancel,
    onSave,
    scheduleList,
    onScheduleChange,
    isLoadingScheduleList,
    isSaving,
    saveButtonDisabled,
    scheduleSelectionDisabled
  } = props;
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <div className="flex items-center text-xs">
      <div className="font-semibold uppercase border-0 border-r-2 border-gray-400 border-solid pr-4 ">
        Modify Activity Links
      </div>
      <div className="grow pl-4">
        <span className="mr-2">Active Schedule:</span>
        {!selectedSchedule?.is_active && !isLoadingScheduleList ? (
          <Select
            className="w-[250px]"
            disabled={scheduleSelectionDisabled}
            onChange={(value) => {
              const newScheduleSelection = scheduleList.find(
                (schedule: any) => schedule.id === value
              );
              onScheduleChange(newScheduleSelection as any);
            }}
            value={selectedSchedule?.id}
            placeholder="Select a schedule"
            loading={isLoadingScheduleList}
            notFoundContent={
              <div className="text-center">
                There are no schedules to select.
                <Link to={`/project/${projectId}/schedule`}> Click here </Link>
                to import a schedule.
              </div>
            }
          >
            {scheduleList.map((schedule: any) => (
              <Select.Option key={schedule.id} value={schedule.id}>
                {DateUtils.format(schedule.schedule_date)} -{" "}
                {schedule.schedule_name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <span>
            {DateUtils.format(selectedSchedule?.schedule_date)} -{" "}
            {selectedSchedule?.schedule_name}
          </span>
        )}
      </div>
      <div className="flex gap-x-3">
        <Button className="px-6" onClick={onCancel} disabled={isSaving}>
          Cancel
        </Button>
        <Button
          className="px-6"
          type="primary"
          onClick={onSave}
          disabled={saveButtonDisabled}
          loading={isSaving}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default ActivityLinksDialogHeader;
