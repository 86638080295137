function AuthenticationExpiredIcon() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.75L13.1627 2.11949C13.5059 2.19575 13.75 2.5001 13.75 2.85163V10.3417C13.75 11.8463 12.998 13.2513 11.7461 14.0859L7 17.25L2.25385 14.0859C1.00196 13.2513 0.25 11.8463 0.25 10.3417V2.85163C0.25 2.5001 0.494148 2.19575 0.837303 2.11949L7 0.75ZM7 2.28659L1.75 3.45326V10.3417C1.75 11.3447 2.2513 12.2814 3.0859 12.8378L7 15.4472L10.9141 12.8378C11.7487 12.2814 12.25 11.3447 12.25 10.3417V3.45326L7 2.28659ZM7 5.25C7.82845 5.25 8.5 5.92157 8.5 6.75C8.5 7.30488 8.19872 7.78935 7.75082 8.04885L7.75 11.25H6.25L6.24992 8.0493C5.80165 7.78995 5.5 7.30519 5.5 6.75C5.5 5.92157 6.17155 5.25 7 5.25Z"
        fill="#EF774B"
      />
    </svg>
  );
}

export default AuthenticationExpiredIcon;
