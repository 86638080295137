import { gql } from "@apollo/client";

const SUBSCRIPTION_PROJECTS = gql`
  subscription Projects($where: project_list_view_bool_exp = {}) {
    project_list_func(where: $where) {
      invited_by
      name
      id
      vendor_id
      project_owner_subscription_name
    }
  }
`;

const SUBSCRIPTION_PROJECTS_PK = gql`
  subscription ProjectPK($id: uuid!) {
    project_by_pk(id: $id) {
      value
      client
      address
      number
      created_at
      created_by
      created_by_user {
        first_name
        last_name
      }
      details
      end_date
      id
      name
      start_date
      status_id
      spec_section
      updated_at
      updated_by
      mode
      timezone {
        timezone_id
        name
      }
    }
  }
`;

const SUBSCRIPTION_PROJECT_OFFSET_DEFAULT_PK = gql`
  subscription GetProjectDefaultSetting($project_id: uuid!) {
    project_offset_defaults_by_pk(project_id: $project_id) {
      ext_review_offset
      fabrication_duration_offset
      final_gc_review_offset
      float
      gc_review_offset
      lead_time_offset
      material_PO_fabrication_offset
      on_site_storage_offset
      submittal_prep_offset
    }
  }
`;

const SUBSCRIPTION_NEW_PROJECT_OFFSET_DEFAULT = gql`
  subscription GetNewProjectDefaultSetting {
    workflow_defaults {
      feature_id
      workflow_data
      float
    }
  }
`;

const SUBSCRIPTION_PROJECT_FEATURE_CONFIGURATION = gql`
  subscription featureConfigurationSubscription {
    project_feature_configurations {
      feature_id
      high_risk_threshold
      low_risk_threshold
    }
  }
`;

const SUBSCRIPTION_PROJECT_SPECS = gql`
  subscription Specs {
    spec_sections(
      order_by: { section_number: asc }
      distinct_on: section_number
    ) {
      title
      id
      section_number
      file_key
    }
  }
`;

const SPECIFICATIONS_PROJECT_SPECS = gql`
  subscription Specs(
    $where: spec_sections_bool_exp = { _eq: [{ status: { _eq: "GENERATED" } }] }
  ) {
    spec_sections(where: $where) {
      title
      id
      section_number
      file_key
    }
  }
`;

const SUBSCRIPTION_SUBMITTALS_LINKING_TABLE = gql`
  subscription SubmittalsLinking {
    submittals(where: { deleted: { _eq: false } }) {
      id
      submittal_id
      title
      description
      spec_name
      spec_no
      material_tracking
      submittal_type {
        id
        value
      }
      submittal_schedule_links {
        gantt_task {
          id
          source_task_id
          text
        }
      }
      submittal_material_links {
        material {
          spec_section_name
          spec_section_no
          name
          material_id
          id
          actual_ROJ
          implicit
          actual_fabrication_start_date
          actual_material_release_date
          actual_shipment_date
          material_schedule_links {
            linked_to_end_date
            gantt_task {
              id
              source_task_id
              text
            }
          }
          material_date_block {
            ROJ_date
            fabrication_duration_offset
            fabrication_start_date
            lead_time_offset
            material_PO_date
            material_PO_fabrication_offset
            on_site_storage_offset
            shipment_date
            material_id
            manual_entry
          }
        }
      }
      submittal_date_block {
        ext_review_offset
        final_gc_review_offset
        gc_review_offset
        submittal_prep_offset
        float
        assigned_date
        ext_review_completed_date
        ext_review_submitted_date
        submittal_distributed_date
        trade_partner_submitted_date
        submittal_id
        actual_assigned_date
        actual_ext_review_completed_date
        actual_ext_review_submitted_date
        actual_submittal_distributed_date
        actual_trade_partner_submitted_date
      }
    }
  }
`;

const SUBSCRIPTION_SUBMITTALS_LINKING_BY_ID = gql`
  subscription SubmittalsLinking($id: uuid!) {
    submittals_by_pk(id: $id) {
      id
      submittal_id
      title
      description
      spec_name
      spec_no
      material_tracking
      status
      submittal_type {
        id
        value
      }
      submittal_schedule_links {
        linked_to_end_date
        created_at
        driving_task
        gantt_task {
          id
          source_task_id
          text
        }
      }
      submittal_material_links {
        created_at
        driving_material
        material {
          spec_section_name
          spec_section_no
          name
          material_id
          id
          actual_ROJ
          implicit
          actual_fabrication_start_date
          actual_material_release_date
          actual_shipment_date
          material_schedule_links {
            linked_to_end_date
            driving_task
            gantt_task {
              id
              source_task_id
              text
            }
          }
          material_date_block {
            ROJ_date
            fabrication_duration_offset
            fabrication_start_date
            lead_time_offset
            material_PO_date
            material_PO_fabrication_offset
            on_site_storage_offset
            shipment_date
            material_id
            projected_fabrication_start_date
            projected_material_PO_date
            projected_shipment_date
            projected_ROJ_date
            manual_entry
          }
          date_block_materials {
            name_milestone_1
            name_milestone_2
            name_milestone_3
            name_milestone_4
            name_milestone_5
            name_milestone_6
            name_milestone_7
            name_milestone_8
            name_milestone_9
            name_milestone_10
            actual_milestone_1
            actual_milestone_2
            actual_milestone_3
            actual_milestone_4
            actual_milestone_5
            actual_milestone_6
            actual_milestone_7
            actual_milestone_8
            actual_milestone_9
            actual_milestone_10
            name_offset_1
            name_offset_2
            name_offset_3
            name_offset_4
            name_offset_5
            name_offset_6
            name_offset_7
            name_offset_8
            name_offset_9
            name_offset_10
            offset_1
            offset_2
            offset_3
            offset_4
            offset_5
            offset_6
            offset_7
            offset_8
            offset_9
            offset_10
            planned_milestone_1
            planned_milestone_2
            planned_milestone_3
            planned_milestone_4
            planned_milestone_5
            planned_milestone_6
            planned_milestone_7
            planned_milestone_8
            planned_milestone_9
            planned_milestone_10
            manual_entry_date
            float
            id
            material_id
            workflow_template_id
          }
        }
      }
      submittal_date_block {
        ext_review_offset
        final_gc_review_offset
        gc_review_offset
        submittal_prep_offset
        float
        assigned_date
        ext_review_completed_date
        ext_review_submitted_date
        submittal_distributed_date
        trade_partner_submitted_date
        submittal_id
        actual_assigned_date
        actual_ext_review_completed_date
        actual_ext_review_submitted_date
        actual_submittal_distributed_date
        actual_trade_partner_submitted_date
        manual_entry
        projected_assigned_date
        projected_trade_partner_submitted_date
        projected_ext_review_submitted_date
        projected_ext_review_completed_date
        projected_submittal_distributed_date
      }
      date_block_submittals {
        name_milestone_1
        name_milestone_2
        name_milestone_3
        name_milestone_4
        name_milestone_5
        name_milestone_6
        name_milestone_7
        name_milestone_8
        name_milestone_9
        name_milestone_10
        actual_milestone_1
        actual_milestone_2
        actual_milestone_3
        actual_milestone_4
        actual_milestone_5
        actual_milestone_6
        actual_milestone_7
        actual_milestone_8
        actual_milestone_9
        actual_milestone_10
        name_offset_1
        name_offset_2
        name_offset_3
        name_offset_4
        name_offset_5
        name_offset_6
        name_offset_7
        name_offset_8
        name_offset_9
        name_offset_10
        offset_1
        offset_2
        offset_3
        offset_4
        offset_5
        offset_6
        offset_7
        offset_8
        offset_9
        offset_10
        planned_milestone_1
        planned_milestone_2
        planned_milestone_3
        planned_milestone_4
        planned_milestone_5
        planned_milestone_6
        planned_milestone_7
        planned_milestone_8
        planned_milestone_9
        planned_milestone_10
        manual_entry_date
        float
        id
        submittal_id
      }
    }
  }
`;

const SUBSCRIPTION_MATERIAL_LINKING_BY_ID = gql`
  subscription MaterialLinking($id: uuid!) {
    material_by_pk(id: $id) {
      spec_section_name
      spec_section_no
      name
      material_id
      id
      actual_ROJ
      implicit
      actual_fabrication_start_date
      actual_material_release_date
      actual_shipment_date
      submittal_material_links {
        created_at
        submittal {
          id
          submittal_id
          title
          description
          spec_name
          spec_no
          material_tracking
          submittal_material_links {
            driving_material
          }
          submittal_schedule_links {
            driving_task
            gantt_task {
              id
              source_task_id
              text
            }
          }
          submittal_type {
            id
            value
          }
          submittal_date_block {
            ext_review_offset
            final_gc_review_offset
            gc_review_offset
            submittal_prep_offset
            float
            assigned_date
            ext_review_completed_date
            ext_review_submitted_date
            submittal_distributed_date
            trade_partner_submitted_date
            submittal_id
            actual_assigned_date
            actual_ext_review_completed_date
            actual_ext_review_submitted_date
            actual_submittal_distributed_date
            actual_trade_partner_submitted_date
            manual_entry
            projected_assigned_date
            projected_trade_partner_submitted_date
            projected_ext_review_submitted_date
            projected_ext_review_completed_date
            projected_submittal_distributed_date
          }
          date_block_submittals {
            name_milestone_1
            name_milestone_2
            name_milestone_3
            name_milestone_4
            name_milestone_5
            name_milestone_6
            name_milestone_7
            name_milestone_8
            name_milestone_9
            name_milestone_10
            actual_milestone_1
            actual_milestone_2
            actual_milestone_3
            actual_milestone_4
            actual_milestone_5
            actual_milestone_6
            actual_milestone_7
            actual_milestone_8
            actual_milestone_9
            actual_milestone_10
            name_offset_1
            name_offset_2
            name_offset_3
            name_offset_4
            name_offset_5
            name_offset_6
            name_offset_7
            name_offset_8
            name_offset_9
            name_offset_10
            offset_1
            offset_2
            offset_3
            offset_4
            offset_5
            offset_6
            offset_7
            offset_8
            offset_9
            offset_10
            planned_milestone_1
            planned_milestone_2
            planned_milestone_3
            planned_milestone_4
            planned_milestone_5
            planned_milestone_6
            planned_milestone_7
            planned_milestone_8
            planned_milestone_9
            planned_milestone_10
            manual_entry_date
            float
            id
            submittal_id
          }
        }
      }
      material_schedule_links {
        linked_to_end_date
        created_at
        driving_task
        gantt_task {
          id
          source_task_id
          text
        }
      }
      material_date_block {
        ROJ_date
        fabrication_duration_offset
        fabrication_start_date
        lead_time_offset
        material_PO_date
        material_PO_fabrication_offset
        on_site_storage_offset
        shipment_date
        material_id
        projected_fabrication_start_date
        projected_material_PO_date
        projected_shipment_date
        projected_ROJ_date
        manual_entry
      }
      date_block_materials {
        name_milestone_1
        name_milestone_2
        name_milestone_3
        name_milestone_4
        name_milestone_5
        name_milestone_6
        name_milestone_7
        name_milestone_8
        name_milestone_9
        name_milestone_10
        actual_milestone_1
        actual_milestone_2
        actual_milestone_3
        actual_milestone_4
        actual_milestone_5
        actual_milestone_6
        actual_milestone_7
        actual_milestone_8
        actual_milestone_9
        actual_milestone_10
        name_offset_1
        name_offset_2
        name_offset_3
        name_offset_4
        name_offset_5
        name_offset_6
        name_offset_7
        name_offset_8
        name_offset_9
        name_offset_10
        offset_1
        offset_2
        offset_3
        offset_4
        offset_5
        offset_6
        offset_7
        offset_8
        offset_9
        offset_10
        planned_milestone_1
        planned_milestone_2
        planned_milestone_3
        planned_milestone_4
        planned_milestone_5
        planned_milestone_6
        planned_milestone_7
        planned_milestone_8
        planned_milestone_9
        planned_milestone_10
        manual_entry_date
        float
        id
        material_id
        workflow_template_id
      }
    }
  }
`;

const SUBSCRIPTION_PROJECT_SUBMITTALS = gql`
  subscription ProjectSubmittals($where: submittals_bool_exp!) {
    submittals(where: $where) {
      assignee
      description
      id
      organization
      status
      submittals_status {
        id
        status
      }
      title
      type
      submittal_id
      spec_no
      spec_name
      revision
      submittal_assignee {
        first_name
        last_name
        email
        id
        project_users {
          subscription_vendor {
            name
          }
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      submittal_attachments_aggregate {
        aggregate {
          count
        }
      }
      deleted
      design_reviewer
      design_reviewer_user {
        email
        first_name
        id
        last_name
        project_users {
          subscription_vendor {
            name
          }
        }
      }
      gc_reviewer
      gc_reviewer_user {
        email
        first_name
        id
        last_name
        project_users {
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      submitter
      submitter_user {
        email
        first_name
        last_name
        id
        project_users {
          subscription_vendor {
            name
          }
        }
      }
      responsible_contractor
      responsible_contractor_vendor {
        id
        name
      }
      submittal_status_history_details(
        where: { status: { _is_null: false } }
        order_by: { created_at: desc }
      ) {
        submittal_response_id
        action
        status
        assignee
        comment
        assignee_user {
          id
          email
          first_name
          last_name
        }
      }
      submittal_schedule_links {
        task_id
      }
      submittal_date_block {
        actual_assigned_date
        actual_ext_review_completed_date
        actual_ext_review_submitted_date
        actual_submittal_distributed_date
        actual_trade_partner_submitted_date
      }
    }
  }
`;

const SUBSCRIPTION_PROJECT_SUBMITTALS_AGGREGATE = gql`
  subscription SubmittalAggregate($projectId: uuid!) {
    submittals_aggregate(where: { project_id: { _eq: $projectId } }) {
      aggregate {
        count
      }
    }
  }
`;

const SUBSCRIPTION_MARKUPS_LAYER_PK = gql`
  subscription MySubscription($id: uuid!) {
    attachments_by_pk(id: $id) {
      markup_layers {
        id
        markups {
          id
          data
          page_no
          type
          created_at
          created_by_user {
            first_name
            last_name
          }
        }
      }
    }
  }
`;

const SUBSCRIPTION_ATTACHMENTS = gql`
  subscription AttachmentSubscription($where: attachments_bool_exp!) {
    attachments(where: $where) {
      file_name
      file_size
      submittal_id
      material_id
      id
      blob_key
      version
      uploaded
      created_at
      file_type
      reference_id
      submittal_status_history {
        workflow_status
      }
      created_by_user {
        email
        first_name
        last_name
        project_users {
          subscription {
            name
          }
          subscription_vendor {
            name
          }
        }
      }
      versions {
        id
        blob_key
        version
        uploaded
        file_type
        file_name
        created_at
        submittal_id
        submittal_status_history {
          workflow_status
        }
        created_by_user {
          email
          first_name
          last_name
          project_users {
            subscription {
              name
            }
            subscription_vendor {
              name
            }
          }
        }
      }
      on_behalf_of_user_id
      on_behalf_of
      attachments_user {
        first_name
        id
        last_name
        email
      }
    }
  }
`;

const SUBSCRIPTION_SUBMITTAL_REVISIONS = gql`
  subscription SubmittalSubscription($id: uuid!) {
    submittals_by_pk(id: $id) {
      submittal_revisions
    }
  }
`;

const SUBSCRIPTION_SUBMITTAL_PK = gql`
  subscription SubmittalSubscription($id: uuid!) {
    submittals_by_pk(id: $id) {
      revision
      ROJ
      assignee
      submittal_assignee {
        email
        first_name
        id
        last_name
        project_users {
          subscription_vendor {
            name
          }
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      created_at
      due_date
      description
      id
      issue_date
      organization
      revision
      spec_section_id
      spec_no
      spec_name
      material_tracking
      submittal_manager
      submittal_manager_user {
        email
        first_name
        id
        last_name
        project_users {
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      received_from
      received_from_user {
        email
        first_name
        last_name
        id
        project_users {
          subscription_vendor {
            name
          }
        }
      }
      status
      workflow_status
      submittals_status {
        id
        status
      }
      title
      type
      submittal_id
      submittal_links_source {
        id
        target_id
      }
      design_reviewer
      design_reviewer_user {
        email
        first_name
        id
        last_name
        project_users {
          subscription_vendor {
            name
          }
        }
      }
      gc_reviewer
      gc_reviewer_user {
        email
        first_name
        id
        last_name
        project_users {
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      submitter
      submitter_user {
        email
        first_name
        last_name
        id
        project_users {
          subscription_vendor {
            name
          }
        }
      }
      responsible_contractor
      responsible_contractor_vendor {
        id
        name
      }
      submittal_status_history_details(order_by: { created_at: desc }) {
        id
        status
        comment
        action
        on_behalf_of
        on_behalf_of_user {
          id
          last_name
          first_name
          email
          project_users {
            subscription_vendor {
              name
            }
          }
          subscription_users {
            subscription {
              name
            }
          }
        }
        created_at
        created_by
        user {
          first_name
          email
          last_name
          id
          project_users {
            subscription_vendor {
              name
            }
          }
          subscription_users {
            subscription {
              name
            }
          }
        }
        assignee
        assignee_user {
          id
          email
          first_name
          last_name
          subscription_users {
            subscription {
              name
            }
          }
          project_users {
            subscription_vendor {
              name
            }
          }
        }
        submittal_revision
        submittal_response_id
        submittal_response {
          id
          key
          response
        }
        assignee_unregistered
        on_behalf_of_unregistered
      }
      submittal_date_block {
        ext_review_offset
        final_gc_review_offset
        gc_review_offset
        submittal_prep_offset
        float
        assigned_date
        ext_review_completed_date
        ext_review_submitted_date
        submittal_distributed_date
        trade_partner_submitted_date
        submittal_id
        actual_assigned_date
        actual_ext_review_completed_date
        actual_ext_review_submitted_date
        actual_submittal_distributed_date
        actual_trade_partner_submitted_date
        manual_entry
        projected_assigned_date
        projected_trade_partner_submitted_date
        projected_ext_review_submitted_date
        projected_ext_review_completed_date
        projected_submittal_distributed_date
      }
      date_block_submittals {
        name_milestone_1
        name_milestone_2
        name_milestone_3
        name_milestone_4
        name_milestone_5
        name_milestone_6
        name_milestone_7
        name_milestone_8
        name_milestone_9
        name_milestone_10
        actual_milestone_1
        actual_milestone_2
        actual_milestone_3
        actual_milestone_4
        actual_milestone_5
        actual_milestone_6
        actual_milestone_7
        actual_milestone_8
        actual_milestone_9
        actual_milestone_10
        name_offset_1
        name_offset_2
        name_offset_3
        name_offset_4
        name_offset_5
        name_offset_6
        name_offset_7
        name_offset_8
        name_offset_9
        name_offset_10
        offset_1
        offset_2
        offset_3
        offset_4
        offset_5
        offset_6
        offset_7
        offset_8
        offset_9
        offset_10
        planned_milestone_1
        planned_milestone_2
        planned_milestone_3
        planned_milestone_4
        planned_milestone_5
        planned_milestone_6
        planned_milestone_7
        planned_milestone_8
        planned_milestone_9
        planned_milestone_10
        manual_entry_date

        float
        id
        submittal_id

        effective_float
        final_deadline_date
        planned_last_milestone_date
        planned_next_milestone_date
        today_date
        risk_assessment
        risk_level
      }
      submittal_schedule_links {
        linked_to_end_date
        task_id
        submittal_id
        driving_task
        submittal {
          id
          submittal_id
        }
        gantt_task {
          id
          source_task_id
          text
          start_date
          end_date
        }
      }
      submittal_material_links {
        material_id
        driving_material
        material {
          material_id
          id
          name
          implicit
          actual_ROJ
          actual_fabrication_start_date
          actual_material_release_date
          actual_shipment_date
          material_schedule_links {
            linked_to_end_date
            driving_task
            gantt_task {
              id
              source_task_id
              text
              start_date
            }
          }
          material_date_block {
            ROJ_date
            fabrication_duration_offset
            fabrication_start_date
            lead_time_offset
            material_PO_date
            material_PO_fabrication_offset
            on_site_storage_offset
            shipment_date
            material_id
            projected_fabrication_start_date
            projected_material_PO_date
            projected_shipment_date
            projected_ROJ_date
            manual_entry
          }
          date_block_materials {
            name_milestone_1
            name_milestone_2
            name_milestone_3
            name_milestone_4
            name_milestone_5
            name_milestone_6
            name_milestone_7
            name_milestone_8
            name_milestone_9
            name_milestone_10
            actual_milestone_1
            actual_milestone_2
            actual_milestone_3
            actual_milestone_4
            actual_milestone_5
            actual_milestone_6
            actual_milestone_7
            actual_milestone_8
            actual_milestone_9
            actual_milestone_10
            name_offset_1
            name_offset_2
            name_offset_3
            name_offset_4
            name_offset_5
            name_offset_6
            name_offset_7
            name_offset_8
            name_offset_9
            name_offset_10
            offset_1
            offset_2
            offset_3
            offset_4
            offset_5
            offset_6
            offset_7
            offset_8
            offset_9
            offset_10
            planned_milestone_1
            planned_milestone_2
            planned_milestone_3
            planned_milestone_4
            planned_milestone_5
            planned_milestone_6
            planned_milestone_7
            planned_milestone_8
            planned_milestone_9
            planned_milestone_10
            manual_entry_date

            float
            id
            material_id
            final_deadline_date
            risk_assessment
            risk_level
            workflow_template_id
          }

          spec_section_name
          spec_section_no
          status
          attachments {
            file_name
            id
            blob_key
          }
        }
        material_sequence_id
        material_name
        submittal_id
        submittal_sequence_id
        title
        spec_no
        implicit
      }
      design_reviewer_unregistered
      assignee_unregistered
      design_reviewer_vendor_unregistered
      responsible_contractor_unregistered
      submitter_unregistered

      submittal_participation_from_integrations {
        submittal_participation_type {
          key
        }
        project_integration_user_participant {
          id
          first_name
          last_name
          project_integration_vendor {
            name
          }
        }
        project_integration_vendor_participant {
          id
          name
        }
      }
    }
  }
`;

const SUBSCRIPTION_PROJECT_USERS = gql`
  subscription ProjectUsers($where: project_users_bool_exp) {
    project_users(where: $where) {
      user_id
      subscription_id
      user_type {
        id
        name
      }
      user {
        email
        first_name
        id
        status_id
        last_name
        phone
      }
      project_role {
        id
        key
        name
      }
      subscription_vendor {
        id
        name
        partner_subscription_id
        project_vendors {
          type_id
          project_vendors_company_status {
            status
          }
          project_vendors_type {
            key
            name
            id
          }
        }
      }
      subscription {
        organization_subscriptions {
          organization {
            name
          }
        }
      }
      status_id
      external
    }
  }
`;

const SUBSCRIPTION_PROJECT_USERS_STREAM = gql`
  subscription ProjectUsersStream(
    $where: project_users_bool_exp
    $cursor: [project_users_stream_cursor_input]!
  ) {
    project_users_stream(batch_size: 100, cursor: $cursor, where: $where) {
      user_id
      subscription_id
      user_type {
        id
        name
      }
      user {
        email
        first_name
        id
        status_id
        last_name
        phone
      }
      project_role {
        id
        key
        name
      }
      subscription_vendor {
        id
        name
        partner_subscription_id
        project_vendors {
          type_id
          project_vendors_company_status {
            status
          }
          project_vendors_type {
            key
            name
            id
          }
        }
      }
      subscription {
        organization_subscriptions {
          organization {
            name
          }
        }
      }
      status_id
      external
      external_source_id
      project_integration_users {
        full_source_name
      }
    }
  }
`;

const SUBSCRIPTION_PROJECT_VENDORS = gql`
  subscription ProjectQuery($where: project_vendors_bool_exp = {}) {
    project_vendors(where: $where) {
      vendor_id
      subscription_vendor {
        partner_subscription_id
        name
        project_users {
          external
        }
      }
      type_id
      project_vendors_type {
        id
        name
        key
      }
      status
      project_vendors_company_status {
        status
        id
      }
      external_source_id
    }
  }
`;

const SUBSCRIPTION_LOCATIONS = gql`
  subscription MyLocationQuery {
    location {
      id
      name
      parent_id
    }
  }
`;

const SUBSCRIPTION_ACTIVE_PROJECT_USERS = gql`
  subscription ProjectUsers($where: project_users_bool_exp!) {
    project_users(where: $where) {
      status_id
      user {
        email
        first_name
        id
        status_id
        last_name
        phone
      }
    }
  }
`;

const SUBSCRIPTION_USERS = gql`
  subscription subscription_users {
    subscription_users {
      user_id
      user {
        id
        first_name
        last_name
        email
        phone
        project_users {
          project_integration_users(distinct_on: full_source_name) {
            full_source_name
          }
        }
      }
      subscription_permission_id
      subscription_permission {
        name
      }
      subscription {
        organization_subscriptions {
          organization {
            name
          }
        }
      }
      status_id
      vendor_id
      external_source_id
      subscription_vendor {
        id
        name
      }
    }
  }
`;

const SUBSCRIPTION_USERS_ACTIVE = gql`
  subscription subscription_users {
    subscription_users(where: { status_id: { _nin: [3, 4] } }) {
      user_id
      user {
        id
        first_name
        last_name
        email
        phone
        project_users {
          project_integration_users(distinct_on: full_source_name) {
            full_source_name
          }
        }
      }
      subscription_permission_id
      subscription_permission {
        name
      }
      subscription {
        organization_subscriptions {
          organization {
            name
          }
        }
      }
      status_id
      vendor_id
      external_source_id
      subscription_vendor {
        id
        name
      }
    }
  }
`;

const SUBSCRIPTION_PROJECT_DETAILS = gql`
  subscription ProjectDetails($projectId: uuid!) {
    project(where: { id: { _eq: $projectId } }) {
      id
      name
      updated_by
      updated_at
      status_id
      start_date
      details
      end_date
      created_by
      created_at
      spec_section
    }
  }
`;

const SUBSCRIPTION_SPECIFICATIONS = gql`
  subscription Specification($specificationId: uuid!) {
    specifications(where: { id: { _eq: $specificationId } }) {
      status
    }
  }
`;

const SUBSCRIPTION_PROJECT_ROLES = gql`
  subscription ProjectRole($where: project_role_bool_exp) {
    project_role(where: $where) {
      id
      name
      type_id
      key
    }
  }
`;

const SUBSCRIPTION_USER_SUBSCRIPTION_ROLES = gql`
  subscription UserSubscriptionRole(
    $where: subscription_permission_bool_exp = {}
  ) {
    subscription_permission(where: $where) {
      id
      name
      key
    }
  }
`;

const SUBSCRIPTION_USER_PROFILE_INFO = gql`
  subscription UserProfileInfo($where: subscription_users_bool_exp) {
    subscription_users(where: $where) {
      user {
        first_name
        last_name
        email
        phone
      }
      subscription {
        organization_subscriptions {
          organization {
            name
          }
        }
      }
    }
  }
`;

const SUBSCRIPTION_SUBMITTAL_COMPANY_LIST = gql`
  subscription CompanyList($where: subscription_vendors_bool_exp = {}) {
    subscription_vendors(where: $where) {
      id
      name
      address
    }
  }
`;

const SUBSCRIPTION_COMPANY_LIST = gql`
  subscription CompanyList($where: subscription_vendors_bool_exp = {}) {
    subscription_vendors(where: $where, order_by: { name: asc }) {
      id
      name
      address
      external_source_id
      trade_partner_pocs(where: { deleted: { _eq: false } }) {
        email
        first_name
        last_name
        phone
        id
      }
      project_vendors {
        type_id
        project_vendors_company_status {
          status
        }
      }
    }
  }
`;

const SUBSCRIPTION_LEVEL_COMPANY_LIST = gql`
  subscription CompanyList($where: subscription_vendors_bool_exp = {}) {
    subscription_vendors(where: $where, order_by: { name: asc }) {
      id
      name
      address
      external_source_id
      trade_partner_pocs(where: { deleted: { _eq: false } }) {
        email
        first_name
        last_name
        phone
        id
      }
    }
  }
`;

const SUBSCRIPTION_ATTACHMENT_MARKUP_REF = gql`
  subscription MyQuery($reference_id: uuid!) {
    attachments(where: { reference_id: { _eq: $reference_id } }) {
      id
    }
  }
`;

const SUBSCRIPTION_ORGANIZATIONS = gql`
  subscription Organizations {
    organization {
      id
      name
      domain
    }
  }
`;

const SUBSCRIPTION_SUBMITTAL_TYPES = gql`
  subscription Organizations {
    configurations_value {
      value
      id
      configuration_master_id
    }
  }
`;

const SUBSCRIPTION_SUBMITTAL_STATUSES = gql`
  subscription GetSubmittalStatus {
    submittal_status {
      id
      key
      name
    }
  }
`;

const SUBSCRIPTION_PROJECT_SUBMITTALS_TITLE_ID = gql`
  subscription GetSubmittal($where: submittals_bool_exp!) {
    submittals(where: $where, order_by: { submittal_id: asc }) {
      submittal_id
      id
      title
    }
  }
`;

const SUBSCRIPTION_SCHEDULER_LIST = gql`
  subscription GetSchedulerList {
    gantt_tasks {
      id
      type
      text
      duration
      end_date
      start_date
      source_task_id
      parent
    }
  }
`;

const SUBSCRIPTION_SCHEDULER_TASK_LINK_LIST = gql`
  subscription GetSchedulerTaskLinkList {
    gantt_links {
      id
      source
      target
      type
    }
  }
`;

const SUBMITTAL_WORKFLOW_RESPONSES = gql`
  subscription SubmittalReviewResponse {
    submittal_response {
      key
      response
      id
    }
  }
`;

const SUBSCRIPTION_CALENDAR_LIST = gql`
  subscription CalendarLis($projectId: uuid) {
    gantt_calendar(
      where: { deleted: { _eq: false }, project_id: { _eq: $projectId } }
      order_by: { created_at: desc }
    ) {
      id
      name
      description
      calendar_holiday_lists(where: { deleted: { _eq: false } }) {
        id
        country_code
        holiday_name
        date
      }
      work_shifts {
        id
        hours
        name
      }
      calendar_shift_mapping {
        id
        deleted
        work_day
        work_day_mapping {
          name
        }
        work_shift {
          name
          hours
        }
      }
    }
  }
`;

const SUBSCRIPTION_DISTRIBUTION_GROUP_LIST = gql`
  subscription DistributionGroups($where: distribution_group_bool_exp!) {
    distribution_group(where: $where) {
      name
      id
      distribution_group_user {
        user {
          id
          email
          first_name
          last_name
          project_users {
            status_id
            project_role {
              name
            }
            subscription {
              organization_subscriptions {
                organization {
                  name
                }
              }
            }
            subscription_vendor {
              name
            }
          }
        }
      }
    }
  }
`;

const SUBSCRIPTION_GET_SUBMITTAL_DL_USERS = gql`
  subscription fetchSubmittalDistributionList(
    $where: submittal_distribution_list_bool_exp!
  ) {
    submittal_distribution_list(where: $where) {
      user_id
      dl_id
    }
  }
`;

const SUBSCRIPTION_PROJECT_MATERIALS = gql`
  subscription ProjectMaterials {
    material(where: { implicit: { _neq: true } }) {
      id
      material_id
      name
      spec_section_no
      spec_section_name
    }
  }
`;

const SUBSCRIPTION_GET_SCHEDULE_VERSIONS = gql`
  subscription ScheduleVersions {
    schedule_import_details(
      order_by: { created_at: desc }
      where: { import_log: { import_type: { _eq: SCHEDULE } } }
    ) {
      id
      import_log_id
      is_active
      schedule_name
      schedule_date
      schedule_type
      activated_at
      created_at
      import_log {
        id
        resolved_values_s3_prefix
        created_at
        created_by_user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const SUBSCRIPTION_IMPORT_FILE_PROCESSING = gql`
  subscription SubscribeToImportLog($where: import_log_bool_exp!) {
    import_log(where: $where) {
      status
      status_comment
    }
  }
`;

const SUBSCRIPTION_MATERIAL_PK = gql`
  subscription MaterialByPK($id: uuid!) {
    material_by_pk(id: $id) {
      actual_ROJ
      actual_fabrication_start_date
      actual_material_release_date
      actual_shipment_date
      assignee
      id
      material_id
      name
      spec_section_name
      spec_section_no
      status
      part_number
      serial_number
      description
      warranty
      manufacturer
      quantity
      material_size
      tag_name
      created_on
      created_by_user {
        email
        first_name
        last_name
      }
      material_quantity_unit {
        measurement
        unit
        id
      }
      material_size_unit {
        id
        measurement
        unit
      }
      assignee_unregistered
      material_assignee {
        first_name
        email
        id
        last_name
        project_users {
          subscription_vendor {
            name
          }
          subscription {
            organization_subscriptions {
              organization {
                name
              }
            }
          }
        }
      }
      trade_partner
      materials_trade_partner {
        name
        id
      }
      material_schedule_links {
        linked_to_end_date
        driving_task
        gantt_task {
          id
          source_task_id
          text
          start_date
          end_date
        }
      }
      material_date_block {
        material_id
        ROJ_date
        fabrication_duration_offset
        fabrication_start_date
        lead_time_offset
        material_PO_date
        material_PO_fabrication_offset
        on_site_storage_offset
        shipment_date
        projected_fabrication_start_date
        projected_material_PO_date
        projected_shipment_date
        projected_ROJ_date
        manual_entry
      }
      date_block_materials {
        name_milestone_1
        name_milestone_2
        name_milestone_3
        name_milestone_4
        name_milestone_5
        name_milestone_6
        name_milestone_7
        name_milestone_8
        name_milestone_9
        name_milestone_10
        actual_milestone_1
        actual_milestone_2
        actual_milestone_3
        actual_milestone_4
        actual_milestone_5
        actual_milestone_6
        actual_milestone_7
        actual_milestone_8
        actual_milestone_9
        actual_milestone_10
        name_offset_1
        name_offset_2
        name_offset_3
        name_offset_4
        name_offset_5
        name_offset_6
        name_offset_7
        name_offset_8
        name_offset_9
        name_offset_10
        offset_1
        offset_2
        offset_3
        offset_4
        offset_5
        offset_6
        offset_7
        offset_8
        offset_9
        offset_10
        planned_milestone_1
        planned_milestone_2
        planned_milestone_3
        planned_milestone_4
        planned_milestone_5
        planned_milestone_6
        planned_milestone_7
        planned_milestone_8
        planned_milestone_9
        planned_milestone_10
        manual_entry_date

        float
        id
        material_id

        effective_float
        final_deadline_date
        planned_last_milestone_date
        planned_next_milestone_date
        today_date
        risk_assessment
        risk_level
        workflow_template_id
      }

      submittal_material_links {
        submittal_id
        submittal {
          submittal_id
          title
          id
          submittal_status_history_details(order_by: { created_at: desc }) {
            status
          }
          submittal_material_links {
            driving_material
          }
          submittal_schedule_links {
            driving_task
            gantt_task {
              id
              source_task_id
              text
            }
          }
          submittal_date_block {
            submittal_id
            actual_assigned_date
            actual_ext_review_completed_date
            actual_ext_review_submitted_date
            actual_submittal_distributed_date
            actual_trade_partner_submitted_date
            assigned_date
            ext_review_completed_date
            ext_review_offset
            ext_review_submitted_date
            final_gc_review_offset
            float
            gc_review_offset
            submittal_distributed_date
            submittal_prep_offset
            trade_partner_submitted_date
            manual_entry
            projected_assigned_date
            projected_trade_partner_submitted_date
            projected_ext_review_submitted_date
            projected_ext_review_completed_date
            projected_submittal_distributed_date
          }

          date_block_submittals {
            name_milestone_1
            name_milestone_2
            name_milestone_3
            name_milestone_4
            name_milestone_5
            name_milestone_6
            name_milestone_7
            name_milestone_8
            name_milestone_9
            name_milestone_10
            actual_milestone_1
            actual_milestone_2
            actual_milestone_3
            actual_milestone_4
            actual_milestone_5
            actual_milestone_6
            actual_milestone_7
            actual_milestone_8
            actual_milestone_9
            actual_milestone_10
            name_offset_1
            name_offset_2
            name_offset_3
            name_offset_4
            name_offset_5
            name_offset_6
            name_offset_7
            name_offset_8
            name_offset_9
            name_offset_10
            offset_1
            offset_2
            offset_3
            offset_4
            offset_5
            offset_6
            offset_7
            offset_8
            offset_9
            offset_10
            planned_milestone_1
            planned_milestone_2
            planned_milestone_3
            planned_milestone_4
            planned_milestone_5
            planned_milestone_6
            planned_milestone_7
            planned_milestone_8
            planned_milestone_9
            planned_milestone_10
            manual_entry_date

            float
            id
            submittal_id
            risk_assessment
            risk_level
          }
          status
          workflow_status
          submittals_status {
            id
            status
          }
          submittal_attachments {
            version_of
            submittal_revision
            file_name
            id
          }
          revision
          spec_section_id
          spec_no
          spec_name
          type
        }

        material_id
        material_sequence_id
        material_name
        submittal_id
        submittal_sequence_id
        title
        spec_no
      }
    }
  }
`;

const SUBSCRIPTION_GC_PROJECT_DASHBOARD = gql`
  subscription ProjectDashboard {
    project_gc_dashboard_func {
      count
      item_type
      status
    }
  }
`;

const SUBSCRIPTION_SC_PROJECT_DASHBOARD = gql`
  subscription ProjectDashboard {
    project_sc_dashboard_func {
      count
      item_type
      status
    }
  }
`;

const SUBSCRIPTION_ARCH_PROJECT_DASHBOARD = gql`
  subscription ProjectDashboard {
    project_arch_dashboard_func {
      count
      item_type
      status
    }
  }
`;

const SUBSCRIPTION_LATEST_SCHED_IMPORTS = gql`
  subscription LatestScheduleImportDetails {
    schedule_import_details(order_by: { id: desc }) {
      created_at
      schedule_name
      schedule_date
      is_active
      activated_at
      import_log {
        id
      }
    }
  }
`;

const SUBSCRIPTION_SCHEDULE_ALERT = gql`
  subscription ScheduleAlertDetails {
    gantt_tasks_aggregate(
      where: {
        _and: [
          {
            _or: [
              {
                material_schedule_links_aggregate: {
                  count: { predicate: { _gt: 0 } }
                }
              }
              {
                submittal_schedule_links_aggregate: {
                  count: { predicate: { _gt: 0 } }
                }
              }
            ]
          }
          { latest_imported_schedule_impact: { _is_null: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const SUBSCRIPTION_SUBMITTAL_LIST_INTEGRATION_MODE = gql`
  subscription SubmittalListIntegrationModeBenchmarking {
    submittal_list_intg_mode_func_benchmarking {
      actual_assigned_date
      actual_ext_review_completed_date
      actual_ext_review_submitted_date
      actual_submittal_distributed_date
      actual_trade_partner_submitted_date
      assignee_unregistered
      assignee_user_id
      attachment_count
      created_by
      description
      design_reviewer_unregistered
      design_reviewer_user_id
      gc_reviewer_user_id
      history
      id
      materials
      planned_ext_review_completed_date
      planned_submittal_distributed_date
      planned_trade_partner_submitted_date
      project_id
      responsible_contractor_id
      responsible_contractor_name
      responsible_contractor_unregistered
      revision
      snippet_coordinates
      snippet_file_key
      spec_name
      spec_no
      state
      state_numeric
      submittal_sequence_id
      submitter_unregistered
      submitter_user_id
      tasks
      title
      type
      workflow_status
    }
  }
`;

const SUBSCRIPTION_HISTORY = gql`
  subscription HistoryQuery($where: History_bool_exp!) {
    History(where: $where, order_by: { created_at: desc }) {
      id
      field
      label
      old_value
      new_value
      user_created {
        email
        first_name
        last_name
      }
      created_at
      project_id
      new_subscription_vendor {
        name
      }
      old_subscription_vendor {
        name
      }
      user_old {
        email
        first_name
        last_name
      }
      user_new {
        email
        first_name
        last_name
      }
    }
  }
`;

const SUBSCRIPTION_TASK_DETAIL_BY_TASK_ID = gql`
  subscription SUBSCRIPTION_TASK_DETAIL_BY_TASK_ID($id: uuid!) {
    gantt_tasks_by_pk(id: $id) {
      active
      assignee
      duration
      end_date
      status
      text
      type
      progress
      start_date
    }
  }
`;
const SUBSCRIPTION_PROJECT_DETAILS_PK_SETTINGS = gql`
  subscription ProjectDetails($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      id
      mode
      spec_section
      name
      timezone {
        timezone_id
        name
      }
    }
  }
`;

const SUBSCRIPTION_PROJECT_INTEGRATION = gql`
  subscription GetProjectIntegrationKey {
    external_integrations {
      integration_key
      configuration
    }
  }
`;

const SUBSCRIPTION_PROJECT_INTEGRATION_USERS = gql`
  subscription GetProjectIntegrationUser {
    project_integration_users {
      id
      email
      first_name
      last_name
      vendor_id
      user {
        project_users {
          status_id
        }
      }
      project_integration_vendor {
        id
        name
      }
    }
  }
`;

const SUBSCRIPTION_SPEC_PAGE_EXTRACTS = gql`
  subscription SpecPageExtractsQuery {
    spec_page_extracts {
      id
      bb_x1
      bb_x2
      bb_y1
      bb_y2
      page_no
      metadata
      spec_page {
        id
        width
        height
        page_file_key
        specification {
          title
          spec_text_analysis_stat {
            spec_file_key
          }
        }
      }
      materials_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export {
  SUBSCRIPTION_PROJECTS,
  SUBSCRIPTION_PROJECTS_PK,
  SUBSCRIPTION_PROJECT_OFFSET_DEFAULT_PK,
  SUBSCRIPTION_PROJECT_SPECS,
  SUBSCRIPTION_PROJECT_SUBMITTALS,
  SUBSCRIPTION_SUBMITTALS_LINKING_TABLE,
  SUBSCRIPTION_PROJECT_SUBMITTALS_AGGREGATE,
  SUBSCRIPTION_SUBMITTAL_PK,
  SUBSCRIPTION_SUBMITTAL_REVISIONS,
  SUBSCRIPTION_PROJECT_USERS,
  SUBSCRIPTION_PROJECT_USERS_STREAM,
  SUBSCRIPTION_PROJECT_VENDORS,
  SUBSCRIPTION_USERS,
  SUBSCRIPTION_USERS_ACTIVE,
  SUBSCRIPTION_PROJECT_DETAILS,
  SUBSCRIPTION_SPECIFICATIONS,
  SUBSCRIPTION_PROJECT_ROLES,
  SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
  SUBSCRIPTION_USER_PROFILE_INFO,
  SUBSCRIPTION_MARKUPS_LAYER_PK,
  SUBSCRIPTION_COMPANY_LIST,
  SUBSCRIPTION_ATTACHMENT_MARKUP_REF,
  SUBSCRIPTION_ORGANIZATIONS,
  SUBSCRIPTION_SUBMITTAL_TYPES,
  SUBSCRIPTION_SUBMITTAL_STATUSES,
  SUBSCRIPTION_PROJECT_SUBMITTALS_TITLE_ID,
  SUBSCRIPTION_ATTACHMENTS,
  SUBSCRIPTION_SCHEDULER_LIST,
  SUBSCRIPTION_SCHEDULER_TASK_LINK_LIST,
  SUBMITTAL_WORKFLOW_RESPONSES,
  SUBSCRIPTION_CALENDAR_LIST,
  SUBSCRIPTION_DISTRIBUTION_GROUP_LIST,
  SUBSCRIPTION_GET_SUBMITTAL_DL_USERS,
  SUBSCRIPTION_ACTIVE_PROJECT_USERS,
  SUBSCRIPTION_GET_SCHEDULE_VERSIONS,
  SUBSCRIPTION_LOCATIONS,
  SUBSCRIPTION_PROJECT_MATERIALS,
  SUBSCRIPTION_IMPORT_FILE_PROCESSING,
  SUBSCRIPTION_MATERIAL_PK,
  SUBSCRIPTION_GC_PROJECT_DASHBOARD,
  SUBSCRIPTION_SC_PROJECT_DASHBOARD,
  SUBSCRIPTION_ARCH_PROJECT_DASHBOARD,
  SUBSCRIPTION_LATEST_SCHED_IMPORTS,
  SUBSCRIPTION_SCHEDULE_ALERT,
  SUBSCRIPTION_SUBMITTAL_COMPANY_LIST,
  SUBSCRIPTION_SUBMITTALS_LINKING_BY_ID,
  SUBSCRIPTION_MATERIAL_LINKING_BY_ID,
  SUBSCRIPTION_HISTORY,
  SPECIFICATIONS_PROJECT_SPECS,
  SUBSCRIPTION_LEVEL_COMPANY_LIST,
  SUBSCRIPTION_TASK_DETAIL_BY_TASK_ID,
  SUBSCRIPTION_PROJECT_DETAILS_PK_SETTINGS,
  SUBSCRIPTION_PROJECT_INTEGRATION,
  SUBSCRIPTION_PROJECT_INTEGRATION_USERS,
  SUBSCRIPTION_SUBMITTAL_LIST_INTEGRATION_MODE,
  SUBSCRIPTION_NEW_PROJECT_OFFSET_DEFAULT,
  SUBSCRIPTION_PROJECT_FEATURE_CONFIGURATION,
  SUBSCRIPTION_SPEC_PAGE_EXTRACTS
};
