/* eslint-disable react/no-unstable-nested-components */
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SUBSCRIPTION_PK_DETAILS } from "admin-app/services/ciq-queries";
import { Button } from "antd";
import { useQuerySubscription } from "hooks/common";
import { useHistory, useParams } from "react-router";
import { getGQL } from "utils/utils";
import { TSubscriptionDetail } from "admin-app/models";
import AuditSubProjectFeatureEvents from "./components/audit-sub-proj-feature-events";

function SubscriptionDetail() {
  const { subId } = useParams() as any;
  const history = useHistory();
  const { query: qQuery, sub: sQuery } = getGQL(SUBSCRIPTION_PK_DETAILS);
  const { data: subscriptionPK } = useQuerySubscription<{
    subscription_by_pk: TSubscriptionDetail;
  }>(qQuery, sQuery, {
    variables: { subscription_id: subId }
  });

  const organization = subscriptionPK?.subscription_by_pk
    ?.organization_subscriptions.length
    ? subscriptionPK?.subscription_by_pk?.organization_subscriptions[0]
        .organization
    : { id: "", name: "" };

  return (
    <div className="flex flex-col h-full p-2">
      <div className="flex w-full px-1 justify-between">
        <Button
          icon={<ArrowLeftOutlined />}
          className="!bg-transparent !border-0 p-0 !shadow-none uppercase font-medium items-center"
          onClick={() => {
            history.push(`/organizations/${organization.id}`);
          }}
        >
          {subscriptionPK?.subscription_by_pk?.name}
        </Button>
        <div className="p-1 flex uppercase items-center space-x-1">
          <div>organization :</div>
          <div className="font-medium ">{organization.name}</div>
        </div>
      </div>
      <AuditSubProjectFeatureEvents
        subscriptionPK={subscriptionPK}
        subId={subId}
      />
    </div>
  );
}
export default SubscriptionDetail;
