import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MUTATION_ACCEPT_PROJECT_INVITE } from "services/graphQL/mutations";
import {
  SUBSCRIPTION_PROJECTS_PK,
  SUBSCRIPTION_PROJECT_ROLES,
  SUBSCRIPTION_PROJECT_USERS,
  SUBSCRIPTION_PROJECT_USERS_STREAM
} from "services/graphQL/subscriptions";
import {
  QUERY_PROJECT_USERS,
  QUERY_RISK_REPORT_SUMMARY
} from "services/graphQL/queries";
import { mergeArrays } from "utils/utils";
import { useGetGQLClientForProject } from "./init-gql-client";
import { RiskStates } from "../constants";
import { useCIQQuery } from "./ciq-gql-hooks";

export const useProjectPK = (projectId: string, client: any = undefined) => {
  const { data, loading, error } = useSubscription(SUBSCRIPTION_PROJECTS_PK, {
    onComplete: () => {
      console.log("onComplete called");
    },
    shouldResubscribe: true,
    variables: { id: projectId },
    client
  });

  return { loading, error, data };
};

export const useProjectUsersList = (
  GQLClient: any,
  projectId: string,
  vendorData: any,
  skip: boolean
) => {
  const variables: any = {
    where: { project_id: { _eq: projectId } }
  };
  if (vendorData) {
    console.log(vendorData);
    // eslint-disable-next-line no-underscore-dangle
    variables.where._or = [
      { external: { _eq: false } },
      {
        subscription_vendor: {
          id: { _eq: vendorData.project_users[0].subscription_vendor.id }
        }
      }
    ];
  }
  const { data, loading, error } = useSubscription(SUBSCRIPTION_PROJECT_USERS, {
    variables,
    client: GQLClient,
    shouldResubscribe: true,
    skip
  });

  return { loading, error, data };
};

export const useProjectUsersStream = (params: {
  client: any;
  variables: any;
  skip?: boolean;
}) => {
  const { client, variables, skip = false } = params;

  const [projectUsersData, setProjectUsersData] = useState<any>(null);

  // start with a query
  const {
    data: projectUsersQueryData,
    error: projectUsersQueryError,
    loading: projectUsersQueryLoading
  } = useQuery(QUERY_PROJECT_USERS, {
    fetchPolicy: "network-only",
    variables,
    client,
    skip
  });

  // console.log(projectQueryData);

  const subscriptionparams = {
    client,
    variables: {
      ...variables,
      cursor: {
        initial_value: {
          updated_at: projectUsersQueryData?.project_users[0].updated_at
        }
      }
    },
    skip: skip || !projectUsersQueryData
  };

  // then get a subscription
  const {
    data: projectUserStreamData,
    error: projectUsersStreamError,
    loading: projectUsersStreamLoading
  } = useSubscription(SUBSCRIPTION_PROJECT_USERS_STREAM, subscriptionparams);

  useEffect(() => {
    if (projectUsersQueryData) {
      // console.log("data from query: ", projectUsersQueryData);
      setProjectUsersData(projectUsersQueryData);
    }
  }, [projectUsersQueryData]);

  useEffect(() => {
    if (projectUsersQueryData && projectUserStreamData) {
      // console.log("data from subscription stream: ", projectUserStreamData);
      const updatedArray = mergeArrays(
        projectUsersQueryData.project_users,
        projectUserStreamData.project_users_stream,
        "user_id"
      );

      setProjectUsersData({ project_users: updatedArray });
    }
  }, [projectUsersQueryData, projectUserStreamData]);

  return {
    data: projectUsersData,
    streamError: projectUsersStreamError,
    queryError: projectUsersQueryError,
    loading: projectUsersQueryLoading || projectUsersStreamLoading
  };
};

export const useTradePartnerList = (
  GQLClient: any,
  projectId: string | undefined,
  isExternalUser: boolean
) => {
  const { data, loading, error } = useSubscription(SUBSCRIPTION_PROJECT_USERS, {
    variables: {
      where: {
        project_id: { _eq: projectId },
        external: { _eq: isExternalUser }
      }
    },
    client: GQLClient,
    shouldResubscribe: true,
    skip: !GQLClient || !projectId
  });

  return { loading, error, data };
};

export const useProjectRoleList = () => {
  const { data, loading, error } = useSubscription(SUBSCRIPTION_PROJECT_ROLES, {
    shouldResubscribe: true
  });

  return { loading, error, data };
};

export const useAcceptProjectInvite = () => {
  const { gqlClientForProject, setIdValues, tokenRetrievalState } =
    useGetGQLClientForProject();

  const [acceptProjectInviteMutation] = useMutation<any>(
    MUTATION_ACCEPT_PROJECT_INVITE,
    {
      client: gqlClientForProject
    }
  );

  const [acceptanceState, setAcceptanceState] = useState({
    isLoading: false,
    error: "",
    isCompleted: false
  });

  const accept = useCallback(async () => {
    const acceptResponse = await acceptProjectInviteMutation();
    console.log("acceptResponse ", acceptResponse);
    if (acceptResponse.data.update_project_users.affected_rows === 1) {
      setAcceptanceState({
        isLoading: false,
        error: "",
        isCompleted: true
      });
    } else {
      setAcceptanceState({
        isLoading: false,
        error: "An error occured while accepting the project invite",
        isCompleted: true
      });
    }
  }, [acceptProjectInviteMutation]);

  useEffect(() => {
    if (tokenRetrievalState?.success && gqlClientForProject) {
      accept();
    }

    if (tokenRetrievalState?.error) {
      setAcceptanceState({
        isLoading: false,
        error: tokenRetrievalState.msg,
        isCompleted: true
      });
    }
  }, [accept, gqlClientForProject, tokenRetrievalState]);

  const acceptProjectInvite = (projectId: string) => {
    setAcceptanceState({
      isLoading: true,
      error: "",
      isCompleted: false
    });
    setIdValues({
      projectId
    });
  };

  return { acceptProjectInvite, acceptanceState };
};

export const useProjectReportSummary = (params: any) => {
  const { gqlClientForProject, eventLogs } = params;

  const { data: subSubmittalRiskData, refetch } = useCIQQuery(
    QUERY_RISK_REPORT_SUMMARY,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  useEffect(() => {
    if (eventLogs?.length && eventLogs?.length > 0) {
      refetch();
    }
  }, [eventLogs, refetch]);

  const riskSummaryData: any = useMemo(() => {
    if (!subSubmittalRiskData) return null;
    const riskData =
      subSubmittalRiskData.project_gc_cumulative_risk_report_summary_func;
    const highRiskSubmittal = riskData.find((item: any) => {
      return (
        item.item_type === "Submittals" && item.risk_level === RiskStates.HIGH
      );
    });
    const mediumRiskSubmittal = riskData.find((item: any) => {
      return (
        item.item_type === "Submittals" && item.risk_level === RiskStates.MEDIUM
      );
    });
    const lowRiskSubmittal = riskData.find((item: any) => {
      return (
        item.item_type === "Submittals" && item.risk_level === RiskStates.LOW
      );
    });
    const highRiskMaterial = riskData.find((item: any) => {
      return (
        item.item_type === "Materials" && item.risk_level === RiskStates.HIGH
      );
    });
    const mediumRiskMaterial = riskData.find((item: any) => {
      return (
        item.item_type === "Materials" && item.risk_level === RiskStates.MEDIUM
      );
    });
    const lowRiskMaterial = riskData.find((item: any) => {
      return (
        item.item_type === "Materials" && item.risk_level === RiskStates.LOW
      );
    });

    const risk: any = {
      submittal: {
        high: highRiskSubmittal ? highRiskSubmittal.count : 0,
        medium: mediumRiskSubmittal ? mediumRiskSubmittal.count : 0,
        low: lowRiskSubmittal ? lowRiskSubmittal.count : 0
      },
      material: {
        high: highRiskMaterial ? highRiskMaterial.count : 0,
        medium: mediumRiskMaterial ? mediumRiskMaterial.count : 0,
        low: lowRiskMaterial ? lowRiskMaterial.count : 0
      }
    };
    return risk;
  }, [subSubmittalRiskData]);

  return riskSummaryData;
};
