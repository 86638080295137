import { Button, Form, message, Modal, Select, Spin, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import { AppLogoIcon } from "components/svg-icons";
import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getTokenForProject, getTokenForSubscription } from "services/auth";
import {
  MUTATION_ACCEPT_PROJECT_INVITE,
  MUTATION_UPDATE_PROJECT_VENDOR
} from "services/graphQL/mutations";
import "../login/login.css";
import { getGraphQLClient } from "services/graphQL";

const { Title } = Typography;

function AcceptInvitePage() {
  const [modal, contextHolder] = Modal.useModal();
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const token = params.get("token");
  const projectId = params.get("projectId");
  const [subscriptionForm] = Form.useForm();

  const [userSubscriptions, setUserSubscriptions] = useState<Array<any>>();
  const [loader, setLoader] = useState(false);

  const errorMessage = useCallback(
    (content: string) => {
      modal.error({
        content,
        onOk() {
          history.push("/");
        }
      });
    },
    [history, modal]
  );

  const acceptAndUpdateVendor = useCallback(
    async (projectID: string, partnerSubscriptionId: string) => {
      try {
        const projectTokenResponse = await getTokenForProject({
          projectId: projectID
        });

        if (projectTokenResponse.error) {
          errorMessage("Failed to Accept Project");
          console.log(projectTokenResponse.error);
          return;
        }

        const jwt: any = jwtDecode(projectTokenResponse.data.success.token);
        const vendorId =
          jwt["https://hasura.io/jwt/claims"]["x-hasura-vendor-id"];

        const graphQlInstance = getGraphQLClient({
          token: projectTokenResponse.data.success.token
        });
        const acceptResponse = await graphQlInstance.GQLClient.mutate({
          mutation: MUTATION_ACCEPT_PROJECT_INVITE
        });

        if (acceptResponse.errors) {
          errorMessage("Failed to Accept Project");
          console.log(acceptResponse.errors[0].message);
          return;
        }
        const updateVendorResponse = await graphQlInstance.GQLClient.mutate({
          mutation: MUTATION_UPDATE_PROJECT_VENDOR,
          variables: {
            where: {
              project_id: { _eq: projectId },
              vendor_id: { _eq: vendorId }
            },
            _set: {
              partner_subscription_id: partnerSubscriptionId
            }
          }
        });
        if (updateVendorResponse.data) {
          message.success("Accepted project Invitation.");
          history.push(`/project/${projectId}/dashboard`);
        }
        if (updateVendorResponse.errors) {
          errorMessage("Failed to Accept Project");
          console.log(updateVendorResponse.errors[0].message);
        }
      } catch (err) {
        console.error(err);
        errorMessage("Something went wrong!");
      }
    },
    [errorMessage, history, projectId]
  );

  const onSubscriptionFinish = async ({
    subscriptionId
  }: {
    subscriptionId: string;
  }) => {
    setLoader(true);
    try {
      const subTokenRes: any = await getTokenForSubscription({
        subscriptionId,
        loginInfo: { token }
      });
      if (subTokenRes.status === 200) {
        await acceptAndUpdateVendor(projectId!, subscriptionId);
      } else {
        errorMessage("Subscription token error");
      }
    } catch (ex) {
      errorMessage("Subscription token error");
    }
    setLoader(false);
  };

  useEffect(() => {
    const getSubscriptionsOfUser = async (tokenSubscriptionId: string) => {
      try {
        if (projectId && token) {
          const subTokenRes: any = await getTokenForSubscription({
            loginInfo: { token },
            subscriptionId: tokenSubscriptionId
          });
          if (subTokenRes.status === 200) {
            const subscriptionList: Array<{ name: string; id: string }> =
              subTokenRes.data.success.associated_subscriptions?.filter(
                (x: any) => x.vendor_id === null
              );
            if (subscriptionList.length > 1) {
              setUserSubscriptions(subscriptionList);
              subscriptionForm.setFieldsValue({
                subscriptionId: subscriptionList[0].id
              });
            } else {
              await acceptAndUpdateVendor(projectId!, tokenSubscriptionId);
            }
          } else {
            errorMessage("Subscription token error");
          }
        }
      } catch (err) {
        console.error(err);
        errorMessage("Something went wrong!");
      }
    };

    if (token && projectId) {
      try {
        const jwt: any = jwtDecode(token);
        if (!jwt) return;
        const expirationTime = jwt.exp * 1000 - 60000;
        if (Date.now() >= expirationTime) {
          errorMessage("Expired Token");
          return;
        }
        const tokenSubscriptionId =
          jwt["https://hasura.io/jwt/claims"]["x-hasura-subscription-id"];
        getSubscriptionsOfUser(tokenSubscriptionId);
      } catch (err: any) {
        errorMessage("Invalid token specified");
      }
    } else {
      errorMessage("Token or Project Id is missing");
    }
  }, [acceptAndUpdateVendor, errorMessage, projectId, subscriptionForm, token]);

  return (
    <>
      {userSubscriptions ? (
        <div className="login">
          <div className="login-form">
            <div style={{ margin: "auto", display: "block" }}>
              <AppLogoIcon />
            </div>
            <div className="text-base py-2 px-1 justify-center flex">
              <Title level={3} className="mt-3">
                Project Invitation
              </Title>
            </div>
            <div className="px-1">
              <Form
                onFinish={(subscriptionData: any) => {
                  onSubscriptionFinish(subscriptionData);
                }}
                form={subscriptionForm}
                labelCol={{ span: 50 }}
                wrapperCol={{ span: 30 }}
                scrollToFirstError
                layout="vertical"
              >
                <FormItem name="subscriptionId" label="Choose a subscription">
                  <Select
                    className="w-full"
                    onChange={() => {}}
                    options={userSubscriptions.map((sub: any) => {
                      return {
                        value: sub.id,
                        label: sub.name
                      };
                    })}
                  />
                </FormItem>

                <div
                  style={{ paddingTop: "1em" }}
                  className="w-full grid grid-cols-3 content-center"
                >
                  <div className="flex items-center" />
                  <div className="flex justify-center">
                    <Button
                      htmlType="submit"
                      size="middle"
                      loading={loader}
                      disabled={loader}
                    >
                      Continue
                    </Button>
                  </div>
                  <div />
                </div>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Spin tip="Loading" size="large" />
        </div>
      )}

      {contextHolder}
    </>
  );
}

export default AcceptInvitePage;
