import { useHistory, useParams } from "react-router";
import { Tabs } from "antd";
import { useContext, useState } from "react";
import "../../project-setting-menu/module-settings/module-settings.scss";
import { SubscriptionContext } from "context/SubscriptionProvider";
import DemoTextIcon from "components/svg-icons/demo-icon";
import FeatureSubscriptionSettings from "./feature-subscription-settings";

function ModuleSubscriptionSettings() {
  const history = useHistory();
  const { tab2Id } = useParams() as any;

  const { isFeatureFlagEnabled } = useContext(SubscriptionContext);

  const [tabIndex, setTabIndex] = useState<string>(tab2Id || "materials");
  const tabItems = [
    {
      label: "Materials",
      key: "materials",
      children: <FeatureSubscriptionSettings />
    },
    ...(isFeatureFlagEnabled("BUYOUT")
      ? [
          {
            label: (
              <span>
                <DemoTextIcon /> Bid Packages
              </span>
            ),
            key: "bidpackages",
            children: <FeatureSubscriptionSettings />
          }
        ]
      : [])
  ];
  return (
    <div className="w-full py-1 h-full">
      <Tabs
        onChange={(activeKey) => {
          history.push(`/settings/modules/${activeKey.toString()}`);
          setTabIndex(activeKey);
        }}
        activeKey={tabIndex}
        items={tabItems}
        destroyInactiveTabPane
      />
    </div>
  );
}
export default ModuleSubscriptionSettings;
