import { IconProps } from "./submittal-icon-2";

function NoLinkIcon({
  className = "",
  onClick,
  style,
  fill = "#3B3B3B",
  size = 24,
  title = "Activity"
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={fill}
      className={className}
      onClick={onClick}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      xlinkTitle="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_62_177)">
        <path d="M10 16.6665C13.6819 16.6665 16.6667 13.6818 16.6667 9.99984C16.6667 6.31794 13.6819 3.33317 10 3.33317C6.31812 3.33317 3.33335 6.31794 3.33335 9.99984C3.33335 13.6818 6.31812 16.6665 10 16.6665ZM10 18.3332C5.39764 18.3332 1.66669 14.6022 1.66669 9.99984C1.66669 5.39746 5.39764 1.6665 10 1.6665C14.6024 1.6665 18.3334 5.39746 18.3334 9.99984C18.3334 14.6022 14.6024 18.3332 10 18.3332ZM10 11.6665C9.07952 11.6665 8.33335 10.9203 8.33335 9.99984C8.33335 9.07934 9.07952 8.33317 10 8.33317C10.9205 8.33317 11.6667 9.07934 11.6667 9.99984C11.6667 10.9203 10.9205 11.6665 10 11.6665Z" />
      </g>
    </svg>
  );
}

export default NoLinkIcon;
