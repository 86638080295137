import { Button } from "antd";
import { ProjectIntegrationsTabSharedContext } from "pages/project-setting-menu/project-integrations/project-integrations-tab-context";
import { useContext } from "react";
import { useHistory } from "react-router";

export default function ProjectIntegrationListMessage(params: {
  onConfigureClick: () => void;
}) {
  const { onConfigureClick } = params;
  const history = useHistory();
  const { sharedState } = useContext(ProjectIntegrationsTabSharedContext);

  const msgProjectNotConfigured = (
    <>
      <span>There is no project integrated.</span>
      <Button
        size="small"
        type="text"
        className="font-medium workflow-document-upload"
        onClick={onConfigureClick}
      >
        Click here
      </Button>
      <span>to Add.</span>
    </>
  );

  const msgSubscriptionNotConfigured = (
    <>
      <span>No integrations are added to this Subscription.</span>
      <Button
        size="small"
        type="text"
        className="font-medium workflow-document-upload"
        onClick={() => {
          history.push("/settings/integrations");
        }}
      >
        Click here
      </Button>
      <span>to go to Subscription Settings.</span>
    </>
  );

  return (
    <div className="absolute top-[45%] z-50 left-0 right-0 flex items-center justify-center text-sm space-x-1">
      {!sharedState.subscriptionIntegrationConfigs?.length
        ? msgSubscriptionNotConfigured
        : msgProjectNotConfigured}
    </div>
  );
}
