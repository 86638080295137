import { Button, Divider, Form, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import CIQDatePicker from "components/custom-date-picker";
import { ProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { DateUtils } from "utils/dateutils";

function FilterForm({
  open,
  onFinish,
  onCancel,
  onBack,
  closable
}: {
  open: boolean;
  closable: boolean;
  onFinish: (e: any) => void;
  onBack: (e: any) => void;
  onCancel: any;
}) {
  const {
    columnHeaders: { materialHeaderMap, submittalHeaderMap }
  } = useContext(ProjectContext);

  const [form] = useForm();
  const [customDatePicker, showCustomDatePicker] = useState(false);
  const [customDate, setCustomDate] = useState<any>();

  const filterOptions = useMemo(() => {
    const readableCustomDate: string = customDate
      ? `(Due by ${DateUtils.format(customDate)})`
      : "";
    return [
      {
        value: DateUtils.dateTimeObj().startOf("day").add(14, "days").valueOf(),
        label: "Due in 2 weeks"
      },
      {
        value: DateUtils.dateTimeObj().startOf("day").valueOf(),
        label: "Past Due date"
      },
      { value: "custom", label: `Custom ${readableCustomDate}` }
    ];
  }, [customDate]);

  const reportFor = Form.useWatch("reportFor", form);

  useEffect(() => {
    form.resetFields(["groupby"]);
  }, [form, reportFor]);

  const resetFormState = () => {
    showCustomDatePicker(false);
    setCustomDate("");
    form.resetFields();
  };

  const formLabels: any = useMemo(() => {
    return {
      submittal: {
        due_date: submittalHeaderMap.due_date
      },
      material: {
        due_date: materialHeaderMap.due_date
      }
    };
  }, [materialHeaderMap.due_date, submittalHeaderMap.due_date]);

  return (
    <Modal
      className="custom-drawer"
      title="UPCOMING PROCUREMENT DEADLINES"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={open}
      closable={closable}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      keyboard={false}
    >
      <div className="px-3 -mt-4">
        <div className="text-xs mb-2">
          Select the below options to view report.
        </div>

        <Form
          form={form}
          layout="vertical"
          className=""
          onFinish={(e) => {
            const values = e as any;
            if (e.filter === "custom") {
              values.filter = customDate ? customDate.valueOf() : "";
            }
            console.log(values);
            onFinish(values);
          }}
        >
          <div>
            <Form.Item name="reportFor" label="Select Item Type" required>
              <Select
                options={[
                  { value: "submittal", label: "Submittals" },
                  { value: "material", label: "Materials" }
                ]}
                placeholder="Select Item Type"
              />
            </Form.Item>
          </div>

          {reportFor && (
            <div>
              <div className="relative">
                <Form.Item
                  name="filter"
                  label="Due Date"
                  initialValue={filterOptions[0].value}
                  required
                >
                  <Select
                    options={filterOptions}
                    onSelect={(v: any) => {
                      setTimeout(() => {
                        if (v === "custom") {
                          showCustomDatePicker(true);
                        } else {
                          showCustomDatePicker(false);
                          setCustomDate("");
                        }
                      });
                    }}
                    onClick={() => {
                      showCustomDatePicker(false);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        const filterValue = form.getFieldValue("filter");
                        if (filterValue === "custom" && !customDate) {
                          form.setFieldValue("filter", filterOptions[0].value);
                        }
                        showCustomDatePicker(false);
                      }, 100);
                    }}
                  />
                </Form.Item>
                <CIQDatePicker
                  className="absolute invisible"
                  open={customDatePicker}
                  value={customDate}
                  onChange={(v: any) => {
                    setCustomDate(v);
                    showCustomDatePicker(false);
                  }}
                />
                {reportFor === "submittal" && (
                  <Form.Item
                    name="groupby"
                    label="GROUP BY"
                    initialValue="RC_GroupBy"
                    required
                  >
                    <Select
                      options={[
                        {
                          value: "RC_GroupBy",
                          label: submittalHeaderMap.responsible_contractor
                        },
                        {
                          value: "BIC_GroupBy",
                          label: submittalHeaderMap.assignee
                        }
                      ]}
                      disabled={customDatePicker}
                    />
                  </Form.Item>
                )}
                {reportFor === "material" && (
                  <Form.Item
                    name="groupby"
                    label="GROUP BY"
                    initialValue="GroupBy_RC"
                    required
                  >
                    <Select
                      options={[
                        {
                          value: "GroupBy_RC",
                          label: materialHeaderMap.trade_partner
                        },
                        {
                          value: "GroupBy_GCR",
                          label: materialHeaderMap.gc_representative
                        }
                      ]}
                      disabled={customDatePicker}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="sortBy"
                  label="SORT BY"
                  initialValue="dueDate"
                  required
                >
                  <Select
                    options={[
                      {
                        value: "dueDate",
                        label: formLabels[reportFor].due_date
                      },
                      { value: "risk", label: "Risk" }
                    ]}
                    disabled={customDatePicker}
                  />
                </Form.Item>
              </div>
            </div>
          )}

          <Divider className="mx-0 mt-1 mb-4" />
          <div className="flex w-full justify-end space-x-4">
            {!closable && (
              <Button htmlType="reset" className="px-5" onClick={onBack}>
                Back
              </Button>
            )}
            {closable && (
              <Button htmlType="reset" className="px-5" onClick={onCancel}>
                Close
              </Button>
            )}
            <Button htmlType="reset" className="px-5" onClick={resetFormState}>
              Reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="px-5"
              disabled={!reportFor || customDatePicker}
            >
              Run Report
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
export default FilterForm;
