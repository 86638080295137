import { ProjectContext } from "context/ProjectProvider";
import { FeatureTypes } from "entity-app/constants";
import useFeatureToFeatureLinks from "entity-app/hooks/feature-to-feature-links";
import { useContext, useMemo } from "react";
import FeatureLinkedItem from "./feature-linked-item";

function FeatureToFeatureLinksViewPanel(props: { featureId: string }) {
  const { featureId } = props;
  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const { linkedFeatures, isLoadingLinkedFeatures, linkedFeaturesError } =
    useFeatureToFeatureLinks({
      token,
      featureInstanceId: featureId,
      featureTypeId: FeatureTypes.DESIGN_PACKAGE,
      linkType: "parent",
      loadLinkedFeatures: true
    });

  const featureLinksDOM = useMemo(() => {
    if ((!linkedFeatures && isLoadingLinkedFeatures) || linkedFeaturesError) {
      return null;
    }

    if (linkedFeatures?.length === 0) {
      return (
        <div className="w-full h-full flex items-center justify-center text-xs italic text-[#3B3B3BCC]">
          No design packages linked
        </div>
      );
    }

    if (linkedFeatures?.length > 0) {
      return linkedFeatures?.map((link: any) => {
        const instance = link.source_feature_instance;
        return (
          <FeatureLinkedItem
            key={instance.id}
            number={instance.number}
            title={instance.title}
            sizeClass="text-sm"
          />
        );
      });
    }
    return null;
  }, [linkedFeatures, isLoadingLinkedFeatures, linkedFeaturesError]);

  return (
    <div className="w-full h-full max-h-full py-3 overflow-y-hidden flex flex-col space-y-2">
      <div className="pb-1 px-2.5 text-[#555555D9] text-sm font-semibold tracking-wide">
        Design Packages
      </div>
      <div className="overflow-y-auto grow space-y-2 px-2.5">
        {featureLinksDOM}
      </div>
    </div>
  );
}

export default FeatureToFeatureLinksViewPanel;
