/* eslint-disable react-hooks/exhaustive-deps */
import { SearchOutlined } from "@ant-design/icons";
import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
import { Input, List } from "antd";
import { DateRangePickerModal } from "components";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import "./custom-date-filter.css";
import { DateUtils } from "utils/dateutils";
import { AppContext } from "context/AppProvider";

export default forwardRef(
  (
    props: IFilterParams & {
      title: string;
    },
    ref: any
  ) => {
    // console.log("props ", props);
    const dateFormat = "MM/DD/YYYY";
    const { title, api } = props;
    const [searchText, setSearchText] = useState<string>("");
    const [selectedFilter, setSelectedFilter] = useState<string>("");
    const dueDateFilters = [
      "Past today",
      "Today",
      "Next 3 days",
      "Next 2 weeks",
      "Custom"
    ];
    const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] =
      useState<boolean>(false);
    const [customDateRange, setCustomDateRange] = useState<any>({});

    const { submittalListFilter }: any = useContext(AppContext);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        isFilterActive() {
          return selectedFilter;
        },
        doesFilterPass(params: IDoesFilterPassParams) {
          const { colDef } = props;
          const { field } = colDef;

          const value = params.data[field!];
          //   console.log("date filter value: ", value);

          if (
            value ||
            selectedFilter === "Actual Date NA" ||
            selectedFilter === "no date present"
          ) {
            const cellDateValue = value
              ? DateUtils.dateTimeObj(value).valueOf()
              : 0;
            const todayDateValue = DateUtils.dateTimeObj(
              DateUtils.format(DateUtils.dateTimeObj())
            ).valueOf();

            switch (selectedFilter) {
              case "Past today": {
                const selectedDate =
                  DateUtils.dateTimeObj(todayDateValue).valueOf();
                return selectedDate > cellDateValue && value;
              }
              case "Today": {
                const selectedDate = DateUtils.dateTimeObj().format(dateFormat);
                const todayDateString =
                  DateUtils.dateTimeObj(value).format(dateFormat);
                return todayDateString === selectedDate && value;
              }
              case "Next 3 days": {
                const nextDate = DateUtils.dateTimeObj(todayDateValue)
                  .add(4, "days")
                  .valueOf();
                const today = DateUtils.dateTimeObj(todayDateValue).valueOf();
                return (
                  cellDateValue <= nextDate && cellDateValue > today && value
                );
              }
              case "Next 2 weeks": {
                const nextDate = DateUtils.dateTimeObj(todayDateValue)
                  .add(15, "days")
                  .valueOf();
                const today = DateUtils.dateTimeObj(todayDateValue).valueOf();
                return (
                  cellDateValue <= nextDate && cellDateValue > today && value
                );
              }
              case "CustomDateRange": {
                if (!customDateRange) return null;
                const { from, to } = customDateRange;
                const fromValue = DateUtils.dateTimeObj(
                  DateUtils.dateTimeObj(from).format(dateFormat)
                ).valueOf();
                const toValue = DateUtils.dateTimeObj(
                  DateUtils.dateTimeObj(to).format(dateFormat)
                ).valueOf();
                return cellDateValue >= fromValue && cellDateValue <= toValue;
              }
              case "Overdue": {
                return cellDateValue < todayDateValue;
              }
              case "Due in 5 days": {
                const { from, to } = {
                  from: DateUtils.dateTimeObj(
                    DateUtils.dateTimeObj().format(dateFormat)
                  ).valueOf(),
                  to: DateUtils.dateTimeObj(
                    DateUtils.dateTimeObj().format(dateFormat)
                  )
                    .add(6, "d")
                    .valueOf()
                };
                return cellDateValue >= from && cellDateValue <= to;
              }
              case "Actual Date NA": {
                return !cellDateValue;
              }

              case "Overdue or due in 2 weeks": {
                const nextDate = DateUtils.dateTimeObj(todayDateValue)
                  .add(15, "days")
                  .valueOf();
                return cellDateValue < nextDate;
              }
              case "due after 2 weeks":
                {
                  const nextDate = DateUtils.dateTimeObj(todayDateValue)
                    .add(15, "days")
                    .valueOf();
                  return value && cellDateValue > nextDate;
                }
                break;
              case "after date":
                {
                  if (!submittalListFilter?.value) return false;
                  const afterDate = DateUtils.dateTimeObj(
                    submittalListFilter.value
                  ).valueOf();
                  return value && cellDateValue >= afterDate;
                }
                break;
              case "no date present":
                if (!cellDateValue) {
                  return true;
                }
                return false;
                break;
              default:
                return false;
            }
          }
          return false;
        },
        getModel() {
          if (
            selectedFilter &&
            selectedFilter !== "CustomDateRange" &&
            selectedFilter !== "Custom"
          ) {
            return { values: [selectedFilter] };
          }

          if (
            selectedFilter &&
            selectedFilter === "CustomDateRange" &&
            customDateRange?.from &&
            customDateRange?.to
          ) {
            return {
              values: ["Custom"],
              range: customDateRange
            };
          }
          return {};
        },

        setModel(model: any) {
          if (!model || !model.values) return;
          if (model.values[0] === "Custom") {
            setCustomDateRange(model.range);
            setSelectedFilter("CustomDateRange");
          } else {
            setSelectedFilter(model.values[0]);
          }
        },

        setSelectedFilter
      };
    });

    useEffect(() => {
      setTimeout(() => {
        api.setQuickFilter(searchText);
      }, 0);
    }, [searchText, api]);

    useEffect(() => {
      props.filterChangedCallback();
    }, [selectedFilter, props]);

    const onCustomDateFilterChanged = () => {
      if (customDateRange) {
        const from = DateUtils.dateTimeObj(customDateRange[0]).valueOf();
        const to = DateUtils.dateTimeObj(customDateRange[1]).valueOf();
        setCustomDateRange({ from, to });
        setSelectedFilter("CustomDateRange");
      } else {
        setSelectedFilter("");
      }
    };

    return (
      <>
        <div className="custom-date-filters">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            allowClear
            style={{ background: "#0000000D" }}
            bordered={false}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <List
            className="filter-list"
            dataSource={dueDateFilters}
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  if (item === "Custom") {
                    setIsCustomDatePickerOpen(true);
                    return;
                  }
                  setSelectedFilter(item);
                }}
              >
                {item}
              </List.Item>
            )}
          />
        </div>
        <DateRangePickerModal
          title={title}
          isOpen={isCustomDatePickerOpen}
          setIsOpen={setIsCustomDatePickerOpen}
          setCustomDateRange={setCustomDateRange}
          onOkClick={onCustomDateFilterChanged}
        />
      </>
    );
  }
);
