import { Tag } from "antd";

export default function DefaultTag() {
  return (
    <Tag
      color="#3B3B3B"
      className="rounded-full text-xs font-thin tracking-wide"
    >
      Default
    </Tag>
  );
}
