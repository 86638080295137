const UPDATE_FEATURE_INSTANCE_BY_PK = `
mutation UPDATE_FEATURE_INSTANCE_BY_PK($featureId: uuid!, $set: feature_instance_set_input) {
  update_feature_instance_by_pk(
    pk_columns: {id: $featureId}
    _set: $set
) {
    id
  }
}
`;

const UPDATE_FEATURE_BID_PACKAGE_BY_PK = `
mutation UPDATE_FEATURE_BID_PACKAGE_BY_PK($featureId: uuid!, $set: feature_bid_package_set_input) {
  update_feature_bid_package_by_pk(
    pk_columns: {feature_instance_id: $featureId}
    _set: $set
  ) {
    feature_instance_id
  }
}
`;

const INSERT_FEATURE_INSTANCE_SPEC_SECTION = `
mutation INSERT_FEATURE_INSTANCE_SPEC_SECTION($featureId: uuid!, $specSectionId: uuid!, $bidScope: Boolean!) {
  insert_feature_instance_spec_section_one(object: {feature_instance_id: $featureId, spec_section_id: $specSectionId, bid_scope: $bidScope}) {
    feature_instance_id
  }
}
`;

const DELETE_FEATURE_INSTANCE_SPEC_SECTION = `
mutation DELETE_FEATURE_INSTANCE_SPEC_SECTION($featureId: uuid!, $specSectionId: uuid!) {
  delete_feature_instance_spec_section_by_pk(feature_instance_id: $featureId, spec_section_id: $specSectionId) {
    feature_instance_id
  }
}
`;

const DELETE_FEATURE_INSTANCE_SPEC_SECTION_BY_IDS = `
mutation DELETE_FEATURE_INSTANCE_SPEC_SECTION_BY_IDS($featureId: uuid!, $specSectionIds: [uuid!]!) {
  delete_feature_instance_spec_section(where: {feature_instance_id: {_eq: $featureId}, spec_section_id: {_in: $specSectionIds}}) {
    affected_rows
  }
}
`;

const DELETE_FEATURE_USER_PARTICIPANT = `
mutation DELETE_FEATURE_USER_PARTICIPANT($userId: uuid!, $instance_id: uuid!, $userParticipantTypeId: Int) {
 delete_feature_user_participant(
    where: {user_id: {_eq: $userId}, instance_id: {_eq: $instance_id}, user_participation_type_id: {_eq: $userParticipantTypeId}}
  ){
    affected_rows
  }
}
`;

const INSERT_FEATURE_USER_PARTICIPANT = `
mutation INSERT_FEATURE_USER_PARTICIPANT($userId: uuid!, $instance_id: uuid!, $userParticipantTypeId: Int) {
insert_feature_user_participant_one(
    object: {instance_id: $instance_id, user_id: $userId, user_participation_type_id: $userParticipantTypeId}
  ) {
    user_id
  }
}
`;

const INSERT_FEATURE_COMPANY_PARTICIPANT = `
mutation INSERT_COMPANY_PARTICIPANT($instanceId: uuid!, $vendorId: uuid!, $companyParticipantTypeId: Int) {
   insert_feature_company_participant_one(
    object: {company_participation_type_id: $companyParticipantTypeId, instance_id: $instanceId, vendor_id: $vendorId}
  ) {
    vendor_id
  }
}
`;

const DELETE_FEATURE_COMPANY_PARTICIPANT = `
mutation DELETE_COMPANY_PARTICIPANT($instanceId: uuid!, $vendorId: uuid!, $companyParticipantTypeId: Int) {
  delete_feature_company_participant(
     where: {instance_id: {_eq: $instanceId}, vendor_id: {_eq: $vendorId}, company_participation_type_id: {_eq: $companyParticipantTypeId}}
  ) {
    affected_rows
  }
}
`;

const UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET = `
mutation updateWorkflowOffset($id: uuid!, $set: feature_workflow_instance_offsets_set_input = {}, $workflowInstanceId: uuid!) {
  update_feature_workflow_instance_offsets_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
  update_feature_workflow_instance_by_pk(pk_columns: {id: $workflowInstanceId}, _set: {template_value_overidden: true}) {
    id
  }
}
`;

const UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE = `
mutation updateWorkflowMilestone($id: uuid!, $set: feature_workflow_instance_milestones_set_input = {}) {
  update_feature_workflow_instance_milestones_by_pk(
    pk_columns: {id: $id}
    _set: $set
  ) {
    id
  }
}
`;

const UPDATE_FEATURE_WORKFLOW_INSTANCE = `
mutation updateFeatureWorkflowInstance($id: uuid!, $set: feature_workflow_instance_set_input!) {
  update_feature_workflow_instance_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
`;

const MUTATION_CREATE_FEATURE_INSTANCE = `
  mutation CreateFeatureInstancePackage(
    $feature_type: String!
    $feature_instances: [json!]
  ) {
    insert_feature_instances_multi(
      feature_type: $feature_type
      feature_instances: $feature_instances
    ) {
      ids
      message
    }
  }
`;
const MUTATION_UPDATE_RESPONSIBLE_DESIGN_FIRM = `
  mutation updateResponsibleDesignFirm($featureInstanceId: uuid!, $designFirm: feature_company_participant_insert_input!) {
    delete_feature_company_participant(where: {_and: [{company_participation_type_id: {_eq: 3}}, {feature_instance: {id: {_eq: $featureInstanceId}}}]}) {
      affected_rows
    }
    delete_feature_user_participant(where: {_and: [{instance_id: {_eq: $featureInstanceId}}, {user_participation_type_id: {_eq: 4}}]}) {
      affected_rows
    }
    insert_feature_company_participant_one(object: $designFirm) {
      vendor_id
    }
  }
`;

const MUTATION_UPDATE_FEATURE_USER_PARTICIPANT = `
  mutation updateFeatureUserParticipant($featureInstanceId: uuid!, $object: feature_user_participant_insert_input!, $user_participation_type_id: Int!) {
    delete_feature_user_participant(where: {_and: [{instance_id: {_eq: $featureInstanceId}}, {user_participation_type_id: {_eq: $user_participation_type_id}}]}) {
      affected_rows
    }
    insert_feature_user_participant_one(object: $object) {
      user_id
    }
}
`;

const UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE = `
  mutation UpdateFeatureInstanceWorkflowTemplate($featureInstanceId: uuid!, $workflowTemplateId: uuid!) {
    update_feature_instance_workflow_template(feature_instance_id: $featureInstanceId, workflow_template_id: $workflowTemplateId) {
      message
      updated
    }
  }
`;

export {
  UPDATE_FEATURE_BID_PACKAGE_BY_PK,
  UPDATE_FEATURE_INSTANCE_BY_PK,
  INSERT_FEATURE_INSTANCE_SPEC_SECTION,
  DELETE_FEATURE_INSTANCE_SPEC_SECTION,
  DELETE_FEATURE_INSTANCE_SPEC_SECTION_BY_IDS,
  DELETE_FEATURE_USER_PARTICIPANT,
  INSERT_FEATURE_USER_PARTICIPANT,
  INSERT_FEATURE_COMPANY_PARTICIPANT,
  DELETE_FEATURE_COMPANY_PARTICIPANT,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_OFFSET,
  UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE,
  UPDATE_FEATURE_WORKFLOW_INSTANCE,
  MUTATION_CREATE_FEATURE_INSTANCE,
  MUTATION_UPDATE_RESPONSIBLE_DESIGN_FIRM,
  MUTATION_UPDATE_FEATURE_USER_PARTICIPANT,
  UPDATE_FEATURE_INSTANCE_WORKFLOW_TEMPLATE
};
