import { useParams } from "react-router";
import "./bid-package-plan-panel.scss";
import { Button, Form, Input, message, Popover, Segmented, Spin } from "antd";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ProjectContext } from "context/ProjectProvider";
import {
  useBidPackagePlanning,
  WorkflowInstanceMilestone,
  WorkflowInstanceOffset
} from "entity-app/hooks/bid-package-planning";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";
import { restrictInputToNumbers } from "utils/inpututils";
import { LinkTypes } from "entity-app/constants";
import moment from "moment";
import { LinkOutlined } from "@ant-design/icons";
import CIQDatePicker from "components/custom-date-picker";
import FeatureActivityLinking from "components/bid-package-planning-panel/feature-activity-linking";
import QuestionIcon from "components/svg-icons/question-icon";
import classNames from "classnames";
import InfoIcon from "components/svg-icons/info-icon";
import ActivityItem from "./activity-item";
import DatePlannerPopoverContent from "./date-planner-popover-content";

function SectionHeading({ title }: { title: string }) {
  return (
    <div className="mt-3 text-xs font-semibold tracking-wide mb-2 text-[#3B3B3B] px-1">
      {title}
    </div>
  );
}

function PlanningItemMilestone({
  milestone,
  emptyDateText
}: {
  milestone: WorkflowInstanceMilestone;
  emptyDateText?: string;
}) {
  return (
    <div className="bg-type-1 px-2 py-1 flex items-center justify-between">
      <div className="italic">{milestone.name}</div>
      {milestone.planned_date && (
        <div>{DateUtils.format(milestone.planned_date, "MM-DD-YYYY")}</div>
      )}
      {!milestone.planned_date && emptyDateText && (
        <div className="text-xs text-color-3 italic">{emptyDateText}</div>
      )}
    </div>
  );
}

function BidPackagePlanPanel({
  refetchFeatureInstanceData,
  disabled
}: {
  refetchFeatureInstanceData: Function;
  disabled: boolean;
}) {
  const { bidPackageId } = useParams() as any;
  const [form] = Form.useForm();

  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [isActivityLinkingDialogOpen, setIsActivityLinkingDialogOpen] =
    useState(false);

  const [activeLinkingView, setActiveLinkingView] = useState<
    "activities" | "date"
  >("activities");

  const scheduleDatePickerRef: any = useRef();

  const prevIncomingFieldValuesRef = useRef(null);

  const [isCalculatingSubmittalPlan, setIsCalculatingSubmittalPlan] =
    useState(false);
  const [isCalculatingMaterialPlan, setIsCalculatingMaterialPlan] =
    useState(false);
  const [isCalculatingROJDate, setIsCalculatingROJDate] = useState(false);

  const {
    featureLinks,
    updateWorkflowInstanceOffset,
    updateWorkflowInstance,
    refetch: refetchPlanningData
  } = useBidPackagePlanning({
    featureId: bidPackageId,
    token,
    onRefetchCallback: refetchFeatureInstanceData
  });

  const handleLoadingIndicators = useCallback(
    (feature: "submittal" | "material" | "schedule") => {
      if (
        feature === "submittal" ||
        feature === "material" ||
        feature === "schedule"
      ) {
        setIsCalculatingSubmittalPlan(true);
        setTimeout(() => {
          setIsCalculatingSubmittalPlan(false);
        }, 4000);
      }

      if (feature === "material" || feature === "schedule") {
        setIsCalculatingMaterialPlan(true);
        setTimeout(() => {
          setIsCalculatingMaterialPlan(false);
        }, 4000);
      }

      if (feature === "schedule") {
        setIsCalculatingROJDate(true);
        setTimeout(() => {
          setIsCalculatingROJDate(false);
        }, 4000);
      }
    },
    []
  );

  const linkedActivities = useMemo(() => {
    const activites =
      featureLinks?.PLANNING_ONLY_MATERIAL?.links_as_source.filter(
        (link: any) => link.target_gantt_task
      );

    activites?.sort((a: any, b: any) => {
      const aDateString =
        a.link_type === LinkTypes.finish_to_start
          ? a.target_gantt_task.start_date
          : a.target_gantt_task.end_date;
      const bDateString =
        b.link_type === LinkTypes.finish_to_start
          ? b.target_gantt_task.start_date
          : b.target_gantt_task.end_date;

      return moment(aDateString).diff(moment(bDateString));
    });

    return activites || null;
  }, [featureLinks]);

  const incomingFieldValues = useMemo(() => {
    if (!featureLinks) {
      return null;
    }

    const fieldValuesToSet: any = {};

    Object.keys(featureLinks).forEach((key: any) => {
      if (featureLinks?.[key]?.workflow_instance_offsets) {
        featureLinks?.[key]?.workflow_instance_offsets.forEach(
          (offset: WorkflowInstanceOffset) => {
            fieldValuesToSet[offset.id] = offset.duration;
          }
        );
      }
    });
    if (
      featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
        ?.manual_entry_date
    ) {
      fieldValuesToSet[
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id!
      ] = DateUtils.dateTimeObj(
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
          ?.manual_entry_date
      );
    } else {
      fieldValuesToSet[
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id!
      ] = null;
    }
    return fieldValuesToSet;
  }, [featureLinks]);

  const governingEntity = useMemo(() => {
    if (featureLinks?.PLANNING_ONLY_ACTIVITY?.governing_link) {
      return {
        governedBy: "manual_date",
        date: featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
          ?.manual_entry_date
      };
    }

    const activities =
      featureLinks?.PLANNING_ONLY_MATERIAL?.links_as_source?.filter(
        (link: any) => link.target_gantt_task
      );
    const governingActivity = activities?.filter(
      (activity: any) => activity.governing_link
    );

    if (governingActivity && governingActivity?.length > 0) {
      const linkDate =
        governingActivity?.[0]?.link_type === LinkTypes.finish_to_start
          ? governingActivity?.[0]?.target_gantt_task?.start_date
          : governingActivity?.[0]?.target_gantt_task?.end_date;
      return {
        governedBy: "linked_activity",
        date: linkDate,
        governingActivity: governingActivity?.[0]
      };
    }

    return null;
  }, [featureLinks]);

  const onBlur = async (change: {
    offsetId: string;
    value: any;
    feature: "submittal" | "material";
  }) => {
    if (incomingFieldValues?.[change.offsetId] === change.value) {
      return;
    }

    const value = change.value ? parseInt(change.value, 10) : null;

    const updateResponse = await updateWorkflowInstanceOffset({
      ...change,
      offsetId: change.offsetId,
      value,
      workflowInstanceId:
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id!
    });

    if (updateResponse.data.update_feature_workflow_instance_offsets_by_pk) {
      message.success("Updated successfully.");

      handleLoadingIndicators(change.feature);
    } else {
      message.error("Failed to update.");
    }
  };

  const onDateChange = async (change: { id: string; value: any }) => {
    const payload = {
      id: change.id,
      set: {
        manual_entry_date: change.value
      }
    };
    const updateResponse = await updateWorkflowInstance(payload);

    if (updateResponse.data.update_feature_workflow_instance_by_pk) {
      message.success("Updated successfully.");
      handleLoadingIndicators("schedule");
    }
  };

  const onActivityLinkingChange = () => {
    handleLoadingIndicators("schedule");
    refetchPlanningData();
  };

  useEffect(() => {
    if (featureLinks && incomingFieldValues) {
      // compare each property in incomingFieldValues and identify if its changed, if yes then add it to a new object
      const changedValues: any = {};
      Object.keys(incomingFieldValues).forEach((key) => {
        if (
          incomingFieldValues[key] !== prevIncomingFieldValuesRef.current?.[key]
        ) {
          changedValues[key] = incomingFieldValues[key];
        }
      });

      form.setFieldsValue(changedValues);

      prevIncomingFieldValuesRef.current = incomingFieldValues;
    }
  }, [featureLinks, form, incomingFieldValues]);

  const submittalPlanHasDates = useMemo(() => {
    return featureLinks?.PLANNING_ONLY_SUBMITTAL?.workflow_instance_milestones?.some(
      (milestone: WorkflowInstanceMilestone) => milestone.planned_date
    );
  }, [featureLinks?.PLANNING_ONLY_SUBMITTAL]);

  const materialPlanHasDates = useMemo(() => {
    return featureLinks?.PLANNING_ONLY_MATERIAL?.workflow_instance_milestones?.some(
      (milestone: WorkflowInstanceMilestone) => milestone.planned_date
    );
  }, [featureLinks?.PLANNING_ONLY_MATERIAL]);

  const submittalPlanHasWFInstanceOffsets = useMemo(() => {
    return featureLinks?.PLANNING_ONLY_SUBMITTAL?.workflow_instance_offsets?.some(
      (offset: WorkflowInstanceOffset) => offset.duration !== null
    );
  }, [featureLinks?.PLANNING_ONLY_SUBMITTAL]);

  const materialPlanHasWFInstanceOffsets = useMemo(() => {
    return featureLinks?.PLANNING_ONLY_MATERIAL?.workflow_instance_offsets?.some(
      (offset: WorkflowInstanceOffset) => offset.duration !== null
    );
  }, [featureLinks?.PLANNING_ONLY_MATERIAL]);

  const incomingFormattedROJDate = useMemo(() => {
    if (
      !incomingFieldValues?.[
        featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id!
      ]
    )
      return null;
    return incomingFieldValues?.[
      featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id!
    ].format(DATE_FORMAT_MMDDYYYY);
  }, [
    featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance?.id,
    incomingFieldValues
  ]);

  const footerForROJDatePicker = (
    <div className="flex justify-center">
      <Button
        onClick={() => {
          onDateChange({
            id: featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
              ?.id!,
            value: null
          });
          form.setFieldsValue({
            [featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
              ?.id!]: null
          });
          setTimeout(() => {
            scheduleDatePickerRef?.current?.blur();
          });
        }}
        className="my-2"
      >
        Clear Date
      </Button>
    </div>
  );

  const ROSPopoverContent = useMemo(() => {
    const activity = governingEntity?.governingActivity?.target_gantt_task;
    const governingActivityDOM = (
      <div className="space-y-0.5">
        <div>Governing Entity:</div>
        <div>
          {activity?.source_task_id} - {activity?.text}
        </div>
      </div>
    );

    const manualDateDOM = <div>Governing Entity: Manually Set Date.</div>;

    return (
      <div className="text-xs text-[#3B3B3BCC] max-w-[200px]">
        {governingEntity?.governedBy === "manual_date"
          ? manualDateDOM
          : governingActivityDOM}
      </div>
    );
  }, [governingEntity]);

  if (!featureLinks) {
    return null;
  }

  return (
    <div className="bid-package-plan-panel px-2.5 pb-3 h-full max-h-full overflow-x-hidden overflow-y-auto">
      <Form form={form} layout="vertical">
        <div className="flex items-center justify-between border-type-1 border-0 border-b bg-white pt-3 pb-1 px-1 sticky top-0 z-10">
          <div className="text-sm text-color-1 font-semibold tracking-wide">
            Date Planner
          </div>
          <Popover
            placement="left"
            content={<DatePlannerPopoverContent />}
            trigger="hover"
          >
            <div className="flex items-center justify-center">
              <QuestionIcon />
            </div>
          </Popover>
        </div>
        {featureLinks?.PLANNING_ONLY_SUBMITTAL && (
          <div>
            <SectionHeading title="Submittal Plan" />
            <div>
              {((featureLinks?.PLANNING_ONLY_SUBMITTAL
                ?.workflow_instance_milestones &&
                (submittalPlanHasWFInstanceOffsets || submittalPlanHasDates)) ||
                isCalculatingSubmittalPlan) && (
                <Spin spinning={isCalculatingSubmittalPlan} size="small">
                  <div className="text-xs italic border-type-2 border-0 border-b min-h-[50px] bg-type-1">
                    {submittalPlanHasDates &&
                      featureLinks?.PLANNING_ONLY_SUBMITTAL?.workflow_instance_milestones.map(
                        (milestone: WorkflowInstanceMilestone) => (
                          <PlanningItemMilestone
                            key={milestone.id}
                            milestone={milestone}
                            emptyDateText={
                              milestone.name === "Assigned"
                                ? "Add Prep and Review Time"
                                : ""
                            }
                          />
                        )
                      )}

                    {submittalPlanHasWFInstanceOffsets &&
                      !submittalPlanHasDates && (
                        <div className="bg-type-1 px-2 py-2 text-center text-[#3B3B3B99]">
                          Configure Schedule & Material Plan to calculate dates.
                        </div>
                      )}
                  </div>
                </Spin>
              )}
            </div>
            <div className="grid grid-cols-2 gap-3 mt-2 px-1">
              {featureLinks?.PLANNING_ONLY_SUBMITTAL?.workflow_instance_offsets?.map(
                (offset: any) => {
                  return (
                    <div key={offset.id}>
                      <Form.Item label={offset.name} name={offset.id}>
                        <Input
                          disabled={disabled}
                          placeholder="0"
                          type="number"
                          suffix="Days"
                          onKeyDown={restrictInputToNumbers}
                          onBlur={(e: any) => {
                            onBlur({
                              offsetId: offset.id,
                              value: e.target.value || null,
                              feature: "submittal"
                            });
                          }}
                          min={0}
                        />
                      </Form.Item>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}

        <div className="separator" />

        {featureLinks?.PLANNING_ONLY_MATERIAL && (
          <div>
            <SectionHeading title="Material Plan" />
            <div>
              {((featureLinks?.PLANNING_ONLY_MATERIAL
                ?.workflow_instance_milestones &&
                (materialPlanHasWFInstanceOffsets || materialPlanHasDates)) ||
                isCalculatingMaterialPlan) && (
                <Spin spinning={isCalculatingMaterialPlan} size="small">
                  <div className="text-xs border-type-2 border-0 border-b min-h-[33px] bg-type-1 mx-1">
                    {materialPlanHasDates &&
                      featureLinks?.PLANNING_ONLY_MATERIAL?.workflow_instance_milestones.map(
                        (milestone: WorkflowInstanceMilestone) => (
                          <PlanningItemMilestone
                            key={milestone.id}
                            milestone={milestone}
                            emptyDateText={
                              milestone.name === "Released"
                                ? "Add Lead Time"
                                : ""
                            }
                          />
                        )
                      )}

                    {materialPlanHasWFInstanceOffsets &&
                      !materialPlanHasDates && (
                        <div className="bg-type-1 px-2 py-2 text-center text-[#3B3B3B99]">
                          Configure Schedule to calculate dates.
                        </div>
                      )}
                  </div>
                </Spin>
              )}
            </div>
            <div className="grid grid-cols-2 gap-3 mt-2 px-1">
              {featureLinks?.PLANNING_ONLY_MATERIAL?.workflow_instance_offsets?.map(
                (offset: any) => {
                  return (
                    <div key={offset.id}>
                      <Form.Item label={offset.name} name={offset.id}>
                        <Input
                          disabled={disabled}
                          placeholder="0"
                          type="number"
                          suffix="Days"
                          onKeyDown={restrictInputToNumbers}
                          onBlur={(e: any) => {
                            onBlur({
                              offsetId: offset.id,
                              value: e.target.value || null,
                              feature: "material"
                            });
                          }}
                          min={0}
                        />
                      </Form.Item>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}

        <div className="separator" />

        <div>
          <SectionHeading title="Schedule Link" />
          <Spin spinning={isCalculatingROJDate} size="small">
            <div className="bg-type-1 px-2 py-2 flex items-center justify-between text-xs border-type-2 border-0 border-b mx-1">
              <div className="text-color-2 italic">Required on Site</div>
              <div className="flex items-center justify-center space-x-1">
                {governingEntity ? (
                  <div>
                    {governingEntity.date
                      ? DateUtils.format(
                          governingEntity.date,
                          DATE_FORMAT_MMDDYYYY
                        )
                      : null}
                  </div>
                ) : (
                  <div className="text-color-3 italic">
                    Add Activity or Date
                  </div>
                )}

                {governingEntity && (
                  <Popover
                    placement="left"
                    content={ROSPopoverContent}
                    trigger="hover"
                  >
                    <div className="flex items-center justify-center">
                      <InfoIcon />
                    </div>
                  </Popover>
                )}
              </div>
            </div>
          </Spin>
          <div className="mt-2.5 flex items-center justify-between mx-1">
            <Segmented
              size="small"
              className="segment-selector"
              defaultValue="activities"
              options={[
                {
                  value: "activities",
                  label: `Activities ${
                    linkedActivities?.length
                      ? `(${linkedActivities?.length})`
                      : ""
                  }`
                },
                {
                  value: "date",
                  label: `Set Date ${incomingFormattedROJDate ? "(✓)" : ""}`
                }
              ]}
              onChange={(value) => {
                setActiveLinkingView(value as "activities" | "date");
              }}
            />
            {!disabled && linkedActivities && linkedActivities?.length > 0 && (
              <div>
                <Button
                  type="link"
                  size="small"
                  className="text-xs ciq-button-1 h-[20px] px-0"
                  onClick={() => setIsActivityLinkingDialogOpen(true)}
                >
                  Edit Links
                  <LinkOutlined className="!ml-1" />
                </Button>
              </div>
            )}
          </div>
          <div
            className={classNames("mt-2 flex flex-col gap-y-2 mx-1", {
              hidden: activeLinkingView !== "activities"
            })}
          >
            {linkedActivities?.map((activity: any) => {
              return (
                <ActivityItem
                  key={activity.id}
                  activity={activity.target_gantt_task}
                  linkType={activity.link_type}
                />
              );
            })}
            {!disabled && linkedActivities?.length === 0 && (
              <div className="text-center py-3">
                <Button
                  type="link"
                  size="small"
                  className=" text-xs ciq-button-1 h-[20px] px-0"
                  onClick={() => setIsActivityLinkingDialogOpen(true)}
                >
                  No Activities Linked. Click to Add
                </Button>
              </div>
            )}
          </div>
          <div
            className={classNames("mt-2 flex flex-col gap-y-2", {
              hidden: activeLinkingView !== "date"
            })}
          >
            <Form.Item
              className="w-7/12"
              name={
                featureLinks?.PLANNING_ONLY_ACTIVITY?.feature_workflow_instance
                  ?.id
              }
              label="On Site Estimate"
            >
              <CIQDatePicker
                disabled={disabled}
                ref={scheduleDatePickerRef}
                className="w-full"
                placeholder="Select date"
                format={DATE_FORMAT_MMDDYYYY}
                onChange={(date: any) => {
                  const saveDate = DateUtils.formatDateWithLunchTime(date);
                  onDateChange({
                    id: featureLinks?.PLANNING_ONLY_ACTIVITY
                      ?.feature_workflow_instance?.id!,
                    value: saveDate
                  });
                }}
                renderExtraFooter={() => {
                  if (
                    featureLinks?.PLANNING_ONLY_ACTIVITY
                      ?.feature_workflow_instance?.id &&
                    incomingFieldValues?.[
                      featureLinks?.PLANNING_ONLY_ACTIVITY
                        ?.feature_workflow_instance?.id!
                    ]
                  ) {
                    return footerForROJDatePicker;
                  }
                  return null;
                }}
                placement="topLeft"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      {isActivityLinkingDialogOpen && (
        <FeatureActivityLinking
          dialogOpenState={[
            isActivityLinkingDialogOpen,
            setIsActivityLinkingDialogOpen
          ]}
          featureId={featureLinks?.PLANNING_ONLY_MATERIAL?.id}
          onSaveSuccess={onActivityLinkingChange}
        />
      )}
    </div>
  );
}

export default BidPackagePlanPanel;
