import { dropDownCellRenderer } from "components/cell-renders";
import ErrorBoundary from "components/error-boundary";
import SubmittalMicroVisualiser from "components/submittal-details/submittal-micro-viz";
import SubmittalMicroVizDateBlock from "components/submittal-details/submittal-microviz-dateblock";
import { Link } from "react-router-dom";

export const linkIdRenderer = ({ data, value }: any) => (
  <Link
    to={{
      pathname: `project/${data?.id}/submittals`
    }}
  >
    {value}
  </Link>
);

export function ASubmittalStatusCellRenderer(params: any) {
  const { data, context } = params;
  // Note: keep useAlternateDBStructure as always true here
  // as the submittal list response structure does not change for new or old DB.
  return (
    <div>
      <div>{dropDownCellRenderer(params)}</div>
      {context.isIntegrationMode && (
        <ErrorBoundary>
          <SubmittalMicroVizDateBlock
            disabledTooltip
            size="sm"
            submittalData={{
              ...data,
              date_block_submittals: [
                {
                  actual_milestone_1: data.actual_assigned_date,
                  actual_milestone_2: data.actual_trade_partner_submitted_date,
                  actual_milestone_3: data.actual_ext_review_submitted_date,
                  actual_milestone_4: data.actual_ext_review_completed_date,
                  actual_milestone_5: data.actual_submittal_distributed_date
                }
              ]
            }}
            projectParticipants={context.projectParticipants}
            submittalRevisions={data.revision_date_blocks}
          />
        </ErrorBoundary>
      )}
      {!context.isIntegrationMode && (
        <div>
          <ErrorBoundary>
            <SubmittalMicroVisualiser
              design_reviewer_id={
                data?.design_reviewer_user_id ||
                data.design_reviewer_unregistered
              }
              gc_reviewer_id={data?.gc_reviewer_user_id}
              submittal_history={data?.history}
              size="sm"
              vendorInfo={{
                designReviewerCompanyName:
                  data.design_reviewer_unregistered_org,
                responsibleContractorCompanyName:
                  data.responsible_contractor_name
              }}
              disabledTooltip
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
}
