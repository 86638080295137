import { gql } from "@apollo/client";

export const ADD_ORGANIZATION_MUTATION = gql`
  mutation InsertOrganization(
    $object: organization_insert_input = { domain: "", name: "test1" }
  ) {
    insert_organization_one(object: $object) {
      domain
      id
      name
    }
  }
`;

export const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation CreateSubscription(
    $organization_id: uuid!
    $email: String!
    $name: String!
  ) {
    insert_subscription_one(
      object: {
        organization_subscriptions: {
          data: { organization_id: $organization_id }
        }
        name: $name
        subscription_users: {
          data: {
            subscription_permission_id: 1
            user: {
              data: { email: $email }
              on_conflict: {
                update_columns: [updated_at]
                constraint: user_email_key
              }
            }
          }
        }
      }
    ) {
      id
      name
    }
  }
`;
