import ErrorBoundary from "components/error-boundary";
import { useCallback, useEffect, useRef, useState } from "react";

import { PDFDocumentProxy } from "pdfjs-dist";
import { PDFViewer } from "pdfjs-dist/legacy/web/pdf_viewer";

import * as pdfjsViewer from "pdfjs-dist/web/pdf_viewer";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { getPdfLoader } from "./loader";
import "./index.scss";
import "pdfjs-dist/web/pdf_viewer.css";
import PdfLoaderDiv from "./pdf-loader";
import { BoundingBoxPositionType } from "./models";

const scale = 2;

type PageChangeType = {
  pageLabel: any;
  pageNumber: number;
  previous: number | null;
};

function CiqPdfViewer({
  fileUrl,
  onExpiredUrl,
  boundingBoxPossition
}: {
  fileUrl: string;
  onExpiredUrl: () => void;
  boundingBoxPossition?: BoundingBoxPositionType;
}) {
  const viewerContainerRef = useRef<HTMLDivElement | null>(null);
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const [viewerPdf, setViewer] = useState<PDFViewer>();
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState<number>(0);

  const [, setState] = useState<{
    pageCount: number;
    pageChange: PageChangeType;
  }>({
    pageCount: 0,
    pageChange: {
      pageNumber: 1,
      previous: null,
      pageLabel: ""
    }
  });

  const appendCanvasLayer = useCallback(
    (textLayerDiv: HTMLDivElement) => {
      if (boundingBoxPossition) {
        const ratio = textLayerDiv.clientWidth / boundingBoxPossition.width;
        const left = `${Math.ceil(boundingBoxPossition.x1 * ratio)}px`;
        const top = `${Math.ceil(boundingBoxPossition.y1 * ratio)}px`;
        const width = `${Math.ceil(
          (boundingBoxPossition.x2 - boundingBoxPossition.x1) * ratio
        )}px`;
        const height = `${Math.ceil(
          (boundingBoxPossition.y2 - boundingBoxPossition.y1) * ratio
        )}px`;

        const id = "canvas-text-layer-annotation";
        let rectangle = document.getElementById(id);
        if (!rectangle) rectangle = document.createElement("div");
        rectangle.id = id;
        rectangle.setAttribute(
          "class",
          "absolute pointer-events-none outline outline-[#33a52d] outline-4"
        );
        rectangle.style.width = width;
        rectangle.style.height = height;
        rectangle.style.left = left;
        rectangle.style.top = top;

        textLayerDiv.appendChild(rectangle);
      }
    },
    [boundingBoxPossition]
  );

  useEffect(() => {
    let pdfViewer: PDFViewer;
    const container = viewerContainerRef.current;

    const onProgress = (data: any) => {
      const percentProgress = Math.round((data.loaded * 95) / data.total);
      setPercent(percentProgress);
    };

    const load = async () => {
      console.log("Loading PDF");
      setPercent(0);
      setLoading(true);
      const pdfDocument: PDFDocumentProxy | null = await getPdfLoader({
        fileUrl,
        onExpiredUrl,
        onProgress
      });
      if (pdfDocument === null) {
        setLoading(false);
        return;
      }

      if (pdfDocument && container && viewerRef.current) {
        setState((pre) => ({ ...pre, pageCount: pdfDocument.numPages }));
        const pdfEventBus = new pdfjsViewer.EventBus();
        const pdfLinkService = new pdfjsViewer.PDFLinkService({
          eventBus: pdfEventBus
        });

        pdfViewer = new pdfjsViewer.PDFViewer({
          container,
          viewer: viewerRef.current,
          linkService: pdfLinkService,
          eventBus: pdfEventBus,
          l10n: pdfjsViewer.NullL10n,
          renderer: "canvas",
          removePageBorders: true
        });

        pdfLinkService.setDocument(pdfDocument, null);
        pdfLinkService.setViewer(pdfViewer);

        pdfViewer.setDocument(pdfDocument);
        setViewer(pdfViewer);

        // Events
        pdfViewer.eventBus.on("pagesinit", (e: any) => {
          // We can use pdfViewer now, e.g. let's change default scale.
          pdfViewer.currentScaleValue = "page-width";
          console.log("pagesinit", e);
          setPercent(100);
          setLoading(false);
        });

        pdfViewer.eventBus.on("textlayerrendered", (e: any) => {
          console.log("textlayerrendered");
          if (e.source.textLayerDiv) {
            appendCanvasLayer(e.source.textLayerDiv);
          }
        });

        pdfViewer.eventBus.on("pagechanging", (pageChange: PageChangeType) => {
          console.log(pageChange);
          setState((prev) => ({ ...prev, pageChange }));
        });
      }
    };
    load();

    return () => {
      if (pdfViewer) {
        if (pdfViewer.pdfDocument) {
          pdfViewer.pdfDocument.destroy();
          pdfViewer.pdfDocument.cleanup();
          console.log("Document cleanup");
        }
        if (pdfViewer.eventBus) {
          pdfViewer.eventBus?.off("textlayerrendered", () => {});
          pdfViewer.eventBus?.off("pagechanging", () => {});
          pdfViewer.eventBus?.off("pagesinit", () => {});
          console.log("eventBus cleanup");
        }
        pdfViewer.cleanup();
        console.log("cleaned up");
      }
    };
  }, [appendCanvasLayer, fileUrl, onExpiredUrl]);

  const zoomButtonsToolbar = (
    <div
      className="flex flex-col rounded-lg space-y-4 absolute left-2 bottom-4 p-3 bg-gray-200 bg-opacity-80 border border-solid "
      style={{ zIndex: 100 }}
    >
      <MinusOutlined
        className="p-2 cursor-pointer rounded bg-gray-300"
        onClick={() => {
          if (viewerPdf) {
            viewerPdf.decreaseScale(scale);
          }
        }}
      />
      <PlusOutlined
        className="p-2 cursor-pointer rounded bg-gray-300"
        onClick={() => {
          if (viewerPdf) {
            viewerPdf.increaseScale(scale);
          }
        }}
      />
    </div>
  );

  return (
    <ErrorBoundary>
      <div className="relative pdf-viewer-ciq bg-gray-400 z-10 w-full h-[calc(100%-28px)] justify-center items-center overflow-auto">
        <div className="viewerContainer" ref={viewerContainerRef}>
          <div id="viewer" ref={viewerRef} className="pdfViewer" />
        </div>
        {loading ? <PdfLoaderDiv percent={percent} /> : zoomButtonsToolbar}
      </div>
    </ErrorBoundary>
  );
}
export default CiqPdfViewer;
