import { LinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import LinkIcon from "components/svg-icons/link-icon";

function FeatureItem(props: {
  number: string;
  title: string;
  onUnlink: (feature: any) => void;
}) {
  const { number, title, onUnlink } = props;
  return (
    <div className="w-full bg-white border border-solid border-[#3B3B3B4D] text-xs p-2 bg-transparent m-0 text-left space-y-1">
      <div className="w-full flex items-center gap-x-1">
        <div className="shrink-0 flex items-center justify-center">
          <LinkIcon />
        </div>
        <div className="grow text-gray-500 truncate" title={number}>
          {number}
        </div>
        <div>
          <Button
            type="link"
            size="small"
            className="text-xs ciq-button-2 h-[20px]"
            onClick={onUnlink}
          >
            Unlink <LinkOutlined className="!ml-1" />
          </Button>
        </div>
      </div>
      <div className="truncate" title={title}>
        {title}
      </div>
    </div>
  );
}

export default FeatureItem;
