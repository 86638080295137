import { TEntityMilestone, TFeatureWorkflowInstance } from "entity-app/models";
import { InputEditDataType } from "popups/rename-model/rename-popup";
import { EditOutlined } from "@ant-design/icons";
import { getDaysText } from "utils/utils";
import { EWorkflowStatusDataBlock } from "../../../../../constants";
import { TWidthCalculation } from "../../models";

export default function TimelineDurations(props: {
  milestones: Array<TEntityMilestone>;
  featureWorkflowInstance: TFeatureWorkflowInstance;
  widthCal: TWidthCalculation;
  setIsRenameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRenameDataInput: React.Dispatch<React.SetStateAction<InputEditDataType>>;
  showFloat: boolean;
  disabled: boolean;
}) {
  const {
    milestones,
    featureWorkflowInstance,
    widthCal,
    setIsRenameModalOpen,
    setRenameDataInput,
    showFloat,
    disabled
  } = props;

  const isWorkflowStarted =
    featureWorkflowInstance.workflow_status !==
    EWorkflowStatusDataBlock.WF_NOT_STARTED;

  const floatBlock = showFloat && (
    <div
      key={`Float_${featureWorkflowInstance.id}`}
      className="flex flex-col items-center text-xs space-y-1 w-20 font-semibold -ml-2"
    >
      <div>Float</div>
      <div className="flex space-x-1">
        <div>
          ({featureWorkflowInstance.float}{" "}
          {getDaysText(featureWorkflowInstance.float)})
        </div>
        {!disabled && !isWorkflowStarted && (
          <EditOutlined
            className="pl-1"
            onClick={() => {
              setRenameDataInput({
                value: featureWorkflowInstance.float,
                id: featureWorkflowInstance.id,
                lable: "Float",
                type: "text",
                identityName: "WorkflowInstanceFloat"
              });
              setIsRenameModalOpen(true);
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="flex h-10 pl-[70px]">
      {milestones.map((m) => {
        if (!m.feature_workflow_instance_offsets_as_src.length)
          return <div className="w-14" key={`TimelineDurations_${m.id}`} />;
        const offset = m.feature_workflow_instance_offsets_as_src[0];
        return (
          <div
            key={`TimelineDurations_${m.id}`}
            className="flex flex-col items-center text-xs space-y-1 font-semibold"
            style={{ width: widthCal.timelineWidth }}
          >
            <div>{offset.name}</div>
            <div className="flex space-x-1">
              <div>
                ({offset.duration} {getDaysText(offset.duration)})
              </div>
              {!disabled && !m.actual_date && (
                <EditOutlined
                  className="pl-1"
                  onClick={() => {
                    setRenameDataInput({
                      value: offset.duration,
                      id: offset.id,
                      lable: offset.name,
                      type: "text",
                      identityName: "WorkflowInstanceOffset",
                      warningMessage: isWorkflowStarted
                        ? "Editing this duration will change the planned dates of the subsequent milestones. Are you sure you want to proceed?"
                        : undefined
                    });
                    setIsRenameModalOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
      {floatBlock}
    </div>
  );
}
