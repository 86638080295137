import { Spin } from "antd";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { PartnerBaseRoute } from "../../constants";

function TinyUrlRedirection() {
  const { projectId } = useParams() as any;
  const history = useHistory();

  useEffect(() => {
    if (projectId)
      history.replace(`${PartnerBaseRoute}/#/project/${projectId}/submittals`);
  }, [projectId, history]);
  return (
    <div className="h-screen w-full items-end justify-center">
      <Spin size="large" />
    </div>
  );
}

export default TinyUrlRedirection;
