import AttachmentTag from "components/widgets/attachment-tag";
import UserAvatar from "components/widgets/user-avatar";
import { DateUtils } from "utils/dateutils";

function FeatureActivityCardItem(props: {
  commentData: any;
  onFileClick: (attachment: any) => void;
  workflowStepText: string;
}) {
  const { commentData, onFileClick, workflowStepText } = props;

  const commentOrFileNotPresent =
    commentData?.description?.trim() === "" &&
    !commentData?.attachments?.length;

  if (commentOrFileNotPresent) {
    return null;
  }

  const createdByUserFullName = `${
    commentData.created_by_user?.first_name || ""
  } ${commentData.created_by_user?.last_name || ""}`;

  return (
    <div className="w-full flex gap-x-2 border-0 border-[#00000033] border-b border-solid py-2.5">
      <div>
        <div className="w-6 h-6 overflow-hidden">
          <UserAvatar fullName={createdByUserFullName} classList="text-xs" />
        </div>
      </div>
      <div className="w-full space-y-3">
        <div className="w-full flex items-center justify-between text-xs">
          <div className="font-semibold">{createdByUserFullName}</div>
          <div className="flex items-center gap-x-3 justify-end">
            <div className="font-semibold">{workflowStepText}</div>
            <div className="text-gray-500">
              {DateUtils.format(commentData.created_at)}
            </div>
          </div>
        </div>
        <div className="text-[13px]">{commentData.description}</div>
        <div className="flex flex-wrap items-center gap-x-1.5 gap-y-1.5">
          {commentData.attachments?.map((attach: any) => {
            return (
              <AttachmentTag
                key={attach.id}
                item={{ name: attach.file_name }}
                onFileClick={() => {
                  onFileClick(attach);
                }}
              />
            );
          })}
        </div>
        {/* <div className="flex justify-end text-xs">Edit</div> */}
      </div>
    </div>
  );
}

export default FeatureActivityCardItem;
