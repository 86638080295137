/* eslint-disable no-plusplus */
import "./add-company.scss";
import TextArea from "antd/lib/input/TextArea";
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useSubscription } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Form,
  FormRule,
  Input,
  message,
  Modal,
  Row
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { ErrorMessages, Regex } from "constants/index";
import { useEffect, useState } from "react";
import {
  MUTATION_INSERT_COMPANY,
  MUTATION_UPDATE_COMPANY,
  MUTATION_UPDATE_COMPANY_POC,
  MUTATION_INSERT_POC_TO_COMPANY
} from "services/graphQL/mutations";
import { SUBSCRIPTION_LEVEL_COMPANY_LIST } from "services/graphQL/subscriptions";

function AddCompany(props: {
  editCompany?: any;
  isFormEditOnly?: boolean;
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  modelHeader: any;
}) {
  const {
    editCompany,
    isFormEditOnly = true,
    setDrawerOpen,
    showDrawerOpen,
    modelHeader
  } = props;

  const [form] = Form.useForm();
  const { data: companies } = useSubscription(SUBSCRIPTION_LEVEL_COMPANY_LIST, {
    shouldResubscribe: true
  });

  const [isCompanyNameExist, setCompanyNameExist] = useState(false);
  const [addCompany, { error: addCompanyError }] = useMutation<any>(
    MUTATION_INSERT_COMPANY
  );
  const [insertPoc] = useMutation<any>(MUTATION_INSERT_POC_TO_COMPANY);
  const [updatePoc] = useMutation<any>(MUTATION_UPDATE_COMPANY_POC);

  const [updateCompanyData] = useMutation<any>(MUTATION_UPDATE_COMPANY);
  const [responseLoading, setResponseLoading] = useState(false);
  const [isFormEditable, setFormEditable] = useState(isFormEditOnly);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const existingPocArr: any[] = [];
  const deletedPocArr: any[] = [];

  useEffect(() => {
    setFormEditable(isFormEditOnly);

    editCompany?.trade_partner_pocs.forEach(async (poc: any) => {
      const pocmap = poc;
      pocmap.isOldPoc = true;
      existingPocArr.push(pocmap);
    });
  }, [editCompany?.trade_partner_pocs, isFormEditOnly, existingPocArr]);

  async function deletePOCList() {
    if (deletedPocArr.length > 0) {
      const deletedItems: any = [];

      deletedPocArr.forEach((poc) => {
        const item = existingPocArr.find(
          (existingItem) => existingItem.id === poc.id
        );
        if (item) {
          deletedItems.push(item);
        }
      });

      if (deletedItems.length > 0) {
        for (let i = 0; i < deletedItems.length; i++) {
          const poc: any = deletedItems[i];
          const variables = {
            pk_columns: { id: poc.id },
            _set: { deleted: true }
          };
          // eslint-disable-next-line no-await-in-loop
          const updatePocResponse = await updatePoc({
            variables
          });

          if (!updatePocResponse.data?.update_trade_partner_poc_by_pk) {
            setResponseLoading(false);
            message.error(ErrorMessages.CompanyUpdateFailedMsg);
            return;
          }
        }
      }
    }
  }

  async function updatePOCList(values: any) {
    const pocs: [] = values.trade_partner_pocs;
    const newPOCArr: any = [];

    for (let i = 0; i < pocs.length; i++) {
      const poc: any = pocs[i];
      const pocMap: any = {};
      const existingPOC = existingPocArr.filter((c) => c.id === poc.id);
      if (existingPOC.length > 0) {
        // Updating existing POC
        Object.keys(poc).forEach((key: string) => {
          if (existingPOC[0][key] !== poc[key]) {
            pocMap[key] = poc[key];
          }
        });
        if (Object.entries(pocMap).length > 0) {
          setResponseLoading(true);
          const variables = {
            pk_columns: { id: poc.id },
            _set: pocMap
          };

          // eslint-disable-next-line no-await-in-loop
          const updatePocResponse = await updatePoc({
            variables
          });

          if (!updatePocResponse.data?.update_trade_partner_poc_by_pk) {
            setResponseLoading(false);
            message.error(ErrorMessages.CompanyUpdateFailedMsg);
            return;
          }
        }
      } else {
        // Adding New Added POC to existing Company
        Object.keys(poc).forEach((key: string) => {
          if (poc[key] && poc[key] !== undefined) {
            pocMap[key] = poc[key];
          }
        });
        if (Object.keys(pocMap).length > 0) {
          pocMap.vendor_id = editCompany.id;
          newPOCArr.push(pocMap);
        }
      }
    }

    // Adding New Added POC to existing Company
    if (newPOCArr.length > 0) {
      const addPOCResponse = await insertPoc({
        variables: { objects: newPOCArr }
      });
      if (!addPOCResponse.data) {
        setResponseLoading(false);
        message.error(ErrorMessages.CompanyUpdateFailedMsg);
      }
    }
  }

  const onFinish = async (values: any) => {
    if (isCompanyNameExist) return;
    const company: any = {};
    const pocs: any = [];
    if (editCompany) {
      setResponseLoading(true);
      await updatePOCList(values);
      await deletePOCList();

      Object.keys(values).forEach((key: string) => {
        if (key !== "trade_partner_pocs") {
          company[key] = values[key];
        }
      });

      if (
        company.name === editCompany.name &&
        company.address === editCompany.address
      ) {
        setResponseLoading(false);
        setDrawerOpen(false);
        message.success(ErrorMessages.CompanyUpdatedMsg);
        return;
      }

      if (
        Object.entries(company).length > 0 &&
        (company.name !== editCompany.name ||
          company.address !== editCompany.address)
      ) {
        const variables = {
          pk_columns: { id: editCompany.id },
          _set: company
        };
        const updateCompanyDataResponse = await updateCompanyData({
          variables
        });
        setResponseLoading(false);
        if (
          updateCompanyDataResponse.data?.update_subscription_vendors_by_pk?.id
        ) {
          setDrawerOpen(false);
          setResponseLoading(false);
          message.success(ErrorMessages.CompanyUpdatedMsg);
        } else {
          message.error(ErrorMessages.CompanyUpdateFailedMsg);
        }
      }
    } else {
      Object.keys(values).forEach((key: string) => {
        if (values[key]) {
          if (key === "trade_partner_pocs") {
            const users: [] = values[key];
            users.forEach((user: any) => {
              const userdict: any = {};
              Object.keys(user).forEach((pockey: string) => {
                if (user[pockey] || user[key] !== undefined) {
                  userdict[pockey] = user[pockey];
                }
              });
              if (Object.keys(userdict).length > 0) {
                pocs.push(userdict);
              }
            });
            if (pocs.length > 0) {
              company[key] = { data: pocs };
            }
          } else {
            company[key] = values[key];
          }
        }
      });
      setResponseLoading(true);
      const addCompanyResponse = await addCompany({
        variables: { object: company }
      });

      setResponseLoading(false);
      if (addCompanyResponse.data?.insert_subscription_vendors_one?.id) {
        form.resetFields();
        setDrawerOpen(false);
        message.success("Company has been added succssfully.");
      } else if (addCompanyError) {
        message.error(addCompanyError.message);
      }
    }
  };

  const onCompanyNameChange = (event: any) => {
    if (companies?.subscription_vendors) {
      const companylist = companies.subscription_vendors;
      for (let index = 0; index < companylist.length; index++) {
        const element: any = companylist[index];
        if (
          (element.name as string).toLowerCase() ===
          (event.target.value as string).toLowerCase().trim()
        ) {
          setCompanyNameExist(true);
          break;
        } else {
          setCompanyNameExist(false);
        }
      }
    }
  };

  const isEmailFieldReadOnly = (poc: any) => {
    if (editCompany) {
      if (poc) {
        const arr = existingPocArr.filter(
          (item: any) => item.email !== poc.email
        );
        if (arr.length > 0) {
          return false;
        }
        return true;
      }
    }
    return false;
  };

  const companyFormRules: { [key: string]: FormRule[] } = {
    name: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.CompanyNameRequired
      }
    ],
    contactFirstName: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.FirstName
      }
    ],
    contactLastName: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.LastName
      }
    ],
    contactPhone: [
      {
        message: ErrorMessages.PhoneNumber,
        validateTrigger: "onSubmit",
        pattern: Regex.phoneNumber
      }
    ]
  };

  return (
    <Modal
      className="custom-drawer"
      title={modelHeader}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 93px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div className="company-form h-full overflow-y overflow-x-hidden px-3">
        <Form
          preserve={false}
          onFinish={onFinish}
          form={form}
          layout="vertical"
          initialValues={{
            name: editCompany?.name,
            address: editCompany?.address,
            trade_partner_pocs: editCompany?.trade_partner_pocs || [{}]
          }}
        >
          <FormItem
            name="name"
            label="Company name"
            rules={companyFormRules.name}
          >
            <Input
              type="text"
              disabled={!isFormEditable}
              onChange={onCompanyNameChange}
            />
          </FormItem>
          {isCompanyNameExist && (
            <div className="ant-form-item-explain-error mt-[-12px]">
              {ErrorMessages.CompanyNameExist}
            </div>
          )}
          <FormItem name="address" label="Address">
            <TextArea
              autoSize={{ maxRows: 3, minRows: 3 }}
              disabled={!isFormEditable}
            />
          </FormItem>
          <Form.List name="trade_partner_pocs">
            {(fields, { add, remove }) => (
              <>
                <div className="form-item-label">Point of contact</div>
                {fields.map((field, index) => (
                  <Card
                    className="mb-2 relative"
                    key={field.key}
                    size="small"
                    title={`Contact ${index + 1}`}
                    extra={
                      isFormEditable && (
                        <DeleteOutlined
                          onClick={() => {
                            if (
                              form.getFieldValue("trade_partner_pocs")[
                                field.key
                              ]
                            ) {
                              deletedPocArr.push(
                                form.getFieldValue("trade_partner_pocs")[
                                  field.key
                                ]
                              );
                            }
                            remove(field.name);
                          }}
                        />
                      )
                    }
                  >
                    <Row gutter={12}>
                      <Col span={12}>
                        <FormItem
                          {...field}
                          key="first_name"
                          label="First name"
                          name={[field.name, "first_name"]}
                          rules={companyFormRules.contactFirstName}
                        >
                          <Input type="text" disabled={!isFormEditable} />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          {...field}
                          key="last_name"
                          name={[field.name, "last_name"]}
                          label="Last name"
                          rules={companyFormRules.contactLastName}
                        >
                          <Input disabled={!isFormEditable} />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          {...field}
                          key="email"
                          className="mb-0"
                          name={[field.name, "email"]}
                          label="Email"
                          rules={[
                            {
                              type: "email",
                              validateTrigger: "onSubmit",
                              message: ErrorMessages.EmailRequired
                            }
                          ]}
                        >
                          <Input
                            disabled={isEmailFieldReadOnly(
                              form.getFieldValue("trade_partner_pocs")[
                                field.key
                              ]
                            )}
                          />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          {...field}
                          key="phone"
                          className="mb-0"
                          name={[field.name, "phone"]}
                          label="Phone number"
                          rules={companyFormRules.contactPhone}
                        >
                          <Input disabled={!isFormEditable} />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          {...field}
                          key="id"
                          name={[field.name, "id"]}
                          hidden
                        />
                      </Col>
                    </Row>
                  </Card>
                ))}
                {isFormEditable && (
                  <div className="text-right">
                    <Button onClick={() => add()} size="small">
                      Add another contact
                    </Button>
                  </div>
                )}
              </>
            )}
          </Form.List>
          {isFormEditable && (
            <div className="mt-4 flex justify-end">
              <Button
                htmlType="submit"
                type="primary"
                disabled={responseLoading || isCompanyNameExist}
                loading={responseLoading}
              >
                {editCompany ? "Update Company" : "Add Company"}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}
export default AddCompany;
