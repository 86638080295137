import EntityDateblock from "entity-app/shared-components/entity-dateblock";
import { TFeatureWorkflowInstance } from "entity-app/models";
import EntityRiskStatementView from "entity-app/shared-components/entity-dateblock/components/entity-risk-statement/entity-risk-statement-view";
import FeatureToFeatureLinksViewPanel from "components/feature-to-feature-links-view-panel";
import ErrorBoundary from "components/error-boundary";
import BidPackagePlanPanel from "components/bid-package-plan-panel";
import ProjectWorkflowTemplateDropdown from "../workflow-template-dropdown";

function HeadingDateBlock(props: {
  featureInstanceData: any;
  actions: any;
  disabled: boolean;
}) {
  const { featureInstanceData, actions, disabled } = props;
  if (!featureInstanceData) return null;
  const { feature_workflow_instance: featureWorkflowInstance } =
    featureInstanceData as {
      feature_workflow_instance: TFeatureWorkflowInstance;
    };

  return (
    <div className="flex w-full h-14 justify-between items-center px-4 py-2 text-[#000000D9]">
      <div className="flex items-center">
        <div className="uppercase text-sm font-bold">BID PACKAGE SCHEDULE</div>
        <div className="pl-8">
          <EntityRiskStatementView
            disabled={disabled}
            actions={actions}
            featureWorkflowInstance={featureWorkflowInstance}
          />
        </div>
      </div>

      <div className="flex items-center space-x-1 font-medium">
        <div className="uppercase text-xs font-bold">BID PACKAGE TEMPLATE:</div>
        <ProjectWorkflowTemplateDropdown
          disabled={disabled}
          actions={actions}
          featureId={4}
          featureWorkflowInstance={featureWorkflowInstance}
        />
      </div>
    </div>
  );
}

function FeatureLinkedTopSection(props: {
  featureInstanceData: any;
  actions: any;
  disabled: boolean;
}) {
  const { featureInstanceData, actions, disabled } = props;
  return (
    <div className="w-full h-[470px] border border-[#3b3b3b80] border-solid flex">
      <div className="w-[200px] shrink-0 border-0 border-r border-solid border-[#3b3b3b80]">
        <ErrorBoundary>
          <FeatureToFeatureLinksViewPanel featureId={featureInstanceData?.id} />
        </ErrorBoundary>
      </div>
      <div className="grow overflow-auto border-0 border-r border-solid border-[#3b3b3b80]">
        <HeadingDateBlock
          disabled={disabled}
          actions={actions}
          featureInstanceData={featureInstanceData}
        />
        <div className="overflow-auto px-1">
          <EntityDateblock
            disabled={disabled}
            actions={actions}
            workflowInstanceMilestones={
              featureInstanceData?.workflow_instance_milestones
            }
            featureWorkflowInstance={
              featureInstanceData?.feature_workflow_instance
            }
            showFloat={featureInstanceData?.feature?.show_float}
          />
        </div>
      </div>
      <div className="w-[300px] shrink-0 overflow-y-auto">
        <ErrorBoundary>
          <BidPackagePlanPanel
            disabled={disabled}
            refetchFeatureInstanceData={actions.refetchFeatureInstanceData}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default FeatureLinkedTopSection;
