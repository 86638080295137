import { dateCellRenderer, userOrgCellRenderer } from "components/cell-renders";
import { DateUtils } from "utils/dateutils";

export const getSubmittalFormattedValues = (params: any) => {
  const { value, column, node, context } = params;
  const { data } = node;

  switch (true) {
    case column.colId === "submittal_id":
      try {
        return context.projectDetails?.spec_section && data.spec_no
          ? `${data.spec_no} - ${value}`
          : `${value}`;
      } catch (ex) {
        return "";
      }
      break;

    case column.colId === "status":
      try {
        const dataMap = column.colDef.cellRendererParams.dataMapVar;
        const fieldLabel = column.colDef.cellRendererParams.labelVar;
        return context[dataMap][value][fieldLabel];
      } catch (ex) {
        return "";
      }
      break;

    case column.colId === "assignee":
      try {
        const userData = userOrgCellRenderer({
          data,
          userInput: column.userProvidedColDef.cellRendererParams.userInput
        });
        if (!userData.fullName || !userData.fullName.trim()) return "";
        return `${userData.fullName} (${userData.userOrg})`;
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "custom_spec_section":
      try {
        if (!data) return "";
        let specSectionStr = params.data.spec_no || "";
        if (data.spec_name) {
          specSectionStr += ` - ${params.data.spec_name}`;
        }
        return specSectionStr;
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "dueDate":
      return data.date_block_submittals.length
        ? DateUtils.format(data.date_block_submittals[0].due_date)
        : "";
      break;
    case column.colId === "risk_assessment":
      return data?.date_block_submittals?.length
        ? data.date_block_submittals[0].risk_assessment
        : "";
      break;
    case column.colId === "linked_tasks":
      try {
        const tasks = value || [];

        const formattedItems = tasks.map((item: any) => {
          const name = item.activity_id
            ? `${item.activity_id} - ${item.task_name}`
            : item.task_name;
          return name;
        });

        return formattedItems.join("\n");
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "linked_materials":
      try {
        const materials = value || [];
        if (materials.length === 1 && materials[0].implicit) {
          const submittalRefrenceId =
            context.projectDetails?.spec_section && data.spec_no
              ? `${data.spec_no} - ${data.submittal_id}`
              : data.submittal_id;

          return `Material for Submittal ${submittalRefrenceId}`;
        }

        const formattedItems = materials
          .filter((material: any) => !material.implict)
          .map((item: any) => {
            return item.name;
          });

        return formattedItems.join("\n");
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "BIC_GroupBy":
      try {
        return data.responsible_contractor_name;
      } catch (ex) {
        return "";
      }
      break;
    default:
      return value ?? "";
  }
};

export const getMaterialFormattedValues = (params: any) => {
  const { value, column, node } = params;
  const { data } = node;

  switch (true) {
    case column.colId === "custom_spec_section":
      try {
        let specSectionStr = data.spec_section_no || "";
        if (data.spec_section_name) {
          specSectionStr += ` - ${data.spec_section_name}`;
        }
        return specSectionStr;
      } catch (ex) {
        return "";
      }
    case column.colId === "name":
      try {
        return node.data.name || "";
      } catch (ex) {
        return "";
      }
    case column.colId === "dueDate":
      return data?.date_block_materials.length
        ? DateUtils.format(data.date_block_materials[0]?.due_date)
        : "";
      break;

    case column.colId === "assignee" || column.colId === "gc_representative":
      try {
        const userData = userOrgCellRenderer({
          data,
          userInput: column.userProvidedColDef.cellRendererParams.userInput
        });
        return userData.userOrg
          ? `${userData.fullName} (${userData.userOrg})`
          : userData.fullName;
      } catch (ex) {
        return "";
      }
    case column.colId === "linked_tasks":
      try {
        const tasks = value || [];

        const formattedItems = tasks.map((item: any) => {
          const name = item.activity_id
            ? `${item.activity_id} - ${item.task_name}`
            : item.task_name;
          return name;
        });

        return formattedItems.join("\n");
      } catch (ex) {
        return "";
      }
    case column.colId === "linked_submittals":
      try {
        const submittals = value || [];
        const formattedItems = submittals.map((item: any) => {
          return item.name;
        });

        return formattedItems.join("\n");
      } catch (ex) {
        return "";
      }
    case column.colId === "next_planned_deadline":
      try {
        if (!params.value) return "";
        return DateUtils.format(params.value);
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "risk_assessment":
      return data?.date_block_materials?.length
        ? data.date_block_materials[0].risk_assessment
        : "";
    case column.colDef.cellRenderer === dateCellRenderer:
      try {
        return value ? DateUtils.format(value) : "";
      } catch (ex) {
        return "";
      }
    case column.colId === "BIC_GroupBy":
      try {
        return data.responsible_contractor_name;
      } catch (ex) {
        return "";
      }
      break;
    default:
      return value ?? "";
  }
};
