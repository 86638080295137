/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-array-index-key */
import { Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import classNames from "classnames";
import "./submittal-micro-viz.scss";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";

type TSubmittalMicroVisualiserProps = {
  size?: "sm" | "md" | "lg";
  gc_reviewer_id: string;
  design_reviewer_id: string;
  submittal_history: any;
  vendorInfo?: any;
  disabledTooltip?: boolean;
};

const sizes = {
  sm: 16,
  md: 24,
  lg: 32
};

const getPendingStages = (lastStage: string) => {
  const stages = ["NA", "SC", "GC", "D", "DO"];

  const startFrom = stages.indexOf(lastStage);

  const pendingStages: any = [];
  for (let s = startFrom + 1; s < stages.length; s++) {
    const alphabet = stages[s].includes("-")
      ? stages[s].split("-")[1]
      : stages[s];
    pendingStages.push({
      statusAlphabet: alphabet,
      stageInProgress: !!(lastStage === "NA" && alphabet === "NA"),
      stageCompleted: false,
      hId: stages[s]
    });
  }
  return pendingStages;
};

function TooltipContents({ history, tooltipData }: any) {
  const { actionInfo } = tooltipData;
  const createdAt = DateUtils.format(history.created_at, DATE_FORMAT_MMDDYYYY);

  return (
    <div className="text-xs">
      <div>{createdAt}</div>
      <hr />
      {actionInfo && <div>{actionInfo}</div>}
      {tooltipData.comment && (
        <>
          <hr />
          <div>Comment: {tooltipData.comment}</div>
        </>
      )}
    </div>
  );
}

function VizBlock({ blockDetails, isStart, isEnd }: any) {
  const className = classNames("viz-block", {
    "viz-block--in-progress": blockDetails.stageInProgress,
    "viz-block--completed-rejected":
      blockDetails.stageCompleted && blockDetails.hasRejected,
    "viz-block--completed-not-rejected":
      blockDetails.stageCompleted && !blockDetails.hasRejected,
    "viz-block--first": isStart,
    "viz-block--last": isEnd
  });

  return (
    <div className={className}>
      <span>
        {blockDetails.statusAlphabet === "DO" ? (
          <>&#10003;</>
        ) : (
          blockDetails.statusAlphabet
        )}
      </span>
      {blockDetails.cancelled && <span className="viz-block--cancelled" />}
    </div>
  );
}

export default function SubmittalMicroVisualiser(
  props: TSubmittalMicroVisualiserProps
) {
  const {
    size = "md",
    gc_reviewer_id,
    design_reviewer_id,
    submittal_history,
    vendorInfo,
    disabledTooltip = false
  } = props;

  // console.log("vendorInfo ", vendorInfo);

  const [vizStack, setVizStack] = useState<any>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showCount, setShowCount] = useState<number>(0);
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [showSliderButtons, setShowSliderButtons] = useState<boolean>(false);

  const getAssigneeUserType = useCallback(
    (assignee: string) => {
      if (assignee === gc_reviewer_id) return "GC";
      if (assignee && assignee === design_reviewer_id) return "DR";

      return null;
    },
    [design_reviewer_id, gc_reviewer_id]
  );

  const checkForRejection = useCallback(
    (historyItem: any, idx: any, allHistoryItems: any) => {
      if (historyItem.status === 2) {
        // when GC immediately rejects submittal
        if (allHistoryItems[idx + 2] && allHistoryItems[idx + 2].status === 2) {
          return {
            rejected: true,
            rejectorIndex: idx + 1
          };
        }
        // when GC rejects submittal at his final stage... search for that event as there can be variable no of other events in between
        for (let h = idx + 1; h < allHistoryItems.length; h++) {
          if (allHistoryItems[h].status === 2) {
            return {
              rejected: true,
              rejectorIndex: h - 1
            };
          }
        }

        return {
          rejected: false,
          rejectorIndex: null
        };
      }
      return {
        rejected: false,
        rejectorIndex: null
      };
    },
    []
  );

  const getUserInfoString = useCallback(
    (historyItem: any, userType: "GC" | "SC" | "DR") => {
      try {
        if (historyItem.assignee_user) {
          const fullName = `${historyItem?.assignee_user?.first_name} ${historyItem?.assignee_user?.last_name}`;
          let companyName = "";
          if (historyItem?.assignee_user?.project_users) {
            companyName = historyItem?.assignee_user?.project_users[0]
              .subscription_vendor
              ? historyItem?.assignee_user?.project_users[0].subscription_vendor
                  .name
              : historyItem.assignee_user.subscription_users[0].subscription
                  .name;
          }
          return companyName ? `${fullName}, ${companyName}` : fullName;
        }
        let POCCompanyName = "";
        if (vendorInfo && historyItem.assignee_unregistered) {
          if (userType === "SC") {
            POCCompanyName = `, ${vendorInfo?.responsibleContractorCompanyName}`;
          }
          if (userType === "DR") {
            POCCompanyName = `, ${vendorInfo?.designReviewerCompanyName}`;
          }
          return `${historyItem.assignee_unregistered}${POCCompanyName}`;
        }
      } catch (ex: any) {
        console.log("getUserInfoString: ", ex.message);
      }
      return "-";
    },
    [vendorInfo]
  );

  const generateVizBlocks = useCallback(
    (historyItems: any) => {
      const vizBlocks: any = [];

      if (historyItems.length === 0) {
        return getPendingStages("NA");
      }

      for (let h = 0; h < historyItems.length; h++) {
        if (historyItems.length === 1 && historyItems[h].status === 1) {
          vizBlocks.push({
            status: historyItems[h].status,
            statusAlphabet: "SC",
            hId: historyItems[h].id,
            stageInProgress: false,
            stageCompleted: historyItems.length > 1
            // originalHistoryItem: historyItems[h]
          });
        }
        if (historyItems[h].status === 2) {
          const rejection = checkForRejection(historyItems[h], h, historyItems);
          const stageInProgress = !historyItems[h + 1];
          const tooltipData: any = {};
          if (stageInProgress) {
            tooltipData.actionInfo = `Assigned to ${getUserInfoString(
              historyItems[h],
              "SC"
            )}`;
            tooltipData.comment =
              historyItems[h - 1].status === 1 ? historyItems[h].comment : null;
          }
          if (!stageInProgress) {
            tooltipData.actionInfo = `Submitted by ${getUserInfoString(
              historyItems[h],
              "SC"
            )}`;
            tooltipData.comment = historyItems[h + 1].comment;
          }

          vizBlocks.push({
            status: historyItems[h].status,
            statusAlphabet: "SC",
            hId: historyItems[h].id,
            hasBeenRejected: rejection.rejected,
            rejectorIndex: rejection.rejectorIndex,
            cancelled: rejection.rejected,
            stageInProgress,
            stageCompleted: !stageInProgress,
            originalHistoryItem: historyItems[h],
            tooltipData
          });
        }
        if (
          historyItems[h].status === 3 &&
          getAssigneeUserType(historyItems[h].assignee) === "GC"
        ) {
          let hasRejected = false;
          if (historyItems[h + 1] && historyItems[h + 1].status === 2) {
            // the initial submittal was rejected by GC
            hasRejected = true;
          }

          let hasBeenRejected = false;
          if (
            (historyItems[h + 1] &&
              historyItems[h + 1].status === 3 &&
              historyItems[h + 2] &&
              historyItems[h + 2].status === 3 &&
              historyItems[h + 2] &&
              getAssigneeUserType(historyItems[h + 2].assignee) === "GC" &&
              historyItems[h + 2].submittal_response_id > 4) ||
            (historyItems[h + 1] &&
              historyItems[h + 1].status === 3 &&
              historyItems[h + 1].submittal_response_id > 4)
          ) {
            hasBeenRejected = true;
          }

          let isFinalStage = false;
          if (
            (historyItems[h].status >= 3 &&
              historyItems[h].submittal_response_id <= 4 &&
              historyItems[h - 1] &&
              getAssigneeUserType(
                historyItems[h - 1].assignee ||
                  historyItems[h - 1].assignee_unregistered
              ) === "DR") ||
            (historyItems[h + 1] && historyItems[h + 1].status === 4)
          ) {
            isFinalStage = true;
          }

          const stageInProgress = !historyItems[h + 1];
          const stageCompleted = !!historyItems[h + 1];

          const tooltipData: any = {};
          if (stageInProgress) {
            tooltipData.actionInfo = `In Review with ${getUserInfoString(
              historyItems[h],
              "GC"
            )}`;
            tooltipData.comment = null;
          }

          if (stageCompleted) {
            if (isFinalStage) {
              tooltipData.actionInfo = "Closed & Distributed";
              tooltipData.comment = historyItems[h + 1].comment;
            } else {
              const nextHistoryItem = historyItems[h + 1];
              let responseText = "";
              if (nextHistoryItem.submittal_response_id > 4) {
                responseText = "Rejected";
              } else {
                responseText = "Approved";
              }

              tooltipData.actionInfo = `${responseText} by ${getUserInfoString(
                historyItems[h],
                "GC"
              )}`;
              tooltipData.comment = historyItems[h + 1].comment;
            }
          }

          vizBlocks.push({
            status: historyItems[h].status,
            reviewer: "GC-R",
            statusAlphabet: isFinalStage ? "DO" : "GC",
            hId: historyItems[h].id,
            hasRejected,
            hasBeenRejected,
            cancelled: hasBeenRejected,
            stageInProgress,
            stageCompleted,
            originalHistoryItem: historyItems[h],
            tooltipData
          });
        }
        if (
          historyItems[h].status === 3 &&
          getAssigneeUserType(
            historyItems[h].assignee || historyItems[h].assignee_unregistered
          ) === "DR"
        ) {
          let hasRejected = false;
          if (
            historyItems[h + 1] &&
            historyItems[h + 1].status === 3 &&
            historyItems[h + 1].submittal_response_id > 4 // DR rejects it
          ) {
            // DR rejects GC's process
            hasRejected = true;
          }

          const stageInProgress = !historyItems[h + 1];
          const stageCompleted = !!historyItems[h + 1];

          const tooltipData: any = {};
          if (stageInProgress) {
            tooltipData.actionInfo = `Assigned to ${getUserInfoString(
              historyItems[h],
              "DR"
            )}`;
            tooltipData.comment = null;
          }

          if (stageCompleted) {
            const nextHistoryItem = historyItems[h + 1];
            let responseText = "";
            if (nextHistoryItem.submittal_response_id > 4) {
              responseText = "Rejected";
            } else {
              responseText = "Approved";
            }

            tooltipData.actionInfo = `${responseText} by ${getUserInfoString(
              historyItems[h],
              "DR"
            )}`;
            tooltipData.comment = historyItems[h + 1].comment;
          }

          vizBlocks.push({
            status: historyItems[h].status,
            reviewer: "DR-R",
            statusAlphabet: "D",
            hId: historyItems[h].id,
            hasRejected,
            cancelled: hasRejected,
            stageInProgress,
            stageCompleted,
            originalHistoryItem: historyItems[h],
            tooltipData
          });
        }
      }

      let startCancelling = false;
      vizBlocks.forEach((block: any, index: any) => {
        if (block.hasBeenRejected) startCancelling = true;
        // eslint-disable-next-line no-param-reassign
        block.cancelled = startCancelling;
        if (block.hasRejected) {
          startCancelling = false;

          if (vizBlocks[index + 1] && vizBlocks[index + 1].hasRejected) {
            startCancelling = true;
          }
        }
      });

      let pendingStages: any = [];
      // console.log("vizBlocks ", vizBlocks);
      if (vizBlocks[vizBlocks.length - 1].status !== 4) {
        const lastStageAplhabet: any =
          vizBlocks[vizBlocks.length - 1].statusAlphabet;
        pendingStages = getPendingStages(lastStageAplhabet);
      }

      const finalVizBlocks = [...vizBlocks, ...pendingStages];

      return finalVizBlocks;
    },
    [checkForRejection, getAssigneeUserType, getUserInfoString]
  );

  useEffect(() => {
    const clonedReversedHistoryArr = (submittal_history || [])
      .slice()
      .reverse();

    const filteredHistory = clonedReversedHistoryArr.filter(
      (history: any) => history.status
    );

    // console.log("filteredHistory ", filteredHistory);
    const vizBlocks = generateVizBlocks(filteredHistory);
    // console.log("vizBlocks ", vizBlocks);
    setVizStack(vizBlocks);
  }, [generateVizBlocks, submittal_history]);

  useEffect(() => {
    let numStatusToShow = Math.floor(
      (containerRef.current?.clientWidth || 0) / sizes[size]
    );
    const numStatus = vizStack.length;
    let showNav = false;
    if (numStatus > numStatusToShow) {
      // account for buttons
      showNav = true;
      numStatusToShow -= 2;
    } else {
      // clip at max number of status
      numStatusToShow = numStatus;
    }
    setShowCount(numStatusToShow);
    setViewIndex(vizStack.length - numStatusToShow);
    setShowSliderButtons(showNav);
  }, [vizStack, size]);

  const showPrevious = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setViewIndex((prev) => prev - 1);
    return false;
  };

  const showNext = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setViewIndex((prev) => prev + 1);
    return false;
  };

  const slicedVizStack = vizStack.slice(viewIndex, viewIndex + showCount);
  const isViewingFirst = viewIndex === 0;
  const isViewingLast = viewIndex + showCount === vizStack.length;

  const sizeClassname = classNames({
    "viz-block--small": size === "sm",
    "viz-block--medium": size === "md",
    "viz-block--large": size === "lg"
  });

  return (
    <div ref={containerRef} className={sizeClassname}>
      <div className="flex">
        {showSliderButtons && (
          <button
            type="button"
            className="viz-block--button"
            disabled={isViewingFirst}
            onClick={showPrevious}
          >
            <IconChevronLeft size={sizes[size]} />
          </button>
        )}
        <div className="flex-grow">
          <div
            className="flex"
            style={{ justifyContent: showSliderButtons ? "center" : "auto" }}
          >
            {slicedVizStack.map((item: any, index: number) => {
              const content = (
                <div className="viz-block--wrapper" key={index}>
                  <VizBlock
                    blockDetails={item}
                    isStart={viewIndex === 0 && index === 0}
                    isEnd={
                      index === slicedVizStack.length - 1 &&
                      viewIndex + showCount === vizStack.length
                    }
                    size={size}
                  />
                </div>
              );
              return item.originalHistoryItem ? (
                <Tooltip
                  key={index}
                  title={
                    disabledTooltip ? (
                      ""
                    ) : (
                      <TooltipContents
                        history={item.originalHistoryItem}
                        tooltipData={item.tooltipData}
                      />
                    )
                  }
                >
                  {content}
                </Tooltip>
              ) : (
                content
              );
            })}
          </div>
        </div>
        {showSliderButtons && (
          <button
            type="button"
            className="viz-block--button"
            onClick={showNext}
            disabled={isViewingLast}
          >
            <IconChevronRight size={sizes[size]} />
          </button>
        )}
      </div>
    </div>
  );
}
