import { Button, Spin } from "antd";
import {
  FilterNamesEnum,
  GcDashBoardType,
  NavigationPageEnum
} from "../models";

function NoPlanMessageBox({ text }: { text: any }) {
  return (
    <div className="bottom-border px-2 py-3 flex justify-between w-full items-center">
      <div className="grow">{text}</div>
    </div>
  );
}

function PlanningDataQualityReport({
  dashboardData,
  goToFiltered
}: {
  dashboardData: GcDashBoardType | undefined;
  goToFiltered: (page: NavigationPageEnum, filter: FilterNamesEnum) => void;
}) {
  return (
    <div className="box-border h-96 w-full">
      <div className="box-header">PLANNING DUE</div>
      <div className="overflow-auto h-[340px] pb-10">
        {dashboardData ? (
          <>
            {dashboardData?.submittals?.submittals_with_no_planned_dates ===
              0 &&
              dashboardData?.materials?.materials_with_no_planned_dates ===
                0 && (
                <div className="flex h-full items-center p-2 justify-center text-xs">
                  <div>
                    There are no submittals and materials which require
                    planning.
                  </div>
                </div>
              )}
            {dashboardData?.submittals?.submittals_with_no_planned_dates ? (
              <NoPlanMessageBox
                text={
                  <div>
                    <Button
                      type="link"
                      className="p-0 h-fit px-1"
                      onClick={() => {
                        goToFiltered(
                          NavigationPageEnum.SubmittalLog,
                          FilterNamesEnum.NoPlan
                        );
                      }}
                    >
                      {
                        dashboardData.submittals
                          .submittals_with_no_planned_dates
                      }{" "}
                      {dashboardData.submittals
                        .submittals_with_no_planned_dates === 1
                        ? "Submittal"
                        : "Submittals"}
                    </Button>
                    {dashboardData.submittals
                      .submittals_with_no_planned_dates === 1
                      ? "does"
                      : "do"}{" "}
                    not have planned dates
                  </div>
                }
              />
            ) : null}
            {dashboardData?.materials?.materials_with_no_planned_dates ? (
              <NoPlanMessageBox
                text={
                  <div>
                    <Button
                      type="link"
                      className="p-0 h-fit px-1"
                      onClick={() => {
                        goToFiltered(
                          NavigationPageEnum.MaterialLog,
                          FilterNamesEnum.NoPlan
                        );
                      }}
                    >
                      {dashboardData.materials.materials_with_no_planned_dates}{" "}
                      {dashboardData.materials
                        .materials_with_no_planned_dates === 1
                        ? "Material"
                        : "Materials"}
                    </Button>
                    {dashboardData.materials.materials_with_no_planned_dates ===
                    1
                      ? "does"
                      : "do"}{" "}
                    not have planned dates
                  </div>
                }
              />
            ) : null}
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}
export default PlanningDataQualityReport;
