import { Select } from "antd";
import { AcceptanceStatus } from "constants/index";
import { forwardRef, useState, useImperativeHandle } from "react";

const { Option } = Select;

export const SubmitterSelectCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing, value, context, data } = props;
  const [selectValue, setSelectValue] = useState(value);

  const users = context.projectParticipants.submitterUsers.filter(
    (user: any) => {
      const userId = data.submitter_user_id;
      if (userId && userId === user.id) {
        return true;
      }
      return user.status_id !== AcceptanceStatus.DEACTIVATED;
    }
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!selectValue) return null;
        return selectValue;
      }
    };
  });

  if (!data.responsible_contractor_id) {
    return (
      <div className="w-full h-full flex items-center justify-center text-red-600 text-xs">
        First select a Responsible Contractor
      </div>
    );
  }

  return (
    <Select
      open
      allowClear
      optionFilterProp="label"
      virtual={false}
      style={{ width: "100%" }}
      showSearch
      onChange={(event: any) => {
        setSelectValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    >
      {users
        .filter((user: any) => {
          return user.company.vendor_id === data?.responsible_contractor_id;
        })
        .map((user: any) => {
          if (user.status_id === AcceptanceStatus.DEACTIVATED) {
            return "";
          }
          return (
            <Option key={user.id} value={user.id}>
              <div>
                {user.first_name} {user.last_name}
              </div>
              <div className="text-sm text-gray-500">{user?.company?.name}</div>
            </Option>
          );
        })}
    </Select>
  );
});
