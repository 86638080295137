/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { fabric } from "fabric";
import { PdfConfig } from "../config";
import { ModeMarkup } from "../types";
import { FabricHelper } from "./fabricHelper";

export class FabricCanvasLayer {
  canvas!: fabric.Canvas;

  pageno: number = 0;

  addFabric!: (page_no: number, data: any, type?: string) => void;

  updateMarkup!: (id: string, updatedData: any) => void;

  isObjectSelected = false;

  keyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.canvas?.discardActiveObject();
      this.canvas?.requestRenderAll();
      this.onToolSelect(ModeMarkup.NONE);
    } else if (event.key === "Delete" || event.key === "Backspace") {
      this.deleteSelected(true);
    }
  }

  constructor(
    canvas: fabric.Canvas,
    pageno: number,
    addFabric: (page_no: number, data: any, type?: string) => void,
    updateMarkup: (id: string, updatedData: any) => void
  ) {
    this.canvas = canvas;
    this.pageno = pageno;
    this.updateMarkup = updateMarkup;
    this.addFabric = addFabric;

    this.canvas.on("object:modified", (obj) => {
      if (
        obj.target &&
        obj.target instanceof fabric.Object &&
        obj.target.data
      ) {
        const jsonData = (obj.target as fabric.Object).toDatalessObject([
          "data",
          "perPixelTargetFind"
        ]);
        this.updateMarkup(obj.target.data.id, { data: jsonData });
      }
    });
    this.canvas.on("selection:created", () => {
      this.isObjectSelected = true;
    });

    this.canvas.on("selection:cleared", () => {
      this.isObjectSelected = false;
    });
    document.addEventListener("keyup", this.keyDown.bind(this), false);
  }

  public onToolSelect(selectedMode: ModeMarkup) {
    this.disableCanvasEvents();

    switch (selectedMode) {
      case ModeMarkup.RECTANGLE:
        this.drawRectangle();
        break;
      case ModeMarkup.CIRCLE:
        this.drawCircle();
        break;
      case ModeMarkup.ARROW:
        this.drawArrow();
        break;
      case ModeMarkup.TEXT:
        this.drawText();
        break;
      case ModeMarkup.STAMP:
        this.drawStamp();
        break;
      case ModeMarkup.RULER:
        this.drawRuler();
        break;
      case ModeMarkup.CLOUD_RECTANGLE:
        this.drawCloudRectangle();
        break;
      case ModeMarkup.CALLOUT:
        this.drawCallOut();
        break;
      default:
        break;
    }

    if (selectedMode === ModeMarkup.NONE) {
      this.selectionEnable(true);
    } else {
      this.selectionEnable(false);
    }
    this.canvas.renderAll();
  }

  private addedNewObject(obj: fabric.Object) {
    const data = obj.toDatalessObject(["data", "perPixelTargetFind"]);
    this.addFabric(this.pageno, data);
  }

  private drawRectangle() {
    let rect: fabric.Rect;
    let isDown: boolean = false;
    let origX: number;
    let origY: number;
    this.canvas.on("mouse:down", (o: any) => {
      isDown = true;
      const pointer = this.canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;
      rect = new fabric.Rect({
        left: origX,
        top: origY,
        originX: "left",
        originY: "top",
        width: pointer.x - origX,
        height: pointer.y - origY,
        angle: 0,
        stroke: PdfConfig.color.stroke,
        strokeWidth: 2,
        fill: "",
        selectable: false,
        hasControls: true,
        perPixelTargetFind: true,
        strokeUniform: true
      });

      this.canvas.add(rect);
    });

    this.canvas.on("mouse:move", (o: any) => {
      if (!isDown) return;
      const pointer = this.canvas.getPointer(o.e);

      if (origX > pointer.x) {
        rect.set({ left: Math.abs(pointer.x) });
      }

      if (origY > pointer.y) {
        rect.set({ top: Math.abs(pointer.y) });
      }

      rect.set({
        width: Math.abs(origX - pointer.x),
        height: Math.abs(origY - pointer.y)
      });
      this.canvas.renderAll();
    });

    this.canvas.on("mouse:up", () => {
      isDown = false;
      rect.setCoords(true);
      this.addedNewObject(rect);
      this.onToolSelect(ModeMarkup.NONE);
    });
  }

  private drawCloudRectangle() {
    let tempArc: Array<fabric.Object>;
    let isDown: boolean = false;
    let left = 0;
    let top = 0;
    let width = 20;
    let height = 20;
    let origX: number;
    let origY: number;
    let lastPointer = {
      x: 0,
      y: 0
    };

    this.canvas.on("mouse:down", (o: any) => {
      isDown = true;
      const pointer = this.canvas.getPointer(o.e);
      origX = pointer.x;
      left = pointer.x;
      origY = pointer.y;
      top = pointer.y;
      tempArc = FabricHelper.createCloudRectangle(20, 20, left, top);
      this.canvas.add(...tempArc);
    });

    this.canvas.on("mouse:move", (o: any) => {
      if (!isDown) return;
      const pointer = this.canvas.getPointer(o.e);

      const distanceX = Math.abs(lastPointer.x - pointer.x);
      const distanceY = Math.abs(lastPointer.y - pointer.x);
      if (Math.max(distanceX, distanceY) < 20) return;
      lastPointer = pointer;
      if (origX > pointer.x) {
        left = Math.abs(pointer.x);
      }
      if (origY > pointer.y) {
        top = Math.abs(pointer.y);
      }
      if (tempArc) {
        this.canvas.remove(...tempArc);
      }
      width = Math.abs(origX - pointer.x);
      height = Math.abs(origY - pointer.y);
      tempArc = FabricHelper.createCloudRectangle(width, height, left, top);
      this.canvas.add(...tempArc);
      this.canvas.renderAll();
    });

    this.canvas.on("mouse:up", () => {
      isDown = false;
      if (tempArc) {
        this.canvas.remove(...tempArc);
      }
      const groupCloud = new fabric.Group(tempArc, {
        lockScalingFlip: true,
        lockUniScaling: true,
        name: "my_CloudRectangleGroup",
        type: "group",
        selectable: false,
        perPixelTargetFind: true
      });
      groupCloud.setCoords(true);
      this.canvas.add(groupCloud);
      this.canvas.renderAll();
      this.onToolSelect(ModeMarkup.NONE);
      this.addedNewObject(groupCloud);
    });
  }

  private drawCircle() {
    let circle: fabric.Circle;
    let isDown: boolean = false;
    let orig: { x: number; y: number };
    this.canvas.on("mouse:down", (o: any) => {
      isDown = true;
      orig = this.canvas.getPointer(o.e);
      circle = new fabric.Circle({
        radius: 0,
        startAngle: 0,
        endAngle: 360,
        left: orig.x,
        top: orig.y,
        originX: "center",
        originY: "center",
        stroke: PdfConfig.color.stroke,
        strokeWidth: 2,
        fill: "",
        selectable: false,
        hasControls: true,
        perPixelTargetFind: true,
        strokeUniform: true
      });

      this.canvas.add(circle);
    });

    this.canvas.on("mouse:move", (o: any) => {
      if (!isDown) return;
      const pointer = this.canvas.getPointer(o.e);
      const radius = FabricHelper.distanceBetweenTwoPoint(orig, pointer);
      circle.set({
        radius
      });
      this.canvas.renderAll();
    });

    this.canvas.on("mouse:up", () => {
      isDown = false;
      circle.setCoords(true);
      this.addedNewObject(circle);
      this.onToolSelect(ModeMarkup.NONE);
    });
  }

  private drawArrow() {
    let isDown = false;
    let line: fabric.Line;
    let triangle: fabric.Triangle;
    let deltaX: number;
    let deltaY: number;
    this.canvas.on("mouse:down", (o: any) => {
      isDown = true;
      const pointer = this.canvas.getPointer(o.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];
      line = new fabric.Line(points, {
        fill: PdfConfig.color.stroke,
        stroke: PdfConfig.color.stroke,
        strokeWidth: 2,
        selectable: false,
        evented: false,
        strokeUniform: true
      });

      const centerX = (line.x1! + line.x2!) / 2;
      const centerY = (line.y1! + line.y2!) / 2;
      deltaX = line.left! - centerX;
      deltaY = line.top! - centerY;

      triangle = new fabric.Triangle({
        left: line.x1! + deltaX,
        top: line.y1! + deltaY,
        originX: "center",
        originY: "center",
        selectable: false,
        angle: -45,
        width: 20,
        height: 20,
        fill: PdfConfig.color.stroke,
        type: "triangle"
      });
      this.canvas.add(line, triangle);
    });
    this.canvas.on("mouse:move", (o: any) => {
      if (!isDown) return;
      const pointer = this.canvas.getPointer(o.e);
      line.set({ x2: pointer.x, y2: pointer.y });
      triangle.set({
        left: pointer.x + deltaX,
        top: pointer.y + deltaY,
        angle: FabricHelper.FabricCalcArrowAngle(
          line.x1,
          line.y1,
          line.x2,
          line.y2
        )
      });
      this.canvas.renderAll();
    });
    this.canvas.on("mouse:up", () => {
      isDown = false;

      const group = new window.fabric.Group([line, triangle], {
        lockScalingFlip: true,
        lockUniScaling: true,
        name: "my_ArrowGroup",
        type: "group",
        selectable: false,
        perPixelTargetFind: true
      });
      this.canvas.remove(line, triangle); // removing old object
      this.canvas.add(group);
      this.addedNewObject(group);
      this.onToolSelect(ModeMarkup.NONE);
    });
  }

  private drawText() {
    let iText: fabric.IText | null;
    this.canvas.on("mouse:down", (o: any) => {
      if (iText) {
        iText!.exitEditing();
        iText = null;
        return;
      }
      const pointer = this.canvas.getPointer(o.e);
      iText = FabricHelper.newITextFromText(pointer);
      this.canvas.add(iText);
      this.canvas.setActiveObject(iText);
      iText.enterEditing();
    });

    this.canvas.on("text:editing:exited", () => {
      if (iText) this.addedNewObject(iText);
      iText = null;
      this.onToolSelect(ModeMarkup.NONE);
    });
  }

  private drawStamp() {
    let isDown = false;
    let origX: number;
    let origY: number;
    const url = `${window.origin}/mock-files/stamp.png`;
    console.log(url);
    let stamp = fabric.Image.fromURL(url, (image: fabric.Image) => {
      image.scale(0);
      image.opacity = 0.3;
      stamp = image;
    });
    this.canvas.on("mouse:down", (o: any) => {
      isDown = true;
      const pointer = this.canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;

      stamp.left = pointer.x;
      stamp.top = pointer.y;
      this.canvas.add(stamp);
    });
    this.canvas.on("mouse:move", (o: any) => {
      if (!isDown) return;

      const pointer = this.canvas.getPointer(o.e);

      if (origX > pointer.x || origY > pointer.y) return;

      const d1 = origX - pointer.x;
      const d2 = origY - pointer.y;
      if (d1 < d2) {
        const scale = d1 / stamp.width!;
        stamp.scale(scale);
      } else {
        const scale = d2 / stamp.width!;
        stamp.scale(scale);
      }
      this.canvas.renderAll();
    });
    this.canvas.on("mouse:up", () => {
      stamp.lockUniScaling = true;
      stamp.setCoords(true);
      this.addedNewObject(stamp);
      this.onToolSelect(ModeMarkup.NONE);
    });
  }

  private drawRuler() {
    const dimensionText = new fabric.IText("0' 0\"", {
      top: 0,
      left: 0,
      fontSize: 25,
      fontWeight: "normal"
    });

    let isDown = false;
    let line: fabric.Line;
    let triangle1: any;
    let triangle2: fabric.Triangle;
    let deltaX: any;
    let deltaY: any;

    this.canvas?.on("mouse:down", (o) => {
      isDown = true;
      const pointer = this.canvas.getPointer(o.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];
      line = new fabric.Line(points, {
        strokeWidth: 2,
        fill: PdfConfig.color.stroke,
        stroke: PdfConfig.color.stroke,
        originX: "center",
        originY: "center",
        type: "line",
        selectable: false
      });

      const centerX = (line.x1! + line.x2!) / 2;
      const centerY = (line.y1! + line.y2!) / 2;
      deltaX = line.left! - centerX;
      deltaY = line.top! - centerY;

      triangle1 = new fabric.Triangle({
        left: line.get("x1") + deltaX,
        top: line.get("y1") + deltaY,
        originX: "center",
        originY: "center",
        selectable: false,
        angle: 45,
        width: 20,
        height: 20,
        fill: PdfConfig.color.stroke,
        type: "triangle"
      });
      triangle2 = new fabric.Triangle({
        left: line.get("x1") + deltaX,
        top: line.get("y1") + deltaY,
        originX: "center",
        originY: "center",
        selectable: false,
        angle: 45,
        width: 20,
        height: 20,
        fill: PdfConfig.color.stroke,
        type: "triangle"
      });
      this.canvas?.add(line, triangle1, triangle2);
    });

    this.canvas?.on("mouse:move", (o) => {
      const pointer = this.canvas.getPointer(o.e);
      if (isDown) {
        line.set({
          x2: pointer.x,
          y2: pointer.y
        });
        triangle1.set({
          left: pointer.x + deltaX,
          top: pointer.y + deltaY,
          angle: FabricHelper.FabricCalcArrowAngle(
            line.x2,
            line.y2,
            line.x1,
            line.y1
          )
        });
        triangle2.set({
          angle: FabricHelper.FabricCalcArrowAngle(
            line.x1,
            line.y1,
            line.x2,
            line.y2
          )
        });
      }

      this.canvas?.renderAll();
    });

    this.canvas?.on("mouse:up", () => {
      isDown = false;
      const midPoint = {
        x: (line.x2! + line.x1!) / 2,
        y: (line.y2! + line.y1!) / 2
      };
      dimensionText.set({
        top: midPoint.y,
        left: midPoint.x
      });

      this.canvas.add(dimensionText);
      this.canvas.setActiveObject(dimensionText);
      dimensionText.enterEditing();
      this.canvas.renderAll();
    });

    this.canvas.on("text:editing:exited", () => {
      this.canvas?.remove(line, triangle1, triangle2, dimensionText); // removing old object

      const group = new window.fabric.Group(
        [line, triangle1, triangle2, dimensionText],
        {
          lockScalingFlip: true,
          name: "my_MeasurementGroup",
          type: "group",
          selectable: true,
          perPixelTargetFind: true,
          data: { id: 1 }
        }
      );
      this.canvas.add(group);
      this.addedNewObject(group);
      this.onToolSelect(ModeMarkup.NONE);
    });
  }

  private drawCallOut() {
    let isDown = false;
    let line: fabric.Line;
    let line2: fabric.Line;
    let rect: fabric.Rect;
    const rectWidth = 60;
    const rectHeight = 30;
    let origin: { x: number; y: number };
    let circle: fabric.Circle;
    let iText: fabric.IText;

    this.canvas.on("mouse:down", (o: any) => {
      isDown = true;
      const pointer = this.canvas.getPointer(o.e);
      origin = pointer;
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];
      line = new fabric.Line(points, {
        fill: PdfConfig.color.stroke,
        stroke: PdfConfig.color.stroke,
        strokeWidth: 2,
        selectable: false,
        evented: false,
        strokeUniform: true
      });
      line2 = new fabric.Line(points, {
        fill: PdfConfig.color.stroke,
        stroke: PdfConfig.color.stroke,
        strokeWidth: 2,
        selectable: false,
        evented: false,
        strokeUniform: true
      });

      rect = new fabric.Rect({
        width: rectWidth,
        height: rectHeight,
        left: pointer.x - rectWidth / 2,
        top: pointer.y - rectHeight / 2,
        stroke: PdfConfig.color.stroke,
        strokeWidth: 1,
        fill: "",
        selectable: false
      });

      circle = new fabric.Circle({
        left: pointer.x,
        top: pointer.y,
        originX: "center",
        originY: "center",
        radius: 5,
        fill: PdfConfig.color.stroke,
        stroke: PdfConfig.color.stroke
      });
      this.canvas.add(line, line2, rect, circle);
    });
    this.canvas.on("mouse:move", (o: any) => {
      if (!isDown) return;
      const pointer = this.canvas.getPointer(o.e);
      if (pointer.y - origin.y < rectHeight) {
        line2.set({
          x1: pointer.x,
          y1: pointer.y + rectHeight / 2,
          x2: pointer.x,
          y2: pointer.y + rectHeight * 2
        });
        line.set({ x2: pointer.x, y2: pointer.y + rectHeight * 2 });
        rect.set({
          left: pointer.x - rectWidth / 2,
          top: pointer.y - rectHeight / 2
        });
      } else {
        line2.set({
          x1: pointer.x,
          y1: pointer.y - rectHeight / 2,
          x2: pointer.x,
          y2: pointer.y - rectHeight * 2
        });
        line.set({ x2: pointer.x, y2: pointer.y - rectHeight * 2 });
        rect.set({
          left: pointer.x - rectWidth / 2,
          top: pointer.y - rectHeight / 2
        });
      }
      this.canvas.renderAll();
    });
    this.canvas.on("mouse:up", (o: any) => {
      isDown = false;
      this.canvas.remove(rect);
      const pointer = this.canvas.getPointer(o.e);
      iText = FabricHelper.newITextFromText(pointer);
      this.canvas.add(iText);
      this.canvas.setActiveObject(iText);
      iText.enterEditing();
    });

    this.canvas.on("text:editing:exited", () => {
      this.canvas?.remove(line, line2, circle, rect, iText); // removing old object
      const group = new window.fabric.Group([line, line2, circle, iText], {
        lockScalingFlip: true,
        name: "my_MeasurementGroup",
        type: "group",
        selectable: true,
        perPixelTargetFind: true,
        data: { id: 1 }
      });
      this.canvas.add(group);
      this.canvas.renderAll();
      this.addedNewObject(group);
      this.onToolSelect(ModeMarkup.NONE);
    });
  }

  private selectionEnable(isEnable: boolean) {
    this.canvas.selection = isEnable;
    this.canvas.forEachObject((ele: fabric.Object) => {
      ele.selectable = isEnable;
    });
  }

  deleteSelected(fromKeyboardCommand = false) {
    if (!this.isObjectSelected) return;
    const selection = (this.canvas as fabric.Canvas).getActiveObjects();
    if (selection.length > 0) {
      for (const element of selection) {
        if (fromKeyboardCommand && element.type === "i-text") {
          const iText: fabric.IText = element as fabric.IText;
          if (iText.isEditing) {
            return;
          }
        }
        this.canvas.remove(element);
        this.updateMarkup(element.data.id, { deleted: true });
      }
      this.canvas?.discardActiveObject();
      this.canvas?.requestRenderAll();
    }
  }

  private disableCanvasEvents() {
    this.canvas.off("mouse:down");
    this.canvas.off("mouse:up");
    this.canvas.off("mouse:move");
    this.canvas.off("mouse:created");
    this.canvas.isDrawingMode = false;
    this.canvas.off("text:editing:exited");
  }
}
