/**
 * At the time of printing ag-grid it's header should be printed on each page
 *
 * There is no support from ag-grid. Below solution is refered from
 * https://github.com/ag-grid/ag-grid/issues/2814
 *
 * It will add this code to all existing ag-grid on the page.
 *
 */
export const AddHeaderOnEachPage = () => {
  const agGrids = document.querySelectorAll(
    ".ag-root-wrapper-body.ag-layout-print"
  );

  agGrids.forEach((agGrid) => {
    const table = document.createElement("table");
    table.classList.add("ag-root");
    if (agGrid && agGrid.parentNode) {
      agGrid.parentNode.appendChild(table);
      table.appendChild(agGrid);
    }
    const thead = document.createElement("thead");
    const tr = document.createElement("tr");
    const th = document.createElement("th");

    tr.appendChild(th);
    thead.appendChild(tr);

    const agGridHeader = agGrid.querySelector(".ag-header");
    if (agGridHeader) {
      th.appendChild(agGridHeader);
      table.appendChild(thead);
    }

    // body
    const tbody = document.createElement("tbody");
    const tr2 = document.createElement("tr");
    const td = document.createElement("td");

    tr2.appendChild(td);
    tbody.appendChild(tr2);

    const agGridBody = agGrid.querySelector(".ag-body-viewport");
    if (agGridBody) {
      td.appendChild(agGridBody);
      table.appendChild(tbody);
    }
    // Footer
    const tfoot = document.createElement("tfoot");
    const tr3 = document.createElement("tr");
    const td2 = document.createElement("td");

    tr3.appendChild(td2);
    tfoot.appendChild(tr3);

    const agGridFooter = agGrid.querySelector(".ag-floating-bottom");
    if (agGridFooter) {
      td2.appendChild(agGridFooter);
      table.appendChild(tfoot);
    }
  });
};

export const StartPrinting = () => {
  // Trigger jsreport utility to start printing
  AddHeaderOnEachPage();
  window.JSREPORT_READY_TO_START = true;
};
