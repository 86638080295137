import { Button, Divider, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { ErrorMessages, InfoMessages } from "../constants";

export default function CopyDBTemplate(props: {
  setCopyPopupVisible: (visible: boolean) => void;
  availableTemplateNames: string[];
  template: any;
  onCopy: (payload: any) => void;
}) {
  const { setCopyPopupVisible, availableTemplateNames, template, onCopy } =
    props;

  const [isSavingTemplate, setIsSavingTemplate] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (formValues: any) => {
    setIsSavingTemplate(true);
    const payload: any = {
      variables: {
        name: (formValues.template_name || "").trim(),
        templateId: template.id
      }
    };
    await onCopy(payload);
    setIsSavingTemplate(false);
    setCopyPopupVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      form.validateFields();
    });
  }, [form]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        template_name: `${template.name} (copy)`
      }}
    >
      <div className="flex flex-col text-xs w-72 space-y-2">
        <div className="flex text-xs">
          Provide Template Name for the duplicate.
        </div>
        <div>
          <Form.Item
            shouldUpdate
            name="template_name"
            label="New Name"
            required
            rules={[
              () => ({
                validator(_, value) {
                  if (!value.trim()) {
                    return Promise.reject(
                      new Error(ErrorMessages.templateNameRequiredValidation)
                    );
                  }
                  return Promise.resolve();
                }
              }),
              () => ({
                validator(_, value) {
                  if (
                    !value ||
                    !availableTemplateNames.includes(value.trim().toLowerCase())
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(ErrorMessages.templateNameExists)
                  );
                }
              })
            ]}
          >
            <Input placeholder={InfoMessages.enterTemplateName} />
          </Form.Item>
        </div>
        <Divider className="py-1" />
        <div className="flex justify-end space-x-2 text-xs">
          <Button
            onClick={() => {
              setCopyPopupVisible(false);
            }}
            disabled={isSavingTemplate}
          >
            Cancel
          </Button>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                disabled={
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                htmlType="submit"
                loading={isSavingTemplate}
              >
                Duplicate
              </Button>
            )}
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}
