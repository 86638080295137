import { useMemo } from "react";
import { getGQL } from "utils/utils";
import { COLUMN_HEADERS } from "services/graphQL/ciq-queries";
import { useCIQQuery } from "./ciq-gql-hooks";

export const useSubmittalMaterialLogsHeaders = (gqlClientForProject: any) => {
  const { query: qColumnHeaders } = useMemo(() => {
    return getGQL(COLUMN_HEADERS);
  }, []);
  const { data: responseData, refetch } = useCIQQuery(qColumnHeaders, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const columnHeaders = useMemo(() => {
    let submittalHeadersMap: any = {};
    let materialHeadersMap: any = {};
    if (
      responseData?.project_feature_configurations &&
      responseData?.project_feature_configurations?.length > 0
    ) {
      const resArr: any[] = responseData?.project_feature_configurations;
      submittalHeadersMap = resArr.find((obj) => obj.feature_id === 1);
      materialHeadersMap = resArr.find((obj) => obj.feature_id === 2);

      const submittalHeaderMap: any = {};
      submittalHeadersMap?.field_mapping?.forEach((columnData: any) => {
        const key = columnData?.field_name;
        submittalHeaderMap[key] = columnData?.caption_en;
      });

      const materialHeaderMap: any = {};
      materialHeadersMap?.field_mapping?.forEach((columnData: any) => {
        const key = columnData?.field_name;
        materialHeaderMap[key] = columnData?.caption_en;
      });

      return {
        submittalHeaders: submittalHeadersMap,
        materialHeaders: materialHeadersMap,
        submittalHeaderMap,
        materialHeaderMap
      };
    }
    return {
      submittalHeaders: null,
      materialHeaders: null,
      submittalHeaderMap: {},
      materialHeaderMap: {}
    };
  }, [responseData]);

  return { columnHeaders, refetch };
};
