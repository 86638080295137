import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ProjectContext } from "context/ProjectProvider";
import { EUserTypes } from "constants/index";
import { matchUserProjectRole } from "utils/utils";
import { useProjectParticipants } from "hooks/project-participants";
import FilterForm from "../components/filter-form";

import "./upcoming-submittals.scss";
import UpcomingSubmittalsComponent from "./upcoming-submittals";
import UpcomingMaterialsComponent from "./upcoming-materials";

type ReportFilterType = {
  filter: number;
  groupby: string;
  sortBy: string;
  reportFor: "submittal" | "material";
} | null;

function UpcomingSubmittals() {
  const history = useHistory();
  const { projectId } = useParams() as any;
  const [openFilterForm, setOpenFilterForm] = useState(true);
  const [reportFilter, setReportFilter] = useState<ReportFilterType>();

  const { projectDetails, tokenContents } = useContext(ProjectContext);
  const isLoggedInUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const { projectParticipants } = useProjectParticipants(!isLoggedInUserGC);

  const gotoReports = () => {
    history.push(`/project/${projectId}/reports`);
  };

  return (
    <div className="flex flex-col h-full w-full upcoming-submittal">
      <Space
        className="w-fit px-4 py-2 font-medium space-x-1 cursor-pointer"
        onClick={gotoReports}
      >
        <ArrowLeftOutlined />
        Reports
      </Space>
      <div className="flex flex-col h-full bg-white px-4 py-1">
        {reportFilter && reportFilter.reportFor === "submittal" && (
          <UpcomingSubmittalsComponent
            {...{ reportFilter, setOpenFilterForm, projectDetails }}
          />
        )}
        {reportFilter && reportFilter.reportFor === "material" && (
          <UpcomingMaterialsComponent
            {...{
              reportFilter,
              setOpenFilterForm,
              projectDetails,
              projectParticipants
            }}
          />
        )}
      </div>
      {openFilterForm && (
        <FilterForm
          open={openFilterForm}
          closable={!!reportFilter}
          onCancel={() => {
            setOpenFilterForm(false);
          }}
          onFinish={(e: any) => {
            setOpenFilterForm(false);
            setReportFilter(null); // clear previous so that component rerenders on selecting new combination
            setTimeout(() => {
              setReportFilter(e);
            });
          }}
          onBack={gotoReports}
        />
      )}
    </div>
  );
}

export default UpcomingSubmittals;
