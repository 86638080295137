import { TEntityMilestone, TFeatureWorkflowInstance } from "entity-app/models";
import { useRef } from "react";
import { Button } from "antd";
import DatePickerWithIcon from "components/date-picker-with-icon/date-picker-with-icon";
import { DateUtils } from "utils/dateutils";
import ProgressIndicatorCircle from "components/date-block/progress-indicator-circle";
import { TWidthCalculation } from "../../models";

export default function TimelineIconsOnly(props: {
  milestones: Array<TEntityMilestone>;
  widthCal: TWidthCalculation;
  featureWorkflowInstance: TFeatureWorkflowInstance;
  actions: any;
  showFloat: boolean;
  disabled: boolean;
}) {
  const {
    milestones,
    widthCal,
    featureWorkflowInstance,
    actions,
    showFloat,
    disabled
  } = props;
  const { length } = milestones;

  const rectWidth = 48;
  const circleWidth = 24;
  const lineWidth = (widthCal.timelineWidth - circleWidth - rectWidth) / 2;
  const leftMargin = (widthCal.milestonesWidth - circleWidth) / 2;

  const childRefPlannedDate = useRef<any>();
  const renderExtraFooterDiv = (
    <div className="flex justify-center">
      <Button
        // disabled={cannotChangePlannedDate}
        onClick={() => {
          childRefPlannedDate.current?.close();
          actions.updateFeatureWorkflowInstance({
            id: featureWorkflowInstance.id,
            set: { manual_entry_date: null }
          });
        }}
      >
        Clear Date
      </Button>
    </div>
  );

  const allActualSubmitted = milestones.every((m) => m.actual_date);
  return (
    <div className="flex h-10">
      <div className="shrink-0" style={{ width: leftMargin }} />
      {milestones.map((m, index) => {
        const isNextMileStone = length !== index + 1;
        const isOffest = m.feature_workflow_instance_offsets_as_src?.length > 0;
        const canHaveFloat = !isNextMileStone && showFloat;

        return (
          <div
            key={`TimelineIconsOnly_${m.id}`}
            className="flex relative items-center"
          >
            <ProgressIndicatorCircle
              actualDate={m.actual_date}
              plannedDate={m.planned_date}
            />

            {isOffest && (
              <div className="flex items-center">
                <div className="bl-line" style={{ width: lineWidth }} />
                <div className="bl-rect" />
              </div>
            )}
            {isNextMileStone && (
              <div className="bl-line" style={{ width: lineWidth }} />
            )}

            {canHaveFloat && (
              <div className="flex items-center">
                <div className="bl-line" style={{ width: lineWidth }} />
                <div className="bl-rect bg-[#EBEBEB]" />
              </div>
            )}
          </div>
        );
      })}

      <div className="flex items-center h-full">
        <div className="bl-line w-[34px]" />
        <div className="border border-solid p-0.5 w-fit h-fit flex items-center justify-center ">
          <DatePickerWithIcon
            disabledDate={() => {
              return disabled || allActualSubmitted;
            }}
            value={
              featureWorkflowInstance.manual_entry_date
                ? DateUtils.dateTimeObj(
                    featureWorkflowInstance.manual_entry_date
                  )
                : undefined
            }
            onChange={(date) => {
              if (date) {
                const saveDate = DateUtils.formatDateWithLunchTime(date);
                const set = { manual_entry_date: saveDate };
                actions.updateFeatureWorkflowInstance({
                  id: featureWorkflowInstance.id,
                  set
                });
              }
            }}
            ref={childRefPlannedDate}
            renderExtraFooter={() => {
              return featureWorkflowInstance.manual_entry_date
                ? renderExtraFooterDiv
                : undefined;
            }}
          />
        </div>
      </div>
    </div>
  );
}
