import { ProjectContext } from "context/ProjectProvider";
import { useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { InteractionOutlined } from "@ant-design/icons";
import { DATE_FORMAT_MMDDYYYY_HHMM_A, DateUtils } from "utils/dateutils";
import { Spin } from "antd";
import { QUERY_PROJECT_WORKFLOW_TEMPLATES_ID_NAME } from "services/graphQL/queries";
import { HISTORY } from "services/graphQL/ciq-queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";

const fieldAndName = [
  { field: "trade_partner", key: "trade_partner" },
  { field: "assignee", key: "assignee" },
  { field: "assignee_unregistered", key: "assignee" },
  { field: "gc_representative", key: "gc_representative" },
  { field: "offset_1", key: "name_offset_1" },
  { field: "offset_2", key: "name_offset_2" },
  { field: "offset_3", key: "name_offset_3" },
  { field: "offset_4", key: "name_offset_4" },
  { field: "offset_5", key: "name_offset_5" },
  { field: "offset_6", key: "name_offset_6" },
  { field: "offset_7", key: "name_offset_7" },
  { field: "offset_8", key: "name_offset_8" },
  { field: "offset_9", key: "name_offset_9" },
  { field: "offset_10", key: "name_offset_10" },
  { field: "actual_milestone_1", key: "name_milestone_1" },
  { field: "actual_milestone_2", key: "name_milestone_2" },
  { field: "actual_milestone_3", key: "name_milestone_3" },
  { field: "actual_milestone_4", key: "name_milestone_4" },
  { field: "actual_milestone_5", key: "name_milestone_5" },
  { field: "actual_milestone_6", key: "name_milestone_6" },
  { field: "actual_milestone_7", key: "name_milestone_7" },
  { field: "actual_milestone_8", key: "name_milestone_8" },
  { field: "actual_milestone_9", key: "name_milestone_9" },
  { field: "actual_milestone_10", key: "name_milestone_10" },
  { field: "workflow_template_id", key: "workflow_template_id" },
  { field: "wf_override_reason", key: "wf_override_reason" }
];

type UserType = {
  email: string;
  first_name: string;
  last_name: string;
};

type HistoryType = {
  id: string;
  field: string;
  label: null | string;
  old_value: null | string;
  new_value: null | string;
  user_created: null | UserType;
  created_at: string;
  project_id: null | string;
  old_subscription_vendor: null | { name: string };
  new_subscription_vendor: null | { name: string };
  user_old: null | UserType;
  user_new: null | UserType;
};

function MaterialHistory({
  created_on,
  created_by_user
}: {
  created_on: string;
  created_by_user: {
    email: string;
    first_name: string;
    last_name: string;
  };
}) {
  const [historyList, setHistoryList] = useState<Array<HistoryType>>();
  const { materialId } = useParams() as any;
  const {
    gqlClientForProject,
    columnHeaders: { materialHeaderMap },
    eventLogs
  } = useContext(ProjectContext);

  const { data: historyData, refetch: refetchHistoryData } = useCIQQuery<{
    History: Array<HistoryType>;
  }>(HISTORY, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: {
      where: {
        material_id: { _eq: materialId }
      }
    }
  });
  useEffect(() => {
    if (historyData && historyData.History) {
      const requiredFields = fieldAndName.map((x) => x.field);
      const history = historyData.History.filter((h) =>
        requiredFields.includes(h.field)
      );
      setHistoryList(history);
    }
  }, [historyData]);

  const { data: workflowTemplates, refetch: refetchWorkflowTemplates } =
    useCIQQuery<{
      project_workflow_templates: Array<{ id: string; name: string }>;
    }>(QUERY_PROJECT_WORKFLOW_TEMPLATES_ID_NAME, {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    });

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs.length && previousEventLogs.current !== eventLogs) {
      if (eventLogs.some((e) => e.info.material_ids?.includes(materialId))) {
        /**
         * Calling again 10 second delay for GC , Assignee and trade-parter changes.
         * They are taking deplay so calling twice.
         */
        if (eventLogs.some((e) => e.data_source === "material")) {
          setTimeout(() => {
            refetchHistoryData();
          }, 10000);
        } else {
          /**
           * For dateblock changes it coming immediately
           */
          refetchHistoryData();
        }
      }
      if (
        eventLogs.some((x) => x.data_source === "project_workflow_templates")
      ) {
        refetchWorkflowTemplates(); // Refetching Material Template data
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, materialId, refetchHistoryData, refetchWorkflowTemplates]);

  const getNameFromFields = (history: HistoryType) => {
    if (history.label) return history.label;
    const { field } = history;
    if (field === "workflow_template_id") return "Material Template";
    if (field === "wf_override_reason") return "Early Start";
    const key = fieldAndName.find((x) => x.field === field)?.key;
    const name = materialHeaderMap[key || ""];
    if (name) return name;
    return undefined;
  };

  const getHistoryDiv = (history: HistoryType) => {
    switch (history.field) {
      case "actual_milestone_1":
      case "actual_milestone_2":
      case "actual_milestone_3":
      case "actual_milestone_4":
      case "actual_milestone_5":
      case "actual_milestone_6":
      case "actual_milestone_7":
      case "actual_milestone_8":
      case "actual_milestone_9":
      case "actual_milestone_10":
        return (
          <>
            {history.old_value && (
              <>
                <span className="font-semibold">
                  {DateUtils.format(history.old_value)}{" "}
                </span>
                {history.new_value && "to "}
              </>
            )}
            {history.new_value && (
              <span className="font-semibold">
                {DateUtils.format(history.new_value)}
              </span>
            )}
          </>
        );
      case "offset_1":
      case "offset_2":
      case "offset_3":
      case "offset_4":
      case "offset_5":
      case "offset_6":
      case "offset_7":
      case "offset_8":
      case "offset_9":
      case "offset_10":
        return (
          <>
            <span className="font-semibold">{history.old_value} </span>
            to <span className="font-semibold">{history.new_value}</span> days.
          </>
        );
      case "trade_partner":
        return (
          <>
            {history.old_subscription_vendor && (
              <>
                <span className="font-semibold">
                  {history.old_subscription_vendor?.name}{" "}
                </span>
                to{" "}
              </>
            )}
            <span className="font-semibold">
              {history.new_subscription_vendor?.name}
            </span>
          </>
        );
      case "gc_representative":
      case "assignee":
        return (
          <>
            {history.user_old && (
              <>
                <span className="font-semibold">
                  {history.user_old?.first_name} {history.user_old?.last_name}{" "}
                </span>
                <span className="text-neutral-600 text-xs">
                  ({history.user_old?.email})
                </span>
                {history.user_new && "to "}
              </>
            )}
            {history.user_new && (
              <>
                <span className="font-semibold">
                  {history.user_new?.first_name} {history.user_new?.last_name}{" "}
                </span>
                <span className="text-neutral-600 text-xs">
                  ({history.user_new?.email})
                </span>
              </>
            )}
          </>
        );

      case "assignee_unregistered":
        return (
          <>
            {history.old_value && (
              <>
                <span className="font-semibold">{history.old_value} </span>
                {history.new_value && "to "}
              </>
            )}
            {history.new_value && (
              <span className="font-semibold">{history.new_value}</span>
            )}
          </>
        );
      case "workflow_template_id":
        return (
          <>
            {history.old_value && (
              <>
                <span className="font-semibold">
                  {workflowTemplates?.project_workflow_templates.find(
                    (x) => x.id === history.old_value
                  )?.name || ""}{" "}
                </span>
                {history.new_value && "to "}
              </>
            )}
            {history.new_value && (
              <span className="font-semibold">
                {workflowTemplates?.project_workflow_templates.find(
                  (x) => x.id === history.new_value
                )?.name || ""}
              </span>
            )}
          </>
        );
      case "wf_override_reason":
        return <span className="font-semibold">{history.new_value}</span>;
      default:
        return (
          <div className="text-red-700 font-semibold">Need to add from FE</div>
        );
        break;
    }
  };
  const getMessage = (history: HistoryType) => {
    switch (true) {
      case !history.old_value &&
        !history.old_subscription_vendor &&
        !history.user_old:
        if (
          history.field === "assignee" ||
          history.field === "assignee_unregistered"
        ) {
          return (
            <span> has assigned the material to {getHistoryDiv(history)}</span>
          );
        }
        if (history.field === "gc_representative") {
          return (
            <span>
              {" "}
              has added {getHistoryDiv(history)} as{" "}
              <span className="font-semibold">
                {getNameFromFields(history)}
              </span>
            </span>
          );
        }
        if (history.field === "wf_override_reason") {
          return (
            <span>
              {" "}
              used the {getNameFromFields(history)} functionality, stating the
              reason {getHistoryDiv(history)}
            </span>
          );
        }
        return (
          <span>
            {" "}
            has updated{" "}
            <span className="font-semibold">
              {getNameFromFields(history)}
            </span>{" "}
            to {getHistoryDiv(history)}
          </span>
        );
      case !history.new_value &&
        !history.new_subscription_vendor &&
        !history.user_new:
        return (
          <span>
            {" "}
            has cleared the{" "}
            <span className="font-semibold">{getNameFromFields(history)}.</span>
          </span>
        );
      default:
        return (
          <span>
            {" "}
            has updated the{" "}
            <span className="font-semibold">
              {getNameFromFields(history)}
            </span>{" "}
            from {getHistoryDiv(history)}
          </span>
        );
    }
  };

  const filterUnusedHistoryItems = (x: HistoryType) =>
    !(x.field === "wf_override_reason" && x.old_value);

  return (
    <div className="h-full">
      {historyList ? (
        <div className="h-full">
          {historyList
            ?.filter(filterUnusedHistoryItems)
            .filter(
              (x: HistoryType) =>
                x.new_value ||
                x.new_subscription_vendor ||
                x.old_value ||
                x.old_subscription_vendor ||
                x.user_old ||
                x.user_new
            )
            .map((history: HistoryType) => (
              <div
                key={history.id}
                className="w-full mb-1 border border-solid border-[#00000033] text-three !text-black  px-2 py-2 "
              >
                {getNameFromFields(history) === undefined ? null : (
                  <div className="flex-col leading-relaxed tracking-wide">
                    <div className="flex justify-between">
                      <InteractionOutlined />
                      <div className="text-xs text-neutral-600">
                        {DateUtils.format(
                          history.created_at,
                          DATE_FORMAT_MMDDYYYY_HHMM_A
                        )}
                      </div>
                    </div>
                    <div>
                      <span className="font-semibold">
                        {history.user_created?.first_name}{" "}
                        {history.user_created?.last_name}{" "}
                      </span>
                      <span className="text-neutral-600 text-xs">
                        ({history.user_created?.email})
                      </span>
                      {getMessage(history)}
                    </div>
                  </div>
                )}
              </div>
            ))}
          {created_by_user && created_on && (
            <div className="w-full mb-1 border border-solid border-[#00000033] text-three !text-black  px-2 py-2 ">
              <div className="flex-col leading-relaxed tracking-wide">
                <div className="flex justify-between">
                  <InteractionOutlined />
                  <div className="text-xs text-neutral-600">
                    {DateUtils.format(created_on, DATE_FORMAT_MMDDYYYY_HHMM_A)}
                  </div>
                </div>
                <div>
                  <span className="font-semibold">
                    {created_by_user?.first_name} {created_by_user?.last_name}{" "}
                  </span>
                  <span className="text-neutral-600 text-xs">
                    ({created_by_user?.email})
                  </span>{" "}
                  created the material.
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="h-full max-h-[300px] flex items-center justify-center">
          <Spin />
        </div>
      )}
    </div>
  );
}
export default MaterialHistory;
