/* eslint-disable no-param-reassign */
/* This file helps to interact with server with interceptors */
import axios from "axios";
// eslint-disable-next-line import/no-cycle
import { getAccessToken, getUser, logout } from "./auth";
import { BASE_URL } from "./endpoints";

const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

client.interceptors.request.use((config: any) => {
  const token = getAccessToken();

  if (token !== null) {
    const expirationTime = (getUser() as any).exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      logout();
    }
  }

  if (!config.headers.Authorization && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const noAuthClient = axios.create({
  baseURL: BASE_URL
});

export { client, noAuthClient };
