export interface LTWH {
  left: number;
  top: number;
  width: number;
  height: number;
}

export interface LTWHP extends LTWH {
  pageNumber?: number;
}

export interface Position {
  boundingRect: LTWHP;
  rects: Array<LTWHP>;
  pageNumber: number;
}

export interface Scaled {
  x1: number;
  y1: number;

  x2: number;
  y2: number;

  width: number;
  height: number;

  pageNumber?: number;
}

export interface ScaledPosition {
  boundingRect: Scaled;
  rects: Array<Scaled>;
  pageNumber: number;
  usePdfCoordinates?: boolean;
}

export interface Page {
  node: HTMLElement;
  number: number;
}

export interface Viewport {
  convertToPdfPoint: (x: number, y: number) => Array<number>;
  convertToViewportRectangle: (pdfRectangle: Array<number>) => Array<number>;
  width: number;
  height: number;
}

export enum ModeMarkup {
  NONE,
  RECTANGLE,
  CIRCLE,
  ARROW,
  RULER,
  TEXT,
  STAMP,
  CLOUD_RECTANGLE,
  CALLOUT
}

export interface NewHighlight {
  position: ScaledPosition;
  text: string;
  comment: string;
}

export interface IHighlight extends NewHighlight {
  id: string;
  page_no: number;
  created_at: any;
  created_by_user: {
    first_name: string;
    last_name: string;
  };
}

export type IMarkupDb = {
  type: string;
  id: string;
  page_no: number;
  data: any;
  created_at: any;
  created_by_user: {
    first_name: string;
    last_name: string;
  };
};
