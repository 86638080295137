import React from "react";

export function EllipsisTextOverflow(props: { text: string }) {
  const { text } = props;
  const [width, setWidth] = React.useState(100);
  const textRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.offsetWidth);
    }
  }, [text]);

  return (
    <div ref={textRef}>
      <div
        className="whitespace-nowrap overflow-hidden text-ellipsis"
        style={{ width }}
      >
        {text}
      </div>
    </div>
  );
}
