import { Modal } from "antd";
import { TEditMilestone } from "components/workflow-template-editor/model";

function DeleteConfirmModel({
  setOpen,
  milestone,
  onConfirm
}: {
  milestone: TEditMilestone;
  setOpen: React.Dispatch<React.SetStateAction<TEditMilestone | undefined>>;
  onConfirm: (milestoneId: string) => void;
}) {
  const handleOk = () => {
    onConfirm(milestone.id);
    setOpen(undefined);
  };

  const handleCancel = () => {
    setOpen(undefined);
  };

  return (
    <Modal
      title="DELETE MILESTONE"
      open={!!milestone}
      onOk={handleCancel}
      onCancel={handleOk}
      okButtonProps={{ className: "w-24", type: "default" }}
      cancelButtonProps={{ className: "w-24", type: "primary" }}
      okText="Cancel"
      cancelText="Yes"
      width={450}
      className="workflow-template-editor"
    >
      <div className="w-full flex-col ">
        <p>
          Are you sure you want to delete
          <strong className="px-1">{milestone.name} ?</strong>
          Deleting the milestone will also delete the associated duration,
          present after the milestone.
        </p>

        <div className="font-medium py-1">
          Are you sure you want to proceed?
        </div>
      </div>
    </Modal>
  );
}

export default DeleteConfirmModel;
