import React, { useReducer, useMemo, useEffect } from "react";
import {
  SUBSCRIPTION_INTEGRATIONS,
  SUBSCRIPTION_INTG_CONFIGS,
  SYSTEM_INTEGRATION_CONFIGS
} from "services/graphQL/ciq-queries";

import { useCIQQuerySubscription } from "hooks/ciq-gql-hooks";
import {
  IntegrationsTabSharedContext,
  initialState,
  integrationsTabStateReducer
} from "./integrations-tab-context";

import {
  IntegrationType,
  simplifySubscriptionIntegrationConfigs,
  simplifySystemIntegrationConfigs
} from "./utils";
import IntegrationsList from "./integrations-list";
import ProcoreInitialIntegration from "./procore/initial-integration";
import ProcoreIntegrationDetails from "./procore/integration-details";
import AutodeskInitialIntegration from "./autodesk/initial-integration";
import AutodeskIntegrationDetails from "./autodesk/integration-details";

function IntegrationsTab() {
  const [sharedState, dispatch] = useReducer(
    integrationsTabStateReducer,
    initialState
  );

  const providerValue: any = useMemo(
    () => ({
      sharedState,
      dispatch
    }),
    [sharedState]
  );

  // console.log("Subscription IntegrationsTab state ", sharedState);

  const { data: dataAvailableIntegrations } = useCIQQuerySubscription(
    SUBSCRIPTION_INTEGRATIONS,
    {}
  );

  const { data: dataSystemIntgConfigs } = useCIQQuerySubscription(
    SYSTEM_INTEGRATION_CONFIGS,
    {}
  );

  const { data: dataIntgConfigs } = useCIQQuerySubscription(
    SUBSCRIPTION_INTG_CONFIGS,
    {}
  );

  // console.log("dataIntgConfigs ", dataIntgConfigs);

  const integrationConfigs = useMemo(() => {
    return simplifySubscriptionIntegrationConfigs(
      dataIntgConfigs?.subscription_integration_configs
    );
  }, [dataIntgConfigs]);

  const systemIntegrationConfigs = useMemo(() => {
    return simplifySystemIntegrationConfigs(
      dataSystemIntgConfigs?.system_integration_configs
    );
  }, [dataSystemIntgConfigs]);

  useEffect(() => {
    if (
      !dataAvailableIntegrations ||
      !integrationConfigs ||
      !systemIntegrationConfigs
    )
      return;
    dispatch({
      type: "SET_SHARED_STATE",
      payload: {
        integrationConfigs,
        systemIntegrationConfigs,
        availableIntegrations: dataAvailableIntegrations.integrations
      }
    });
  }, [dataAvailableIntegrations, integrationConfigs, systemIntegrationConfigs]);

  return (
    <IntegrationsTabSharedContext.Provider value={providerValue}>
      <div className="bg-white h-[calc(100vh-100px)]">
        {sharedState.activeView === "list" && <IntegrationsList />}
        {sharedState.system === IntegrationType[IntegrationType.PROCORE] &&
          sharedState.activeView === "configure-initial" && (
            <ProcoreInitialIntegration />
          )}
        {sharedState.system === IntegrationType[IntegrationType.AUTODESK] &&
          sharedState.activeView === "configure-initial" && (
            <AutodeskInitialIntegration />
          )}
        {sharedState.system === IntegrationType[IntegrationType.PROCORE] &&
          sharedState.activeView === "details" && <ProcoreIntegrationDetails />}

        {sharedState.system === IntegrationType[IntegrationType.AUTODESK] &&
          sharedState.activeView === "details" && (
            <AutodeskIntegrationDetails />
          )}
      </div>
    </IntegrationsTabSharedContext.Provider>
  );
}
export default IntegrationsTab;
