import React, { useRef } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import MaterialGrid from "./detail-grids/material-grid";
import SubmittalGrid from "./detail-grids/submittal-grid";
import { TActivityTask, TLinkedMaterial, TLinkedSubmittal } from "./models";

export function DetailCellRendererActivity(params: ICellRendererParams) {
  const { data, node } = params;
  const gridRefLinkedMaterial = useRef<AgGridReact<TLinkedMaterial>>(null);
  const gridRefLinkedSubmittal = useRef<AgGridReact<TLinkedSubmittal>>(null);

  node.childrenMapped = {
    gridRefLinkedMaterial,
    gridRefLinkedSubmittal
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { linked_submittals, linked_materials } = data as TActivityTask;
  if (!linked_submittals?.length && !linked_materials?.length)
    return (
      <div className="flex h-full w-full items-center px-4 py-2">
        No materials or submittals are affected due to this change in schedule.
      </div>
    );
  return (
    <div className="grow">
      {linked_materials?.length ? (
        <div className="w-full pb-2 px-5">
          <MaterialGrid
            materials={linked_materials}
            gridRef={gridRefLinkedMaterial}
          />
        </div>
      ) : (
        <div className="flex h-full w-full items-center px-5 py-2 font-semibold">
          No linked Material
        </div>
      )}
      {linked_submittals?.length > 0 && (
        <div className="w-full pb-2 px-5">
          <SubmittalGrid
            submittals={linked_submittals}
            gridRef={gridRefLinkedSubmittal}
          />
        </div>
      )}
    </div>
  );
}
