import { gql } from "@apollo/client";

export const QUERY_AUDIT_CHANGE_EVENT = gql`
  query AuditChangeEvents($where: audit_change_events_bool_exp = {}) {
    audit_change_events(where: $where, order_by: { created_at: desc }) {
      id
      change_type
      data_source
      created_at
      info
      subscription_id
      project_id
      object_id
    }
  }
`;
