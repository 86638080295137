import { Button } from "antd";

type TSelectNotFoundContent = {
  notFoundMsg: string;
  onAddClick: Function;
  showAddBtn?: boolean;
};

export default function SelectNotFoundContent(props: TSelectNotFoundContent) {
  const { notFoundMsg, onAddClick, showAddBtn } = props;
  return (
    <div className="w-full space-y-1">
      <div className="text-center">{notFoundMsg}</div>
      <div className="text-center">
        {showAddBtn !== false && (
          <Button
            type="primary"
            className=""
            onClick={() => {
              onAddClick();
            }}
          >
            Add
          </Button>
        )}
      </div>
    </div>
  );
}
