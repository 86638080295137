function DatePlannerPopoverContent() {
  return (
    <div className="w-[200px] text-xs space-y-3 p-1">
      <div className="space-y-0.5">
        <div className="font-semibold text-[#3B3B3BCC]">
          Submtital & Material Plan:
        </div>
        <div className="text-[#000000]">
          Enter estimated duration to calculate start and end dates for
          Submittals and Materials.
        </div>
      </div>
      <div className="space-y-0.5">
        <div className="font-semibold text-[#3B3B3BCC]">Schedule:</div>
        <div className="text-[#000000]">
          Link activities from the schedule and/or enter an estimate for when
          the Activities would start.
          <div className="mt-3">
            Material Plan will be based on the earliest start date of
            activities.
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatePlannerPopoverContent;
