import FeatureHeader from "pages/preconstruction-details/components/bid-package-header";
import EditableDetailsCardWrapper from "entity-app/shared-components/editable-details-card-wrapper/editable-details-card-wrapper";
import FeatureActivityCard from "components/feature-activity-card/feature-activity-card";
import FeatureAttachments from "components/feature-attachments";
import { useContext, useMemo } from "react";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import { ProjectPermissionEnum } from "constants/index";
import DesignLinkedTopSection from "./components/design-linked-top-section";
import DesignPackageGeneralInfo from "./components/design-package-general-info";

function DesignPackageDetailsTab(props: {
  featureInstanceData: any;
  actions: any;
  featureId: string;
}) {
  const { featureInstanceData, actions, featureId } = props;
  const { tokenContents } = useContext(ProjectContext);
  const permissionsForActivityCard = useMemo(() => {
    return ["create_comment"];
  }, []);
  const cannotWriteAccessOfDesignPackage = isPermissionNotGrantted(
    ProjectPermissionEnum.DesignPackageWrite,
    tokenContents?.role!
  );

  return (
    <div className="space-y-4 mb-4">
      <FeatureHeader
        actions={actions}
        number={featureInstanceData?.number}
        headerTitle={featureInstanceData?.title}
        status={featureInstanceData?.feature_workflow_instance?.milestone_state}
        disabled={cannotWriteAccessOfDesignPackage}
        feature="DESIGN_PACKAGE"
      />
      <DesignLinkedTopSection
        actions={actions}
        featureInstanceData={featureInstanceData}
        disabled={cannotWriteAccessOfDesignPackage}
      />
      <div className="grid grid-cols-3 gap-4 w-full h-[563px]">
        <EditableDetailsCardWrapper title="General Information">
          <DesignPackageGeneralInfo
            featureInstanceData={featureInstanceData}
            actions={actions}
            disabled={cannotWriteAccessOfDesignPackage}
          />
        </EditableDetailsCardWrapper>

        <FeatureActivityCard
          historyTabContents={<div>History</div>}
          featureId={featureId}
          featureKey="feature_instance_id"
          permissions={permissionsForActivityCard}
          showHistoryTab={false}
        />

        <EditableDetailsCardWrapper title="Attachments">
          <FeatureAttachments
            featureId={featureId}
            featureKey="feature_instance_id"
          />
        </EditableDetailsCardWrapper>
      </div>
    </div>
  );
}

export default DesignPackageDetailsTab;
