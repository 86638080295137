export default function WarningIcon() {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 41.25C12.1447 41.25 3.75 32.8552 3.75 22.5C3.75 12.1447 12.1447 3.75 22.5 3.75C32.8552 3.75 41.25 12.1447 41.25 22.5C41.25 32.8552 32.8552 41.25 22.5 41.25ZM22.5 37.5C30.7843 37.5 37.5 30.7843 37.5 22.5C37.5 14.2157 30.7843 7.5 22.5 7.5C14.2157 7.5 7.5 14.2157 7.5 22.5C7.5 30.7843 14.2157 37.5 22.5 37.5ZM20.625 13.125H24.375V16.875H20.625V13.125ZM20.625 20.625H24.375V31.875H20.625V20.625Z"
        fill="#F59E0B"
      />
    </svg>
  );
}
