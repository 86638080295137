import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";
import { DateUtils } from "utils/dateutils";

const workerSrc = "/scripts/pdf.worker.min.js";
GlobalWorkerOptions.workerSrc = workerSrc;

const isUrlExpired = (fileUrl: string) => {
  const urlParams = new URLSearchParams(fileUrl);
  const date = urlParams.get("X-Amz-Date");
  if (!date) return false;
  const expireTime = urlParams.get("X-Amz-Expires");
  const dateTime = DateUtils.dateTimeObj(date);
  dateTime.add(expireTime, "seconds");
  const now = DateUtils.dateTimeObj();
  if (now > dateTime) {
    return true;
  }
  return false;
};

export const getPdfLoader = async ({
  fileUrl,
  onExpiredUrl,
  onProgress
}: {
  fileUrl: string;
  onExpiredUrl?: any;
  onProgress?: Function;
}) => {
  if (isUrlExpired(fileUrl)) {
    onExpiredUrl();
    return null;
  }

  const loadingTask = getDocument({
    url: fileUrl
  });

  // get progress data
  if (onProgress) loadingTask.onProgress = onProgress;

  return loadingTask.promise;
};
