import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { Tooltip } from "antd";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";

type TSubmittalMicroVisualiserProps = {
  size?: "sm" | "md" | "lg";
  disabledTooltip?: boolean;
  submittalData: any;
  projectParticipants?: any;
  submittalRevisions: any;
  selectedRevision?: any;
};

const sizes = {
  sm: 16,
  md: 24,
  lg: 32
};

enum BlockStates {
  active = "active",
  completed = "completed",
  rejected = "rejected",
  unknown = "unknown"
}

const microVizSteps: string[] = ["SC", "GC", "D", "FGC"];

const newDBActualDatesMap: any = {
  SC: {
    active: "actual_milestone_1",
    completed: "actual_milestone_2"
  },
  GC: {
    active: "actual_milestone_2",
    completed: "actual_milestone_3"
  },
  D: {
    active: "actual_milestone_3",
    completed: "actual_milestone_4"
  },
  FGC: {
    active: "actual_milestone_4",
    completed: "actual_milestone_5"
  }
};

const userActions: any = {
  SC: {
    active: "Assigned to",
    completed: "Submitted by"
  },
  GC: {
    active: "In review with",
    completed: "Approved by",
    rejected: "Rejected by"
  },
  D: {
    active: "In review with",
    completed: "Approved by"
  },
  FGC: {
    active: "In review with",
    completed: "Closed & Distributed"
  }
};

const findRejectorForRevision = (dateBlock: any) => {
  const submittedBySC = dateBlock[newDBActualDatesMap.SC.completed];
  const gcReviewCompleted = dateBlock[newDBActualDatesMap.GC.completed];
  const designReviewCompleted = dateBlock[newDBActualDatesMap.D.completed];
  const finalGCReviewCompleted = dateBlock[newDBActualDatesMap.FGC.completed];

  if (submittedBySC && !gcReviewCompleted) return ["GC"];

  if (gcReviewCompleted && designReviewCompleted && !finalGCReviewCompleted)
    return ["D", "FGC"];
  return [];
};

function TooltipContents({ blockDetails }: any) {
  const { dates, step, state, user } = blockDetails;

  const createdAt = dates[state]
    ? DateUtils.format(dates[state], DATE_FORMAT_MMDDYYYY)
    : null;

  const actionSummary = useMemo(() => {
    try {
      if (!user) return "";
      const stepAction = userActions[step][state];
      return `${stepAction} ${user.first_name} ${user.last_name}, ${
        user?.company?.name || ""
      }`;
    } catch (ex) {
      //
    }
    return null;
  }, [state, step, user]);

  if (!createdAt && !actionSummary) return <span>NA</span>;

  return (
    <div className="text-xs">
      {createdAt && <div>{createdAt}</div>}
      {createdAt && actionSummary && <hr />}
      {actionSummary}
    </div>
  );
}

function VizBlock({
  blockDetails,
  isStart,
  isEnd,
  blockIndex,
  disabledTooltip
}: any) {
  const className = classNames("viz-block", {
    "viz-block--in-progress": blockDetails.state === BlockStates.active,
    "viz-block--completed-not-rejected":
      blockDetails.state === BlockStates.completed,
    "viz-block--completed-rejected":
      blockDetails.state === BlockStates.rejected,
    "viz-block--completed-unknown": blockDetails.state === BlockStates.unknown,
    "viz-block--first": isStart,
    "viz-block--last": isEnd
  });

  const blockMarkup = (
    <div className={className}>
      <span data-title={blockDetails.step}>
        {blockDetails.step === "FGC" ? "GC" : blockDetails.step}
      </span>
      {blockDetails.from === "revision" && (
        <span className="viz-block--cancelled" />
      )}
    </div>
  );

  if (
    !disabledTooltip &&
    blockDetails.state &&
    blockDetails.state !== BlockStates.unknown
  ) {
    return (
      <Tooltip
        title={
          <TooltipContents
            blockDetails={blockDetails}
            blockIndex={blockIndex}
          />
        }
      >
        {blockMarkup}
      </Tooltip>
    );
  }

  return blockMarkup;
}

export default function SubmittalMicroVizDateBlock(
  props: TSubmittalMicroVisualiserProps
) {
  const {
    submittalData,
    size = "md",
    disabledTooltip,
    projectParticipants,
    submittalRevisions,
    selectedRevision = null
  } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showCount, setShowCount] = useState<number>(0);
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [showSliderButtons, setShowSliderButtons] = useState<boolean>(false);

  const dataSourceForUser = useMemo(() => {
    if (!submittalRevisions) return submittalData;
    if (
      selectedRevision === null ||
      selectedRevision === submittalRevisions.length
    ) {
      return submittalData;
    }
    return submittalRevisions[selectedRevision];
  }, [selectedRevision, submittalData, submittalRevisions]);

  const submitter: any = useMemo(() => {
    const id =
      dataSourceForUser?.submitter ||
      dataSourceForUser?.submitter_user_id ||
      submittalData?.submitter ||
      submittalData?.submitter_user_id;
    return projectParticipants.submitterUsers.find((user: any) => {
      return user.id === id;
    });
  }, [
    dataSourceForUser?.submitter,
    dataSourceForUser?.submitter_user_id,
    projectParticipants.submitterUsers,
    submittalData?.submitter,
    submittalData?.submitter_user_id
  ]);

  const GCReviewer: any = useMemo(() => {
    const id =
      dataSourceForUser?.gc_reviewer ||
      dataSourceForUser?.gc_reviewer_user_id ||
      submittalData?.gc_reviewer ||
      submittalData?.gc_reviewer_user_id;
    return projectParticipants.gcReviewers.find((user: any) => {
      return user.id === id;
    });
  }, [
    dataSourceForUser?.gc_reviewer,
    dataSourceForUser?.gc_reviewer_user_id,
    projectParticipants.gcReviewers,
    submittalData?.gc_reviewer,
    submittalData?.gc_reviewer_user_id
  ]);

  const designReviewer: any = useMemo(() => {
    const id =
      dataSourceForUser?.design_reviewer ||
      dataSourceForUser?.design_reviewer_user_id ||
      submittalData?.design_reviewer ||
      submittalData?.design_reviewer_user_id;
    return projectParticipants.designReviewers.find((user: any) => {
      return user.id === id;
    });
  }, [
    dataSourceForUser?.design_reviewer,
    dataSourceForUser?.design_reviewer_user_id,
    projectParticipants.designReviewers,
    submittalData?.design_reviewer,
    submittalData?.design_reviewer_user_id
  ]);

  const userToStepMap: any = useMemo(() => {
    return {
      SC: submitter,
      GC: GCReviewer,
      D: designReviewer,
      FGC: GCReviewer
    };
  }, [GCReviewer, designReviewer, submitter]);

  const currentDateBlock: any = useMemo(() => {
    return submittalData.date_block_submittals
      ? submittalData.date_block_submittals[0]
      : {};
  }, [submittalData.date_block_submittals]);

  const actualDatesMap: any = newDBActualDatesMap;

  const getBlocksForCurrent = useCallback(
    (dateBlock: any, from: string = "current") => {
      const lastActualCompleteDatePresentStep =
        [...microVizSteps].reverse().find((step: string) => {
          return dateBlock[actualDatesMap[step].completed];
        }) || "";

      const lastActualCompleteDatePresentStepIdx = [...microVizSteps].indexOf(
        lastActualCompleteDatePresentStep
      );

      return microVizSteps.map((step: any, index: number) => {
        const microVizStep: any = {
          step,
          dates: {
            completed: dateBlock[actualDatesMap[step].completed],
            active: dateBlock[actualDatesMap[step].active]
          },
          user: userToStepMap[step],
          from,
          revision: from
        };
        microVizStep.state =
          (microVizStep.dates.completed ? BlockStates.completed : "") ||
          (microVizStep.dates.active ? BlockStates.active : "");
        if (lastActualCompleteDatePresentStepIdx) {
          if (index <= lastActualCompleteDatePresentStepIdx) {
            microVizStep.state = BlockStates.completed;
          }
        }
        return microVizStep;
      });
    },
    [actualDatesMap, userToStepMap]
  );

  const getBlocksForRevision = useCallback(
    (revision: any, index: number, addRemaining: boolean = false) => {
      try {
        const blocks: any = [];
        const dateBlockSource =
          revision.date_block_submittals || revision.date_block;
        if (dateBlockSource && dateBlockSource[0]) {
          const dateBlock = dateBlockSource[0];
          const rejectors = findRejectorForRevision(dateBlock);

          for (let m = 0; m < microVizSteps.length; m += 1) {
            const microVizStep: any = {
              step: microVizSteps[m],
              dates: {
                completed:
                  dateBlock[newDBActualDatesMap[microVizSteps[m]].completed],
                active: dateBlock[newDBActualDatesMap[microVizSteps[m]].active]
              },
              state: "",
              user: userToStepMap[microVizSteps[m]],
              from: "revision",
              revision: index
            };

            if (microVizStep.dates.active) {
              microVizStep.state = BlockStates.completed;
            }
            if (microVizStep.dates.completed) {
              microVizStep.state = BlockStates.completed;
            }
            if (rejectors.includes(microVizSteps[m])) {
              microVizStep.state = BlockStates.rejected;
            }
            if (microVizSteps[m] === "D" && rejectors.includes("D")) {
              microVizStep.state = BlockStates.unknown;
            }

            if (addRemaining && microVizStep.state === "") {
              microVizStep.from = "";
            }

            blocks.push(microVizStep);
          }
        }
        if (addRemaining) {
          return blocks;
        }
        return blocks.length ? blocks.filter((block: any) => block.state) : [];
      } catch (ex) {
        return [];
      }
    },
    [userToStepMap]
  );

  const revisionMicroVizBlocks = useMemo(() => {
    if (!submittalRevisions || !submittalRevisions.length) return [];
    let blocks: any = [];
    let revStopIdx = selectedRevision;

    if (selectedRevision === submittalData.revision) {
      revStopIdx = submittalRevisions.length;
    } else {
      revStopIdx = selectedRevision + 1;
    }

    if (submittalRevisions) {
      for (let r = 0; r < revStopIdx; r += 1) {
        const addRemaining =
          selectedRevision !== submittalData.revision
            ? r === revStopIdx - 1
            : false;
        const revisionBlocks = getBlocksForRevision(
          submittalRevisions[r],
          r,
          addRemaining
        );
        blocks = [...blocks, ...revisionBlocks];
      }
    }

    return blocks;
  }, [
    getBlocksForRevision,
    selectedRevision,
    submittalData,
    submittalRevisions
  ]);

  const currentMicroVizBlocks = useMemo(() => {
    return getBlocksForCurrent(currentDateBlock);
  }, [currentDateBlock, getBlocksForCurrent]);

  const microVizBlocks = useMemo(() => {
    if (
      selectedRevision === 0 &&
      submittalRevisions &&
      submittalRevisions.length
    ) {
      return getBlocksForRevision(submittalRevisions[0], 0, true);
    }

    if (selectedRevision === submittalData.revision) {
      return [...revisionMicroVizBlocks, ...currentMicroVizBlocks];
    }

    return [...revisionMicroVizBlocks];
  }, [
    currentMicroVizBlocks,
    getBlocksForRevision,
    revisionMicroVizBlocks,
    selectedRevision,
    submittalData.revision,
    submittalRevisions
  ]);

  // console.log("microVizBlocks ", microVizBlocks);

  useEffect(() => {
    let numStatusToShow = Math.floor(
      (containerRef.current?.clientWidth || 0) / sizes[size]
    );
    const numStatus = microVizBlocks.length;
    let showNav = false;
    if (numStatus > numStatusToShow) {
      // account for buttons
      showNav = true;
      numStatusToShow -= 2;
    } else {
      // clip at max number of status
      numStatusToShow = numStatus;
    }
    setShowCount(numStatusToShow);
    setViewIndex(microVizBlocks.length - numStatusToShow);
    setShowSliderButtons(showNav);
  }, [microVizBlocks, size]);

  const showPrevious = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setViewIndex((prev) => prev - 1);
    return false;
  };

  const showNext = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setViewIndex((prev) => prev + 1);
    return false;
  };

  const slicedMicroVizBlocks = microVizBlocks.slice(
    viewIndex,
    viewIndex + showCount
  );
  const isViewingFirst = viewIndex === 0;
  const isViewingLast = viewIndex + showCount === microVizBlocks.length;

  const sizeClassname = classNames({
    "viz-block--small": size === "sm",
    "viz-block--medium": size === "md",
    "viz-block--large": size === "lg"
  });

  return (
    <div ref={containerRef} className={sizeClassname}>
      <div className="flex">
        {showSliderButtons && (
          <button
            type="button"
            className="viz-block--button"
            disabled={isViewingFirst}
            onClick={showPrevious}
          >
            <IconChevronLeft size={sizes[size]} />
          </button>
        )}
        <div className="flex-grow">
          <div
            className="flex"
            style={{ justifyContent: showSliderButtons ? "center" : "auto" }}
          >
            {slicedMicroVizBlocks.map((item: any, index: number) => {
              return (
                <div
                  className="viz-block--wrapper"
                  key={`${item.revision}_${item.step}`}
                >
                  <VizBlock
                    blockIndex={index}
                    blockDetails={item}
                    isStart={index === 0}
                    isEnd={
                      index === slicedMicroVizBlocks.length - 1 &&
                      viewIndex + showCount === microVizBlocks.length
                    }
                    size={size}
                    disabledTooltip={disabledTooltip}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {showSliderButtons && (
          <button
            type="button"
            className="viz-block--button"
            onClick={showNext}
            disabled={isViewingLast}
          >
            <IconChevronRight size={sizes[size]} />
          </button>
        )}
      </div>
    </div>
  );
}
