export class ErrorMessage {
  private timer: any = undefined;

  private message: string = "";

  private count: number = 0;

  private displayed: boolean = false;

  constructor(message: string) {
    this.message = message;
    this.timer = undefined;
  }

  setMessage(message: string) {
    this.message = message;
  }

  getMessage(): string {
    return this.message;
  }

  setTimer(timer: NodeJS.Timeout) {
    this.timer = timer;
  }

  getTimer(): number {
    return this.timer;
  }

  setDisplayed(shown: boolean) {
    this.displayed = shown;
  }

  increaseCount() {
    this.count += 1;
  }

  isDisplayed() {
    return this.displayed;
  }
}
