import { Rule } from "antd/lib/form";
import { ErrorMessages } from "../../constants";
import { TEditMilestone } from "./model";

const duplicateMilestone = (otherMilestones: Array<TEditMilestone>) =>
  ({
    validator(_, value) {
      if (value) {
        const trimValue = value.trim().toLowerCase();
        if (otherMilestones.some((x) => x.name.toLowerCase() === trimValue))
          return Promise.reject(new Error("Milestone name already exists."));
      }
      return Promise.resolve();
    }
  } as Rule);

const duplicateOffsetName = (otherMilestones: Array<TEditMilestone>) =>
  ({
    validator(_, value) {
      if (value) {
        const trimValue = value.trim().toLowerCase();
        if (
          otherMilestones.some((x) => x.offsetName?.toLowerCase() === trimValue)
        )
          return Promise.reject(new Error("Duration name already exists."));
      }
      return Promise.resolve();
    }
  } as Rule);

const charactersLimit = (limit: number) =>
  ({
    max: limit,
    message: `Max limit: ${limit} characters.`
  } as Rule);

export const VRules = {
  TemplateTitle: (otherTemplateNames: string[]) =>
    [
      {
        required: true,
        whitespace: true,
        message: "Please enter template name."
      },
      charactersLimit(30),
      {
        validator(_, value) {
          if (
            !value ||
            !otherTemplateNames.includes(value.trim().toLowerCase())
          ) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(ErrorMessages.templateNameExists));
        }
      }
    ] as Array<Rule>,
  ActionInfo: [
    {
      required: true,
      whitespace: true,
      message: "Please enter action info detail."
    },
    charactersLimit(300)
  ] as Array<Rule>,
  OffsetName: (otherMilestones: Array<TEditMilestone>) =>
    [
      {
        required: true,
        whitespace: true,
        message: "Please enter offset name."
      },
      charactersLimit(25),
      duplicateOffsetName(otherMilestones)
    ] as Array<Rule>,
  Duration: [
    {
      required: true,
      whitespace: true,
      message: "Please enter duration value."
    },
    {
      pattern: /^\+?(0|[1-9]\d*)$/,
      message: "Please enter positive number."
    }
  ] as Array<Rule>,
  MilestoneName: (otherMilestones: Array<TEditMilestone>) =>
    [
      {
        required: true,
        whitespace: true,
        message: "Please enter milestone name."
      },
      charactersLimit(25),
      duplicateMilestone(otherMilestones)
    ] as Array<Rule>
};
