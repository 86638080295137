import { Spin } from "antd";
import {
  FilterNamesEnum,
  GcDashBoardType,
  NavigationPageEnum
} from "../models";
import MatrixBox from "./matrix-box";

function RiskUpdateComponent({
  dashboardData,
  goToFiltered
}: {
  dashboardData: GcDashBoardType | undefined;

  goToFiltered: (page: NavigationPageEnum, filter: FilterNamesEnum) => void;
}) {
  return (
    <div className="">
      <div className="flex items-center font-semibold h-8">RISK UPDATES</div>
      <div className="h-96 grow box-border p-5">
        {dashboardData ? (
          <div className="grid grid-cols-2 w-full h-full gap-x-5">
            <MatrixBox
              page={NavigationPageEnum.SubmittalLog}
              matrix={dashboardData?.submittals.risk_assessment}
              goToFiltered={goToFiltered}
            />
            <MatrixBox
              page={NavigationPageEnum.MaterialLog}
              matrix={dashboardData?.materials.risk_assessment}
              goToFiltered={goToFiltered}
            />
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
}

export default RiskUpdateComponent;
