export default function DateInputIcon({
  className = ""
}: {
  className?: string;
}) {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="19.2" fill="#D9D9D9" fillOpacity="0.4" />
      <rect
        x="0.25"
        y="0.25"
        width="23.5"
        height="18.7"
        stroke="#3B3B3B"
        strokeOpacity="0.8"
        strokeWidth="0.5"
      />
      <path
        d="M10.25 3.5835V4.75016H13.75V3.5835H14.9167V4.75016H17.25C17.5722 4.75016 17.8334 5.01133 17.8334 5.3335V14.6668C17.8334 14.989 17.5722 15.2502 17.25 15.2502H6.75002C6.42786 15.2502 6.16669 14.989 6.16669 14.6668V5.3335C6.16669 5.01133 6.42786 4.75016 6.75002 4.75016H9.08335V3.5835H10.25ZM16.6667 9.41683H7.33335V14.0835H16.6667V9.41683ZM9.08335 5.91683H7.33335V8.25016H16.6667V5.91683H14.9167V7.0835H13.75V5.91683H10.25V7.0835H9.08335V5.91683Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}
