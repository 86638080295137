import { Modal } from "antd";
import "./feature-linking-dialog.scss";
import { useEffect, useState } from "react";

function FeatureLinkingDialog(props: {
  open: boolean;
  headerComponent?: React.ReactNode;
  lhsComponent?: React.ReactNode;
  rhsComponent?: React.ReactNode;
}) {
  const { open, headerComponent, lhsComponent, rhsComponent } = props;

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => setShowContent(true), 100);
      return () => clearTimeout(timer);
    }
    setShowContent(false);
    return () => {};
  }, [open]);

  return (
    <Modal
      title={headerComponent}
      open={open}
      closable={false}
      width={1200}
      footer={null}
      className="feature-activity-linking-dialog"
      destroyOnClose
      centered
    >
      <div className="h-[550px] overflow-hidden">
        {showContent && (
          <div className="h-full flex items-stretch">
            <div className="w-[30%] pb-3 bg-[#F7F6F4] h-full max-h-full overflow-y-hidden">
              {lhsComponent}
            </div>
            <div className="w-[70%] pb-3 px-4 h-full max-h-full overflow-y-auto">
              {rhsComponent}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default FeatureLinkingDialog;
