import { Button } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { INTEGRATION_IMPORT_LOGS } from "services/graphQL/ciq-queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { EUserRoleName, InfoMessages } from "../../constants";
import GridLoadingIndicator from "./grid-loading-indicator";

export function GridStatusMessage(params: {
  isIntegrationMode: any;
  isIntegrationConfigured: any;
  submittalListLoading: boolean;
  submittalListData: any[];
  submittalListLoadedOnce: boolean;
  projectId: string;
}) {
  const {
    isIntegrationMode,
    isIntegrationConfigured,
    submittalListLoading,
    submittalListData,
    submittalListLoadedOnce,
    projectId
  } = params;

  const [delayAfterComponentInit, setDelayAfterComponentInit] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDelayAfterComponentInit(true);
    }, 100);
  }, []);

  const history = useHistory();

  const { tokenContents, gqlClientForProject, eventLogs }: TProjectContext =
    useContext(ProjectContext);

  const isLoggedInUserGcAdmin =
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_project_admin] ||
    tokenContents?.role === EUserRoleName[EUserRoleName.gc_sc_project_admin];

  const { data: importLogData, refetch: refetchIntegrationImportLogs } =
    useCIQQuery(INTEGRATION_IMPORT_LOGS, {
      client: gqlClientForProject,
      skip: !gqlClientForProject
    });

  useEffect(() => {
    if (!eventLogs || !eventLogs.length) return;
    const log = eventLogs.find(
      (event: TAuditChangeLog) => event.data_source === "import_log"
    );
    if (log && log.project_id === projectId) {
      refetchIntegrationImportLogs();
    }
  }, [eventLogs, projectId, refetchIntegrationImportLogs]);

  const submittalExists = submittalListData?.length;
  const lastImportStatus = importLogData?.import_log[0]?.status;
  const emptyImportLog = !importLogData?.import_log?.length;

  if ((!submittalExists && submittalListLoading) || !delayAfterComponentInit) {
    return (
      <div className="absolute top-[45%] z-50 left-0 right-0 flex justify-center">
        <GridLoadingIndicator />
      </div>
    );
  }

  let sectionContents = null;

  if (submittalExists) return null;

  if (isIntegrationMode) {
    if (!isIntegrationConfigured) {
      if (isLoggedInUserGcAdmin) {
        sectionContents = (
          <div className="absolute top-[25%] z-50 left-0 right-0 flex justify-center text-base">
            <div className="max-w-[800px]">
              <div className="mb-4">
                <p className="font-semibold">Instructions: </p>
              </div>
              <div>
                <ul className="space-y-1.5 pl-5">
                  <li>
                    You have selected Integration Mode as the preferred
                    Submittal Management Mode. To import submittals from another
                    application, you will first have to configure the
                    integration.
                  </li>
                  <li>
                    Upon clicking on the button provided below, you will be
                    redirected to configure the integration.
                  </li>
                  <li>
                    After the above set up has been completed, submittals will
                    be imported from the selected application once a day.
                  </li>
                </ul>
              </div>
              <div className="mt-10 flex items-center justify-center">
                <Button
                  type="text"
                  size="small"
                  className="workflow-document-upload mr-1"
                  onClick={() => {
                    history.push(`/project/${projectId}/settings/integrations`);
                  }}
                >
                  Click Here
                </Button>
                <span>to configure the integration.</span>
              </div>
            </div>
          </div>
        );
      } else {
        sectionContents = (
          <div className="absolute top-[45%] z-50 left-0 right-0 flex justify-center">
            Integration is yet to be performed by the GC.
          </div>
        );
      }
    }
    if (
      isIntegrationConfigured &&
      !submittalExists &&
      (!lastImportStatus ||
        (lastImportStatus && lastImportStatus === "PROCESSING"))
    ) {
      sectionContents = (
        <div className="absolute top-[45%] z-50 left-0 right-0 flex justify-center">
          {InfoMessages.ImportingSubmittals}
        </div>
      );
    }
    if (emptyImportLog && isIntegrationConfigured) {
      sectionContents = (
        <div className="absolute top-[45%] z-50 left-0 right-0 flex justify-center">
          {InfoMessages.EmptyACCSubmittalLogs}
        </div>
      );
    }
  } else if (submittalListLoadedOnce && !submittalExists) {
    sectionContents = (
      <div className="absolute top-[45%] z-50 left-0 right-0 flex justify-center">
        {InfoMessages.EmptySubmittalWorkflowMode}
      </div>
    );
  }
  return sectionContents;
}
