import { Viewer, Worker } from "@react-pdf-viewer/core";
import { Spin, message } from "antd";
import { useEffect, useState } from "react";
import { getFileDownloadURL } from "services/file-mgmt";

function SpecFileViewer(props: { fileKey: string; pageNumber: number }) {
  const { fileKey, pageNumber } = props;

  const [fileUrl, setFileUrl] = useState("");
  const [loading, setloading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const fileDownloadURLResp: any = await getFileDownloadURL([fileKey]);
        setloading(false);
        if (fileDownloadURLResp.data) {
          const link = fileDownloadURLResp.data.success.urls[fileKey];
          setFileUrl(link);
        }
      } catch (ex: any) {
        setloading(false);
        setFileUrl("");
        console.log("err: ", ex);
        const error: string =
          ex.response?.data?.error || ex.message || "Unable to fetch file";
        message.error(error);
      }
    }
    if (fileKey) fetchData();
  }, [fileKey]);

  return (
    <div className="h-full">
      {!fileKey || !fileUrl || loading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <Worker workerUrl="/scripts/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl}
            initialPage={pageNumber ? pageNumber - 1 : 0}
          />
        </Worker>
      )}
    </div>
  );
}

export default SpecFileViewer;
