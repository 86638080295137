/* eslint-disable no-param-reassign */
import { useMutation, useSubscription } from "@apollo/client";
import { message } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import { AttachmentDoc } from "models/submittal-log";
import { PDFDocumentProxy } from "pdfjs-dist";
import { useContext, useEffect, useRef, useState } from "react";
import {
  MUTATION_INSERT_ATTACHMENT,
  MUTATION_INSERT_MARKUPS_ONE,
  MUTATION_INSERT_MARKUP_LAYER,
  MUTATION_UPDATE_MARKUPS,
  MUTATION_UPDATE_MARKUPS_MANY
} from "services/graphQL/mutations";
import {
  SUBSCRIPTION_ATTACHMENT_MARKUP_REF,
  SUBSCRIPTION_MARKUPS_LAYER_PK
} from "services/graphQL/subscriptions";
import { IHighlight, IMarkupDb, NewHighlight } from "../types";
import CreateNewVersionOfPdfModel from "./newVersionOfPdfModel";
import { PdfCanvas } from "./PdfCanvas";

export type IMarkupLayerPdf = {
  pdfDocument: PDFDocumentProxy;
  file: AttachmentDoc;
};

export function MarkupLayerDbPdf({ pdfDocument, file }: IMarkupLayerPdf) {
  const layerId = useRef<string | null>(null);
  const allMarkups = useRef<Array<IMarkupDb>>([]);
  const [highlights, setHighlights] = useState<Array<IHighlight>>([]);
  const [fabricObjects, setFabricObjects] = useState<Array<IMarkupDb>>([]);
  const { gqlClientForProject } = useContext(ProjectContext);
  const [
    showCreateVersionDocConfirmModal,
    setShowCreateVersionDocConfirmModal
  ] = useState(false);
  const [isMarkupRead, setMarkupReady] = useState(false);

  const { data: listOfReference } = useSubscription(
    SUBSCRIPTION_ATTACHMENT_MARKUP_REF,
    {
      variables: { reference_id: file.id },
      shouldResubscribe: true,
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const { data: dataMarkupsByPk } = useSubscription(
    SUBSCRIPTION_MARKUPS_LAYER_PK,
    {
      variables: { id: file.id },
      onComplete: () => {
        console.log("onComplete called");
      },
      shouldResubscribe: true,
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const [insertMarkupPK] = useMutation<any>(MUTATION_INSERT_MARKUPS_ONE, {
    client: gqlClientForProject
  });
  const [updateMarkupPK] = useMutation<any>(MUTATION_UPDATE_MARKUPS, {
    client: gqlClientForProject
  });
  const [updateMarkupMany] = useMutation<any>(MUTATION_UPDATE_MARKUPS_MANY, {
    client: gqlClientForProject
  });
  const [insertMarkupLayerPK] = useMutation<any>(MUTATION_INSERT_MARKUP_LAYER, {
    client: gqlClientForProject
  });

  const [insertAttachment] = useMutation<any>(MUTATION_INSERT_ATTACHMENT, {
    client: gqlClientForProject
  });

  const tempRemoved = true; // temparary hide markups
  useEffect(() => {
    if (tempRemoved) return;
    if (listOfReference) {
      if (listOfReference.attachments.length > 0) {
        setMarkupReady(false);
        return;
      }

      if (dataMarkupsByPk) {
        console.log(dataMarkupsByPk);
        if (dataMarkupsByPk.attachments_by_pk.markup_layers.length === 0) {
          console.log("Adding Layers ", file.id);
          insertMarkupLayerPK({
            variables: {
              attachment_id: file.id
            }
          }).then((response) => {
            console.log(response);
            if (response.data.insert_markup_layer_one.id !== layerId.current) {
              layerId.current = response.data.insert_markup_layer_one.id;
            }
          });
        } else {
          const firstLayer = dataMarkupsByPk.attachments_by_pk.markup_layers[0];
          if (firstLayer.id !== layerId.current) {
            layerId.current = firstLayer.id;
          }
          console.log("layerId ", firstLayer.id);
          allMarkups.current = firstLayer.markups;
          const highlightJSON = allMarkups.current.filter(
            (a) => a.type === "highlight"
          );
          const hig = highlightJSON.map((h) => {
            return {
              ...h.data,
              id: h.id,
              page_no: h.page_no,
              created_at: h.created_at,
              created_by_user: h.created_by_user
            };
          });
          setHighlights(hig);

          const fabricJSON = allMarkups.current.filter(
            (a) => a.type === "fabric"
          );
          // eslint-disable-next-line no-restricted-syntax
          for (const fa of fabricJSON) {
            if (fa.data) {
              if (fa.data.data) {
                if (fa.data.data.id !== fa.id) fa.data.data.id = fa.id;
              } else {
                fa.data.data = { id: fa.id };
              }
            }
          }

          setFabricObjects(fabricJSON);
          setMarkupReady(true);
        }
      }
    }
  }, [
    dataMarkupsByPk,
    listOfReference,
    insertMarkupLayerPK,
    file.id,
    tempRemoved
  ]);

  async function addMarkup(type: string, page_no: number, data: any) {
    const response = await insertMarkupPK({
      variables: {
        layer_id: layerId.current,
        type,
        page_no,
        data
      }
    });
    if (response.data) {
      message.success("Updated");
    }
    if (response.errors) {
      message.error(response.errors);
    }
  }

  const updateMarkup = async (id: string, updatedData: any) => {
    const response = await updateMarkupPK({
      variables: {
        id,
        set: updatedData
      }
    });
    if (response.data) {
      message.success("Updated");
    }
    if (response.errors) {
      message.error(response.errors);
    }
  };

  const addHighlight = (newHighLight: NewHighlight) => {
    addMarkup("highlight", newHighLight.position.pageNumber, newHighLight);
  };

  const addFabric = (page_no: number, data: any, type = "fabric") => {
    addMarkup(type, page_no, data);
  };

  const createMarkupVersion = async () => {
    const newFileName = `${file.file_name.replace(
      /(\.[\w\d_-]+)$/i,
      "_markup1"
    )}.pdf`;

    insertAttachment({
      variables: {
        attachment: {
          reference_id: file.id,
          version: file.version,
          file_name: newFileName,
          file_size: file.file_size,
          submittal_id: file.submittal_id,
          blob_key: file.blob_key,
          file_type: file.file_type,
          uploaded: true
        }
      }
    }).then((response) => {
      const newId = response.data.insert_attachments_one.id;

      insertMarkupLayerPK({
        variables: {
          attachment_id: newId
        }
      }).then((response2) => {
        const newlayerId = response2.data.insert_markup_layer_one.id;
        updateMarkupMany({
          variables: {
            where: { layer_id: { _eq: layerId.current } },
            set: { layer_id: newlayerId }
          }
        }).then(() => {
          message.success("New Markup file created");
        });
      });
    });

    setShowCreateVersionDocConfirmModal(() => false);
  };

  return (
    <>
      <CreateNewVersionOfPdfModel
        isModalOpen={showCreateVersionDocConfirmModal}
        setIsModalOpen={setShowCreateVersionDocConfirmModal}
        handleOk={createMarkupVersion}
        fileName={file.file_name}
      />
      <PdfCanvas
        pdfDocument={pdfDocument}
        highlights={highlights}
        addHighlight={addHighlight}
        addFabric={addFabric}
        updateMarkup={updateMarkup}
        fabricObjects={fabricObjects}
        isMarkupRead={isMarkupRead}
        isMarkupVersionFile={!file.reference_id}
        setIsNewVersionModalOpen={setShowCreateVersionDocConfirmModal}
      />
    </>
  );
}

export default MarkupLayerDbPdf;
