import { Button } from "antd";
import { FilterNamesEnum, NavigationPageEnum, RiskMatrixType } from "../models";

function MatrixBox({
  page,
  matrix,
  goToFiltered
}: {
  page: NavigationPageEnum;
  matrix: RiskMatrixType | undefined;
  goToFiltered: (page: NavigationPageEnum, filter: FilterNamesEnum) => void;
}) {
  return (
    <div className="flex h-full">
      <div className="h-full w-8  relative">
        <div
          className="flex py-5 justify-center font-medium"
          style={{
            textOrientation: "sideways",
            writingMode: "vertical-rl",
            rotate: "180deg",
            marginTop: "33px"
          }}
        >
          Due in 2 weeks
        </div>
        <div
          className="flex py-5 justify-center font-medium"
          style={{
            textOrientation: "sideways",
            writingMode: "vertical-rl",
            rotate: "180deg",
            marginTop: "17px"
          }}
        >
          Due later
        </div>
      </div>
      <div className="h-full w-full flex flex-col">
        <div className="h-6 font-semibold uppercase">
          {page === NavigationPageEnum.SubmittalLog
            ? "Submittals"
            : "Materials"}
        </div>
        <div className=" grow grid grid-cols-2 gap-0 gap-y-0">
          <div className="flex items-center justify-center bg-[#F19C9A]">
            {matrix &&
              (matrix.before_than_date.High ? (
                <Button
                  type="link"
                  className="p-0 h-fit"
                  onClick={() => {
                    goToFiltered(page, FilterNamesEnum.HighDueIn2Week);
                  }}
                >
                  <div className="underlined-text text-matrix-box">
                    {matrix.before_than_date.High}
                  </div>
                </Button>
              ) : (
                <div className="text-matrix-box">
                  {matrix.before_than_date.High}
                </div>
              ))}
          </div>
          <div className="flex items-center justify-center bg-[#FDF2D0]">
            {matrix &&
              (matrix.before_than_date.Low + matrix.before_than_date.Medium >
              0 ? (
                <Button
                  type="link"
                  className="p-0 h-fit"
                  onClick={() => {
                    goToFiltered(page, FilterNamesEnum.LowDueIn2Week);
                  }}
                >
                  <div className="underlined-text text-matrix-box">
                    {matrix.before_than_date.Low +
                      matrix.before_than_date.Medium}
                  </div>
                </Button>
              ) : (
                <div className="text-matrix-box">
                  {matrix.before_than_date.Low + matrix.before_than_date.Medium}
                </div>
              ))}
          </div>
          <div className="flex items-center justify-center bg-[#FDF2D0]">
            {matrix &&
              (matrix.after_than_date.High ? (
                <Button
                  type="link"
                  className="p-0 h-fit"
                  onClick={() => {
                    goToFiltered(page, FilterNamesEnum.HighDueLater);
                  }}
                >
                  <div className="underlined-text text-matrix-box">
                    {matrix.after_than_date.High}
                  </div>
                </Button>
              ) : (
                <div className="text-matrix-box">
                  {matrix.after_than_date.High}
                </div>
              ))}
          </div>
          <div className="flex items-center justify-center bg-[#DCEFDE]">
            {matrix &&
              (matrix.after_than_date.Low + matrix.after_than_date.Medium >
              0 ? (
                <Button
                  type="link"
                  className="p-0 h-fit"
                  onClick={() => {
                    goToFiltered(page, FilterNamesEnum.LowDueLater);
                  }}
                >
                  <div className="underlined-text text-matrix-box">
                    {matrix.after_than_date.Low + matrix.after_than_date.Medium}
                  </div>
                </Button>
              ) : (
                <div className="text-matrix-box">
                  {matrix.after_than_date.Low + matrix.after_than_date.Medium}
                </div>
              ))}
          </div>
        </div>
        <div className="grid grid-cols-2 h-6 font-medium">
          <div className="">High Risk</div>
          <div className="">Medium / Low Risk</div>
        </div>
      </div>
    </div>
  );
}
export default MatrixBox;
