import NotFoundPage from "pages/not-found";
import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext } from "react";
import { Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router";
import RiskReport from "./risk-report";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";

function RiskReportPage() {
  const history = useHistory();
  const { projectId } = useParams() as any;
  const { tokenContents }: TProjectContext = useContext(ProjectContext);

  const cannotViewProjectReports = tokenContents
    ? isPermissionNotGrantted(
        ProjectPermissionEnum.ViewProjectReports,
        tokenContents?.role
      )
    : false;

  if (cannotViewProjectReports) {
    return (
      <NotFoundPage
        status="403"
        title="403"
        subTitle={ErrorMessages.UnauthorizedPageAccess}
      />
    );
  }

  return (
    <div className="flex flex-col h-full w-full">
      <Space
        className="w-fit px-4 py-2 font-medium space-x-1 cursor-pointer"
        onClick={() => {
          history.push(`/project/${projectId}/reports`);
        }}
      >
        <ArrowLeftOutlined />
        Reports
      </Space>
      <RiskReport />
    </div>
  );
}

export default RiskReportPage;
