import {
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { Button, Divider, Input, Popover, Select, Tag, message } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  updateProjectIntegration,
  getOAuthUrl
} from "services/integration-service";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import moment from "moment";

import { useIntegrationAuthProcess } from "hooks/integration-hooks";

import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { ReauthorizeOverlay } from "pages/subscription-settings/common-components";
import { ProjectIntegrationsTabSharedContext } from "../project-integrations-tab-context";
import {
  ErrorMessages,
  IntegrationAuthProcessStatuses,
  IntegrationAuthStates,
  IntegrationSystemNameMap
} from "../../../../constants";

export default function ProjectAutodeskIntegrationDetails() {
  const { tokenRetrievalState }: TProjectContext = useContext(ProjectContext);

  const { sharedState, dispatch } = useContext(
    ProjectIntegrationsTabSharedContext
  );
  const { subscriptionRole } = useContext(SubscriptionContext);
  const canChangeIntegration =
    subscriptionRole === RoleSubscriptionEnum.subscription_admin;

  const { initAuthProcess } = useIntegrationAuthProcess("project");

  const [isIntegrationNameInEditMode, setIsIntegrationNameInEditMode] =
    useState(false);
  const [isEmailInEditMode, setIsEmailInEditMode] = useState(false);
  const [alertEmails, setAlertEmails] = useState([]);

  const [saveIntgNameInProg, setSaveIntgNameInProg] = useState(false);
  const [savingEmailInProg, setSavingEmailInProg] = useState(false);
  const [changeAuthUserInProg, setChangeAuthUserInProg] = useState(false);
  const [integrationName, setIntegrationName] = useState("");

  const integrationDetails = useMemo(() => {
    return sharedState.projectIntegrationConfigs.find((config: any) => {
      return config.id === sharedState.additionalData.id;
    });
  }, [sharedState.additionalData.id, sharedState.projectIntegrationConfigs]);

  const integrationProperties = integrationDetails?.properties;
  const subscriptionIntegrationProperties =
    integrationDetails?.subscriptionProperties;

  const incomingAlertEmails = useMemo(() => {
    return integrationProperties?.PROJECT_ALERT_EMAIL?.split(",");
  }, [integrationProperties?.PROJECT_ALERT_EMAIL]);

  useEffect(() => {
    setAlertEmails(incomingAlertEmails);
  }, [incomingAlertEmails]);

  useEffect(() => {
    setIntegrationName(integrationProperties?.INTEGRATION_NAME);
  }, [integrationProperties?.INTEGRATION_NAME]);

  const saveField = async (
    payloadData: any,
    messages: { success: string; error: string }
  ) => {
    const payload: any = [
      {
        ...payloadData
      }
    ];

    const updateResponse = await updateProjectIntegration(
      sharedState.system,
      integrationDetails.id,
      payload,
      tokenRetrievalState.token
    );

    if (updateResponse.success) {
      message.success(messages.success);
      setIsEmailInEditMode(false);
    } else {
      message.error(messages.error);
    }
    return updateResponse;
  };

  const resetEmailFieldState = () => {
    setIsEmailInEditMode(false);
    setAlertEmails(incomingAlertEmails);
  };

  const resetIntgNameFieldState = () => {
    setIsIntegrationNameInEditMode(false);
    setIntegrationName(integrationProperties?.INTEGRATION_NAME);
  };

  const saveEmail = async () => {
    setSavingEmailInProg(true);
    await saveField(
      {
        properties: {
          project_alert_email: alertEmails.join(",")
        }
      },
      {
        success: "Notification recipients updated successfully.",
        error: "Failed to update notification recipients."
      }
    );
    setSavingEmailInProg(false);
  };

  const saveIntegName = async () => {
    setSaveIntgNameInProg(true);
    await saveField(
      {
        properties: {
          integration_name: integrationName
        }
      },
      {
        success: "Integration name updated successfully.",
        error: "Failed to update integration name."
      }
    );
    setSaveIntgNameInProg(false);
    resetIntgNameFieldState();
  };

  const changeAuthUser = async (messages: {
    success: string;
    error: string;
  }) => {
    setChangeAuthUserInProg(true);
    const OAuthURLResponse = await getOAuthUrl(
      sharedState.system,
      {},
      tokenRetrievalState?.token
    );
    setChangeAuthUserInProg(false);
    if (OAuthURLResponse.success) {
      const authResponse: any = await initAuthProcess(OAuthURLResponse.data);

      if (
        authResponse?.oauth_status?.status ===
        IntegrationAuthProcessStatuses.PROCESSED
      ) {
        const payload: any = [
          {
            oauth_operation_id: authResponse.id
          }
        ];

        const updateResponse = await updateProjectIntegration(
          sharedState.system,
          integrationDetails.id,
          payload,
          tokenRetrievalState.token
        );

        if (updateResponse.success) {
          message.success(messages.success);
        } else {
          message.success(message.error);
        }
      } else {
        const error = authResponse.output?.error || "Authorization failed";
        message.error(error);
      }
    }
  };

  const updateInProgress =
    savingEmailInProg || changeAuthUserInProg || saveIntgNameInProg;

  return (
    <div className="h-full flex flex-col">
      <div className="h-full relative">
        <div className="grow w-full md:max-w-screen-sm mx-auto py-4">
          <div className="mt-5 ">
            <div className="text-sm uppercase font-semibold text-neutral-700">
              You are authorized with{" "}
              {IntegrationSystemNameMap[sharedState.system]}
            </div>
            <div className="text-xs mt-1">
              <span className="text-neutral-600">Last authorized on: </span>
              <span>
                {integrationProperties?.LAST_AUTHORIZED
                  ? moment(integrationProperties?.LAST_AUTHORIZED)
                      .local()
                      .format("hh:mmA MM/DD/YYYY")
                  : null}
              </span>
            </div>
          </div>
          <div className="mt-8 w-[500px] space-y-5">
            <div>
              <div className="flex">
                <span className="form-field-heading-2">Integration Name</span>
              </div>
              {isIntegrationNameInEditMode && (
                <div className="w-full flex items-start space-x-2">
                  <div className="w-full">
                    <Input
                      className="normal-case w-full"
                      disabled={saveIntgNameInProg}
                      value={integrationName}
                      onChange={(event: any) => {
                        setIntegrationName(event.target.value);
                      }}
                    />
                    {!integrationName.trim() && (
                      <div className="text-[#ff4d4f]">
                        Please enter the integration name
                      </div>
                    )}
                  </div>
                  <Button
                    size="small"
                    className="bg-[#f5f5f5] p-2 mt-1"
                    title="Save"
                    icon={<SaveOutlined />}
                    onClick={async () => {
                      saveIntegName();
                    }}
                    disabled={!integrationName.trim() || updateInProgress}
                    loading={saveIntgNameInProg}
                  />
                  <Button
                    size="small"
                    className="bg-[#f5f5f5] mt-1"
                    title="Cancel"
                    onClick={resetIntgNameFieldState}
                    icon={<CloseOutlined />}
                    disabled={saveIntgNameInProg}
                  />
                </div>
              )}
              {!isIntegrationNameInEditMode && (
                <div className="mt-1 flex flex-wrap">
                  <div>
                    <Tag
                      key={integrationProperties?.INTEGRATION_NAME}
                      className="tag-selected-item"
                    >
                      {integrationProperties?.INTEGRATION_NAME}
                    </Tag>
                  </div>

                  <Button
                    size="small"
                    className="bg-[#f5f5f5]"
                    onClick={() => {
                      setIsIntegrationNameInEditMode(true);
                    }}
                    icon={<EditOutlined />}
                    disabled={!canChangeIntegration || updateInProgress}
                    title={
                      !canChangeIntegration
                        ? ErrorMessages.PermissionNotGranted
                        : ""
                    }
                  />
                </div>
              )}
            </div>
            <div>
              <div className="flex">
                <span className="form-field-heading-2">ACCOUNT NAME</span>
              </div>
              <div className="mt-1 flex flex-wrap gap-y-2">
                <Tag key={0} className="tag-selected-item">
                  {subscriptionIntegrationProperties?.AUTODESK_ACCOUNT_NAME}
                </Tag>
              </div>
            </div>
            <div>
              <div className="flex">
                <span className="form-field-heading-2">PROJECT NAME</span>
              </div>
              <div className="mt-1 flex flex-wrap gap-y-2">
                <Tag key={0} className="tag-selected-item">
                  {integrationProperties?.AUTODESK_PROJECT_NAME}
                </Tag>
              </div>
            </div>
            <div>
              <div className="flex">
                <span className="form-field-heading-2">
                  Notification Recipients
                </span>
                <Popover
                  content={
                    <div className="text-sm font-medium">
                      Enter the email address of users who need to be notified
                      in case of failure in authorization.
                    </div>
                  }
                >
                  <InfoCircleOutlined className="pl-0.5 text-xs mt-0.5" />
                </Popover>
              </div>
              {isEmailInEditMode && (
                <div className="w-full flex items-start space-x-2">
                  <div className="w-full">
                    <Select
                      className="normal-case w-full"
                      mode="tags"
                      placeholder="Email IDs"
                      notFoundContent={null}
                      dropdownStyle={{ display: "none" }}
                      value={alertEmails}
                      onChange={(value: any) => {
                        setAlertEmails(value);
                      }}
                      disabled={savingEmailInProg || updateInProgress}
                    />
                    {alertEmails?.length === 0 && (
                      <div className="text-[#ff4d4f]">
                        Please enter at least one email
                      </div>
                    )}
                  </div>
                  <Button
                    size="small"
                    className="bg-[#f5f5f5] p-2 mt-1"
                    title="Save"
                    icon={<SaveOutlined />}
                    onClick={saveEmail}
                    disabled={
                      alertEmails?.length === 0 ||
                      savingEmailInProg ||
                      updateInProgress
                    }
                    loading={savingEmailInProg}
                  />
                  <Button
                    size="small"
                    className="bg-[#f5f5f5] mt-1"
                    title="Cancel"
                    onClick={resetEmailFieldState}
                    icon={<CloseOutlined />}
                    disabled={savingEmailInProg}
                  />
                </div>
              )}
              {!isEmailInEditMode && (
                <div className="mt-1 flex flex-wrap gap-y-2">
                  {alertEmails?.map((email: string) => {
                    return (
                      <Tag key={email} className="tag-selected-item">
                        {email}
                      </Tag>
                    );
                  })}

                  <Button
                    size="small"
                    className="bg-[#f5f5f5]"
                    onClick={() => {
                      setIsEmailInEditMode(true);
                    }}
                    icon={<EditOutlined />}
                    disabled={!canChangeIntegration || changeAuthUserInProg}
                    title={
                      !canChangeIntegration
                        ? ErrorMessages.PermissionNotGranted
                        : ""
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mt-9 grid grid-cols-2 gap-x-4">
            <div className="space-y-1">
              <div className="info-label">
                AUTHORIZATION GRANTED BY (ACC USER):
              </div>
              <div className="info-value">
                {integrationProperties.AUTHORIZER_AUTODESK_NAME} (
                {integrationProperties.AUTHORIZER_AUTODESK_EMAIL})
              </div>
              <div className="text-sm pt-3">
                <Button
                  size="small"
                  type="text"
                  className="font-medium workflow-document-upload"
                  onClick={() => {
                    resetEmailFieldState();
                    resetIntgNameFieldState();
                    changeAuthUser({
                      success: "User changed successfully.",
                      error: "Failed to change user."
                    });
                  }}
                  loading={changeAuthUserInProg}
                  disabled={!canChangeIntegration || savingEmailInProg}
                  title={
                    !canChangeIntegration
                      ? ErrorMessages.PermissionNotGranted
                      : ""
                  }
                >
                  Click here
                </Button>{" "}
                to change <span className="font-semibold">ACC</span> user
              </div>
            </div>
            <div className="space-y-1">
              <div className="info-label">AUTHORIZATION INITIATED BY:</div>
              <div className="info-value">
                {integrationProperties?.AUTHORIZER_CIQ_NAME} (
                {integrationProperties?.AUTHORIZER_CIQ_EMAIL})
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className="mx-0 pb-0 mb-0" />
      <footer className="p-6 flex justify-end gap-x-4">
        <Button
          size="middle"
          onClick={() => {
            dispatch({
              type: "SET_ACTIVE_LIST_VIEW"
            });
          }}
        >
          Back
        </Button>
      </footer>
      {integrationProperties?.PROJECT_CONFIG_AUTH_STATE &&
      integrationProperties?.PROJECT_CONFIG_AUTH_STATE ===
        IntegrationAuthStates.REAUTHORIZATION_REQUIRED ? (
        <ReauthorizeOverlay
          onReauthClick={() => {
            if (!canChangeIntegration) return;
            changeAuthUser({
              success: "Re-Authenticated successfully.",
              error: "Failed to Re-Authenticate."
            });
          }}
          reauthInProgress={false}
        />
      ) : null}
    </div>
  );
}
