import { Card, Tabs } from "antd";
import "./activity-card.scss";
import { ActivityCardPermissions } from "models/types";
import { TNewDateBlock } from "components/date-block/models";
import ErrorBoundary from "components/error-boundary";
import CommentsTabComponent from "./comments-tab";

function ActivityCard(props: {
  historyTabContents: any;
  entityId: string;
  permissions: ActivityCardPermissions;
  dateBlock: TNewDateBlock;
  commentsData: { comment: Array<any> } | undefined;
}) {
  const { historyTabContents, entityId, permissions, dateBlock, commentsData } =
    props;

  const tabItems = [
    {
      label: "Comments",
      key: "1",
      children: (
        <ErrorBoundary>
          <CommentsTabComponent
            entityId={entityId}
            permissions={permissions}
            dateBlock={dateBlock}
            commentsData={commentsData}
          />
        </ErrorBoundary>
      )
    },
    {
      label: "History",
      key: "2",
      children: historyTabContents
    }
  ];

  return (
    <Card className="activity-card card-block">
      <div className="h-[1262px]">
        <Tabs items={tabItems} />
      </div>
    </Card>
  );
}

export default ActivityCard;
