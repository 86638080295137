import { ProjectSettingType } from "context/ProjectProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  SUBMITTAL_LIST_INTG_PERF,
  SUBMITTAL_LIST_WF_PERF
} from "../queries/submittal-list-queries";

export const useSubmittalListPerf = (params: {
  gqlClientForProject: any;
  projectDetails: ProjectSettingType | undefined;
  isIntegrationMode: any;
  skipSubscribe: any;
  tokenContents: any;
}): {
  data: any;
  error: any;
  loading: boolean;
  loadedOnce: boolean;
  refetch: any;
} => {
  const {
    gqlClientForProject,
    projectDetails,
    isIntegrationMode,
    skipSubscribe,
    tokenContents
  } = params;

  const [submittalListLoadedOnce, setSubmittalListLoadedOnce] = useState(false);

  const isWFMode = !isIntegrationMode;

  // if project is in integration mode, skipForWF will be true;
  const skipForWF =
    !projectDetails ||
    isIntegrationMode ||
    skipSubscribe ||
    !tokenContents ||
    !gqlClientForProject;

  // if project is in workflow mode, skipForINTG will be true;
  const skipForINTG =
    !projectDetails ||
    isWFMode ||
    skipSubscribe ||
    !tokenContents ||
    !gqlClientForProject;

  const {
    data: subWFsubmittalListData,
    error: subWFSubmittalListError,
    loading: subWFSubmittalListLoading,
    refetch: refetchWFData
  } = useCIQQuery(SUBMITTAL_LIST_WF_PERF, {
    client: gqlClientForProject,
    skip: skipForWF,
    fetchPolicy: "network-only"
  });

  const {
    data: submittalListIntegrationModeData,
    error: INTGSubmittalListError,
    loading: INTGSubmittalListLoading,
    refetch: refetchINTGData
  } = useCIQQuery(SUBMITTAL_LIST_INTG_PERF, {
    client: gqlClientForProject,
    skip: skipForINTG,
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (subWFSubmittalListLoading || INTGSubmittalListLoading) {
      setSubmittalListLoadedOnce(true);
    }
  }, [subWFSubmittalListLoading, INTGSubmittalListLoading]);

  const finalSubmittalList = useMemo(() => {
    return (
      subWFsubmittalListData?.submittal_list_wf_mode_func ||
      submittalListIntegrationModeData?.submittal_list_intg_mode_func
    );
  }, [
    subWFsubmittalListData?.submittal_list_wf_mode_func,
    submittalListIntegrationModeData?.submittal_list_intg_mode_func
  ]);

  const refetch = useCallback(() => {
    console.log({ subWFsubmittalListData, submittalListIntegrationModeData });
    if (subWFsubmittalListData) {
      refetchWFData();
    }
    if (submittalListIntegrationModeData) {
      refetchINTGData();
    }
  }, [
    refetchINTGData,
    refetchWFData,
    subWFsubmittalListData,
    submittalListIntegrationModeData
  ]);

  return {
    data: finalSubmittalList,
    error: subWFSubmittalListError || INTGSubmittalListError,
    loading: subWFSubmittalListLoading || INTGSubmittalListLoading,
    loadedOnce: submittalListLoadedOnce,
    refetch
  };
};
