declare const CONFIG_BASE_URL: string;
declare const CONFIG_GRAPHQL_URL: string;
declare const CONFIG_SENTRY_DSN: string;
declare const CONFIG_AG_GRID_LICENSE: string;
declare const CONFIG_GTAG_URL: string;
declare const CONFIG_GA_MEASUREMENT_ID: string;
declare const CONFIG_ENVIRONMENT_NAME: string;

export const BASE_URL: string =
  typeof CONFIG_BASE_URL !== "undefined" && CONFIG_BASE_URL.trim() !== ""
    ? CONFIG_BASE_URL.trim()
    : (process.env.REACT_APP_BASE_URL as string);

export const GRAPHQL_URL =
  typeof CONFIG_GRAPHQL_URL !== "undefined" && CONFIG_GRAPHQL_URL.trim() !== ""
    ? CONFIG_GRAPHQL_URL.trim()
    : (process.env.REACT_APP_GRAPHQL_URL as string);

export const SENTRY_DSN =
  typeof CONFIG_SENTRY_DSN !== "undefined" && CONFIG_SENTRY_DSN.trim() !== ""
    ? CONFIG_SENTRY_DSN.trim()
    : (process.env.REACT_APP_SENTRY_DSN as string);

export const AG_GRID_LICENSE =
  typeof CONFIG_AG_GRID_LICENSE !== "undefined" &&
  CONFIG_AG_GRID_LICENSE.trim() !== ""
    ? CONFIG_AG_GRID_LICENSE.trim()
    : (process.env.REACT_APP_AG_GRID_LICENSE as string);

export const GTAG_URL =
  typeof CONFIG_GTAG_URL !== "undefined" && CONFIG_GTAG_URL.trim() !== ""
    ? CONFIG_GTAG_URL.trim()
    : (process.env.REACT_APP_GTAG_URL as string);

export const GA_MEASUREMENT_ID =
  typeof CONFIG_GA_MEASUREMENT_ID !== "undefined" &&
  CONFIG_GA_MEASUREMENT_ID.trim() !== ""
    ? CONFIG_GA_MEASUREMENT_ID.trim()
    : (process.env.REACT_APP_GA_MEASUREMENT_ID as string);

export const ENVIRONMENT_NAME =
  typeof CONFIG_ENVIRONMENT_NAME !== "undefined" &&
  CONFIG_ENVIRONMENT_NAME.trim() !== ""
    ? CONFIG_ENVIRONMENT_NAME.trim()
    : (process.env.REACT_APP_ENVIRONMENT_NAME as string);

export const SUBSCRIPTION_GRAPHQL_URL = `wss://${GRAPHQL_URL}`;
export const GRAPHQL_API_URL = `https://${GRAPHQL_URL}`;
