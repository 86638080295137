import { ArrowLeftOutlined } from "@ant-design/icons";
import { ORGANIZATION_DETAIL } from "admin-app/services/ciq-queries";
import { Button, Tabs } from "antd";
import { useQuerySubscription } from "hooks/common";
import { useHistory, useParams } from "react-router";
import { getGQL } from "utils/utils";
import { TOrganizationDetail } from "admin-app/models";
import SubscriptionList from "../subscriptions-list";

function OrganizationDetail() {
  const { orgId } = useParams() as any;
  const history = useHistory();

  const { query: qOrganizationQuery, sub: sOrganizationQuery } =
    getGQL(ORGANIZATION_DETAIL);
  const { data: organizationPK } = useQuerySubscription<{
    organization_by_pk: TOrganizationDetail;
  }>(qOrganizationQuery, sOrganizationQuery, {
    variables: { id: orgId }
  });

  const tabItems = [
    {
      label: "Subscriptions",
      key: "1",
      children: (
        <SubscriptionList
          organization_id={orgId}
          organization_subscriptions={
            organizationPK?.organization_by_pk?.organization_subscriptions
          }
        />
      )
    }
  ];

  return (
    <div className="flex flex-col h-full p-2">
      <div className="flex w-full px-2">
        <Button
          icon={<ArrowLeftOutlined />}
          className="!bg-transparent !border-0 px-0 !shadow-none uppercase font-medium items-center"
          onClick={() => {
            history.push("/organizations");
          }}
        >
          {organizationPK?.organization_by_pk?.name}
        </Button>
      </div>

      <div className="flex flex-col h-full w-full px-2">
        <Tabs
          items={tabItems}
          destroyInactiveTabPane
          className="flex flex-col h-full"
        />
      </div>
    </div>
  );
}
export default OrganizationDetail;
