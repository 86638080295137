import { Button, Divider, Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { TEditMilestone } from "components/workflow-template-editor/model";
import { VRules } from "components/workflow-template-editor/validation-rules";
import { v4 as uuidV4 } from "uuid";

function AddMilestonePanel({
  isOpen,
  setOpen,
  onAdd,
  milestones
}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAdd: (milestone: TEditMilestone) => void;
  milestones: Array<TEditMilestone>;
}) {
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="ADD MILESTONE"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Add"
      footer={null}
      width={500}
      className="workflow-template-editor"
    >
      <div className="text-xs">
        A duration associated to the milestone will be added after the
        milestone. Please add the below details of the new milestone and its
        associated duration.
      </div>
      <Divider className="mt-2" />
      <Form
        layout="vertical"
        className="space-y-2"
        onFinish={(values) => {
          console.log(values);
          setOpen(false);

          const milestone: TEditMilestone = {
            name: values.name,
            id: uuidV4(),
            milestone_action: values.milestone_action,
            duration: Number(values.duration),
            offsetName: values.OffsetName
          };
          onAdd(milestone);
        }}
      >
        <Form.Item
          label="Milestone Name"
          name="name"
          rules={VRules.MilestoneName(milestones)}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Milestone Action"
          name="milestone_action"
          rules={VRules.ActionInfo}
          required
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label="ASSOCIATED DURATION"
          name="OffsetName"
          rules={VRules.OffsetName(milestones)}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="DEFAULT DURATION IN DAYS"
          name="duration"
          rules={VRules.Duration}
          required
        >
          <Input />
        </Form.Item>
        <div className="w-full p-2 space-x-2 flex justify-end">
          <Button className="w-24" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="w-24" type="primary" htmlType="submit">
            Add
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddMilestonePanel;
