import { Tag } from "antd";
import UserTag from "components/user-tag/user-tag";
import { AcceptanceStatus } from "constants/index";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

const dlTagRenderer = (
  prop: CustomTagProps,
  projectUsers: any,
  groups?: any
) => {
  const { value, closable, onClose } = prop;
  const selectedUser = projectUsers?.find((usr: any) => usr.user.id === value);
  const selectedGroup =
    groups?.length > 0 ? groups?.find((grp: any) => grp.value === value) : null;

  const tagLabel = selectedUser
    ? `${selectedUser?.user?.first_name} ${selectedUser?.user?.last_name}`
    : selectedGroup?.label;

  return (
    <Tag
      className="flex items-center"
      closable={closable}
      onClose={onClose}
      style={{ margin: 1 }}
    >
      <div>{tagLabel}</div>
      {selectedUser?.status_id &&
        selectedUser?.status_id === AcceptanceStatus.DEACTIVATED && (
          <div className="flex ml-3 -mt-[3px] -mr-1">
            <UserTag label="Inactive" />
          </div>
        )}
    </Tag>
  );
};

export default dlTagRenderer;
