import { ProjectContext } from "context/ProjectProvider";
import { TFeatureWorkflowInstance } from "entity-app/models";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GET_PROJECT_WORKFLOW_TEMPLATE_NAMES } from "services/graphQL/ciq-queries";
import { EWorkflowStatusDataBlock } from "../../../../constants";

export default function useWorkflowTemplateHook(props: {
  featureId: number;
  featureWorkflowInstance: TFeatureWorkflowInstance;
}) {
  const { featureId, featureWorkflowInstance } = props;
  const {
    workflow_status: workflowStatus,
    workflow_template_id: workflowTemplateId
  } = featureWorkflowInstance || {
    workflow_status: "",
    workflow_template_id: ""
  };

  const [selectedMaterialTemplateId, setSelectedMaterialTemplateId] =
    useState<string>(workflowTemplateId);
  const [applyingMDBTemplate] = useState(false);
  const isWFStarted =
    workflowStatus !== EWorkflowStatusDataBlock.WF_NOT_STARTED;

  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);
  const {
    data: projectWorkflowTemplates,
    loading,
    refetch: refetchMDB
  } = useCIQQuery<{
    project_workflow_templates: Array<any>;
  }>(GET_PROJECT_WORKFLOW_TEMPLATE_NAMES, {
    client: gqlClientForProject,
    variables: { featureId }
  });

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      if (
        eventLogs.some((x) => x.data_source === "project_workflow_templates")
      ) {
        refetchMDB(); // Refetching Project workflow Template data
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, refetchMDB]);

  const options = useMemo(() => {
    const tooltip = "tooltip";
    if (
      projectWorkflowTemplates &&
      projectWorkflowTemplates.project_workflow_templates
    ) {
      setSelectedMaterialTemplateId(workflowTemplateId);
      const templates =
        projectWorkflowTemplates.project_workflow_templates.filter(
          (template: any) => {
            return !template.disabled || template.id === workflowTemplateId;
          }
        );

      return templates.map((t) => ({
        label: t.name,
        value: t.id,
        tooltip
      }));
    }
    return [] as any[];
  }, [projectWorkflowTemplates, workflowTemplateId]);

  return {
    options,
    isWFStarted,
    loading,
    selectedMaterialTemplateId,
    setSelectedMaterialTemplateId,
    applyingMDBTemplate
  };
}
