import { client } from "./axios";
import { GET_DOWNLOAD_URLS, GET_SIGNED_URL } from "./endpoints";

interface IS3Params {
  feature?: string;
  feature_id?: string;
  file_name: string;
  sub_feature?: string;
  version_of?: string;
  on_behalf_of?: string;
  on_behalf_of_user_id?: string;
}

export const generateS3URL = async (
  params: IS3Params,
  projectToken: string
) => {
  try {
    const response = await client.post(GET_SIGNED_URL, params, {
      headers: {
        Authorization: `Bearer ${projectToken}`
      }
    });
    return response;
  } catch (ex) {
    return { error: ex };
  }
};

export const getFileDownloadURL = async (file_keys: Array<string>) => {
  return client.post(GET_DOWNLOAD_URLS, { file_keys });
};
