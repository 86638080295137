import { Spin } from "antd";
import "./user-avatar.css";
import UserAvatar from "./user-avatar";

function UserAvatarWithDetails({
  fullName,
  email,
  // workEmail,
  // style,
  photo,
  badge,
  // showAvatar,
  subscription
}: any) {
  const url = photo || null;
  // const size1 = size || "default";
  // const showAvatar1 = showAvatar !== undefined ? showAvatar : true;

  return (
    <div className="flex items-center justify-center gap-2">
      <UserAvatar url={url} isRect={false} fullName={fullName} />
      <div className="flex-col">
        <div className="name">
          {fullName || <Spin size="small" />} {badge}
        </div>
        <div className="email">{email}</div>
        {subscription && (
          <div className="text-[#3B3B3BCC] text-[10px] font-normal">
            Subscription: <span className=" font-semibold">{subscription}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserAvatarWithDetails;
