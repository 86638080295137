import { StatusCellRenderer } from "admin-app/components/cellRenderer";
import CreateSubscriptionForm from "admin-app/components/forms/create-subscription";
import SideModelPanel from "admin-app/components/side-model";
import { TOrganizationSubscriptionsLog } from "admin-app/models";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import SearchInput from "components/search-input";
import { GridLoadingIndicator } from "components/widgets";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DATE_FORMAT_MMDDYYYY_HHMM_A,
  DateFilter,
  DateUtils
} from "utils/dateutils";
import { Link } from "react-router-dom";
import { AcceptanceStatus } from "../../../constants";

function SubscriptionList({
  organization_id,
  organization_subscriptions
}: {
  organization_id: string;
  organization_subscriptions?: Array<TOrganizationSubscriptionsLog>;
}) {
  const gridRef = useRef<AgGridReact<TOrganizationSubscriptionsLog>>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [stats, setStats] = useState("");
  const [openAddSubscriptionModel, setOpenAddSubscriptionModel] =
    useState(false);

  const columnDefs: ColDef[] = [
    {
      colId: "name",
      field: "subscription.name",
      headerName: "SUBSCRIPTION NAME",
      tooltipField: "subscription.name",
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ({ data, value }: any) => (
        <Link
          to={`/organizations/${organization_id}/subscriptions/${data.subscription.id}/project/`}
        >
          {value}
        </Link>
      )
    },
    {
      colId: "create_by",
      headerName: "CREATED BY",
      valueGetter: ({ data }) => {
        const { subscription } = data;
        return `${subscription.created_by_user.first_name} ${subscription.created_by_user.last_name}`;
      },
      tooltipValueGetter: ({ value }) => value
    },
    {
      colId: "created_at",
      field: "created_at",
      headerName: "CREATED ON",
      comparator: DateFilter.comparator,
      valueGetter: ({ data }) => {
        const { subscription } = data;
        return DateUtils.format(
          subscription.created_at,
          DATE_FORMAT_MMDDYYYY_HHMM_A
        );
      },
      tooltipValueGetter: ({ value }) => value
    },
    {
      colId: "subscription_users_email",
      headerName: "SUBSCRIPTION ADMIN INVITED",
      valueGetter: ({ data }) => {
        const subscriptionUsers: Array<any> =
          data.subscription.subscription_users;
        if (subscriptionUsers.length) {
          return subscriptionUsers[0].user.email;
        }
        return "";
      },
      tooltipValueGetter: ({ value }) => value
    },
    {
      colId: "status",
      headerName: "STATUS",
      valueGetter: ({ data }) => {
        const subscriptionUsers: Array<any> =
          data.subscription.subscription_users;
        if (subscriptionUsers.length) {
          return subscriptionUsers[0].status_id;
        }
        return "";
      },
      getQuickFilterText: ({ value }) => {
        switch (value) {
          case AcceptanceStatus.ACTIVE:
            return "Active";
          case AcceptanceStatus.DEACTIVATED:
            return "Deactivated";

          default:
            return "Pending";
        }
      },
      cellRenderer: StatusCellRenderer
    }
  ];

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: []
    };
  }, []);

  const updateStats = useCallback(() => {
    if (isGridReady && organization_subscriptions) {
      const { length } = organization_subscriptions;

      if (gridRef.current?.api.isAnyFilterPresent()) {
        setStats(
          `${gridRef.current?.api.getDisplayedRowCount()} of ${length} Subscriptions`
        );
      } else {
        setStats(`${length} Subscriptions`);
      }
    }
  }, [organization_subscriptions, isGridReady]);

  useEffect(
    () => updateStats(),
    [updateStats, organization_subscriptions, isGridReady]
  );

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  return (
    <div className="flex flex-col w-full h-[calc(100vh-140px)] px-2 pb-2 bg-white">
      <div className="flex w-full justify-between items-center py-1">
        <div className="uppercase font-medium ">
          <div className="flex space-x-1">{stats}</div>
        </div>
        <div className="flex items-center space-x-2">
          <SearchInput
            placeholder="Search Subscriptions"
            onChange={(text) => {
              gridRef.current?.api?.setQuickFilter(text);
              updateStats();
            }}
          />
          <Button
            className="px-5"
            onClick={() => setOpenAddSubscriptionModel(true)}
          >
            Create Subscription
          </Button>
        </div>
      </div>
      <div className="grow w-full h-full  ag-theme-alpine">
        <AgGridReact<TOrganizationSubscriptionsLog>
          ref={gridRef}
          rowData={organization_subscriptions}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={36}
          loadingOverlayComponent={GridLoadingIndicator}
          onGridReady={() => {
            setGridReady(true);
            gridRef.current?.api.sizeColumnsToFit();
          }}
          overlayNoRowsTemplate="There is no subscriptions created yet, please click 'Create Subscription' button to create subscriptions."
        />
      </div>
      {openAddSubscriptionModel && (
        <SideModelPanel
          title="Create Subscription"
          open={openAddSubscriptionModel}
          onCancel={() => setOpenAddSubscriptionModel(false)}
        >
          <CreateSubscriptionForm
            organization_id={organization_id}
            onClose={() => setOpenAddSubscriptionModel(false)}
          />
        </SideModelPanel>
      )}
    </div>
  );
}
export default SubscriptionList;
