/* eslint-disable react/destructuring-assignment */
import { ReloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { ErrorInfo, ReactNode } from "react";
import ErrorManager from "utils/cumulative-error-message/error-manager";

interface ErrorBoundaryProps {
  children: ReactNode;
  onError?: () => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

const initialErrorState = {
  hasError: false,
  error: null,
  errorInfo: null
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { ...initialErrorState };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onError) {
      this.props.onError();
    }
    this.setState({
      hasError: true,
      error,
      errorInfo
    });
    ErrorManager().handleError(error, false);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div className="text-gray-500 text-xs flex items-center">
            <span />
            Something went wrong
            <Button
              className="border-0"
              size="small"
              icon={<ReloadOutlined className="text-gray-500 !text-xs" />}
              onClick={() => {
                this.setState({ ...initialErrorState });
              }}
              title="Retry"
            />
          </div>
          <div className="hidden">
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
