/* eslint-disable react/require-default-props */
import { Select } from "antd";
import { FocusEventHandler } from "react";

type Props = {
  title: string;
  value: string | undefined;
  onChange: ((value: string, option: any) => void) | undefined;
  showSearch: boolean;
  filterOption: boolean | ((inputValue: string, option?: any) => boolean);
  children: React.ReactNode[];
  disabled?: boolean;
  notFoundContent?: React.ReactNode;
  onSearch?: (value: string) => void | undefined;
  onBlur?: FocusEventHandler<HTMLElement> | undefined;
  className: string;
  loading?: boolean;
  placeholder?: string | "";
  ref?: any;
  allowClear?: boolean | false;
  onClear?: any;
  mode?: any;
};
function SelectField(props: Props) {
  const {
    title,
    value,
    onChange,
    showSearch,
    filterOption,
    children,
    disabled = false,
    notFoundContent,
    onSearch,
    onBlur,
    className,
    loading,
    placeholder,
    ref,
    allowClear,
    onClear,
    mode
  } = props;
  return (
    <>
      <p className="heading">{title}</p>
      <Select
        mode={mode}
        notFoundContent={notFoundContent}
        className={className}
        value={value}
        onChange={onChange}
        showSearch={showSearch}
        optionFilterProp="label"
        filterOption={filterOption}
        // defaultValue="Luke"
        disabled={disabled}
        virtual={false}
        onSearch={onSearch}
        onBlur={onBlur}
        defaultActiveFirstOption={false}
        loading={loading}
        placeholder={placeholder}
        ref={ref}
        allowClear={allowClear}
        onClear={onClear}
      >
        {children}
      </Select>
    </>
  );
}

export default SelectField;
