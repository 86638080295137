import React from "react";
import { DateUtils } from "utils/dateutils";
import { IconExternalLink } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import { computeLayout, getNormalizedDate } from "./TemporalLayout";
import styles from "./SubmittalDiff.module.css";
import { ImpactVisualisationProps } from "./model";

const RHS_PADDING = 300;

export function SubmittalDiff(props: ImpactVisualisationProps) {
  const {
    primaryElementName,
    milestones,
    todayDate,
    arrow,
    linkedMaterials,
    linkedSubmittals,
    xLocationsByDate,
    width,
    projectId,
    siCalculations,
    isCompletedWorkflow
  } = props;

  const temporalLayout = computeLayout(
    primaryElementName,
    xLocationsByDate,
    milestones,
    siCalculations,
    todayDate,
    width,
    linkedMaterials,
    linkedSubmittals
  );

  if (temporalLayout.layoutError) {
    return <div>{temporalLayout.layoutError}</div>;
  }

  const {
    tracks,
    markers,
    today,
    float,
    milestoneWidth,
    preMilestoneTracks,
    postMilestoneTracks,
    height,
    labelsByDate
  } = temporalLayout;

  const maxLocationX =
    Math.max(...Object.values(xLocationsByDate)) + RHS_PADDING;

  return (
    <div>
      <svg
        width={maxLocationX}
        height={height}
        xmlns="http://www.w3.org/1999/xhtml"
      >
        {/* submittal track */}
        <rect
          className={styles.milestoneRectangle}
          x={tracks.submittal.x}
          y={tracks.submittal.y}
          width={tracks.submittal.width}
          height={tracks.submittal.height}
        />
        {/* markers */}
        {markers.map((m) => (
          <React.Fragment key={m.milestone.type}>
            <rect
              x={m.x}
              y={m.y}
              height={m.height}
              width={m.width}
              className={m.markerClassName}
            />
            {m.labelX !== -1 ? (
              <foreignObject
                x={m.labelX}
                y={m.labelY}
                width={milestoneWidth - 20}
                height="60"
                className={styles.milestoneLabelWrapper}
              >
                <div className={m.labelClassName}>
                  <div className="my-1">
                    {DateUtils.format(m.milestone.date)}
                  </div>
                  <div>
                    {labelsByDate[getNormalizedDate(m.milestone.date)].join(
                      ", "
                    )}
                  </div>
                </div>
              </foreignObject>
            ) : null}
          </React.Fragment>
        ))}
        {/* arrow */}
        {/* draw an arrow with a triangle on its end */}
        <defs>
          <marker
            id="arrow"
            markerWidth="8"
            markerHeight="8"
            refX="8"
            refY="4"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,8 L8,4 z" className={styles.arrowHead} />
          </marker>
        </defs>
        {arrow && arrow.type === "submittal" ? (
          <line
            x1={arrow?.x1}
            x2={arrow?.x2}
            y1={tracks.submittal.y - 10}
            y2={tracks.submittal.y - 10}
            className={styles.arrow}
            markerEnd="url(#arrow)"
          />
        ) : null}
        {/* today */}
        {!isCompletedWorkflow && (
          <rect
            className={today.indicatorClassName}
            x={today.coordinates.x}
            y={today.coordinates.y}
            height={today.coordinates.height}
            width={today.coordinates.width}
            rx={2}
          />
        )}
        {!isCompletedWorkflow && (
          <foreignObject
            x={today.coordinates.labelX + today.coordinates.width / 2}
            y={today.coordinates.labelY}
            width={milestoneWidth * 2}
            height="50"
            className={styles.milestoneLabelWrapper}
          >
            <div className={today.labelClassName}>
              <div className="my-1">{DateUtils.format(todayDate)}</div>
              <div>{today.label}</div>
            </div>
          </foreignObject>
        )}
        {!isCompletedWorkflow && (
          <line
            x1={today.coordinates.x + today.coordinates.width / 2}
            x2={today.coordinates.x + today.coordinates.width / 2}
            y1={today.coordinates.labelY}
            y2={tracks.submittal.y}
            stroke="black"
            strokeDasharray="1,3"
          />
        )}
        {/* float */}
        <rect
          className={float.trackClassName}
          x={tracks.float.x}
          y={tracks.float.y}
          width={tracks.float.width}
          height={tracks.float.height}
        />
        <foreignObject
          x={tracks.float.labelX + 3}
          y={tracks.float.labelY}
          width={milestoneWidth * 2}
          height="50"
          className={styles.milestoneLabelWrapper}
        >
          <div className={float.labelClassName}>
            <div className="mb-1">Float</div>
            <div>{float.numberOfDays} days remaining</div>
          </div>
        </foreignObject>
        <line
          x1={tracks.float.labelX}
          x2={tracks.float.labelX}
          y1={tracks.float.labelY}
          y2={tracks.submittal.y}
          stroke="black"
          strokeDasharray="1,3"
        />
        <foreignObject
          x={tracks.float.x}
          y={tracks.submittal.y}
          width={tracks.float.width}
          height={tracks.float.height}
          className={styles.milestoneLabelWrapper}
        >
          <div
            className="flex items-center"
            style={{
              height: tracks.float.height,
              marginLeft: tracks.float.labelX - tracks.float.x + 3
            }}
          >
            {float.numberOfDays}d
          </div>
        </foreignObject>
        {/* submittal track */}
        {preMilestoneTracks.map((track, i) => (
          <React.Fragment key={`${track.x}-${track.y}`}>
            {i === 0 ? (
              <line
                x1={tracks.submittal.x}
                x2={tracks.submittal.x}
                y1={tracks.submittal.y + track.height + 10}
                y2={track.y - 10}
                stroke="black"
                strokeDasharray="2,2"
              />
            ) : null}
            <rect
              key={`${track.x}-${track.y}`}
              className={styles.milestoneRectangle}
              x={track.x}
              y={track.y}
              height={track.height}
              width={track.width}
            />
            <foreignObject
              x={track.labelX}
              y={track.labelY}
              width={track.width}
              height={track.height}
              className={styles.milestoneLabelWrapper}
            >
              <div
                className=""
                style={{
                  height: track.height,
                  width: track.width,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  padding: 3,
                  display: "block"
                }}
              >
                {track.label}
              </div>
            </foreignObject>
          </React.Fragment>
        ))}
        {/* material track */}
        {postMilestoneTracks.map((track, i) => {
          const materialURL = `/project/${projectId}/materials/${track.materialId}`;
          let arrowY = 0;
          const finalArrow =
            i === postMilestoneTracks.length - 1 && arrow ? arrow : null;
          if (finalArrow) {
            if (finalArrow.type === "material") {
              arrowY = track.y + track.height / 2;
              if (finalArrow.x1 < finalArrow.x2) {
                arrowY = track.y - track.height / 2;
              }
            } else {
              arrowY = tracks.submittal.y - 10;
            }
          }

          return (
            <React.Fragment key={`${track.x}-${track.y}`}>
              {i === postMilestoneTracks.length - 1 ? (
                <line
                  x1={track.x}
                  x2={track.x}
                  y1={tracks.submittal.y - 10}
                  y2={track.y + track.height + 10}
                  stroke="black"
                  strokeDasharray="2,2"
                />
              ) : null}
              {i === postMilestoneTracks.length - 1 && finalArrow ? (
                <line
                  x1={arrow?.x1}
                  x2={arrow?.x2}
                  y1={arrowY}
                  y2={arrowY}
                  className={styles.arrow}
                  markerEnd="url(#arrow)"
                />
              ) : null}
              <rect
                key={`${track.x}-${track.y}`}
                className={styles.milestoneRectangle}
                x={track.x}
                y={track.y}
                height={track.height}
                width={track.width}
              />
              <foreignObject
                x={track.labelX}
                y={track.labelY}
                width={track.width}
                height={track.height}
                className={styles.milestoneLabelWrapper}
              >
                <div
                  className="flex items-center pr-2"
                  style={{
                    width: `${track.width}px`,
                    height: `${track.height}px`
                  }}
                >
                  <IconExternalLink
                    className="mx-0.5"
                    size={track.height - 6}
                  />
                  <div className="truncate grow">
                    <Link
                      to={materialURL}
                      className="text-gray-800"
                      target="_blank"
                    >
                      {track.label}
                    </Link>
                  </div>
                </div>
              </foreignObject>
              <foreignObject
                x={track.labelX}
                y={track.labelY + track.height}
                width={track.width}
                height={track.height}
                className={styles.milestoneLabelWrapper}
              >
                <div
                  className="flex py-[3px] items-center justify-end"
                  style={{ fontSize: "10px" }}
                >
                  ROJ: {track.date}
                </div>
              </foreignObject>
            </React.Fragment>
          );
        })}
      </svg>
    </div>
  );
}
