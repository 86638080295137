import Gantt from "components/gantt-layout/gantt";

function ProcurementSchedule() {
  return (
    <div>
      <Gantt />
    </div>
  );
}

export default ProcurementSchedule;
