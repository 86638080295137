import {
  Button,
  Form,
  Input,
  Tooltip,
  Typography,
  message as messages
} from "antd";
import { AppLogoIcon } from "components/svg-icons";
import { ErrorMessages } from "constants/index";
import FormItem from "antd/es/form/FormItem";
import { ChangePasswordRequest } from "models/user";
import { useState } from "react";
import { changepassword } from "services/auth";
import { useHistory } from "react-router";
import { InfoCircleOutlined } from "@ant-design/icons";

function ChangePassword() {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const onFinish = async (event: ChangePasswordRequest) => {
    setLoader(true);
    const result: any = await changepassword({
      old_password: event.old_password,
      new_password: event.new_password
    });

    setLoader(false);
    if (result.status >= 200 && result.status <= 204) {
      setLoader(false);
      messages.success(ErrorMessages.PasswordSetMsg);
      history.push("/");
    } else if (result.response?.data?.error) {
      messages.error(result.response?.data.error);
      if (result.response?.data?.error === "Link has expired.") {
        history.push("/");
      }
    } else {
      messages.error(result.message);
    }

    form.resetFields();
  };

  return (
    <div className="flex flex-col h-[90vh]">
      <div className="flex bg-[#f7f6f4] items-center justify-center h-full w-full">
        <div className="flex flex-col p-8 shadow-[0px_5px_25px_rgba(0,0,0,0.1)] w-[400px] rounded-md">
          <div className="m-auto block">
            <AppLogoIcon />
          </div>
          <div className="flex justify-center">
            <Title level={3}>Change Password</Title>
          </div>
          <Form
            className="space-y-3"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
            layout="vertical"
          >
            <FormItem
              name="old_password"
              label={
                <div>
                  Current Password{" "}
                  <Tooltip title={ErrorMessages.PasswordRequired}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
              className="password"
              validateStatus=""
              rules={[
                {
                  validateTrigger: "onSubmit",
                  required: true,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$£@_%+=.,^&(){}[\]|;:'"”<>?\\]).{12,}$/,
                  message: ErrorMessages.PasswordRequired
                }
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Enter New Password" />
            </FormItem>
            <FormItem
              name="new_password"
              label={
                <div>
                  New Password{" "}
                  <Tooltip title={ErrorMessages.PasswordRequired}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
              className="password"
              validateStatus=""
              rules={[
                {
                  validateTrigger: "onSubmit",
                  required: true,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$£@_%+=.,^&(){}[\]|;:'"”<>?\\]).{12,}$/,
                  message: ErrorMessages.PasswordRequired
                }
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Enter New Password" />
            </FormItem>

            <FormItem
              name="confirmpassword"
              label="Confirm Password"
              className="password"
              dependencies={["new_password"]}
              hasFeedback
              validateStatus=""
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  message: ErrorMessages.ConfirmPasswordRequired
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(ErrorMessages.ConfirmPasswordRequired)
                    );
                  }
                })
              ]}
            >
              <Input.Password placeholder="Enter Confirm Password" />
            </FormItem>
            <div style={{ paddingTop: "1em" }}>
              <Button
                htmlType="submit"
                size="middle"
                style={{ margin: "auto", display: "block" }}
                loading={loader}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default ChangePassword;
