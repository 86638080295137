import { useMemo } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useParams } from "react-router";
import {
  twoRowCellRenderer,
  twoRowGetQuickFilterText
} from "../../../entity-app/shared-components/log-render-components/cell-renders";
import {
  coldefNavigation,
  coldefSingleField,
  coldefUser
} from "../../../entity-app/shared-components/log-render-components/col-defs";

export const useDesignPackageAgGrid = (props: {
  featureKey: string;
  milestonesColumns: any;
}) => {
  const { featureKey, milestonesColumns } = props;
  const { projectId } = useParams() as any;

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    resizable: true,
    menuTabs: ["filterMenuTab"],
    minWidth: 100,
    flex: 1
  };

  const columnDefs = useMemo(
    () =>
      [
        coldefNavigation(
          {
            field: "auto_inc_value",
            headerName: "ID",
            pinned: "left",
            lockPosition: "left",
            checkboxSelection: true,
            width: 100,
            sort: "asc",
            lockVisible: true
          },
          projectId,
          featureKey
        ),
        coldefSingleField({
          field: "number",
          headerName: "NUMBER",
          pinned: "left",
          lockPosition: "left",
          width: 120
        }),
        coldefSingleField({
          field: "title",
          headerName: "DESIGN PACKAGE TITLE",
          width: 180,
          pinned: "left",
          lockPosition: "left",
          cellRenderer: twoRowCellRenderer,
          cellRendererParams: {
            featureKey,
            projectId
          },
          getQuickFilterText: twoRowGetQuickFilterText,
          lockVisible: true
        }),
        coldefSingleField({
          field: "milestone_state",
          headerName: "STATUS",
          lockVisible: true
        }),
        coldefSingleField({
          field: "design_firm_name",
          headerName: "RESPONSIBLE DESIGN FIRM"
        }),
        coldefUser({
          def: { headerName: "RESPONSIBLE DESIGNER" },
          user: {
            id: "responsible_designer_id",
            firstName: "responsible_designer_first_name",
            lastName: "responsible_designer_last_name",
            statusId: "responsible_designer_status_id"
          }
        }),
        coldefUser({
          def: { headerName: "GC REPRESENTATIVE" },
          user: {
            id: "gc_representative_id",
            firstName: "gc_representative_first_name",
            lastName: "gc_representative_last_name"
          }
        }),
        ...milestonesColumns.allColumns
      ] as (ColDef<any> | ColGroupDef<any>)[],
    [projectId, featureKey, milestonesColumns.allColumns]
  );
  return {
    columnDefs,
    defaultColDef,
    milestonesColumns
  };
};
