import {
  BASE_URL as CONFIG_BASE_URL,
  GRAPHQL_URL as CONFIG_GRAPHQL_URL
} from "config/config";

export const BASE_URL = `${CONFIG_BASE_URL}/gateway/api`;
export const GRAPHQL_URL = CONFIG_GRAPHQL_URL;
export const GATEWAY_GRAPHQL_URL = `${CONFIG_BASE_URL}/gateway/graphql`;

export const GET_SIGNED_URL = "/file/upload";
export const GET_DOWNLOAD_URLS = "/file/download";

export const INTEGRATION_BASE_URL = `${BASE_URL}/data-integration`;

// Report Service url to export the report
export const REPORT_EXPORT_URL = "/report";

// Buyout visualization end point
export const BUYOUT_VISUALIZATION_URL =
  "/features/linkings/<DESIGN_PACKAGE_ID>/view/design-visualization";
