import { Button, Tooltip } from "antd";
import { AttachmentDoc } from "models/submittal-log";
import "./document-list-widget.scss";
import attachmentIconPath from "assets/svg/attachment-icon.svg";
import docIconPath from "assets/svg/doc-icon.svg";
import deleteIconPath from "assets/svg/delete-icon.svg";
import { DateUtils } from "utils/dateutils";
import { SubmittalWFStatusToStrMap } from "constants/index";
import moment from "moment";
import { useMemo } from "react";

type TDocumentListWidgetProps = {
  documents: any;
  onDocumentClick: Function;
  showDeleteBtn?: boolean;
  deleteDisabled?: boolean;
  deleteDisabledTooltip?: string;
  onDeleteBtnClick?: Function;
  source?: string;
};

export default function DocumentListWidget(props: TDocumentListWidgetProps) {
  const {
    documents,
    onDocumentClick,
    showDeleteBtn = false,
    deleteDisabled,
    onDeleteBtnClick = () => {},
    deleteDisabledTooltip,
    source = "submittal"
  } = props;

  const sortedDocs = useMemo(() => {
    const clonedDocs = documents && documents.length ? [...documents] : [];
    return clonedDocs.sort((attachment1: any, attachment2: any) => {
      const dateA = moment(attachment1.created_at);
      const dateB = moment(attachment2.created_at);
      if (dateA.isBefore(dateB)) {
        return 1;
      }
      if (dateA.isAfter(dateB)) {
        return -1;
      }
      return 0;
    });
  }, [documents]);

  return (
    <div className="w-full max-w-full h-full document-list-item">
      {documents && documents.length ? (
        sortedDocs.map((data: AttachmentDoc) => {
          const projectUsers: any = data.created_by_user?.project_users
            ? data.created_by_user?.project_users
            : [];
          let org = "";
          if (projectUsers.length > 0) {
            const projuser = projectUsers[0];
            org = projuser?.subscription_vendor
              ? projuser?.subscription_vendor.name || ""
              : projuser?.subscription?.name || "";
          }

          let workflowStatus = "";
          if (data?.submittal_status_history?.workflow_status) {
            const wfstatus = data?.submittal_status_history?.workflow_status;
            workflowStatus = SubmittalWFStatusToStrMap[wfstatus]
              ? SubmittalWFStatusToStrMap[wfstatus]
              : "";
          }

          return (
            <div
              key={data.id}
              className="w-full mb-1 flex items-center border border-solid border-[#3B3B3B4D]  pr-1 min-h-[50px]"
            >
              <button
                type="button"
                className="bg-transparent border-0 grow text-left cursor-pointer p-2 text-ellipsis truncate"
                onClick={() => {
                  onDocumentClick(data);
                }}
              >
                <div className="flex-col space-y-1">
                  <div className="flex doc-label space-x-3">
                    <div className="flex space-x-2">
                      <img src={attachmentIconPath} alt="" />
                      <div className="w-[111px]">
                        {data?.created_at
                          ? DateUtils.format(data?.created_at)
                          : ""}
                      </div>
                    </div>
                    <div className=" text-ellipsis ">
                      {`${data?.created_by_user?.first_name || ""} ${
                        data?.created_by_user?.last_name || ""
                      }`}
                      <span className="italic text-[#3B3B3BCC]">
                        {org !== "" ? `(${org})` : ""}
                      </span>
                    </div>
                  </div>
                  <div className="flex doc-label space-x-3">
                    <div className="flex space-x-2">
                      <img src={docIconPath} alt="" />
                      <div
                        className={`${
                          source === "submittal" ? "w-[111px]" : "w-full"
                        } truncate`}
                        title={data.file_name}
                      >
                        {data.file_name}
                      </div>
                    </div>
                    <div hidden={source === "material"}>{workflowStatus}</div>
                  </div>
                </div>
              </button>
              {showDeleteBtn && (
                <Tooltip title={deleteDisabledTooltip} placement="leftBottom">
                  <Button
                    className=" bg-transparent border-none"
                    size="small"
                    onClick={() => {
                      onDeleteBtnClick(data);
                    }}
                    disabled={
                      deleteDisabled === undefined ? true : deleteDisabled
                    }
                  >
                    <img src={deleteIconPath} alt="" />
                  </Button>
                </Tooltip>
              )}
            </div>
          );
        })
      ) : (
        <div className="text-xs text-neutral-400">No attachments added.</div>
      )}
    </div>
  );
}
