import { Select } from "antd";
import { AcceptanceStatus } from "constants/index";
import { forwardRef, useState, useImperativeHandle } from "react";
import { getUserCompany } from "utils/utils";

const { Option } = Select;

const UserSelectCellEditor = forwardRef((props: any, ref) => {
  const { context, usersVar, stopEditing, value, notFoundContent } = props;
  const [selectValue, setSelectValue] = useState(value);

  const users = context.projectParticipants[usersVar].filter((user: any) => {
    let userId;
    if (usersVar === "gcReviewers") {
      userId = props.data.gc_reviewer_user_id;
    } else if (usersVar === "designReviewers") {
      userId = props.data.design_reviewer_user_id;
    }
    if (userId && userId === user.id) {
      return true;
    }
    return user.status_id !== AcceptanceStatus.DEACTIVATED;
  });
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!selectValue) return null;
        return selectValue;
      }
    };
  });
  return (
    <Select
      open
      allowClear
      optionFilterProp="label"
      filterOption
      virtual={false}
      style={{ width: "100%" }}
      showSearch
      notFoundContent={notFoundContent}
      onChange={(event: any) => {
        setSelectValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    >
      {users.map((item: any) => {
        const user = item.user ? item.user : item;
        const userId = user.user_id || user.id;
        if (item.status_id === AcceptanceStatus.DEACTIVATED) {
          return "";
        }
        return (
          <Option
            key={userId}
            value={userId}
            label={`${user.first_name} ${user.last_name}`}
          >
            <div>
              {user.first_name} {user.last_name}
            </div>
            <div className="text-sm text-gray-500">
              {item.user ? getUserCompany(item) : getUserCompany(user)}
            </div>
          </Option>
        );
      })}
    </Select>
  );
});

export default UserSelectCellEditor;
