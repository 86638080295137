import { GridApi } from "ag-grid-community";

export const customSearchForActivityReport = (text: string, api: GridApi) => {
  if (api) {
    api.forEachNode((rowNode) => {
      if (!rowNode.detailNode) return;
      const { childrenMapped } = rowNode.detailNode;
      if (childrenMapped) {
        const { gridRefLinkedMaterial, gridRefLinkedSubmittal } =
          childrenMapped;
        if (gridRefLinkedMaterial && gridRefLinkedMaterial.current) {
          const mApi = gridRefLinkedMaterial.current.api as GridApi;
          mApi?.setQuickFilter(text);
          mApi.forEachNode((mRowNode) => {
            if (mRowNode.detailNode && mRowNode.detailNode.detailGridInfo) {
              const gridInfo = mRowNode.detailNode.detailGridInfo;
              gridInfo.api?.setQuickFilter(text);
            }
          });
        }
        if (gridRefLinkedSubmittal && gridRefLinkedSubmittal.current) {
          const mApi = gridRefLinkedSubmittal.current.api as GridApi;

          mApi?.setQuickFilter(text);
        }
      }
    });
    api.setQuickFilter(text);
  }
};
