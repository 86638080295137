import { ColumnApi, GridApi } from "ag-grid-community";
import { TColumnType, TSaveColumnState } from ".";

export const UtilColumnConfig = {
  getColumnStateFromGrid: (props: {
    gridRef?: {
      api: GridApi<any>;
      columnApi: ColumnApi;
    };
  }) => {
    const { gridRef } = props;
    const sortedColArr = [] as Array<TColumnType>;
    const colFilters = gridRef?.api?.getFilterModel();

    gridRef?.columnApi.getColumnState()?.forEach((col, index) => {
      const colKey = col.colId || "";
      const colDef = gridRef?.api.getColumnDef(colKey);

      if (colFilters && col && colDef && !colDef.suppressColumnsToolPanel) {
        const range = { ...colFilters[colKey]?.range };

        if (range) {
          range.to = range.to ? `${range.to}` : null;
          range.from = range.from ? `${range.from}` : null;
        }

        const field = {
          name: colKey,
          filter: colFilters[colKey]?.values || null,
          sortOrder: col.sort || null,
          range
        };

        sortedColArr.push({
          order: index + 1,
          field,
          visibility: !col.hide
        });
      }
    });

    return {
      columnList: sortedColArr,
      quickSearch: ""
    };
  },

  setFilterStateFromData: (props: {
    columnStates: TSaveColumnState;
    gridRef?: {
      api: GridApi<any>;
      columnApi: ColumnApi;
    };
  }) => {
    const { columnStates, gridRef } = props;
    if (!columnStates) return;
    gridRef?.api.setFilterModel(null);
    const filterstate: any = {};
    if (columnStates.columnList?.length > 0) {
      const columnState = gridRef?.columnApi
        ?.getColumnState()
        ?.map((column: any) => {
          const { columnList } = columnStates;
          const colKey = column?.colId || "";
          const col1 = columnList?.find((cols) => cols.field.name === colKey);
          const filter = col1?.field.filter;
          if (filter) {
            const range = { ...col1?.field?.range };
            if (range) {
              range.to = Number(range.to);
              range.from = Number(range.from);
            }
            filterstate[colKey] = {
              values: filter || null,
              filterType: "set",
              range
            };
          }

          const gridCol = { ...column };
          gridCol.hide = !col1?.visibility;
          gridCol.order = col1?.order;
          gridCol.sort = col1?.field.sortOrder;
          return gridCol;
        })
        .filter((c) => c);

      if (columnState) {
        const sortedArr = columnState?.sort((a, b) => a.order - b.order);
        gridRef?.columnApi.applyColumnState({
          state: sortedArr,
          applyOrder: true
        });

        if (Object.keys(filterstate).length > 0) {
          setTimeout(() => {
            gridRef?.api?.setFilterModel(filterstate);
          }, 0);
        }
      }
    }
  }
};
