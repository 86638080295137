import { ApolloProvider } from "@apollo/client";
import PrivateRoute from "ProtectedRoute";
import AppProvider from "context/AppProvider";
import ProjectProvider from "context/ProjectProvider";
import SubscriptionProvider from "context/SubscriptionProvider";
import { useGetGQLClientForSubscription } from "hooks/init-gql-client";
import { HashRouter, Route, Switch, useParams } from "react-router-dom";
import { ReactNode } from "react";
import { NotFoundPage } from "pages";
import ALogin from "./pages/login/a-login";
import ASubmittalList from "./pages/a-submittal-list/a-submittal-list";
import ASubmittalPage from "./pages/a-submittal";
import ANavbar from "./components/a-navbar";

type LayoutProps = {
  children: ReactNode;
};

function ProjectLayout(props: LayoutProps) {
  const { children } = props;
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <div className="flex flex-col h-screen">
      <ANavbar key={projectId} />
      {children}
    </div>
  );
}

function PrivateRoutes() {
  const { gqlClient } = useGetGQLClientForSubscription();
  if (!gqlClient) return <PrivateRoute />;
  return (
    <ApolloProvider client={gqlClient}>
      <AppProvider>
        <SubscriptionProvider>
          <Switch>
            <PrivateRoute path={["/project/:projectId"]}>
              <ProjectProvider>
                <ProjectLayout>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/project/:projectId/submittals"
                      component={ASubmittalList}
                    />
                    <PrivateRoute
                      exact
                      path="/project/:projectId/submittals/:submittalId"
                      component={ASubmittalPage}
                    />
                    <PrivateRoute>
                      <Route component={NotFoundPage} />
                    </PrivateRoute>
                  </Switch>
                </ProjectLayout>
              </ProjectProvider>
            </PrivateRoute>
            <PrivateRoute>
              <Route component={NotFoundPage} />
            </PrivateRoute>
          </Switch>
        </SubscriptionProvider>
      </AppProvider>
    </ApolloProvider>
  );
}

function PartnerApp() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={ALogin} />
        <Route component={PrivateRoutes} />
        <PrivateRoute>
          <Route component={NotFoundPage} />
        </PrivateRoute>
      </Switch>
    </HashRouter>
  );
}

export default PartnerApp;
