import { CloseOutlined } from "@ant-design/icons";
import FileIcon from "components/svg-icons/file-icon";

function AttachmentTag(props: {
  onDelete?: () => void;
  item: any;
  showDelete?: boolean;
  onFileClick?: (file: any) => void;
}) {
  const { onDelete, item, showDelete = false, onFileClick } = props;
  // console.log(item);
  return (
    <div className="flex items-center space-x-1 text-xs bg-[#F5F5F5] p-0.5 pl-1.5 max-w-[150px] cursor-pointer">
      <div className="flex items-center">
        <FileIcon />
      </div>
      {onFileClick ? (
        <button
          type="button"
          className="grow truncate pr-1 border-0 bg-transparent text-left p-0 cursor-pointer"
          title={item.name}
          onClick={() => {
            onFileClick(item);
          }}
        >
          {item.name}
        </button>
      ) : (
        <div className="grow truncate pr-1" title={item.name}>
          {item.name}
        </div>
      )}
      {onDelete && showDelete ? (
        <button
          type="button"
          className="cursor-pointer border-0 bg-transparent p-0 m-0 hover:bg-[#ebebeb] px-1"
          onClick={() => {
            onDelete();
          }}
        >
          <CloseOutlined
            style={{ fontSize: "10px" }}
            className="text-[#3B3B3B] opacity-80"
          />
        </button>
      ) : null}
    </div>
  );
}

export default AttachmentTag;
