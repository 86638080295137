/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-named-as-default */
import { PDFDocumentProxy } from "pdfjs-dist";
import { PureComponent } from "react";
import ReactDom from "react-dom";
import debounce from "lodash.debounce";
import {
  EventBus,
  PDFViewer,
  PDFLinkService,
  PDFFindController
} from "pdfjs-dist/legacy/web/pdf_viewer";

import "pdfjs-dist/web/pdf_viewer.css";
import "../style/pdf-viewer.css";

import "../style/PdfHighlighter.css";
import { fabric } from "fabric";
import { Button, Card, Drawer, Row, Select, Tree } from "antd";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import { DataNode } from "antd/lib/tree";
import { DateUtils } from "utils/dateutils";
import * as pdfjsViewer from "pdfjs-dist/web/pdf_viewer";
import {
  getPagesFromRange,
  getWindow,
  findOrCreateCanvasLayer,
  findOrCreateContainerLayer
} from "../lib/pdfjs-dom";
import { FabricCanvasLayer } from "../lib/fabricCanvasLayer";
import {
  IHighlight,
  IMarkupDb,
  ModeMarkup,
  NewHighlight,
  Position,
  ScaledPosition,
  Viewport
} from "../types";
import getBoundingRect from "../lib/get-bounding-rect";
import getClientRects from "../lib/get-client-rects";
import { scaledToViewport, viewportToScaled } from "../lib/coordinates";
import TipContainer from "./TipContainer";
import Tip from "./Tip";
import Highlight from "./Highlight";
import Popup from "./Popup";
import { FabricHelper } from "../lib/fabricHelper";

function HighlightPopup({
  comment,
  id,
  updateMarkup
}: {
  comment: string;
  id: string;
  updateMarkup: (id: string, updatedData: any) => void;
}) {
  console.log(id);
  return comment ? (
    <div className="bg-gray-500 text-white rounded-md p-1">
      <span className="pl-1">{comment} </span>
      <Button
        type="text"
        title="Remove Markup"
        onClick={() => updateMarkup(id, { deleted: true })}
        icon={<DeleteOutlined className="text-white" />}
      />
    </div>
  ) : null;
}
type T_ViewportHighlight<IHighlight> = { position: Position } & IHighlight;
const EMPTY_ID = "empty-id";

interface State {
  ghostHighlight: {
    position: ScaledPosition;
    text?: string;
  } | null;
  pageCount: number;
  pageChange: {
    pageNumber: number;
    previous: number | null;
  };
  isCollapsed: boolean;
  range: Range | null;
  tipPosition: Position | null;
  tipChildren: JSX.Element | null;
  tip: {
    highlight: T_ViewportHighlight<IHighlight>;
    callback: (highlight: T_ViewportHighlight<IHighlight>) => JSX.Element;
  } | null;
  scrolledToHighlightId: string;
  isOpenMarkupTreeDrawer: boolean;
  markupTreeData: Array<DataNode>;
}

interface Props {
  pdfDocument: PDFDocumentProxy;
  highlights: Array<IHighlight>;
  fabricObjects: Array<IMarkupDb>;
  addHighlight: (newHighLight: NewHighlight) => void;
  addFabric: (page_no: number, data: any, type?: string) => void;
  updateMarkup: (id: string, updatedData: any) => void;
  setIsNewVersionModalOpen: (isOpen: boolean) => void;
  isMarkupRead: boolean;
  isMarkupVersionFile: boolean;
}

export class PdfCanvas extends PureComponent<Props, State> {
  scaleValue = "auto";

  static defaultProps = {
    pdfScaleValue: "auto"
  };

  state: State = {
    ghostHighlight: null,
    pageCount: 0,
    pageChange: {
      pageNumber: 1,
      previous: null
    },
    isCollapsed: false,
    range: null,
    tipPosition: null,
    tipChildren: null,
    tip: null,
    scrolledToHighlightId: EMPTY_ID,
    isOpenMarkupTreeDrawer: false,
    markupTreeData: []
  };

  eventBus = new EventBus();

  linkService = new PDFLinkService({
    eventBus: this.eventBus,
    externalLinkTarget: 2
  });

  viewer!: PDFViewer;

  resizeObserver: ResizeObserver | null = null;

  containerNode?: HTMLDivElement | null = null;

  unsubscribe = () => {};

  handleScaleValue = () => {
    if (this.viewer) {
      this.viewer.currentScaleValue = this.scaleValue; // "page-width";
    }
  };

  debouncedScaleValue: () => void = debounce(this.handleScaleValue, 500);

  constructor(props: Props) {
    super(props);
    if (typeof ResizeObserver !== "undefined") {
      this.resizeObserver = new ResizeObserver(this.debouncedScaleValue);
    }
  }

  componentDidMount() {
    this.init();
  }

  attachRef = (ref: HTMLDivElement | null) => {
    const { eventBus, resizeObserver: observer } = this;
    this.containerNode = ref;
    this.unsubscribe();

    if (ref) {
      const { ownerDocument: doc } = ref;
      eventBus.on("textlayerrendered", this.onTextLayerRendered);
      eventBus.on("pagesinit", this.onDocumentReady);
      doc.addEventListener("selectionchange", this.onSelectionChange);
      // doc.addEventListener("keydown", this.handleKeyDown);
      doc.defaultView?.addEventListener("resize", this.debouncedScaleValue);
      if (observer) observer.observe(ref);

      this.unsubscribe = () => {
        eventBus.off("pagesinit", this.onDocumentReady);
        eventBus.off("textlayerrendered", this.onTextLayerRendered);
        doc.removeEventListener("selectionchange", this.onSelectionChange);
        // doc.removeEventListener("keydown", this.handleKeyDown);
        doc.defaultView?.removeEventListener(
          "resize",
          this.debouncedScaleValue
        );
        if (observer) observer.disconnect();
      };
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pdfDocument !== this.props.pdfDocument) {
      this.init();
    }
    if (prevProps.highlights !== this.props.highlights) {
      this.renderHighlights();
    }
    if (prevProps.fabricObjects !== this.props.fabricObjects) {
      this.updateFabricObjectsInCanvas();
    }
  }

  init() {
    const { pdfDocument } = this.props;
    const findController = new PDFFindController({
      linkService: this.linkService,
      eventBus: this.eventBus
    });

    this.viewer =
      this.viewer ||
      new PDFViewer({
        container: this.containerNode!,
        eventBus: this.eventBus,
        // enhanceTextSelection: true, // deprecated. https://github.com/mozilla/pdf.js/issues/9943#issuecomment-409369485
        textLayerMode: 2,
        removePageBorders: true,
        linkService: this.linkService,
        renderer: "canvas",
        l10n: pdfjsViewer.NullL10n,
        findController
      });

    this.linkService.setDocument(pdfDocument);
    this.linkService.setViewer(this.viewer);

    this.viewer.setDocument(pdfDocument);

    // debug
    (window as any).PdfViewer = this;
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onTextLayerRendered = (e: any) => {
    if (e.pageNumber === this.state.pageChange.pageNumber)
      this.findOrCreateFabricCanvasLayer();
    this.renderHighlights();
  };

  viewportPositionToScaled({
    pageNumber,
    boundingRect,
    rects
  }: Position): ScaledPosition {
    const { viewport } = this.viewer.getPageView(pageNumber - 1);

    return {
      boundingRect: viewportToScaled(boundingRect, viewport),
      rects: (rects || []).map((rect) => viewportToScaled(rect, viewport)),
      pageNumber
    };
  }

  onSelectionChange = () => {
    const container = this.containerNode;
    const selection = getWindow(container).getSelection();

    if (!selection) {
      return;
    }

    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    if (selection.isCollapsed) {
      this.setState({ isCollapsed: true });
      return;
    }

    if (
      !range ||
      !container ||
      !container.contains(range.commonAncestorContainer)
    ) {
      return;
    }

    this.setState({
      isCollapsed: false,
      range
    });

    this.debouncedAfterSelection();
  };

  afterSelection = () => {
    const { isCollapsed, range } = this.state;
    if (!range || isCollapsed) {
      return;
    }

    const pages = getPagesFromRange(range);

    if (!pages || pages.length === 0) {
      return;
    }

    const rects = getClientRects(range, pages);

    if (rects.length === 0) {
      return;
    }

    const boundingRect = getBoundingRect(rects);

    const viewportPosition: Position = {
      boundingRect,
      rects,
      pageNumber: pages[0].number
    };

    const text = range.toString();
    const scaledPosition = this.viewportPositionToScaled(viewportPosition);

    this.setState({
      ghostHighlight: { position: scaledPosition }
    });
    const tipDiv = (
      <Tip
        onOpen={() => {
          const newHighLight: NewHighlight = {
            comment: "Highlight",
            text,
            position: scaledPosition
          };
          this.props.addHighlight(newHighLight);
          this.hideTipAndSelection();
          this.renderHighlights();
        }}
        onConfirm={() => {}}
      />
    );

    this.setTip(viewportPosition, tipDiv);
    // this.renderHighlights();
  };

  private hideTipAndSelection = () => {
    this.setState({
      tipPosition: null,
      tipChildren: null
    });

    this.setState({ ghostHighlight: null, tip: null }, () =>
      this.renderHighlights()
    );
  };

  private setTip(position: Position, inner: JSX.Element | null) {
    this.setState({
      tipPosition: position,
      tipChildren: inner
    });
  }

  renderTip = () => {
    const { tipPosition, tipChildren } = this.state;
    if (!tipPosition) return null;

    const { boundingRect, pageNumber } = tipPosition;
    const page = {
      node: this.viewer.getPageView((boundingRect.pageNumber || pageNumber) - 1)
        .div,
      pageNumber: boundingRect.pageNumber || pageNumber
    };

    const pageBoundingClientRect = page.node.getBoundingClientRect();

    const pageBoundingRect = {
      bottom: pageBoundingClientRect.bottom,
      height: pageBoundingClientRect.height,
      left: pageBoundingClientRect.left,
      right: pageBoundingClientRect.right,
      top: pageBoundingClientRect.top,
      width: pageBoundingClientRect.width,
      x: pageBoundingClientRect.x,
      y: pageBoundingClientRect.y,
      pageNumber: page.pageNumber
    };

    return (
      <TipContainer
        scrollTop={this.viewer.container.scrollTop}
        pageBoundingRect={pageBoundingRect}
        style={{
          left:
            page.node.offsetLeft + boundingRect.left + boundingRect.width / 2,
          top: boundingRect.top + page.node.offsetTop,
          bottom: boundingRect.top + page.node.offsetTop + boundingRect.height
        }}
      >
        {tipChildren}
      </TipContainer>
    );
  };

  findOrCreateHighlightLayer(page: number) {
    const { textLayer } = this.viewer.getPageView(page - 1) || {};

    if (!textLayer) {
      return null;
    }

    return findOrCreateContainerLayer(
      textLayer.textLayerDiv,
      "PdfHighlighter__highlight-layer"
    );
  }

  private groupHighlightsByPage(highlights: Array<IHighlight>): {
    [pageNumber: string]: Array<IHighlight>;
  } {
    const { ghostHighlight } = this.state;

    const allHighlights = [...highlights, ghostHighlight].filter(Boolean);

    const pageNumbers = new Set<number>();
    for (const highlight of allHighlights) {
      pageNumbers.add(highlight!.position.pageNumber);
      for (const rect of highlight!.position.rects) {
        if (rect.pageNumber) {
          pageNumbers.add(rect.pageNumber);
        }
      }
    }

    const groupedHighlights = {} as Record<number, any[]>;

    for (const pageNumber of pageNumbers) {
      groupedHighlights[pageNumber] = groupedHighlights[pageNumber] || [];
      for (const highlight of allHighlights) {
        const pageSpecificHighlight = {
          ...highlight,
          position: {
            pageNumber,
            boundingRect: highlight!.position.boundingRect,
            rects: [],
            usePdfCoordinates: highlight!.position.usePdfCoordinates
          } as ScaledPosition
        };
        let anyRectsOnPage = false;
        for (const rect of highlight!.position.rects) {
          if (
            pageNumber === (rect.pageNumber || highlight!.position.pageNumber)
          ) {
            pageSpecificHighlight.position.rects.push(rect);
            anyRectsOnPage = true;
          }
        }
        if (anyRectsOnPage || pageNumber === highlight!.position.pageNumber) {
          groupedHighlights[pageNumber].push(pageSpecificHighlight);
        }
      }
    }

    return groupedHighlights;
  }

  private scaledPositionToViewport({
    pageNumber,
    boundingRect,
    rects,
    usePdfCoordinates
  }: ScaledPosition): Position {
    const { viewport } = this.viewer.getPageView(pageNumber - 1);

    return {
      boundingRect: scaledToViewport(boundingRect, viewport, usePdfCoordinates),
      rects: (rects || []).map((rect) =>
        scaledToViewport(rect, viewport, usePdfCoordinates)
      ),
      pageNumber
    };
  }

  private showTip(highlight: IHighlight, content: JSX.Element) {
    const { isCollapsed, ghostHighlight } = this.state;

    const highlightInProgress = !isCollapsed || ghostHighlight;

    if (highlightInProgress) {
      return;
    }

    this.setTip(this.scaledPositionToViewport(highlight.position), content);
  }

  renderHighlights() {
    const { pdfDocument, highlights } = this.props;
    const { tip, scrolledToHighlightId } = this.state;

    const highlightsByPage = this.groupHighlightsByPage(highlights);

    for (
      let pageNumber = 1;
      pageNumber <= pdfDocument.numPages;
      pageNumber += 1
    ) {
      const highlightLayer = this.findOrCreateHighlightLayer(pageNumber);

      if (highlightLayer) {
        ReactDom.render(
          <div>
            {(highlightsByPage[String(pageNumber)] || []).map(
              ({ position, id, ...highlight }, index) => {
                // @ts-ignore
                const viewportHighlight: T_ViewportHighlight<T_HT> = {
                  id,
                  position: this.scaledPositionToViewport(position),
                  ...highlight
                };

                if (tip && tip.highlight.id === String(id)) {
                  this.showTip(tip.highlight, tip.callback(viewportHighlight));
                }

                const isScrolledTo = Boolean(scrolledToHighlightId === id);

                const component = (
                  <Highlight
                    isScrolledTo={isScrolledTo}
                    position={this.scaledPositionToViewport(position)}
                  />
                );

                return (
                  <Popup
                    popupContent={
                      <HighlightPopup
                        id={id}
                        comment={viewportHighlight.comment}
                        updateMarkup={this.props.updateMarkup}
                      />
                    }
                    onMouseOver={(popupContent) =>
                      this.setTip(
                        this.scaledPositionToViewport(position),
                        popupContent
                      )
                    }
                    onMouseOut={this.hideTipAndSelection}
                    key={index}
                    children={component}
                  />
                );
              }
            )}
          </div>,
          highlightLayer
        );
      }
    }
  }

  debouncedAfterSelection: () => void = debounce(this.afterSelection, 500);

  onDocumentReady = () => {
    // const { scrollRef } = this.props;
    this.setState({ pageCount: this.linkService.pagesCount });
    this.eventBus.on(
      "pagechanging",
      (pageChange: {
        pageLabel: any;
        pageNumber: number;
        previous: number;
      }) => {
        this.setState({ pageChange });
        this.findOrCreateFabricCanvasLayer();
      }
    );

    this.handleScaleValue();

    // scrollRef(this.scrollTo);
  };

  downloadPDF() {
    const { pdfDocument } = this.props;
    pdfDocument.getData().then((data) => {
      this.downLoadFile(data, "pdf/application");
    });
  }

  downLoadFile(data: any, type: string) {
    const blob = new Blob([data], { type: type.toString() });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", "download.pdf");
    document.body.appendChild(anchor);
    anchor.click();
    anchor.parentNode!.removeChild(anchor);
  }

  canvasLayer: FabricCanvasLayer | undefined;

  private updateFabricObjectsInCanvas() {
    if (this.canvasLayer) {
      const { pageno } = this.canvasLayer;
      const currentPageObjects = this.props.fabricObjects.filter(
        (f) => f.page_no === pageno
      );
      console.log(
        `Total : ${this.props.fabricObjects.length}, Current ${pageno} page : ${currentPageObjects.length}`
      );
      if (currentPageObjects.length > 0) {
        const objects = currentPageObjects.map((o) => o.data);
        this.canvasLayer.canvas.loadFromJSON({ objects }, () => {
          if (this.canvasLayer?.canvas) {
            const callbackAfterTextEdit = (g: fabric.Group) => {
              if (g.data) {
                const jsonData = g.toDatalessObject(["data"]);
                this.props.updateMarkup(g.data.id, { data: jsonData });
              }
            };
            FabricHelper.afterLoadFromJSON(
              this.canvasLayer?.canvas,
              callbackAfterTextEdit
            );
          }
        });
      }
    }
  }

  findOrCreateFabricCanvasLayer() {
    const { pageNumber } = this.state.pageChange;
    const { textLayer, viewport } =
      this.viewer.getPageView(pageNumber - 1) || {};

    if (!textLayer) {
      return;
    }
    const canvasDiv = findOrCreateCanvasLayer(
      textLayer.textLayerDiv,
      `canvas${pageNumber}`
    );

    if (!this.canvasLayer) {
      const canvas = new fabric.Canvas(canvasDiv.id, {
        width: viewport.width,
        height: viewport.height
      });
      this.canvasLayer = new FabricCanvasLayer(
        canvas,
        pageNumber,
        this.props.addFabric,
        this.props.updateMarkup
      );
    } else {
      this.canvasLayer.canvas.initialize(canvasDiv.id, {
        width: viewport.width,
        height: viewport.height
      });
      this.canvasLayer.pageno = pageNumber;
    }
    this.canvasLayer.canvas.setZoom(viewport.width / 1000);

    // Update current page fabric objects
    this.updateFabricObjectsInCanvas();
  }

  // searchText(text: string) {
  //   (this.viewer.findController as PDFFindController).executeCommand("find", {
  //     caseSensitive: false,
  //     findPrevious: undefined,
  //     highlightAll: true,
  //     phraseSearch: true,
  //     query: text
  //   });
  // }

  pointerEventToggle(isNone: boolean) {
    const collection = document.getElementsByClassName("fabric-canvas-layer");
    for (let i = 0; i < collection.length; i += 1) {
      const ele: any = collection[i];
      if (isNone) ele.style["pointer-events"] = "none";
      else ele.style["pointer-events"] = "all";
    }
  }

  onSelectedMarkupTool(mode: ModeMarkup) {
    this.pointerEventToggle(false);
    if (this.canvasLayer) {
      console.log("Layered");
      this.canvasLayer.onToolSelect(mode);
    } else console.log("Not created");
  }

  pdfZoomUpdate(value: any) {
    this.scaleValue = value;
    this.handleScaleValue();
  }

  zoomOptions = [
    {
      value: "auto",
      label: "Auto zoom"
    },
    {
      value: "page-actual",
      label: "Actual size"
    },
    {
      value: "page-fit",
      label: "Page fit"
    },
    {
      value: "page-width",
      label: "Page width"
    },
    {
      value: "0.5",
      label: "50%"
    },
    {
      value: "0.75",
      label: "75%"
    },
    {
      value: "1",
      label: "100%"
    },
    {
      value: "1.5",
      label: "150%"
    },
    {
      value: "2",
      label: "200%"
    },
    {
      value: "3",
      label: "300$"
    },
    {
      value: "4",
      label: "400%"
    }
  ];

  showMarkupTreeDrawer = () => {
    const { highlights, fabricObjects } = this.props;
    const allMarkups = [...fabricObjects, ...highlights];
    const result = allMarkups.reduce((r: Record<number, any[]>, a) => {
      // eslint-disable-next-line no-param-reassign
      r[a.page_no] = r[a.page_no] || [];
      r[a.page_no].push(a);
      return r;
    }, Object.create(null));

    const treeData = Array<DataNode>();
    // eslint-disable-next-line no-restricted-syntax
    for (const pageno in result) {
      if (Object.prototype.hasOwnProperty.call(result, pageno)) {
        const element = result[pageno];

        const tree = {
          title: `Page ${pageno}`,
          key: `page ${pageno}`,
          children: element.map((ele) => {
            return {
              title: (
                <Row
                  className="flex-col"
                  onClick={() => {
                    this.setState({ isOpenMarkupTreeDrawer: false });
                    const scrollMargin = 20;
                    const pageNumber = Number(pageno);
                    const pageViewport = this.viewer.getPageView(pageNumber - 1)
                      .viewport as Viewport;
                    if (ele.type === "fabric") {
                      const top =
                        ele.data.top * (pageViewport.width / 1000) -
                        scrollMargin;
                      const point = pageViewport.convertToPdfPoint(0, top);
                      this.viewer.scrollPageIntoView({
                        pageNumber,
                        destArray: [null, { name: "XYZ" }, ...point, 0]
                      });
                    } else {
                      const highlight = ele as IHighlight;
                      const { boundingRect, usePdfCoordinates } =
                        highlight.position;

                      const { top } = scaledToViewport(
                        boundingRect,
                        pageViewport,
                        usePdfCoordinates
                      );
                      const point = pageViewport.convertToPdfPoint(
                        0,
                        top - scrollMargin
                      );
                      this.viewer.scrollPageIntoView({
                        pageNumber,
                        destArray: [null, { name: "XYZ" }, ...point, 0]
                      });
                    }
                  }}
                >
                  <div className=" text-color-one text-two">
                    {ele.type === "fabric" ? ele.data.type : "Highlight"}
                  </div>
                  <div className=" text-color-two text-three">
                    created by : {ele.created_by_user.first_name}
                    {ele.created_by_user.last_name},
                    {DateUtils.format(ele.created_at)}
                  </div>
                </Row>
              ),
              key: ele.id
            };
          })
        };
        treeData.push(tree);
      }
    }
    this.setState({ markupTreeData: treeData, isOpenMarkupTreeDrawer: true });
  };

  onClose = () => {
    this.setState({ isOpenMarkupTreeDrawer: false });
  };

  render() {
    return (
      <div>
        <Card bodyStyle={{ padding: 4 }}>
          <div className="flex">
            <div className="flex-grow">
              {this.props.isMarkupRead && (
                <div className="space-x-1 flex">
                  <Button
                    onClick={() => {
                      this.pointerEventToggle(true);
                    }}
                  >
                    HighLight
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.NONE);
                    }}
                  >
                    None
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.RECTANGLE);
                    }}
                  >
                    Rect
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.CLOUD_RECTANGLE);
                    }}
                  >
                    Cloud
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.CIRCLE);
                    }}
                  >
                    Circle
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.ARROW);
                    }}
                  >
                    Arrow
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.RULER);
                    }}
                  >
                    Ruler
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.TEXT);
                    }}
                  >
                    Text
                  </Button>
                  <Button
                    onClick={() => {
                      this.onSelectedMarkupTool(ModeMarkup.CALLOUT);
                    }}
                  >
                    Callout
                  </Button>
                </div>
              )}
            </div>
            <div className="space-x-1 flex items-center">
              <span>
                Page {this.state.pageChange.pageNumber} of{" "}
                {this.state.pageCount}
              </span>
              {this.props.isMarkupRead && this.props.isMarkupVersionFile && (
                <Button
                  onClick={() => this.props.setIsNewVersionModalOpen(true)}
                >
                  Save as markup file
                </Button>
              )}
              {this.props.isMarkupRead && (
                <Button onClick={this.showMarkupTreeDrawer}>
                  Open markup List
                </Button>
              )}
              <Select
                className="w-32"
                defaultValue="auto"
                onChange={this.pdfZoomUpdate.bind(this)}
                options={this.zoomOptions}
              />
              <Button
                onClick={() => {
                  this.downloadPDF();
                }}
              >
                Download
              </Button>
            </div>
          </div>
        </Card>
        <div style={{ position: "relative" }}>
          <div
            ref={this.attachRef}
            className="PdfHighlighter"
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="pdfViewer" />
            {this.renderTip()}
          </div>
          <Drawer
            title="Markups"
            placement="right"
            onClose={this.onClose.bind(this)}
            open={this.state.isOpenMarkupTreeDrawer}
          >
            <Tree
              showIcon
              defaultExpandAll
              defaultSelectedKeys={["0-0-0"]}
              switcherIcon={<DownOutlined />}
              treeData={this.state.markupTreeData}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}
