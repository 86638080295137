import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Col, Input, Popover, Row, Spin } from "antd";
import { ESubmittalStatus } from "constants/index";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { MUTATION_SUBMITTAL_SUMMARY } from "services/graphQL/mutations";
import { QUERY_ACTIVE_PROJECT_USERS } from "services/graphQL/queries";

export function CloseAndDistributeSubmittal(props: {
  submittalData: any;
  submittalWorkflowResponses?: any;
  reviewVal?: string;
  submittalViewState: {
    status: number;
    userType: string;
  };
  commentVal?: string;
  redistributeSubmittalToUsers?: any;
}) {
  const {
    submittalData,
    submittalWorkflowResponses,
    reviewVal,
    submittalViewState,
    commentVal,
    redistributeSubmittalToUsers
  } = props;
  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);
  const { projectId } = useParams() as any;
  const [submittalSummary, setSubmittalSummary] = useState<any>();
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [foundUsers, setFoundUsers] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState<any>([]);

  const { data: usersData, loading } = useQuery(QUERY_ACTIVE_PROJECT_USERS, {
    fetchPolicy: "no-cache",
    client: gqlClientForProject,
    variables: {
      where: {
        project_id: { _eq: projectId },
        status_id: { _eq: 2 }
      }
    }
  });

  const [getSubmittalDistributionSummary] = useMutation<any>(
    MUTATION_SUBMITTAL_SUMMARY,
    {
      client: gqlClientForProject
    }
  );

  const retriveSubmittalSummary = async () => {
    setSummaryLoading(true);
    const submittalSummaryData: any = await getSubmittalDistributionSummary({
      variables: { submittalId: submittalData.id }
    });
    setSummaryLoading(false);
    if (submittalSummaryData?.data?.prepare_submittal_distribution_summary) {
      setSubmittalSummary(
        submittalSummaryData?.data?.prepare_submittal_distribution_summary
      );
    }
  };

  useEffect(() => {
    if (usersData?.project_users) {
      setFoundUsers(usersData?.project_users);
    }
  }, [usersData]);

  useEffect(() => {
    if (submittalViewState.status !== ESubmittalStatus.DONE) {
      retriveSubmittalSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittalData.id]);

  const reviewStatusObj = submittalWorkflowResponses?.filter(
    (item: any) => item.id === reviewVal
  );

  const onSearch = (e: any) => {
    const searchTxt = e.target.value;

    if (searchTxt !== "") {
      const results = usersData?.project_users?.filter((user: any) => {
        return `${user?.user?.first_name} ${user?.user?.last_name}`
          .toLowerCase()
          .includes(searchTxt.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundUsers(results);
    } else {
      setFoundUsers(usersData?.project_users);
    }

    setSearchText(searchTxt);
  };

  const getDistributionUsers = () => {
    const distributiongroupsandusers =
      submittalSummary?.submittalDLDisplayList || [];
    const dlGroups: any[] = submittalSummary?.submittalDGDisplayList || [];
    const popupContent = (users: []) => (
      <div className="flex-col border-solid border-[1px] p-1 space-y-1 border-gray-300 overflow-y-auto max-h-64">
        {users.map((item: any) => {
          return (
            <div className="flex-col px-2 border-solid  border-[1px] border-gray-300 min-w-[100px]  max-w-[150px] truncate">
              {item?.name}
            </div>
          );
        })}
      </div>
    );

    return (
      <div className="flex flex-wrap w-full gap-x-2">
        {distributiongroupsandusers.map((user: any, index: number) => {
          const dlUsers = dlGroups.find((usr: any) => usr.name === user.name);
          return user.type === "distribution_group" ? (
            <div className="flex min-w-fit cursor-pointer">
              <Popover content={popupContent(dlUsers?.users || [])}>
                {distributiongroupsandusers.length > 0 &&
                index <= distributiongroupsandusers.length - 2
                  ? `${user?.name},`
                  : user?.name}
              </Popover>
            </div>
          ) : (
            <div className="flex min-w-fit">
              {distributiongroupsandusers.length > 0 &&
              index <= distributiongroupsandusers.length - 2
                ? `${user?.name},`
                : user?.name}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex justify-center items-center">
      {loading || summaryLoading ? (
        <Spin />
      ) : (
        <div className="flex w-full h-full">
          {submittalViewState.status !== ESubmittalStatus.DONE && (
            <Col className="flex-col w-full h-full border-solid border-[1px] border-gray-300 p-2 px-2 space-y-2">
              <Row className="flex-col border-solid border-[1px] border-gray-300 px-2">
                <div className="flex space-x-2 h-6">
                  <p className="font-semibold">GC Reviewer: </p>
                  <p>{submittalSummary?.mainGcReviewer || ""}</p>
                </div>
                <div className="flex space-x-2 h-6">
                  <p className=" font-semibold">Review Status</p>
                  <p>
                    {(reviewStatusObj?.length > 0 &&
                      reviewStatusObj[0].response) ||
                      submittalSummary?.latestResponse ||
                      ""}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <p className=" font-semibold">Comment:</p>
                  <textarea
                    readOnly
                    className="w-full border-gray-300 mb-2 pl-1 outline-none"
                  >
                    {commentVal || submittalSummary?.latestComment || ""}
                  </textarea>
                </div>
              </Row>
              <Row className="flex-col border-solid border-[1px] border-gray-300 px-2">
                <div className="flex space-x-2 h-6">
                  <p className=" font-semibold">
                    Responsible Contractor Company:
                  </p>
                  <p
                    className="max-w-[180px] truncate h-full"
                    title={
                      submittalSummary?.responsibleContractorCompanyName || ""
                    }
                  >
                    {submittalSummary?.responsibleContractorCompanyName || ""}
                  </p>
                </div>
                <div className="flex space-x-2 h-6">
                  <p className=" font-semibold">Submitter(s):</p>
                  <p
                    className="max-w-[320px] truncate h-full"
                    title={submittalSummary?.submitters || ""}
                  >
                    {submittalSummary?.submitters || ""}
                  </p>
                </div>
              </Row>
              <Row className="flex-col border-solid border-[1px] border-gray-300 px-2">
                <div className="flex space-x-2 h-6">
                  <p className=" font-semibold">Design Reviewer Company:</p>
                  <p
                    className="max-w-[220px] truncate h-full"
                    title={submittalSummary?.designReviewerCompanyName || ""}
                  >
                    {submittalSummary?.designReviewerCompanyName || ""}
                  </p>
                </div>
                <div className="flex space-x-2 h-6">
                  <p className=" font-semibold">Design Reviewer(s):</p>
                  <p
                    className="max-w-[270px] truncate h-full"
                    title={submittalSummary?.designReviewers || ""}
                  >
                    {submittalSummary?.designReviewers || ""}
                  </p>
                </div>
              </Row>
              <Row className="flex-col border-solid border-[1px] border-gray-300 px-2">
                <div className="flex space-x-2 h-6">
                  <p className=" font-semibold">Alternate GC Reviewer(s):</p>
                  <p
                    className="max-w-[230px] truncate h-full"
                    title={submittalSummary?.alternateGcReviewers || ""}
                  >
                    {submittalSummary?.alternateGcReviewers || ""}
                  </p>
                </div>
              </Row>
              <Row className="flex-col border-solid border-[1px] border-gray-300 px-2">
                <div className="flex space-x-2">
                  <p className=" font-semibold">Distribution List:</p>
                  <p>{getDistributionUsers()}</p>
                </div>
              </Row>
            </Col>
          )}
          {submittalViewState.status === ESubmittalStatus.DONE && (
            <div className="flex-col w-full space-y-2">
              <Input
                type="search"
                value={searchText}
                onChange={onSearch}
                className="p-2 border-solid border-[1px] border-gray-300 w-full h-10 rounded focus:border-none focus:outline-gray-300"
                placeholder="Search here"
              />
              <Checkbox.Group className="flex w-full">
                <Col
                  span={50}
                  className="flex-col w-full overflow-y-auto max-h-80 border-solid border-[1px] border-gray-300 px-2 py-2 space-y-2"
                >
                  {foundUsers && foundUsers?.length === 0 && (
                    <p className="mt-2 w-full text-center">No user found</p>
                  )}
                  {foundUsers?.length > 0 &&
                    foundUsers.map((item: any) => {
                      return (
                        <Row className="flex w-full">
                          <div className="flex w-full p-2 border-solid border-[1px] border-gray-300">
                            <Checkbox
                              value={item?.user?.email}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setCheckedUsers([
                                    ...checkedUsers,
                                    e.target.value
                                  ]);

                                  redistributeSubmittalToUsers([
                                    ...checkedUsers,
                                    e.target.value
                                  ]);
                                } else {
                                  const checkedUserArr = checkedUsers.filter(
                                    (email: string) => email !== e.target.value
                                  );
                                  redistributeSubmittalToUsers(checkedUserArr);
                                  setCheckedUsers(checkedUserArr);
                                }
                              }}
                            >
                              {`${item?.user?.first_name} ${item?.user?.last_name}`}
                            </Checkbox>
                          </div>
                        </Row>
                      );
                    })}
                </Col>
              </Checkbox.Group>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
