import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button, Drawer } from "antd";
import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";
import { GridLoadingIndicator } from "components/widgets";

import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useQuery, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_PROJECT_VENDORS } from "services/graphQL/subscriptions";
import InviteProjectCompanies from "components/invite-project-companies";
import { QUERY_TRADE_USER_TYPE } from "services/graphQL/queries";
import InviteCompanyProjectUser from "pages/invite-user/invite-company-project-user";
import { FilterItem } from "models/types";
import { FilterChips } from "components";
import { getSource, isProjectInIntegrationMode } from "utils/utils";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";

function ProjectCompanies() {
  const gridRef = useRef<AgGridReact>(null);
  const [drawerCompany, setCompanyDrawerVisibility] = useState<boolean>(false);
  const [drawerCompanyUser, setCompanyUserDrawerVisibility] =
    useState<boolean>(false);
  const [isGridReady, setGridReady] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{
    venderId: string;
    typeId: number;
  }>();
  const { gqlClientForProject, tokenContents, projectDetails } =
    useContext(ProjectContext);
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [showClearBtn, setShowClearBtn] = useState(false);
  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const { data: companyTypes } = useQuery(QUERY_TRADE_USER_TYPE);

  const { data: companiesData } = useSubscription(
    SUBSCRIPTION_PROJECT_VENDORS,
    {
      client: gqlClientForProject,
      skip: !gqlClientForProject && !companyTypes,
      variables: { where: {} }
    }
  );

  // Each Column Definition results in one Column.

  const columnDefs: ColDef[] = useMemo<ColDef[]>(() => {
    return [
      {
        field: "subscription_vendor.name",
        headerName: "Company Name",
        headerTooltip: "COMPANY NAME",
        menuTabs: [],
        filter: false,
        tooltipField: "subscription_vendor.name",
        sort: "asc"
      },
      {
        field: "project_vendors_type.name",
        headerName: "Role of Company",
        headerTooltip: "ROLE OF COMPANY",
        menuTabs: ["filterMenuTab"],
        filter: true,
        tooltipField: "project_vendors_type.name"
      },
      {
        colId: "external_source_id",
        headerName: "Source",
        menuTabs: ["filterMenuTab"],
        hide: !isIntegrationMode,
        valueGetter: ({ data }) => {
          return getSource(data?.external_source_id);
        }
      }
    ];
  }, [isIntegrationMode]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true
    };
  }, []);

  const onFilterTextBoxChanged = useCallback(
    (value: string) => {
      gridRef.current!.api.setQuickFilter(value);
    },
    [gridRef]
  );

  useEffect(() => {
    const handleResize = () => {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    };
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const contextData = {
    companyTypes,
    setCompanyUserDrawerVisibility,
    setSelectedCompany,
    projectRole: tokenContents?.role
  };

  const onFilterChipDelete = (item: FilterItem) => {
    if (gridRef.current) {
      const filterInstance = gridRef.current.api.getFilterInstance(item.field);
      if (filterInstance) {
        const model = filterInstance.getModel();
        const values = model.values.filter((val: any) => val !== item.value);
        if (values.length === 0) {
          gridRef?.current?.api.destroyFilter(item.field);
          return;
        }
        filterInstance.setModel({ values });
        gridRef.current.api.onFilterChanged();
      }
    }
  };

  const resetFilters = () => {
    setShowClearBtn(false);
    // setGridSearchtext("");
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "subscription_vendor.name":
          columnDefArr[index].sortable = true;
          break;
        default:
          columnDefArr[index].sortable = true;
          columnDefArr[index].suppressMenu = false;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
    gridRef.current!.api.setFilterModel(null);
    gridRef.current!.api.setQuickFilter("");
  };

  function setSortAndFilterVisibility(
    isEnabled: boolean,
    isRowDataUpdated: boolean
  ) {
    setShowClearBtn(isRowDataUpdated ? false : !isEnabled);
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "subscription_vendor.name":
          columnDefArr[index].sortable = isEnabled;
          break;
        default:
          columnDefArr[index].sortable = isEnabled;
          columnDefArr[index].suppressMenu = !isEnabled;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
  }

  const onFiltersApplied = (event: any) => {
    const filtersApplied = event.api.getFilterModel();
    if (filtersApplied) {
      const items: FilterItem[] = new Array<FilterItem>();
      Object.keys(filtersApplied).forEach((key: any) => {
        if (
          filtersApplied[key]?.values &&
          filtersApplied[key].values.length > 0
        ) {
          const field = columnDefs.filter((x) => x.field === key)[0];
          const { values } = filtersApplied[key];

          items.push({
            field: key,
            header: field ? field.headerName : key,
            value: values.filter(Boolean)
          });
        }
      });
      setFilters(items.filter(Boolean));
    }

    if (
      gridRef?.current &&
      gridRef.current.api?.getDisplayedRowCount() < 2 &&
      gridRef.current.api?.isAnyFilterPresent()
    ) {
      setSortAndFilterVisibility(false, false);
    } else {
      setSortAndFilterVisibility(true, false);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-160px)]">
      <ActionBar noXPadding noYPadding>
        <div className="flex w-full justify-end space-x-2 items-center">
          <SearchInput
            placeholder="Search Project Companies"
            onChange={onFilterTextBoxChanged}
          />
          <FilterChips
            items={filters}
            onChipDelete={onFilterChipDelete}
            onCategoryDelete={(item: FilterItem) => {
              gridRef?.current?.api?.destroyFilter(item.field);
            }}
            resetAll={resetFilters}
          />
          {showClearBtn && !filters?.length && (
            <Button
              onClick={() => {
                filters.forEach((item: any) => {
                  gridRef?.current?.api?.destroyFilter(item.field);
                });

                resetFilters();
              }}
            >
              Clear All
            </Button>
          )}
          <Button
            onClick={() => {
              setCompanyDrawerVisibility(true);
            }}
            title={
              isPermissionNotGrantted(
                ProjectPermissionEnum.AddTradePartner,
                tokenContents?.role!
              )
                ? ErrorMessages.PermissionNotGranted
                : "Add Company"
            }
            disabled={isPermissionNotGrantted(
              ProjectPermissionEnum.AddTradePartner,
              tokenContents?.role!
            )}
          >
            Add Company
          </Button>
        </div>
      </ActionBar>
      <div className="grow ag-theme-alpine">
        <AgGridReact<any>
          ref={gridRef}
          onGridReady={() => {
            gridRef.current!.api.sizeColumnsToFit();
            setGridReady(true);
          }}
          rowData={companiesData?.project_vendors}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows // Optional - set to 'true' to have rows animate when sorted
          loadingOverlayComponent={GridLoadingIndicator}
          suppressDragLeaveHidesColumns
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          context={contextData}
          onFilterChanged={onFiltersApplied}
          onRowDataUpdated={() => {
            if (
              gridRef.current &&
              gridRef.current.api.getDisplayedRowCount() < 2
            ) {
              setSortAndFilterVisibility(false, true);
            } else {
              setSortAndFilterVisibility(true, true);
            }
          }}
        />
      </div>
      {drawerCompany && (
        <InviteProjectCompanies
          setDrawerVisibility={setCompanyDrawerVisibility}
          showAddProjectCompanyModel={drawerCompany}
        />
      )}
      <Drawer
        width={450}
        title="Invite Company"
        placement="right"
        onClose={() => {
          setCompanyUserDrawerVisibility(false);
        }}
        open={drawerCompanyUser}
      >
        {drawerCompanyUser && selectedCompany && (
          <InviteCompanyProjectUser
            setDrawerVisibility={setCompanyUserDrawerVisibility}
            companyInput={selectedCompany}
          />
        )}
      </Drawer>
    </div>
  );
}

export default ProjectCompanies;
