export interface IconProps {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  fill?: string;
  size?: number;
  title?: string;
}
function SubmittalIcon({
  className = "",
  onClick,
  style,
  fill = "#3B3B3B",
  size = 24,
  title = "Submittal"
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      className={className}
      onClick={onClick}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_62_174)">
        <path d="M6 7V4C6 3.44772 6.44772 3 7 3H13.4142L15.4142 5H21C21.5523 5 22 5.44772 22 6V16C22 16.5523 21.5523 17 21 17H18V20C18 20.5523 17.5523 21 17 21H3C2.44772 21 2 20.5523 2 20V8C2 7.44772 2.44772 7 3 7H6ZM6 9H4V19H16V17H6V9ZM8 5V15H20V7H14.5858L12.5858 5H8Z" />
      </g>
    </svg>
  );
}

export default SubmittalIcon;
