export default function PdfAttachmentIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1375 5.6125H3.6V4.575H4.2C4.33104 4.575 4.44088 4.53067 4.52953 4.44203C4.61818 4.35338 4.6625 4.24354 4.6625 4.1125V3.5125C4.6625 3.38146 4.61818 3.27162 4.52953 3.18298C4.44088 3.09433 4.33104 3.05 4.2 3.05H3.1375V5.6125ZM3.6 4.1125V3.5125H4.2V4.1125H3.6ZM5.2125 5.6125H6.2625C6.3875 5.6125 6.49583 5.56817 6.5875 5.47953C6.67917 5.39088 6.725 5.28104 6.725 5.15V3.5125C6.725 3.38146 6.67917 3.27162 6.5875 3.18298C6.49583 3.09433 6.3875 3.05 6.2625 3.05H5.2125V5.6125ZM5.675 5.15V3.5125H6.2625V5.15H5.675ZM7.3375 5.6125H7.8V4.575H8.425V4.1125H7.8V3.5125H8.425V3.05H7.3375V5.6125ZM2.25 8.5C2.05 8.5 1.875 8.425 1.725 8.275C1.575 8.125 1.5 7.95 1.5 7.75V0.75C1.5 0.55 1.575 0.375 1.725 0.225C1.875 0.075 2.05 0 2.25 0H9.25C9.45 0 9.625 0.075 9.775 0.225C9.925 0.375 10 0.55 10 0.75V7.75C10 7.95 9.925 8.125 9.775 8.275C9.625 8.425 9.45 8.5 9.25 8.5H2.25ZM2.25 7.75H9.25V0.75H2.25V7.75ZM0.75 10C0.55 10 0.375 9.925 0.225 9.775C0.075 9.625 0 9.45 0 9.25V1.5H0.75V9.25H8.5V10H0.75Z"
        fill="#3B3B3B"
        fillOpacity="0.5"
      />
    </svg>
  );
}
