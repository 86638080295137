import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_USER_PROFILE_INFO } from "services/graphQL/subscriptions";

export const useUserProfileInfo = (emailId: string) => {
  const { data, loading, error } = useSubscription(
    SUBSCRIPTION_USER_PROFILE_INFO,
    {
      variables: { where: { user: { email: { _eq: emailId } } } },
      onComplete: () => {
        console.log("onComplete called");
      },
      shouldResubscribe: true
    }
  );

  return { loading, error, data };
};
