import { createContext } from "react";

export type TScheduleChange = {
  params: {
    importLogId: any;
    sourceLogId: any;
    projectToken: any;
  };
  searchText: string;
};

export const ScheduleChangeDefaultState: TScheduleChange = {
  params: {
    importLogId: null,
    sourceLogId: null,
    projectToken: null
  },
  searchText: ""
};

export const ScheduleChangeSharedContext = createContext<{
  sharedState: TScheduleChange;
  dispatch: any;
}>({
  sharedState: ScheduleChangeDefaultState,
  dispatch: () => null
});

export const ScheduleChangeStateReducer = (state: any, action: any) => {
  return { ...state, ...action };
};
