import { useContext, useEffect, useState } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import "./gantt.scss";
import { SubmittalStatusToStrMap } from "constants/index";
import { useQuery } from "@apollo/client";
import {
  QUERY_SCHEDULER_TASK_LINK_LIST,
  QUERY_GANTT_TASKS_LIST_FOR_PROCUREMENT,
  QUERY_CALENDAR_HOLIDAY_LIST
} from "services/graphQL/queries";
import { ProjectContext } from "context/ProjectProvider";
import { Spin, Select, Button, Divider, Input } from "antd";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import errorImgPath from "assets/svg/error.svg";
import linkImgPath from "assets/svg/link.svg";
import { DateUtils } from "utils/dateutils";
import { useParams } from "react-router";

function Gantt() {
  const { gqlClientForProject } = useContext(ProjectContext);
  const { projectId } = useParams() as any;

  const { data: tasksData } = useQuery(QUERY_GANTT_TASKS_LIST_FOR_PROCUREMENT, {
    client: gqlClientForProject,
    fetchPolicy: "no-cache",
    skip: !gqlClientForProject
  });

  const { data: taskLinksData, loading: linkLoading } = useQuery(
    QUERY_SCHEDULER_TASK_LINK_LIST,
    {
      client: gqlClientForProject,
      fetchPolicy: "no-cache",
      skip: !gqlClientForProject
    }
  );

  const { data: calHolidayData } = useQuery(QUERY_CALENDAR_HOLIDAY_LIST, {
    client: gqlClientForProject,
    fetchPolicy: "no-cache",
    variables: { projectId },
    skip: !gqlClientForProject
  });
  const [searchText, setSearchText] = useState("");

  let ganttContainer: any;
  const { Option } = Select;
  const [counter, setCounter] = useState(4);
  // const [filterCounter, setFilterCounter] = useState(0);
  let linkTasks: any = Object;
  let highlightedLinks: any = {};
  let allLinkedTask: any[] = [];

  const zoomConfig = {
    levels: [
      {
        name: "day",
        scale_height: 27,
        min_column_width: 80,
        scales: [{ unit: "day", step: 1, format: "%d %M" }]
      },
      {
        name: "week",
        scale_height: 50,
        min_column_width: 50,
        scales: [
          {
            unit: "week",
            step: 1,
            format(date: any) {
              const dateToStr = gantt.date.date_to_str("%d %M");
              const endDate = gantt.date.add(date, -6, "day");
              const weekNum = gantt.date.date_to_str("%W")(date);
              return `#${weekNum}, ${dateToStr(date)} - ${dateToStr(endDate)}`;
            }
          },
          { unit: "day", step: 1, format: "%j %D" }
        ]
      },
      {
        name: "month",
        scale_height: 50,
        min_column_width: 120,
        scales: [
          { unit: "month", format: "%F, %Y" },
          { unit: "week", format: "Week #%W" }
        ]
      },
      {
        name: "quarter",
        height: 50,
        min_column_width: 90,
        scales: [
          { unit: "month", step: 1, format: "%M" },
          {
            unit: "quarter",
            step: 1,
            format(date: any) {
              const dateToStr = gantt.date.date_to_str("%M");
              const endDate = gantt.date.add(
                gantt.date.add(date, 3, "month"),
                -1,
                "day"
              );
              return `${dateToStr(date)} - ${dateToStr(endDate)}`;
            }
          }
        ]
      },
      {
        name: "year",
        scale_height: 50,
        min_column_width: 30,
        scales: [{ unit: "year", step: 1, format: "%Y" }]
      }
    ]
  };

  const getScaleNumber = (scale: number) => {
    let selectedScale = "day";
    switch (scale) {
      case 1:
        selectedScale = "day";
        break;
      case 2:
        selectedScale = "week";
        break;
      case 3:
        selectedScale = "month";
        break;
      case 4:
        selectedScale = "quarter";
        break;
      case 5:
        selectedScale = "year";
        break;
      default:
        break;
    }
    return selectedScale;
  };

  // const onToggleChange = (e: RadioChangeEvent) => {
  //   setSearchText("");
  //   setFilterCounter(e.target.value);

  //   if (e.target.value === 1) {
  //     sessionStorage.setItem("ganttSearchStr", "FilterSubmitalOnToggle");
  //   } else if (e.target.value === 2) {
  //     sessionStorage.setItem("ganttSearchStr", "FilterMaterialOnToggle");
  //   } else {
  //     sessionStorage.setItem("ganttSearchStr", "clearFilter");
  //   }
  //   gantt.refreshData();
  // };

  const children = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  function sortActivityId(a: any, b: any) {
    if (a.source_task_id > b.source_task_id) {
      return -1;
    }
    if (a.source_task_id < b.source_task_id) {
      return 1;
    }

    return 0;
  }

  function sortByActivityId() {
    gantt.sort(sortActivityId);
  }

  function highlightLinkedTasks(currentTask: any) {
    if (allLinkedTask.find((tk: any) => tk?.id === currentTask?.id)) {
      const sourceTasks: any[] = currentTask?.$source || [];
      const targetTasks: any[] = currentTask?.$target || [];
      sourceTasks.forEach((l_id: string) => {
        const targetTaskId = gantt?.getLink(l_id).target;
        const sourceTaskId = gantt?.getLink(l_id).source;
        highlightedLinks[l_id] = true;

        if (
          targetTaskId &&
          gantt.isTaskExists(targetTaskId) &&
          !linkTasks[targetTaskId]
        ) {
          linkTasks[targetTaskId] = true;
          highlightLinkedTasks(gantt.getTask(targetTaskId));
        }
        if (
          sourceTaskId &&
          gantt.isTaskExists(sourceTaskId) &&
          !linkTasks[sourceTaskId]
        ) {
          linkTasks[sourceTaskId] = true;
          highlightLinkedTasks(gantt.getTask(sourceTaskId));
        }
      });

      targetTasks.forEach((l_id: string) => {
        const targetTaskId = gantt?.getLink(l_id).target;
        const sourceTaskId = gantt?.getLink(l_id).source;
        highlightedLinks[l_id] = true;

        if (
          targetTaskId &&
          gantt.isTaskExists(targetTaskId) &&
          !linkTasks[targetTaskId]
        ) {
          linkTasks[targetTaskId] = true;
          highlightLinkedTasks(gantt.getTask(targetTaskId));
        }
        if (
          sourceTaskId &&
          gantt.isTaskExists(sourceTaskId) &&
          !linkTasks[sourceTaskId]
        ) {
          linkTasks[sourceTaskId] = true;
          highlightLinkedTasks(gantt.getTask(sourceTaskId));
        }
      });
    }
  }

  // const getTaskProgress = (type: string, status: number) => {
  //   switch (type) {
  //     case "submittal":
  //       switch (status) {
  //         case ESubmittalStatus.CREATE:
  //           return 0.25;
  //         case ESubmittalStatus.SUBMIT:
  //           return 0.5;
  //         case ESubmittalStatus.REVIEW:
  //           return 0.75;
  //         case ESubmittalStatus.DONE:
  //           return 1;
  //         default:
  //           return 0;
  //       }
  //     case "material":
  //       switch (status) {
  //         case ESubmittalStatus.CREATE:
  //           return 0.25;
  //         case ESubmittalStatus.SUBMIT:
  //           return 0.5;
  //         case ESubmittalStatus.REVIEW:
  //           return 0.75;
  //         case ESubmittalStatus.DONE:
  //           return 1;
  //         default:
  //           return 0;
  //       }
  //     default:
  //       return "task";
  //   }
  // };

  // let taskLinks: any[] = [];
  function setupGanttTask() {
    const taskArr = [];
    // const rootActivities: any = [];
    const submittalActivities: any = [];
    const materialActivities: any = [];
    const taskLinks: any[] = [];
    // Setup Activites from gantt_tasks table
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < tasksData?.gantt_tasks.length; index++) {
      const task = { ...tasksData?.gantt_tasks[index] };
      const startDate = task?.start_date
        ? DateUtils.format(task?.start_date)
        : "";
      const endDate = task?.end_date ? DateUtils.format(task?.end_date) : "";
      task.start_date = startDate;
      task.end_date = endDate;
      // Skip tasks of type 3 and 4 (MDB SDB tasks)
      if (task.type?.toString() === "3" || task.type?.toString() === "4") {
        // eslint-disable-next-line no-continue
        continue;
      }
      // Push All root level tasks
      // if (!task.parent) {
      //   taskArr.push(task);
      //   rootActivities.push(task);
      // }
      // Check for tasks linked with Submittals and Materials
      if (
        task.material_schedule_links.length > 0 ||
        task.submittal_schedule_links.length > 0
      ) {
        // Push the tasks that do have links
        taskArr.push(task);
        // Fetch its ancestors and push them too if not already done
        // let rootNodeFound = false;
        // let currentParent = task.parent;
        // let curTask = task;
        // while (!rootNodeFound) {
        //   if (rootActivities.find((item: any) => item.id === currentParent))
        //     rootNodeFound = true;
        //   else {
        //     curTask = taskList.find((item: any) => item.id === currentParent);
        //     currentParent = curTask.parent;
        //     if (!curTask.parent) {
        //       if (
        //         !rootActivities.find((item: any) => item.id === currentParent)
        //       ) {
        //         rootActivities.push(curTask);
        //         taskArr.push(curTask);
        //       }
        //       rootNodeFound = true;
        //     } else {
        //       taskArr.push(curTask);
        //     }
        //   }
        // }
        // Add Materials and Links from Materials To Activities
        if (task.material_schedule_links.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let mat = 0; mat < task.material_schedule_links.length; mat++) {
            const materialDetails: any =
              task.material_schedule_links[mat]?.material;
            let delayed = false;
            if (
              !materialActivities.find(
                (item: any) => item.id === task.material_schedule_links[mat].id
              )
            ) {
              let materialName: string;
              if (materialDetails.implicit) {
                materialName = `Material for ${
                  materialDetails?.submittal_material_links[0]?.submittal
                    ?.title || ""
                }`;
              } else {
                materialName = `Material: ${materialDetails?.name || ""}`;
              }

              const dateBlockMaterial =
                materialDetails?.date_block_materials &&
                materialDetails?.date_block_materials.length > 0
                  ? materialDetails?.date_block_materials[0]
                  : "";
              let materialStartDate = dateBlockMaterial?.planned_milestone_1;
              materialStartDate = DateUtils.format(materialStartDate);
              let materialEndDate = dateBlockMaterial?.planned_milestone_4;
              const todayDate = new Date();
              const matEndDate = new Date(materialEndDate);
              if (materialEndDate && todayDate > matEndDate) delayed = true;
              materialEndDate = DateUtils.format(materialEndDate);
              const materialTask: any = {
                id: materialDetails.id,
                text: materialName,
                start_date: materialStartDate,
                end_date: materialEndDate,
                type: "3",
                // parent: "material_parent_id",
                material: materialDetails,
                delayed
              };
              if (
                materialStartDate !== "Invalid date" &&
                materialEndDate !== "Invalid date"
              ) {
                taskArr.push(materialTask);
                materialActivities.push(materialTask);
              }
              // Add Submittal Material Links (always from sub to material)
              for (
                let subMat = 0;
                subMat < materialDetails.submittal_material_links.length;
                subMat += 1
              ) {
                const curMaterialLink =
                  materialDetails.submittal_material_links[subMat];
                // Add Submittal to taskArr first
                const submittalDetails: any = curMaterialLink.submittal;
                let subDelayed = false;
                if (
                  !submittalActivities.find(
                    (item: any) => item.id === submittalDetails.id
                  )
                ) {
                  const dateBlockSubmittal =
                    submittalDetails?.date_block_submittals &&
                    submittalDetails?.date_block_submittals.length > 0
                      ? submittalDetails?.date_block_submittals[0]
                      : "";
                  let submittalStartDate =
                    dateBlockSubmittal?.planned_milestone_1;
                  submittalStartDate = DateUtils.format(submittalStartDate);

                  let submittalEndDate =
                    dateBlockSubmittal?.planned_milestone_5;
                  const subtodayDate = new Date();
                  const subEndDate = new Date(submittalEndDate);
                  if (subtodayDate > subEndDate) subDelayed = true;
                  submittalEndDate = DateUtils.format(submittalEndDate);
                  const submittalTask: any = {
                    id: submittalDetails.id,
                    text: `Submittal: ${submittalDetails?.title || ""}`,
                    start_date: submittalStartDate,
                    end_date: submittalEndDate,
                    type: 4,
                    // parent: "submittal_parent_id",
                    submittal: submittalDetails,
                    delayed: subDelayed
                  };
                  if (
                    submittalStartDate !== "Invalid date" &&
                    submittalEndDate !== "Invalid date"
                  ) {
                    taskArr.push(submittalTask);
                    submittalActivities.push(submittalTask);
                  }
                }
                taskLinks.push({
                  id: `${curMaterialLink.submittal_id}${materialDetails.id}`,
                  source: curMaterialLink.submittal_id,
                  target: materialDetails.id,
                  type: 0
                });
              }
            }
            taskLinks.push({
              id: `${materialDetails.id}${task.id}`,
              source: materialDetails.id,
              target: task.id,
              type: 0
            });
          }
        }
        if (task.submittal_schedule_links.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let sub = 0; sub < task.submittal_schedule_links.length; sub++) {
            const submittalDetails: any =
              task.submittal_schedule_links[sub]?.submittal;
            let delayed = false;
            if (
              !submittalActivities.find(
                (item: any) => item.id === task.submittal_schedule_links[sub].id
              )
            ) {
              const dateBlockSubmittal =
                submittalDetails?.date_block_submittals &&
                submittalDetails?.date_block_submittals.length > 0
                  ? submittalDetails?.date_block_submittals[0]
                  : "";

              let submittalStartDate = dateBlockSubmittal?.planned_milestone_1;
              submittalStartDate = DateUtils.format(submittalStartDate);

              let submittalEndDate = dateBlockSubmittal?.planned_milestone_5;
              const todayDate = new Date();
              const subEndDate = new Date(submittalEndDate);
              if (submittalEndDate && todayDate > subEndDate) delayed = true;
              submittalEndDate = DateUtils.format(submittalEndDate);
              const submittalTask: any = {
                id: submittalDetails.id,
                text: `Submittal: ${submittalDetails.title}`,
                start_date: submittalStartDate,
                end_date: submittalEndDate,
                type: "4",
                // parent: "submittal_parent_id",
                submittal: submittalDetails,
                delayed
              };

              if (
                submittalStartDate !== "Invalid date" &&
                submittalEndDate !== "Invalid date"
              ) {
                taskArr.push(submittalTask);
                submittalActivities.push(submittalTask);
              }
              // Add Submittal Material Links (always from sub to material)
              for (
                let subMat = 0;
                subMat < submittalDetails.submittal_material_links.length;
                subMat += 1
              ) {
                const curMaterialLink =
                  submittalDetails.submittal_material_links[subMat];
                taskLinks.push({
                  id: `${submittalDetails.id}${curMaterialLink.material_id}`,
                  source: submittalDetails.id,
                  target: curMaterialLink.material_id,
                  type: 0
                });
              }
            }
            // Add Link from Submittal to Task
            // // eslint-disable-next-line no-debugger
            // debugger;

            taskLinks.push({
              id: `${submittalDetails.id}${task.id}`,
              source: submittalDetails.id,
              target: task.id,
              type: 0
            });
          }
        }
      }
    }

    // Setup Submittal Tasks for every linked submittal
    // TODO: Add a date for these two parent tasks
    // taskArr.push({
    //   id: "submittal_parent_id",
    //   text: "Submittals ",
    //   type: 4
    // });

    // taskArr.push({
    //   id: "material_parent_id",
    //   text: "Materials",
    //   type: 3
    // });

    // Sort Task Order : Sub => Mat => Activities
    // Separate Tasks
    const subTasks: any[] = taskArr.filter(
      (sTask: any) => sTask.type?.toString() === "4"
    );
    const matTasks: any[] = taskArr.filter(
      (mTask: any) => mTask.type?.toString() === "3"
    );
    const otherTasks: any[] = taskArr.filter(
      (iTask) =>
        iTask.type?.toString() !== "3" && iTask.type?.toString() !== "4"
    );
    // Sort each type
    subTasks.sort(
      (a, b) =>
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
    );
    matTasks.sort(
      (a, b) =>
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
    );
    otherTasks.sort(
      (a, b) =>
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
    );
    // Concatenate the sorted lists
    let taskArrSorted: any[] = [];
    taskArrSorted = [...subTasks, ...matTasks, ...otherTasks];
    gantt.plugins({
      tooltip: true
    });
    // eslint-disable-next-line func-names
    gantt.templates.rightside_text = function (start, end, task) {
      // const percent = `<span>${Math.round((task?.progress || 0) * 100)}%<span>`;
      let delayImg = "";
      if (task.delayed) {
        delayImg = `<img height=${
          gantt.config.row_height / 2
        } src='${errorImgPath}'> `;
      }
      return delayImg;
    };

    gantt.templates.leftside_text = (start, end, task) => {
      if (task.type?.toString() === "4") {
        return `<span onclick= "event.stopPropagation();">
                  <a target="_blank" href=${`/project/${projectId}/submittals/${task?.submittal?.id}`}>
                      <img height=${
                        gantt.config.row_height / 2
                      } style="margin-right: -10px;z-index: 2" src='${linkImgPath}'>
                  </a> 
                </span>`;
      }
      if (task.type?.toString() === "3") {
        return `<span onclick= "event.stopPropagation();">
                  <a target="_blank" href=${`/project/${projectId}/materials/${task?.material?.id}`}>
                      <img height=${
                        gantt.config.row_height / 2
                      } style="margin-right: -10px;z-index: 2" src='${linkImgPath}'>
                  </a> 
                </span>`;
      }
      return "";
    };
    gantt.config.date_format = "%m-%d-%Y";
    gantt.config.date_grid = "%m-%d-%Y";
    gantt.config.readonly = true;
    gantt.config.sort = true;
    gantt.setWorkTime({ hours: ["8:00-12:00", "13:00-17:00"] });
    gantt.config.work_time = true;
    gantt.config.duration_step = 1;
    gantt.config.duration_unit = "hour";
    gantt.config.open_tree_initially = true;
    gantt.config.show_errors = false;
    gantt.templates.grid_file = () => {
      return "";
    };
    gantt.templates.parse_date = (date) => {
      return new Date(DateUtils.dateTimeObj(date) as any);
    };
    gantt.config.columns = [
      {
        name: "source_task_id",
        label: "Activity ID",
        width: "*",
        min_width: 100,
        max_width: 260,
        align: "center",
        template(task) {
          if (task?.type?.toString() === "3") {
            return task?.material?.material_id || "";
          }

          if (task?.type?.toString() === "4") {
            return task?.submittal?.spec_no
              ? `${task?.submittal?.spec_no || ""} - ${
                  task?.submittal?.submittal_id || ""
                }`
              : task?.submittal?.submittal_id || "";
          }
          return task?.source_task_id || "";
        }
      },
      {
        name: "text",
        label: "Activity Name",
        tree: true,
        width: "*",
        min_width: 250,
        max_width: 400
      },
      {
        name: "start_date",
        label: "Start Time",
        align: "center",
        width: "*",
        min_width: 150,
        max_width: 250,
        template(task) {
          if (task?.type?.toString() === "3") {
            const dateBlockMaterial =
              task?.material?.date_block_materials &&
              task?.material?.date_block_materials?.length > 0
                ? task?.material?.date_block_materials[0]
                : "";
            return dateBlockMaterial?.planned_milestone_1
              ? task?.start_date
              : "";
          }

          if (task?.type?.toString() === "4") {
            const dateBlockSubmittal =
              task?.submittal?.date_block_submittals &&
              task?.submittal?.date_block_submittals.length > 0
                ? task?.submittal?.date_block_submittals[0]
                : "";
            return dateBlockSubmittal?.planned_milestone_1
              ? task?.start_date
              : "";
          }
          return task?.start_date ? task?.start_date : "";
        }
      },
      {
        name: "duration",
        label: "Duration",
        align: "center",
        template(task) {
          const duration = Math.floor((task?.duration || 0) / 8);
          if (
            (task?.type?.toString() === "4" ||
              task?.type?.toString() === "3") &&
            duration === 0
          ) {
            return `${duration} day(s)`;
          }

          if (duration === 0) {
            return `${Math.ceil((task?.duration || 0) / 8)} day(s)`;
          }
          return `${Math.floor((task?.duration || 0) / 8)} day(s)`;
        }
      }
    ];
    gantt.config.layout = {
      css: "gantt_container",
      cols: [
        {
          width: 600,
          minWidth: 200,
          maxWidth: 600,

          // adding horizontal scrollbar to the grid via the scrollX attribute
          rows: [
            {
              view: "grid",
              scrollX: "gridScroll",
              scrollable: true,
              scrollY: "scrollVer"
            },
            { view: "scrollbar", id: "gridScroll" }
          ]
        },
        { resizer: true, width: 1 },
        {
          rows: [
            { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
            { view: "scrollbar", id: "scrollHor" }
          ]
        },
        { view: "scrollbar", id: "scrollVer" }
      ]
    };
    gantt.ext.zoom.init(zoomConfig);
    gantt.ext.zoom.setLevel("quarter");

    // eslint-disable-next-line func-names
    gantt.templates.timeline_cell_class = function (task, date) {
      if (
        (gantt.ext.zoom.getCurrentLevel() === 0 ||
          gantt.ext.zoom.getCurrentLevel() === 1) &&
        (date.getDay() === 0 || date.getDay() === 6)
      ) {
        return "weekend";
      }
      return "";
    };

    gantt.templates.link_class = (link) => {
      if (Object.keys(highlightedLinks).length > 0) {
        if (highlightedLinks[link.id]) {
          return "selected-link";
        }
        return "unselected-link";
      }
      return "selected-link";
    };
    gantt.templates.task_class = (start, end, task) => {
      if (gantt.isTaskExists(task.id) && linkTasks[task.id]) {
        return "gantt_task_background";
      }

      if (Object.keys(linkTasks).length) {
        if (task.type?.toString() === "4") {
          return task?.delayed
            ? "partial-visible-task delayed_submittal_task"
            : "partial-visible-task submittal_task";
        }
        if (task.type?.toString() === "3") {
          return task?.delayed
            ? "partial-visible-task delayed_material_task"
            : "partial-visible-task material_task";
        }
        return "partial-visible-task";
      }

      if (task.type?.toString() === "4") {
        return task?.delayed ? "delayed_submittal_task" : "submittal_task";
      }
      if (task.type?.toString() === "3") {
        return task?.delayed ? "delayed_material_task" : "material_task";
      }
      return "task";
    };

    // eslint-disable-next-line func-names
    gantt.templates.tooltip_text = function (start, end, task) {
      let nameStr = task.text || "";
      let status = "";
      let assignee = "";

      // Material
      if (task?.type?.toString() === "3") {
        nameStr = task.text || "";
        status = SubmittalStatusToStrMap[task?.material?.status] || "";
        assignee = `${task?.material?.material_assignee?.first_name || ""} ${
          task?.material?.material_assignee?.last_name || ""
        }`;
        if (task.delayed) {
          status = "Delayed";
        } else {
          status = "On-Time";
        }

        const dateBlockMaterial =
          task?.material?.date_block_materials &&
          task?.material?.date_block_materials?.length > 0
            ? task?.material?.date_block_materials[0]
            : "";

        if (!dateBlockMaterial?.planned_milestone_4) {
          status = "";
        }
        return `<b>Material:</b> ${nameStr}<br/>
                <b>Start Date:</b> ${
                  dateBlockMaterial?.planned_milestone_1
                    ? DateUtils.format(start)
                    : ""
                }<br>
                <b>End Date:</b> ${
                  dateBlockMaterial?.planned_milestone_4
                    ? DateUtils.format(end)
                    : ""
                }<br>
                <b>Status:</b> ${status}<br/>
                <b>Assignee:</b> ${assignee}<br/>`;
      }

      // Submittal
      if (task?.type?.toString() === "4") {
        nameStr = task.text || "";
        status =
          SubmittalStatusToStrMap[task?.submittal?.workflow_status] || "";

        if (task?.submittal?.submittal_assignee?.email)
          assignee = `${task?.submittal?.submittal_assignee?.first_name}${task?.submittal?.submittal_assignee?.last_name}`;
        else assignee = "";

        let assignTo = "";
        if (task?.submittal?.assignee === task?.submittal?.gc_reviewer) {
          assignTo = "GC Reviwer";
          assignee = `${task?.submittal?.gc_reviewer_user?.first_name || ""} ${
            task?.submittal?.gc_reviewer_user?.last_name || ""
          }`;
        } else if (
          task?.submittal?.assignee === task?.submittal?.responsible_contractor
        ) {
          assignTo = "SC Reviwer";
          assignee = task?.submittal?.responsible_contractor_vendor?.name || "";
        } else if (
          task?.submittal?.assignee === task?.submittal?.design_reviewer
        ) {
          assignTo = "DR Reviwer";
          assignee = `${
            task?.submittal?.design_reviewer_user?.first_name || ""
          } ${task?.submittal?.design_reviewer_user?.last_name || ""}`;
        }
        if (task.delayed) {
          status = "Delayed";
        } else {
          status = "On-Time";
        }

        const dateBlockSubmittal =
          task.submittal?.date_block_submittals &&
          task.submittal?.date_block_submittals.length > 0
            ? task.submittal?.date_block_submittals[0]
            : "";

        if (!dateBlockSubmittal?.planned_milestone_5) {
          status = "";
        }
        return `<b>Submittal:</b> ${nameStr}<br/>
                <b>Start Date:</b> ${
                  dateBlockSubmittal?.planned_milestone_1
                    ? DateUtils.format(start)
                    : ""
                }<br>
                <b>End Date:</b> ${
                  dateBlockSubmittal?.planned_milestone_5
                    ? DateUtils.format(end)
                    : ""
                }<br>
                <b>Status:</b> ${status}<br/>
                <b>Assignee:</b> ${assignee}<br/> 
                Currently assigned to <b>${assignTo} </b>`;
      }

      return `<b>Activity:</b> ${nameStr}<br/>
              <b>Start Date:</b> ${DateUtils.format(start)}<br>
              <b>End Date:</b> ${DateUtils.format(end)}`;
    };

    allLinkedTask = [
      ...new Map(taskArr.map((item) => [item.id, item])).values()
    ];

    gantt.clearAll();
    gantt.init(ganttContainer);
    gantt.parse({
      data: taskArrSorted,
      links: taskLinks
    });
    sortByActivityId();
    // eslint-disable-next-line func-names
    gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
      const txt = sessionStorage.getItem("ganttSearchStr");
      if (!txt || txt === "clearFilter") return true;

      if (txt === "FilterSubmitalOnToggle") {
        return task.type === 4;
      }

      if (txt === "FilterMaterialOnToggle") {
        return task.type === 3;
      }

      const normalizedText = `${task?.source_task_id || ""}${task?.text || ""}${
        task?.submittal?.spec_no || ""
      } - ${task?.submittal?.submittal_id || ""}${
        task?.material?.material_id || ""
      }`.toLowerCase();
      const normalizedValue = txt?.toLowerCase().trim();
      return normalizedText.indexOf(normalizedValue) > -1;
    });

    // eslint-disable-next-line func-names
    gantt.attachEvent("onTaskClick", function (id) {
      linkTasks = {};
      highlightedLinks = {};
      linkTasks[id] = true;
      highlightLinkedTasks(gantt.getTask(id));
      gantt.refreshData();
      Object.keys(linkTasks).forEach((i) => {
        gantt.refreshTask(i);
      });
      return true;
    });

    // eslint-disable-next-line func-names
    gantt.attachEvent("onEmptyClick", function () {
      linkTasks = {};
      highlightedLinks = {};
      gantt.refreshData();
      return true;
    });

    // eslint-disable-next-line func-names
    gantt.attachEvent("onTaskRowClick", function () {
      linkTasks = {};
      highlightedLinks = {};
      gantt.refreshData();
      return true;
    });
  }

  useEffect(() => {
    if (calHolidayData && calHolidayData?.gantt_calendar?.length > 0) {
      const formatDate = gantt.date.str_to_date("%Y-%m-%d");
      const calendar = calHolidayData?.gantt_calendar[0];
      for (
        let i = 0;
        i < calendar?.calendar_holiday_lists?.length;
        // eslint-disable-next-line no-plusplus
        i++
      ) {
        const convertedDate = formatDate(
          calendar?.calendar_holiday_lists[i]?.date
        );
        gantt.setWorkTime({ date: convertedDate, hours: false });
      }

      gantt.refreshData();
    }
  }, [calHolidayData]);

  useEffect(() => {
    gantt.render();
  }, [searchText]);

  useEffect(() => {
    if (tasksData?.gantt_tasks?.length > 0 && taskLinksData?.gantt_links) {
      setupGanttTask();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksData, taskLinksData]);

  useEffect(() => {
    if (tasksData?.gantt_tasks?.length) {
      gantt.ext.zoom.setLevel(getScaleNumber(counter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const onFilterTextBoxChanged = (value: string) => {
    // eslint-disable-next-line func-names
    gantt.eachTask(function (task: any) {
      // eslint-disable-next-line no-param-reassign
      task.$open = true;
    });
    // setFilterCounter(0);
    sessionStorage.setItem("ganttSearchStr", value);
    setSearchText(value);
  };

  useEffect(() => {
    return () => {
      gantt.ext.tooltips.tooltip.hide();
      sessionStorage.removeItem("ganttSearchStr");
    };
  }, []);

  const retriveGanttData = () => {
    return tasksData?.gantt_tasks && !linkLoading ? (
      <div className="flex h-[calc(100vh-195px)] ">
        {tasksData?.gantt_tasks?.length > 0 ? (
          <div className="flex-col h-full w-full space-y-2">
            <div className="flex gap-x-2">
              <Input
                type="search"
                value={searchText}
                className="w-[240px]"
                placeholder="Search here."
                onChange={(e) => onFilterTextBoxChanged(e.target.value)}
              />
              <Button
                onClick={() => {
                  setSearchText("");
                  sessionStorage.setItem("ganttSearchStr", "clearFilter");
                  gantt.refreshData();
                }}
              >
                Clear
              </Button>

              {/* <Radio.Group
            onChange={onToggleChange}
            value={filterCounter}
            className="flex ml-10 items-center"
          >
            <Radio value={1}>Submittal</Radio>
            <Radio value={2}>Material</Radio>
            <Radio value={3}>Clear Filter</Radio>
          </Radio.Group> */}
            </div>
            <Divider />
            <div className="flex justify-between items-center mb-2">
              <div className="flex space-x-2">
                <div className="flex space-x-2">
                  <div className="flex items-center space-x-1">
                    <div className=" w-3 h-3 rounded-full bg-[#19D486]" />
                    <div>Submittals</div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className=" w-3 h-3 rounded-full bg-[#203A72]" />
                    <div>Materials</div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className=" w-3 h-3 rounded-full bg-[#3B82F6]" />{" "}
                    <div>Activities</div>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    if (counter > 1) {
                      setCounter(counter - 1);
                    }
                  }}
                >
                  <ZoomInOutlined style={{ fontSize: "16px" }} />
                </Button>
                <Button
                  onClick={() => {
                    if (counter < 5) {
                      setCounter(counter + 1);
                    }
                  }}
                >
                  <ZoomOutOutlined style={{ fontSize: "16px" }} />
                </Button>
              </div>
            </div>
            <div
              ref={(input) => {
                ganttContainer = input;
              }}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ) : (
          <div className="flex w-full h-full items-center justify-center text-gray-600">
            No procurement schedule available.
          </div>
        )}
      </div>
    ) : (
      <div className="flex h-[calc(100vh-180px)] w-screen justify-center items-center">
        <Spin />
      </div>
    );
  };
  return retriveGanttData();
}

export default Gantt;
