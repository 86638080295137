import {
  Form,
  Input,
  Button,
  message,
  Modal,
  Divider,
  Select,
  Tooltip
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useContext, useMemo, useRef, useState } from "react";
import { MUTATION_INSERT_SUBMITTAL } from "services/graphQL/mutations";
import { voidStartingSpaceInput } from "utils/inpututils";
import "./submittal-log-create.css";
import SpecNumberNameDropDown from "components/spec-number-name";
import { useQuery } from "@apollo/client";
import { ProjectContext } from "context/ProjectProvider";
import CreateSpecSectionModal from "components/submittal-details/create-spec-section";
import { QUERY_SUBMITTAL_TYPES } from "services/graphQL/queries";
import { useProjectParticipants } from "hooks/project-participants";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import { useParams } from "react-router";
import { priorities } from "components/submittal-details/submittal-details";
import { AcceptanceStatus } from "constants/index";

type TCreateSubmittal = {
  title: any;
  spec_section_id: any;
  spec_name: any;
  spec_no: any;
  description: undefined | string;
  type: undefined | number;
  raw_type: undefined | any;
  priority: string | undefined;
  responsible_contractor: undefined | string;
  submitter: undefined | string | null;
  submitter_unregistered: string;
  gc_reviewer: undefined | string | null;
  design_reviewer: undefined | string | null;
  design_reviewer_unregistered: undefined | string | null;
  design_reviewer_vendor_unregistered: undefined | string | null;
};

export type SubmittalLog = {
  onSubmittalCreated: any;
  onCancelClick: any;
  gqlClient: any;
  showCreateSubModel: boolean;
  setDrawerOpen: Function;
};

function SubmittalLogCreateComponent(props: SubmittalLog) {
  const {
    onSubmittalCreated,
    onCancelClick,
    gqlClient,
    showCreateSubModel,
    setDrawerOpen
  } = props;
  const createSpecRef = useRef<any>(null);
  const [callInProgress, setCallInProgress] = useState<boolean>(false);
  const { projectId } = useParams() as any;

  const [submittalData, setSubmittalData] = useState<TCreateSubmittal>({
    title: undefined,
    spec_section_id: undefined,
    spec_name: undefined,
    spec_no: undefined,
    description: undefined,
    type: undefined,
    raw_type: undefined,
    priority: undefined,
    responsible_contractor: undefined,
    submitter: undefined,
    submitter_unregistered: "",
    gc_reviewer: undefined,
    design_reviewer: undefined,
    design_reviewer_unregistered: undefined,
    design_reviewer_vendor_unregistered: undefined
  });

  const {
    gqlClientForProject,
    columnHeaders: { submittalHeaderMap }
  } = useContext(ProjectContext);

  const { data: dataSubmittalTypes } = useQuery(QUERY_SUBMITTAL_TYPES, {
    client: gqlClientForProject
  });

  const { projectParticipants } = useProjectParticipants(false);

  const responsibleContractorOptions = useMemo(() => {
    return projectParticipants.responsibleContractors.map((company: any) => {
      return {
        id: company.vendor_id,
        name: company.subscription_vendor.name,
        companyInviteStatus: company.project_vendors_company_status.status,
        ...company
      };
    });
  }, [projectParticipants.responsibleContractors]);

  const submitterUserOptions = useMemo(() => {
    const submitterUsers = projectParticipants.submitterUsers.filter(
      (user: any) => {
        return user.company.vendor_id === submittalData.responsible_contractor;
      }
    );
    return submitterUsers;
  }, [
    projectParticipants.submitterUsers,
    submittalData.responsible_contractor
  ]);

  const [showCreateSpecSectionModal, setShowCreateSpecSectionModal] =
    useState(false);

  const onCreateButtonClick = async () => {
    setCallInProgress(true);

    const insertSubmittalResponse = await gqlClient.mutate({
      mutation: MUTATION_INSERT_SUBMITTAL,
      variables: {
        submittal: submittalData
      }
    });

    setCallInProgress(false);
    if (insertSubmittalResponse.data) {
      onSubmittalCreated(
        insertSubmittalResponse.data?.insert_submittals_one?.id,
        submittalData.title
      );
    } else {
      message.error("Failed to create new Submittal.");
    }
  };

  return (
    <Modal
      title="Create Submittal"
      className="submittal-create custom-drawer"
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      width={420}
      bodyStyle={{ minHeight: "calc(100vh - 92px)" }}
      footer={null}
      open={showCreateSubModel}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <Form
        layout="vertical"
        preserve
        className="space-y-3 px-3"
        onFinish={onCreateButtonClick}
        onReset={onCancelClick}
        validateTrigger="onBlur"
        disabled={showCreateSpecSectionModal}
      >
        <Form.Item
          name="title"
          label={submittalHeaderMap?.title?.toUpperCase()}
          required
          rules={[{ required: true, message: "Enter submittal title." }]}
        >
          <Input
            className="discriptionArea"
            placeholder="Enter Submittal title"
            maxLength={1000}
            onInput={voidStartingSpaceInput}
            value={submittalData.title}
            onChange={(e) =>
              setSubmittalData((pre) => ({ ...pre, title: e.target.value }))
            }
          />
        </Form.Item>
        <Form.Item label={submittalHeaderMap?.spec_no?.toUpperCase()}>
          <SpecNumberNameDropDown
            allowClear
            allowCreate
            currentSpecSection={{
              specSectionId: submittalData.spec_section_id,
              enable: false
            }}
            onChange={(
              id: string | null,
              number: string | null,
              name: string | null
            ) => {
              setSubmittalData((pre) => ({
                ...pre,
                spec_section_id: id,
                spec_no: number,
                spec_name: name
              }));
            }}
            onCreateSpecSectionClick={(showSpecSectionView) => {
              setShowCreateSpecSectionModal(showSpecSectionView);
              setTimeout(() => {
                if (createSpecRef.current)
                  createSpecRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                  });
              }, 100);
            }}
          />
        </Form.Item>
        <Form.Item label={submittalHeaderMap?.description?.toUpperCase()}>
          <TextArea
            disabled={showCreateSpecSectionModal}
            className="discriptionArea"
            rows={3}
            placeholder="Fill the Description"
            maxLength={150}
            value={submittalData.description}
            onChange={(e) =>
              setSubmittalData((pre) => ({
                ...pre,
                description: e.target.value
              }))
            }
          />
        </Form.Item>
        <Form.Item label={submittalHeaderMap?.type?.toUpperCase()}>
          <Select
            allowClear
            value={submittalData.type}
            onChange={(typeId) => {
              const typeObj = dataSubmittalTypes?.configurations_value?.find(
                (t: any) => t.id === typeId || t.value === typeId
              );
              setSubmittalData((pre) => ({
                ...pre,
                type: typeObj?.id,
                raw_type: typeObj?.value
              }));
            }}
            options={
              dataSubmittalTypes
                ? dataSubmittalTypes.configurations_value.map((type: any) => ({
                    value: type.id,
                    label: type.value
                  }))
                : []
            }
          />
        </Form.Item>
        <Form.Item
          label={submittalHeaderMap?.priority?.toUpperCase() || "PRIORITY"}
        >
          <Select
            placeholder={`Select a ${
              submittalHeaderMap?.priority || "Priority"
            }`}
            value={submittalData.priority}
            onChange={(value) => {
              setSubmittalData((pre) => ({ ...pre, priority: value }));
            }}
            options={priorities}
          />
        </Form.Item>
        <Form.Item
          label={submittalHeaderMap?.responsible_contractor?.toUpperCase()}
        >
          <Select
            showSearch
            allowClear
            filterOption
            optionFilterProp="label"
            onChange={(value) => {
              setSubmittalData((pre) => ({
                ...pre,
                responsible_contractor: value,
                submitter: undefined,
                submitter_unregistered: ""
              }));
            }}
            notFoundContent={
              <SelectSearchNotFoundContent
                notFoundMsg={
                  responsibleContractorOptions?.length > 0
                    ? "Company not found. To add, go to "
                    : "No companies added to this project. To add, go to "
                }
                linkTitle="Project Companies"
                linkPath={`/project/${projectId}/settings/general/project-companies`}
              />
            }
            options={
              responsibleContractorOptions
                ? responsibleContractorOptions.map((company: any) => ({
                    label: company.name,
                    value: company.id
                  }))
                : []
            }
          />
        </Form.Item>
        <Form.Item label={submittalHeaderMap?.submitter?.toUpperCase()}>
          <Tooltip
            title={
              submittalData.responsible_contractor
                ? ""
                : "First select a Responsible Contractor"
            }
          >
            <Select
              notFoundContent={
                <SelectSearchNotFoundContent
                  notFoundMsg={
                    submitterUserOptions?.length > 0
                      ? "User is not part of above selected company. To add, go to "
                      : "There are no users added to this company. To add, go to "
                  }
                  linkTitle="Project Settings"
                  linkPath={`/project/${projectId}/settings/general/project-users`}
                />
              }
              showSearch
              optionFilterProp="filterProp"
              filterOption
              disabled={!submittalData.responsible_contractor}
              onChange={(value: string) => {
                const selectedUser = submitterUserOptions.find((user: any) => {
                  return user.id === value;
                });
                if (selectedUser.type === "actual") {
                  setSubmittalData((pre) => ({
                    ...pre,
                    submitter: value,
                    submitter_unregistered: ""
                  }));
                } else {
                  setSubmittalData((pre) => ({
                    ...pre,
                    submitter_unregistered: value,
                    submitter: null
                  }));
                }
              }}
              options={
                submitterUserOptions
                  ? submitterUserOptions
                      .filter(
                        (usr: any) =>
                          usr.status_id !== AcceptanceStatus.DEACTIVATED
                      )
                      .map((user: any) => ({
                        value: user.id,
                        filterProp: `${user.first_name} ${user.last_name} ${user.company.name}`,
                        label: (
                          <div>
                            <div>
                              {user.first_name} {user.last_name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {user.company.name}
                            </div>
                          </div>
                        )
                      }))
                  : []
              }
            />
          </Tooltip>
        </Form.Item>
        <Form.Item label={submittalHeaderMap?.gc_reviewer?.toUpperCase()}>
          <Select
            value={submittalData.gc_reviewer}
            onChange={(value: string) => {
              setSubmittalData((pre) => ({
                ...pre,
                gc_reviewer: value
              }));
            }}
            showSearch
            filterOption
            optionFilterProp="filterProp"
            options={projectParticipants.gcReviewers
              .filter(
                (usr: any) => usr.status_id !== AcceptanceStatus.DEACTIVATED
              )
              .map((user: any) => ({
                value: user.id,
                filterProp: `${user.first_name} ${user.last_name} ${user?.company?.name}`,
                label: (
                  <div>
                    <div>
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {user?.company?.name}
                    </div>
                  </div>
                )
              }))}
          />
        </Form.Item>
        <Form.Item label={submittalHeaderMap?.design_reviewer?.toUpperCase()}>
          <Select
            value={submittalData.design_reviewer}
            onChange={(value: string) => {
              const foundUser: any = projectParticipants.designReviewers.find(
                (user: any) => {
                  return user.id === value;
                }
              );
              if (foundUser.type === "actual") {
                setSubmittalData((pre) => ({
                  ...pre,
                  design_reviewer: value,
                  design_reviewer_unregistered: "",
                  design_reviewer_vendor_unregistered: ""
                }));
              } else {
                setSubmittalData((pre) => ({
                  ...pre,
                  design_reviewer_unregistered: value,
                  design_reviewer_vendor_unregistered: foundUser.company.name,
                  design_reviewer: null
                }));
              }
            }}
            showSearch
            filterOption
            optionFilterProp="filterProp"
            notFoundContent={
              <SelectSearchNotFoundContent
                notFoundMsg={
                  projectParticipants.designReviewers.length > 0
                    ? "Architect/Engineer is not added to project. To add, go to "
                    : "There are no Architects/Engineers added to this project. To add, go to "
                }
                linkTitle="Project Settings"
                linkPath={`/project/${projectId}/settings/general/project-users`}
              />
            }
            options={projectParticipants.designReviewers
              .filter(
                (usr: any) => usr.status_id !== AcceptanceStatus.DEACTIVATED
              )
              .map((user: any) => ({
                value: user.id,
                filterProp: `${user.first_name} ${user.last_name} ${user.company?.name}`,
                label: (
                  <div>
                    <div>
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {user.company?.name}
                    </div>
                  </div>
                )
              }))}
          />
        </Form.Item>
        <section className="mt-4 flex items-center justify-end space-x-2">
          <Button htmlType="reset">Cancel</Button>

          <Button type="primary" htmlType="submit" loading={callInProgress}>
            Create Submittal
          </Button>
        </section>
      </Form>
      {showCreateSpecSectionModal && (
        <div className="" ref={createSpecRef}>
          <Divider style={{ margin: "15px 0" }} />
          <div className="">
            <CreateSpecSectionModal
              isModalOpen={showCreateSpecSectionModal}
              onDoneCb={(newSpecSectionId: string, name: any, number: any) => {
                if (newSpecSectionId) {
                  setSubmittalData((pre) => ({
                    ...pre,
                    spec_section_id: newSpecSectionId,
                    spec_no: number,
                    spec_name: name
                  }));
                  setShowCreateSpecSectionModal(false);
                } else {
                  setShowCreateSpecSectionModal(false);
                }
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default SubmittalLogCreateComponent;
