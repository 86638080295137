import React from "react";

function GridLoadingIndicator() {
  return (
    <div className="ag-overlay-loading-center bg-slate-100">
      <i className="fas fa-hourglass-half"> Loading.. </i>
    </div>
  );
}

export default GridLoadingIndicator;
