import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { useHistory, useParams } from "react-router";
import { useManageFeatureInstanceDetails } from "entity-app/hooks/feature-details-page";
import DesignPackageDetailsTab from "./design-package-detail-tab";

function DesignPackageDetailPage() {
  const history = useHistory();
  const { projectId, designPackageId } = useParams() as any;

  const { featureInstanceData, actions } =
    useManageFeatureInstanceDetails(designPackageId);

  const goBackToList = () => {
    history.push(`/project/${projectId}/design-packages`);
  };

  const tabItems = [
    {
      label: "Design Package Details",
      key: "1",
      children: (
        <DesignPackageDetailsTab
          featureInstanceData={featureInstanceData}
          actions={actions}
          featureId={designPackageId}
        />
      )
    }
  ];

  return (
    <div className="w-full px-5 pb-6">
      <div className="w-full bg-transparent border-solid border-0 border-b-2 border-[#00000005] grid grid-cols-12 items-center h-[40px]">
        <div className="col-span-2 flex items-center justify-start">
          <Button
            icon={<ArrowLeftOutlined />}
            className="text-one text-black !bg-transparent !border-0 px-0 !shadow-none"
            onClick={goBackToList}
          >
            All Design Packages
          </Button>
        </div>
      </div>
      <div className="relative">
        <Tabs activeKey="1" items={tabItems} className="details-page-tabs" />
      </div>
    </div>
  );
}

export default DesignPackageDetailPage;
