import { v4 as uuidV4 } from "uuid";

export type UnitType = {
  id: string;
  measurement: string;
  unit: string;
};
export const getTreeFromMeasuremntList = (itemsMap: Array<UnitType>) => {
  const tree: Array<any> = [];
  itemsMap.forEach((item) => {
    let parentNode = tree.find((x) => x.title === item.measurement);
    if (!parentNode) {
      parentNode = {
        value: uuidV4(),
        title: item.measurement,
        label: `${item.measurement} ${item.unit}`,
        disabled: true,
        children: [
          {
            value: item.id,
            title: item.unit,
            label: `${item.measurement} ${item.unit}`
          }
        ]
      };
      tree.push(parentNode);
    } else {
      parentNode.children?.push({
        value: item.id,
        title: item.unit,
        label: `${item.measurement} ${item.unit}`
      });
    }
  });
  return tree;
};
