import { message as antMessage } from "antd";
import { ErrorMessage } from "./error-message";

class CumulativeErrorMessageUtil {
  private timeInterval: number = 5 * 1000; // 5 sec

  private cumulativeErrorMessages: { [key: string]: ErrorMessage } = {};

  public clearMessage(errorMessage: ErrorMessage) {
    clearInterval(errorMessage.getTimer());
    this.removeErrorMessage(errorMessage.getMessage());
  }

  private removeErrorMessage(message: string) {
    delete this.cumulativeErrorMessages[message];
  }

  private setErrorMessage(errorMessage: ErrorMessage) {
    this.cumulativeErrorMessages[errorMessage.getMessage()] = errorMessage;
  }

  private getErrorMessage(message: string): ErrorMessage {
    return this.cumulativeErrorMessages[message];
  }

  public showError(message: string) {
    /**
     * Check if there is an existing message already added
     * if not added then
     *  - show the message
     *  - start the timer
     *  - add message + count++
     * else
     *  - add message + count++
     *  "You appear to be offline. Please confirm that you have an internet connection."
     */

    let errorMessage = this.getErrorMessage(message);

    if (errorMessage) {
      errorMessage.increaseCount();
    } else {
      errorMessage = new ErrorMessage(message);
      errorMessage.increaseCount();
      const self = this;
      const timer = setInterval(() => {
        self.clearMessage(errorMessage);
      }, this.timeInterval);
      errorMessage.setTimer(timer);
      this.setErrorMessage(errorMessage);
    }

    if (!errorMessage.isDisplayed()) {
      antMessage.error(errorMessage.getMessage());
      errorMessage.setDisplayed(true);
    }
  }
}

export default new CumulativeErrorMessageUtil();
