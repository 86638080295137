import ProjectAgGridListComponent from "components/project-list-view/projectAgGridListComponent";

import "./projects-list.scss";
import moment from "moment-timezone";

function ProjectList() {
  // Set user local timezone for project list page.
  moment.tz.setDefault(moment.tz.guess());
  return <ProjectAgGridListComponent />;
}

export default ProjectList;
