import { Button, Divider, Modal } from "antd";
import DatePickerWithIcon from "components/date-picker-with-icon/date-picker-with-icon";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import "./style.scss";
import TextArea from "antd/lib/input/TextArea";
import { DateUtils } from "utils/dateutils";

type PopupProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  onOk: (props: {
    actualDate: Moment | null;
    reason: string | null;
  }) => Promise<void>;
  disabledDate?: ((current: Moment) => boolean) | undefined;
  milestoneName: string;
  existingActualDate: string | null;
  existingReason: string | null;
};
function EarlyStartModelConfirm({
  isModalOpen,
  setIsModalOpen,
  onOk,
  disabledDate,
  milestoneName,
  existingActualDate,
  existingReason
}: PopupProps) {
  const [loading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);

  const [inputData, setInputData] = useState({
    actualDate: existingActualDate
      ? DateUtils.dateTimeObj(existingActualDate)
      : null,
    reason: existingReason
  });

  useEffect(() => {
    if (
      inputData.reason &&
      /\S/.test(inputData.reason) &&
      inputData.actualDate
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [inputData.actualDate, inputData.reason]);

  const handleOk = async () => {
    setLoading(true);
    await onOk(inputData);
    setLoading(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClearActualDate = async () => {
    setLoading(true);
    await onOk({ actualDate: null, reason: null });
    setLoading(false);
    setIsModalOpen(false);
  };

  return (
    <Modal
      title="RELEASE MATERIAL EARLY"
      className="early-start-model"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      width={536}
    >
      <div className="flex flex-col w-full space-x-1 space-y-3 p-2">
        {!existingActualDate && (
          <div className="text-xs">
            <div className="pb-3">
              Are you sure you want to release material before the submittal
              workflow is complete?
            </div>
            <ul>
              <li>If yes, please enter the below details and click Confirm.</li>
              <li>If no, click Cancel.</li>
            </ul>
          </div>
        )}
        <div>
          <div className="form-item-required">REASON</div>
          <TextArea
            rows={2}
            placeholder="Please enter reason to start early."
            style={{ resize: "none" }}
            required
            value={inputData.reason || undefined}
            onChange={(e) => {
              setInputData((pre) => ({ ...pre, reason: e.target.value }));
            }}
            disabled={loading}
          />
        </div>
        <div>
          <div className="flex space-x-1 items-center">
            <div className="form-item-required">
              ACTUAL DATE ({milestoneName})
            </div>
            :<div>{DateUtils.format(inputData.actualDate)}</div>
            <DatePickerWithIcon
              value={inputData.actualDate}
              onChange={(date) => {
                if (date) setInputData((pre) => ({ ...pre, actualDate: date }));
              }}
              disabledDate={disabledDate}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      <Divider className="m-0" />
      <div className="flex justify-end space-x-4 p-2">
        {existingActualDate && (
          <Button
            className="w-24"
            onClick={handleClearActualDate}
            disabled={loading}
          >
            Clear All
          </Button>
        )}
        <Button
          key="cancel"
          className="w-24"
          onClick={handleCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={!isValid}
          loading={loading}
          onClick={handleOk}
          className="w-24"
        >
          {existingActualDate ? "Update" : "Confirm"}
        </Button>
      </div>
    </Modal>
  );
}
export default EarlyStartModelConfirm;
