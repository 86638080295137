import {
  TEditMilestone,
  TMilestonesTemplate
} from "components/workflow-template-editor/model";
import { v4 as uuidV4 } from "uuid";

export const transformMilestone = (milestones: Array<TMilestonesTemplate>) =>
  milestones.map(
    (x) =>
      ({
        id: x.id,
        name: x.name,
        milestone_action: x.milestone_action,
        offsetName: x.next_offset.length > 0 ? x.next_offset[0].name : null,
        sequence_no: x.sequence_no
      } as TEditMilestone)
  );

export const milestonesForSubmittal = (submittalDateBlockData: any) => {
  const numberOfPossibleMilestones = 10;
  if (!submittalDateBlockData) return [];
  const milestones = [];
  for (let i = 1; i <= numberOfPossibleMilestones; i += 1) {
    if (submittalDateBlockData[`name_milestone_${i}`]) {
      milestones.push({
        id: uuidV4(),
        name: submittalDateBlockData[`name_milestone_${i}`],
        offsetName: submittalDateBlockData[`name_offset_${i + 1}`]
          ? submittalDateBlockData[`name_offset_${i}`]
          : "",
        sequence_no: i
      });
    }
  }
  return milestones;
};
