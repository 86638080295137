import { FeatureTypes } from "entity-app/constants";
import { getFeatureTypeString } from "entity-app/utils/utils";
import {
  TEditMilestone,
  TMilestonesTemplate,
  TSaveWorkflowModelInput
} from "./model";

export const transformMilestoneToEditMilestone = (
  milestones: Array<TMilestonesTemplate>
) =>
  milestones.map(
    (x) =>
      ({
        id: x.id,
        name: x.name,
        milestone_action: x.milestone_action,
        offsetName: x.next_offset.length > 0 ? x.next_offset[0].name : null,
        duration: x.next_offset.length > 0 ? x.next_offset[0].duration : null
      } as TEditMilestone)
  );

export const transformEditTemplateToSaveModel = (
  template_id: string,
  name: string,
  feature_id: number,
  milestoneEdit: Array<TEditMilestone>,
  float: number
): TSaveWorkflowModelInput => {
  const feature: FeatureTypes = getFeatureTypeString(feature_id);
  const milestones = milestoneEdit.map((x, index) => ({
    name: x.name,
    milestone_action: x.milestone_action || "",
    sequence: 1 + index
  }));

  const offsets = milestoneEdit
    .filter((m) => m.offsetName)
    .map((x, index) => ({
      duration: x.duration || 0,
      name: x.offsetName || "",
      prev_milestone: x.name,
      next_milestone:
        milestones.length > 1 + index ? milestones[index + 1].name : null
    }));
  return {
    template_id,
    name,
    feature,
    milestones,
    offsets,
    float
  };
};
