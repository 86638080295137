import "./file-viewer-modal.scss";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { getFileDownloadURL } from "services/file-mgmt";
import { useEffect, useState } from "react";
import {
  DraggableModal,
  DraggableModalProvider
} from "ant-design-draggable-modal";
import { Spin } from "antd";
import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";
import { noAuthClient } from "services/axios";

function FileViewerModal(params: {
  fileKey: any;
  showModal: boolean;
  pageNumber: number;
  headerComponent?: any;
  hideModal: () => void;
}) {
  const { fileKey, showModal, hideModal, pageNumber, headerComponent } = params;

  const [fileUrl, setFileUrl] = useState("");
  const [loading, setloading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  // const [docLoadedInViewer, setDocLoadedInViewer] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const fileDownloadURLResp: any = await getFileDownloadURL([fileKey]);
        setloading(false);
        if (fileDownloadURLResp.data) {
          const link = fileDownloadURLResp.data.success.urls[fileKey];
          setFileUrl(link);
        }
      } catch (err) {
        setloading(false);
        setFileUrl("");
      }
    }
    if (fileKey) fetchData();
  }, [fileKey]);

  const downloadFile = async (url: string) => {
    setDownloading(true);
    try {
      const downloadResp = await noAuthClient.get(url, {
        responseType: "blob"
      });
      const file = new Blob([downloadResp.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (ex) {
      console.log(ex);
    }
    setDownloading(false);
  };

  const modalHeader = () => {
    return (
      <div className="w-[97%] inline-flex">
        <div className="grow">
          {headerComponent || (
            <span className="font-semibold">File Details</span>
          )}
        </div>
        {fileUrl && (
          <button
            type="button"
            className="border-0 bg-transparent cursor-pointer mr-3"
            title="Open file"
            onClick={() => {
              downloadFile(fileUrl);
            }}
            disabled={downloading}
          >
            {downloading ? <LoadingOutlined /> : <ExportOutlined />}
          </button>
        )}
      </div>
    );
  };

  const modalInitialWidth = (60 * window.innerWidth) / 100;
  const modalInitialHeight = (85 * window.innerHeight) / 100;

  return (
    <DraggableModalProvider>
      <DraggableModal
        title={modalHeader()}
        open={showModal}
        closable
        onCancel={() => hideModal()}
        footer={null}
        initialHeight={modalInitialHeight}
        initialWidth={modalInitialWidth}
        zIndex={100}
        className="file-viewer-modal"
        destroyOnClose
      >
        <div className="h-full w-full">
          {!fileKey || !fileUrl || loading ? (
            <div className="h-full w-full flex items-center justify-center">
              <Spin size="large" />
            </div>
          ) : (
            <div
              className="w-[850px]"
              style={{
                height: `${modalInitialHeight - 100}px`
              }}
            >
              <Worker workerUrl="/scripts/pdf.worker.min.js">
                <Viewer
                  fileUrl={fileUrl}
                  initialPage={pageNumber ? pageNumber - 1 : 0}
                  // onDocumentLoad={() => {
                  //   setDocLoadedInViewer(true);
                  // }}
                />
              </Worker>
            </div>
          )}
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default FileViewerModal;
