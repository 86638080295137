import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { Button, Divider, Input, Spin } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSubscription } from "@apollo/client";
import { ProjectContext } from "context/ProjectProvider";
import { SUBSCRIPTION_SPEC_PAGE_EXTRACTS } from "services/graphQL/subscriptions";
import { IconSearch } from "@tabler/icons-react";
import Tooltip from "antd/es/tooltip";
import ErrorBoundary from "components/error-boundary";
import { ExtractSpecType } from "./model";
import CiqDocumentView from "../ciq-pdf-viewer/ciq-pdf-document";
import CreateMaterialPopup from "./create-material-popup";

function TabComponent({
  specPage,
  selectedSpecPage,
  setSelectedSpecPage,
  setOpenSavePopup
}: {
  specPage: ExtractSpecType;
  selectedSpecPage: ExtractSpecType | undefined;
  setSelectedSpecPage: React.Dispatch<
    React.SetStateAction<ExtractSpecType | undefined>
  >;
  setOpenSavePopup: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [showButton, setShowButton] = useState(false);

  return (
    <div
      key={specPage.id}
      className={
        selectedSpecPage?.id === specPage.id
          ? "flex items-center  border border-solid px-2 bg-[#EBEBEB]"
          : "flex items-center  border border-solid px-2"
      }
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      aria-hidden
      role="button"
      onClick={() => {
        if (specPage !== selectedSpecPage) {
          setSelectedSpecPage(specPage);
        }
      }}
    >
      <div className="w-fit py-2 grow truncate uppercase cursor-pointer">
        {specPage.metadata?.table_name}
      </div>
      {specPage.materials_aggregate.aggregate.count === 0 && showButton && (
        <Button
          type="primary"
          className="text-xs justify-self-end h-fit w-fit p-1"
          onClick={() => {
            setOpenSavePopup(true);
          }}
        >
          Extract
        </Button>
      )}
      {specPage.materials_aggregate.aggregate.count > 0 && (
        <Tooltip title="Already extracted." placement="left">
          <CheckOutlined />
        </Tooltip>
      )}
    </div>
  );
}

type Props = {
  onClose: () => void;
};

function MaterialExtraction(props: Props) {
  const { onClose } = props;
  const [openSavePopup, setOpenSavePopup] = useState(false);
  const [selectedSpecPage, setSelectedSpecPage] = useState<ExtractSpecType>();
  const [extractPagesList, setExtractPagesList] = useState<
    Array<ExtractSpecType>
  >([]);

  const { gqlClientForProject } = useContext(ProjectContext);
  const [loading, setLoading] = useState(true);

  const { data: extractSpecPagesData } = useSubscription<{
    spec_page_extracts: Array<ExtractSpecType>;
  }>(SUBSCRIPTION_SPEC_PAGE_EXTRACTS, { client: gqlClientForProject });

  useEffect(() => {
    if (extractSpecPagesData?.spec_page_extracts) {
      const sortedList = extractSpecPagesData.spec_page_extracts.sort((a, b) =>
        (a.metadata?.table_name || "") > (b.metadata?.table_name || "") ? 1 : -1
      );
      setExtractPagesList(sortedList);
      setLoading(false);
    }
  }, [extractSpecPagesData]);

  const boundingBoxPossition = useMemo(
    () =>
      selectedSpecPage
        ? {
            width: selectedSpecPage.spec_page.width,
            height: selectedSpecPage.spec_page.height,
            x1: selectedSpecPage.bb_x1,
            x2: selectedSpecPage.bb_x2,
            y1: selectedSpecPage.bb_y1,
            y2: selectedSpecPage.bb_y2,
            page_no: selectedSpecPage.page_no
          }
        : undefined,
    [selectedSpecPage]
  );

  return (
    <div className="flex h-full material-extraction">
      <div className="grow">
        {selectedSpecPage ? (
          <div className="flex-col h-full">
            <div className="flex h-7 pl-5 items-center bg-[#E0E0E0] w-full truncate uppercase font-medium">
              {selectedSpecPage.spec_page.specification.title}
            </div>

            <CiqDocumentView
              blobKey={selectedSpecPage.spec_page.page_file_key}
              boundingBoxPossition={boundingBoxPossition}
            />
          </div>
        ) : (
          <div className="grow w-full h-full bg-gray-500 bg-opacity-60" />
        )}
      </div>
      <div className="flex flex-col w-96 h-full bg-white pointer-events-auto shadow-2xl">
        <div className="flex h-7 px-2 items-center bg-[#E0E0E0]">
          <div className="grow truncate uppercase font-medium">
            Extract from drawings
          </div>
          <CloseOutlined
            className="justify-self-end px-1"
            onClick={() => onClose()}
          />
        </div>
        <div className="flex p-2 mt-1 font-medium italic text-sm text-[#3B3B3B]">
          <span>
            This feature is under development and available only for
            demonstration purposes.
          </span>
        </div>
        <div className="p-1">
          <Input
            type="text"
            prefix={<IconSearch size={14} />}
            disabled={loading}
            placeholder="Search Schedules"
            onChange={(evt: any) => {
              const text = evt.target.value?.toLowerCase() || "";

              const list = extractSpecPagesData?.spec_page_extracts;
              if (list) {
                const result = list.filter((x) => {
                  return (
                    x.metadata?.table_name &&
                    x.metadata?.table_name?.toLowerCase().indexOf(text) > -1
                  );
                });
                setExtractPagesList(result);
              }
            }}
            allowClear
          />
        </div>

        <Divider className="m-0" />
        <div className="p-1 flex-col space-y-1 overflow-auto">
          {loading && (
            <div className="flex w-full justify-center my-20">
              <Spin />
            </div>
          )}

          {extractPagesList.length === 0
            ? !loading && (
                <div className="flex w-full justify-center my-20">
                  No Data found
                </div>
              )
            : extractPagesList.map((m) => (
                <TabComponent
                  key={m.id}
                  specPage={m}
                  selectedSpecPage={selectedSpecPage}
                  setSelectedSpecPage={setSelectedSpecPage}
                  setOpenSavePopup={setOpenSavePopup}
                />
              ))}
        </div>

        <div />
      </div>
      {openSavePopup && (
        <ErrorBoundary>
          <CreateMaterialPopup
            open={openSavePopup}
            onClose={(isParentClose: boolean) => {
              setOpenSavePopup(false);
              if (isParentClose) {
                onClose();
              }
            }}
            extractMat={selectedSpecPage}
          />
        </ErrorBoundary>
      )}
    </div>
  );
}
export default MaterialExtraction;
