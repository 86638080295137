import { Progress } from "antd";

function PdfLoaderDiv({ percent = 0 }: { percent?: number }) {
  return (
    <div className="flex h-full w-full items-center justify-center bg-gray-500">
      <Progress percent={percent} type="circle" />
    </div>
  );
}
export default PdfLoaderDiv;
