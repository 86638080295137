function SubmittalInsightsIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0V16H18V18H0V0H2ZM17.2929 3.29289L18.7071 4.70711L13 10.4142L10 7.415L5.70711 11.7071L4.29289 10.2929L10 4.58579L13 7.585L17.2929 3.29289Z"
        fill="#3B3B3B"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default SubmittalInsightsIcon;
