var createBaseBarRender = require("./task_bar_render");

var isInViewPort = require("./viewport/is_bar_in_viewport");

var getVisibleRange = require("./viewport/get_visible_bars_range");

function createTaskRenderer(gantt) {
  var defaultRender = createBaseBarRender(gantt);

  function renderSplitTask(task, timeline) {
    if (task.rollup !== false && task.$rollup && task.$rollup.length) {
      var el = document.createElement('div'),
          sizes = gantt.getTaskPosition(task);
      task.$rollup.forEach(function (itemId) {
        var child = gantt.copy(gantt.getTask(itemId));
        child.$rendered_at = task.id;
        var displayRollup = gantt.callEvent("onBeforeRollupTaskDisplay", [child.id, child, task.id]);

        if (displayRollup === false) {
          return;
        }

        var element = defaultRender(child, timeline);
        if (!element) return;
        var height = timeline.getBarHeight(task.id, child.type == gantt.config.types.milestone);
        var padding = Math.floor((timeline.getItemHeight(task.id) - height) / 2);
        element.style.top = sizes.top + padding + "px";
        element.classList.add("gantt_rollup_child");
        element.setAttribute("data-rollup-parent-id", task.id);
        el.appendChild(element);
      });
      return el;
    }

    return false;
  }

  return {
    render: renderSplitTask,
    update: null,
    //getRectangle: getBarRectangle
    isInViewPort: isInViewPort,
    getVisibleRange: getVisibleRange
  };
}

module.exports = createTaskRenderer;