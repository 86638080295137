import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";

function ReauthorizeOverlay(props: any) {
  const { onReauthClick, reauthInProgress = false } = props;

  return (
    <div>
      <div className="absolute inset-0 z-10 bg-white opacity-70" />
      <div className="absolute top-[30%] -translate-y-2/4 left-[50%] -translate-x-2/4 z-20 bg-white border border-solid border-gray-500 px-6 py-3 flex items-baseline space-x-1">
        <div className="font-medium flex">
          <WarningOutlined className="text-orange-500 font-semibold inline-block" />
        </div>
        <div className="grow">
          Please Re-Authenticate your account.{" "}
          <Button
            size="small"
            type="text"
            className="font-medium workflow-document-upload"
            onClick={() => {
              onReauthClick();
            }}
            loading={reauthInProgress}
          >
            Click here
          </Button>{" "}
          to Authenticate
        </div>
      </div>
    </div>
  );
}

function SelectTagRenderer(props: {
  label: string;
  onDelete?: Function;
  canDelete: boolean;
}) {
  const { label, onDelete, canDelete } = props;
  return (
    <span
      className="ant-select-selection-item"
      title="Trial account xagojyry@socam.me"
    >
      <span className="ant-select-selection-item-content">{label}</span>
      {canDelete && (
        <span
          className="ant-select-selection-item-remove"
          aria-hidden="true"
          onClick={() => {
            if (onDelete) onDelete();
          }}
        >
          <span role="img" aria-label="close" className="anticon anticon-close">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
            </svg>
          </span>
        </span>
      )}
    </span>
  );
}

function IntgWizardHeader(props: any) {
  const { integrationCompleted } = props;
  return (
    <div className="integration-wizard w-full flex items-center">
      <div className="pill completed">
        <div className="w-full text-center">Authorized</div>
        <div className="check-mark">
          <CheckOutlined />
        </div>
      </div>
      <div className="grow">
        <hr className="pill-connection completed" />
      </div>
      <div
        className={classNames("pill", {
          inprogress: !integrationCompleted,
          completed: integrationCompleted
        })}
      >
        <div className="w-full text-center">Select Account</div>
        <div className="check-mark">
          <CheckOutlined />
        </div>
      </div>
      <div className="grow">
        <hr
          className={classNames("pill-connection", {
            inprogress: !integrationCompleted,
            completed: integrationCompleted
          })}
        />
      </div>
      <div
        className={classNames("pill", {
          completed: integrationCompleted
        })}
      >
        <div className="w-full text-center">Integrated</div>
        <div className="check-mark">
          <CheckOutlined />
        </div>
      </div>
    </div>
  );
}

function ACCauthorizationNotDone() {
  return (
    <ul className="pl-3">
      <li>To integrate with Autodesk Construction Cloud, click Configure.</li>
      <li>Login with your ACC credential.</li>
      <li>Select the accounts you want to configure.</li>
      <li>
        You need to be part of at least one project in each of the selected ACC
        account(s).
      </li>
      <li>
        To connect with ACC projects of above configured accounts, go to Project
        Settings.
      </li>
    </ul>
  );
}
function ACCauthorizationActive() {
  return (
    <>
      <p className="m-0 p-0">
        You are connected with Autodesk Construction Cloud. <br />
        Click <span className="font-semibold">View Details</span> to:
      </p>
      <ul className="list-none pl-3">
        <li>1. View or edit details of authorization.</li>
        <li>2. Configure more accounts.</li>
      </ul>
    </>
  );
}
function ACCauthorizationExpired() {
  return (
    <p className="m-0 p-0">
      Authorization has expired and integration with Autodesk Construction Cloud
      is no longer valid. Click reauthorize to renew the connection.
    </p>
  );
}

export {
  ReauthorizeOverlay,
  SelectTagRenderer,
  IntgWizardHeader,
  ACCauthorizationNotDone,
  ACCauthorizationActive,
  ACCauthorizationExpired
};
