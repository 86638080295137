import { Button, Modal } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { BASE_URL, BUYOUT_VISUALIZATION_URL } from "services/endpoints";
import { ProjectContext } from "context/ProjectProvider";
import { client } from "services/axios";
import RenderVisual from "./RenderVisual";

function ShowDependenciesVisualization(props: { designPackageId: string }) {
  const { designPackageId } = props;
  const { tokenRetrievalState } = useContext(ProjectContext);
  const token = tokenRetrievalState?.token;

  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const config = useMemo(() => {
    return {
      Authorization: `Bearer ${token}`
    };
  }, [token]);

  useEffect(() => {
    const getVisualizationData = async () => {
      let responseData = null;
      if (designPackageId) {
        const url = `${BASE_URL}${BUYOUT_VISUALIZATION_URL.replace(
          "<DESIGN_PACKAGE_ID>",
          designPackageId
        )}`;

        responseData = await client.get(url, {
          responseType: "json",
          headers: config
        });
      }
      setData(responseData?.data);
    };
    if (open) getVisualizationData();
  }, [config, designPackageId, open]);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={showModal}>View Dependencies</Button>
      {open && (
        <Modal
          title="DESIGN PACKAGE DEPENDENCIES"
          width="70%"
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          footer={null}
          className="view-dependencies-modal"
        >
          <div>
            <RenderVisual data={data} />
          </div>
        </Modal>
      )}
    </>
  );
}

export default ShowDependenciesVisualization;
