import React from "react";
import { IconExternalLink } from "@tabler/icons-react";
import { Link } from "react-router-dom";

import { computeVisulisationLayout } from "./TemporalLayout";
import styles from "./SubmittalDiffV6.module.css";
import { ImpactVisualisationProps } from "./model";
import { EllipsisTextOverflow } from "./EllipsisTextOverflow";

export function SubmittalDiffV6(props: ImpactVisualisationProps) {
  const {
    primaryElementName,
    milestones,
    todayDate,
    arrow,
    linkedMaterials,
    xLocationsByDate,
    projectId,
    siCalculations,
    isCompletedWorkflow
  } = props;

  const temporalLayout = computeVisulisationLayout(
    projectId,
    primaryElementName,
    xLocationsByDate,
    milestones,
    siCalculations,
    todayDate,
    linkedMaterials
  );

  if (temporalLayout.layoutError) {
    return <div>⚠ {temporalLayout.layoutError}</div>;
  }

  return (
    <svg width={temporalLayout.width} height={temporalLayout.height + 4}>
      <defs>
        <marker
          id="floatEnd"
          markerWidth="4"
          markerHeight="4"
          refX="2"
          refY="2"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M0,0 L0,4 L4,4 L4,0 z" fill="black" />
        </marker>
      </defs>
      {/* primary track */}
      <rect
        x={temporalLayout.primaryTrack.originX}
        y={temporalLayout.primaryTrack.originY}
        width={temporalLayout.primaryTrack.width}
        height={temporalLayout.primaryTrack.height}
        fill="white"
        stroke="black"
        strokeWidth={1}
      />
      {/* milestone markers */}
      {temporalLayout.milestoneMarkers.map((marker, index) => {
        const key = `milestone-marker-${index}`;
        return (
          <line
            key={key}
            x1={marker.originX}
            y1={marker.originY}
            x2={marker.originX}
            y2={marker.originY + marker.height}
            className={marker.className}
            strokeWidth={2}
          />
        );
      })}
      {/* milestone special labels */}
      {temporalLayout.specialLabels.map((label, index) => {
        const key = `milestone-special-label-${index}`;
        return (
          <foreignObject
            key={key}
            x={label.originX}
            y={label.originY}
            width={label.width}
            height={label.height}
            fontSize={11}
          >
            <div
              className="p-1"
              style={{
                width: label.width,
                height: label.height,
                lineHeight: 1,
                display: "flex",
                alignItems: "flex-end"
              }}
            >
              {label.label}
            </div>
          </foreignObject>
        );
      })}
      {/* milestone labels */}
      {temporalLayout.labels.map((label, index) => {
        const key = `milestone-label-${index}`;
        return (
          <foreignObject
            key={key}
            x={label.originX}
            y={label.originY}
            width={label.width}
            height={label.height}
            fontSize={11}
          >
            <div className={`${styles.dateLabel} px-1`}>{label.date}</div>
            <div
              className="px-1"
              style={{
                width: label.width,
                height: label.height,
                lineHeight: 1
              }}
            >
              {label.label}
            </div>
          </foreignObject>
        );
      })}
      {/* material tracks */}
      {temporalLayout.postTracks.map((track, index) => {
        const key = `post-track-${index}`;
        return (
          <React.Fragment key={key}>
            <rect
              x={track.originX}
              y={track.originY}
              width={track.width}
              height={track.height}
              fill="white"
              stroke="black"
              strokeWidth={1}
            />
            <foreignObject
              x={track.originX}
              y={track.originY}
              width={track.width}
              height={track.height}
              fontSize={11}
            >
              {track.url ? (
                <Link to={track.url} target="_blank">
                  <div
                    className="px-1 flex"
                    style={{ lineHeight: `${track.height}px` }}
                  >
                    <IconExternalLink
                      className="my-1"
                      size={12}
                      color="black"
                    />
                    <div className="flex-grow text-black">
                      <EllipsisTextOverflow text={track.label} />
                    </div>
                  </div>
                </Link>
              ) : (
                <div className="flex-grow text-black p-1">
                  <EllipsisTextOverflow text={track.label} />
                </div>
              )}
            </foreignObject>
            <foreignObject
              x={track.originX}
              y={track.originY + 20}
              width={track.width}
              height={20}
              fontSize={11}
            >
              <div className="text-right">{track.endLabel}</div>
            </foreignObject>
          </React.Fragment>
        );
      })}
      {/* float indicator */}
      {temporalLayout.float.days <= 0 ? (
        <rect
          x={temporalLayout.float.originX}
          y={temporalLayout.float.originY}
          width={temporalLayout.float.width}
          height={temporalLayout.float.height}
          fill="#ff000033"
          style={{ pointerEvents: "none" }}
        />
      ) : null}
      {temporalLayout.float.days !== 0 ? (
        <>
          <line
            x1={temporalLayout.float.originX + 2}
            y1={140}
            x2={temporalLayout.float.originX + temporalLayout.float.width - 2}
            y2={140}
            stroke="black"
            strokeDasharray="2,3"
            markerStart="url(#floatEnd)"
            markerEnd="url(#floatEnd)"
          />
          <foreignObject
            x={temporalLayout.float.originX}
            y={140 - 18}
            width={temporalLayout.float.width}
            height={20}
            fontSize={11}
          >
            <div
              className="text-center"
              style={{
                width: `${temporalLayout.float.width}px`,
                height: `${temporalLayout.float.height}px`
              }}
            >
              <div>Float {temporalLayout.float.days} days</div>
            </div>
          </foreignObject>
        </>
      ) : null}
      {temporalLayout.float.days === 0 ? (
        <>
          <rect
            x={temporalLayout.float.originX - 2}
            y={140}
            width={4}
            height={4}
            fill="black"
          />
          <foreignObject
            x={temporalLayout.float.originX}
            y={140}
            width={200}
            height={20}
            fontSize={11}
          >
            <div
              className="px-1"
              style={{
                width: `${200}px`,
                height: `${temporalLayout.float.height}px`
              }}
            >
              <div>No float</div>
            </div>
          </foreignObject>
        </>
      ) : null}
      <line
        x1={temporalLayout.float.originX}
        y1={temporalLayout.float.originY}
        x2={temporalLayout.float.originX}
        y2={temporalLayout.float.originY + temporalLayout.float.height}
        stroke="black"
        strokeDasharray="1,2"
      />
      <line
        x1={temporalLayout.float.originX + temporalLayout.float.width}
        y1={temporalLayout.float.originY}
        x2={temporalLayout.float.originX + temporalLayout.float.width}
        y2={temporalLayout.float.originY + temporalLayout.float.height}
        stroke="black"
        strokeDasharray="1,2"
      />
      {/* arrow */}
      {/* draw an arrow with a triangle on its end */}
      <defs>
        <marker
          id="arrow"
          markerWidth="8"
          markerHeight="8"
          refX="8"
          refY="4"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M0,0 L0,8 L8,4 z" className={styles.arrowHead} />
        </marker>
      </defs>
      {/** Arrow */}
      {arrow && arrow.type === "submittal" ? (
        <line
          x1={arrow.x1 + 10}
          x2={arrow.x2 + 10}
          y1={temporalLayout.primaryTrack.originY + 20}
          y2={temporalLayout.primaryTrack.originY + 20}
          className={styles.arrow}
          markerEnd="url(#arrow)"
        />
      ) : null}

      {/* today indicator */}
      {!isCompletedWorkflow ? (
        <>
          <rect
            className={temporalLayout.today.className}
            x={temporalLayout.today.originX - temporalLayout.today.width / 2}
            y={temporalLayout.today.originY - temporalLayout.today.height / 2}
            height={temporalLayout.today.height}
            width={temporalLayout.today.width}
            rx={2}
            stroke="black"
          />
          <line
            x1={temporalLayout.today.originX}
            y1={temporalLayout.today.originY}
            x2={temporalLayout.today.originX}
            y2={temporalLayout.today.originY - 60}
            stroke="black"
            strokeDasharray="1,2"
          />
          <foreignObject
            x={temporalLayout.today.originX}
            y={temporalLayout.today.originY - 60}
            width={temporalLayout.today.labelWidth}
            height={120}
            fontSize={11}
            style={{ lineHeight: 1 }}
          >
            <div className="font-bold px-1">{temporalLayout.today.date}</div>
            <div className="px-1">{temporalLayout.today.label}</div>
          </foreignObject>
          {temporalLayout.todayToNextPlannedMilestone ? (
            <line
              x1={temporalLayout.todayToNextPlannedMilestone.originX + 3}
              y1={temporalLayout.today.originY}
              x2={
                temporalLayout.todayToNextPlannedMilestone.originX +
                temporalLayout.todayToNextPlannedMilestone.width -
                temporalLayout.today.width
              }
              y2={temporalLayout.today.originY}
              stroke="black"
              strokeDasharray="2,3"
              markerStart="url(#floatEnd)"
              markerEnd="url(#floatEnd)"
            />
          ) : null}
        </>
      ) : null}
    </svg>
  );
}
