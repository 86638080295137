import { Tabs } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import ErrorBoundary from "components/error-boundary";
import IntegrationsTab from "./integrations-tab-new/integrations-tab";
import ModuleSubscriptionSettings from "./module-settings";

function SubscriptionSettings() {
  const history = useHistory();
  const { tab1Id } = useParams() as any;
  const [tabIndex, setTabIndex] = useState<string>(tab1Id || "integrations");
  const tabItems = [
    {
      label: "Integrations",
      key: "integrations",
      children: (
        <ErrorBoundary>
          <IntegrationsTab />
        </ErrorBoundary>
      )
    },
    {
      label: "Module Settings",
      key: "modules",
      children: <ModuleSubscriptionSettings />
    }
  ];

  return (
    <div className="w-full px-4 project-details-tabs">
      <Tabs
        destroyInactiveTabPane
        activeKey={tabIndex}
        items={tabItems}
        onChange={(activeKey) => {
          history.push(`/settings/${activeKey.toString()}`);
          setTabIndex(activeKey);
        }}
      />
    </div>
  );
}

export default SubscriptionSettings;
