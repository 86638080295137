import { useLazyQuery, useQuery } from "@apollo/client";
import { IconSearch } from "@tabler/icons-react";
import { ColDef, GridOptions } from "ag-grid-community";
import { DateFilter, DateUtils } from "utils/dateutils";
import { AgGridReact } from "ag-grid-react";
import { Button, Input } from "antd";
import {
  SubmittalStatusCellRenderer,
  UpcomingSubmittalReportDetailsCellRenderer,
  dateCellRenderer,
  specSectionRenderer,
  submittalDueDateHeaderComponent,
  userOrgCellRenderer
} from "components/cell-renders";
import { ProjectContext, ProjectSettingType } from "context/ProjectProvider";
import {
  useIntegrationProjectParticipants,
  useProjectParticipants
} from "hooks/project-participants";
import { updateSubmittalList } from "pages/submittals-list/helpers";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import {
  QUERY_SUBMITTAL_RISK_REPORT_INTG,
  QUERY_SUBMITTAL_RISK_REPORT_WF,
  QUERY_SUBMITTAL_TYPES
} from "services/graphQL/queries";

import {
  downloadStringAsCSVFile,
  isProjectInIntegrationMode
} from "utils/utils";
import { TAuditChangeLog } from "change-events/change-event-polling";
import { useCiqSubmittalRiskReportList } from "pages/risk-report/hooks/risk-report";
import { SubmittalStatusToStrMap } from "../../../constants";
import { InnerIdLinkSubmittalRenderer } from "../components/cellRenderer";
import { getSubmittalFormattedValues } from "./export-csv-util";

type ReportFilterType = {
  filter: number;
  groupby: string;
  sortBy: string;
};

function UpcomingSubmittalsComponent(props: {
  reportFilter: any;
  setOpenFilterForm: any;
  projectDetails: ProjectSettingType | undefined;
}) {
  const { reportFilter, setOpenFilterForm, projectDetails } = props;
  const [numberOfSubmittals, setNumberOfSubmittals] = useState<number>(0);
  const [gridSearchtext, setGridSearchtext] = useState("");
  const [masterData, setMasterData] =
    useState<Array<{ name: string; id: string; callRecords: Array<any> }>>();

  const [expandedRowCount, setExpandedRowCount] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [isGridReady, setGridReady] = useState(false);

  const gridRef = useRef<AgGridReact<any>>(null);
  const [gridData, setGridData] = useState<{
    submittals?: any;
  }>({
    submittals: null
  });

  const {
    gqlClientForProject,
    columnHeaders: { submittalHeaderMap },
    eventLogs
  } = useContext(ProjectContext);

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails.mode : 0
  );

  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode
  );

  const { projectParticipants: WFProjectParticipants } =
    useProjectParticipants(isIntegrationMode);

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  const SUBMITTAL_RISK_REPORT = isIntegrationMode
    ? QUERY_SUBMITTAL_RISK_REPORT_INTG
    : QUERY_SUBMITTAL_RISK_REPORT_WF;

  const { data: upcomingSubmittalData } = useCiqSubmittalRiskReportList({
    gqlClientForProject,
    isIntegrationMode,
    variables: {
      where: {}
    }
  });

  const [getLazyUpcomingSubmittalData] = useLazyQuery(SUBMITTAL_RISK_REPORT, {
    client: gqlClientForProject,
    fetchPolicy: "network-only"
  });

  const { data: submittalTypes } = useQuery(QUERY_SUBMITTAL_TYPES, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const deleteSubmittalsFromList = useCallback(
    (ids: Array<string>) => {
      const items = gridData.submittals ? [...gridData.submittals] : [];
      ids.forEach((id) => {
        const index = items.findIndex((x) => x.id === id);
        items.splice(index, 1);
      });
      setGridData({
        submittals: items
      });
    },
    [gridData.submittals]
  );

  const getAndUpdateSubmittalList = useCallback(
    async (submittalIds: Array<string>) => {
      if (!submittalIds.length) return;
      const variables = { where: { id: { _in: submittalIds } } };

      const res = await getLazyUpcomingSubmittalData({
        variables,
        fetchPolicy: "network-only"
      });
      if (res.data) {
        const updatedSubmittalList = isIntegrationMode
          ? res.data.submittal_cumulative_risk_report_intg_mode_func
          : res.data.submittal_cumulative_risk_report_wf_mode_func;

        const items = [...gridData.submittals] as Array<any>;
        updatedSubmittalList.forEach((item: any) => {
          const foundIndex = items.findIndex((x) => x.id === item.id);
          if (foundIndex === -1) items.push(item);
          items[foundIndex] = item;
        });
        setGridData({
          submittals: items
        });
      }
    },
    [getLazyUpcomingSubmittalData, gridData.submittals, isIntegrationMode]
  );

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs?.length && previousEventLogs.current !== eventLogs) {
      const conditionForSubmittalRiskLog = (log: TAuditChangeLog) =>
        // log.info.computation === true &&
        log?.info?.submittal_ids && log?.info?.submittal_ids?.length > 0;

      if (eventLogs.some(conditionForSubmittalRiskLog)) {
        const auditLogs = eventLogs.filter(
          (log: TAuditChangeLog) => log.info.submittal_ids
        ) as Array<TAuditChangeLog>;

        const deletedIds = auditLogs
          .filter(
            (log) =>
              log.change_type === "delete" && log.data_source === "submittals"
          )
          .map((s: TAuditChangeLog) => s.info.submittal_ids!) as any;
        if (deletedIds.length) {
          const deletedDArray = [...new Set([].concat([], ...deletedIds))];
          deleteSubmittalsFromList(deletedDArray);
        }

        const ids = auditLogs
          .filter(
            (log) =>
              log.change_type === "update" &&
              (log.data_source === "date_block" ||
                log.data_source === "submittals")
          ) // update create import
          .map((s) => s.info.submittal_ids) as any;

        if (ids.length) {
          const singleDArray = [...new Set([].concat([], ...ids))];
          getAndUpdateSubmittalList(singleDArray);
        }
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    eventLogs,
    deleteSubmittalsFromList,
    isIntegrationMode,
    getAndUpdateSubmittalList
  ]);

  const updateGridView = (e: ReportFilterType, list: Array<any>) => {
    const dateForFilter = DateUtils.dateTimeObj(e.filter);

    const masterD: Array<{
      name: string;
      id: string;
      callRecords: Array<any>;
    }> = [];

    const groupByItem: any =
      e.groupby === "BIC_GroupBy"
        ? {
            name: "assignee_unregistered",
            id: "assignee_user_id",
            firstName: "assignee_first_name",
            lastName: "assignee_last_name"
          }
        : {
            name: "responsible_contractor_name",
            id: "responsible_contractor_id"
          };
    let submittalCount = 0;
    list?.forEach((data: any) => {
      if (
        data.date_block_submittals[0]?.due_date &&
        DateUtils.dateTimeObj(data.date_block_submittals[0]?.due_date).diff(
          dateForFilter,
          "days"
        ) <= 0
      ) {
        // eslint-disable-next-line no-plusplus
        ++submittalCount;
        const id = data[groupByItem.id]
          ? data[groupByItem.id]
          : data[groupByItem.name];

        const d = masterD.find((x) => x.id === id);
        if (d) {
          d.callRecords.push(data);
        } else {
          const unknown =
            e.groupby === "BIC_GroupBy"
              ? "No Ball in Court"
              : "Unassigned Responsible Contractor";
          // eslint-disable-next-line no-nested-ternary
          const name = data[groupByItem.name]
            ? data[groupByItem.name]
            : data[groupByItem.firstName]
            ? `${data[groupByItem.firstName]} ${data[groupByItem.lastName]}`
            : unknown;
          masterD.push({
            name,
            id,
            callRecords: [data]
          });
        }
      }
    });
    setNumberOfSubmittals(submittalCount);

    setMasterData(masterD.sort((a, b) => a.name.localeCompare(b.name)));
    setTotalNumberOfRows(submittalCount + masterD.length);
  };

  useEffect(() => {
    if (upcomingSubmittalData) {
      const submittals: any = isIntegrationMode
        ? upcomingSubmittalData.submittal_cumulative_risk_report_intg_mode_func
        : upcomingSubmittalData.submittal_cumulative_risk_report_wf_mode_func;

      setGridData({ submittals });
    }
  }, [upcomingSubmittalData, isIntegrationMode]);

  const submittalListTableData: Array<any> = useMemo(() => {
    if (!gridData?.submittals || !projectParticipants.allProjectUsersMap)
      return null;

    const updatedSubmittalList = updateSubmittalList(
      gridData?.submittals,
      projectParticipants
    );

    if (reportFilter) updateGridView(reportFilter, updatedSubmittalList);

    return updatedSubmittalList;
  }, [projectParticipants, reportFilter, gridData?.submittals]);

  const expandCollapseAll = useCallback(() => {
    const isExpand = expandedRowCount !== totalNumberOfRows; // tmpExpandedItemIds.length > 0;

    gridRef.current!.api.forEachNode((node) => {
      node.setExpanded(isExpand);
    });

    setTimeout(() => {
      if (isExpand)
        gridRef.current!.api.forEachDetailGridInfo((gridInfo) => {
          gridInfo.api?.forEachNode((node) => {
            node.setExpanded(isExpand);
          });
        });
    }, 100);
    if (isExpand) setExpandedRowCount(totalNumberOfRows);
    else setExpandedRowCount(0);
    gridRef.current!.api.onGroupExpandedOrCollapsed();
  }, [expandedRowCount, totalNumberOfRows]);

  const defaultColDef: {} = useMemo(() => {
    return {
      menuTabs: [],
      sortingOrder: ["asc", "desc"],
      suppressColumnsToolPanel: true,
      flex: 1
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200
    };
  }, []);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "name",
        cellRenderer: "agGroupCellRenderer",
        valueGetter: ({ data }) => `${data.name} (${data.callRecords.length})`
      }
    ],
    []
  );

  const detailCellRenderer = useMemo(() => {
    return UpcomingSubmittalReportDetailsCellRenderer;
  }, []);

  const typesMap = useMemo(() => {
    if (submittalTypes) {
      const tmpTypesMap: any = {};
      submittalTypes.configurations_value.forEach((type: any) => {
        tmpTypesMap[type.id] = type;
      });
      return tmpTypesMap;
    }
    return null;
  }, [submittalTypes]);

  const statusesMap: any = useMemo(() => {
    const tmpStatusesMap: any = {};

    Object.keys(SubmittalStatusToStrMap).forEach((keyStatusId: any) => {
      tmpStatusesMap[keyStatusId] = {
        name: SubmittalStatusToStrMap[keyStatusId]
      };
    });
    return tmpStatusesMap;
  }, []);

  const onUpdateExpandedRow = () => {
    let count = 0;
    gridRef.current?.api?.forEachNode((node) => {
      if (node.expanded) count = 1 + count;
    });
    gridRef.current?.api?.forEachDetailGridInfo((gridInfo) => {
      gridInfo.api?.forEachNode((node) => {
        if (node.expanded) count = 1 + count;
      });
    });
    setExpandedRowCount(count);
  };

  const gridContext: any = useMemo(() => {
    return {
      projectDetails,
      typesMap,
      statusesMap,
      isIntegrationMode,
      projectParticipants,
      submittalHeaderMap
    };
  }, [
    isIntegrationMode,
    projectDetails,
    projectParticipants,
    statusesMap,
    typesMap,
    submittalHeaderMap
  ]);

  const detailCellRendererParams = useMemo<{
    detailGridOptions: GridOptions;
  }>(() => {
    return {
      // provide the Grid Options to use on the Detail Grid
      detailGridOptions: {
        context: gridContext,
        masterDetail: true,
        detailCellRenderer,
        detailRowAutoHeight: true,
        rowHeight: 50,
        defaultColDef: {
          sortable: true,
          filter: "agSetColumnFilter",
          menuTabs: ["filterMenuTab"],
          resizable: true
        },
        onGridReady(gridApi) {
          if (!reportFilter) return;
          gridApi.columnApi.applyColumnState({
            state: [
              {
                colId: reportFilter.sortBy,
                sort: "asc"
              }
            ]
          });
          gridApi.api?.sizeColumnsToFit();
        },
        onRowGroupOpened() {
          onUpdateExpandedRow();
        },
        columnDefs: [
          {
            colId: "submittal_id",
            field: "submittal_id",
            headerName: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
            headerTooltip:
              submittalHeaderMap?.submittal_id?.toUpperCase() || "",
            width: 150,
            minWidth: 80,
            maxWidth: 200,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
              innerRenderer: InnerIdLinkSubmittalRenderer
            },
            comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
              if (projectDetails?.spec_section) {
                if (nodeA.data.spec_no && nodeB.data.spec_no) {
                  const specnoA = Number(nodeA.data.spec_no.replace(/ /g, ""));
                  const specnoB = Number(nodeB.data.spec_no.replace(/ /g, ""));
                  if (nodeA.data.spec_no === nodeB.data.spec_no) {
                    if (valueA === valueB) {
                      return 0;
                    }
                    return valueA > valueB ? 1 : -1;
                  }
                  return specnoA > specnoB ? 1 : -1;
                }

                if (valueA === valueB) {
                  return 0;
                }
                return valueA > valueB ? 1 : -1;
              }

              if (valueA === valueB) {
                return 0;
              }

              return valueA > valueB ? 1 : -1;
            },
            valueGetter: (params: any) => {
              try {
                if (!params.data) return "";
                const cellText =
                  params.context.projectDetails?.spec_section &&
                  params.data.spec_no
                    ? `${params.data.spec_no} - ${params.data.submittal_id}`
                    : `${params.data.submittal_id}`;
                return cellText;
              } catch {
                return "";
              }
            },
            tooltipValueGetter: ({ value }) => value
          },
          {
            colId: "custom_spec_section",
            headerName: submittalHeaderMap?.spec_no?.toUpperCase() || "",
            headerTooltip: submittalHeaderMap?.spec_no?.toUpperCase() || "",
            cellRenderer: specSectionRenderer,
            cellRendererParams: {
              specNoField: "spec_no",
              specNameField: "spec_name"
            },
            valueGetter: (params: any) => {
              try {
                if (!params.data) return "";
                let specSectionStr = params.data.spec_no || "";
                if (params.data.spec_name) {
                  specSectionStr += ` - ${params.data.spec_name}`;
                }
                return specSectionStr;
              } catch (ex) {
                return "";
              }
            },
            comparator: (valueA: string | null, valueB: string | null) => {
              if (!valueA) return 1;
              if (!valueB) return -1;
              return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            },
            tooltipValueGetter: ({ value }) => value
          },
          {
            colId: "title",
            field: "title",
            headerName: submittalHeaderMap?.title?.toUpperCase() || "",
            headerTooltip: submittalHeaderMap?.title?.toUpperCase() || "",
            tooltipField: "title"
          },
          {
            colId: "type",
            field: "type",
            headerName: submittalHeaderMap?.type?.toUpperCase() || "",
            headerTooltip: submittalHeaderMap?.type?.toUpperCase() || "",
            keyCreator: (params: any) => params.value,
            getQuickFilterText: (params: any) => params.value,
            tooltipValueGetter: (params: any) => params.value
          },
          {
            colId: "status",
            field: "workflow_status",
            headerName:
              submittalHeaderMap?.workflow_status?.toUpperCase() || "",
            headerTooltip:
              submittalHeaderMap?.workflow_status?.toUpperCase() || "",
            cellRenderer: SubmittalStatusCellRenderer,
            cellRendererParams: {
              dataMapVar: "statusesMap",
              valueVar: "id",
              labelVar: "name"
            },
            tooltipValueGetter: (params: any) => {
              return params.context.statusesMap[params.value].name;
            },
            keyCreator: (params: any) => {
              try {
                return params.context.statusesMap[params.value].name;
              } catch (error) {
                return "";
              }
            }
          },
          {
            colId: "assignee",
            headerName: submittalHeaderMap?.assignee?.toUpperCase() || "",
            headerTooltip: submittalHeaderMap?.assignee?.toUpperCase() || "",
            cellRenderer: (params: any) => {
              if (!params.data) return null;
              const data = userOrgCellRenderer(params);
              return data.cellMarkup;
            },
            cellRendererParams: {
              userInput: {
                id: "assignee_user_id",
                firstName: "assignee_first_name",
                lastName: "assignee_last_name",
                org: "assignee_org",
                unregistered_user: "assignee_unregistered",
                unregistered_user_org: "assignee_unregistered_org",
                statusKey: "assignee_status"
              }
            },
            valueGetter: (params: any) => {
              if (!params.data) return null;
              const data = userOrgCellRenderer(params);
              return data.fullName;
            },
            tooltipValueGetter: (params: any) => {
              if (!params.data) return null;
              const data = userOrgCellRenderer(params);
              return `${data.fullName} - ${data.userOrg}`;
            },
            hide: reportFilter.groupby === "BIC_GroupBy"
          },
          {
            colId: "dueDate",
            headerName: submittalHeaderMap?.due_date?.toUpperCase() || "",
            headerTooltip: submittalHeaderMap?.due_date?.toUpperCase() || "",
            headerComponentParams: submittalDueDateHeaderComponent,
            cellRenderer: dateCellRenderer,
            comparator: DateFilter.comparator,
            valueGetter: ({ data }) => {
              if (!data || data.date_block_submittals.length === 0) return "";
              return data.date_block_submittals[0]?.due_date;
            },
            tooltipValueGetter: (params) => {
              return DateUtils.format(params.value);
            },
            filterValueGetter: (params: any) => {
              return DateUtils.format(params.value);
            }
          },
          {
            colId: "risk_level",
            field: "risk_level",
            headerName: "RISK",
            hide: true
          },
          {
            colId: "risk_assessment",
            headerName: "RISK ASSESSMENT",
            hide: true
          },
          {
            colId: "linked_materials",
            field: "submittal_material_links",
            headerName: "IMPACTED MATERIALS",
            hide: true
          },
          {
            colId: "linked_tasks",
            field: "submittal_schedule_links",
            headerName: "IMPACTED ACTIVITIES",
            hide: true
          },
          {
            colId: "BIC_GroupBy",
            headerName:
              submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
            headerTooltip:
              submittalHeaderMap?.responsible_contractor?.toUpperCase() || "",
            rowGroup: false,
            hide: reportFilter.groupby === "RC_GroupBy",
            valueGetter: (param) => param.data.responsible_contractor_name,
            // eslint-disable-next-line react/no-unstable-nested-components
            cellRenderer: ({ value }: any) => (
              <div className="uppercase font-medium">{value}</div>
            )
          }
        ] as ColDef[]
      },

      // get the rows for each Detail Grid
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.callRecords);
      }
    };
  }, [
    detailCellRenderer,
    gridContext,
    projectDetails?.spec_section,
    reportFilter,
    submittalHeaderMap
  ]);

  useEffect(() => {
    gridRef.current?.api?.forEachDetailGridInfo((gridInfo) => {
      gridInfo.api?.setQuickFilter(gridSearchtext);
    });
    gridRef.current?.api?.onFilterChanged();
  }, [gridSearchtext]);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isGridReady]);

  useEffect(() => {
    if (gridData?.submittals) {
      setTimeout(() => {
        gridRef.current?.api.refreshCells({
          force: true,
          columns: ["assignee"]
        });
      }, 0);
    }
  }, [gridData?.submittals]);

  useEffect(() => {
    gridRef.current?.api?.refreshCells({ force: true });
  }, [gridData?.submittals, projectParticipants]);

  const getParamsForCSVExport = useCallback(
    ({ skipColumnHeaders, detailGridInfo, firstTableParams }: any) => {
      const additionalColumns = [
        "assignee",
        "BIC_GroupBy",
        "risk_level",
        "risk_assessment",
        "linked_materials",
        "linked_tasks"
      ];

      const allgridCols = detailGridInfo?.columnApi?.getAllGridColumns();

      const columnKeysData = firstTableParams?.columnKeys
        ? firstTableParams?.columnKeys
        : allgridCols
            ?.filter((col: any) => {
              const colId = col.getColId();
              return col.isVisible() || additionalColumns?.includes(colId);
            })
            .map((col: any) => col.getColId());

      return {
        columnKeys: columnKeysData,
        processCellCallback(params: any): string {
          return getSubmittalFormattedValues(params);
        },
        skipColumnHeaders
      };
    },
    []
  );

  const exportLogAsCSV = useCallback(() => {
    let csvOpString = "";
    let firstTableParams: any = null;
    gridRef.current?.api?.forEachDetailGridInfo(
      (detailGridInfo, index: number) => {
        const getDataParams = getParamsForCSVExport({
          skipColumnHeaders: index > 0,
          detailGridInfo,
          firstTableParams
        });

        if (index === 0) firstTableParams = getDataParams;

        const gridDataAsCsv = detailGridInfo?.api?.getDataAsCsv(getDataParams);
        csvOpString += index > 0 ? `\n${gridDataAsCsv}` : gridDataAsCsv;
      }
    );

    downloadStringAsCSVFile(
      csvOpString,
      `Upcoming Submittals_${projectDetails?.name}.csv`
    );
  }, [getParamsForCSVExport, projectDetails?.name]);

  return (
    <div className="flex flex-col h-full">
      <div className="w-full flex justify-between py-1">
        <div className="font-medium flex uppercase items-center">
          {numberOfSubmittals} Upcoming Submittals
        </div>
        <div className="flex space-x-3 items-center">
          <Input
            type="text"
            placeholder="Search Submittals"
            prefix={<IconSearch size={14} />}
            onChange={(event) => {
              setGridSearchtext(event.target.value);
            }}
            allowClear
            disabled={!submittalListTableData || !submittalListTableData.length}
          />
          <Button onClick={() => setOpenFilterForm(true)}>
            Run New Report
          </Button>
          <Button
            onClick={() => {
              expandCollapseAll();
            }}
            className="w-[110px]"
            disabled={!submittalListTableData || !submittalListTableData.length}
          >
            {expandedRowCount === totalNumberOfRows ? (
              <span>Collapse All</span>
            ) : (
              <span>Expand All</span>
            )}
          </Button>

          <Button
            onClick={() => {
              gridRef.current!.api.forEachNode((node) => {
                node.setExpanded(true);
              });

              setTimeout(() => {
                exportLogAsCSV();
              }, 500);
            }}
            disabled={!submittalListTableData || !submittalListTableData.length}
          >
            Export CSV
          </Button>
        </div>
      </div>
      <div className="grow ag-theme-alpine pt-1">
        <AgGridReact
          ref={gridRef}
          className="ag-theme-alpine"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          headerHeight={0}
          rowData={masterData}
          suppressContextMenu
          onGridReady={(gridApi) => {
            setGridReady(true);
            gridApi.api.sizeColumnsToFit();
          }}
          onFirstDataRendered={() => {
            setTimeout(() => {
              expandCollapseAll();
            });
          }}
          masterDetail
          detailCellRendererParams={detailCellRendererParams}
          onRowGroupOpened={() => {
            onUpdateExpandedRow();
          }}
          keepDetailRows
          detailRowAutoHeight
          isExternalFilterPresent={() => {
            return true;
          }}
          doesExternalFilterPass={(rowNode) => {
            const { detailNode } = rowNode;
            if (detailNode) {
              const displayedCount =
                detailNode.detailGridInfo?.api?.getDisplayedRowCount();
              if (!displayedCount) return false;
            }
            return true;
          }}
        />
      </div>
    </div>
  );
}

export default UpcomingSubmittalsComponent;
