import LinkIcon from "components/svg-icons/link-icon";

function FeatureLinkedItem(props: {
  number: string;
  title: string;
  sizeClass: string;
}) {
  const { number, title, sizeClass } = props;
  return (
    <div
      className={`border-solid border-[#3b3b3b80] border-[.5px] rounded-sm p-2 ${sizeClass}`}
    >
      <div className="flex items-center space-x-1">
        <div className="shrink-0 flex items-center justify-center">
          <LinkIcon />
        </div>
        <div className="text-[#3B3B3B99] truncate max-w-full" title={number}>
          {number}
        </div>
      </div>
      <div className="pt-1 pl-0.5 text-[#3B3B3BCC] truncate" title={title}>
        {title}
      </div>
    </div>
  );
}

export default FeatureLinkedItem;
