import classNames from "classnames";
import moment, { Moment } from "moment";
import { DateUtils } from "utils/dateutils";
import styles from "./SubmittalDiffV6.module.css";
import { GoverningMaterial, TimelineSiCalculations, Milestone } from "./model";

export type Workflow = {
  uiMetaData: { [key: string]: string };
  milestones: Milestone[];
};

export type XPosByDate = {
  [key: string]: number;
};

export const numberOfDays = (isoString1: string, isoString2: string): number =>
  DateUtils.dateTimeObj(isoString1).diff(
    DateUtils.dateTimeObj(isoString2),
    "days"
  );

const DATE_FORMAT_YYYYMMDD = "YYYYMMDD";
export const getNormalizedDate = (date: any) =>
  DateUtils.format(date, DATE_FORMAT_YYYYMMDD);
export const getMomentFromNormalizedDate = (date: string) =>
  moment(date, DATE_FORMAT_YYYYMMDD);
const MILESTONE_WIDTH = 150;
const DEFAULT_BIG_GAP = 100;
const MIN_DAYS_FOR_BIG_GAP = 5;

const PADDING = 30;

const DEBUG_LOGGING = false;

export function computeXPositionsForDatesv6(
  dates: Moment[],
  today: Moment,
  milestoneWidth = Math.floor(MILESTONE_WIDTH * 1.25)
): XPosByDate {
  const xLocations: XPosByDate = {};

  const milestoneDates = new Set<string>(
    dates.map((d) => getNormalizedDate(d))
  );
  milestoneDates.add(getNormalizedDate(today));

  // Phase 1: Uniform allocation of dates by milestone
  const sortedMilestoneDates = Array.from(milestoneDates).sort();
  sortedMilestoneDates.forEach((date, i) => {
    xLocations[date] = i * milestoneWidth;
  });

  // Phase 2: Linearisation below big gap
  const ENABLE_LINEARISATION = false;
  if (ENABLE_LINEARISATION) {
    const DYNAMIC_RANGE_TRIGGER = 7;
    const DYNAMIC_RANGE_PX_PER_DAY = Math.floor(
      milestoneWidth / DYNAMIC_RANGE_TRIGGER
    );

    for (let i = 0; i <= sortedMilestoneDates.length - 2; i += 1) {
      const currentMilestoneDate = sortedMilestoneDates[i];
      const nextMilestoneDate = sortedMilestoneDates[i + 1];

      // Gap to the next milestone
      const nextGap = numberOfDays(nextMilestoneDate, currentMilestoneDate);

      if (DEBUG_LOGGING) {
        console.log(
          `currentMilestoneDate:${currentMilestoneDate} -> nextMilestoneDate:${nextMilestoneDate} :: ${nextGap}`
        );
      }

      if (nextGap < DYNAMIC_RANGE_TRIGGER) {
        const newWidth = DYNAMIC_RANGE_PX_PER_DAY * nextGap;
        const shrinkDelta = milestoneWidth - newWidth;
        if (DEBUG_LOGGING) {
          console.log(`Dynamically shrinking by ${shrinkDelta} to ${newWidth}`);
        }
        for (let j = i + 1; j <= sortedMilestoneDates.length - 1; j += 1) {
          const adjustmentTargetDate = sortedMilestoneDates[j];
          // eslint-disable-next-line operator-assignment
          xLocations[adjustmentTargetDate] =
            xLocations[adjustmentTargetDate] - shrinkDelta;
        }
      }
    }
  }

  // Phase 3: Rescale
  const ENABLE_RESCALE = false;
  if (ENABLE_RESCALE) {
    const TARGET_WIDTH = window.innerWidth;

    // Compute the total widths used
    const currentLastX =
      xLocations[sortedMilestoneDates[sortedMilestoneDates.length - 1]];

    if (DEBUG_LOGGING) {
      console.log(`currentLastX ${currentLastX}`);
    }
    const scaleRatio = TARGET_WIDTH / currentLastX;
    if (DEBUG_LOGGING) {
      console.log(`Dynamically rescaling by ${scaleRatio}`);
    }

    for (let i = 0; i <= xLocations.length - 1; i += 1) {
      // eslint-disable-next-line operator-assignment
      xLocations[i] = xLocations[i] * scaleRatio;
    }
  }

  return xLocations;
}

export function computeXPosition(
  dates: Moment[],
  width: number,
  today: Moment,
  milestoneWidth = MILESTONE_WIDTH
): XPosByDate {
  const xLocations: XPosByDate = {};

  let currentX = PADDING;

  const milestoneDates = new Set<string>(
    dates.map((d) => getNormalizedDate(d))
  );

  // Extract all the milestones to be displayed
  // todo: incorporate start and end of pre-material submittals & post-submittal materials
  milestoneDates.add(getNormalizedDate(today));

  const sortedMilestoneDates = Array.from(milestoneDates).sort();

  // todo: add the estimate

  // Step 1: Uniform layout
  let widthBudget = width;
  sortedMilestoneDates.forEach((date, i) => {
    xLocations[date] = currentX;
    if (i !== sortedMilestoneDates.length - 1) {
      currentX += milestoneWidth;
      widthBudget -= milestoneWidth;
    }
  });

  // Step 2: Compute step-to-step changes
  const gapSizes: { [key: string]: number } = {};
  for (let i = 0; i <= sortedMilestoneDates.length - 2; i += 1) {
    const gapSize = numberOfDays(
      sortedMilestoneDates[i],
      sortedMilestoneDates[i + 1]
    );
    gapSizes[sortedMilestoneDates[i]] = gapSize;
  }

  // Step 3: Allocate
  const rangesByGap = Object.keys(gapSizes).sort(
    (a, b) => (gapSizes[a] ?? 0) - (gapSizes[b] ?? 0)
  );

  for (let i = 0; i < rangesByGap.length; i += 1) {
    const indexOfGapToAdjust = rangesByGap[i];

    if ((gapSizes[indexOfGapToAdjust] ?? 0) <= MIN_DAYS_FOR_BIG_GAP) break;
    if (widthBudget < DEFAULT_BIG_GAP) break;

    const index = sortedMilestoneDates.indexOf(indexOfGapToAdjust) + 1;

    if (index < sortedMilestoneDates.length) {
      xLocations[indexOfGapToAdjust] = DEFAULT_BIG_GAP;
      widthBudget -= DEFAULT_BIG_GAP + milestoneWidth;
    }

    // Move all the later dates
    for (let j = i + 1; j < sortedMilestoneDates.length; j += 1) {
      const newLocation =
        (xLocations[indexOfGapToAdjust] ?? 0) +
        DEFAULT_BIG_GAP -
        milestoneWidth;
      xLocations[indexOfGapToAdjust] = newLocation;
    }
  }

  // calculate today
  xLocations.today = xLocations[getNormalizedDate(today)] ?? 0;
  return xLocations;
}

export type Decorators = {
  arrow:
    | {
        x1: number;
        x2: number;
        type: "material" | "submittal";
      }
    | undefined;
};

export const computeDecorators = (
  xLocationsByDate: { [key: string]: number },
  beforeMaterialROJ: string | null | undefined,
  afterMaterialROJ: string | null | undefined,
  beforeFinalDeadline: string | null,
  afterFinalDeadline: string | null
): Decorators => {
  const beforeMaterialROJNormalized = beforeMaterialROJ
    ? getNormalizedDate(beforeMaterialROJ)
    : "";
  const afterMaterialROJNormalized = afterMaterialROJ
    ? getNormalizedDate(afterMaterialROJ)
    : "";

  const beforeFinalDeadlineNormalized = beforeFinalDeadline
    ? getNormalizedDate(beforeFinalDeadline)
    : "";
  const afterFinalDeadlineNormalized = afterFinalDeadline
    ? getNormalizedDate(afterFinalDeadline)
    : "";

  let arrowType: "material" | "submittal" = "material";
  let arrowStartX = xLocationsByDate[beforeMaterialROJNormalized];
  let arrowEndX = xLocationsByDate[afterMaterialROJNormalized];
  if (beforeMaterialROJNormalized === afterMaterialROJNormalized) {
    arrowType = "submittal";
    arrowStartX = xLocationsByDate[beforeFinalDeadlineNormalized];
    arrowEndX = xLocationsByDate[afterFinalDeadlineNormalized];
  }
  const arrowLength = arrowEndX - arrowStartX;

  return {
    arrow:
      arrowLength !== 0
        ? {
            x1: arrowStartX,
            x2: arrowEndX,
            type: arrowType
          }
        : undefined
  };
};

type Coordinates = {
  x: number;
  y: number;
  width: number;
  height: number;
  labelX: number;
  labelY: number;
};

const emptyCoordinates: Coordinates = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  labelX: 0,
  labelY: 0
};

export type TemporalLayout = {
  layoutError: string;

  tracks: {
    submittal: Coordinates;
    float: Coordinates;
  };

  markers: (Coordinates & {
    milestone: Milestone;
    markerClassName: string;
    labelClassName: string;
  })[];

  today: {
    coordinates: Coordinates;
    indicatorClassName: string;
    labelClassName: string;
    label: string;
  };

  float: {
    numberOfDays: number;
    trackClassName: string;
    labelClassName: string;
  };

  milestoneWidth: number;

  preMilestoneTracks: (Coordinates & { label: string })[];
  postMilestoneTracks: (Coordinates & {
    label: string;
    date: string;
    materialId: string;
  })[];

  labelsByDate: { [key: string]: string[] };
  height: number;
};

const TRACKER_HEIGHT = 16;
const MARKER_WIDTH = 2;
const MARKER_HEIGHT = 24;

const TODAY_MARKER_WIDTH = 8;
const LABEL_OFFSET = 3;

export function computeLayout(
  primaryElementName: String,
  _xLocationsByDate: { [key: string]: number },
  milestones: Milestone[],
  siCalculations: TimelineSiCalculations,
  todayDate: Moment,
  width: number,
  _linkedMaterials?: GoverningMaterial[],
  _linkedSubmittals?: string[]
): TemporalLayout {
  const temporalLayout: TemporalLayout = {
    layoutError: "",
    tracks: {
      submittal: { ...emptyCoordinates },
      float: { ...emptyCoordinates }
    },
    markers: [],
    today: {
      coordinates: { ...emptyCoordinates },
      labelClassName: "",
      indicatorClassName: "",
      label: ""
    },
    float: {
      numberOfDays: siCalculations.effectiveFloat,
      trackClassName: "",
      labelClassName: ""
    },
    preMilestoneTracks: [],
    postMilestoneTracks: [],
    milestoneWidth: 0,
    height: 0,
    labelsByDate: {}
  };

  const xLocationsByDate = {
    ..._xLocationsByDate
  };
  const linkedMaterials = _linkedMaterials ?? [];
  const linkedSubmittals = _linkedSubmittals ?? [];

  let currentX = PADDING;
  let currentY = PADDING;

  // pre-material submittal tracks
  const trackBottomMargin = TRACKER_HEIGHT / 3;
  const preMilestoneTracks = linkedSubmittals.map((submittalId, i) => {
    const submittalTrack = {
      x: currentX,
      y: currentY + i * (TRACKER_HEIGHT + trackBottomMargin),
      width: MILESTONE_WIDTH,
      height: TRACKER_HEIGHT,
      labelX: currentX,
      labelY: currentY + i * (TRACKER_HEIGHT + trackBottomMargin),
      label: submittalId
    };
    return submittalTrack;
  });
  currentX += linkedSubmittals.length > 0 ? MILESTONE_WIDTH : 0;
  currentY += linkedSubmittals.length * (TRACKER_HEIGHT + trackBottomMargin);
  currentY += 20;

  // todo: check here
  if (currentY < 70) {
    currentY = 70;
  }

  const allMilestones: Milestone[] = milestones;
  const milestoneDates = new Set<string>(
    milestones.map((m) => getNormalizedDate(m.date))
  );

  if (linkedSubmittals.length > 0) {
    Object.keys(xLocationsByDate).forEach((date) => {
      xLocationsByDate[date] += MILESTONE_WIDTH;
    });
  }

  const sortedMilestoneDates = Array.from(milestoneDates).sort();

  // todo: this is wrong
  const estimatedWidthOfPlot =
    PADDING + (sortedMilestoneDates.length + 1) * MILESTONE_WIDTH; // +1 for the materials
  if (estimatedWidthOfPlot > width) {
    temporalLayout.layoutError = "Not enough width to render the plot";
    return temporalLayout;
  }

  // Specical dates
  const firstPlannedMilestone = allMilestones.find(
    (m) => m.type === "assigned_sc"
  );
  const lastPlannedMilestone = allMilestones.find(
    (m) => m.type === "last_planned_milestone"
  );
  const finalDeadlineMilestone = allMilestones.find(
    (m) => m.type === "final_deadline"
  );
  const nextPlannedMilestone = allMilestones.find(
    (m) => m.type === "next_planned_milestone"
  );

  // calculate submittal track
  const firstMilestone = sortedMilestoneDates[0];
  const lastMilestone = sortedMilestoneDates[sortedMilestoneDates.length - 1];

  const firstMilestoneX = xLocationsByDate[firstMilestone] ?? 0;
  const lastMilestoneX = xLocationsByDate[lastMilestone] ?? 0;

  currentX = lastMilestoneX;

  temporalLayout.tracks.submittal = {
    x: firstMilestoneX,
    y: currentY,
    width: lastMilestoneX - firstMilestoneX,
    height: TRACKER_HEIGHT,
    labelX: firstMilestoneX,
    labelY: 0
  };

  // calculate float
  const milestoneBeforeDeadlineX =
    xLocationsByDate[
      lastPlannedMilestone?.date
        ? getNormalizedDate(lastPlannedMilestone.date)
        : ""
    ] ?? 0;
  const finalDeadlineX =
    xLocationsByDate[
      finalDeadlineMilestone?.date
        ? getNormalizedDate(finalDeadlineMilestone.date)
        : ""
    ] ?? 0;
  const floatWidth = finalDeadlineX - milestoneBeforeDeadlineX;
  const floatStartX =
    floatWidth < 0 ? finalDeadlineX : milestoneBeforeDeadlineX;

  temporalLayout.float = {
    numberOfDays: siCalculations.effectiveFloat,
    trackClassName: classNames(styles.floatRectangle, {
      [styles.floatRectangleExhausted]: siCalculations.effectiveFloat <= 0 // checks if the floats are exhausted, applies classname
    }),
    labelClassName: classNames(styles.floatLabel, {
      [styles.floatLabelExhausted]: siCalculations.effectiveFloat <= 0
    })
  };

  temporalLayout.tracks.float = {
    x: floatStartX,
    y: currentY,
    width: Math.abs(floatWidth),
    height: TRACKER_HEIGHT,
    labelX: floatStartX + Math.abs(floatWidth) / 2 + LABEL_OFFSET,
    labelY: currentY - 50
  };

  const xToday = xLocationsByDate.today ?? 0;

  // todo: account for missing next planned milestone
  const delayed = todayDate.diff(nextPlannedMilestone?.date, "days") > 0;
  const yetToStart = todayDate.diff(firstPlannedMilestone?.date, "days") < 0;

  const labelsByDate: { [key: string]: string[] } = {};
  allMilestones.forEach((milestone) => {
    labelsByDate[getNormalizedDate(milestone.date)] =
      labelsByDate[getNormalizedDate(milestone.date)] || [];
    labelsByDate[getNormalizedDate(milestone.date)].push(
      `${milestone.label} ${milestone.otherInfo}`
    );
  });

  temporalLayout.labelsByDate = labelsByDate;

  // calculate markers
  temporalLayout.markers = allMilestones
    .sort((m1, m2) => m1.date.diff(m2.date))
    .map((milestone) => {
      const markerClassName = classNames(styles.milestoneMarker, {
        [styles.milestoneMarkerLate]:
          milestone.type === "next_planned_milestone" && delayed,
        [styles.lastMilestoneMarker]: false
      });

      return {
        x:
          (xLocationsByDate[getNormalizedDate(milestone.date)] ?? 0) -
          MARKER_WIDTH / 2,
        y: currentY - (MARKER_HEIGHT - TRACKER_HEIGHT) / 2,
        width: MARKER_WIDTH,
        height: MARKER_HEIGHT,
        milestone,
        labelX: ["last_planned_milestone"].includes(milestone.type)
          ? -1
          : (xLocationsByDate[getNormalizedDate(milestone.date)] ?? 0) -
            MARKER_WIDTH / 2 +
            LABEL_OFFSET,
        labelY: currentY - (MARKER_HEIGHT - TRACKER_HEIGHT) / 2 + MARKER_HEIGHT,
        markerClassName,
        labelClassName: classNames(styles.milestoneLabel, {
          [styles.milestoneLabelLate]: false // todo: add logic
        })
      };
    });

  let todayStatusLabel = "";
  if (delayed) {
    todayStatusLabel = "Delayed";
  } else if (yetToStart) {
    todayStatusLabel = ""; // TODO: Fold this back in when we have better testing
    // todayStatusLabel = "Yet to start";
  } else {
    todayStatusLabel = "On time";
  }

  // calculate today
  temporalLayout.today = {
    coordinates: {
      x: xToday - TODAY_MARKER_WIDTH / 2,
      y: currentY + TODAY_MARKER_WIDTH / 2,
      width: TODAY_MARKER_WIDTH,
      height: TODAY_MARKER_WIDTH,
      labelX: xToday - TODAY_MARKER_WIDTH / 2 + LABEL_OFFSET,
      labelY: currentY - 50
    },
    labelClassName: classNames(styles.todayLabel, {
      [styles.todayLabelLate]: false // todo: add logic
    }),
    indicatorClassName: classNames(styles.todayIndicator, {
      [styles.todayIndicatorLate]: delayed
    }),
    label: todayStatusLabel
  };

  currentY += TRACKER_HEIGHT + 50;

  // check for clashes in the float label & today's label
  const todayLabelX = temporalLayout.today.coordinates.x;
  const floatLabelX = temporalLayout.tracks.float.labelX;
  const clash = Math.abs(todayLabelX - floatLabelX) < MILESTONE_WIDTH * 0.5;
  temporalLayout.float.labelClassName = classNames(styles.floatLabel);
  const flipOffset = temporalLayout.tracks.float.width / 2.5;
  temporalLayout.tracks.float.labelX -= clash ? flipOffset : 0;

  // post-submittal material tracks
  const postMilestoneTracks = (linkedMaterials ?? []).map(
    (linkedMaterial, i) => {
      const startDate = getNormalizedDate(linkedMaterial.start_date);
      const endDate = getNormalizedDate(linkedMaterial.end_date);
      const startX = xLocationsByDate[startDate] ?? currentX;
      const endX = xLocationsByDate[endDate] ?? currentX + MILESTONE_WIDTH + 10; // 10 is so that it doesn't look like it coincides with the next milestone

      const materialTrack = {
        x: startX,
        y: currentY + i * (TRACKER_HEIGHT + trackBottomMargin),
        width: endX - startX,
        height: TRACKER_HEIGHT,
        labelX: startX,
        labelY: currentY + i * (TRACKER_HEIGHT + trackBottomMargin),
        label: linkedMaterial.implicit
          ? `Material for ${primaryElementName}`
          : linkedMaterial.name,
        materialId: linkedMaterial.id,
        date: DateUtils.format(linkedMaterial.end_date)
      };
      return materialTrack;
    }
  );

  currentY += linkedMaterials.length * (TRACKER_HEIGHT + trackBottomMargin);
  if (linkedMaterials.length === 0) {
    currentY -= 20;
  }

  temporalLayout.preMilestoneTracks = preMilestoneTracks;
  temporalLayout.postMilestoneTracks = postMilestoneTracks;
  temporalLayout.milestoneWidth = MILESTONE_WIDTH;
  temporalLayout.height = currentY + PADDING;

  return temporalLayout;
}

type VisulisationLayout = {
  layoutError: string;

  primaryTrack: {
    originX: number;
    originY: number;
    width: number;
    height: number;
  };

  preTracks: {
    originX: number;
    originY: number;
    width: number;
    height: number;
  }[];

  postTracks: {
    originX: number;
    originY: number;
    width: number;
    height: number;
    label: string;
    endLabel: string;
    url: string | undefined;
  }[];

  milestoneMarkers: {
    originX: number;
    originY: number;
    width: number;
    height: number;
    className: string;
  }[];

  specialLabels: {
    originX: number;
    originY: number;
    width: number;
    height: number;
    label: string;
    date: string;
    className: string;
  }[];

  labels: {
    originX: number;
    originY: number;
    width: number;
    height: number;
    label: string;
    date: string;
    className: string;
  }[];

  today: {
    originX: number;
    originY: number;
    width: number;
    height: number;
    className: string;
    label: string;
    date: string;
    labelWidth: number;
  };

  todayToNextPlannedMilestone?: {
    originX: number;
    width: number;
  };

  float: {
    originX: number;
    originY: number;
    width: number;
    height: number;
    className: string;
    label: string;
    days: number;
  };

  width: number;
  height: number;
};

const primaryTrackGap = 160;

export function computeVisulisationLayout(
  projectId: string,
  primaryElementName: String,
  _xLocationsByDate: { [key: string]: number },
  milestones: Milestone[],
  siCalculations: TimelineSiCalculations,
  todayDate: Moment,
  _linkedMaterials?: GoverningMaterial[]
): VisulisationLayout {
  // Limitations:
  // This visulisation does not handle the pre-primary tracks (i.e. linked submittals)

  const visulisationLayout: VisulisationLayout = {
    layoutError: "",
    primaryTrack: {
      originX: 0,
      originY: 0,
      width: 0,
      height: 0
    },
    preTracks: [],
    postTracks: [],
    milestoneMarkers: [],
    specialLabels: [],
    labels: [],
    today: {
      originX: 0,
      originY: 0,
      width: 0,
      height: 0,
      className: "",
      date: "",
      label: "Today",
      labelWidth: 0
    },
    float: {
      originX: 0,
      originY: 0,
      width: 0,
      height: 0,
      className: "",
      label: "",
      days: 0
    },
    width: 0,
    height: 0
  };

  // move all the x dates position by an offset to avoid borders being cutoff
  const xLocationsByDate = Object.keys(_xLocationsByDate).reduce(
    (acc, date) => {
      acc[date] = _xLocationsByDate[date] + 10;
      return acc;
    },
    {} as { [key: string]: number }
  );
  const yPositions = {
    topLabels: 10,
    primaryTrack: 50,
    milestoneTracks: (_linkedMaterials ?? []).map(
      (_, i) => i * 50 + primaryTrackGap
    )
  };

  const milestoneDates = new Set<string>(
    milestones.map((m) => m.normalizedDate)
  );

  // Specical dates
  const specialMilestoneTypes = [
    "last_planned_milestone",
    "final_deadline",
    "next_planned_milestone"
  ];
  const firstPlannedMilestone = milestones.find(
    (m) => m.type === "assigned_sc"
  );
  const lastPlannedMilestone = milestones.find(
    (m) => m.type === "last_planned_milestone"
  );
  const finalDeadlineMilestone = milestones.find(
    (m) => m.type === "final_deadline"
  );
  const nextPlannedMilestone = milestones.find(
    (m) => m.type === "next_planned_milestone"
  );

  if (
    (firstPlannedMilestone?.normalizedDate ?? "").localeCompare(
      finalDeadlineMilestone?.normalizedDate ?? ""
    ) > 0
  ) {
    visulisationLayout.layoutError = "Final deadline is before first milestone";
    return visulisationLayout;
  }

  const firstMilestone = firstPlannedMilestone?.normalizedDate;
  const lastMilestone = lastPlannedMilestone?.normalizedDate;
  if (!firstMilestone || !lastMilestone) {
    visulisationLayout.layoutError = "No milestones found";
    return visulisationLayout;
  }
  const nextMilestone = nextPlannedMilestone?.normalizedDate;

  const firstMilestoneX = xLocationsByDate[firstMilestone] ?? -1;
  const lastMilestoneX = xLocationsByDate[lastMilestone] ?? -1;
  if (firstMilestoneX === -1 || lastMilestoneX === -1) {
    visulisationLayout.layoutError = "No milestone dates found";
    return visulisationLayout;
  }

  let visualisationWidth = 0;

  const isSubmittalLate =
    getNormalizedDate(todayDate).localeCompare(nextMilestone ?? "") > 0;
  const lateDays = siCalculations.currentDelay;

  // submittal track
  visulisationLayout.primaryTrack = {
    originX: firstMilestoneX,
    originY: yPositions.primaryTrack,
    width: lastMilestoneX - firstMilestoneX,
    height: 20 // todo: make it const
  };

  // material tracks
  visulisationLayout.postTracks = (_linkedMaterials ?? []).map(
    (linkedMaterial, index) => {
      const startDate = getNormalizedDate(linkedMaterial.start_date);
      const endDate = getNormalizedDate(linkedMaterial.end_date);
      const startX = xLocationsByDate[startDate] ?? -1;
      const endX = xLocationsByDate[endDate] ?? -1;
      const label = linkedMaterial.implicit
        ? `Material for ${primaryElementName}`
        : linkedMaterial.name;

      // Show the last milestone name as ROJ
      const endLabel = `${linkedMaterial?.roj_label} : ${DateUtils.format(
        linkedMaterial.end_date
      )}`;

      if (endX > visualisationWidth) {
        visualisationWidth = endX;
      }

      return {
        originX: startX,
        originY: yPositions.milestoneTracks[index],
        width: Math.abs(endX - startX),
        height: 20, // todo: make it const,
        label,
        endLabel,
        url: linkedMaterial.implicit
          ? undefined
          : `/project/${projectId}/materials/${linkedMaterial.id}`
      };
    }
  );

  // float
  const finalDeadlineMilestoneX =
    xLocationsByDate[finalDeadlineMilestone?.normalizedDate ?? ""];
  const lastPlannedMilestoneX =
    xLocationsByDate[lastPlannedMilestone?.normalizedDate ?? ""];
  if (finalDeadlineMilestoneX + MILESTONE_WIDTH > visualisationWidth) {
    visualisationWidth = finalDeadlineMilestoneX + MILESTONE_WIDTH;
  }
  if (lastPlannedMilestoneX + MILESTONE_WIDTH > visualisationWidth) {
    visualisationWidth = lastPlannedMilestoneX + MILESTONE_WIDTH;
  }

  const floatYEnd =
    yPositions.milestoneTracks[yPositions.milestoneTracks.length - 1] ??
    primaryTrackGap;
  visulisationLayout.float = {
    originX: Math.min(finalDeadlineMilestoneX, lastPlannedMilestoneX),
    originY: yPositions.primaryTrack - 20,
    width: Math.abs(finalDeadlineMilestoneX - lastPlannedMilestoneX),
    height: floatYEnd,
    className: "",
    label: "",
    days: siCalculations.effectiveFloat
  };

  // milestone markers
  visulisationLayout.milestoneMarkers = milestones
    .sort((m1, m2) => m1.date.diff(m2.date))
    .map((milestone) => {
      return {
        originX: xLocationsByDate[milestone.normalizedDate] ?? -1,
        originY: yPositions.primaryTrack - 5,
        width: 1,
        height: 30,
        className:
          milestone.type === "next_planned_milestone" && isSubmittalLate
            ? styles.milestoneMarkerLate
            : styles.milestoneMarker
      };
    });

  // Get the milestone object from the list of milestones for given special milestone
  /* 
  const findMilestoneByKey = (key: any): any => {
    console.log("key =>", key);
    milestones.find((m) => {
      console.log("m.type =>", m.type, key);
      return m.type === key ? m : null;
    });
  };
  */
  const specialLabelsByDate: { [key: string]: string } = {};
  if (firstPlannedMilestone) {
    specialLabelsByDate[firstPlannedMilestone.normalizedDate] =
      // findMilestoneByKey(MilestoneTypeKeys.firstPlannedMilestone) ||
      "First planned milestone";
  }
  if (nextPlannedMilestone) {
    specialLabelsByDate[nextPlannedMilestone.normalizedDate] =
      // findMilestoneByKey(MilestoneTypeKeys.nextPlannedMilestone) ||
      "Next planned milestone";
    if (isSubmittalLate) {
      specialLabelsByDate[nextPlannedMilestone.normalizedDate] = `${
        // findMilestoneByKey(MilestoneTypeKeys.nextPlannedMilestone) ||
        "Next planned milestone"
      } (Delayed by ${lateDays} ${lateDays === 1 ? "day" : "days"})`;
    }
  }
  if (lastPlannedMilestone) {
    specialLabelsByDate[lastPlannedMilestone.normalizedDate] =
      // findMilestoneByKey(MilestoneTypeKeys.lastPlannedMilestone) ||
      "Last planned milestone";
  }
  if (finalDeadlineMilestone) {
    specialLabelsByDate[finalDeadlineMilestone.normalizedDate] =
      // findMilestoneByKey(MilestoneTypeKeys.finalDeadline) ||
      "Final deadline";
  }

  Array.from(milestoneDates).forEach((milestoneDate) => {
    const milestone = milestones.find(
      (m) =>
        m.normalizedDate === milestoneDate &&
        specialMilestoneTypes.includes(m.type)
    );
    if (milestone) {
      visulisationLayout.specialLabels.push({
        originX: xLocationsByDate[milestone.normalizedDate] ?? -1,
        originY: yPositions.primaryTrack - 40,
        width: MILESTONE_WIDTH,
        height: 40,
        className: "",
        date: DateUtils.format(milestone.date),
        label: specialLabelsByDate[milestone.normalizedDate] ?? ""
      });
    }
  });
  // combine all labels
  const labelsByDate: { [key: string]: string[] } = {};
  milestones.forEach((milestone) => {
    labelsByDate[milestone.normalizedDate] =
      labelsByDate[milestone.normalizedDate] || [];
    const isSpecialMilestone = specialMilestoneTypes.includes(milestone.type);
    if (!isSpecialMilestone) {
      labelsByDate[milestone.normalizedDate].push(milestone.label);
    } else if (milestone.otherInfo)
      labelsByDate[milestone.normalizedDate].push(milestone.otherInfo);
  });

  // milestone labels
  Array.from(milestoneDates).forEach((milestoneDate) => {
    visulisationLayout.labels.push({
      originX: xLocationsByDate[milestoneDate] ?? -1,
      originY: yPositions.primaryTrack + 25,
      width: MILESTONE_WIDTH - 20,
      height: 120,
      className: "",
      date: DateUtils.format(getMomentFromNormalizedDate(milestoneDate)),
      label: (labelsByDate[milestoneDate] ?? []).join(", ")
    });
  });

  // today
  const todayClassName = classNames(styles.todayIndicator, {
    [styles.todayIndicatorLate]: isSubmittalLate
  });
  visulisationLayout.today = {
    originX: xLocationsByDate[getNormalizedDate(todayDate)] ?? -1,
    originY: yPositions.primaryTrack + 10,
    width: 8,
    height: 8,
    className: todayClassName,
    label: "Today",
    labelWidth: MILESTONE_WIDTH,
    date: DateUtils.format(todayDate)
  };
  if (visulisationLayout.today.originX + MILESTONE_WIDTH > visualisationWidth) {
    visualisationWidth = visulisationLayout.today.originX + MILESTONE_WIDTH;
  }

  // today to next planned milestone
  if (isSubmittalLate) {
    const nextPlannedMilestoneX =
      xLocationsByDate[nextPlannedMilestone?.normalizedDate ?? ""] ?? 0;
    const todayX = xLocationsByDate[getNormalizedDate(todayDate)] ?? 0;
    visulisationLayout.todayToNextPlannedMilestone = {
      originX: nextPlannedMilestoneX,
      width: todayX - nextPlannedMilestoneX
    };
  }

  visulisationLayout.width = visualisationWidth + 1;
  visulisationLayout.height = floatYEnd + 40;
  return visulisationLayout;
}
