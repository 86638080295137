export default function RedirectIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4.5V6H3.75V14.25H12V10.5H13.5V15C13.5 15.4142 13.1642 15.75 12.75 15.75H3C2.58579 15.75 2.25 15.4142 2.25 15V5.25C2.25 4.83579 2.58579 4.5 3 4.5H7.5ZM15.75 2.25V8.25H14.25L14.2499 4.80975L8.40532 10.6553L7.34467 9.59468L13.1887 3.75H9.75V2.25H15.75Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}
