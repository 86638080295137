import { createContext } from "react";

type TSystems = string;

export type TProjectSharedState = {
  activeView: "list" | "details" | "configure-initial";
  system: TSystems;
  subscriptionIntegrationConfigs: any;
  projectIntegrationConfigs: any;
  systemIntegrationConfigs: any;
  availableIntegrations: any;
  additionalData: any;
  authData: any;
};

type Taction = {
  type: string;
  payload?: any;
};

export const projectInitialState: TProjectSharedState = {
  activeView: "list",
  system: "",
  subscriptionIntegrationConfigs: null,
  projectIntegrationConfigs: null,
  systemIntegrationConfigs: null,
  availableIntegrations: null,
  additionalData: null,
  authData: null
};

export const ProjectIntegrationsTabSharedContext = createContext<{
  sharedState: TProjectSharedState;
  dispatch: any;
}>({
  sharedState: projectInitialState,
  dispatch: () => null
});

export const ProjectIntegrationsTabStateReducer = (
  state: any,
  action: Taction
): TProjectSharedState => {
  switch (action.type) {
    case "SET_SHARED_STATE": {
      return { ...state, ...action.payload };
    }
    case "SET_ACTIVE_LIST_VIEW": {
      return {
        ...state,
        activeView: "list",
        additionalData: null,
        authData: null,
        system: ""
      };
    }
    default:
      return state;
  }
};
