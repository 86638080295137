/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, message, Modal, Spin, Upload } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import { AttachmentDoc } from "models/submittal-log";
import { useContext, Fragment } from "react";
import { noAuthClient } from "services/axios";
import { generateS3URL } from "services/file-mgmt";
import "./document-section.css";
import classNames from "classnames";
import { DateUtils } from "utils/dateutils";

type DocumentSectionProps = {
  documents: Array<AttachmentDoc>;
  referenceDocuments: Array<AttachmentDoc>;
  RemoveAttachment: Function;
  selectedDocument: AttachmentDoc | undefined;
  setSelectedDocument: any;
  disabled: boolean;
  findLatestAttachmentVersion: (doc: AttachmentDoc) => AttachmentDoc;
};
function DocumentSection({
  documents,
  referenceDocuments,
  RemoveAttachment,
  selectedDocument,
  setSelectedDocument,
  disabled,
  findLatestAttachmentVersion
}: DocumentSectionProps) {
  const { tokenRetrievalState } = useContext(ProjectContext);
  const [modal, contextHolder] = Modal.useModal();

  const removeDocumentConfirmModel = (
    id: string,
    versionNumber: number,
    parentFileName: string,
    versions: AttachmentDoc[],
    isMarkup = false
  ) => {
    if (versionNumber === 0) {
      if (versions.length > 0) {
        // eslint-disable-next-line no-nested-ternary
        const content = isMarkup
          ? `Do you want to remove markup of V${
              versionNumber + 1
            } of ${parentFileName} document?`
          : versionNumber === 0
          ? `You are removing the first version of the document. This will remove all versions of ${parentFileName}.`
          : `Do you want to remove V${
              versionNumber + 1
            } of ${parentFileName} document?`;
        modal.confirm({
          title: "Remove Document",
          icon: <ExclamationCircleOutlined />,
          content,
          onOk() {
            RemoveAttachment(id);
          },
          okText: "Remove"
        });
      } else {
        RemoveAttachment(id);
      }
    } else if (versionNumber > 0) {
      // eslint-disable-next-line no-nested-ternary
      const content = isMarkup
        ? `Do you want to remove markup of V${
            versionNumber + 1
          } of ${parentFileName} document?`
        : `Do you want to remove V${
            versionNumber + 1
          } of ${parentFileName} document?`;
      modal.confirm({
        title: "Remove Document",
        icon: <ExclamationCircleOutlined />,
        content,
        onOk() {
          RemoveAttachment(id);
        },
        okText: "Remove"
      });
    }
  };

  const UploadAttachmentVersion = async (
    file: any,
    parentDoc: AttachmentDoc
  ) => {
    const signedUrlRespose: any = await generateS3URL(
      {
        feature: "SUBMITTALS",
        feature_id: parentDoc.submittal_id,
        version_of: parentDoc.id,
        file_name: file.name
      },
      tokenRetrievalState.token
    );

    if (signedUrlRespose.error) {
      message.error(signedUrlRespose.error.msg);
      return null;
    }

    const fileUploadResponse: any = await noAuthClient.put(
      `${signedUrlRespose.data.success.url}`,
      file,
      {
        onUploadProgress: (progressEvent) => {
          const progressCount = Math.ceil(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(progressCount);
          // if (setProgress) setProgress(progressCount);
        }
      }
    );
    if (fileUploadResponse.error) {
      message.error(signedUrlRespose.error.msg);
      return null;
      // reject(new Error(fileUploadResponse.error));
    }
    return fileUploadResponse;
  };

  function DocumentRow(props: {
    doc: AttachmentDoc;
    fileName: string;
    isMarkup?: boolean;
  }) {
    const { doc, fileName, isMarkup } = props;

    const onClickDelete = () => {
      removeDocumentConfirmModel(
        doc.id,
        doc.version,
        fileName,
        doc.versions,
        isMarkup
      );
    };

    const onClickSelect = () => {
      setSelectedDocument(doc);
    };

    if (!doc.uploaded) {
      return (
        <Button
          className="text-left italic px-2 border-0 rounded-none"
          block
          disabled
        >
          <Spin size="small" className="mr-2" /> Uploading file…
        </Button>
      );
    }

    const isSelected = selectedDocument && selectedDocument.id === doc.id;
    const buttonClassname = classNames(
      "text-left",
      "shadow-none",
      "px-2",
      "rounded-none",
      {
        "border-0 hover:font-semibold": !isSelected,
        "border-1 border-gray-600 font-semibold bg-gray-50": isSelected
      }
    );

    return (
      <div className="flex">
        <div>
          <Button
            onClick={onClickDelete}
            disabled={disabled}
            className="pl-0 pr-2 border-0"
          >
            <DeleteOutlined className="hover:text-red-500" />
          </Button>
        </div>
        <div className="flex-grow">
          <Button onClick={onClickSelect} block className={buttonClassname}>
            {`V${doc.version + 1} ${isMarkup ? "Markup," : ""} ${
              doc.created_by_user.first_name
            } ${doc.created_by_user.last_name}, ${DateUtils.format(
              doc.created_at
            )}`}
          </Button>
        </div>
      </div>
    );
  }

  function RefDocRow(props: { doc: AttachmentDoc; fileName: string }) {
    const { doc, fileName } = props;
    const referenceDoc = referenceDocuments.find(
      (d) => d.reference_id === doc.id
    );
    return referenceDoc ? (
      <DocumentRow doc={referenceDoc} fileName={fileName} isMarkup />
    ) : null;
  }

  if (!documents || documents.length === 0) {
    return (
      <div className="text-gray-500 text-center mb-2 p-2 border border-solid border-gray-200">
        No attachments added.
      </div>
    );
  }

  return (
    <div>
      {documents.map((data: AttachmentDoc) => {
        const { file_name, id } = data;
        return (
          <Card
            key={id}
            size="small"
            className="mb-2"
            title={
              <span
                role="button"
                tabIndex={0}
                className="font-semibold cursor-pointer"
                onClick={() => {
                  const latest = findLatestAttachmentVersion(data);
                  setSelectedDocument(latest);
                }}
                onKeyDown={() => {
                  const latest = findLatestAttachmentVersion(data);
                  setSelectedDocument(latest);
                }}
              >
                {file_name}
              </span>
            }
            extra={
              <Upload
                showUploadList={false}
                customRequest={({ file }) =>
                  UploadAttachmentVersion(file, data)
                }
                disabled={disabled}
              >
                <Button
                  block
                  size="small"
                  className="text-xs"
                  disabled={disabled}
                >
                  + New version
                </Button>
              </Upload>
            }
          >
            <DocumentRow doc={data} fileName={file_name} />
            <RefDocRow doc={data} fileName={file_name} />
            {data.versions.map((version) => (
              <Fragment key={version.reference_id}>
                <DocumentRow doc={version} fileName={file_name} />
                <RefDocRow doc={version} fileName={file_name} />
              </Fragment>
            ))}
          </Card>
        );
      })}
      {contextHolder}
    </div>
  );
}
export default DocumentSection;
