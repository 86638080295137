export default function AutodeskLogoIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_17_148"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="18"
      >
        <path d="M18.5625 0H0V18H18.5625V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_17_148)">
        <mask
          id="mask1_17_148"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="106"
          height="18"
        >
          <path d="M105.945 0H0V18H105.945V0Z" fill="white" />
        </mask>
        <g mask="url(#mask1_17_148)">
          <path
            d="M3.5051 17.9461C3.00437 17.9461 2.74415 17.7541 2.49437 17.3079L0.212654 13.007C0.0554155 12.6989 0.0529099 12.5961 0.0529099 12.3741L3.63891 12.5349L4.45967 13.3694H11.6527L5.68027 17.1315C5.38916 17.3066 4.28196 17.9461 3.5051 17.9461Z"
            fill="url(#paint0_linear_17_148)"
          />
          <path
            d="M4.46296 13.3694H1.36111C0.424243 13.3694 0.0529099 12.8711 0.0529099 12.3741C0.0529099 12.0596 0.152439 11.8881 0.290052 11.5385L4.81043 0.565599L4.85901 0.469311L4.92158 0.370516L5.05168 0.24158L5.2427 0.137761L5.51935 0.0758057L5.99528 0.0883644L9.13648 1.27558L8.07431 4.68149L4.46296 13.3694Z"
            fill="url(#paint1_linear_17_148)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5709 17.9455L6.43579 0.769875C6.31289 0.477419 6.11585 0.10008 5.60582 0.10008C5.15012 0.10008 4.93302 0.324449 4.81042 0.565592C4.87382 0.391691 5.06565 0.0538635 5.62163 0.0538635H10.5461C10.9999 0.0538635 11.2176 0.325574 11.3169 0.574346L18.5323 17.9455H13.5709Z"
            fill="url(#paint2_linear_17_148)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17_148"
          x1="1.55128"
          y1="15.5376"
          x2="11.6287"
          y2="13.563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2B7A77" />
          <stop offset="0.510487" stopColor="#4EBAA9" />
          <stop offset="1" stopColor="#5CC2B1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17_148"
          x1="6.73504"
          y1="1.4559"
          x2="1.59766"
          y2="13.3212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236736" />
          <stop offset="0.4928" stopColor="#6BA443" />
          <stop offset="1" stopColor="#86C146" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17_148"
          x1="8.66476"
          y1="0.10928"
          x2="16.2161"
          y2="17.7946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#29A5E3" />
          <stop offset="0.515493" stopColor="#356EB9" />
          <stop offset="1" stopColor="#394DA5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
