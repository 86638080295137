import { message } from "antd";
import { useState, useEffect } from "react";
import { getAccessToken, logout } from "services/auth";
import { getGraphQLClient } from "services/graphQL";

export const useGetGQLClientForAdmin = () => {
  const [graphQlInstance, setGraphQlInstance] = useState<any>();
  const [gqlClient, setGqlClient] = useState(graphQlInstance?.GQLClient);
  const [wsLink, setwsLink] = useState<any>(graphQlInstance?.wsLink);
  const token: any = getAccessToken();

  useEffect(() => {
    const createSubscriptionLoginToken = async () => {
      try {
        const graphQlIns = getGraphQLClient({ token });
        setGraphQlInstance(graphQlIns);
        setGqlClient(graphQlIns.GQLClient);
        setwsLink(graphQlIns.wsLink);
      } catch (err) {
        console.error(err);
        message.error("Session expired, redirecting to login");
        logout();
      }
    };

    createSubscriptionLoginToken();
  }, [token]);

  return { gqlClient, wsLink };
};
