import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useState } from "react";
import { QUERY_GET_PROJECT_SPECS } from "services/graphQL/queries";
import { Button, Divider, Select } from "antd";

function SelectFeatureSpecSections(props: {
  onCreateSpecSectionClick?: any;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const { onCreateSpecSectionClick, selectedIds, setSelectedIds } = props;

  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);
  const [open, setOpen] = useState(false);

  const { data: specSections, refetch: refetchSpecs } = useCIQQuery<{
    spec_sections: Array<{
      title: string;
      id: string;
      section_number: string;
    }>;
  }>(QUERY_GET_PROJECT_SPECS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const onChange = (values: string[]) => {
    setSelectedIds(values);
  };

  useEffect(() => {
    const hasInsertEvent = eventLogs.some(
      (log) =>
        log.data_source === "spec_sections" && log.change_type === "insert"
    );
    if (hasInsertEvent) {
      refetchSpecs();
    }
  }, [eventLogs, refetchSpecs]);

  const dropdownRender = (menu: any) => {
    return (
      <>
        {menu}
        <Divider className="my-1" style={{ margin: "8px 0" }} />
        <div className="w-full px-1">
          <Button
            className="rounded-none  bg-[#3b3b3b3b] hover:bg-[#3b3b3b5c] w-full"
            onClick={() => {
              setOpen(false);
              if (onCreateSpecSectionClick) {
                onCreateSpecSectionClick(true);
              }
            }}
          >
            Add New Spec Section
          </Button>
        </div>
      </>
    );
  };

  return (
    <Select
      value={selectedIds}
      showArrow
      showSearch
      mode="multiple"
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
      options={specSections?.spec_sections.map((spec) => ({
        value: spec.id,
        label: spec.title
          ? `${spec.section_number} - ${spec.title}`
          : spec.section_number
      }))}
      onChange={onChange}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      dropdownRender={dropdownRender}
    />
  );
}

export default SelectFeatureSpecSections;
