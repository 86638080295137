/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Dropdown, Menu } from "antd";
import "./account-menu.css";
import { UserAvatar, UserAvatarWithDetails } from "components/user-avatar";
import { getUser, logout } from "services/auth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import LogoutIcon from "components/svg-icons/logout-icon";
import { useQuery } from "@apollo/client";
import { QUERY_USER_PROFILE_INFO } from "services/graphQL/queries";

function AccountMenu() {
  // const dispatch = useAppDispatch();
  const [currentUser]: [any, any] = useState(getUser());
  const [userInfo, setUserInfo] = useState<any>();
  // const { data: userData } = useUserProfileInfo(currentUser.email);
  const { data: userData } = useQuery(QUERY_USER_PROFILE_INFO, {
    variables: { where: { user: { email: { _eq: currentUser.email } } } }
  });
  const history = useHistory();

  useEffect(() => {
    if (!userData) return;
    if (userData.subscription_users.length > 0) {
      setUserInfo(userData.subscription_users[0]);
    }
  }, [userData]);

  const changePassword = () => {
    history.push("/change_password");
  };

  const menu = (
    <Menu className="p-0 space-y-1">
      <Menu.Item key="profile" className="px-2">
        <UserAvatarWithDetails
          showAvatar={false}
          fullName={
            userInfo
              ? `${userInfo?.user?.first_name} ${userInfo?.user?.last_name}`
              : undefined
          }
          email={currentUser.email}
          subscription={userInfo?.subscription?.name || ""}
        />
      </Menu.Item>
      <Menu.Divider className="bg-[#3B3B3B80]" />
      <Menu.Item key="change-password" className="h-6">
        <div
          className="h-6 w-full ml-2 text-[10px] font-normal text-[#3B3B3BCC]"
          onClick={changePassword}
        >
          Change Password
        </div>
      </Menu.Item>
      <Menu.Item key="sign-out" className="p-0">
        <div
          onClick={() => logout()}
          className="flex w-full h-6 items-center justify-between  bg-[#E8E8E8] px-4 "
        >
          <div className="flex h-full items-center font-semibold text-xs text-[#3B3B3B]">
            Logout
          </div>
          <LogoutIcon />
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown arrow placement="bottomRight" overlay={menu} trigger={["click"]}>
      <div data-cy="account" className="accMenuContainer">
        <UserAvatar
          url=""
          isRect={false}
          fullName={
            userInfo
              ? `${userInfo?.user?.first_name} ${userInfo?.user?.last_name}`
              : ""
          }
        />
      </div>
    </Dropdown>
  );
}

export default AccountMenu;
