import { DateUtils } from "utils/dateutils";
import { ProjectSettingType } from "context/ProjectProvider";
import { TActivityTask, TLinkedMaterial, TLinkedSubmittal } from "../../models";

export const valueGetterMaterialId = ({ data }: { data: TLinkedMaterial }) =>
  data?.material_id ? data.material_id : "";

export const valueGetterMaterialSpecSection = ({
  data
}: {
  data: TLinkedMaterial;
}) => {
  return data?.spec_section_no && data?.spec_section_name
    ? `${data?.spec_section_no} - ${data?.spec_section_name}`
    : "";
};

export const valueGetterMaterialName = ({
  data
}: {
  data: TLinkedMaterial;
}) => {
  if (data) {
    if (data.material_id) return data.name;
    if (data.linked_submittals.length) {
      const { submittal_id: submittalId } = data.linked_submittals[0].submittal;
      return `Material for Submittal ${submittalId}`;
    }
  }
  return "";
};

export const valueGetterMaterialGcRepresentative = ({
  data
}: {
  data: TLinkedMaterial;
}) =>
  data?.gc_representative_user
    ? `${data?.gc_representative_user.first_name} ${data?.gc_representative_user.last_name}`
    : "";

export const valueGetterMaterialResponsibleContractor = ({
  data
}: {
  data: TLinkedMaterial;
}) => data?.responsible_contractor_name;

export const valueGetterMaterialDueDate = ({
  data
}: {
  data: TLinkedMaterial;
}) =>
  data?.date_block_materials?.length
    ? DateUtils.format(data?.date_block_materials[0]?.due_date)
    : "";

export const valueGetterMaterialRisk = ({ data }: { data: TLinkedMaterial }) =>
  data?.date_block_materials?.length
    ? data?.date_block_materials[0].risk_level
    : "";

type TSubmittalGetterProp = {
  data: TLinkedSubmittal;
  context: { projectDetails: ProjectSettingType | undefined };
};

export const valueGetterSubmittalId = ({
  data,
  context
}: TSubmittalGetterProp) => {
  if (!data) return "";
  const { projectDetails } = context;
  return projectDetails?.spec_section && data.spec_no
    ? `${data.spec_no} - ${data.submittal_id}`
    : `${data.submittal_id}`;
};

export const valueGetterSubmittalSpecSection = ({
  data
}: TSubmittalGetterProp) =>
  data?.spec_no ? `${data?.spec_no} - ${data?.spec_name}` : "";

export const valueGetterSubmittalBallInCourt = ({
  data
}: TSubmittalGetterProp) =>
  data?.submittal_assignee
    ? `${data?.submittal_assignee.first_name} ${data?.submittal_assignee.last_name}`
    : "";

export const valueGetterSubmittalDueDate = ({ data }: TSubmittalGetterProp) =>
  data?.date_block_submittals?.length
    ? DateUtils.format(data?.date_block_submittals[0]?.due_date)
    : "";

export const valueGetterSubmittalRisk = ({ data }: TSubmittalGetterProp) =>
  data?.date_block_submittals?.length
    ? data?.date_block_submittals[0].risk_level
    : "";

export const getActivityValueGetterData = (data: TActivityTask) => [
  data.source_task_id,
  data.text,
  DateUtils.format(data.start_date),
  DateUtils.format(data.end_date),
  data.duration / 8
];

export const getLinkedMaterialGetterData = (props: {
  data: TLinkedMaterial;
}) => [
  "",
  valueGetterMaterialId(props),
  valueGetterMaterialSpecSection(props),
  valueGetterMaterialName(props),
  props.data.status,
  valueGetterMaterialGcRepresentative(props),
  valueGetterMaterialResponsibleContractor(props),
  valueGetterMaterialDueDate(props),
  valueGetterMaterialRisk(props)
];

export const getLinkedSubmittalGetterData = (props: {
  data: TLinkedSubmittal;
  context: { projectDetails: ProjectSettingType | undefined };
}) => [
  "",
  valueGetterSubmittalId(props),
  valueGetterSubmittalSpecSection(props),
  props.data.title,
  props.data.workflow_status,
  valueGetterSubmittalBallInCourt(props),
  valueGetterSubmittalDueDate(props),
  valueGetterSubmittalRisk(props)
];
