import { createContext } from "react";

type TSystems = string;

export type TSharedState = {
  activeView: "list" | "details" | "configure-initial";
  system: TSystems;
  integrationConfigs: any;
  systemIntegrationConfigs: any;
  availableIntegrations: any;
  additionalData: any;
  authData: any;
};

type Taction = {
  type: string;
  payload?: any;
};

export const initialState: TSharedState = {
  activeView: "list",
  system: "",
  integrationConfigs: null,
  systemIntegrationConfigs: null,
  availableIntegrations: null,
  additionalData: null,
  authData: null
};

export const IntegrationsTabSharedContext = createContext<{
  sharedState: TSharedState;
  dispatch: any;
}>({
  sharedState: initialState,
  dispatch: () => null
});

export const integrationsTabStateReducer = (
  state: any,
  action: Taction
): TSharedState => {
  switch (action.type) {
    case "SET_SHARED_STATE": {
      return { ...state, ...action.payload };
    }
    case "SET_ACTIVE_LIST_VIEW": {
      return {
        ...state,
        activeView: "list",
        additionalData: null,
        authData: null,
        system: ""
      };
    }
    default:
      return state;
  }
};
