import { Button, Divider, Form, Modal, Select, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import { SUBSCRIPTION_COMPANY_LIST } from "services/graphQL/subscriptions";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { QUERY_TRADE_USER_TYPE } from "services/graphQL/queries";
import { useContext, useMemo, useState } from "react";
import { MUTATION_INSERT_COMPANY_TO_PROJECT } from "services/graphQL/mutations";
import { ProjectContext } from "context/ProjectProvider";
import { useParams } from "react-router";
import { v4 as uuidV4 } from "uuid";
import ClearIcon from "components/svg-icons/clear-icon";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import { EUserTypes, ErrorMessages } from "../../constants";
import "./invite-project-companies.scss";

type Props = {
  showAddProjectCompanyModel: boolean;
  setDrawerVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

function InviteProjectCompanies(props: Props) {
  const { setDrawerVisibility, showAddProjectCompanyModel } = props;
  const { projectId } = useParams() as any;
  const [form] = Form.useForm();
  const companiesAddded = Form.useWatch("companies", form);

  const [responseLoading, setResponseLoading] = useState(false);
  const { gqlClientForProject } = useContext(ProjectContext);
  const { data: companyData, loading: companyDataLoading } = useSubscription(
    SUBSCRIPTION_COMPANY_LIST,
    {
      variables: {
        where: {
          _not: { project_vendors: { project_id: { _eq: projectId } } }
        }
      },
      shouldResubscribe: true,
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const { data: companyTypes, loading: loadingUserType } = useQuery(
    QUERY_TRADE_USER_TYPE
  );

  const [addCompanyToProject] = useMutation<any>(
    MUTATION_INSERT_COMPANY_TO_PROJECT,
    {
      client: gqlClientForProject
    }
  );
  const onSendInvite = async ({ companies }: { companies: Array<any> }) => {
    setResponseLoading(true);
    const companiesList = companies.map(({ name, company_type }: any) => {
      return { name, company_type };
    });
    const companyResponse = await addCompanyToProject({
      variables: { companyData: companiesList, projectId }
    });
    setResponseLoading(false);
    if (companyResponse.data) {
      message.success(ErrorMessages.CompanyAddedSuccessMessage);
      setDrawerVisibility(false);
    } else {
      message.error(ErrorMessages.CompanyAddedFailureMessage);
      console.error(companyResponse.errors);
    }
  };

  const filteredCompanyTypes = useMemo(() => {
    return companyTypes?.user_types?.filter((companyType: any) => {
      if (EUserTypes[companyType.id]) return true;
      return false;
    });
  }, [companyTypes]);

  return (
    <Modal
      className="invite-project-companies"
      title="ADD COMPANY"
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showAddProjectCompanyModel}
      onCancel={() => {
        setDrawerVisibility(false);
      }}
      destroyOnClose
    >
      <Form
        className="h-full overflow-y-auto"
        preserve={false}
        form={form}
        scrollToFirstError
        layout="vertical"
        onFinish={onSendInvite}
        initialValues={{
          companies: [{ key: uuidV4() }]
        }}
      >
        <Form.List name="companies">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field) => (
                <div
                  key={field.key}
                  className="flex justify-between space-x-1 px-2"
                >
                  <FormItem
                    {...field}
                    name={[field.name, "name"]}
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: ErrorMessages.CompanyNameReqd
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) return Promise.resolve();
                          const fieldValues = getFieldValue("companies");
                          if (
                            fieldValues.filter((d: any) => d.name === value)
                              .length !== 1
                          ) {
                            return Promise.reject(
                              new Error("Duplicate values are not allowed.")
                            );
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                    className="w-full"
                  >
                    <Select
                      className={
                        fields.length > 1
                          ? "max-w-[230px] text-ellipsis"
                          : "max-w-[245px] text-ellipsis"
                      }
                      showSearch
                      placeholder="Search or select a company"
                      loading={companyDataLoading}
                      filterOption={(input, option: any) =>
                        option.value
                          .toLowerCase()
                          .includes(input.toString().toLowerCase())
                      }
                      virtual={false}
                      options={
                        companyData
                          ? companyData.subscription_vendors.map((c: any) => {
                              return {
                                label: c.name,
                                value: c.name
                              };
                            })
                          : []
                      }
                      notFoundContent={
                        <SelectSearchNotFoundContent
                          notFoundMsg={
                            companyData?.subscription_vendors?.length > 0
                              ? "Company does not exist. To add, go to "
                              : "There are no companies added. To add companies, go to "
                          }
                          linkTitle="Companies"
                          linkPath="/companies"
                        />
                      }
                    />
                  </FormItem>
                  <FormItem
                    {...field}
                    className="w-full"
                    name={[field.name, "company_type"]}
                    label="Role of Company"
                    rules={[
                      {
                        required: true,
                        message: ErrorMessages.ProjectRole
                      }
                    ]}
                  >
                    <Select
                      loading={loadingUserType}
                      className="w-full"
                      options={
                        companyTypes && filteredCompanyTypes
                          ? filteredCompanyTypes.map((type: any) => {
                              return {
                                label: type.name,
                                value: type.id
                              };
                            })
                          : []
                      }
                    />
                  </FormItem>
                  {form.getFieldValue("companies").length > 1 && (
                    <Button
                      icon={<ClearIcon />}
                      disabled={form.getFieldValue("companies").length <= 1}
                      className="h-[20px] w-[20px]  border-none mt-6"
                      onClick={() => remove(field.name)}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <div className="flex flex-col items-end">
                  <Button
                    className="add-more-button mr-2"
                    disabled={responseLoading}
                    onClick={() => add({ key: uuidV4() })}
                  >
                    Add More
                  </Button>
                  <Divider className="" />
                  <Button
                    className="add-companies-btn mr-2"
                    loading={responseLoading}
                    disabled={responseLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    {companiesAddded?.length > 1
                      ? "Add Companies"
                      : "Add Company"}
                  </Button>
                </div>
              </Form.Item>
            </div>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}
export default InviteProjectCompanies;
