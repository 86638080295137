import { useQuery, useSubscription } from "@apollo/client";
import { SubscriptionContext } from "context/SubscriptionProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { QUERY_PROJECT_INTEGRATION } from "services/graphQL/queries";
import { SUBSCRIPTION_PROJECT_INTEGRATION } from "services/graphQL/subscriptions";
import { mergeArrays } from "utils/utils";

export const useSubscriptionStream = (
  queryDef: any,
  subDef: any,
  idField: string,
  params: {
    client: any;
    variables?: any;
    skip?: boolean;
  }
) => {
  const { client, variables, skip = false } = params;

  const [finalData, setFinalData] = useState<any>(null);

  // start with a query
  const {
    data: queryData,
    error: queryError,
    loading: queryLoading,
    refetch: queryRefetch
  } = useQuery(queryDef, {
    fetchPolicy: "cache-and-network",
    variables,
    client,
    skip
  });

  let subVariables = {};
  let queryRespRoot = "";
  if (queryData) {
    const queryRespKeys = Object.keys(queryData);
    // eslint-disable-next-line prefer-destructuring
    queryRespRoot = queryRespKeys[0];

    subVariables = {
      ...variables,
      cursor: {
        initial_value: {
          updated_at: queryData[queryRespRoot][0]?.updated_at
        }
      }
    };
  }

  // then get a subscription
  const {
    data: streamData,
    error: streamError,
    loading: streamLoading
  } = useSubscription(subDef, {
    client,
    variables: subVariables,
    skip: skip || !queryData
  });

  let subRespRoot: string = "";
  if (streamData) {
    const subRespKeys = Object.keys(streamData);
    // eslint-disable-next-line prefer-destructuring
    subRespRoot = subRespKeys[0];
  }

  useEffect(() => {
    if (queryData) {
      setFinalData(queryData[queryRespRoot]);
    }
  }, [queryData, queryRespRoot]);

  useEffect(() => {
    if (queryData && streamData) {
      queryRefetch(); // to update query cache
      const updatedArray = mergeArrays(
        queryData[queryRespRoot],
        streamData[subRespRoot],
        idField
      );

      setFinalData(updatedArray);
    }
  }, [
    idField,
    queryData,
    queryRefetch,
    queryRespRoot,
    streamData,
    subRespRoot
  ]);

  return {
    data: finalData,
    streamError,
    queryError,
    loading: queryLoading || streamLoading
  };
};

export const useQuerySubscription = <TData = any>(
  queryDef: any,
  subDef: any,
  params: {
    client?: any;
    variables?: any;
    skip?: boolean;
  }
): {
  data: TData;
  error: any;
  loading: any;
} => {
  const { client, variables, skip = false } = params;

  const {
    data: queryData,
    error: queryError,
    loading: queryLoading,
    subscribeToMore
  } = useQuery(queryDef, {
    fetchPolicy: "cache-and-network",
    variables,
    client,
    skip
  });

  useEffect(() => {
    if (!queryData) return () => {};
    subscribeToMore({
      document: subDef,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return { ...subscriptionData.data };
      }
    });
    return () => {};
  }, [queryData, subDef, subscribeToMore, variables]);

  return {
    data: queryData,
    error: queryError,
    loading: queryLoading
  };
};

export const useProjectIntegration = (
  gqlClientForProject: any,
  skip: boolean,
  skipSubscription: boolean
) => {
  const [finalProjectIntegrationData, setFinalProjectIntegrationData] =
    useState<any>(null);
  const { data: projectIntegrationQueryData } = useQuery(
    QUERY_PROJECT_INTEGRATION,
    {
      client: gqlClientForProject,
      skip
    }
  );
  // console.log("skipSubscription ", skipSubscription);
  const { data: projectIntegrationSubData } = useSubscription(
    SUBSCRIPTION_PROJECT_INTEGRATION,
    {
      client: gqlClientForProject,
      skip:
        skipSubscription ||
        projectIntegrationQueryData?.external_integrations[0]
          .integration_key !== null
    }
  );
  useEffect(() => {
    setFinalProjectIntegrationData(projectIntegrationQueryData);
  }, [projectIntegrationQueryData]);
  useEffect(() => {
    setFinalProjectIntegrationData(projectIntegrationSubData);
  }, [projectIntegrationSubData]);

  return { data: finalProjectIntegrationData };
};

export const useCustomOAuthPopup = () => {
  const externalPopup = useRef<any>(null);

  const openPopup = (config: { title: string; url: string }) => {
    const pWidth = 500;
    const pHeight = 500;
    const pLeft = window.screenX + (window.outerWidth - pWidth) / 2;
    const pTop = window.screenY + (window.outerHeight - pHeight) / 2;
    const pTitle = config.title;
    const pUrl = config.url;
    const finalPopup: any = window.open(
      pUrl,
      pTitle,
      `width=${pWidth},height=${pHeight},left=${pLeft},top=${pTop}`
    );
    externalPopup.current = finalPopup;
  };

  const closePopup = () => {
    if (externalPopup.current) {
      externalPopup.current.close();
      externalPopup.current = null;
    }
  };

  const isPopupOpen = externalPopup.current !== null;
  return { openPopup, closePopup, isPopupOpen };
};

export const useShowDesignAndSpec = () => {
  const { isFeatureFlagEnabled } = useContext(SubscriptionContext);
  const isDesignTabEnabled = isFeatureFlagEnabled("DESIGN_TAB");
  return { isDesignTabEnabled };
};
