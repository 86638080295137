import moment, { Moment } from "moment";

interface IDateUtils {
  dateTimeObj(
    date?: TDateTimeObj | string | Date | number | null,
    format?: string
  ): moment.Moment;
  format(date: any, format?: string | { in?: string; out?: string }): string;
  formatDateWithLunchTime(date: any, inFormat?: string): string;
}

export const ISO_DATE_FORMAT_1 = "YYYY-MM-DDTHH:mm:ssZZ";
export const DATE_FORMAT_MMDDYYYY = "MM-DD-YYYY";
export const DATE_FORMAT_YYYYMMDD = "YYYY-MM-DD";
export const DATE_FORMAT_MMDDYYYY_HHMM_A = "MM-DD-YYYY hh:mm A";
export const DATE_FORMAT_MMDDYYYY_HHMM_A_Z = "MM-DD-YYYY hh:mm A (z)";
export const DATE_FORMAT_MMDDYYYY_HHMM_Z = "DD-MMM-YYYY HH:mm:ss Z";

export type TDateTimeObj = Moment;

export const DateUtils: IDateUtils = {
  dateTimeObj(date?: TDateTimeObj | string | Date | number, format?: string) {
    if (!date) return moment();
    if (moment.isMoment(date) || date instanceof Date) return moment(date);
    if (typeof date === "string") {
      return moment(
        date,
        format || [
          "MM-DD-YYYY",
          "YYYY-MM-DD",
          "YYYY-MM-DDTHH:mm:ssZZ",
          "YYYY-MM-DD HH:mm"
        ]
      );
    }
    return moment(date);
  },

  format(date: any, format?: string | { in?: string; out?: string }) {
    if (!date) return "";
    if (format && typeof format === "object") {
      return moment(date, format.in).format(format.out || DATE_FORMAT_MMDDYYYY);
    }

    return moment(date).format(format || DATE_FORMAT_MMDDYYYY);
  },

  formatDateWithLunchTime(date: any, inFormat?: string) {
    if (!date) return "";
    try {
      const formattedDate = moment(
        date,
        inFormat || ["MM-DD-YYYY", "YYYY-MM-DD"]
      ).format(DATE_FORMAT_YYYYMMDD);
      return `${formattedDate}T13:23:24+00:00`;
    } catch (ex) {
      return "";
    }
  }
};

export const DateFilter = {
  comparator: (value1: string | null, value2: string | null): number => {
    if (!value1 && !value2) {
      return 0;
    }

    if (value1 && !value2) {
      return -1;
    }

    if (!value1 && value2) {
      return 1;
    }

    const date1 = value1 ? new Date(value1) : null;
    const date2 = value2 ? new Date(value2) : null;

    if (date1 === date2) {
      return 0;
    }
    return date1 && date2 && date1 > date2 ? 1 : -1;
  }
};
