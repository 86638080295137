import { Button, message, Modal, Tooltip } from "antd";
import DatePickerIcon from "components/svg-icons/datepicker-icon";
import { useEffect, useMemo, useState } from "react";
import "./feature-status.scss";
import GrayDatePickerIcon from "components/svg-icons/gray-datepicker-icon";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";
import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_DATEBLOCK_PK } from "components/date-block/gql-graphgl";
import moment, { Moment } from "moment";
import CIQDatePicker from "components/custom-date-picker";
import { ESubmittalStatus, EWorkflowStatusDataBlock } from "constants/index";

function FeatureSetStatus(props: {
  headerTitle?: string;
  data?: any;
  gqlClientForProject?: any;
}) {
  const { headerTitle, data, gqlClientForProject } = props;
  const [visible, setVisible] = useState(false);
  const [milestoneInfo, setMilestoneInfo] = useState<{
    selectedDate: any;
    nextActualDateKey: string;
    prevActualDate: any;
    nextMilestoneName: String;
  }>({
    selectedDate: null,
    nextActualDateKey: "",
    prevActualDate: null,
    nextMilestoneName: ""
  });

  const [updateDateBlockMutationPK, { loading }] = useMutation(
    MUTATION_UPDATE_DATEBLOCK_PK,
    {
      client: gqlClientForProject
    }
  );

  const dateBlockData = useMemo(() => {
    return data?.date_block_data;
  }, [data]);

  const linkedSubmittalsWfCompleted = useMemo(() => {
    if (
      dateBlockData?.workflow_status === EWorkflowStatusDataBlock.WF_NOT_STARTED
    ) {
      if (data?.submittals?.length === 0) return true;

      const areAllSubmittalsWFCompleted = data?.submittals?.some(
        (submittal: any) => submittal?.workflow_status === ESubmittalStatus.DONE
      );

      return areAllSubmittalsWFCompleted;
    }
    return true;
  }, [data?.submittals, dateBlockData?.workflow_status]);

  useEffect(() => {
    if (!dateBlockData) return;
    const totalMilestones = Object.keys(dateBlockData)?.filter((key) =>
      key.startsWith("actual_milestone_")
    );

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalMilestones?.length; i++) {
      const nextMilestoneKey = totalMilestones[i];

      if (!dateBlockData[nextMilestoneKey]) {
        const prevMilestoneKey = totalMilestones[i - 1];
        const nextMilestoneNameKey = `name_milestone_${i + 1}`;
        setMilestoneInfo({
          selectedDate: moment(),
          nextActualDateKey: nextMilestoneKey,
          prevActualDate: dateBlockData[prevMilestoneKey],
          nextMilestoneName: dateBlockData[nextMilestoneNameKey]
        });
        break;
      }
    }
  }, [dateBlockData]);

  const onChange = (value: any) => {
    if (!value) return;
    setMilestoneInfo({
      selectedDate: value,
      nextActualDateKey: milestoneInfo?.nextActualDateKey,
      prevActualDate: milestoneInfo?.prevActualDate,
      nextMilestoneName: milestoneInfo?.nextMilestoneName
    });
  };

  const onConfirm = async () => {
    const selectedDate = milestoneInfo?.selectedDate
      ? DateUtils.formatDateWithLunchTime(milestoneInfo?.selectedDate)
      : null;

    const setObj: any = {};
    setObj[milestoneInfo?.nextActualDateKey] = selectedDate;

    const variables: any = { id: dateBlockData?.id, set: setObj };
    try {
      const res = await updateDateBlockMutationPK({
        variables
      });

      if (res.data) {
        setVisible(false);
        message.success("Updated successfully.");
      }
      if (res.errors) {
        message.error(res.errors[0].message);
      }
    } catch (ex: any) {
      message.error(ex.message);
    }
  };

  const disabledActualDate = (current: Moment) => {
    const after = DateUtils.dateTimeObj(current).isAfter(
      DateUtils.dateTimeObj()
    );
    if (!milestoneInfo?.prevActualDate) return after;

    const currDateFromPicker = DateUtils.dateTimeObj(current)
      .hours(13)
      .minutes(0)
      .seconds(0);
    const before = currDateFromPicker.isBefore(
      DateUtils.dateTimeObj(milestoneInfo?.prevActualDate)
    );
    return before || after;
  };

  const isMaterialWorkflowCompleted = useMemo(() => {
    return (
      dateBlockData?.workflow_status === EWorkflowStatusDataBlock.WF_COMPLETED
    );
  }, [dateBlockData]);

  return (
    <div className="flex ">
      <Tooltip
        title={
          // eslint-disable-next-line no-nested-ternary
          linkedSubmittalsWfCompleted === false
            ? "Linked Submittals have not been distributed. To release the Material ahead of schedule, go to Material Details."
            : isMaterialWorkflowCompleted === true
            ? "Workflow completed"
            : "Update status"
        }
      >
        {isMaterialWorkflowCompleted === true ||
        linkedSubmittalsWfCompleted === false ? (
          <div className="flex w-[46px] justify-center">
            <GrayDatePickerIcon />
          </div>
        ) : (
          <Button
            className="flex space-x-2 items-center border-none bg-transparent disabled:bg-slate-600"
            onClick={() => setVisible(true)}
          >
            <DatePickerIcon />
          </Button>
        )}
      </Tooltip>
      <Modal
        title={
          <div className="px-3">{`Update Status of ${headerTitle ?? ""}`}</div>
        }
        bodyStyle={{ height: 330 }}
        open={visible}
        destroyOnClose
        onOk={onConfirm}
        okText="Confirm"
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        onCancel={() => {
          setVisible(false);
        }}
        width={391}
      >
        <div className="flex flex-col feature-status-popover-content justify-center space-y-1">
          <div className=" text-xs w-full text-left">
            <span className="font-semibold uppercase">
              {milestoneInfo?.nextMilestoneName}
            </span>{" "}
            on{" "}
            {DateUtils.format(milestoneInfo.selectedDate, DATE_FORMAT_MMDDYYYY)}
          </div>
          <CIQDatePicker
            value={milestoneInfo?.selectedDate}
            className="w-full"
            onChange={onChange}
            open
            getPopupContainer={(trigger: any) => {
              return trigger;
            }}
            showToday={false}
            disabledDate={disabledActualDate}
          />
        </div>
      </Modal>
    </div>
  );
}

export default FeatureSetStatus;
