import { Tabs } from "antd";
import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext } from "react";
import NotFoundPage from "pages/not-found";
import { useShowDesignAndSpec } from "hooks/common";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";
import SpecificationDocumentsTab from "./specification-documents-tab/specification-documents-tab";

function DesignPage() {
  const { tokenContents }: TProjectContext = useContext(ProjectContext);
  const cannotViewDesignPage = isPermissionNotGrantted(
    ProjectPermissionEnum.ViewDesignPage,
    tokenContents?.role!
  );
  const { isDesignTabEnabled } = useShowDesignAndSpec();

  if (!tokenContents) return null;

  if (!isDesignTabEnabled || cannotViewDesignPage) {
    return (
      <NotFoundPage
        status="403"
        title="403"
        subTitle={ErrorMessages.UnauthorizedPageAccess}
      />
    );
  }

  const tabItems = [
    {
      label: "Specifications",
      key: "1",
      children: <SpecificationDocumentsTab />
    }
  ];

  return (
    <div className="px-4 pb-1">
      <Tabs items={tabItems} />
    </div>
  );
}

export default DesignPage;
