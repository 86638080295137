import { ReactElement, useContext, useEffect, useState } from "react";
import "./index.scss";
import { useSubscription } from "@apollo/client";
import { ProjectContext } from "context/ProjectProvider";
import { SUBSCRIPTION_TASK_DETAIL_BY_TASK_ID } from "services/graphQL/subscriptions";
import { LoadingOutlined } from "@ant-design/icons";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";

function TaskDetailCard({
  taskId,
  isLinkedEndDate = false
}: {
  taskId: string;
  isLinkedEndDate?: boolean;
}): ReactElement {
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState({}) as any;
  const { gqlClientForProject } = useContext(ProjectContext);

  const { data } = useSubscription(SUBSCRIPTION_TASK_DETAIL_BY_TASK_ID, {
    client: gqlClientForProject,
    variables: {
      id: taskId
    },
    shouldResubscribe: true,
    skip: !taskId,
    onData: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (data?.gantt_tasks_by_pk) {
      setTask(data.gantt_tasks_by_pk);
    }
  }, [data]);

  if (loading) {
    return (
      <div className="db-task-detail-card">
        <div className="h-full w-full flex items-center justify-center">
          <LoadingOutlined />
        </div>
      </div>
    );
  }
  return (
    <div className="db-task-detail-card">
      <div className="db-task-detail-card--row">
        <b>Activity:</b> {task.text}
      </div>
      {isLinkedEndDate ? (
        <div className="db-task-detail-card--row">
          <b>End Date:</b>{" "}
          {DateUtils.format(task.end_date, DATE_FORMAT_MMDDYYYY) || "--"}
        </div>
      ) : (
        <div className="db-task-detail-card--row">
          <b>Start Date:</b>{" "}
          {DateUtils.format(task.start_date, DATE_FORMAT_MMDDYYYY) || "--"}
        </div>
      )}
    </div>
  );
}

export default TaskDetailCard;
