import { TActivityTask, TLinkedMaterial } from "../models";

export const translateWithParentInfo = (orignal: Array<TActivityTask>) => {
  const trans = [] as Array<TActivityTask>;

  orignal.forEach((orgActivity) => {
    const newActivity = {
      ...orgActivity,
      linked_materials: [],
      linked_submittals: []
    } as TActivityTask;

    orgActivity.linked_materials.forEach((orgMat: TLinkedMaterial) => {
      const newMat = {
        ...orgMat,
        parentActivity: newActivity,
        linked_submittals: []
      } as TLinkedMaterial;

      orgMat.linked_submittals.forEach((s) => {
        const submittal = { ...s.submittal, parentMaterial: newMat };
        newMat.linked_submittals.push({ submittal });
      });

      newActivity.linked_materials.push({
        ...newMat,
        parentActivity: orgActivity
      });
    });

    orgActivity.linked_submittals.forEach((s) => {
      newActivity.linked_submittals.push({ ...s, parentActivity: newActivity });
    });

    trans.push(newActivity);
  });
  return trans;
};
