import { Link } from "react-router-dom";
import { AcceptanceStatus } from "../../../constants";

export function LinkCellRenderer({ data, value }: any) {
  return (
    <Link
      className=""
      to={{
        pathname: `/organizations/${data.id}`
      }}
    >
      {value}
    </Link>
  );
}

export function StatusCellRenderer({ value }: any) {
  switch (value) {
    case AcceptanceStatus.ACTIVE:
      return <p className="margin-0 captialize-first text-green-600">Active</p>;
    case AcceptanceStatus.DEACTIVATED:
      return (
        <p className="margin-0 captialize-first text-red-600">Deactivated</p>
      );
    default:
      return <p className="captialize-first text-red-600">Pending</p>;
  }
}

export function ProjectFeatureLinkCellRenderer(param: any) {
  const { data, value, context, colDef } = param;

  let featureId = 0;
  switch (colDef.headerName) {
    case "Submittal":
      featureId = 1;
      break;
    case "Submittal DateBlock":
      featureId = 2;
      break;
    case "Material":
      featureId = 3;
      break;
    case "Material DateBlock":
      featureId = 4;
      break;
    default:
      break;
  }

  if (value > 0) {
    const linkObj = {
      pathname: `/organizations/${context?.orgId}/subscriptions/${context?.subId}/project/${context?.projectId}/events/project`,
      state: {
        name: data?.name,
        userId: data?.id,
        projectId: context.projectId,
        featureId,
        organization: context?.organization,
        subscription: context?.subscription,
        featureName: colDef.headerName
      }
    };

    return <Link to={linkObj}>{value}</Link>;
  }

  return value;
}

export function LoginFeatureLinkCellRenderer(params: any) {
  const { data, value, context } = params;
  if (value > 0) {
    const linkObj = {
      pathname: `/organizations/${context?.orgId}/subscriptions/${context?.subId}/project/${context?.projectId}/events/login`,
      state: {
        name: data?.name,
        userId: data?.id,
        organization: context?.organization,
        subscription: context?.subscription
      }
    };

    return <Link to={linkObj}>{value}</Link>;
  }

  return value;
}
