import { ProjectSettingType } from "context/ProjectProvider";
import { useCIQLazyQuery } from "hooks/ciq-gql-hooks";
import {
  SUBMITTAL_LIST_INTG_PERF,
  SUBMITTAL_LIST_WF_PERF
} from "../queries/submittal-list-queries";

export const useLazySubmittalListPerf = (params: {
  projectDetails: ProjectSettingType | undefined;
  isIntegrationMode: any;
}): {
  getLazySubmittal: any;
} => {
  const { isIntegrationMode } = params;

  const [getWfSubmittals] = useCIQLazyQuery(SUBMITTAL_LIST_WF_PERF);

  const [getIntgSubmittals] = useCIQLazyQuery(SUBMITTAL_LIST_INTG_PERF);

  if (isIntegrationMode) return { getLazySubmittal: getIntgSubmittals };

  return { getLazySubmittal: getWfSubmittals };
};
