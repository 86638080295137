import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

export const OpenNotification = (props: {
  projectId: string;
  id: string;
  title: string;
  placement: NotificationPlacement;
}) => {
  const { placement, projectId, id, title } = props;
  notification.open({
    message: (
      <div className="notifications space-x-1">
        <span className="notification-highlight-title ">{title}</span>
        <span className="notification-title">successfully created.</span>
      </div>
    ),
    description: (
      <div className="notifications space-x-1">
        <span className="notification-subTitle">Click</span>
        <a
          href={`/project/${projectId}/submittals/${id}`}
          className="underline notification-highlight-subTitle "
        >
          here
        </a>
        <span className="notification-subTitle">to view details.</span>
      </div>
    ),
    placement
  });
};
