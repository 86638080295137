export default function DeletedIcon({
  className = ""
}: {
  className?: string;
}) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="18" height="18" rx="9" fill="#3B3B3B" />
      <path
        d="M13.0833 8.41667H4.91666V9.58333H13.0833V8.41667Z"
        fill="white"
      />
    </svg>
  );
}
