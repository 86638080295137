import { Select } from "antd";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useContext
} from "react";

const { Option } = Select;

type Role = {
  id: number;
  name: string;
};

const UserRoleSelectCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing, value, context } = props;
  const [selectValue, setSelectValue] = useState(value);
  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const { subscriptionRole } = useContext(SubscriptionContext);

  useEffect(() => {
    if (context.userSubscriptionRoles) {
      const subscriptionPermission =
        context.userSubscriptionRoles?.subscription_permission?.filter(
          (role: any) => role.key !== "subscription_none"
        ); // Remove None permission for editor

      if (subscriptionRole <= RoleSubscriptionEnum.subscription_editor) {
        const roles = subscriptionPermission?.filter(
          (role: any) => role.name !== "Admin"
        );
        setUserRoles(roles);
      } else {
        setUserRoles(subscriptionPermission);
      }
    }
  }, [subscriptionRole, context.userSubscriptionRoles]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!selectValue) return null;
        return selectValue;
      }
    };
  });

  return (
    <Select
      open
      allowClear
      optionFilterProp="label"
      virtual={false}
      style={{ width: "100%" }}
      showSearch
      // notFoundContent={loading ? "" : notFoundContent}
      onChange={(event: any) => {
        setSelectValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    >
      {userRoles.length > 0 &&
        userRoles.map((role: Role) => (
          <Option key={role.id} value={role.name}>
            {role.name}
          </Option>
        ))}
    </Select>
  );
});

export default UserRoleSelectCellEditor;
