import { Card, Tabs } from "antd";
import "./feature-activity-card.scss";
import { ActivityCardPermissions } from "models/types";
import { TNewDateBlock } from "components/date-block/models";
import ErrorBoundary from "components/error-boundary";
import FeatureCommentsTabComponent from "./feature-comments-tab";

function FeatureActivityCard(props: {
  historyTabContents: any;
  permissions: ActivityCardPermissions;
  dateBlock?: TNewDateBlock;
  showHistoryTab?: boolean;
  featureKey: string;
  featureId: string;
}) {
  const {
    historyTabContents,
    permissions,
    dateBlock,
    showHistoryTab = true,
    featureKey,
    featureId
  } = props;

  const tabItems = [
    {
      label: "Comments",
      key: "1",
      children: (
        <ErrorBoundary>
          <FeatureCommentsTabComponent
            permissions={permissions}
            dateBlock={dateBlock}
            featureKey={featureKey}
            featureId={featureId}
          />
        </ErrorBoundary>
      )
    },
    {
      label: "History",
      key: "2",
      children: historyTabContents
    }
  ];

  if (!showHistoryTab) {
    tabItems.splice(1, 1);
  }

  return (
    <Card className="activity-card ciq-card-block">
      <div className="h-full max-h-full">
        <Tabs items={tabItems} />
      </div>
    </Card>
  );
}

export default FeatureActivityCard;
