import { ColDef, Column, ColumnApi } from "ag-grid-community";
import { dateCellRenderer } from "components/cell-renders";
import { TMilestonesTemplate } from "components/workflow-template-editor/model";
import CustomDateFilters from "pages/submittals-list/custom-date-filter";
import { DateFilter, DateUtils } from "utils/dateutils";

type MaxMileStoneInfo = {
  isLastMileStone: boolean;
  numberOfMaxMileStones: number;
};

export const getMilestoneColumns = ({
  setCustomDateFilter,
  totalPossibleMileStones = 10
}: {
  setCustomDateFilter: Function;
  totalPossibleMileStones?: number;
}) => {
  const groupColInitData = {
    width: 0,
    maxWidth: 0,
    headerClass: "!p-0 !w-0",
    filter: false,
    editable: false,
    menuTabs: [],
    suppressMovable: true
  };

  const colInitData = {
    hide: true,
    suppressColumnsToolPanel: true,
    suppressMovable: true,
    menuTabs: ["filterMenuTab"],
    editable: false,
    width: 160,
    keyCreator: ({ value }: any) => value
  };

  const withMilestones = {
    headerName: "MILESTONES",
    colId: "_milestones",
    isLastMileStone: true,
    columns: [
      {
        headerName: "MILESTONE NAME ",
        field: "name_milestone_",
        tooltipValueGetter: ({ value }: any) => value,
        filter: true
      },
      {
        headerName: "PLANNED DATE ",
        field: "planned_milestone_",
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        tooltipValueGetter: ({ value }: any) => DateUtils.format(value),
        getQuickFilterText: () => "",
        filter: CustomDateFilters
      },
      {
        headerName: "ACTUAL DATE ",
        field: "actual_milestone_",
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        tooltipValueGetter: ({ value }: any) => DateUtils.format(value),
        getQuickFilterText: () => "",
        filter: CustomDateFilters
      }
    ]
  };
  const withDurations = {
    headerName: "DURATIONS",
    colId: "_durations",
    columns: [
      {
        headerName: "DURATION NAME ",
        field: "name_offset_",
        tooltipValueGetter: ({ value }: any) => value,
        filter: true
      },
      {
        headerName: "DURATION ",
        field: "offset_",
        tooltipValueGetter: ({ value }: any) => value,
        filter: true,
        getQuickFilterText: () => ""
      }
    ]
  };
  const columnIdAndFields = [withMilestones, withDurations];
  const groupColDefMilestone = [] as ColDef[];

  // Hidden columns for milestone and duration
  columnIdAndFields.forEach((groupCol) => {
    const newObj = {
      colId: groupCol.colId,
      headerName: groupCol.headerName,
      ...groupColInitData
    };
    groupColDefMilestone.push(newObj);
  });

  // All Visible and Hide columns based on Toolbar UI
  const mileStoneColumns = [] as ColDef[];
  for (let index = 1; index <= totalPossibleMileStones; index = 1 + index) {
    withMilestones.columns.forEach((col: any) => {
      const colId = col.field + index;
      const field = `date_block_data.${colId}`;
      const headerName = col.headerName + index;
      mileStoneColumns.push({
        ...colInitData,
        colId,
        field,
        headerName,
        cellRenderer: col.cellRenderer,
        tooltipValueGetter: col.tooltipValueGetter,
        comparator: col.comparator,
        filter: col.filter ? col.filter : false,
        getQuickFilterText: col.getQuickFilterText,
        filterParams: {
          title: headerName || "",
          columnData: {
            field,
            header: headerName || ""
          },
          setCustomDateFilter
        }
      });
    });

    withDurations.columns.forEach((col: any) => {
      const colId = col.field + index;
      const field = `date_block_data.${colId}`;
      const headerName = col.headerName + index;
      mileStoneColumns.push({
        ...colInitData,
        colId,
        field,
        headerName,
        tooltipValueGetter: col.tooltipValueGetter,
        getQuickFilterText: col.getQuickFilterText,
        comparator: col.comparator,
        filter: col.filter ? col.filter : false
      });
    });
  }

  type Props = {
    column: Column | null;
    columnApi: ColumnApi;
  };
  const onColumnVisible = (e: Props, milestoneInfo: MaxMileStoneInfo) => {
    const { column, columnApi } = e;
    const colId = column?.getColId();
    const extraColumn = columnIdAndFields.find((c) => c.colId === colId);
    if (!extraColumn) return;

    const isVisible = column?.isVisible() || false;
    const colIdsColumns = [] as string[];
    const hideColumnIds = [] as string[];
    let count = milestoneInfo.numberOfMaxMileStones;
    if (extraColumn.colId === "_durations" && !milestoneInfo.isLastMileStone) {
      count = milestoneInfo.numberOfMaxMileStones - 1;
    }

    extraColumn.columns.forEach((c) => {
      for (let index = 1; index <= totalPossibleMileStones; index = 1 + index) {
        if (count >= index) colIdsColumns.push(`${c.field}${index}`);
        else hideColumnIds.push(`${c.field}${index}`);
      }
    });

    columnApi.setColumnsVisible(colIdsColumns, isVisible);
    columnApi.setColumnsVisible(hideColumnIds, false);
  };

  const updateAllMileStoneInitialVisibility = (
    columnApi: ColumnApi,
    milestoneInfo: MaxMileStoneInfo
  ) => {
    if (!columnApi) return;
    const colIds = groupColDefMilestone.map((x) => x.colId);
    colIds.forEach((colId) => {
      const column = columnApi.getColumn(colId);
      if (column) {
        onColumnVisible(
          {
            column,
            columnApi
          },
          milestoneInfo
        );
      }
    });
  };
  const resp = {
    groupColDefMilestone,
    mileStoneColumns,
    onColumnVisible,
    updateAllMileStoneInitialVisibility
  };
  return resp;
};

export const getMaxNumberOfMilestoneFromProjectTemplate = (
  templates: Array<{
    id: string;
    additional_offset: boolean;
    project_template_milestones: Array<TMilestonesTemplate>;
  }>
): MaxMileStoneInfo => {
  let numberOfMaxMileStones = 0;
  templates.forEach((t) => {
    if (t.project_template_milestones.length > numberOfMaxMileStones) {
      numberOfMaxMileStones = t.project_template_milestones.length;
    }
  });
  const isLastMileStone = templates
    .filter(
      (t) => t.project_template_milestones.length === numberOfMaxMileStones
    )
    .some((t) => t.additional_offset);

  return { isLastMileStone, numberOfMaxMileStones };
};
