import { isNetworkAvailable } from "utils/utils";
import { message as antdMessage } from "antd";
import * as Sentry from "@sentry/react";
import cumulativeErrorMessageUtil from "./cumulative-error-message-util";
import { ErrorMessages } from "../../constants";

export default function ErrorManager() {
  const showNetworkError = () => {
    if (!isNetworkAvailable()) {
      cumulativeErrorMessageUtil.showError(
        ErrorMessages.NetworkError.offlineMessage
      );
      // } else { // it will be enable once we have complete error implementation for all pages.
      //   // cumulativeErrorMessageUtil.showError(ErrorMessages.SomethingWentWrong);
      //   cumulativeErrorMessageUtil.showError(
      //     ErrorMessages.NetworkError.internetError
      //   );
    }
  };

  const show = (error: any, message?: string) => {
    /**
     * If the error has network error then capture and show in cumulative way
     * if there is gql error then show generic issue and notify it to server
     */
    if (error && error?.networkError) {
      showNetworkError();
    } else {
      cumulativeErrorMessageUtil.showError(
        message || ErrorMessages.SomethingWentWrong
      );
    }
  };

  const handleError = (error: any, showError: boolean = true) => {
    const errorMsg = error.response?.data?.error || error.message;
    if (showError) {
      antdMessage.error(errorMsg);
    }
    Sentry.captureException(error);
    return errorMsg;
  };

  return { showNetworkError, show, handleError };
}
