import { Select } from "antd";
import SelectNotFoundContent from "components/widgets/select-notfound-content";
import { AcceptanceStatus } from "constants/index";
import { forwardRef, useState, useImperativeHandle } from "react";

const { Option } = Select;

export const MaterialAssigneeSelectCellEditor = forwardRef(
  (props: any, ref) => {
    const { stopEditing, value, context, data } = props;
    const [selectValue, setSelectValue] = useState(value);

    const users = context.projectParticipants.submitterUsers.filter(
      (user: any) => {
        if (data.assignee === user.id) {
          return true;
        }
        return user.status_id !== AcceptanceStatus.DEACTIVATED;
      }
    );

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (!selectValue) return null;
          return selectValue;
        }
      };
    });

    if (!data.trade_partner) {
      return (
        <div className="w-full h-full flex items-center justify-center text-red-600 text-xs">
          First select a Responsible Contractor
        </div>
      );
    }

    return (
      <Select
        open
        allowClear
        optionFilterProp="label"
        virtual={false}
        style={{ width: "100%" }}
        showSearch
        filterOption={(input, option: any) => {
          const user: any = users
            .filter((usr: any) => {
              return usr.company.vendor_id === data.trade_partner;
            })
            .find((u: any) => u.id === option.key);
          const inputMes = input.toString().toLowerCase();
          const userData = [
            user.first_name.toLowerCase(),
            user.last_name.toLowerCase(),
            user?.company?.subscription_vendor?.name?.toLowerCase()
          ];
          return userData.some((text) => text.includes(inputMes));
        }}
        onChange={(event: any) => {
          setSelectValue(event);
          setTimeout(() => {
            stopEditing();
          }, 200);
        }}
        notFoundContent={
          <SelectNotFoundContent
            notFoundMsg="User not found. Go to Project Settings to add users"
            onAddClick={() => {}}
            showAddBtn={false}
          />
        }
      >
        {users
          .filter((user: any) => {
            return user.company.vendor_id === data.trade_partner;
          })
          .map((user: any) => {
            if (user.status_id === AcceptanceStatus.DEACTIVATED) {
              return "";
            }

            return (
              <Option key={user.id} value={user.id}>
                <div>
                  {user.first_name} {user.last_name}
                </div>
                <div className="text-sm text-gray-500">
                  {user?.company?.subscription_vendor?.name}
                </div>
              </Option>
            );
          })}
      </Select>
    );
  }
);
