import CIQDatePicker from "components/custom-date-picker";
import { forwardRef, useState, useImperativeHandle } from "react";
import { DateUtils, TDateTimeObj } from "utils/dateutils";

const dateCellEditor = forwardRef((props: any, ref) => {
  const [value, setValue] = useState<TDateTimeObj | null>(null);

  const dateObj = props.value ? DateUtils.dateTimeObj(props.value) : null;

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value ? value.format("MM-DD-YYYY") : null;
      }
    };
  });

  return (
    <CIQDatePicker
      defaultOpen
      format="MM-DD-YYYY"
      value={dateObj}
      onChange={(date: any) => {
        setValue(date);
        setTimeout(() => {
          props.stopEditing();
        }, 200);
      }}
      style={{ width: "100%" }}
    />
  );
});

export default dateCellEditor;
