/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";

import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/legacy/build/pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { DateUtils } from "utils/dateutils";
import { Spinner } from "./Spinner";

interface Props {
  url: string;
  children?: (pdfDocument: PDFDocumentProxy) => JSX.Element;
  onError?: (error: Error) => void;
  onExpiredUrl: () => void;
  cMapUrl?: string;
  cMapPacked?: boolean;
}

const workerSrc = "/scripts/pdf.worker.min.js";
GlobalWorkerOptions.workerSrc = workerSrc;

export function PdfLoader(props: Props) {
  const { url, children, onExpiredUrl, cMapUrl, cMapPacked, onError } = props;
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy | null>();
  const [error, setError] = useState<Error | null>();
  const documentRef = React.createRef<HTMLElement>();

  useEffect(() => {
    const isUrlExpired = () => {
      const urlParams = new URLSearchParams(url);
      const date = urlParams.get("X-Amz-Date");
      if (!date) return;
      const expireTime = urlParams.get("X-Amz-Expires");
      const dateTime = DateUtils.dateTimeObj(date);
      dateTime.add(expireTime, "seconds");
      const now = DateUtils.dateTimeObj();
      if (now > dateTime) {
        onExpiredUrl();
        return true;
      }
      return false;
    };
    const load = () => {
      const { ownerDocument = document } = documentRef.current || {};
      const discardedDocument = pdfDocument;
      setPdfDocument(null);
      setError(null);
      if (isUrlExpired()) return;

      Promise.resolve()
        .then(() => discardedDocument && discardedDocument.destroy())
        .then(() => {
          if (!url) {
            return;
          }

          return getDocument({
            ...props,
            ownerDocument,
            cMapUrl,
            cMapPacked
          }).promise.then((doc) => {
            setPdfDocument(doc);
          });
        })
        .catch((e) => {
          console.log(e);
          if (onError) {
            onError(e);
          }
          setPdfDocument(null);
          setError(e);
        });
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <>
      <span ref={documentRef} />
      {error ? (
        <div>Error {error?.message}</div>
      ) : !pdfDocument || !children ? (
        <Spinner />
      ) : (
        children(pdfDocument)
      )}
    </>
  );
}

export default PdfLoader;
