import { Tabs } from "antd";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import ProcurementSchedule from "pages/schedule/schedule-page/procurment-schedule";
import ProjectSchedule from "pages/schedule/schedule-page/project-schedule";
import ProjectScheduleCompare from "pages/schedule/schedule-page/compare-schedule-2";
import SubmittalLinking from "pages/submittal-linking-2/submittalLinking";
import MaterialLinkingTab from "pages/submittal-linking-2/materialLinkingTab";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { isProdDemoEnv } from "utils/utils";
import { ProjectPermissionEnum } from "../../constants";
import "./schedule-menu-tabs.scss";

function ScheduleMenuTabs() {
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get("tab");
  const [tabIndex, setTabIndex] = useState<string>(tab || "2");
  const paramScheduleId = new URLSearchParams(location.search).get(
    "ScheduleId"
  );
  const history = useHistory();
  const { projectId } = useParams() as any;
  const { tokenContents } = useContext(ProjectContext);
  const isPermissionNotGrant = isPermissionNotGrantted(
    ProjectPermissionEnum.ViewLinkingPage,
    tokenContents?.role!
  );

  const isPermissionChangeImpactNotGrant = isPermissionNotGrantted(
    ProjectPermissionEnum.ImportSchedule,
    tokenContents?.role!
  );

  useEffect(() => {
    setTabIndex(tab?.toString() || "2");
  }, [tab, paramScheduleId]);

  const getTabItems = useMemo(() => {
    const tabs: any[] = [];

    if (!isProdDemoEnv()) {
      tabs.push({
        label: "Procurement Schedule (Preview)",
        key: "1",
        children: <ProcurementSchedule />
      });
    }

    tabs.push({
      label: "Project Schedule",
      key: "2",
      children: <ProjectSchedule />
    });

    if (!isPermissionChangeImpactNotGrant) {
      tabs.push({
        label: "Change Impact",
        key: "5",
        children: <ProjectScheduleCompare />
      });
    }

    if (!isPermissionNotGrant) {
      tabs.push({
        label: "Submittal Linking",
        key: "3",
        children: <SubmittalLinking />
      });
      tabs.push({
        label: "Material Linking",
        key: "4",
        children: <MaterialLinkingTab />
      });
    }

    return tabs;
  }, [isPermissionChangeImpactNotGrant, isPermissionNotGrant]);

  return (
    <div className="flex flex-col h-full schedule-tabs">
      <div className="p-2">
        <Tabs
          onTabClick={(activeKey: any, event: any) => {
            if (event?.ctrlKey) {
              window.open(
                `/project/${projectId}/schedule?tab=${activeKey.toString()}`,
                "_blank"
              );
            } else {
              history.push(
                `/project/${projectId}/schedule?tab=${activeKey.toString()}`
              );
            }
          }}
          activeKey={tabIndex}
          items={getTabItems}
          destroyInactiveTabPane
        />
      </div>
    </div>
  );
}
export default ScheduleMenuTabs;
