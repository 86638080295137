import { Input } from "antd";
import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle
} from "react";

const UserNameCellEditor = forwardRef((props: any, ref) => {
  const { data, stopEditing } = props;

  const firstNameInputRef: any = useRef();

  const [firstNameValue, setFirstNameValue] = useState(
    data.user.first_name || ""
  );
  const [lastNameValue, setLastNameValue] = useState(data.user.last_name || "");

  useEffect(() => {
    firstNameInputRef.current?.focus();
  }, [data]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return { firstNameValue, lastNameValue };
      }
    };
  });

  return (
    <div className="flex w-full h-full items-center">
      <div className="flex w-full">
        <Input
          placeholder="*First Name"
          className="w-2/5"
          tabIndex={0}
          type="text"
          ref={firstNameInputRef}
          value={firstNameValue}
          onChange={(event: any) => {
            setFirstNameValue(event.target.value);
          }}
          onPressEnter={(event: any) => {
            setFirstNameValue(event.target.value);
            stopEditing();
          }}
        />
        <Input
          placeholder="Last Name"
          className="w-3/5"
          tabIndex={0}
          value={lastNameValue}
          onChange={(event: any) => {
            setLastNameValue(event.target.value);
          }}
          onPressEnter={(event: any) => {
            setLastNameValue(event.target.value);
            stopEditing();
          }}
        />
      </div>
    </div>
  );
});

export default UserNameCellEditor;
