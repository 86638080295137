import { Tooltip } from "antd";

function CustomTooltip(props: { value: string }) {
  const { value } = props;

  return (
    <Tooltip
      destroyTooltipOnHide
      showArrow={false}
      title={value}
      placement="bottomLeft"
      autoAdjustOverflow
      overlayInnerStyle={{
        border: "1px solid #d1d1d1",
        color: "#1f2426",
        fontSize: 12
      }}
      overlayStyle={{ padding: 0 }}
    >
      {value}
    </Tooltip>
  );
}

export default CustomTooltip;
