import { Select } from "antd";
import { forwardRef, useState, useImperativeHandle, useMemo } from "react";

const SelectCellEditor = forwardRef((props: any, ref) => {
  const {
    context,
    dataMapVar,
    valueVar,
    labelVar,
    showSearch,
    stopEditing,
    notFoundContent,
    isSorting = true
  } = props;
  const [value, setValue] = useState(props.value);
  const itemsMap = context[dataMapVar];

  const dropDownOptions = useMemo(() => {
    const options = Object.keys(itemsMap).map((key: any) => {
      return {
        label: itemsMap[key][labelVar],
        value: itemsMap[key][valueVar]
      };
    });
    if (isSorting)
      return options.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return options;
  }, [isSorting, itemsMap, labelVar, valueVar]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!value) return null;
        return value;
      }
    };
  });

  return (
    <Select
      autoFocus
      defaultOpen
      placeholder={showSearch ? "Select / Search" : "Select"}
      allowClear
      showSearch={showSearch}
      optionFilterProp="label"
      options={dropDownOptions}
      virtual={false}
      notFoundContent={notFoundContent}
      style={{ width: "100%" }}
      filterOption={(input, option: any) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      onChange={(event: any) => {
        setValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    />
  );
});

export default SelectCellEditor;
