import { Button, Dropdown, Menu, MenuProps } from "antd";
import LogoutIcon from "components/svg-icons/logout-icon";
import { UserAvatar, UserAvatarWithDetails } from "components/user-avatar";
import { useState } from "react";
import { useHistory } from "react-router";
import { getUser } from "services/auth";

const ACCESS_TOKEN_KEY = "accToken";
const SUB_ID = "subId";

function AdminAccountMenu() {
  const history = useHistory();
  const [currentUser]: [any, any] = useState(getUser());

  const logout = () => {
    try {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(SUB_ID);
      history.push("/");
    } catch (ex) {
      console.error(ex);
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      label: (
        <UserAvatarWithDetails
          showAvatar={false}
          fullName={`${currentUser["first-name"]} ${currentUser["last-name"]}`}
          email={currentUser.email}
        />
      ),
      key: "profile"
    },
    {
      label: (
        <Button
          onClick={() => logout()}
          className="flex w-full h-8 items-center justify-between  bg-[#E8E8E8] px-4 "
        >
          <div className="flex h-full items-center font-medium text-xs text-[#3B3B3B]">
            Logout
          </div>
          <LogoutIcon />
        </Button>
      ),
      key: "sign-out",
      className: "p-0"
    }
  ];
  const menu = <Menu className="p-0 space-y-1" items={menuItems} />;

  return (
    <Dropdown arrow placement="bottomRight" overlay={menu} trigger={["click"]}>
      <div data-cy="account" className="accMenuContainer">
        <UserAvatar
          url=""
          isRect={false}
          fullName={`${currentUser["first-name"]} ${currentUser["last-name"]}`}
        />
      </div>
    </Dropdown>
  );
}
export default AdminAccountMenu;
