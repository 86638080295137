import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  QUERY_MATERIAL_RISK_REPORT,
  QUERY_SUBMITTAL_RISK_REPORT_INTG,
  QUERY_SUBMITTAL_RISK_REPORT_WF
} from "services/graphQL/queries";

export const useCiqSubmittalRiskReportList = <TData = any>({
  gqlClientForProject,
  isIntegrationMode,
  variables
}: {
  gqlClientForProject: any;
  isIntegrationMode: boolean;
  variables: any;
}): {
  data: TData;
} => {
  const SUBMITTAL_RISK_REPORT = isIntegrationMode
    ? QUERY_SUBMITTAL_RISK_REPORT_INTG
    : QUERY_SUBMITTAL_RISK_REPORT_WF;

  const { data } = useCIQQuery<any>(SUBMITTAL_RISK_REPORT, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables
  });

  return { data };
};

export const useCiqMaterialRiskReportList = <TData = any>({
  gqlClientForProject,
  variables
}: {
  gqlClientForProject: any;
  variables: any;
}): {
  data: TData;
} => {
  const { data } = useCIQQuery<any>(QUERY_MATERIAL_RISK_REPORT, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables
  });

  return { data };
};
