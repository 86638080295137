import { Button, Form, message, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { SubmittalLog } from "models/submittal-log";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getLoggedinUserId } from "services/auth";
import { getUserCompany } from "utils/utils";
import { QUERY_PROJECT_USERS } from "services/graphQL/queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { EUserTypes } from "../../constants";
import { updateSubmittalOne } from "./helpers";

const { Option } = Select;

type TadvanceAssignModalProps = {
  projectId: string;
  submittalId: string;
  isModalOpen: boolean;
  onDoneCb: Function;
  submittalData: SubmittalLog;
};

export default function AdvanceAssignModal(props: TadvanceAssignModalProps) {
  const { projectId, submittalId, isModalOpen, onDoneCb, submittalData } =
    props;

  const { gqlClientForProject, tokenContents }: TProjectContext =
    useContext(ProjectContext);

  const [form] = Form.useForm();

  const loggedInUserId = getLoggedinUserId();

  let where: any = {
    project_id: { _eq: projectId }
  };

  if (tokenContents?.claims["x-hasura-vendor-id"]) {
    where = {
      ...where,
      external: { _eq: true },
      subscription_vendor: {
        id: {
          _eq: tokenContents?.claims["x-hasura-vendor-id"]
        }
      }
    };
  } else if (tokenContents?.claims["x-hasura-subscription-id"]) {
    where = {
      ...where,
      subscription_id: {
        _eq: tokenContents?.claims["x-hasura-subscription-id"]
      }
    };
  }

  const [savingAssignee, setSavingAssignee] = useState(false);

  const { data: submitters, loading } = useCIQQuery(QUERY_PROJECT_USERS, {
    client: gqlClientForProject,
    variables: {
      where
    }
  });

  const onFormSubmit = async (formdata: any) => {
    const postObj: any = {
      assignee: formdata.assignee
    };

    if (formdata.comment && formdata.comment.trim() !== "") {
      postObj.comment = formdata.comment.trim();
    }

    setSavingAssignee(true);

    try {
      const updateResponse = await updateSubmittalOne(
        {
          submittal_id: submittalId,
          ...postObj
        },
        gqlClientForProject
      );
      // console.log("updateResponse ", updateResponse);
      if (updateResponse && updateResponse.data) {
        message.success("Updated successfully");
        onDoneCb(false);
      }
    } catch (ex: any) {
      message.error(ex);
    }

    setSavingAssignee(false);
  };

  const getAsigneeList = useCallback(() => {
    if (!submitters) return [];
    switch (tokenContents?.userType) {
      case EUserTypes[EUserTypes.GENERAL_CONTRACTOR]:
        if (loggedInUserId === submittalData.gc_reviewer) {
          return submitters.filter(
            (item: any) => item.user_id !== loggedInUserId
          );
        }
        return submitters.filter(
          (item: any) => item.user_id === submittalData.gc_reviewer
        );
        break;
      case EUserTypes[EUserTypes.SUBCONTRACTOR]:
        if (loggedInUserId === submittalData.submitter) {
          return submitters.filter(
            (item: any) => item.user_id !== loggedInUserId
          );
        }
        return submitters.filter(
          (item: any) => item.user_id === submittalData.submitter
        );
        break;
      case EUserTypes[EUserTypes.ARCHITECT] || EUserTypes[EUserTypes.ENGINEER]:
        if (loggedInUserId === submittalData.design_reviewer) {
          return submitters.filter(
            (item: any) => item.user_id !== loggedInUserId
          );
        }
        return submitters.filter(
          (item: any) => item.user_id === submittalData.design_reviewer
        );
        break;
      default:
        return [];
    }
  }, [
    loggedInUserId,
    submittalData.design_reviewer,
    submittalData.gc_reviewer,
    submittalData.submitter,
    submitters,
    tokenContents?.userType
  ]);

  const assigneeList = useMemo(getAsigneeList, [getAsigneeList]);

  useEffect(() => {
    if (assigneeList && assigneeList.length === 1) {
      form.setFieldsValue({ assignee: assigneeList[0].user.id });
    }
  }, [assigneeList, form]);

  return (
    <Modal
      title="Alternate Assign"
      open={isModalOpen}
      footer={null}
      onCancel={() => {
        onDoneCb(false);
      }}
    >
      <div>
        {isModalOpen && (
          <Form
            name="advancedAssign"
            layout="vertical"
            initialValues={{ remember: false }}
            onFinish={onFormSubmit}
            onFinishFailed={() => {}}
            autoComplete="off"
            className="space-y-3"
            form={form}
          >
            <Form.Item
              label="ASSIGNEE"
              name="assignee"
              rules={[
                {
                  required: true,
                  message: "Please select an assignee"
                }
              ]}
            >
              <Select placeholder="Select an assignee" loading={loading}>
                {(assigneeList || []).map((item: any) => {
                  return (
                    <Option key={item.user.id} value={item.user.id}>
                      <div>
                        {item.user.first_name} {item.user.last_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {getUserCompany(item)}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item label="COMMENT" name="comment">
              <TextArea placeholder="Comments" />
            </Form.Item>

            <Form.Item>
              <div className="flex gap-x-2 justify-end mt-3">
                <Button
                  htmlType="button"
                  onClick={() => {
                    onDoneCb(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={savingAssignee}
                  loading={savingAssignee}
                >
                  Assign
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </div>
    </Modal>
  );
}
