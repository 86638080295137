import { Select } from "antd";
import { forwardRef, useState, useImperativeHandle } from "react";
import { EUserTypes, UserProjectRoles } from "../../constants";

const { Option } = Select;

type Role = {
  id: number;
  name: string;
  type_id: number;
};

const ProjectUserRoleSelectCellEditor = forwardRef((props: any, ref) => {
  const { stopEditing, value, data, context } = props;
  const { allProjectRoles = [] } = context;
  const [selectValue, setSelectValue] = useState(value);

  let projectRoles = allProjectRoles;

  const projectVendorType =
    data?.subscription_vendor?.project_vendors[0]?.project_vendors_type?.id ||
    EUserTypes.GENERAL_CONTRACTOR;

  projectRoles = allProjectRoles?.filter((role: any) =>
    UserProjectRoles[projectVendorType].includes(role.key)
  );

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!selectValue) return null;
        return selectValue;
      }
    };
  });

  return (
    <Select
      open
      allowClear
      optionFilterProp="label"
      virtual={false}
      style={{ width: "100%" }}
      showSearch
      // notFoundContent={loading ? "" : notFoundContent}
      onChange={(id) => {
        setSelectValue(() => id);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
      loading={!projectRoles}
    >
      {projectRoles &&
        projectRoles
          .filter((x: Role) => x.type_id === data?.user_type?.id)
          .map((role: Role) => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
    </Select>
  );
});

export default ProjectUserRoleSelectCellEditor;
