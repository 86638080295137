function AsterixIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <text x="2" y="14" fontSize="15" fill="currentColor">
        *
      </text>
    </svg>
  );
}

export default AsterixIcon;
