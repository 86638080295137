import {
  Button,
  Form,
  Input,
  Spin,
  Tooltip,
  Typography,
  message as messages
} from "antd";
import { AppLogoIcon } from "components/svg-icons";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { SetupAccountRequest } from "models/user";
import { setupAccount, validateUser } from "services/auth";
import { useHistory } from "react-router";
import { ErrorMessages, Regex } from "constants/index";
import jwtDecode from "jwt-decode";
import { InfoCircleOutlined } from "@ant-design/icons";

function SetupAccount() {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [loader, setLoader] = useState(false);
  const [isEnvAdmin, setEndAdmin] = useState(false);
  const history = useHistory();

  const params = new URLSearchParams(window.location.search);
  const accessToken = params.get("token") ?? "";
  const [readonlyPhone, setReadOnlyForm] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [isSessionExpired, setSessionExpired] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const goToLoginComponent = (
    <div>
      Password is successfully set.{" "}
      <span>
        <a href={isEnvAdmin ? "/admin/#/" : "/"} className="underline">
          Click here
        </a>
      </span>{" "}
      to Login.
    </div>
  );

  useEffect(() => {
    if (accessToken) {
      const jwt: any = jwtDecode(accessToken);
      if (!jwt) return;

      const expirationTime = jwt.exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        setDisabled(true);
        setSessionExpired(true);
        setLoading(false);
        messages.error(ErrorMessages.sessionExpiredMsg);
        return;
      }
      const validateUserToken = async () => {
        if (!jwt) return;

        const userId = jwt["https://hasura.io/jwt/claims"]["x-hasura-user-id"];

        const result: any = await validateUser(
          userId,
          accessToken,
          undefined,
          undefined
        );

        setLoading(false);

        if (result?.data?.success) {
          if (result?.data?.success?.status === "USER_ACCOUNT_SETUP_NOT_DONE") {
            const firstName = jwt["first-name"];
            const lastName = jwt["last-name"];

            if (
              jwt["https://hasura.io/jwt/claims"]["x-hasura-default-role"] ===
              "env_admin"
            )
              setEndAdmin(true);

            form.setFieldsValue({
              first_name: firstName,
              last_name: lastName
            });
          } else if (
            result?.data?.success?.status === "USER_ACCOUNT_SETUP_DONE"
          ) {
            setSessionExpired(true);
            messages.success({
              content: (
                <div>
                  Password has already been set{" "}
                  <span>
                    <a
                      href={isEnvAdmin ? "/admin/#/" : "/"}
                      className="underline"
                    >
                      Click here
                    </a>
                  </span>{" "}
                  to Login.
                </div>
              ),
              duration: 60
            });
          } else {
            setSessionExpired(true);
            messages.success(result?.data?.success?.message);
            history.push("/");
          }
        }
        if (result?.response?.data?.error) {
          setSessionExpired(true);
          if (result?.response?.data?.error === "Link has expired.") {
            messages.error(ErrorMessages.sessionExpiredMsg);
          } else {
            messages.error(result?.response?.data?.error || "");
          }
        }
      };
      validateUserToken();
    }
  }, [accessToken, form, history, isEnvAdmin]);

  useEffect(() => {
    if (readonlyPhone)
      setTimeout(() => {
        setReadOnlyForm(false);
      }, 1000);
  }, [readonlyPhone]);

  const onFinish = async (event: SetupAccountRequest) => {
    setLoader(true);

    const result: any = await setupAccount({
      password: event.password,
      token: accessToken,
      first_name: event.first_name,
      last_name: event.last_name,
      phone: event.phone
    });

    setLoader(false);
    if (result.status >= 200 && result.status <= 204) {
      messages.success({
        content: goToLoginComponent,
        duration: 60
      });
    } else if (result.response?.data?.error) {
      const erroMsg =
        result.response?.data?.error === "Link has expired."
          ? ErrorMessages.sessionExpiredMsg
          : result.response?.data?.error;
      messages.error(erroMsg);
      if (result.response?.data?.error === "Link has expired.") {
        setSessionExpired(true);
        if (isEnvAdmin) history.push("/admin/#/");
        else history.push("/");
      }
    } else {
      messages.error(result.message);
    }

    form.resetFields();
  };

  if (isLoading) {
    return (
      <div className="flex min-w-full min-h-screen bg-[#f7f6f4] items-center justify-center p-2">
        <Spin />
      </div>
    );
  }

  return (
    <div className="flex min-w-full min-h-screen bg-[#f7f6f4] items-center justify-center p-2">
      <div className="flex flex-col p-8 shadow-[0px_5px_25px_rgba(0,0,0,0.1)] w-[400px] rounded-md">
        <div className="m-auto block">
          <AppLogoIcon />
        </div>
        {!isSessionExpired && (
          <div>
            <div className="flex justify-center">
              <Title level={3}>Set Password</Title>
            </div>
            <Form
              onFinish={onFinish}
              className="space-y-3"
              form={form}
              scrollToFirstError
              layout="vertical"
              disabled={isDisabled}
            >
              <FormItem
                name="first_name"
                label="First Name"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.FirstName
                  }
                ]}
              >
                <Input placeholder="Enter First Name" />
              </FormItem>
              <FormItem
                name="last_name"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.LastName
                  }
                ]}
              >
                <Input placeholder="Enter Last Name" />
              </FormItem>
              <FormItem
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    message: ErrorMessages.PhoneNumber,
                    validateTrigger: "onSubmit",
                    pattern: Regex.phoneNumber
                  }
                ]}
              >
                <Input
                  placeholder="Enter Phone Number"
                  readOnly={readonlyPhone}
                />
              </FormItem>
              <FormItem
                name="password"
                label={
                  <div>
                    Password{" "}
                    <Tooltip title={ErrorMessages.PasswordRequired}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                className="password"
                validateStatus=""
                rules={[
                  {
                    validateTrigger: "onSubmit",
                    required: true,
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$£@_%+=.,^&(){}[\]|;:'"”<>?\\]).{12,}$/,
                    message: ErrorMessages.PasswordRequired
                  }
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Enter Password" />
              </FormItem>
              <FormItem
                name="confirmpassword"
                label="Confirm Password"
                className="password"
                dependencies={["password"]}
                hasFeedback
                validateStatus=""
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.ConfirmPasswordRequired
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(ErrorMessages.ConfirmPasswordRequired)
                      );
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Enter Confirm Password" />
              </FormItem>
              <div style={{ paddingTop: "1em" }}>
                <Button
                  htmlType="submit"
                  size="middle"
                  style={{ margin: "auto", display: "block" }}
                  loading={loader}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
}
export default SetupAccount;
