export function SpecFileModalHeader(props: {
  spec_no: string;
  spec_name: string;
}) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { spec_no, spec_name } = props;
  return (
    <div className="w-full grid grid-cols-3 gap-x-6">
      <div className="col-span-2 truncate">
        {spec_no && <span>{spec_no} -</span>} {spec_name || ""}
      </div>
    </div>
  );
}
