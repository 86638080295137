import { Form } from "antd";
import DistributionList from "components/distribution-list-submittal/dl-submittal-details";
import { ESubmittalStatus } from "../../../constants";

function ReadOnlyWorkflowCard(props: any) {
  const {
    submittalData,
    submitterUserOptions,
    getGCReviewerOptions,
    getDReviewerOptions,
    responsibleContractorOptions,
    isIntegrationMode,
    submittalViewState,
    submittalHeaderMap
  } = props;

  const getSubmitter = () => {
    if (!submitterUserOptions || !submitterUserOptions.length) return null;
    const user = submitterUserOptions[0];
    return {
      fullName: `${user.first_name} ${user.last_name}`,
      company: user.company.name
    };
  };

  const getGCReviewer = () => {
    const gcReviewerOptions = getGCReviewerOptions();
    if (!gcReviewerOptions || !gcReviewerOptions.length) return null;
    return {
      fullName: `${gcReviewerOptions[0].first_name} ${gcReviewerOptions[0].last_name}`
    };
  };

  const getDesignReviewer = () => {
    const designReviewerOptions = getDReviewerOptions();
    if (!designReviewerOptions || !designReviewerOptions.length) return null;
    const user = designReviewerOptions[0];
    return {
      fullName: `${user.first_name} ${user.last_name}`
    };
  };

  const getResponsibleCont = () => {
    const respContOptions = responsibleContractorOptions;
    if (!respContOptions || !respContOptions.length) return null;
    const cont = respContOptions[0];
    return cont.name;
  };

  return (
    <div>
      <Form.Item label="Submitter">
        <span>{getSubmitter()?.fullName}</span>
      </Form.Item>
      <Form.Item label="GC Reviewer">
        <span>{getGCReviewer()?.fullName}</span>
      </Form.Item>
      <Form.Item label="Design Reviewer">
        <span>{getDesignReviewer()?.fullName}</span>
      </Form.Item>
      <DistributionList
        submittalId={submittalData.id}
        disabled={
          isIntegrationMode ||
          submittalViewState.status !== ESubmittalStatus.CREATE
        }
      />

      <Form.Item label="Responsible Contractor">
        <span>{getResponsibleCont()}</span>
      </Form.Item>
      <Form.Item
        label={submittalHeaderMap?.watchers?.toUpperCase() || "WATCHERS"}
      >
        <span>{submittalData.watchers}</span>
      </Form.Item>
    </div>
  );
}

export default ReadOnlyWorkflowCard;
