import { useEffect, useMemo, useState } from "react";
import {
  PROJECT_INTG_CONFIGS,
  SUBMITTAL_LIST_WF,
  SUBMITTAL_LIST_INTG
} from "services/graphQL/ciq-queries";
import { getGQL } from "utils/utils";
import { ProjectSettingType } from "context/ProjectProvider";
import { useQuerySubscription } from "./common";

export const useSubmittalList = (params: {
  gqlClientForProject: any;
  projectDetails: ProjectSettingType | undefined;
  isIntegrationMode: any;
  skipSubscribe: any;
  tokenContents: any;
}): { data: any; error: any; loading: boolean; loadedOnce: boolean } => {
  const {
    gqlClientForProject,
    projectDetails,
    isIntegrationMode,
    skipSubscribe,
    tokenContents
  } = params;

  const [submittalListLoadedOnce, setSubmittalListLoadedOnce] = useState(false);

  const isWFMode = !isIntegrationMode;

  // if project is in integration mode, skipForWF will be true;
  const skipForWF =
    !projectDetails ||
    isIntegrationMode ||
    skipSubscribe ||
    !tokenContents ||
    !gqlClientForProject;

  // if project is in workflow mode, skipForINTG will be true;
  const skipForINTG =
    !projectDetails ||
    isWFMode ||
    skipSubscribe ||
    !tokenContents ||
    !gqlClientForProject;

  const { query: qWFSubmittalList, sub: sWFSubmittalList } =
    getGQL(SUBMITTAL_LIST_WF);

  const {
    data: subWFsubmittalListData,
    error: subWFSubmittalListError,
    loading: subWFSubmittalListLoading
  } = useQuerySubscription(qWFSubmittalList, sWFSubmittalList, {
    client: gqlClientForProject,
    skip: skipForWF
  });

  const { query: qINTGSubmittalList, sub: sINTGSubmittalList } =
    getGQL(SUBMITTAL_LIST_INTG);
  const {
    data: submittalListIntegrationModeData,
    error: INTGSubmittalListError,
    loading: INTGSubmittalListLoading
  } = useQuerySubscription(qINTGSubmittalList, sINTGSubmittalList, {
    client: gqlClientForProject,
    skip: skipForINTG
  });

  useEffect(() => {
    if (subWFSubmittalListLoading || INTGSubmittalListLoading) {
      setSubmittalListLoadedOnce(true);
    }
  }, [subWFSubmittalListLoading, INTGSubmittalListLoading]);

  const finalSubmittalList = useMemo(() => {
    return (
      subWFsubmittalListData?.submittal_list_wf_mode_func ||
      submittalListIntegrationModeData?.submittal_list_intg_mode_func
    );
  }, [
    subWFsubmittalListData?.submittal_list_wf_mode_func,
    submittalListIntegrationModeData?.submittal_list_intg_mode_func
  ]);

  return {
    data: finalSubmittalList,
    error: subWFSubmittalListError || INTGSubmittalListError,
    loading: subWFSubmittalListLoading || INTGSubmittalListLoading,
    loadedOnce: submittalListLoadedOnce
  };
};

export const useGetProjectINTGState = ({ skip, client }: any) => {
  getGQL(PROJECT_INTG_CONFIGS);

  const { query: qProjectIntgConfigs, sub: sProjectIntgConfigs } =
    useMemo(() => {
      return getGQL(PROJECT_INTG_CONFIGS);
    }, []);

  const { data, error } = useQuerySubscription(
    qProjectIntgConfigs,
    sProjectIntgConfigs,
    {
      client,
      skip
    }
  );

  const isIntegrationConfigured = !!(
    data && data?.project_integration_configs.length
  );

  return {
    data,
    error,
    isIntegrationConfigured
  };
};
