import { useContext, useEffect, useState } from "react";
import CiqAgGrid from "components/ciq-ag-grid";
import { AgGridReact } from "ag-grid-react";
import { ProjectContext } from "context/ProjectProvider";
import { TLinkedSubmittal } from "../models";
import { submittalColDefs } from "../col-defs/submittal-col-def";

const MinHeight = 120;

function SubmittalGrid({
  submittals,
  gridRef
}: {
  submittals: Array<TLinkedSubmittal>;
  gridRef: React.RefObject<AgGridReact<TLinkedSubmittal>>;
}) {
  const { projectDetails } = useContext(ProjectContext);
  const [height, setHeight] = useState(MinHeight);

  useEffect(() => {
    const headerHeight = 75;
    const total = headerHeight + submittals.length * 42;

    setHeight(MinHeight > total ? MinHeight : total);
  }, [submittals]);

  return (
    <div className="flex min-h-[120px]" style={{ height }}>
      <CiqAgGrid
        {...{
          gridRef,
          columnDefs: submittalColDefs,
          rowData: submittals,
          sideBar: null,
          context: { projectDetails },
          suppressMovableColumns: true
        }}
      />
    </div>
  );
}
export default SubmittalGrid;
