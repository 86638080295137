/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable default-case */
import { useState, useMemo, useEffect, useRef, useContext } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { Button, message } from "antd";
import InviteSubscriptionUserComponent from "pages/invite-user/invite-subscription-user";
import { useMutation, useSubscription } from "@apollo/client";
import {
  SUBSCRIPTION_USERS,
  SUBSCRIPTION_USER_SUBSCRIPTION_ROLES
} from "services/graphQL/subscriptions";
import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";

import {
  InviteStatusCellRenderer,
  userNameRenderer,
  userRoleRenderer
} from "components/cell-renders";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { ColDef } from "ag-grid-community";
import { GridLoadingIndicator } from "components/widgets";
import UserNameCellEditor from "components/cell-editor/user-name-cell-editor";
import UserRoleSelectCellEditor from "components/cell-editor/user-role-cell-editor";
import {
  MUTATION_UPDATE_SUBSCRIPTION_USER_NAME,
  MUTATION_UPDATE_SUBSCRIPTION_USER_PERMISSION
} from "services/graphQL/mutations";
import { FilterItem } from "models/types";
import { FilterChips } from "components";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { QUERY_GET_PROJECT_LIST } from "services/graphQL/queries";
import { getSource } from "utils/utils";
import InviteAccUserComponent from "pages/invite-user/invite-subscription-user/invite-acc-user";
import {
  AcceptanceStatus,
  DialogTitles,
  ErrorMessages,
  ESubmittalMgmtModes
} from "../../constants";

const hasNameCellEditAccess = (params: any) => {
  const { context, data } = params;
  return (
    context.hasAdminAccess &&
    !(
      context.subscriptionRole === RoleSubscriptionEnum.subscription_editor &&
      data.subscription_permission.name === "Admin"
    )
  );
};

const nameRendererCell = (params: any) => {
  if (hasNameCellEditAccess(params)) return userNameRenderer(params);

  return <div>{params.value}</div>;
};

const hasPermissionCellEditAccess = (params: any) => {
  const { context, data } = params;
  return (
    context.hasAdminAccess && data?.subscription_permission?.name !== "Admin"
  );
};

const permissionRendererCell = (params: any) => {
  if (hasPermissionCellEditAccess(params)) return userRoleRenderer(params);
  return <div>{params.value}</div>;
};

function Users() {
  const gridRef = useRef<AgGridReact>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [drawer, setDrawerVisibility] = useState<boolean>(false);
  const [allUsers, setUsers] = useState([]);
  const { subscriptionRole, subscriptionId } = useContext(SubscriptionContext);
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [inviteAccUser, setInviteAccUser] = useState<{
    showInviteModel: boolean;
    inviteUserData?: any;
  }>({
    showInviteModel: false,
    inviteUserData: null
  });

  const hasAdminAccess =
    subscriptionRole === RoleSubscriptionEnum.subscription_admin ||
    subscriptionRole === RoleSubscriptionEnum.subscription_editor;

  const {
    data: usersData,
    error,
    loading
  } = useSubscription(SUBSCRIPTION_USERS, {
    shouldResubscribe: true
  });

  const { data: userSubscriptionRoles } = useSubscription(
    SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
    {
      shouldResubscribe: true
    }
  );

  const { data: projectListData } = useCIQQuery(QUERY_GET_PROJECT_LIST, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        status_id: { _neq: AcceptanceStatus.DEACTIVATED }
      }
    }
  });

  const isIntegratedProjectExist = useMemo(() => {
    if (projectListData && projectListData?.project_list_func.length > 0) {
      const isIntegratedProjExist = projectListData?.project_list_func.some(
        (proj: any) => proj?.mode === ESubmittalMgmtModes.INTEGRATION_MODE
      );
      return isIntegratedProjExist;
    }
    return false;
  }, [projectListData]);

  const [updateUserPermission] = useMutation<any>(
    MUTATION_UPDATE_SUBSCRIPTION_USER_PERMISSION
  );

  const [updateUserName] = useMutation<any>(
    MUTATION_UPDATE_SUBSCRIPTION_USER_NAME
  );

  const setDrawerAction = (drawerAction: boolean) => {
    setDrawerVisibility(drawerAction);
  };

  const getSubscriptionUserSources = (params: any) => {
    const allSources: string[] = [];
    if (params?.user?.project_users) {
      const projectUserArr = params?.user?.project_users || [];
      projectUserArr?.forEach((projectUserItem: any) => {
        if (projectUserItem?.project_integration_users?.length > 0) {
          projectUserItem.project_integration_users
            .map((piu: any) => piu.full_source_name)
            ?.reduce((acc: string[], item: string) => {
              if (!acc.includes(item)) {
                acc.push(item);
              }
              return acc;
            }, allSources);
        }
      });
    }
    const originalSource = getSource(params?.external_source_id);
    if (!allSources.includes(originalSource)) {
      allSources.push(originalSource);
    }
    return allSources;
  };

  useEffect(() => {
    if (isGridReady && gridRef && gridRef.current) {
      if (loading) {
        gridRef.current?.api.showLoadingOverlay();
      } else {
        gridRef.current?.api.hideOverlay();
      }
    }
    if (error) {
      gridRef.current?.api.hideOverlay();
      message.error(error.message);
      return;
    }
    if (!usersData) return;
    setUsers(usersData.subscription_users);
  }, [error, loading, usersData, isGridReady]);

  // Each Column Definition results in one Column.
  const columnDefs: ColDef[] = useMemo<ColDef[]>(() => {
    return [
      {
        valueGetter: (params: any) => {
          return `${params.data.user?.first_name} ${params.data.user?.last_name}`;
        },
        field: "name",
        headerName: "Name",
        headerTooltip: "NAME",
        tooltipValueGetter: (params: any) => {
          return `${params.data.user?.first_name} ${params.data.user?.last_name}`;
        },
        menuTabs: [],
        comparator: (valueA: any, valueB: any) => {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        editable: hasNameCellEditAccess,
        cellRenderer: nameRendererCell,
        cellEditor: UserNameCellEditor,
        cellClass: (params: any) => {
          return params.context.hasAdminAccess ? "cell-editable" : "";
        },
        sort: "asc"
      },
      {
        valueGetter: (params: any) => {
          return params.data.user.email;
        },
        headerName: "Email",
        headerTooltip: "EMAIL",
        tooltipField: "user.email",
        menuTabs: [],
        field: "email",
        colId: "email"
      },
      {
        valueGetter: (params: any) => {
          const { data } = params;
          const sources = getSubscriptionUserSources(data);
          if (sources.includes("ConstructivIQ")) {
            if (data?.subscription_vendor?.name)
              return data?.subscription_vendor?.name;
            if (data?.subscription?.organization_subscriptions?.length > 0) {
              return (
                data?.subscription?.organization_subscriptions[0]?.organization
                  ?.name || ""
              );
            }
          }
          return data?.subscription_vendor?.name || "";
        },
        headerName: "Company",
        headerTooltip: "COMPANY",
        filter: true,
        menuTabs: ["filterMenuTab"],
        field: "company",
        colId: "company",
        tooltipValueGetter: ({ value }: any) => value
      },
      {
        valueGetter: (params: any) => {
          return params.data.subscription_permission?.name;
        },
        field: "permission",
        colId: "permission",
        headerName: "Permission",
        headerTooltip: "PERMISSION",
        tooltipField: "subscription_permission.name",
        filter: true,
        menuTabs: ["filterMenuTab"],
        editable: hasPermissionCellEditAccess,
        cellRenderer: permissionRendererCell,
        cellEditor: UserRoleSelectCellEditor,
        cellClass: (params: any) => {
          return params.context.hasAdminAccess ? "cell-editable" : "";
        }
      },
      {
        valueGetter: (params: any) => {
          return params.data.user.phone;
        },
        field: "phone",
        headerName: "Phone Number",
        headerTooltip: "PHONE NUMBER",
        tooltipField: "user.phone",
        menuTabs: [],
        colId: "phone"
      },
      {
        colId: "source",
        headerName: "Source",
        menuTabs: ["filterMenuTab"],
        hide: !isIntegratedProjectExist,
        valueGetter: ({ data }) => {
          const sources = getSubscriptionUserSources(data);
          return sources.join(",");
        }
      },
      {
        field: "status_id",
        colId: "status_id",
        headerName: "Invite Status",
        headerTooltip: "INVITE STATUS",
        minWidth: 220,
        menuTabs: [],
        cellRenderer: InviteStatusCellRenderer,
        cellRendererParams: { inviteType: "Subscription" },
        comparator: (valueA: any, valueB: any) => {
          return AcceptanceStatus[valueA]
            .toLowerCase()
            .localeCompare(AcceptanceStatus[valueB].toLowerCase());
        },
        tooltipValueGetter: (params) => {
          let status = "";
          switch (params.data.status_id) {
            case 1:
              status = "Pending";
              break;
            case 2:
              status = "Active";
              break;
            case 3:
              status = "Deactivated";
              break;

            default:
              break;
          }
          return status;
        }
      }
    ];
  }, [isIntegratedProjectExist]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      resizable: true
    };
  }, []);

  const onFilterTextBoxChanged = (value: string) => {
    gridRef.current!.api.setQuickFilter(value);
  };

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef.current!.api.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const onUserNameCellEdit = async (event: any) => {
    const firstName = event.newValue.firstNameValue?.trim();
    const lastName = event.newValue.lastNameValue?.trim();

    const oldData = { ...event.data };

    if (
      firstName === "" ||
      (firstName === oldData.user.first_name &&
        lastName === oldData.user.last_name)
    )
      return;

    event.node.setData({
      ...oldData,
      user: {
        first_name: firstName,
        last_name: lastName
      }
    });

    const variables = {
      pk_columns: { id: event.data?.user_id },
      _set: { first_name: firstName, last_name: lastName }
    };
    // eslint-disable-next-line no-await-in-loop
    const updateResponse = await updateUserName({
      variables
    });
    try {
      if (updateResponse.data?.update_user_by_pk) {
        message.success("User name updated successfully.");
      }
    } catch (ex) {
      message.error("Failed to update");
      event.node.setData({
        user: {
          first_name: oldData.user.first_name,
          last_name: oldData.user.last_name
        }
      });
    }
  };

  const onUserPermissionCellEdit = async (event: any) => {
    if (event.newValue?.trim() === event.oldValue?.trim()) return;

    const userRole = userSubscriptionRoles?.subscription_permission?.find(
      (role: any) => role?.name === event.newValue
    );

    if (userRole?.id) {
      const variables = {
        where: {
          subscription_id: { _eq: subscriptionId },
          user_id: { _eq: event.data.user_id }
        },
        _set: { subscription_permission_id: userRole?.id }
      };
      // eslint-disable-next-line no-await-in-loop
      const updateResponse = await updateUserPermission({
        variables
      });
      try {
        if (updateResponse.data?.update_subscription_users) {
          message.success("User permission updated successfully.");
        } else {
          message.error("Failed to update.");
        }
      } catch (ex) {
        message.error("An error occured");
      }
    }
  };

  const cellEditRequest = async (event: any) => {
    if (!event.newValue) return;
    if (event.column.colId === "name") {
      onUserNameCellEdit(event);
      return;
    }

    if (event.column.colId === "permission") {
      onUserPermissionCellEdit(event);
    }
  };

  const onFilterChipDelete = (item: FilterItem) => {
    if (gridRef.current) {
      const filterInstance = gridRef.current.api.getFilterInstance(item.field);
      if (filterInstance) {
        const model = filterInstance.getModel();
        const values = model.values.filter((val: any) => val !== item.value);
        if (values.length === 0) {
          gridRef?.current?.api.destroyFilter(item.field);
          return;
        }
        filterInstance.setModel({ values });
        gridRef.current.api.onFilterChanged();
      }
    }
  };

  const resetFilters = () => {
    setShowClearBtn(false);
    // setGridSearchtext("");
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "name" || "email" || "phone" || "status_id":
          columnDefArr[index].sortable = true;
          break;
        default:
          columnDefArr[index].sortable = true;
          columnDefArr[index].suppressMenu = false;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
    gridRef.current!.api.setFilterModel(null);
    gridRef.current!.api.setQuickFilter("");
  };

  function setSortAndFilterVisibility(
    isEnabled: boolean,
    isRowDataUpdated: boolean
  ) {
    setShowClearBtn(isRowDataUpdated ? false : !isEnabled);
    const columnDefArr = columnDefs;
    columnDefArr.forEach((colDef, index) => {
      switch (colDef.colId) {
        case "name" || "email" || "phone" || "status_id":
          columnDefArr[index].sortable = isEnabled;
          break;
        default:
          columnDefArr[index].sortable = isEnabled;
          columnDefArr[index].suppressMenu = !isEnabled;
          break;
      }
    });
    gridRef.current!.api.setColumnDefs(columnDefArr);
  }

  const renderUsers = () => {
    if (usersData && usersData.subscription_users.length === 0) {
      return (
        <div>Start by adding users of your company you want work with</div>
      );
    }

    const onFiltersApplied = (event: any) => {
      const filtersApplied = event.api.getFilterModel();
      if (filtersApplied) {
        const items: FilterItem[] = new Array<FilterItem>();
        Object.keys(filtersApplied).forEach((key: any) => {
          if (
            filtersApplied[key]?.values &&
            filtersApplied[key].values.length > 0
          ) {
            const field = columnDefs.filter((x) => x.field === key)[0];
            const { values } = filtersApplied[key];

            items.push({
              field: key,
              header: field ? field.headerName : key,
              value: values.filter(Boolean)
            });
          }
        });
        setFilters(items.filter(Boolean));
      }

      if (
        gridRef?.current &&
        gridRef.current.api?.getDisplayedRowCount() < 2 &&
        gridRef.current.api?.isAnyFilterPresent()
      ) {
        setSortAndFilterVisibility(false, false);
      } else {
        setSortAndFilterVisibility(true, false);
      }
    };

    return (
      <AgGridReact<any>
        className="ag-theme-alpine"
        ref={gridRef}
        onGridReady={() => {
          setGridReady(true);
          gridRef.current!.api.sizeColumnsToFit();
        }}
        rowData={allUsers}
        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows // Optional - set to 'true' to have rows animate when sorted
        loadingOverlayComponent={GridLoadingIndicator}
        readOnlyEdit
        singleClickEdit
        onCellEditRequest={cellEditRequest}
        stopEditingWhenCellsLoseFocus
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
        context={{
          hasAdminAccess,
          subscriptionRole,
          userSubscriptionRoles,
          setInviteAccUser
        }}
        onFilterChanged={onFiltersApplied}
        onRowDataUpdated={() => {
          if (
            gridRef.current &&
            gridRef.current.api.getDisplayedRowCount() < 2
          ) {
            setSortAndFilterVisibility(false, true);
          } else {
            setSortAndFilterVisibility(true, true);
          }
        }}
      />
    );
  };

  return (
    <>
      <ActionBar>
        <div className="flex w-full justify-end space-x-2 items-center">
          <SearchInput
            placeholder="Search Users"
            onChange={onFilterTextBoxChanged}
          />
          <FilterChips
            items={filters}
            onChipDelete={onFilterChipDelete}
            onCategoryDelete={(item: FilterItem) => {
              gridRef?.current?.api?.destroyFilter(item.field);
            }}
            resetAll={resetFilters}
          />
          {showClearBtn && !filters?.length && (
            <Button
              onClick={() => {
                filters.forEach((item: any) => {
                  gridRef?.current?.api?.destroyFilter(item.field);
                });

                resetFilters();
              }}
            >
              Clear All
            </Button>
          )}
          <Button
            onClick={() => setDrawerVisibility(true)}
            title={
              subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
                ? ErrorMessages.PermissionNotGranted
                : "Add User"
            }
            disabled={
              subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
            }
          >
            Add User
          </Button>
        </div>
      </ActionBar>
      <div className="grow  px-2 pb-2">{renderUsers()}</div>
      {drawer && (
        <InviteSubscriptionUserComponent
          isFromProjectUser={false}
          existingUsers={allUsers || []}
          setDrawerOpen={setDrawerAction}
          showDrawerOpen={drawer}
          modelTitle={DialogTitles.addUser}
        />
      )}
      {inviteAccUser.showInviteModel && (
        <InviteAccUserComponent
          setDrawerOpen={setInviteAccUser}
          showDrawerOpen={inviteAccUser.showInviteModel}
          modelTitle={DialogTitles.inviteUser}
          userData={inviteAccUser.inviteUserData}
          isFromProjectUser={false}
        />
      )}
    </>
  );
}

export default Users;
