import {
  ColumnApi,
  CsvExportParams,
  GridApi,
  ProcessCellForExportParams
} from "ag-grid-community";
import { ProjectSettingType } from "context/ProjectProvider";
import { ArrayStringCellRenderer } from "entity-app/shared-components/log-render-components/cell-renders";

const getFormattedValues = (params: ProcessCellForExportParams<any>) => {
  const { value, column } = params;
  const colDef = column.getColDef();

  switch (true) {
    case colDef.cellRenderer === ArrayStringCellRenderer:
      {
        const arr = (value || []) as Array<{ id: string; name: string }>;
        const formattedItems = arr.map((item) => item.name);
        return formattedItems.join("\n");
      }
      break;
    default:
      return value ?? "";
  }
};

const getParams = (props: {
  entityName: string;
  projectDetails: ProjectSettingType;
  columnApi: ColumnApi;
  includedColumns: Array<string>;
  excludedColumns: Array<string>;
}): CsvExportParams => {
  const {
    entityName,
    projectDetails,
    columnApi,
    includedColumns,
    excludedColumns
  } = props;
  return {
    columnKeys: columnApi
      ?.getAllGridColumns()
      ?.filter((col) => {
        const colId = col.getColId();
        if (includedColumns.includes(colId)) return true;

        if (excludedColumns.includes(colId)) return false;

        return col.isVisible();
      })
      .map((col) => col.getColId()),
    processCellCallback(params): string {
      return getFormattedValues(params);
    },
    fileName: `${entityName}_${projectDetails.name}.csv`
  };
};

export const exportEntityLogAsCSV = (props: {
  entityName: string;
  projectDetails: ProjectSettingType;
  gridRef: {
    api: GridApi<any>;
    columnApi: ColumnApi;
  };
  includedColumns: Array<string>;
  excludedColumns: Array<string>;
}) => {
  const {
    gridRef,
    projectDetails,
    entityName,
    includedColumns,
    excludedColumns
  } = props;
  const { api, columnApi } = gridRef;
  api.exportDataAsCsv(
    getParams({
      entityName,
      projectDetails,
      columnApi,
      includedColumns,
      excludedColumns
    })
  );
};
