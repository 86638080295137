import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { Button, Form, Input, Select, message, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ErrorMessages, UserProjectRoles } from "constants/index";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { useContext, useEffect, useState } from "react";
import {
  SUBSCRIPTION_PROJECT_ROLES,
  SUBSCRIPTION_USER_SUBSCRIPTION_ROLES
} from "services/graphQL/subscriptions";
import {
  MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE,
  MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE
} from "services/graphQL/mutations";
import { QUERY_GET_USER_TYPE_BY_KEY } from "services/graphQL/queries";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { Role } from "./invite-subscription-user";
import "./invite-acc-user.scss";

const { Option } = Select;

export type InviteAccUserAction = {
  isFromProjectUser: boolean;
  userData: any;
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  modelTitle: string;
};

function InviteAccUserComponent(props: InviteAccUserAction) {
  const {
    setDrawerOpen,
    showDrawerOpen,
    modelTitle,
    userData,
    isFromProjectUser
  } = props;
  const [form] = Form.useForm();
  const [responseLoading, setResponseLoading] = useState(false);
  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const { subscriptionRole } = useContext(SubscriptionContext);
  const [projectRoles, setprojectRoles] = useState<Role[]>([]);
  const [userTypeId, setUserTypeId] = useState<Number>();
  const { tokenContents, gqlClientForProject: gqlClient }: TProjectContext =
    useContext(ProjectContext);

  const { data: projectSubscriptionRoles } = useSubscription(
    SUBSCRIPTION_PROJECT_ROLES,
    {
      shouldResubscribe: true,
      variables: {
        where: { type_id: { _eq: userTypeId } }
      },
      skip: !userTypeId
    }
  );

  const { data: userSubscriptionRoles } = useSubscription(
    SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
    {
      shouldResubscribe: true
    }
  );

  const [inviteAccSubscriptionUserMutation] = useMutation(
    MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE
  );

  const [inviteAccProjectUserMutation] = useMutation(
    MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE,
    {
      client: gqlClient
    }
  );
  const [queryUserType] = useLazyQuery(QUERY_GET_USER_TYPE_BY_KEY);
  // const { data: userTypes } = useQuery(QUERY_TRADE_USER_TYPE);

  useEffect(() => {
    if (tokenContents) {
      queryUserType({
        variables: { key: tokenContents.userType }
      }).then((res) => {
        if (res.data) {
          setUserTypeId(res.data.user_types[0].id);
        }
      });
    }
  }, [queryUserType, tokenContents]);

  useEffect(() => {
    if (projectSubscriptionRoles) {
      setprojectRoles(projectSubscriptionRoles.project_role);
    }
  }, [projectSubscriptionRoles]);

  useEffect(() => {
    if (userSubscriptionRoles) {
      const subscriptionPermission =
        userSubscriptionRoles?.subscription_permission?.filter(
          (role: any) => role.key !== "subscription_none"
        ); // Remove None permission for editor

      if (subscriptionRole <= RoleSubscriptionEnum.subscription_editor) {
        const roles = subscriptionPermission?.filter(
          (role: any) => role.name !== "Admin"
        );
        setUserRoles(roles);
      } else {
        setUserRoles(subscriptionPermission);
      }
    }
  }, [subscriptionRole, userSubscriptionRoles]);

  useEffect(() => {
    const mapObj: any = {
      company: userData?.company_name,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      phone: userData?.phone
    };

    if (isFromProjectUser) {
      mapObj.type = userData?.type;
    } else {
      const userRole = userRoles?.find(
        (role: any) => role?.key === "subscription_viewer"
      );
      mapObj.subscription_permission_id = userRole?.id || "";
    }

    form.setFieldsValue(mapObj);
  }, [form, isFromProjectUser, userData, userRoles]);

  const onSendInvite = async (values: any) => {
    setResponseLoading(true);

    if (isFromProjectUser) {
      const res = await inviteAccProjectUserMutation({
        variables: {
          user_id: userData?.id,
          role_id: values?.role_id
        }
      });
      if (res.data) {
        message.success("Invited successfully.");
        setDrawerOpen({ showInviteModel: false, inviteUserData: null });
      }
      if (res.errors) {
        message.error(res.errors[0].message);
      }
      setResponseLoading(false);
      return;
    }

    const res = await inviteAccSubscriptionUserMutation({
      variables: {
        user_id: userData?.id,
        permission_id: values?.subscription_permission_id
      }
    });
    if (res.data) {
      message.success("Invited successfully.");
      setDrawerOpen({ showInviteModel: false, inviteUserData: null });
    }
    if (res.errors) {
      message.error(res.errors[0].message);
    }
    setResponseLoading(false);
  };

  return (
    <Modal
      className="custom-drawer"
      title={modelTitle}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen({ showInviteModel: false, inviteUserData: null });
      }}
      destroyOnClose
    >
      <div className="flex-col h-full overflow-y overflow-x-hidden px-3 invite-acc-user">
        <Form
          preserve={false}
          onFinish={onSendInvite}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <div>
            <FormItem name="company" label="Company">
              <Input disabled />
            </FormItem>
            <FormItem name="first_name" label="First Name">
              <Input disabled />
            </FormItem>
            <FormItem name="last_name" label="Last Name">
              <Input disabled />
            </FormItem>
            <FormItem name="email" label="Email">
              <Input disabled />
            </FormItem>
            <FormItem name="phone" label="Phone Number">
              <Input disabled />
            </FormItem>
            {!isFromProjectUser && (
              <FormItem
                name="subscription_permission_id"
                label="Permission"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.SubscriptionRole,
                    validator(_, value: string) {
                      if (!value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Select style={{ width: "100%" }}>
                  {userRoles?.length > 0 &&
                    userRoles?.map((role: Role) => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
                </Select>
              </FormItem>
            )}
            {isFromProjectUser && (
              <FormItem
                name="role_id"
                label="Project Role"
                rules={[
                  {
                    required: true,
                    validateTrigger: "onSubmit",
                    message: ErrorMessages.ProjectRole,
                    validator(_, value: string) {
                      if (!value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Select>
                  {projectRoles.length > 0 &&
                    projectRoles
                      .filter((role: any) => {
                        return UserProjectRoles[userData?.type]?.includes(
                          role.key
                        );
                      })
                      .map((role: Role) => (
                        <Option key={role.id} value={role.id}>
                          {role.name}
                        </Option>
                      ))}
                </Select>
              </FormItem>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              htmlType="submit"
              disabled={responseLoading}
              loading={responseLoading}
              type="primary"
            >
              Send Invite
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default InviteAccUserComponent;
