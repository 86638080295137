import { gql } from "@apollo/client";

export const AUDIT_SUBSCRIPTION_LOG = gql`
  query auditSubscriptionEvents($subscription_id: uuid!) {
    audit_subscription_events_aggregate(
      where: { subscription_id: { _eq: $subscription_id } }
    ) {
      aggregate {
        count
      }
    }
    audit_subscription_events(
      where: { subscription_id: { _eq: $subscription_id } }
    ) {
      created_at
      created_by_user {
        email
      }
      event_log {
        event {
          description
        }
      }
    }
  }
`;

export const AUDIT_PROJECT_FEATURE_EVENTS = gql`
  query ProjectFeatureEvents(
    $project_id: uuid!
    $created_by: uuid!
    $feature_id: Int
  ) {
    audit_project_feature_events(
      where: {
        project_id: { _eq: $project_id }
        created_by: { _eq: $created_by }
        feature_id: { _eq: $feature_id }
      }
    ) {
      created_at
      created_by
      event_log {
        event {
          description
        }
      }
      field
    }
  }
`;

export const AUDIT_USER_EVENTS = gql`
  query auditUserEvents($user_id: uuid!) {
    audit_user_events(
      where: { user_id: { _eq: $user_id } }
      order_by: { event_log: { created_at: desc } }
    ) {
      created_at
      created_by_user {
        email
      }
      event_log {
        event {
          description
        }
      }
    }
  }
`;

export const PROJECT_USERS = gql`
  query ProjectUsers($project_id: uuid = "") {
    project_users(where: { project_id: { _eq: $project_id } }) {
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

export const AUDIT_SUB_PROJECT_FEATURE = gql`
  query getFeatureWiseProjectUserFeatureEvents($projectId: uuid!) {
    audit_project_feature_events_list_func(args: { projectid: $projectId }) {
      email
      feature_events
    }
    user(where: { project_users: { project_id: { _eq: $projectId } } }) {
      id
      email
      first_name
      last_name
      user_events_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
